const toStringWithPadZero = (int: number) => {
    return int < 10 ? `0${int}` : int.toString();
};

export const intToHoutMinute = (
    value: number,
    type: 'hour' | 'minute',
    przedzial_minutowy?: string
) => {
    const minuteInterval = parseInt(przedzial_minutowy ?? '1');
    if (type === 'minute') {
        return toStringWithPadZero((minuteInterval * Math.floor(value / minuteInterval)) % 60);
    } else {
        return toStringWithPadZero(value % 24);
    }
};
