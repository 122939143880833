import React, { FC } from 'react';
import { Box } from '@mui/material';

import { listScreen } from 'src/constants/subtitles';
import PopupTemplateMultiButtons from '../../../shared/organisms/popupTemplateMultiButtons/PopupTemplateMultiButtons';
import { useTypedSelector, useAppDispatch } from '../../../../store';
import { closePopup } from '../../../../store/src/popup';
import { useUpdateObjectsData } from 'src/hooks';
import { ListSearchFilters } from 'src/store/src/listScreen/transformations/types';
import { useSearchParam } from 'src/hooks';
import { useSaveListSearchFilter } from 'src/hooks';
import { reducer } from './reducer';
import { NormalScroll } from 'src/components/shared/molecules/normalScroll/NormalScroll';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import { FilterPopupManager } from 'src/components/listScreen/organisms/popupContentSearch/FilterPopupManager';
import { DynamicSearchInputs } from 'src/components/listScreen/molecules/dynamicSearchInputs/DynamicSearchInputs';
import { usePressKey } from 'src/hooks/src/shared/usePressKey';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { generateToken } from 'src/utils';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { globalPopup } from 'src/constants/subtitles';

const PopupContentSearch: FC<BasePopupContentProps> = ({
    widthAvailable,
    heightAvailable,
    popup
}) => {
    usePressKey(handleKeyPress);
    const screen = useSearchParam('obiekt');
    const saveListSearchFilterCallback = useSaveListSearchFilter();
    const objectDataState = useTypedSelector((state) => state.listSearchFields);
    const filters: ListSearchFilters = useTypedSelector(
        (state) => state.listTransformations.filters
    );

    const paging = useTypedSelector((state) => state.listTransformations.paging);

    const dispatch = useAppDispatch();
    const updateObjects = useUpdateObjectsData();

    const [stateFilter, dispatchFilter] = React.useReducer(
        reducer,
        new FilterPopupManager({
            objectsPerPage: paging.objectsPerPage,
            searchFields: objectDataState,
            listSearchFiltersIN: filters
        }).setInitState() as any
    );

    const prepareDataToApi = () => {
        return {
            filters: new FilterPopupManager({
                objectsPerPage: paging.objectsPerPage,
                listSearchFiltersIN: stateFilter,
                searchFields: objectDataState
            }).getDataToAPi(),
            paging: {
                ...paging,
                objectsPerPage: stateFilter['ilosc_wynikow']?.fieldFilter.value
            }
        };
    };

    const searchLocal = async () => {
        const updated = prepareDataToApi();
        dispatch(closePopup(popup.key));
        await updateObjects({ ...updated, resetPage: true });
    };

    function handleKeyPress(e: any) {
        if (e.keyCode === 13 && document.activeElement === document.body) {
            searchLocal();
        }
    }

    const save = async (name: string) => {
        if (screen != null) {
            return await saveListSearchFilterCallback(screen, prepareDataToApi(), name);
        }
    };

    const searchAndSave = async () => {
        const closeCallbackEventId = `listSearchSaveFilter-close-${generateToken()}`;
        const submitCallbackEventId = `listSearchSaveFilter-submit-${generateToken()}`;

        EventBus.$on(closeCallbackEventId, () => {
            EventBus.$off(closeCallbackEventId);
            EventBus.$off(submitCallbackEventId);
        });

        EventBus.$on(submitCallbackEventId, async (text: string) => {
            EventBus.$off(closeCallbackEventId);
            EventBus.$off(submitCallbackEventId);

            if (text) {
                await searchLocal();
                await save(text);
            }
        });

        new PopupManager({ dispatch, action: null }).setPopupPrompt({
            title: globalPopup.contentPrompt.title,
            message: listScreen.getSearchFilterName,
            closeCallbackEventId,
            submitCallbackEventId
        });
    };

    return (
        <div>
            <PopupTemplateMultiButtons
                title={'Szukaj'}
                sxFooter={{
                    paddingRight: '60px'
                }}
                buttonOne={{
                    onClick: () => {
                        searchLocal();
                    },
                    buttonTxt: 'Znajdź'
                }}
                buttonTwo={{
                    onClick: () => {
                        searchAndSave();
                    },
                    buttonTxt: 'Zapisz i znajdź'
                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    <NormalScroll
                        width={`${widthAvailable > 950 ? 820 : widthAvailable - 60}px`}
                        height={`${heightAvailable > 690 ? 450 : heightAvailable - 250}px`}
                        styleInnerWrapper={{
                            paddingRight: '20px',
                            paddingLeft: '20px'
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                                width: '750px'
                            }}>
                            <DynamicSearchInputs
                                searchFields={objectDataState}
                                listSearchFilters={stateFilter}
                                dispatchFilter={dispatchFilter}
                                confirm={searchLocal}
                            />
                        </Box>
                    </NormalScroll>
                </Box>
            </PopupTemplateMultiButtons>
        </div>
    );
};

export default PopupContentSearch;
