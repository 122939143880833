import { fetchMessageData } from 'src/api/src/messageScreen/fetchMessage';
import { AppThunk } from 'src/store';
import { setMessageData } from 'src/store/src/messageScreen/message/messageSlice';

export const fetchAndLoadInitialMessageScreenData = (): AppThunk => async (dispatch) => {
    const apiResponse = await fetchMessageData(window.location.search.replace('?', ''));

    if (!apiResponse.success) {
        window.location.replace(`/message?tytul=${apiResponse.message}`);
        throw new Error('fetchAndLoadInitialMessageData: not get InitialMessageScreenData ');
    }
    dispatch(setMessageData(apiResponse.data));
};
