import React, { FC } from 'react';

import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import { DynamicAutoComplete } from 'src/components/shared/atoms/dynamicAutoComplete/DynamicAutoComplete';
import { DynamicListSearch } from 'src/components/shared/atoms/dynamicListSearch/DynamicListSearch';
import { DynamicSingleDateSearch } from 'src/components/shared/atoms/dynamicSingleDateSearch/DynamicSingleDateSearch';
import { DynamicDateSearch } from 'src/components/shared/atoms/dynamicDateSearch/DynamicDateSearch';
import { DynamicTextSearch } from 'src/components/shared/atoms/dynamicTextSearch/DynamicTextSearch';
import { DynamicRangeNumberSearch } from 'src/components/shared/atoms/dynamicRangeNumberSearch/DynamicRangeNumberSearch';
import { DynamicDateRelativeSearch } from 'src/components/shared/atoms/dynamicDateRelativeSearch/DynamicDateRelativeSearch';
import { DynamicNumberFromZeroSearch } from 'src/components/shared/atoms/dynamicNumberFromZeroSearch/DynamicNumberFromZeroSearch';
import { DynamicListManySearch2 } from 'src/components/shared/atoms/dynamicListManySearch2/DynamicListManySearch2';

export const DynamicSearchInput: FC<DynamicSearchInputProps> = (props) => {
    switch (props.searchField.searchType) {
        case 'lista_rozwijalna':
            return <DynamicAutoComplete {...props} />;
        case 'lista_wielokrotna':
            return <DynamicListManySearch2 {...props} />;
        case 'lista':
        case 'lista_ilosc_na_stronie':
            return <DynamicListSearch {...props} />;
        case 'data_pojedyncza':
            return <DynamicSingleDateSearch {...props} />;
        case 'data':
            return <DynamicDateSearch {...props} />;
        case 'data_wzgledna':
            return <DynamicDateRelativeSearch {...props} />;
        case 'plik':
        case 'tekst':
            return <DynamicTextSearch {...props} />;
        case 'liczba':
            return <DynamicRangeNumberSearch {...props} />;
        case 'liczba_rozliczana_do_zera':
            return <DynamicNumberFromZeroSearch {...props} />;
        default:
            return <div></div>;
    }
};
