import { Theme } from '@mui/material';
import { UiFieldFlags } from 'src/components/popupFormExtended/dynamicField/types';

export const getColorForInputWithAllFlag = ({
    theme,
    flag
}: {
    theme: Theme;
    flag: UiFieldFlags;
    disabled?: {
        archived?: boolean;
        disabled?: boolean;
        disabledWew?: boolean;
        isError?: boolean;
    };
}) => {
    if (flag.isError) {
        return theme.palette.error.main;
    } else if (flag.disabled || flag.disabledWew) {
        return theme.palette.gray.first;
    } else if (flag.archived) {
        return theme.palette.gray.second;
    } else {
        return theme.palette.primary.main;
    }
};
