import { BudgetFieldKeyParts } from 'src/store/src/budget/budget/types';

export type CreateKeyFieldBudgetProps = BudgetFieldKeyParts & {
    name: string;
};

export function createKeyFieldBudget(data: CreateKeyFieldBudgetProps): {
    keyField: string;
    keyFieldPrefixMethod: `${string}.${string}`;
} {
    const keyField = createKeyFieldNameBudget(data);
    return {
        keyField,
        keyFieldPrefixMethod: `${data.methodId}.${keyField}`
    };
}

function createKeyFieldNameBudget(data: CreateKeyFieldBudgetProps) {
    let name = data.name;
    if (name?.includes('$miesiac.data_rozp') && data.monthDate) {
        name = name.replace('{$miesiac.data_rozp}', String(data.monthDate));
    }
    if (name?.includes('{$item.id}') && data.objectId) {
        name = name?.replace('{$item.id}', data.objectId);
    }
    if (name?.includes('{$kontrakt.id}') && data.methodId) {
        name = name?.replace('{$kontrakt.id}', data.methodId);
    }
    return name;
}
