export function filterSafeParams(
    url: string,
    queryParams: URLSearchParams | undefined = undefined
) {
    const tempParams = new URLSearchParams(queryParams);
    const newParams = new URLSearchParams();
    for (const [key, value] of tempParams.entries()) {
        if (value && value !== 'null') {
            newParams.append(key, value);
        }
    }

    switch (url) {
        case 'ekran_wiadomosc.php':
            return newParams;
        default:
            return queryParams;
    }
}
