import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ObjectScreenLocationState } from 'src/components/object/pages/object/types';
import { TabId } from 'src/store/src/object/object/types';
import { fetchAndLoadObjectScreenTabData } from 'src/store/src/object/sharedActions';
import { getSectionParams, savePageLocationState } from 'src/utils';
import { useSafeNavigate } from '../shared/useSafeNavigate';
import { useAppDispatch } from 'src/store';

export const useUpdateObjectTabData = () => {
    // collect hooks
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();
    const location = useLocation();

    return useCallback(
        (tabId: TabId) => {
            // load section params
            const sectionParams = getSectionParams();
            dispatch(fetchAndLoadObjectScreenTabData(tabId, sectionParams));

            const locationData: ObjectScreenLocationState = {
                params: { id_zakladki: tabId },
                legacyParams: {},
                store: null
            };

            savePageLocationState(locationData, navigate, location.pathname, true);
        },
        [dispatch, location.pathname, navigate]
    );
};
