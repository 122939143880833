import React, { FC } from 'react';

import { DynamicSearchInputsProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import { DynamicSearchInput } from 'src/components/listScreen/molecules/dynamicSearchInputs/DynamicSearchInput';
import { DynamicSearchLabel } from 'src/components/listScreen/molecules/dynamicSearchLabel/DynamicSearchLabel';
import styles from './DynamicSearchInputs.module.css';

export const DynamicSearchInputs: FC<DynamicSearchInputsProps> = (props) => {
    return (
        <div className={styles.wrapper}>
            {props.searchFields.map((searchField, i) => (
                <div
                    className={styles.item}
                    key={i}
                    style={{ width: `${searchField?.width && searchField.width * 100 + '%'}` }}>
                    <DynamicSearchLabel
                        searchField={searchField}
                        listSearchFilters={props.listSearchFilters}
                        dispatchFilter={props.dispatchFilter}
                    />
                    <DynamicSearchInput
                        searchField={searchField}
                        listSearchFilters={props.listSearchFilters}
                        dispatchFilter={props.dispatchFilter}
                        confirm={props.confirm}
                    />
                </div>
            ))}
        </div>
    );
};
