import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import style from './DynamicLabel.module.css';
import { DynamicLabelProps } from 'src/components/shared/atoms/dynamicLabel/types';

export const DynamicLabel: FC<DynamicLabelProps> = (props) => {
    const theme = useTheme();
    return (
        <p
            className={style.p}
            style={{
                color: theme.palette.text.label
            }}>
            {props.label}
        </p>
    );
};
