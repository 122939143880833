import React, { FC } from 'react';

import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { ApiActionSendMessageProps } from './types';
import { usePrepareDataForApiMessageScreen } from 'src/hooks';

const ApiActionSendMessage: FC<ApiActionSendMessageProps> = ({ children, action }) => {
    const { prepareDataForApiMessageScreen } = usePrepareDataForApiMessageScreen(action);

    return (
        <ButtonCustom onClick={() => prepareDataForApiMessageScreen()} withoutStyles={true}>
            {children}
        </ButtonCustom>
    );
};

export default ApiActionSendMessage;
