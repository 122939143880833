const EasterDates = [
    '2000	April 23',
    '2001	April 15',
    '2002	March 31',
    '2003	April 20',
    '2004	April 11',
    '2005	March 27',
    '2006	April 16',
    '2007	April 8',
    '2008	March 23',
    '2009	April 12',
    '2010	April 4',
    '2011	April 24',
    '2012	April 8',
    '2013	March 31',
    '2014	April 20',
    '2015	April 5',
    '2016	March 27',
    '2017	April 16',
    '2018	April 1',
    '2019	April 21',
    '2020	April 12',
    '2021	April 4',
    '2022	April 17',
    '2023	April 9',
    '2024	March 31',
    '2025	April 20',
    '2026	April 5',
    '2027	March 28',
    '2028	April 16',
    '2029	April 1',
    '2030	April 21',
    '2031	April 13',
    '2032	March 28',
    '2033	April 17',
    '2034	April 9',
    '2035	March 25',
    '2036	April 13',
    '2037	April 5',
    '2038	April 25',
    '2039	April 10',
    '2040	April 1',
    '2041	April 21',
    '2042	April 6',
    '2043	March 29',
    '2044	April 17',
    '2045	April 9',
    '2046	March 25',
    '2047	April 14',
    '2048	April 5',
    '2049	April 18',
    '2050	April 10',
    '2051	April 2',
    '2052	April 21',
    '2053	April 6',
    '2054	March 29',
    '2055	April 18',
    '2056	April 2',
    '2057	April 22',
    '2058	April 14',
    '2059	March 30',
    '2060	April 18',
    '2061	April 10',
    '2062	March 26',
    '2063	April 15',
    '2064	April 6',
    '2065	March 29',
    '2066	April 11',
    '2067	April 3',
    '2068	April 22',
    '2069	April 14',
    '2070	March 30',
    '2071	April 19',
    '2072	April 10',
    '2073	March 26',
    '2074	April 15',
    '2075	April 7',
    '2076	April 19',
    '2077	April 11',
    '2078	April 3',
    '2079	April 23',
    '2080	April 7',
    '2081	March 30',
    '2082	April 19',
    '2083	April 4',
    '2084	March 26',
    '2085	April 15',
    '2086	March 31',
    '2087	April 20	2088	April 11',
    '2089	April 3',
    '2090	April 16',
    '2091	April 8',
    '2092	March 30',
    '2093	April 12',
    '2094	April 4',
    '2095	April 24',
    '2096	April 15',
    '2097	March 31',
    '2098	April 20',
    '2099	April 12'
];

const getHolidayKey = (date: Date) => date.getDate() + '.' + (date.getMonth() + 1);

/**
 * Function returns object with given year holiday dates as keys and holiday names as strings.
 * Accepts year range between 2000 and 2099.
 * @example {..., '1.5': '1 Maja', ...}
 */
export function getHolidaysForYear(year: number): { [key: string]: string } {
    if (year < 2000 || year > 2099) throw new RangeError('Holidays list: Date out of range');
    const index = year - 2000;
    const easterDate = new Date(EasterDates[index]);
    const easterSecondDay = new Date(easterDate.getTime() + 1000 * 60 * 60 * 24);
    /* Exactly 49 days after easter */
    const pentecost = new Date(easterDate.getTime() + 1000 * 60 * 60 * 24 * 49);
    /* Exactly 60 days after easter */
    const corpusChristi = new Date(easterDate.getTime() + 1000 * 60 * 60 * 24 * 60);

    return {
        '1.1': 'Nowy Rok',
        '6.1': 'Trzech Króli',
        [getHolidayKey(easterDate)]: 'Wielkanoc',
        [getHolidayKey(easterSecondDay)]: 'Wielkanoc',
        '1.5': '1 Maja',
        '3.5': '3 Maja',
        [getHolidayKey(pentecost)]: 'Zielone Świątki',
        [getHolidayKey(corpusChristi)]: 'Boże ciało',
        '15.8': 'Wniebowzięcie Najświętszej Maryi Panny',
        '1.11': 'Wszystkich Świętych',
        '11.11': 'Święto Niepodległości',
        '25.12': 'Boże Narodzenie',
        '26.12': 'Boże Narodzenie'
    };
}
