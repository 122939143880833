import { FieldPrice } from 'src/components/popupFormExtended/dynamicField/fieldPrice/types';

import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { JS_TIMESTAMP_DAY, JS_TIMESTAMP_HOUR } from 'src/constants/timestamps';
import { FieldPriceManager } from 'src/utils/src/shared/FieldPriceManager';

import { NumberManager } from 'src/utils/src/shared/NumberManager';

type FieldDependentPriceManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldPrice;
};

export class FieldDependentPriceManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldPrice;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentPriceManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async przeliczWartosc() {
        if (
            this.fieldWasTriggered?.updatedNumber === undefined ||
            (this.fieldWasTriggered?.updatedNumber !== undefined &&
                this.fieldWasTriggered.updatedNumber < 5)
        ) {
            if (!this.fieldWasTriggered.isUpdatedCalc) {
                await this.calc();
                await this.runNested(this.fieldWasTriggeredDynamicFieldT);
            }
        }
    }

    private async calc() {
        const data = this.getValuesToSet();

        let result = 0;
        let value1 = 0;
        let value2 = 0;
        let update = false;
        let vat;
        switch (data.one.typ) {
            case 'data':
                value1 = data.one.timestampWithoutHourMinute / JS_TIMESTAMP_DAY;
                break;
            case 'data_godzina':
                if (this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie === '-bezgodzin') {
                    value1 = data.one.timestampWithoutHourMinute / JS_TIMESTAMP_DAY;
                } else value1 = data.one.timestampAll / JS_TIMESTAMP_HOUR;
                break;
            case 'data_godziny':
                {
                    const dataTemp =
                        data.one.hourTwo +
                        data.one.minuteTwo / 60 -
                        data.one.hour -
                        data.one.minute / 60;
                    value1 = Math.round(data.round * dataTemp) / data.round;
                    if (value1 <= 0) {
                        value1 = value1 + 24;
                    }
                }
                break;
            case 'liczba':
                if (!isNaN(data.one.number)) {
                    value1 = data.one.number;
                }
                break;
            case 'koszty':
                value1 = data.one.number;
                break;
            case 'cena':
                value1 = data.one.netto;
                break;
            case 'cena_liczba':
                value1 = data.one.netto;
                break;
            default:
                break;
        }

        switch (data.two.typ) {
            case 'data':
                value2 = data.two.timestampWithoutHourMinute / JS_TIMESTAMP_DAY;
                break;
            case 'data_godzina':
                if (this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie === '-bezgodzin') {
                    value2 = data.two.timestampWithoutHourMinute / JS_TIMESTAMP_DAY;
                } else value2 = data.two.timestampAll / JS_TIMESTAMP_HOUR;
                break;
            case 'cena':
                value2 = data.two.netto;
                vat = data.two.vat;
                break;
            case 'cena_liczba':
                value2 = data.two.netto;
                break;
            case 'liczba':
                if (!isNaN(data.two.number)) {
                    value2 = data.two.number;
                }
                break;
            case 'koszty':
                value2 = data.two.number;
                break;
            case 'lista':
                vat = data.two.vat;
                break;
            default:
                break;
        }

        if (this.fieldSuperiorOneDynamicFieldT?.pole_do_wyliczen_1_procent === 't') {
            value1 = value1 / 100;
        }
        if (this.fieldSuperiorTwoDynamicFieldT?.pole_do_wyliczen_2_procent === 't') {
            value2 = value2 / 100;
        }

        switch (this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie) {
            case 'cnt()':
            case '=':
            case '*VAT':
            case '=godziny':
                result = value1;
                update = true;
                break;
            case '+':
            case '-':
            case '*':
            case '/':
                {
                    const datanew = NumberManager.calcArithmeticValue(
                        value1,
                        value2,
                        this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie
                    );
                    result = Math.round(datanew * data.round) / data.round;
                    update = true;
                }
                break;
            default:
                break;
        }

        const newValue = new FieldPriceManager({
            oldValue: this.fieldWasTriggered.value,
            changeType: 'netto',
            value: String(result),
            vatOptional: vat
        }).calc();

        const value = {
            ...this.fieldWasTriggered,
            value: newValue
        };
        if (update) {
            this.setTempFieldsState(value, 'isUpdatedCalc');
            this.isRunNested = true;
        }
    }
}
