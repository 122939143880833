import React, { FC, useMemo } from 'react';
import { FieldFileUnmodifiedVersionRowProps } from './types';
import { SystemAction } from 'src/data/types';
import { ActionType } from 'src/components/shared/molecules/actionType/ActionType';
import styles from './fileUnmodified/UiFileUnmodified.module.css';

import { fields } from 'src/constants/subtitles';

const FieldFileUnmodifiedVersionRow: FC<FieldFileUnmodifiedVersionRowProps> = ({
    date,
    user,
    id
}) => {
    const actionDownload: SystemAction = useMemo(
        () => ({
            name: 'Pobierz',
            code: 'download_file_version',
            colorConditions: null,
            displayConditions: null,
            behaviour: {
                type: 'fileDownload',
                data: {
                    url: 'pobierz_wersje.php',
                    params: '',
                    payload: [`{ "key": "id", "value": "${id}" }`]
                }
            }
        }),
        [id]
    );

    return (
        <div className={styles.unmodifiedVersionWrapper}>
            <p>
                {date} {user}
            </p>
            <ActionType action={actionDownload} rowId={null}>
                <p className={styles.unmodifiedDownloadText}>{fields.fieldFile.download}</p>
            </ActionType>
        </div>
    );
};

export default FieldFileUnmodifiedVersionRow;
