import React, { FC, useMemo } from 'react';
import { Field } from 'src/components/popupFormExtended/field/Field';
import { FieldsRelationProps } from 'src/components/popupFormExtended/fieldsRelation/types';
import { RelationCancel } from 'src/components/popupFormExtended/relationCancel/RelationCancel';
import { FormContext } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import styles from './FieldsRelation.module.css';
import { UiFieldCommonProps } from '../dynamicField/types';
import { FieldMinWidth } from 'src/components/shared/atoms/fieldMinWidth/FieldMinWidth';

export const FieldsRelation: FC<FieldsRelationProps> = (props) => {
    const { relation, relatedObjectData, prefixFieldKey, usedForAddingObject, keyPopup } = props;
    const { formState } = React.useContext(FormContext);
    const relationToDelete = formState?.fields[`${prefixFieldKey}_powiazanie_usun_obiekt`];

    const uiFieldCommonProps: UiFieldCommonProps = useMemo(
        () => ({
            isLabel: false,
            archived: relatedObjectData.archived,
            disabled: relationToDelete ? true : false,
            usedForAddingObject: usedForAddingObject
        }),
        [usedForAddingObject, relatedObjectData.archived, relationToDelete]
    );

    return (
        <tr className={styles.tr}>
            {relation?.fields?.map((field: DynamicFieldT) => {
                if (field.ukryte === 1) return null;
                return (
                    <td key={field.id}>
                        <FieldMinWidth dynamicFieldT={field}>
                            <Field
                                keyPopup={keyPopup}
                                dynamicFieldT={field}
                                keyOfFieldInState={`${props.prefixFieldKey}_${field.kod}`}
                                prefix={`${props.prefixFieldKey}_`}
                                usedForAddingObject={usedForAddingObject}
                                relationId={relation.id}
                                uiFieldCommonProps={uiFieldCommonProps}
                            />
                        </FieldMinWidth>
                    </td>
                );
            })}
            {!relatedObjectData.isNotToDelete && <RelationCancel {...props} />}
        </tr>
    );
};
