import { SafeNavigateFunction } from 'src/hooks/src/shared/types';
import { BaseApiResponse, UpdateObjectDataForApi } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { SUBMIT_MODES } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObject';

export async function sendPopupFormExtendedEndRequestEdit(
    url: string,
    mode: SUBMIT_MODES,
    data: UpdateObjectDataForApi,
    navigate: SafeNavigateFunction
): Promise<BaseApiResponse<null>> {
    const splittedUrl = url.split('?');
    const params =
        splittedUrl[1] === '' ? `cyclicMode=${mode}` : `${splittedUrl[1]}&cyclicMode=${mode}`;
    return simpleQueryAPIData(
        `${splittedUrl[0]}?${params}`,
        'POST',
        data,
        true,
        { alert: false, navigation: true },
        navigate
    );
}
