import { DynamicFieldT } from 'src/data/popupFormTypes';
import { AutocompleteClicedOption, AutocompleteOption } from 'src/data/uiTypes/autocompleteTypes';

export function calculateValuesOnChangeForListForeignMany({
    options,
    newValues,
    clickedOption,
    dynamicFieldT
}: {
    options: AutocompleteOption[];
    newValues: AutocompleteOption[];
    clickedOption: AutocompleteClicedOption;
    dynamicFieldT: DynamicFieldT;
}) {
    // for wyszukiwanie_rozwijalna mode it wil be better "wszystkie" option work as "normal"
    // option, not setting all available values
    if (dynamicFieldT.wyszukiwanie_rozwijalna === 't') return [...newValues];

    const foundAll = newValues.find((item) => item.text === 'WSZYSTKIE');

    if (foundAll && clickedOption?.option?.text === 'WSZYSTKIE') {
        return [...options];
    }

    if (!foundAll && clickedOption?.option?.text === 'WSZYSTKIE') {
        return [];
    }

    return [...newValues];
}
