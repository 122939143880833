import React, { FC } from 'react';
import { ThemeCompatibleFaIconProps } from './types';
import ThemeCompatibleSvgFileNameIcon from 'src/components/shared/atoms/themeCompatibleSvgFileNameIcon/themeCompatibleSvgFileNameIcon';
import { FontSizeWrapper } from './styles';
import { Icon } from '@mui/material';
/**
 * display fa icon, handle displaying svg icon instead fa if is that icon made
 */
// chane : fontSizeType to lowercase due to React warning
const ThemeCompatibleFaIcon: FC<ThemeCompatibleFaIconProps> = ({
    iconData,
    forceFa,
    fontSize,
    wrapperCommonProps,
    ...props
}) => {
    return iconData.svgFileName && !forceFa ? (
        <ThemeCompatibleSvgFileNameIcon
            svgFileName={iconData.svgFileName}
            fontSize={fontSize}
            wrapperCommonProps={wrapperCommonProps}
            {...props}
        />
    ) : (
        <FontSizeWrapper fontsizetype={fontSize} {...wrapperCommonProps}>
            <Icon
                baseClassName="fa"
                {...props}
                className={`${iconData.faCode} ${props.className}`}
            />
        </FontSizeWrapper>
    );
};

export default ThemeCompatibleFaIcon;
