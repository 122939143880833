import React, { FC } from 'react';

import { useTypedSelector } from 'src/store';
import { useTheme } from '@mui/material/styles';
import styles from './DynamicDescription.module.css';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
import { FieldHelper } from 'src/utils/src/shared/FieldHelper';
import { PopupState } from 'src/store/src/popup/popup/types';
import { reduceStr } from 'src/utils';
import { DynamicFormHelper } from 'src/components/shared/molecules/dynamicForm/DynamicFormHelper';

type Props = {
    id: RowDataId;
    popup: PopupState;
    closePopup?: () => void;
};

export const DynamicDecscription: FC<Props> = ({ popup, id }) => {
    const theme = useTheme();
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const objectSlice = useTypedSelector((state) => state.object);

    const fields = FieldHelper.getFilteredFields({
        fields: popup.popupFormData?.fields ?? [],
        key: 'modyfikacja',
        value: 0
    });

    const fieldsTemplated = fields.map((item) => {
        return {
            ...item,
            domyslna_wartosc: new DynamicFormHelper({
                objectSlice,
                rowData,
                popupState: popup,
                rowId: id
            }).getCheckTemplateRawValueOrText({
                str: item.domyslna_wartosc,
                type: 'text',
                sign: '!'
            })
        };
    });
    return (
        <div className={styles.wrapper}>
            {fieldsTemplated.map((item, i) => {
                if (item.ukryte === 1) {
                    return <></>;
                }
                return (
                    <div key={item.nazwa + i} className={styles.box}>
                        <p
                            className={styles.p}
                            style={{
                                fontWeight: '600',
                                color: `${theme.palette.primary.main}`
                            }}>
                            {item.nazwa}:
                        </p>
                        <p className={styles.p}>{reduceStr(item.domyslna_wartosc, 100)}</p>
                    </div>
                );
            })}
        </div>
    );
};
