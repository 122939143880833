import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldDateHoursObjectProps } from './types';
import { UiFieldDateHours } from 'src/components/popupFormExtended/dynamicField/fieldDateHours/UiFieldDateHours';
import { GetDate } from 'src/components/shared/atoms/datePicker/DatePicker';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { FieldDateHoursManager } from 'src/utils/src/shared/FieldDateHoursManager';

export const FieldDateHoursObject: FC<FieldDateHoursObjectProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const onChangeDate = (data: GetDate) => {
        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: {
                ...field.value,
                date: data.dateSelectedNormal
            }
        };
        dispatch({
            type: 'data_godziny',
            payload: {
                code: keyOfFieldInState,
                value: newValue
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newValue,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };

    const onChangeInput = (
        e: React.ChangeEvent<HTMLInputElement>,
        type: 'rozp' | 'zak',
        type2: 'minutes' | 'hours'
    ) => {
        const value = e.target.value;
        const newValueCalc = new FieldDateHoursManager({
            fieldStateValue: field.value
        }).generateDataInOnChangeInput({ value, type, type2 });

        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: {
                ...field.value,
                [type]: newValueCalc
            }
        };

        dispatch({
            type: 'data_godziny',
            payload: {
                code: keyOfFieldInState,
                value: {
                    ...field,
                    value: {
                        ...field.value,
                        [type]: newValueCalc
                    }
                }
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newValue,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };

    return (
        <UiFieldDateHours
            value={field.value}
            dynamicFieldT={dynamicFieldT}
            onChangeDate={onChangeDate}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            onChangeRozpMinutes={(e) => onChangeInput(e, 'rozp', 'minutes')}
            onChangeRozpHours={(e) => onChangeInput(e, 'rozp', 'hours')}
            onChangeZakHours={(e) => onChangeInput(e, 'zak', 'hours')}
            onChangeZakMinutes={(e) => onChangeInput(e, 'zak', 'minutes')}
        />
    );
};
