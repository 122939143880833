import { SettledObjectsCodes } from 'src/store/src/budget/budget/types';

const excludedFields = ['koszty_7', 'kontakty_ryczalty_7', 'rozprawy_ryczalty_7', 'dokumenty_7'];

export function checkIfFieldIsExcludedFromSum(
    settledObjectsCodes: SettledObjectsCodes,
    fieldId: string
) {
    if (excludedFields.includes(`${settledObjectsCodes}_${fieldId}`)) {
        return true;
    }
    return false;
}
