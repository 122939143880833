import { combineFullUrl } from '../shared/combineFullUrl';

export const getFullUrlWithIdOkna = (url: string, params?: URLSearchParams) => {
    const paramsAll = new URLSearchParams(window.location.search);
    const idOkna = paramsAll.get('id_okna');
    if (idOkna) {
        params?.append('id_okna', String(idOkna));
    }
    return combineFullUrl(url, params);
};
