import { BugdetCaseLevel, SettledElement } from 'src/store/src/budget/budget/types';

const stableSettledElement: SettledElement[] = [
    {
        code: 'zadania',
        objects: []
    }
];

// used to display stableSettledElement SettledElement even when they don't have any items
// for example 'zadania' should be displayed even when there are no items, to enable adding new items
export const useManageCaseView = (caseItem: BugdetCaseLevel) => {
    const emptySettledElement: SettledElement[] = [];

    stableSettledElement.forEach((item) => {
        const isExist = Boolean(
            caseItem.settledElements.filter((item2) => item2.code === item.code).length
        );
        if (!isExist) {
            emptySettledElement.push(item);
        }
    });

    const isEmptyCase = caseItem?.settledElements.length === 0;
    return {
        isEmptyCase,
        emptySettledElement
    };
};
