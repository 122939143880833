import React, { FC } from 'react';
import { DynamicNumber } from 'src/components/shared/atoms/dynamicNumber/DynamicNumber';
import { DynamicDateHour } from 'src/components/shared/atoms/dynamicDateHour/DynamicDateHour';
import { DynamicCheckbox } from 'src/components/shared/atoms/dynamicCheckbox/DynamicCheckbox';
import { DynamicText } from 'src/components/shared/atoms/dynamicText/DynamicText';
import { DynamicList } from 'src/components/shared/atoms/dynamicList/DynamicList';
import { UiWrapperField } from 'src/components/shared/atoms/uiLayout/uiWrapperField/UiWrapperField';
import { DynamicFieldProps } from 'src/components/shared/molecules/dynamicField/types';
import { DynamicDateHours } from '../../atoms/dynamicDateHours/DynamicDateHours';
import { DynamicRelationshipType } from 'src/components/shared/atoms/dynamicRelationshipType/DynamicRelationshipType';
import { DynamicRelationshipTypeMany } from 'src/components/shared/atoms/dynamicRelationshipTypeMany/DynamicRelationshipTypeMany';
import { DynamicListForeign } from 'src/components/shared/atoms/dynamicListForeign/DynamicListForeign';
import { DynamicListForeignMany } from 'src/components/shared/atoms/dynamicListForeignMany/DynamicListForeignMany';
import { DynamicTextWithoutRestrict } from 'src/components/shared/atoms/dynamicTextWithoutRestrict/DynamicTextWIthoutRestrict';

export const DynamicField: FC<DynamicFieldProps> = (props) => {
    if (props.dynamicFieldT.ukryte === 1) {
        return <></>;
    }
    switch (props.field.value.type) {
        case 'liczba':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicNumber {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'data_godzina':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicDateHour {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'lista':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicList {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'checkbox':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicCheckbox {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'tekst':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicText {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'tekst_bez_ograniczen':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicTextWithoutRestrict {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'data_godziny':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicDateHours {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'lista_obca':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicListForeign {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'lista_obca_wielokrotna':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicListForeignMany {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'powiazanie_typ':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicRelationshipType {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        case 'powiazanie_typ_wielokrotne':
            return (
                <UiWrapperField width={props.dynamicFieldT.width}>
                    <DynamicRelationshipTypeMany {...props} fieldValue={props.field.value} />
                </UiWrapperField>
            );
        default:
            return <></>;
    }
};
