import React, { FC } from 'react';
import PopupTemplateHead from 'src/components/shared/molecules/popupTemaplateHead/PopupTemplateHead';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import styles from './PopupTemplateSecond.module.css';

/**
 * Describes main menu, handle all mechanics related to menu data
 */

type Props = {
    title: string;
    buttonLeftTxt: string;
    buttonLeftOnClick: (value: any) => void;
    buttonRightTxt: string;
    buttonRightOnClick: (value: any) => void;
};

const PopupTemplateSecond: FC<Props> = ({
    children,
    title,
    buttonLeftTxt,
    buttonLeftOnClick,
    buttonRightOnClick,
    buttonRightTxt
}) => {
    return (
        <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
            <PopupTemplateHead title={title} />
            <div className={styles.box}>{children}</div>
            <div className={styles.box2}>
                <ButtonCustom onClick={buttonLeftOnClick} preventMultipleClick={true}>
                    <p className={`${styles.button}`}>{buttonLeftTxt}</p>
                </ButtonCustom>
                <ButtonCustom onClick={buttonRightOnClick} preventMultipleClick={true}>
                    <p className={`${styles.button}`}>{buttonRightTxt}</p>
                </ButtonCustom>
            </div>
        </div>
    );
};

export default PopupTemplateSecond;
