import { useEffect, useState } from 'react';
import { ADDRESS_OPTIONS_KEY } from 'src/constants';
import { getDataForAddressField } from 'src/api/src/shared/getDataForAddressField';
import { UiInputOptionEntry } from 'src/components/shared/atoms/uiInputs/types';
import { StoreDataForAddressField } from 'src/components/popupFormExtended/dynamicField/fieldAdress/types';

export const useLoadAddressOptions = () => {
    const [voivodships, setVoivodships] = useState<UiInputOptionEntry[]>([]);
    const [countries, setCountries] = useState<UiInputOptionEntry[]>([]);

    useEffect(() => {
        const loadDataFromStorage = () => {
            const rawData = localStorage.getItem(ADDRESS_OPTIONS_KEY);
            if (!rawData) {
                console.error('useLoadAddressOptions(): Cannot load data from localStorage');
                return;
            }
            const data: StoreDataForAddressField = JSON.parse(rawData);
            setVoivodships(data.voivodships);
            setCountries(data.countries);
        };

        const loadDataFromAPI = async () => {
            const res = await getDataForAddressField();
            if (res.success) {
                // set data to state
                setVoivodships(res.data.voivodships);
                setCountries(res.data.countries);

                // save data to localStorage
                localStorage.setItem(
                    ADDRESS_OPTIONS_KEY,
                    JSON.stringify({
                        voivodships: res.data.voivodships,
                        countries: res.data.countries
                    })
                );
            } else {
                console.error('useLoadAddressOptions(): Cannot load data from api');
            }
        };

        const loadData = async () => {
            if (!localStorage.getItem(ADDRESS_OPTIONS_KEY)) {
                await loadDataFromAPI();
            } else {
                loadDataFromStorage();
            }
        };

        loadData();
    }, []);

    return { voivodships: [{ name: '', value: 'empty' }, ...voivodships], countries };
};
