import { budgetStableCodesForTotalCalc } from 'src/constants/budget/budgetStableCodesForTotalCalc';

import {
    BudgetSettledObjectsStructures,
    BugdetSettlementMethodLevel,
    HoursLimitData,
    SettledObjectsCodes,
    SettleObject
} from 'src/store/src/budget/budget/types';

import { NumberManager } from 'src/utils/src/shared/NumberManager';

export class BudgetCalcCapfeeRemainingForMethod {
    settledObjectsStructures: BudgetSettledObjectsStructures;
    method: BugdetSettlementMethodLevel;
    capfeeRemaing: number = 0;
    capfeeAll: number = 0;
    capfeeRateExchange: number = 1;
    capfeeWithoutRateExchange: number = 0;
    constructor({
        settledObjectsStructures,
        method
    }: {
        settledObjectsStructures: BudgetSettledObjectsStructures;
        method: BugdetSettlementMethodLevel;
    }) {
        this.settledObjectsStructures = settledObjectsStructures;
        this.method = method;
        this.capfeeWithoutRateExchange = method.capfee?.capfee ?? 0;
        this.capfeeAll = method.capfee?.capfee ?? 0;
        this.capfeeRateExchange = method.capfee?.capfee_kurs ?? 1;
        this.capfeeRemaing = method.capfee?.capfee ?? 0;
    }
    execute() {
        this.setCapfeeRemaing();
        this.getObjectsSettled();
        this.checkIsCapfeeRemaingIsNotGratterThanCapfeeAll();
        this.checkIsCapfeeIsMinus();
        return this.capfeeRemaing;
    }

    private setCapfeeRemaing() {
        this.capfeeRemaing = this.capfeeWithoutRateExchange * this.capfeeRateExchange;
        this.capfeeAll = this.capfeeWithoutRateExchange * this.capfeeRateExchange;
    }

    private getObjectsSettled() {
        if (this.method.years) {
            this.method.settledElements.forEach((element) => {
                element.objects.forEach((object) => {
                    this.getObjectSettled(object, element.code);
                });
            });
            const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] = Object.entries(
                this.method.settledSingleElements ?? {}
            ) as [SettledObjectsCodes, SettleObject][];
            settledSingleElementsArr.forEach(([code, object]) => {
                this.getObjectSettled(object, code);
            });
            this.method.years.forEach((year) => {
                if (year.months) {
                    year.months.forEach((month) => {
                        if (month.hoursLimit) {
                            this.getLimitSettled(month.hoursLimit);
                        }
                        month.cases.forEach((element) => {
                            element.settledElements.forEach((settledElement) => {
                                settledElement.objects.forEach((object) => {
                                    this.getObjectSettled(object, settledElement.code);
                                });
                            });
                        });
                        month.projects.forEach((element) => {
                            element.settledElements.forEach((settledElement) => {
                                settledElement.objects.forEach((object) => {
                                    this.getObjectSettled(object, settledElement.code);
                                });
                            });
                        });
                        month.settledElements.forEach((element) => {
                            element.objects.forEach((object) => {
                                this.getObjectSettled(object, element.code);
                            });
                        });
                        const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] =
                            Object.entries(month.settledSingleElements ?? {}) as [
                                SettledObjectsCodes,
                                SettleObject
                            ][];
                        settledSingleElementsArr.forEach(([code, object]) => {
                            this.getObjectSettled(object, code);
                        });
                    });
                }
            });
        }
    }

    private getObjectSettled(object: SettleObject, code: SettledObjectsCodes) {
        if (object.settled && !object.settledInThisSettlingObject) {
            const codes = budgetStableCodesForTotalCalc[code];
            const rateToExchange: number =
                codes.kurs != null && NumberManager.isNumber(object.values[codes.kurs])
                    ? NumberManager.getSafeNumberFromString(object.values[codes.kurs])
                    : 1;
            const numberUnit =
                object.values[codes.liczba] ??
                this.getValueFromFieldStructure(code, codes.liczba) ??
                1;
            const price =
                object.values[codes.cena] ?? this.getValueFromFieldStructure(code, codes.liczba);
            const value = NumberManager.round(
                Number(numberUnit) * Number(price) * rateToExchange,
                2
            );
            this.capfeeRemaing = this.capfeeRemaing - value;
        }
    }

    private getLimitSettled(limitData: HoursLimitData) {
        if (limitData.settled && !limitData.settledInThisSettlingObject) {
            const rateToExchange = limitData['limit_godzin_kurs'] ?? 1;
            const numberUnit = limitData['limit_godzin_ilosc'] ?? 1;
            const price = limitData['limit_godzin'] ?? 1;
            let value = NumberManager.round(
                Number(numberUnit) * Number(price) * Number(rateToExchange),
                2
            );
            if (value > 0) {
                value = value * -1;
            }
            this.capfeeRemaing = this.capfeeRemaing - value;
        }
    }

    private getValueFromFieldStructure(code: SettledObjectsCodes, codeField: string) {
        const fields = this.settledObjectsStructures[code].fields;
        const field = fields.find((field) => field.code === codeField);
        if (field?.value) {
            return field?.value;
        }
        return null;
    }

    private checkIsCapfeeIsMinus() {
        if (this.capfeeRemaing < 0) {
            this.capfeeRemaing = 0;
        }
    }

    private checkIsCapfeeRemaingIsNotGratterThanCapfeeAll() {
        if (this.capfeeRemaing > this.capfeeAll) {
            this.capfeeRemaing = this.capfeeAll;
        }
    }
}
