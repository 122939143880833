import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_START_POINT_SET_KEY } from 'src/constants';
import { useAppDispatch } from 'src/store';
import { setLoggedOffUser } from 'src/store/src/general';
import { closePopupAll } from 'src/store/src/popup';

export const useLogout = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        await fetch('logout.php');
        navigate('/login');
        dispatch(setLoggedOffUser());

        sessionStorage.removeItem(NAVIGATION_START_POINT_SET_KEY);
        localStorage.removeItem('GT');

        dispatch(closePopupAll());
    }, [dispatch, navigate]);
};
