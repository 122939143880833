import React, { FC } from 'react';

import { NormalScroll } from 'src/components/shared/molecules/normalScroll/NormalScroll';
import styles from './PopupTemplateFour.module.css';

type Props = {
    maxWidth: number;
    maxHeight: number;
};

export const PopupTemplateFour: FC<Props> = ({ children, maxHeight, maxWidth }) => {
    return (
        <NormalScroll
            width={`${maxWidth}px`}
            height="fit-content"
            styleOuterWrapper={{ maxWidth: `${maxWidth}px`, maxHeight: `${maxHeight}px` }}>
            <div className={styles.box} onClick={(e) => e.stopPropagation()}>
                <div className={styles.box2}>{children}</div>
            </div>
        </NormalScroll>
    );
};
