import React, { FC, useRef, useState, useCallback, useEffect } from 'react';
import { ThemeCompatibleSvgFileNameIconProps } from './types';
import ThemeCompatibleSvgIcon from 'src/components/shared/atoms/themeCompatibleSvgIcon/ThemeCompatibleSvgIcon';
import { useDynamicSVGImport } from 'src/hooks';
import { SvgFromText } from '../svgFromText/SvgFromText';
import { simpleQueryAnyText } from 'src/api/src/simpleQueryAnyText';

/**
 * Provide easy way to act with SVG icons, handle applying color and fontSize to icon, compatible
 * with mui theme
 */

const LOADING_STATES = {
    initial: 0,
    importedSvg: 1,
    fetchedSvg: 2
};

const ThemeCompatibleSvgFileNameIcon: FC<ThemeCompatibleSvgFileNameIconProps> = ({
    svgFileName,
    ...props
}) => {
    const svgIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
    const [_, setLoadingState] = useState<number>(LOADING_STATES.initial);
    // only to get rid of Warning: Can't perform a React state update on an unmounted component.
    const componentMounted = useRef(true);

    useEffect(() => {
        componentMounted.current = true;
        return () => {
            componentMounted.current = false;
        };
    }, []);

    const loadSvgIconFromFetch = useCallback(async () => {
        const text = await simpleQueryAnyText({
            url: `grafika_new/svg_icons/${svgFileName}`,
            additionalFetchinit: {
                cache: 'force-cache'
            }
        });
        if (text && componentMounted.current) {
            svgIconRef.current = (props) => <SvgFromText text={text} {...props} />;
            setLoadingState(LOADING_STATES.fetchedSvg);
        }
    }, [svgFileName]);

    const svgImportEndCallback = useCallback(() => {
        if (!svgIconRef.current) {
            loadSvgIconFromFetch();
            return;
        }
        if (componentMounted.current) {
            setLoadingState(LOADING_STATES.importedSvg);
        }
    }, [loadSvgIconFromFetch]);

    const startLoading = useCallback(() => {
        setLoadingState(LOADING_STATES.initial);
    }, [setLoadingState]);

    useDynamicSVGImport(svgFileName, svgIconRef, {
        onCompleted: svgImportEndCallback,
        onStart: startLoading
    });

    return svgIconRef.current ? (
        <ThemeCompatibleSvgIcon
            SvgComponent={svgIconRef.current as React.FC<React.SVGProps<SVGSVGElement>>}
            {...props}
        />
    ) : (
        <div></div>
    );
};

export default ThemeCompatibleSvgFileNameIcon;
