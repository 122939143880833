import isToday from 'date-fns/isToday';
import getDay from 'date-fns/getDay';
import endOfDay from 'date-fns/endOfDay';
import isBefore from 'date-fns/isBefore';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import addDays from 'date-fns/addDays';
import startOfToday from 'date-fns/startOfToday';
import isSameDay from 'date-fns/isSameDay';
import getYear from 'date-fns/getYear';
import { CalendarDays, When } from '../../../store/src/calendar/calendar/types';
import { DAYS_OF_WEEK } from '../../../constants';
import { getHolidaysForYear } from './getHolidaysForYear';

export function whenIsDate(date: Date | number): When {
    return isToday(date) ? 'today' : startOfToday() > date ? 'past' : 'future';
}

/**
 * Function takes two dates as Date objects or timestamps and returns array with day of the month number and day of week string
 * for each day in range
 */
export function getDaysRange(
    startDate: Date | number,
    endDate: Date | number,
    selectedDate: Date | number = Date.now()
): CalendarDays {
    if (isBefore(endDate, startDate)) throw new TypeError('End date must not be before start date');
    const daysDiff = differenceInCalendarDays(endDate, startDate);

    /* Day of the week for first day in range */

    const days: CalendarDays = [];
    const holidays = getHolidaysForYear(getYear(selectedDate));

    for (let i = 0; i <= daysDiff; i++) {
        const currentDay = addDays(startDate, i);
        // either sunday or one of holiday list
        const holiday = Boolean(
            getDay(currentDay) === 0 ||
                holidays[currentDay.getDate() + '.' + (currentDay.getMonth() + 1)]
        );

        days.push({
            number: currentDay.getDate(),
            weekDay: DAYS_OF_WEEK[getDay(currentDay)],
            when: whenIsDate(currentDay),
            selected: isSameDay(currentDay, selectedDate),
            startTimestamp: currentDay.getTime(),
            endTimestamp: endOfDay(currentDay).getTime(),
            month: currentDay.getMonth(),
            holiday
        });
    }
    return days;
}
