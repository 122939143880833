export const cityMock = {
    id: '',
    text: '',
    name: '',
    fullName: ''
};

export const postCodeMock = {
    id: '',
    text: '',
    code: '',
    ulica: '',
    gmina: '',
    powiat: '',
    wojewodztwo: '',
    codeFull: ''
};
