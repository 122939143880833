import React, { FC } from 'react';
import PopupTemplateHead from 'src/components/shared/molecules/popupTemaplateHead/PopupTemplateHead';
import styles from './PopupTemplateThird.module.css';
/**
 * Describes main menu, handle all mechanics related to menu data
 */

type Props = {
    title: string;
};

const PopupTemplateThird: FC<Props> = ({ children, title }) => {
    return (
        <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
            <PopupTemplateHead title={title} />
            <div className={styles.box}>{children}</div>
        </div>
    );
};

export default PopupTemplateThird;
