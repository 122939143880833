import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

/**
 * Delete object/objects from api
 */
export async function unlockObject(
    screen: string,
    objectId: string
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        `api_react/src/popupFormExtended/unlockObject.php?screen=${screen}&objectId=${objectId}`,
        'POST'
    );

    // return {
    //     message: 'Odblokowano',
    //     success: true,
    //     errorCode: null,
    //     data: null
    // };
}
