import React, { FC, useCallback } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldText } from 'src/components/popupFormExtended/dynamicField/fieldText/UIFieldText';
import { FieldTextObjectProps } from 'src/components/popupFormExtended/dynamicField/fieldText/types';
import { InputValidator } from 'src/components/popupFormExtended/dynamicField/shared/InputValidator';
import { dataSetInputKey } from 'src/constants/dataset';

export const FieldTextObject: FC<FieldTextObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        const checkedValue = new InputValidator({
            dynamicFieldT,
            oldValue: state,
            staticActions: ['runSize'],
            newValue: e.target.value
        }).execute();

        setState(checkedValue);

        if (onBlur) {
            dispatchToState(checkedValue);
        }
    };

    const onClear = () => {
        dispatchToState(null);
    };

    const dispatchToState = (value: string | null) => {
        dispatch({
            type: 'tekst',
            payload: {
                code: keyOfFieldInState,
                value: {
                    ...field,
                    value: value
                }
            }
        });
    };

    const onAcceptValue = useCallback(() => {
        setState(field.valueToAccept);
        dispatch({ type: 'accept_value', payload: keyOfFieldInState });
    }, [dispatch, field.valueToAccept, keyOfFieldInState]);

    React.useEffect(() => {
        if (JSON.stringify(field.value) !== JSON.stringify(state)) {
            setState(field.value);
        }
    }, [field.value]);

    return (
        <UIFieldText
            value={state}
            onChange={(e) => onChange(e, false)}
            onBlur={(e) => onChange(e, true)}
            onClear={() => onClear()}
            onAcceptValue={onAcceptValue}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
