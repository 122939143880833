import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldTextWithPrefix } from 'src/components/popupFormExtended/dynamicField/fieldTextWithPrefix/UIFieldTextWithPrefix';
import { FieldTextWithPrefixObjectProps } from './types';
import { dataSetInputKey } from 'src/constants/dataset';

export const FieldTextWithPrefixObject: FC<FieldTextWithPrefixObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [textStatte, setTextState] = React.useState(field.value.text);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        setTextState(e.target.value);
        if (onBlur) {
            dispatch({
                type: 'tekst_z_przedrostkiem',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: {
                            ...field.value,
                            text: e.target.value
                        }
                    }
                }
            });
        }
    };

    return (
        <UIFieldTextWithPrefix
            value={{ text: textStatte, prefix: field.value.prefix }}
            onChange={(e) => onChange(e, false)}
            onBlur={(e) => onChange(e, true)}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
