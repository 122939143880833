import { FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import { BudgetFormStateRHF } from 'src/hooks/src/budget/useFormBudget';
import { BudgetScreenState } from 'src/store/src/budget/budget/types';

export class BudgetApiFormDataCreatorUtils {
    budgetState: BudgetScreenState;
    dataState: BudgetFormStateRHF;

    constructor(budgetState: BudgetScreenState, dataState: BudgetFormStateRHF) {
        this.budgetState = budgetState;
        this.dataState = dataState;
    }

    protected loopThroughDataStateMain(
        callback: (
            keyOfField: string,
            fieldValue: FieldsStateUnionRHF,
            keyIdSplitted: string[]
        ) => void
    ) {
        for (const methodId in this.dataState.main) {
            if (typeof this.dataState.main[methodId] !== 'object') {
                continue;
            }

            for (const keyOfField in this.dataState.main[methodId]) {
                const fieldValue = this.dataState.main[methodId][keyOfField];
                if (typeof fieldValue !== 'object') {
                    continue;
                }

                if (!('keyId' in fieldValue) || !fieldValue.keyId) {
                    continue;
                }

                const keyId = fieldValue.keyId;
                const keyIdSplitted = keyId.split('!');

                callback(keyOfField, fieldValue, keyIdSplitted);
            }
        }
    }
}
