import { JS_TIMESTAMP_HOUR } from 'src/constants/timestamps';
import { DynamicStateFormFields } from './../../../components/shared/molecules/dynamicForm/types.d';
import { TemplateMainItemReturn } from './TemplateMainItem';

export function generateValueFieldFromTemplate<T extends DynamicStateFormFields>({
    templateMainItemReturn,
    data
}: {
    templateMainItemReturn: TemplateMainItemReturn;
    data: T;
}): T {
    switch (data.type) {
        case 'data_godziny': {
            const tempData = data;
            if (templateMainItemReturn.sign.includes('@')) {
                const { rozp, zak } = setTimeRozpZak(templateMainItemReturn.contentClear);
                tempData.value = {
                    data: new Date(),
                    rozp: new Date(rozp).toLocaleTimeString('pl', {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    zak: new Date(zak).toLocaleTimeString('pl', {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                };
            }
            return tempData;
        }
        case 'data_godzina': {
            const tempData = data;
            if (templateMainItemReturn.sign.includes('@')) {
                const timestamp = setTimeHour(templateMainItemReturn.contentClear);
                tempData.value = {
                    date: new Date(timestamp),
                    hour: new Date(timestamp).toLocaleTimeString('pl', {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                };
            }
            return tempData;
        }
        default:
            return data;
    }
}

function setTimeHour(contentClear: string) {
    let timestamp = new Date().getTime();
    const contentSplited = contentClear.split('+');
    if (contentClear) {
        if (contentClear === 'now') return new Date().getTime();
        else {
            if (contentSplited[1] && typeof contentSplited[1] === 'string') {
                const partTimestamp = Number(contentSplited[1].slice(0, -1)) * JS_TIMESTAMP_HOUR;
                if (!isNaN(partTimestamp)) {
                    timestamp = new Date(new Date().getTime() + partTimestamp).getTime();
                }
            }
        }
    }
    return timestamp;
}

function setTimeRozpZak(contentClear: string) {
    const contentSplited = contentClear.split('&');
    let rozp = new Date().getTime();
    let zak = new Date().getTime();

    rozp = setTime(contentSplited[0]);
    zak = setTime(contentSplited[1]);

    return {
        rozp,
        zak
    };
}

function setTime(str: string) {
    let timestamp = new Date().getTime();

    if (str) {
        if (str === 'now') return new Date().getTime();
        else {
            if (str.includes('h')) {
                const partTimestamp = Number(str.slice(0, -1)) * JS_TIMESTAMP_HOUR;
                if (!isNaN(partTimestamp)) {
                    timestamp = new Date(new Date().getTime() + partTimestamp).getTime();
                }
            }
        }
    }

    return timestamp;
}
