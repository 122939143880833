import React, { FC, useMemo } from 'react';

import styles from './ColumnLeftRight.module.css';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { Field } from 'src/components/popupFormExtended/field/Field';
import { FieldSizer } from 'src/components/popupFormExtended/fieldSizer/FieldSizer';
import { ColumnLeftRightProps } from 'src/components/popupFormExtended/columnLeftRight/types';
import { UiFieldCommonProps } from '../dynamicField/types';

export const ColumnLeftRight: FC<ColumnLeftRightProps> = ({
    column,
    fieldWidth,
    keyPopup,
    usedForAddingObject
}) => {
    const uiFieldCommonProps: UiFieldCommonProps = useMemo(
        () => ({
            isLabel: true,
            usedForAddingObject: usedForAddingObject,
            maxWidth: fieldWidth
        }),
        [usedForAddingObject, fieldWidth]
    );

    return (
        <div className={styles.column}>
            {column.fields?.map((field: DynamicFieldT) => (
                <FieldSizer key={field.id}>
                    <Field
                        keyPopup={keyPopup}
                        dynamicFieldT={field}
                        keyOfFieldInState={field.kod}
                        usedForAddingObject={usedForAddingObject}
                        uiFieldCommonProps={uiFieldCommonProps}
                    />
                </FieldSizer>
            ))}
        </div>
    );
};
