import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldBankAccountNumber } from './UIFieldBankAccountNumber';
import { FieldBankAccountNumberObjectProps } from './types';
import { dataSetInputKey } from 'src/constants/dataset';

export const FieldBankAccountNumberObject: FC<FieldBankAccountNumberObjectProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        setState(e.target.value);

        if (onBlur) {
            dispatch({
                type: 'numer_rachunku_bankowego',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: e.target.value
                    }
                }
            });
        }
    };

    return (
        <UIFieldBankAccountNumber
            value={state}
            onChange={(e) => onChange(e, false)}
            onBlur={(e) => onChange(e, true)}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
