import React, { FC } from 'react';
import { Typography, Box } from '@mui/material';
import { ErrorPageProps } from './types';

/**
 * Display error message to user eg. invalid url
 */
const ErrorPage: FC<ErrorPageProps> = ({ message }) => {
    return (
        <Box width={1} display="flex" alignItems="center" justifyContent="center">
            <Typography color="error" fontSize="large" m={4}>
                {message}
            </Typography>
        </Box>
    );
};

export default ErrorPage;
