import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { LoginApiResponse } from './types';

export async function loginUser(
    login: string,
    password: string
): Promise<BaseApiResponse<LoginApiResponse>> {
    const bodyPayload = new FormData();
    bodyPayload.append('login', login);
    bodyPayload.append('password', password);

    return simpleQueryAPIData(
        'api_react/src/loginScreen/login.php',
        'POST',
        bodyPayload,
        false,
        undefined,
        undefined,
        false
    );
}
