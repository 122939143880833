import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiRaportData } from './types';

export async function fetchRaportInitData(obiekt: string): Promise<BaseApiResponse<ApiRaportData>> {
    const params = new URLSearchParams([['obiekt', obiekt]]);
    return simpleQueryAPIData(
        `api_react/src/raportScreen/getInitialData.php?${params.toString()}`,
        'GET'
    );
}
