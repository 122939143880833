import * as actionTypes from '../../constants/actions';
export const initialState = {};

export default function appReducer(state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case actionTypes.RESET_APP_STATE: {
            return { ...initialState };
        }

        case actionTypes.OPEN_CHAT: {
            const { chat } = payload;

            return {
                ...state,
                [chat.id]: chat
            };
        }

        case actionTypes.RESET_CHAT: {
            const { roomID } = payload;
            const newState = { ...state };
            delete newState[roomID];

            return newState;
        }

        case actionTypes.APPEND_CHAT_MESSAGE: {
            const { roomID, message } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    messages: [...state[roomID].messages, message]
                }
            };
        }

        case actionTypes.PREPEND_CHAT_MESSAGES: {
            const { roomID, messages, index } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    index,
                    messages: [...messages, ...state[roomID].messages]
                }
            };
        }

        case actionTypes.SET_BOTTOM_OFFSET: {
            const { roomID, value } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    bottomOffset: value
                }
            };
        }

        case actionTypes.SET_SCROLLED_UP: {
            const { roomID, boolean } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            const newMessage = boolean ? state[roomID].newMessage : false;

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    scrolledUp: boolean,
                    newMessage: newMessage
                }
            };
        }

        case actionTypes.SET_CHAT_WINDOW_LOADING: {
            const { roomID, boolean } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    loading: boolean
                }
            };
        }

        case actionTypes.REPLACE_MESSAGE: {
            const { roomID, message } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            const messages = state[roomID].messages;
            const i = messages.findIndex((m) => m.id === message.id);
            const msgs = messages.slice(0);

            msgs[i] = message;

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    messages: msgs
                }
            };
        }

        case actionTypes.REPLACE_MESSAGES_ID: {
            const { roomID, idsArr } = payload;
            const chat = state[roomID];

            if (!chat) {
                return state;
            }

            const messages = chat.messages;
            let newMsgs = [],
                newLastRead = [];
            const lastReadMessages = chat.lastReadMessages;

            /* aktualizuj lastReadMessages */
            lastReadMessages.forEach((lastRead) => {
                const idsObj = idsArr.find((obj) => lastRead.messageID === obj.old);
                if (idsObj) {
                    return newLastRead.push({ messageID: idsObj.new, roomID: lastRead.roomID });
                }

                newLastRead.push(lastRead);
            });

            messages.forEach((m) => {
                const idsObj = idsArr.find((obj) => obj.old === m.id);

                if (idsObj) {
                    const msg = { ...m, id: idsObj.new };
                    return newMsgs.push(msg);
                }

                newMsgs.push(m);
            });

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    messages: newMsgs,
                    lastReadMessages: newLastRead
                }
            };
        }

        case actionTypes.SET_NEW_MESSAGE: {
            const { roomID, boolean } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    newMessage: boolean
                }
            };
        }

        case actionTypes.SET_CHAT_WINDOW_MINIMIZED: {
            const { roomID, boolean } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    minimized: boolean
                }
            };
        }

        case actionTypes.SET_LAST_READ: {
            const { roomID, lastRead } = payload;

            if (!roomID || !state[roomID]) {
                return state;
            }

            return {
                ...state,
                [roomID]: {
                    ...state[roomID],
                    lastReadMessages: lastRead
                }
            };
        }

        case actionTypes.UPDATE_LAST_READ: {
            const { roomID, chatRoomID, lastReadID } = payload;

            if (!chatRoomID || !state[chatRoomID]) {
                return state;
            }
            const lastRead = state[chatRoomID].lastReadMessages.map((lr) => ({
                roomID: lr.roomID,
                messageID: lr.roomID === roomID ? lastReadID : lr.messageID
            }));

            return {
                ...state,
                [chatRoomID]: {
                    ...state[chatRoomID],
                    lastReadMessages: lastRead
                }
            };
        }

        default: {
            return state;
        }
    }
}

/**
 * @description - Wycinek stanu aplikacji. Słownik czatów gdzie kluczami są id czatów a wartościami obiekty czatów
 * @typedef {Object.<string, chat>} Store.ChatsSlice
 * @property {object} [chat] - . pojedyńczy czat (może ich nie być w ogóle)
 * @property {string|null} chat.name - nazwa czatu (dostępna tylko dla czatów grupowych)
 * @property {'private'|'group'} chat.type - część id typ czatu prywatna rozmowa lub czat grupowy
 * @property {number} chat.chatID - część id identyfikator czatu w bazie danych
 * @property {string} chat.id - id czatu - chat.type + chat.chatID np "private3" lub 'group4'
 * @property {string} chat.windowID - identyfikator okna w którym otwarty jest czat, w przypadku czatów grupowych windowID = chat.id, w przypadku prywatnych równa się id użytkownika z którym prowadzisz konwersację
 * @property {string} chat.creator - id osoby która założyła czat
 * @property {number|null} chat.index - id pierwszej załadowanej wiadomości, lub null jeśli pierwsza załadowana wiadomość jest jednocześnie pierwszą wiadomością czatu
 * @property {boolean} scrolledUp - informacja czy okno czatu zostało przeskrolowane do góry
 * @property {number} bottomOffset - informacja o jaką ilośc pikseli od dołu zostało przeskrolowane okno czatu
 * @property {boolean} newMessage - informacja czy w otwartym oknie czatu znajdują się niezobaczone przez użytkownika wiadomości
 * @property {boolean} loading - stan ładowania okna czatu
 * @property {boolean} minimized - stan zminimalizowania czatu
 * @property {Message[]} messages - tablica z wiadomościami [Message]{@link Store.Message}
 */

/**
 * @description - Pojedyńczy element [ChatSlice.messages]{@link Store.ChatsSlice}
 * @typedef {Object} Store.Message
 * @property {number} id - identyfikator wiadomości w bazie danych
 * @property {number} chatID - identyfikator czatu w bazie danych
 * @property {string} userType - część roomID, type użytkownika który stworzył wiadomość
 * @property {number} userID - część roomID, userID użytkownika który stworzył wiadomość
 * @property {string} roomID - roomID  userType + userID np: "pracownicy3"
 * @property {'notification'|'deleted'|'text'|'image'|'file'} type - typ wiadomości
 * @property {string} content - treść wiadomości
 * @property {number} created_at - timestamp stworzenia wiadomości
 * @property {number|null} modified_at - timestamp modyfikacji wiadomości
 * @property {string|null} fileName - tylko dla typów 'image' i 'file', string pozwalający serwerowi zidentyfikować plik
 */
