import { UiFieldFlags } from 'src/components/popupFormExtended/dynamicField/types';

export function passOnlyFlag(data: { [key: string]: any } & UiFieldFlags) {
    const flags: UiFieldFlags = {};
    for (const key in data) {
        if (data) {
            if (
                key === 'archived' ||
                key === 'isError' ||
                key === 'disabled' ||
                key === 'disabledWew'
            ) {
                flags[key] = data[key];
            }
        }
    }
    return flags;
}
