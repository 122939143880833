import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import {
    BrowserPageLocationState,
    ConvertableToParams,
    PageLocationState,
    ValidLocationState
} from 'src/data/types';
import { AppThunk } from 'src/store';
import { getActualURLParamsAsObject } from 'src/utils';

/**
 * subscribe component to location state changes and dispatch given setToStore action at every
 * change, this enable to have store up to date with navigation back and forth in history. It wise
 * to use this component at pages who are Route child and describe page using state
 *
 * @param setToStore Thunk updating store by given PageLocationState, should set to store initial
 * store values if some values in locationdata are not present. setToStore function get
 * locationData to load and Location object in case they have need of modification location.state
 */
export const useSubscribeToLocationState = <
    K extends BrowserPageLocationState<
        PageLocationState<ConvertableToParams, ConvertableToParams, any>
    >
>(
    setToStore: (locationData: K, navigate: NavigateFunction) => AppThunk
) => {
    const ifLocationStateValid = (
        locationState: unknown
    ): locationState is ValidLocationState<K> => {
        return (
            typeof locationState == 'object' &&
            locationState != null &&
            'legacyParams' in locationState &&
            'store' in locationState
        );
    };

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // run only when can set to store
        if (!dispatch) return;

        // console.info('useSubscribeToLocationState(): updating from PLS, location:', location);
        // recieve data from location and parameters
        const paramsObject = getActualURLParamsAsObject();

        const setToStoreObject = {
            params: paramsObject as K['params'],
            legacyParams: {},
            store: {}
        } as K;
        // console.info('useSubscribeToLocationState(): locationStore valid check', location.state);
        if (ifLocationStateValid(location.state)) {
            setToStoreObject.legacyParams = location.state.legacyParams as K['legacyParams'];
            setToStoreObject.store = location.state.store as K['store'];
        }

        dispatch(setToStore(setToStoreObject, navigate));
    }, [location, setToStore, dispatch]);
};
