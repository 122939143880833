import React, { FC, useMemo } from 'react';
import { getLegacyCompatibleUrlData } from 'src/utils';
import QueryLink from 'src/components/shared/atoms/queryLink/QueryLink';
import { LegacyCompatibleLinkProps } from './types';

/**
 * Wrapper component for custom link navigation compatible with legacy php pages and react-router
 */
const LegacyCompatibleLink: FC<LegacyCompatibleLinkProps> = ({
    url,
    queryParams,
    children,
    ...props
}) => {
    const cleanedLinkData = useMemo(() => {
        return getLegacyCompatibleUrlData(url, queryParams);
    }, [url, queryParams]);

    return (
        <QueryLink url={cleanedLinkData.url} queryParams={cleanedLinkData.queryParams} {...props}>
            {children}
        </QueryLink>
    );
};

export default LegacyCompatibleLink;
