import React, { FC } from 'react';
import { ObjectProps } from './types';
import { useLoadScreenParamPageTitle, useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const ObjectView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ObjectView" */ 'src/components/object/templates/objectView/ObjectView'
        )
);

const ObjectItem: FC<ObjectProps> = () => {
    useRunAllHooksOnEachRenderReactPage();
    useLoadScreenParamPageTitle();

    return (
        <div>
            <React.Suspense fallback>
                <ObjectView />
            </React.Suspense>
        </div>
    );
};

export default ObjectItem;
