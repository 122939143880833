import { RefObject, useLayoutEffect, useRef, useEffect } from 'react';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import { CalendarView } from '../../../store/src/calendar/calendar/types';
import { CELL_SIZE } from '../../../styles/sizes';
import { AppDispatch, setSelectedRow } from '../../../store/src/calendar';

const maxStep = 100;
const minStep = 20;

/**
 * Hook is responsible for smooth scrolling passed Html ref to calendar cell corresponding to current hour, or selectedRowValue
 */
export function useScrollToRow(
    dispatch: AppDispatch,
    ref: RefObject<HTMLElement>,
    view: CalendarView,
    selectedRow: number | null
): void {
    // create viewRef
    const viewRef = useRef<CalendarView | undefined>();

    // handle viewRef to get offect of usePrevious
    // this enables to compare current view and previous view
    useEffect(() => {
        viewRef.current = view;
    }, [view]);

    useLayoutEffect(() => {
        const el = ref.current;
        /* Reset scroll */
        if (el) el.scrollTop = 0;
        /* Calculate how many pixels to scroll */
        const now = new Date();
        /* Calculate one calendar cell height */
        const cellHeight = CELL_SIZE;
        /* Either passed row or current time, minus some padding */
        const cellsToScroll = selectedRow
            ? selectedRow - 2
            : (getHours(now) - 2) * 2 + (getMinutes(now) < 30 ? 0 : 1);
        const distance = cellsToScroll * cellHeight - 10;

        function smoothScroll(dist: number, step: number) {
            if (el) {
                /* Accelerate until 0.3 of distance is reached */
                if (dist / distance > 0.7 && step <= maxStep) step += 8;
                /* Deccelerate when 0.8 of distance is reached */
                if (dist / distance < 0.2 && step > minStep) step -= 12;
                el.scrollTop = el.scrollTop + step;
                dist -= step;
                /* Call scroll again until distance is reached or have scrolled to end of container */
                if (dist > 0 && el.scrollTop + el.clientHeight < el.scrollHeight - 10)
                    window.requestAnimationFrame(() => smoothScroll(dist, step));
                /* Last time adjust position */ else el.scrollTop = distance;
            }
        }

        if (view !== viewRef.current) {
            window.requestAnimationFrame(() => smoothScroll(distance, 30));
        }

        // reset selected row
        if (selectedRow) dispatch(setSelectedRow(null));
    }, [dispatch, view, ref, selectedRow, viewRef]);
}
