import React, { FC } from 'react';

import { UIFieldListDependentProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

export const UIFieldListDependent: FC<UIFieldListDependentProps> = (props) => {
    const { value, opcje, onChange, uiFieldCommonProps, uiFieldSharedProps } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <UiInputSelectTwo
                {...uiFieldCommonProps}
                value={value}
                opcje={opcje}
                onChange={onChange}
                inputProps={uiFieldSharedProps.inputProps}
            />
        </UiWrapper>
    );
};
