import React, { FC } from 'react';

import styles from './FieldSizer.module.css';
import { FieldSizerProps } from 'src/components/popupFormExtended/fieldSizer/types';
import { useCalculateMinHeight } from 'src/hooks';
import { isSafari } from 'react-device-detect';

export const FieldSizer: FC<FieldSizerProps> = ({ children }) => {
    const { ref, minHeight } = useCalculateMinHeight<HTMLDivElement>(isSafari ? 53 : 50);

    return (
        <div
            className={styles.wrapper}
            style={{
                minHeight: minHeight + 'px'
            }}>
            <div ref={ref}>{children}</div>
        </div>
    );
};
