import * as React from 'react';

import { FieldsPure } from 'src/components/popupFormExtended/dynamicField/types';
import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFormObjectDateValidatorProps, TypeValidateDate } from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { getValuesFromFields } from 'src/utils';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateDate';
// mozna zweryfikowac przy dodawaniu zadania na devvps4 przy ustawieniu daty wczesniej niz aktualna
export class DynamicFormObjectDateValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateDate;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectDateValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        for (const key in this.formState.fields) {
            if (this.dataRef.validationResult.isFormValid) {
                const dynamicFieldT = this.popupFormExtendedData.fields.find(
                    (item) => item.kod === this.formState.fields[key]?.kod
                );
                const field = this.formState.fields[key];
                if (
                    dynamicFieldT &&
                    field &&
                    (dynamicFieldT.typ === 'data' ||
                        dynamicFieldT.typ === 'data_godzina' ||
                        dynamicFieldT.typ === 'data_godziny') &&
                    (dynamicFieldT.minimalna_wartosc != 0 ||
                        dynamicFieldT.minimalna_wartosc_ten_dzien === 't')
                ) {
                    await this.runValidationOne(field, key);
                }
            } else {
                break;
            }
        }
    }
    private async runValidationOne(field: FieldsPure, key: string) {
        const data = getValuesFromFields(this.formState.fields[field.kod]);

        const params = {
            id_obiektu: this.fieldRelatedObjectData.id,
            ekran: this.popupFormExtendedData.screen,
            kod_pola: field?.kod,
            wartosc: String(data.timestampAll / 1000)
        };

        const res = await getFieldAdditionalData({
            url: 'json_sprawdz_date.php',
            method: 'GET',
            params: params
        });

        if (res.data && !res.data.valid) {
            this.setValidationResult({
                typeOfInfo: 'Error',
                type: {
                    issue: this.typeFnValidate,
                    message: res.data.message,
                    title: validationData[this.typeFnValidate].title,
                    id_zakladki: data.id_zakladki
                }
            });
            this.setError(key, field);
        }
    }
}
