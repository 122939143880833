import { useState, useEffect, useRef } from 'react';

export function useFlagTimeout(time: number = 1000, defaultFlag: boolean = false) {
    const isMountingRef = useRef(false);
    const [flag, setFlag] = useState<boolean>(defaultFlag);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (isMountingRef.current) {
            timeout = setTimeout(() => {
                setFlag(defaultFlag);
            }, time);
        }
        isMountingRef.current = true;
        return () => {
            clearTimeout(timeout);
        };
    }, [time, defaultFlag, flag]);

    return {
        setFlag,
        flag
    };
}
