import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldLocationObjectProps } from './types';
import { UIFieldLocation } from './UIFieldLocation';
import { dataSetInputKey } from 'src/constants/dataset';
import { FieldLocationBasicTypeValue } from 'src/data/fieldsFormType';

export const FieldLocationObject: FC<FieldLocationObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        property: keyof FieldLocationBasicTypeValue
    ) => {
        setState((prevState) => ({
            ...prevState,
            [property]: e.target.value
        }));
    };

    return (
        <UIFieldLocation
            value={state}
            onChange={onChange}
            onBlur={(_) => {
                dispatch({
                    type: 'polozenie',
                    payload: {
                        code: keyOfFieldInState,
                        value: {
                            ...field,
                            value: {
                                latitude: state.latitude,
                                longitude: state.longitude
                            }
                        }
                    }
                });
            }}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
