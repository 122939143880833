import { ConvertableToParams } from 'src/data/types';
import { checkIfValueIsTypedArray } from 'src/utils';

/**
 * get actual params from window.location.search and convert them to OperableToParams
 */
export const getActualURLParamsAsObject: (customParams?: string) => ConvertableToParams = (
    customParams?: string
) => {
    const params = new URLSearchParams(customParams ?? window.location.search);
    const paramsObject: ConvertableToParams = {};

    // save each param in paramsObject
    for (const [key, value] of params.entries()) {
        // to be compatible with name[] format
        if (key.endsWith('[]')) {
            // use keys without [] in object
            const clearKey = key.slice(0, -2);

            // if there is no param with that name add array for values
            if (!(clearKey in paramsObject)) {
                paramsObject[clearKey] = [value];
                continue;
            }

            // if key exists in paramsObject
            const actualValue = paramsObject[clearKey];
            if (checkIfValueIsTypedArray<string>(actualValue)) {
                actualValue.push(value);
            }
            if (checkIfValueIsTypedArray<number>(actualValue)) {
                actualValue.push(parseInt(value));
            }
        } else {
            paramsObject[key] = value;
        }
    }

    // console.info('getActualURLParamsAsObject: paramsObject = ', paramsObject);

    return paramsObject;
};
