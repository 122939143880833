import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import rootReducer from './rootReducer';
import pgBoolMiddleware from '../widgets/shared/middlewares/pgBool';

export type RootState = ReturnType<typeof rootReducer>;
const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(pgBoolMiddleware)
});

export default store;

export const dispatch = store.dispatch;
export const getState = store.getState;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    undefined,
    Action<string>
>;

// export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

/**
 * @namespace Store
 * @category Redux
 * @description Centralny obiekt aplikacji, przechowuje stan, umożliwia kontrolowaną aktualizacje stanu.
 * @see [reducers]{@link https://redux.js.org/basics/reducers}
 * @see [store]{@link https://redux.js.org/basics/store}
 */

/**
 * @namespace Middleware
 * @category Redux
 * @description Funkcje które przekształcają akcje przed przekazaniem ich do store.
 * @see [middlewares]{@link https://redux.js.org/advanced/middleware}
 * @see [Store]{@link Store}
 */
