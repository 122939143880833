import { FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import {
    BugdetSettlementMethodLevel,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { createMemoKey } from 'src/utils/src/budget/createMemoKey';
import { getKeyMonthIdToUpdateMonthsForCaseLimit } from './getKeyMonthIdToUpdateMonthsForCaseLimit';

type SetKeyMemoMonthProps = {
    monthId: string;
};

type SetKeyMemoProps = {
    monthId: string;
    code: SettledObjectsCodes;
    objectId: string;
};

type BudgetHandlerStateManageKeyMemoProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
    method?: BugdetSettlementMethodLevel;
};
export class BudgetHandlerStateManageKeyMemo extends BudgetSetterOperationalState {
    method?: BugdetSettlementMethodLevel;
    constructor({ budget, operationalState, method }: BudgetHandlerStateManageKeyMemoProps) {
        super({ budget, operationalState });
        this.method = method;
    }

    setKeyMonth({ monthId }: SetKeyMemoMonthProps) {
        this.operationalState.fieldsTemp[createMemoKey([monthId])] = {
            liczba: '1'
        };
    }

    setKeyObject({ monthId, code, objectId }: SetKeyMemoProps) {
        this.operationalState.fieldsTemp[createMemoKey([monthId, code, objectId])] = {
            liczba: '1'
        };
    }

    updateKeyMonth(monthId: string) {
        if (this.method) {
            if (this.method.rozliczanielimitgodzin) {
                const monthIdLimitArr = getKeyMonthIdToUpdateMonthsForCaseLimit(
                    this.operationalState.templateKeyMonthIdToUpdateMonthsForCaseLimit,
                    this.method.id,
                    monthId
                );
                for (const monthIdLimit of monthIdLimitArr) {
                    const key = '@' + monthIdLimit;
                    this.updateKeyWew(key);
                }
            } else {
                const key = '@' + monthId;
                this.updateKeyWew(key);
            }
        }
    }
    updateKey(partKey: string[]) {
        const key = createMemoKey(partKey);
        this.updateKeyWew(key);
    }
    updateKeys(monthId: string) {
        for (const key in this.operationalState.fieldsTemp) {
            if (key.includes('@')) {
                const partKey = key.split('@');

                if (partKey[1] === String(monthId)) {
                    this.updateKeyWew(key);
                }
            }
        }
    }

    private updateKeyWew(key: string) {
        const value = this.getValueNumberFromOSByKeyField(key);

        const field: FieldsStateUnionRHF = {
            liczba: String(Number(value) + 1)
        };

        this.operationalState.fieldsTemp[key] = field;
    }
}
