import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ObjectScreenLocationState } from 'src/components/object/pages/object/types';
import { TabId } from 'src/store/src/object/object/types';
import { fetchAndLoadInitialObjectData } from 'src/store/src/object/sharedActions';
import { getSectionParams, savePageLocationState } from 'src/utils';
import { useSafeNavigate } from '../shared/useSafeNavigate';
import { useAppDispatch } from 'src/store';
import { useSearchParam } from '../shared/useSearchParam';

export const useRefreshObjectData = () => {
    // collect hooks
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();
    const location = useLocation();

    const screen = useSearchParam('obiekt');
    const id = useSearchParam('id');

    return useCallback(
        (
            tabId: TabId | null,
            loadTabFromParam: boolean = false,
            saveNewPLS: boolean = true,
            loadSectionsFromParam: boolean = false,
            sectionParams?: string[][]
        ) => {
            if (screen === null || id === null) {
                console.error('useLoadInitialObjectData: obiekt or id params are null');
                return;
            }

            if (loadTabFromParam)
                tabId = new URLSearchParams(window.location.search).get('id_zakladki');
            if (loadSectionsFromParam) sectionParams = getSectionParams();

            dispatch(fetchAndLoadInitialObjectData(screen, id, tabId, sectionParams));

            if (tabId !== null && saveNewPLS) {
                const locationData: ObjectScreenLocationState = {
                    params: { id_zakladki: tabId },
                    legacyParams: {},
                    store: null
                };

                savePageLocationState(locationData, navigate, location.pathname, true);
            }
        },
        [dispatch, location.pathname, navigate, id, screen]
    );
};
