import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { UI } from 'src/utils/src/list/manegersDataForInner/UI';
import styles from './styles.module.css';
import UICheckboxWewSquare from 'src/components/shared/atoms/uiInputs/uiCheckboxWewSquare/UICheckboxWewSquare';
import { PopupContentConfigColumnSectionProps } from './types';

export const PopupContentConfigColumnSection: FC<PopupContentConfigColumnSectionProps> = ({
    ubdatePinnedItem,
    ubdateVisibleItem,
    popupStateContext
}) => {
    const theme = useTheme();

    return (
        <div className={styles.wrapperInner}>
            {popupStateContext.config.propertyCol.map((item) => {
                const isDisabled = popupStateContext.config.limitIsAchieved && !item.isPinned;
                return (
                    <div key={item.code} className={styles.boxOne}>
                        <div
                            style={{
                                border: `1px solid ${
                                    isDisabled ? '#949494' : theme.palette.primary.main
                                } `,
                                cursor: `${isDisabled ? 'default' : 'pointer'}`
                            }}
                            className={styles.boxTwo}
                            onClick={isDisabled ? () => {} : () => ubdatePinnedItem(item)}>
                            {' '}
                            <ThemeCompatibleIcon
                                SvgComponent={UI.chooseIconForPinned(item.statusIconPinned)}
                                sx={{
                                    fontSize: '0.8rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: '1px',
                                    background: `${
                                        item.statusIconPinned === 'isPinned'
                                            ? theme.palette.primary.main
                                            : 'transparent'
                                    }`
                                }}
                            />
                        </div>
                        <UICheckboxWewSquare
                            checked={item.isVisible}
                            onClick={() => ubdateVisibleItem(item)}
                        />
                        <p className={`${styles.p}`}>{item.name}</p>
                    </div>
                );
            })}
        </div>
    );
};
