import React, { FC } from 'react';

import { FieldCheckboxUnmodifiedProps } from 'src/components/popupFormExtended/dynamicField/fieldCheckbox/types';
import { FieldWrapperUnmodified } from '../shared/fieldWrapperUnmodified/FieldWrapperUnmodified';

export const FieldCheckboxUnmodified: FC<FieldCheckboxUnmodifiedProps> = ({
    dynamicFieldT,
    field,
    uiFieldCommonProps
}) => {
    return (
        <FieldWrapperUnmodified
            dynamicFieldT={dynamicFieldT}
            field={field}
            isLabel={Boolean(uiFieldCommonProps?.isLabel)}>
            <p>{field.value ? 'TAK' : 'NIE'}</p>
        </FieldWrapperUnmodified>
    );
};
