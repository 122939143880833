import { SelectOpcje, SelectOption } from 'src/data/uiTypes/selectTypes';

type CreateSelectOptionsProps = {
    obj: SelectOpcje;
    type?: '0' | '-1';
};

export function createSelectOptions({ obj, type }: CreateSelectOptionsProps): SelectOption[] {
    if (type === '0') {
        return Object.entries(obj)
            .sort(([, a], [, b]) => {
                if (a === 'Dodaj...' || b === 'Dodaj...') return 0;
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            })
            .map((item) => {
                return {
                    name: item[1],
                    value: item[0]
                };
            });
    } else {
        return Object.entries(obj).map((item) => {
            return {
                name: item[1],
                value: item[0]
            };
        });
    }
}
