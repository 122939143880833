import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

/**
 * Delete object/objects from api
 */
export async function deleteObject(
    screen: string,
    data: { objectIds: number[] }
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        `api_react/src/shared/deleteObject.php?screen=${screen}`,
        'POST',
        data
    );

    // return {
    //     message: 'Usunięto',
    //     success: true,
    //     errorCode: null,
    //     data: null
    // };
}
