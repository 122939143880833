import {
    BudgetMonthId,
    BugdetSettlementMethodId,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';

export type CreateKeyGlobalSummaryProps = {
    methodId: BugdetSettlementMethodId;
    monthId?: BudgetMonthId;
    settleObjectCode: SettledObjectsCodes;
    objectId: string;
};

export function createKeyGlobalSummary({
    methodId,
    monthId,
    settleObjectCode,
    objectId
}: CreateKeyGlobalSummaryProps) {
    let key = '';
    if (monthId) {
        key = `${settleObjectCode}!${methodId}!${objectId}!${monthId}`;
    } else key = `${settleObjectCode}!${methodId}!${objectId}`;

    return key;
}
