import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PopupTemplateHead from '../../molecules/popupTemaplateHead/PopupTemplateHead';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
/**
 * Describes main menu, handle all mechanics related to menu data
 */
type PropButton = {
    buttonTxt: string;
    onClick: () => void;
    color?: string;
};

type Props = {
    title: string;
    buttonOne: PropButton;
    buttonTwo: PropButton;
    sxFooter?: SxProps<Theme>;
    sxWrapper?: SxProps<Theme>;
    sxBody?: SxProps<Theme>;
};

const PopupTemplateMultiButtons: FC<Props> = ({
    children,
    title,
    buttonOne,
    buttonTwo,
    sxFooter,
    sxWrapper,
    sxBody
}) => {
    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                padding: '40px',
                ...sxWrapper
            }}>
            <PopupTemplateHead title={title} />
            <Box
                sx={{
                    marginTop: '30px',
                    marginBottom: '20px',
                    display: 'flex',
                    justifyItems: 'center',
                    ...sxBody
                }}>
                {children}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    paddingTop: '10px',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    ...sxFooter
                }}>
                <Button
                    variant="contained"
                    sx={{
                        color: (theme) => theme.palette.background.default,
                        padding: '6px 40px',
                        cursor: 'pointer',
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '180px'
                    }}
                    onClick={buttonOne.onClick}>
                    <Typography>{buttonOne.buttonTxt}</Typography>
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        color: (theme) => theme.palette.background.default,
                        padding: '6px 40px',
                        cursor: 'pointer',
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '180px'
                    }}
                    onClick={buttonTwo.onClick}>
                    <Typography>{buttonTwo.buttonTxt}</Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default PopupTemplateMultiButtons;
