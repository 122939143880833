import React, { FC } from 'react';

import { usePopupState } from 'src/context/globalPopup/popupContext';
import styles from './TabsWithoutScroll.module.css';
import { Tabs } from 'src/components/popupFormExtended/tabs/Tabs';
import { TabsWithoutScrollProps } from 'src/components/popupFormExtended/tabsWithoutScroll/types';
import { useComponentSize } from 'react-use-size';

export const TabsWithoutScroll: FC<TabsWithoutScrollProps> = (props) => {
    const { popup } = props;
    const { ref } = useComponentSize();
    const { dispatchPopupStateContext, popupStateContext } = usePopupState(popup.key);

    React.useEffect(() => {
        if (
            ref.current?.clientWidth &&
            popupStateContext.dimensions.width &&
            ref.current?.clientWidth > popupStateContext.dimensions.width
        ) {
            dispatchPopupStateContext({ type: 'SET_TAB_WITH_SCROLL' });
        }
    }, [ref.current?.clientWidth, popupStateContext.dimensions.width]);

    return (
        <div className={styles.box} ref={ref}>
            <Tabs {...props} />
        </div>
    );
};
