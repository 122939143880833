import React from 'react';
import { dispatch } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { clearState } from 'src/store/src/massEditObjectsScreen/massEditObjects/massEditObjectsSlice';
import { fetchAndLoadInitialMassEditObjectsScreenData } from 'src/store/src/massEditObjectsScreen/sharedActions';

export const useLoadInitialMassEditObjectsScreenData = () => {
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setSpinner(true));

            await dispatch(fetchAndLoadInitialMassEditObjectsScreenData());
            dispatch(setSpinner(false));
        };

        loadData();
        return () => {
            dispatch(clearState());
        };
    }, []);
};
