import React, { createContext, Reducer } from 'react';
import thunk from 'redux-thunk';

import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import {
    FormState,
    FormActions,
    DynamicFormDispatch
} from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFormObjectGlobalMiddlewareHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectGlobalMiddlewareHandler';
import { usePopupState } from 'src/context/globalPopup/popupContext';
import { consoleConditionally, createReducer, getNewFieldValueFromValueToAccept } from 'src/utils';

export enum FormActionKind {
    checkbox = 'checkbox',
    lista = 'lista',
    lista_cena = 'lista_cena',
    data_godzina = 'data_godzina',
    data_godziny = 'data_godziny',
    tekst = 'tekst',
    data = 'data',
    powiazanie_typ = 'powiazanie_typ',
    powiazanie_typ_wielokrotne = 'powiazanie_typ_wielokrotne',
    liczba = 'liczba',
    lista_obca = 'lista_obca',
    lista_obca_wielokrotna = 'lista_obca_wielokrotna',
    tekst_bez_ograniczen = 'tekst_bez_ograniczen',
    cena_liczba = 'cena_liczba',
    plik = 'plik',
    pliki_wielokrotne = 'pliki_wielokrotne',
    lista_zalezna = 'lista_zalezna',
    data_wzgledna = 'data_wzgledna',
    cena = 'cena',
    numer = 'numer',
    nip = 'nip',
    haslo_otwarte = 'haslo_otwarte',
    numer_rachunku_bankowego = 'numer_rachunku_bankowego',
    system_data = 'system_data',
    koszty = 'koszty',
    email = 'email',
    login = 'login',
    pesel = 'pesel',
    adres = 'adres',
    emaile_wielokrotne = 'emaile_wielokrotne',
    liczba_rozliczana_do_zera = 'liczba_rozliczana_do_zera',
    update_fields = 'update_fields',
    update_field = 'update_field',
    set_values_to_accept = 'set_values_to_accept',
    update_field_values = 'update_field_values',
    accept_value = 'accept_value',
    set_state = 'set_state',
    reset_state = 'reset_state',
    set_fields = 'set_fields',
    sums_relationship = 'sums_relationship',
    delete_relationship = 'delete_relationship',
    polozenie = 'polozenie',
    tekst_z_przedrostkiem = 'tekst_z_przedrostkiem',
    update_fields_and_sums = 'update_fields_and_sums',
    update_state = 'update_state'
}

export const initialStateDynamicFormObject: FormState = {
    fieldsForeign: {},
    fields: {},
    sumsRelationship: {},
    deleteRelationship: {},
    tabs: {},
    onMountForm: false
};

export const reducer: Reducer<FormState, FormActions> = (state, actionData) => {
    const action = new DynamicFormObjectGlobalMiddlewareHandler({ formState: state }).execute(
        actionData
    );
    switch (action.type) {
        case FormActionKind.checkbox:
        case FormActionKind.lista:
        case FormActionKind.lista_cena:
        case FormActionKind.lista_zalezna:
        case FormActionKind.data_godzina:
        case FormActionKind.data:
        case FormActionKind.tekst:
        case FormActionKind.pesel:
        case FormActionKind.email:
        case FormActionKind.login:
        case FormActionKind.emaile_wielokrotne:
        case FormActionKind.powiazanie_typ:
        case FormActionKind.lista_obca:
        case FormActionKind.lista_obca_wielokrotna:
        case FormActionKind.liczba:
        case FormActionKind.tekst_bez_ograniczen:
        case FormActionKind.cena_liczba:
        case FormActionKind.cena:
        case FormActionKind.numer:
        case FormActionKind.numer_rachunku_bankowego:
        case FormActionKind.plik:
        case FormActionKind.pliki_wielokrotne:
        case FormActionKind.data_godziny:
        case FormActionKind.data_wzgledna:
        case FormActionKind.powiazanie_typ_wielokrotne:
        case FormActionKind.koszty:
        case FormActionKind.haslo_otwarte:
        case FormActionKind.polozenie:
        case FormActionKind.adres:
        case FormActionKind.liczba_rozliczana_do_zera:
        case FormActionKind.tekst_z_przedrostkiem:
        case FormActionKind.update_field:
        case FormActionKind.nip:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.code]: action.payload.value
                }
            };
        // this field should does not modify state
        case FormActionKind.system_data:
            return {
                ...state
            };
        // for password file there id no action, because this field shouldn't modify form state
        case FormActionKind.sums_relationship:
            return {
                ...state,
                sumsRelationship: {
                    ...state.sumsRelationship,
                    ...action.payload
                }
            };
        case FormActionKind.delete_relationship:
            return {
                ...state,
                deleteRelationship: {
                    ...state.deleteRelationship,
                    [action.payload.code]: action.payload.value
                }
            };
        case FormActionKind.set_fields:
            return {
                ...state,
                fields: action.payload
            };
        case FormActionKind.update_fields:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...action.payload
                }
            };
        case FormActionKind.update_fields_and_sums:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...action.payload.fields
                },
                sumsRelationship: {
                    ...state.sumsRelationship,
                    ...action.payload.sums
                }
            };
        case FormActionKind.update_state:
            return {
                ...state,
                fields: action.payload.fields
                    ? {
                          ...action.payload.fields
                      }
                    : state.fields,
                sumsRelationship: action.payload.sumsRelationship
                    ? {
                          ...action.payload.sumsRelationship
                      }
                    : state.sumsRelationship,
                deleteRelationship: action.payload.deleteRelationship
                    ? {
                          ...action.payload.deleteRelationship
                      }
                    : state.deleteRelationship,
                tabs: action.payload.tabs ? action.payload.tabs : state.tabs
            };
        case FormActionKind.set_values_to_accept:
            for (const fieldCode in state.fields) {
                const field = state.fields[fieldCode];
                if (!field) continue;
                if (fieldCode in action.payload.values) {
                    state.fields[fieldCode] = {
                        ...field,
                        valueToAccept: action.payload.values[fieldCode],
                        valueToAcceptText:
                            fieldCode in action.payload.texts
                                ? action.payload.texts[fieldCode]
                                : undefined
                    };
                } else {
                    state.fields[fieldCode] = {
                        ...field,
                        valueToAccept: undefined,
                        valueToAcceptText: undefined
                    };
                }
            }
            return {
                ...state,
                fields: {
                    ...state.fields
                }
            };
        case FormActionKind.update_field_values:
            for (const fieldCode in action.payload) {
                const field = state.fields[fieldCode];
                if (!field) continue;

                state.fields[fieldCode] = {
                    ...field,
                    value: getNewFieldValueFromValueToAccept(field, action.payload[fieldCode])
                };
            }
            return {
                ...state,
                fields: {
                    ...state.fields
                }
            };
        case FormActionKind.accept_value:
            for (const fieldCode in state.fields) {
                if (fieldCode === action.payload) {
                    const field = state.fields[fieldCode];
                    if (field)
                        return {
                            ...state,
                            fields: {
                                ...state.fields,
                                [fieldCode]: {
                                    ...field,
                                    // fix for fields like FieldList, that have under value object having main value and other things
                                    value: getNewFieldValueFromValueToAccept(field),
                                    valueToAccept: undefined,
                                    valueToAcceptText: undefined
                                }
                            }
                        };
                }
            }
            return {
                ...state
            };
        case FormActionKind.set_state:
            return {
                ...action.payload
            };
        case FormActionKind.reset_state:
            return {
                fieldsForeign: {},
                fields: {},
                sumsRelationship: {},
                deleteRelationship: {},
                tabs: {},
                onMountForm: false
            };

        default:
            return {
                ...state
            };
    }
};

export type FormContextProps = {
    keyPopup: string;
};

const FormContext = createContext<{
    formState: FormState;
    dispatchFormState: DynamicFormDispatch;
}>({
    formState: initialStateDynamicFormObject,
    dispatchFormState: () => null
});

// @ts-ignore
const useThunkReducer = createReducer(thunk);

const DynamicFormContextProvider: React.FC<FormContextProps> = ({ children, keyPopup }) => {
    const { popupStateRedux } = usePopupState(keyPopup);

    const popupData = popupStateRedux?.popupFormExtendedData;

    // @ts-ignore
    const [formState, dispatchFormState] = useThunkReducer(reducer, {
        fieldsForeign: {},
        fields: {},
        sumsRelationship: {},
        deleteRelationship: {},
        tabs: {},
        onMountForm: false
    });

    consoleConditionally(formState, 'formState in Provider ');

    React.useEffect(() => {
        if (!formState.onMountForm) {
            dispatchFormState({
                type: 'set_state',
                payload: new DynamicFormObjectStateHandler({
                    relations: popupData?.relations,
                    fields: popupData?.fields,
                    tabs: popupData?.tabs,
                    sections: popupData?.sections,
                    usedForAddingObject: popupData?.objectId === 'nowy'
                }).handleState('init')
            });
        }
        //
        return () => {
            if (formState.onMountForm) {
                dispatchFormState({
                    type: 'reset_state'
                });
            }
        };
    }, []);

    return (
        <FormContext.Provider value={{ formState, dispatchFormState }}>
            {children}
        </FormContext.Provider>
    );
};

export { DynamicFormContextProvider, FormContext };
