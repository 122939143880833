import { BudgetGetValuesFn } from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetHandlerStateGlobalFnExecuteProps,
    BudgetHandlerStateGlobalFnExecuteSharedProps
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';

// Class working on only onChange

type BudgetHandlerStateGlobalHelperProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {
    getValues: BudgetGetValuesFn;
};

export class BudgetHandlerStateGlobalHelper extends BudgetSetterOperationalState {
    getValues: BudgetGetValuesFn;

    constructor(props: BudgetHandlerStateGlobalHelperProps) {
        super({ budget: props.budget, operationalState: props.operationalState });
        this.getValues = props.getValues;
    }

    protected assignAllStateFormRHF({ methodId }: { methodId: string }) {
        Object.assign(this.operationalState.fieldsTempFromRHF, {
            ...this.getValues(`main.${methodId}`)
        });
        Object.assign(this.operationalState.globalSummaryData, {
            ...this.getValues('globalSummaryData')
        });
    }

    protected assignSumFromTempFieldsToSum() {
        const sumTemp: Record<string, any> = {};
        for (const key in this.operationalState.fieldsTempFromRHF) {
            if (key.includes('#')) {
                sumTemp[key] = this.operationalState.fieldsTempFromRHF[key];
            }
        }
        Object.assign(this.operationalState.sumTemp, sumTemp);
    }

    protected findMonthWhereWasChanged(data: BudgetHandlerStateGlobalFnExecuteProps) {
        this.budget.settlementMethods.forEach((method) => {
            if (method.id === data.methodId) {
                method.years.forEach((year) => {
                    year?.months?.forEach((month) => {
                        if (String(month.id) === String(data.monthId)) {
                            this.operationalState.monthWhereWasChanged = month;
                        }
                    });
                });
            }
        });
    }

    protected addFieldsTempFromRHFToFieldsTemp() {
        this.operationalState.fieldsTemp = {
            ...this.operationalState.fieldsTempFromRHF,
            ...this.operationalState.fieldsTemp
        };
    }

    protected addLimitTempToFieldsTempAndFieldsTempFromRHF() {
        this.operationalState.fieldsTemp = {
            ...this.operationalState.fieldsTemp,
            ...this.operationalState.limitTemp
        };
        this.operationalState.fieldsTempFromRHF = {
            ...this.operationalState.fieldsTempFromRHF,
            ...this.operationalState.limitTemp
        };
    }
}
