import React, { FC } from 'react';
import { SumsSwitcherProps } from 'src/components/popupFormExtended/sumsSwitcher/types';
import { FormContext } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import { formatNumberSpan } from 'src/utils';

export const SumsSwitcher: FC<SumsSwitcherProps> = (props) => {
    const { formState } = React.useContext(FormContext);

    if (props.dynamicFieldT.mozliwe_sumowanie === 't') {
        switch (props.dynamicFieldT.typ) {
            case 'cena':
            case 'cena_liczba': {
                const netto = formState.sumsRelationship[props.keyOfFieldInState];
                const brutto = formState.sumsRelationship[`${props.keyOfFieldInState}_brutto`];
                return (
                    <div>
                        Razem {formatNumberSpan({ number: netto })} netto{' '}
                        {formatNumberSpan({ number: brutto })} brutto
                    </div>
                );
            }
            case 'koszty':
            case 'liczba': {
                const value = formState.sumsRelationship[props.keyOfFieldInState];
                return <div>Razem {formatNumberSpan({ number: value })}</div>;
            }
            default:
                return null;
        }
    } else {
        return null;
    }
};
