import { FieldDependentGlobalRHFManager } from 'src/components/form/molecules/fieldDependent/FieldDependentGlobalManagerRHF';
import {
    FieldDependentRHFProps,
    FieldDependentRowRHF,
    FieldDependentStaticDataRHF,
    FieldDependentTempStateRHF,
    FieldDepenedentFormStateHandlersRHF
} from 'src/components/form/molecules/fieldDependent/types';

import { DynamicFieldT } from 'src/data/popupFormTypes';

type FieldDependentSetterRHFProps = FieldDependentRHFProps & {
    tempState?: FieldDependentTempStateRHF;
};

type FieldDependentSetterOnChangeItem = {
    dynamicFieldT: DynamicFieldT;
    row?: FieldDependentRowRHF;
};
export class FieldDependentSetterRHF {
    dynamicFieldsT: DynamicFieldT[];
    formStateHandlers: FieldDepenedentFormStateHandlersRHF;
    tempState: FieldDependentTempStateRHF;
    staticData: FieldDependentStaticDataRHF;
    constructor({
        dynamicFieldsT,
        formStateHandlers,
        staticData,
        tempState
    }: FieldDependentSetterRHFProps) {
        this.dynamicFieldsT = dynamicFieldsT;
        this.formStateHandlers = formStateHandlers;
        this.tempState = tempState ?? {};
        this.staticData = staticData;
    }
    async onMount() {
        await new FieldDependentGlobalRHFManager({
            dynamicFieldsT: this.dynamicFieldsT,
            formStateHandlers: this.formStateHandlers,
            tempState: this.tempState,
            staticData: this.staticData
        }).onMount();
        await this.updateState();
    }
    async onChangeItem({ dynamicFieldT, row }: FieldDependentSetterOnChangeItem) {
        const keyValue = Object.keys(this.tempState)[0];
        await new FieldDependentGlobalRHFManager({
            dynamicFieldsT: this.dynamicFieldsT,
            formStateHandlers: this.formStateHandlers,
            tempState: this.tempState,
            staticData: this.staticData
        }).onChangeItem({ dynamicFieldT, row });
        await this.clearBeforeUpdate(keyValue);
        await this.updateState();
    }

    private async clearBeforeUpdate(keyValue: string) {
        for (const key in this.tempState) {
            if (key === keyValue) {
                delete this.tempState[key];
            }
        }
    }
    private async updateState() {
        if (Object.keys(this.tempState).length) {
            this.formStateHandlers.reset((prev) => {
                return { ...prev, ...this.tempState };
            });
        }
    }
}
