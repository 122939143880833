import {
    BrowserPageLocationState,
    ConvertableToParams,
    PageLocationState,
    ValidLocationState
} from 'src/data/types';

export const makeValidLocationState = <
    K extends BrowserPageLocationState<
        PageLocationState<ConvertableToParams, ConvertableToParams, any>
    >
>(
    pageLocationState: K
): ValidLocationState<K> => {
    return { store: pageLocationState.store, legacyParams: pageLocationState.legacyParams };
};
