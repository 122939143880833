import React, { FC } from 'react';
import UtilityBarOptionView from 'src/components/listScreen/molecules/utilityBarOptionView/UtilityBarOptionView';
import { ActionType } from 'src/components/shared/molecules/actionType/ActionType';
import { UtilityBarOptionProps } from './types';
import SearchOptionDeleteIndicator from 'src/components/listScreen/atoms/searchOptionDeleteIndicator/SearchOptionDeleteIndicator';
import { UtilityBarOptionHorizontalView } from 'src/components/listScreen/molecules/utilityBarOptionViewHorizontal/UtilityBarOptionViewHorizontal';

const UtilityBarOption: FC<UtilityBarOptionProps> = ({
    action,
    deleteSearchOptionCallback,
    typeDisplay
}) => {
    return (
        <div style={{ display: 'flex', position: 'relative' }}>
            <ActionType action={action} rowId={null}>
                {action.behaviour.type === 'searchFilter' && (
                    <SearchOptionDeleteIndicator
                        deleteSearchOptionCallback={deleteSearchOptionCallback}
                        action={action}
                    />
                )}
                {typeDisplay === 'vertical' ? (
                    <UtilityBarOptionView name={action.name} icon={action.icon} />
                ) : (
                    <UtilityBarOptionHorizontalView name={action.name} icon={action.icon} />
                )}
            </ActionType>
        </div>
    );
};
export default UtilityBarOption;
