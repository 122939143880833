import { fetchMessageData } from 'src/api/src/messageScreen/fetchMessage';
import { dispatch, useTypedSelector } from 'src/store';
import { setMessageData } from 'src/store/src/messageScreen';
import { MessageDataApiCreator } from 'src/store/src/messageScreen/message/MessageDataApiCreator';
import { setPopupInfoTwo } from 'src/store/src/popup';

export const useLoadTemplateMessageScreenData = () => {
    const messageRowHidden = useTypedSelector((state) => state.messageSlice.rowHidden);
    const messageRow = useTypedSelector((state) => state.messageSlice.row);

    const loadTemaplateMessageScreenData = async () => {
        const data = new MessageDataApiCreator({
            messageRowHidden,
            messageRow
        }).createDataForTemplateRequest();
        if (data.validationResult.isValid) {
            // filter params not to send by accident undefined as param value, ie. email=undefined
            const params = new URLSearchParams(
                data.messageDataRequest.params.filter((param) => Boolean(param[1]))
            ).toString();
            const apiResponse = await fetchMessageData(params);
            console.log(apiResponse);
            if (apiResponse.success) {
                dispatch(setMessageData(apiResponse.data));
            }
        } else {
            dispatch(
                setPopupInfoTwo({
                    message: data.validationResult.message,
                    title: 'Błąd'
                })
            );
        }
    };
    return { loadTemaplateMessageScreenData };
};
