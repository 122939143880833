import {
    FieldDependentFieldTypeRHF,
    FieldDependentFunctionRHF
} from 'src/components/form/molecules/fieldDependent/types';

export const massEditObjectsAvailableFunctionToCallInField: {
    [key in FieldDependentFieldTypeRHF]?: FieldDependentFunctionRHF[];
} = {
    lista_obca: ['zmienPole'],
    lista: [],
    powiazanie_typ: ['zmienPole']
};
