import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { FieldListForeign } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/types';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { getFieldForeignValueWithSelectedSoleOption } from 'src/utils/src/popupFormExtended/getFieldForeignValueWithSelectedSoleOption';
import { DynamicFormObjectDataConverter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectDataConverter';
import { FieldListForeignBasicTypeValue } from 'src/data/fieldsFormType';

type FieldDependentListForeignManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldListForeign;
};

export class FieldDependentListForeignManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldListForeign;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentListForeignManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async zmienPole() {
        if (!this.fieldWasTriggered.isUpdatedDepedentent) {
            await this.checkAndChangeFieldChangeStateOfField();
            await this.runNested(this.fieldWasTriggeredDynamicFieldT);
        }
    }

    private async checkAndChangeFieldChangeStateOfField() {
        let opcje = this.fieldWasTriggered.value.opcje;
        const beforeValue = this.fieldWasTriggered.value.value;
        const beforeText = this.fieldWasTriggered.value.text;
        const data = await this.getValueToSetForChangeField();
        opcje = data.opcje;

        if (this.fieldWasTriggeredDynamicFieldT?.dodanie_nowego === 't') {
            opcje['nowy'] = 'Dodaj...';
        }

        if (!this.fieldWasTriggeredDynamicFieldT) {
            console.error(
                'FieldDependentListForeignManager fieldWasTriggeredDynamicFieldT is not defined'
            );
            return;
        }

        const valueWithSoleOption = getFieldForeignValueWithSelectedSoleOption(
            { opcje, value: '0', text: '' },
            DynamicFormObjectDataConverter.getArrayIdAndText(opcje),
            this.fieldWasTriggeredDynamicFieldT
        );

        let value: FieldListForeignBasicTypeValue = { opcje, value: '0', text: '' };

        if (
            this.fieldWasTriggeredDynamicFieldT.zablokuj_po_ustawieniu_z_nadrzednego === 't' ||
            (beforeValue && opcje[beforeValue])
        ) {
            value.value = beforeValue;
            value.text = beforeText;
        }

        const newValue = {
            ...this.fieldWasTriggered,
            value: valueWithSoleOption ?? value
        };

        this.isRunNested = true;
        this.setTempFieldsState(newValue, 'withoutFlag');
    }

    async zmienWartosciPolZNadrzednegoModTextForm() {
        await this.getDataForSuperior();
        await this.checkIfIsReturnValuesForChild();
    }

    private async getDataForSuperior() {
        const screen = this.getScreen();
        const params = {
            q: this.fieldWasTriggered.value.value,
            ekran: screen,
            tabela_opcji: this.fieldWasTriggeredDynamicFieldT?.tabela_opcji
        };
        const res = await getFieldAdditionalData({
            url: 'json_pobierz_wartosci_pol_z_nadrzenego.php',
            params
        });
        if (res.data) {
            this.valuesFromSuperior = res.data;
            this.isCaptureValuesFromSuperior = true;
        }
    }
}
