import {
    BudgetObjectsCodes,
    BudgetScreenState,
    BugdetMonthLevel,
    BugdetSettlementMethodLevel,
    MonthLevelType,
    MonthSubLevelItem,
    SettledObjectsCodes,
    SettleObject
} from 'src/store/src/budget/budget/types';
import { CreateKeySumSharedProps } from './createKeySumBudget';
import { BudgetStableFieldSumType } from 'src/constants/budget/budgetStableFieldSum';
import { CalcSumFn } from './BudgetHandlerStateSum';
import { budgetCapfeeStableFields } from 'src/constants/budget/budgetCapfeeStableFields';

type BaseFunctionProps = CreateKeySumSharedProps & {
    object: SettleObject;
    code: SettledObjectsCodes;
    method: BugdetSettlementMethodLevel;
    month?: BugdetMonthLevel;
};

export class BudgetHandlerUtils {
    protected runForMonthSublevelForEveryObject(
        functionToRun: (props: BaseFunctionProps) => void,
        method: BugdetSettlementMethodLevel,
        month: BugdetMonthLevel,
        monthSubLevelItems: MonthSubLevelItem[],
        monthLevelType: MonthLevelType
    ) {
        monthSubLevelItems.forEach((caseItem) => {
            caseItem.settledElements.forEach((settledElement) => {
                settledElement.objects.forEach((object) => {
                    functionToRun({
                        code: settledElement.code,
                        object,
                        method,
                        month,
                        monthLevelTypeId: caseItem.id,
                        monthLevelType: monthLevelType,
                        methodLevelType: 'miesiac'
                    });
                });
            });
        });
    }

    protected runForMonthSublevelForCaseItem(
        baseFunctionToRun: (props: CalcSumFn) => void,
        method: BugdetSettlementMethodLevel,
        month: BugdetMonthLevel,
        monthSubLevelItems: MonthSubLevelItem[],
        monthLevelType: 'sprawy' | 'projekty',
        stableFieldsSumType: BudgetStableFieldSumType,
        IfFunctionToRun?: (props: CalcSumFn) => void,
        guardCallFn?: (caseItemId: string) => boolean
    ) {
        if (guardCallFn) {
            monthSubLevelItems.forEach((caseItem) => {
                if (guardCallFn(caseItem.id)) {
                    baseFunctionToRun({
                        type: stableFieldsSumType,
                        month,
                        method,
                        monthLevelTypeId: caseItem.id,
                        monthLevelType: monthLevelType,
                        methodLevelType: 'miesiac'
                    });
                }
            });
        } else {
            monthSubLevelItems.forEach((caseItem) => {
                baseFunctionToRun({
                    type: stableFieldsSumType,
                    month,
                    method,
                    monthLevelTypeId: caseItem.id,
                    monthLevelType: monthLevelType,
                    methodLevelType: 'miesiac'
                });
            });
        }

        if (IfFunctionToRun && monthSubLevelItems?.length) {
            IfFunctionToRun({
                type: monthLevelType,
                month,
                method,
                monthLevelType: monthLevelType,
                methodLevelType: 'miesiac'
            });
        }
    }

    static getField(
        objectCode: BudgetObjectsCodes,
        fieldId: string,
        structures: BudgetScreenState['settledObjectsStructures']
    ) {
        const objects = { ...structures, capfee: { fields: budgetCapfeeStableFields } };
        if (!(objectCode in objects)) {
            return undefined;
        }
        return objects[objectCode].fields.find((field) => field.id === fieldId);
    }

    static getFieldName(
        objectCode: BudgetObjectsCodes,
        fieldId: string,
        structures: BudgetScreenState['settledObjectsStructures']
    ) {
        const currencyField = this.getField(objectCode, fieldId, structures);
        return currencyField
            ? currencyField.onlyText
                ? undefined
                : currencyField.name
            : undefined;
    }
}
