export function getVat(vat: string | number) {
    const data = typeof vat === 'string' ? vat : String(vat);

    switch (data) {
        case '23':
            return 1.23;
        case '8':
            return 1.08;
        case '5':
            return 1.05;
        case '3':
            return 1.03;
        case '0':
            return 1;
        default:
            return 1;
    }
}
