import React, { FC } from 'react';
import { FieldAdressObject } from 'src/components/popupFormExtended/dynamicField/fieldAdress/FieldAdressObject';
import { FieldBankAccountNumberObject } from 'src/components/popupFormExtended/dynamicField/fieldBankAccountNumber/FieldBankAccountNumberObject';
import { FieldCheckboxObject } from 'src/components/popupFormExtended/dynamicField/fieldCheckbox/FieldCheckboxObject';
import { FieldCostsObject } from 'src/components/popupFormExtended/dynamicField/fieldCosts/FieldCostsObject';
import { FieldDateObject } from 'src/components/popupFormExtended/dynamicField/fieldDate/FieldDateObject';
import { FieldDateHourObject } from 'src/components/popupFormExtended/dynamicField/fieldDateHour/FieldDateHourObject';
import { FieldDateHoursObject } from 'src/components/popupFormExtended/dynamicField/fieldDateHours/FieldDateHoursObject';
import { FieldDateRelativeObject } from 'src/components/popupFormExtended/dynamicField/fieldDateRelative/FieldDateRelativeObject';
import { FieldFileObject } from 'src/components/popupFormExtended/dynamicField/fieldFile/FieldFileObject';
import { FieldFileManyObject } from 'src/components/popupFormExtended/dynamicField/fieldFileMany/FieldFileManyObject';
import { FieldListObject } from 'src/components/popupFormExtended/dynamicField/fieldList/FieldListObject';
import { FieldListDependentObject } from 'src/components/popupFormExtended/dynamicField/fieldListDependent/FieldListDependentObject';
import { FieldListForeignObject } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/FieldListForeignObject';
import { FieldListForeignObjectMany } from 'src/components/popupFormExtended/dynamicField/fieldListForeignMany/FieldListForeignManyObject';
import { FieldMailManyObject } from 'src/components/popupFormExtended/dynamicField/fieldMailMany/FieldMailMany';
import { FieldNipObject } from 'src/components/popupFormExtended/dynamicField/fieldNip/FieldNipObject';
import { FieldNumberObject } from 'src/components/popupFormExtended/dynamicField/fieldNumber/FieldNumberObject';
import { FieldObjectNumberObject } from 'src/components/popupFormExtended/dynamicField/fieldObjectNumber/FieldObjectNumberObject';
import { FieldOpenPasswordObject } from 'src/components/popupFormExtended/dynamicField/fieldOpenPassword/FieldOpenPasswordObject';
import { FieldPriceObject } from 'src/components/popupFormExtended/dynamicField/fieldPrice/FieldPriceObject';
import { FieldPriceNumberObject } from 'src/components/popupFormExtended/dynamicField/fieldPriceNumber/FieldPriceNumberObject';
import { FieldRelationshipTypeObject } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipType/FieldRelationshipTypeObject';
import { FieldRelationshipTypeManyObject } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipTypeMany/FieldRelationshipTypeObjectMany';
import { FieldSystemDateObject } from 'src/components/popupFormExtended/dynamicField/fieldSystemDate/FieldSystemDateObject';
import { FieldTextObject } from 'src/components/popupFormExtended/dynamicField/fieldText/FieldTextObject';
import { FieldTextWithoutRestrictObject } from 'src/components/popupFormExtended/dynamicField/fieldTextWithoutRestrict/FieldTextWithoutRestrictObject';
import { FieldMailObject } from 'src/components/popupFormExtended/dynamicField/fieldMail/FieldMailObject';
import { FieldLoginObject } from 'src/components/popupFormExtended/dynamicField/fieldLogin/FieldLoginObject';
import { FieldSwitcherProps } from 'src/components/popupFormExtended/fieldSwitcher/types';
import { FieldsBelow } from 'src/components/popupFormExtended/fieldsBelow/FieldsBelow';
import { UiWrapperFieldTwo } from 'src/components/shared/atoms/uiLayout/uiWrapperFieldTwo/UiWrapperFieldTwo';
import { FieldPeselObject } from 'src/components/popupFormExtended/dynamicField/fieldPesel/FieldObjectPesel';
import { FieldLocationObject } from '../dynamicField/fieldLocation/FieldLocationObject';
import { FieldNumberSettledToZeroObject } from '../dynamicField/fieldNumberSettledToZero/FieldNumberSettledToZeroObject';
import { FieldTextWithPrefixObject } from '../dynamicField/fieldTextWithPrefix/FieldTextObjectWithPrefix';
import { FieldPasswordObject } from '../dynamicField/fieldPassword/FieldPasswordObject';
import { FieldAddExtend } from 'src/components/popupFormExtended/dynamicField/fieldAddExtend/FieldAddExtend';

const FieldSwitcherComp: FC<FieldSwitcherProps> = (props) => {
    if (props.popupFormExtendedData === null) {
        console.log('FieldSwitcher popupFormExtendedData is null');
        return null;
    }

    const fieldState = props.switcherFieldState;
    switch (fieldState?.typ) {
        case 'lista_obca':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldListForeignObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'lista_wielokrotna_cena':
        case 'lista_wielokrotna':
        case 'lista_obca_wielokrotna':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldListForeignObjectMany
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'lista':
        case 'lista_cena':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldListObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'lista_zalezna':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldListDependentObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'plik':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldFileObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                </UiWrapperFieldTwo>
            );
        case 'koszty':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldCostsObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                </UiWrapperFieldTwo>
            );
        case 'pliki_wielokrotne':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldFileManyObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                </UiWrapperFieldTwo>
            );
        case 'checkbox':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldCheckboxObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'powiazanie_typ':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldRelationshipTypeObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'powiazanie_typ_wielokrotne':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldRelationshipTypeManyObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'email':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldMailObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'login':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldLoginObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'tekst':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldTextObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'pesel':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldPeselObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'tekst_bez_ograniczen':
            return (
                <UiWrapperFieldTwo
                    sx={{
                        gridColumn: '1/3'
                    }}
                    {...props}>
                    <FieldTextWithoutRestrictObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'cena_liczba':
            return (
                <UiWrapperFieldTwo sx={{ flexGrow: '2' }} {...props}>
                    <FieldPriceNumberObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'cena':
            return (
                <UiWrapperFieldTwo sx={{ flexGrow: '2' }} {...props}>
                    <FieldPriceObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'numer':
            return (
                <UiWrapperFieldTwo sx={{ flexGrow: '2' }} {...props}>
                    <FieldObjectNumberObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'numer_rachunku_bankowego':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldBankAccountNumberObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'data':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldDateObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'data_godzina':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldDateHourObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'data_godziny':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldDateHoursObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'data_wzgledna':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldDateRelativeObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'liczba':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldNumberObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'nip':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldNipObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'adres':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldAdressObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'emaile_wielokrotne':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldMailManyObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'system_data':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldSystemDateObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'haslo':
            // do not render password component when form is about adding object, if made here to
            // make sure that UiWrapperFieldTwo do not render
            return props.uiFieldCommonProps?.usedForAddingObject ? null : (
                <UiWrapperFieldTwo {...props}>
                    <FieldPasswordObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        screen={props.screen}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'haslo_otwarte':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldOpenPasswordObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'polozenie':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldLocationObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'liczba_rozliczana_do_zera':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldNumberSettledToZeroObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'tekst_z_przedrostkiem':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldTextWithPrefixObject
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                    <FieldsBelow {...props} />
                </UiWrapperFieldTwo>
            );
        case 'dodanie_powiazania':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldAddExtend
                        {...props}
                        field={fieldState}
                        dispatch={props.dispatchFormState}
                        popupFormExtendedData={props.popupFormExtendedData}
                    />
                </UiWrapperFieldTwo>
            );
        default:
            return null;
    }
};

export const FieldSwitcher = React.memo(FieldSwitcherComp);
