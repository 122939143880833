import React, { FC, SyntheticEvent } from 'react';
import { Divider } from '@mui/material';
import { ShortcutsPinSectionScreenListProps } from './types';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import Pin from 'src/assets/pinezka_biala.svg';
import { useTheme } from '@mui/material/styles';
import styles from './ShortcutsPinSectionModuleList.module.css';

const ShortcutsPinSectionScreenList: FC<ShortcutsPinSectionScreenListProps> = ({
    screens,
    updatePinnedScreensInListModule
}) => {
    const theme = useTheme();
    const handleClick = (e: SyntheticEvent, screen: number) => {
        updatePinnedScreensInListModule(screen);
    };
    return (
        <div className={styles.wrapperMain}>
            <Divider />
            {screens.map((screen) => {
                return (
                    <div
                        key={screen.name}
                        className={styles.wrapperScreen}
                        onClick={(e) => handleClick(e, screen.id)}>
                        <div className={styles.wrapperScreenInner}>
                            <p>
                                {screen.isSelectedToShortcut ? (
                                    <div
                                        className={styles.wrapperScreenInner2}
                                        style={{ background: theme.palette.primary.main }}>
                                        <ThemeCompatibleIcon
                                            SvgComponent={Pin}
                                            sx={{
                                                fontSize: '0.8rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                paddingTop: '1px'
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.listBox}>
                                        <ThemeCompatibleIcon
                                            SvgComponent={Pin}
                                            sx={{
                                                fontSize: '0.8rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                paddingTop: '1px'
                                            }}
                                        />
                                    </div>
                                )}
                            </p>
                        </div>
                        <p className={styles.p}>{screen.name}</p>
                    </div>
                );
            })}
            <Divider />
        </div>
    );
};

export default ShortcutsPinSectionScreenList;
