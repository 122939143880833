import { useEffect } from 'react';

export const usePressKeyWithKeyCode = (func: Function, keyCode: string) => {
    useEffect(() => {
        function run(e: KeyboardEvent): void {
            if (e.code === keyCode) {
                func();
            }
        }
        window.addEventListener('keydown', run);
        return function cleanupListener() {
            window.removeEventListener('keydown', run);
        };
    }, [func]);
};
