import { BrowserListScreenLocationState } from 'src/components/listScreen/pages/objectList/types';
import { SearchFieldsState } from 'src/store/src/listScreen/searchFields/types';
import {
    DropDownListFilterValue,
    ListSearchFilters
} from 'src/store/src/listScreen/transformations/types';

export const prepareFilters = ({
    locationData,
    searchFieldsSlice
}: {
    locationData: BrowserListScreenLocationState;
    searchFieldsSlice?: SearchFieldsState;
}) => {
    const paramsObject: { [key: string]: any } =
        { ...locationData?.params, ...locationData?.legacyParams } ?? {};
    const filterForListUnhold: ListSearchFilters = {};
    const dropdownListFields =
        searchFieldsSlice?.filter((item) => item?.searchType === 'lista_rozwijalna') || [];

    const paramsToDel: string[] = [];

    dropdownListFields.forEach((dropdownListField) => {
        for (const paramName in paramsObject) {
            if (dropdownListField.searchCode === paramName) {
                // if we know that only lista_rozwijalna fields be loaded we can format value properly
                const fieldFilterValue: DropDownListFilterValue = {
                    text: paramsObject[paramName],
                    screen: null,
                    id: 0
                };
                const rawFilterParamValue = paramsObject[`${paramName}Select`];
                // because ${paramName}Select can have value like '1256' or '909_sprawy'
                if (isNaN(rawFilterParamValue)) {
                    [fieldFilterValue.id, fieldFilterValue.screen] = rawFilterParamValue.split('_');
                } else {
                    fieldFilterValue.id = parseInt(rawFilterParamValue);
                }
                paramsToDel.push(paramName, `${paramName}Select`);

                filterForListUnhold[paramName] = {
                    fieldFilter: { orMode: false, value: fieldFilterValue }
                };
            }
        }
    });

    return {
        filters: {
            ...filterForListUnhold,
            ...(locationData.store?.filters ?? {})
        },
        legacyParamsKeys: paramsToDel
    };
};
