import { TooltipProps } from './types';
import React, { FC } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import { useStyles } from './styles';

/**
 * Tooltip Component displayed to better explain other components purpose.
 */
const Tooltip: FC<TooltipProps> = ({
    title,
    children,
    disabled = false,
    portal = true,
    clickOnly,
    open
}) => {
    const classes = useStyles();

    if (disabled) return <>{children}</>;

    return (
        <MuiTooltip
            PopperProps={{
                disablePortal: !portal
            }}
            open={open}
            title={title}
            disableFocusListener
            disableHoverListener={clickOnly}
            placement="bottom"
            classes={{ tooltip: classes.tooltip, popper: classes.popper }}>
            {children}
        </MuiTooltip>
    );
};

export default Tooltip;
