import React from 'react';
import { fieldDataMinutes } from 'src/constants/inputDateHourData';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { transformListMinute } from 'src/utils';

export function useGetMinutesOptionsForField(dynamicFieldT: DynamicFieldT) {
    const minutes = React.useMemo(
        () =>
            dynamicFieldT.przedzial_minutowy !== '1'
                ? transformListMinute(dynamicFieldT.przedzial_minutowy)
                : fieldDataMinutes,
        [dynamicFieldT]
    );
    return minutes;
}
