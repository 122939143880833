import React from 'react';
import { FormActions, FormStateFields } from './types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { DateManager, generateToken } from 'src/utils';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { AppDispatch } from 'src/store';

export class DynamicFormObjectHistoryCommentsManage {
    dispatch: React.Dispatch<FormActions>;
    appDispatch: AppDispatch;
    fields: FormStateFields;
    popupFormExtendedData: PopupFormExtendedData;

    constructor(
        dispatch: React.Dispatch<FormActions>,
        appDispatch: AppDispatch,
        fields: FormStateFields,
        popupFormExtendedData: PopupFormExtendedData
    ) {
        this.dispatch = dispatch;
        this.appDispatch = appDispatch;
        this.fields = fields;
        this.popupFormExtendedData = popupFormExtendedData;
    }
    async execute() {
        // prompting about new history entry is available only at object edit
        if (this.popupFormExtendedData.objectId === 'nowy') return;

        for (const fieldCode in this.fields) {
            const field = this.fields[fieldCode];
            const apiFieldData = this.popupFormExtendedData.fields.find(
                (field) => field.kod === fieldCode
            );

            if (
                !field ||
                !apiFieldData ||
                apiFieldData.historia !== 't' ||
                apiFieldData.historia_uwagi !== 't'
            )
                continue;

            if (field.historyComments != null) continue;

            // history comments temporary only apply for data_godzina fields
            if (field.typ !== 'data_godzina') continue;
            // check if value was changed
            const fieldDate = field.value.date;
            fieldDate?.setHours(parseFloat(field.value.hour ?? ''));
            fieldDate?.setMinutes(parseFloat(field.value.minute ?? ''));
            if (
                fieldDate?.getTime() ===
                DateManager.prepareFromVariousTypeOfDateToNewDate(
                    apiFieldData.domyslna_wartosc_int
                )?.getTime()
            )
                continue;

            if (apiFieldData.historia_uwagi_obowiazkowe !== 't') {
                field.historyComments = await this.alertPromptComment(
                    `Podaj uwagi do zmiany pola ${field.name}`
                );
                continue;
            }

            while (!field.historyComments || field.historyComments === '')
                field.historyComments = await this.alertPromptComment(
                    `Podaj uwagi do zmiany pola ${field.name}`
                );
        }
    }

    async alertPromptComment(text: string) {
        return new Promise<string | null>((res, _) => {
            const closeCallbackEventId = `historyCommentPrompt-close-${generateToken()}`;
            const submitCallbackEventId = `historyCommentPrompt-submit-${generateToken()}`;

            EventBus.$on(closeCallbackEventId, () => {
                EventBus.$off(closeCallbackEventId);
                EventBus.$off(submitCallbackEventId);

                res(null);
            });

            EventBus.$on(submitCallbackEventId, async (text: string) => {
                EventBus.$off(closeCallbackEventId);
                EventBus.$off(submitCallbackEventId);

                res(text);
            });

            new PopupManager({ dispatch: this.appDispatch, action: null }).setPopupPrompt({
                message: text,
                closeCallbackEventId,
                submitCallbackEventId
            });
        });
    }
}
