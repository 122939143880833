import fileDownload from 'js-file-download';

type Props = {
    resource: string;
    nameFile: string;
};

export const downloadFileAsync = async ({ resource, nameFile }: Props) => {
    const res = await fetch(resource);
    const blob = await res.blob();
    await fileDownload(blob, nameFile);
};
