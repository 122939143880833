import React from 'react';
import WrapperIcon from 'src/components/shared/atoms/wrapperIcon/WrapperIcon';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import MessageIcon from 'src/assets/ikona_wiadomosc.svg';
import { fetchMessagesScreenStatus } from 'src/api/src/menu/fetchMessagesScreenStatus';
import { Box } from '@mui/material';
import { dispatch, useTypedSelector } from 'src/store';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useNavigate, useLocation } from 'react-router-dom';
import { StyledBadgeProps } from './types';
import RefreshDOMEventListener from 'src/components/shared/atoms/refreshDOMEventListener/RefreshDOMEventListener';
import { fetchAndUpdateMessagesCount } from 'src/store/src/menu/menu/menuSlice';
import { useSearchParam } from 'src/hooks';

const MessageBadge = styled(Badge, {
    shouldForwardProp: (prop) => prop !== 'isMessageIncome'
})<StyledBadgeProps>(({ isMessageIncome, theme }) => ({
    '& .MuiBadge-badge': {
        right: '25%',
        top: 2,
        height: 17,
        minHeight: 17,
        width: 17,
        minWidth: 17,
        fontSize: 8,
        padding: 0,
        ...(isMessageIncome === true && {
            animation: `pulse 1000ms  ${theme.transitions.easing.easeInOut}`
        })
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(1.4) translate(50%, -50%)'
        },
        '25%': {
            transform: 'scale(1) translate(50%, -50%)'
        },
        '50%': {
            transform: 'scale(1.4) translate(50%, -50%)'
        },
        '100%': {
            transform: 'scale(1) translate(50%, -50%)'
        }
    }
})); // zestylowany badge, wielkosci sa nieco wieksze niz w adobe xd przy 11 w/h i 6 font size nieczytelne

const MessagesSection = () => {
    // check if messages screen exists/user has access, file
    // api_react/src/menu/getMessagesScreenStatus.php, if don't exisits yet simply make mock data
    // if exists render icon and start some kind of timeout updates messagees count,
    // fetchAndUpdateMessagesCount will be helpful

    const navigate = useNavigate();

    const [screenId, SetScreenId] = React.useState<number | null>(null);
    const [msgCount, SetMsgCount] = React.useState<number | null>(null);
    const [initailRun, SetInitialRun] = React.useState<boolean>(true);

    const [msgIncome, SetMsgIncome] = React.useState<boolean>(false);
    const objectParam = useSearchParam('obiekt');
    const screenParam = useSearchParam('screen');

    const getScreenStatus = async () => {
        const response = await fetchMessagesScreenStatus();
        if (response.success === true) {
            SetScreenId(response.data.isMessageScreenVisible ? response.data.id : null);
        } else {
            console.error(`Error ${response.errorCode} occured`);
        }
    };

    const goToMessage = () => {
        if (screenId !== null) {
            const link = `/index.html?obiekt=${screenId}&screen=adresaciwiadomosci&php_url=ekran_obiektow.php`;
            navigate(link);
        }
    };

    const messageCount = useTypedSelector((state) => state.menu.newMessagesCount);

    React.useEffect(() => {
        getScreenStatus();
    }, []);

    React.useEffect(() => {
        if (objectParam === 'adresaciwiadomosci' || screenParam === 'adresaciwiadomosci')
            setTimeout(() => refrehMessagesCount(), 800);
    }, [objectParam, screenParam]);

    React.useEffect(() => {
        if (messageCount != 0 && messageCount != null && initailRun == false) {
            SetMsgIncome(true);
        }

        if (msgCount !== null) {
            SetInitialRun(false);
        }
    }, [msgCount]);

    React.useEffect(() => {
        messageCount == null ? SetMsgCount(null) : SetMsgCount(messageCount);
    }, [messageCount]);

    const refrehMessagesCount = () => {
        dispatch(fetchAndUpdateMessagesCount());
    };

    return (
        <Box
            onClick={goToMessage}
            sx={{
                cursor: 'pointer',
                display: () => {
                    return screenId === null ? 'none' : 'block';
                }
            }}>
            <MessageBadge
                isMessageIncome={msgIncome}
                badgeContent={msgCount}
                max={9}
                color="primary">
                <WrapperIcon>
                    <ThemeCompatibleIcon
                        SvgComponent={MessageIcon}
                        fontSize={'small'}
                        sx={{
                            position: 'absolute',
                            fontSize: '1rem',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)'
                        }}
                    />
                </WrapperIcon>
            </MessageBadge>
        </Box>
    );
};

export default React.memo(MessagesSection);
