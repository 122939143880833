/**
 * @namespace ActionTypes
 * @category Redux
 * @description Stringi używane jako typy akcji.
 * @example
 * // action
 * const chatOpen = {
 *  	type: actionTypes.SET_CHAT_OPEN,
 * 		payload: {boolean: true}
 * };
 * @example
 * // action creator
 * const setChatOpen = (boolean) => ({
 *  	type: actionTypes.SET_CHAT_OPEN,
 * 		payload: {boolean}
 * });
 * @see [ActionCreators]{@link ActionCreators}
 */

/* app actions */

/**
 * @constant {'SET_CHAT_OPEN'} ActionTypes.SET_CHAT_OPEN
 */
export const SET_CHAT_OPEN = 'SET_CHAT_OPEN';

/**
 * @constant {'SET_CONFIG'} ActionTypes.SET_CONFIG
 */
export const SET_CONFIG = 'SET_CONFIG';

/**
 * @constant {'SET_DISCONNECTED'} ActionTypes.SET_DISCONNECTED
 */
export const SET_DISCONNECTED = 'SET_DISCONNECTED';

/**
 * @constant {'SET_TAB_ACTIVE'} ActionTypes.SET_TAB_ACTIVE
 */
export const SET_TAB_ACTIVE = 'SET_TAB_ACTIVE';

/**
 * @constant {'OPEN_CHAT_WINDOW'} ActionTypes.OPEN_CHAT_WINDOW
 */
export const OPEN_CHAT_WINDOW = 'OPEN_CHAT_WINDOW';

/**
 * @constant {'CLOSE_CHAT_WINDOW'} ActionTypes.CLOSE_CHAT_WINDOW
 */
export const CLOSE_CHAT_WINDOW = 'CLOSE_CHAT_WINDOW';

/**
 * @constant {'SET_LOADING_STATE'} ActionTypes.SET_LOADING_STATE
 */
export const SET_LOADING_STATE = 'SET_LOADING_STATE';

/**
 * @constant {'PREPEND_CHAT_WINDOW'} ActionTypes.PREPEND_CHAT_WINDOW
 */
export const PREPEND_CHAT_WINDOW = 'PREPEND_CHAT_WINDOW';

/**
 * @constant {'SET_ACTIVE_CHAT_WINDOW'} ActionTypes.SET_ACTIVE_CHAT_WINDOW
 */
export const SET_ACTIVE_CHAT_WINDOW = 'SET_ACTIVE_CHAT_WINDOW';

/**
 * @constant {'SET_ERROR'} ActionTypes.SET_ERROR
 */
export const SET_ERROR = 'SET_ERROR';

/**
 * @constant {'CANCEL_ERROR'} ActionTypes.CANCEL_ERROR
 */
export const CANCEL_ERROR = 'CANCEL_ERROR';

/**
 * @constant {'SET_UNREAD_CHATS'} ActionTypes.SET_UNREAD_CHATS
 */
export const SET_UNREAD_CHATS = 'SET_UNREAD_CHATS';

/**
 * @constant {'REMOVE_UNREAD_CHAT'} ActionTypes.REMOVE_UNREAD_CHAT
 */
export const REMOVE_UNREAD_CHAT = 'REMOVE_UNREAD_CHAT';

/**
 * @constant {'ADD_ACTIVE_CHAT'} ActionTypes.ADD_ACTIVE_CHAT
 */
export const ADD_ACTIVE_CHAT = 'ADD_ACTIVE_CHAT';

/**
 * @constant {'SET_ACTIVE_CHATS'} ActionTypes.SET_ACTIVE_CHATS
 */
export const SET_ACTIVE_CHATS = 'SET_ACTIVE_CHATS';

/**
 * @constant {'REMOVE_ACTIVE_CHAT'} ActionTypes.REMOVE_ACTIVE_CHAT
 */
export const REMOVE_ACTIVE_CHAT = 'REMOVE_ACTIVE_CHAT';

/**
 * @constant {'REPLACE_TEMPORARY_ID'} ActionTypes.REPLACE_TEMPORARY_ID
 */
export const REPLACE_TEMPORARY_ID = 'REPLACE_TEMPORARY_ID';

/**
 * @constant {'SET_CALLBACK'} ActionTypes.SET_CALLBACK
 */
export const SET_CALLBACK = 'SET_CALLBACK';

/**
 * @constant {'EXECUTE_CALLBACK'} ActionTypes.EXECUTE_CALLBACK
 */
export const EXECUTE_CALLBACK = 'EXECUTE_CALLBACK';

/**
 * @constant {'SET_HIDDEN_WINDOWS'} ActionTypes.SET_HIDDEN_WINDOWS
 */
export const SET_HIDDEN_WINDOWS = 'SET_HIDDEN_WINDOWS';

/**
 * @constant {'SET_ACTIVE_STATUS'} ActionTypes.SET_ACTIVE_STATUS
 */
export const SET_ACTIVE_STATUS = 'SET_ACTIVE_STATUS';

/**
 * @constant {'SET_SOUNDS'} ActionTypes.SET_SOUNDS
 */
export const SET_SOUNDS = 'SET_SOUNDS';

/**
 * @constant {'SET_BUSY'} ActionTypes.SET_BUSY
 */
export const SET_BUSY = 'SET_BUSY';

/**
 * @constant {'TOGGLE_SEARCH'} ActionTypes.TOGGLE_SEARCH
 */
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';

/**
 * @constant {'SET_SEARCH_QUERY'} ActionTypes.SET_SEARCH_QUERY
 */
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';

/* combined */

/**
 * @constant {'RESET_APP_STATE'} ActionTypes.RESET_APP_STATE
 */
export const RESET_APP_STATE = 'RESET_APP_STATE';

/* chatUser */

/**
 * @constant {'SET_USER'} ActionTypes.SET_USER
 */
export const SET_USER = 'SET_USER';

/**
 * @constant {'SET_CHAT_USER'} ActionTypes.SET_CHAT_USER
 */
export const SET_CHAT_USER = 'SET_CHAT_USER';

/**
 * @constant {'ADD_GROUP'} ActionTypes.ADD_GROUP
 */
export const ADD_GROUP = 'ADD_GROUP';

/**
 * @constant {'DELETE_GROUP'} ActionTypes.DELETE_GROUP
 */
export const DELETE_GROUP = 'DELETE_GROUP';

/**
 * @constant {'SET_USERS'} ActionTypes.SET_USERS
 */
export const SET_USERS = 'SET_USERS';

/**
 * @constant {'SET_GROUPS'} ActionTypes.SET_GROUPS
 */
export const SET_GROUPS = 'SET_GROUPS';

/**
 * @constant {'SET_USER_ACTIVE_STATUS'} ActionTypes.SET_USER_ACTIVE_STATUS
 */
export const SET_USER_ACTIVE_STATUS = 'SET_USER_ACTIVE_STATUS';

/* chats */

/**
 * @constant {'OPEN_CHAT'} ActionTypes.OPEN_CHAT
 */
export const OPEN_CHAT = 'OPEN_CHAT';

/**
 * @constant {'APPEND_CHAT_MESSAGE'} ActionTypes.APPEND_CHAT_MESSAGE
 */
export const APPEND_CHAT_MESSAGE = 'APPEND_CHAT_MESSAGE';

/**
 * @constant {'PREPEND_CHAT_MESSAGES'} ActionTypes.PREPEND_CHAT_MESSAGES
 */
export const PREPEND_CHAT_MESSAGES = 'PREPEND_CHAT_MESSAGES';

/**
 * @constant {'RESET_CHAT'} ActionTypes.RESET_CHAT
 */
export const RESET_CHAT = 'RESET_CHAT';

/**
 * @constant {'SET_BOTTOM_OFFSET'} ActionTypes.SET_BOTTOM_OFFSET
 */
export const SET_BOTTOM_OFFSET = 'SET_BOTTOM_OFFSET';

/**
 * @constant {'SET_SCROLLED_UP'} ActionTypes.SET_SCROLLED_UP
 */
export const SET_SCROLLED_UP = 'SET_SCROLLED_UP';

/**
 * @constant {'SET_CHAT_WINDOW_LOADING'} ActionTypes.SET_CHAT_WINDOW_LOADING
 */
export const SET_CHAT_WINDOW_LOADING = 'SET_CHAT_WINDOW_LOADING';

/**
 * @constant {'REPLACE_MESSAGE'} ActionTypes.REPLACE_MESSAGE
 */
export const REPLACE_MESSAGE = 'REPLACE_MESSAGE';

/**
 * @constant {'REPLACE_MESSAGES_ID'} ActionTypes.REPLACE_MESSAGES_ID
 */
export const REPLACE_MESSAGES_ID = 'REPLACE_MESSAGES_ID';

/**
 * @constant {'SET_NEW_MESSAGE'} ActionTypes.SET_NEW_MESSAGE
 */
export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';

/**
 * @constant {'SET_CHAT_WINDOW_MINIMIZED'} ActionTypes.SET_CHAT_WINDOW_MINIMIZED
 */
export const SET_CHAT_WINDOW_MINIMIZED = 'SET_CHAT_WINDOW_MINIMIZED';

/**
 * @constant {'SET_NOTIFICATIONS'} ActionTypes.SET_NOTIFICATIONS
 */
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

/**
 * @constant {'SET_NOTIFICATION'} ActionTypes.SET_NOTIFICATION
 */
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

/**
 * @constant {'REMOVE_NOTIFICATION'} ActionTypes.REMOVE_NOTIFICATION
 */
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

/**
 * @constant {'UPDATE_LAST_MESSAGE'} ActionTypes.UPDATE_LAST_MESSAGE
 */
export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';

/**
 * @constant {'SET_LAST_READ'} ActionTypes.SET_LAST_READ
 */
export const SET_LAST_READ = 'SET_LAST_READ';

/**
 * @constant {'UPDATE_LAST_READ'} ActionTypes.UPDATE_LAST_READ
 */
export const UPDATE_LAST_READ = 'UPDATE_LAST_READ';
