import fileDownload from 'js-file-download';
import { general } from 'src/constants/subtitles';

export const downloadFileFromResponse = async (
    response: Response,
    dafaultName: string = general.defaultFileName
) => {
    const fileName =
        response.headers
            .get('Content-Disposition')
            ?.split('filename=')[1]
            .split(';')[0]
            .replaceAll('"', '') ?? dafaultName;

    const blob = await response.blob();
    if (typeof window.escape === 'function') {
        await fileDownload(blob, decodeURIComponent(escape(fileName)));
    } else {
        await fileDownload(blob, fileName);
    }
};
