import React, { FC } from 'react';

import styles from './UIFieldNip.module.css';
import { UiInputButtonNormal } from '../shared/uiInputButtonNormal/UiInputButtonNormal';
import { UIFieldNipProps } from 'src/components/popupFormExtended/dynamicField/fieldNip/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { fields } from 'src/constants/subtitles';

export const UIFieldNip: FC<UIFieldNipProps> = (props) => {
    const {
        value,
        onChange,
        onBlur,
        uiFieldCommonProps,
        uiFieldSharedProps,
        onButtonClick,
        infoText
    } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                <UiInputButtonNormal
                    {...uiFieldCommonProps}
                    text={fields.fieldNip.button}
                    onClick={onButtonClick}
                />
            </div>
            {infoText && <p>{infoText}</p>}
        </UiWrapper>
    );
};
