import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiBudgetData } from './types';

export async function getBudgetInitData(
    obiekt: string,
    id: string,
    withPrecedingYears?: string
): Promise<BaseApiResponse<ApiBudgetData>> {
    const param = withPrecedingYears
        ? [
              ['obiekt', obiekt],
              ['id', id],
              ['withPrecedingYears', withPrecedingYears]
          ]
        : [
              ['obiekt', obiekt],
              ['id', id]
          ];
    const params = new URLSearchParams(param);
    return simpleQueryAPIData(
        `api_react/src/budget/getBudgetScreenData.php?${params.toString()}`,
        'GET'
    );
}
