import React, { FC } from 'react';

import { DynamicFormObject } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObject';
import { DynamicFormObjectWrapperProps } from './types.td';
import { FormContext } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import { LoaderWewTwo } from 'src/components/shared/organisms/loader/LoaderWewTwo';

export const DynamicFormObjectWrapper: FC<DynamicFormObjectWrapperProps> = ({
    availableWidth,
    keyPopup
}) => {
    const { formState } = React.useContext(FormContext);

    if (!formState.onMountForm) {
        return <LoaderWewTwo />;
    }

    return <DynamicFormObject availableWidth={availableWidth} keyPopup={keyPopup} />;
};
