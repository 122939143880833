import { makeStyles } from '@material-ui/core/styles';

export const useMenuPropsPaperClass = (mainColor: string) => {
    const useStyles = makeStyles({
        paper: {
            background: '#fff!important',
            fontWeight: 'bold',
            border: `solid 1px ${mainColor}`,
            transform: 'translateX(-2px)'
        }
    });
    return useStyles();
};

export const sxInputSelect = (mainColor: string) => ({
    border: `solid 1px ${mainColor}`,
    background: 'transparent',
    paddingLeft: '0px',
    '& fieldset': {
        background: 'transparent',
        border: '0px!important',
        width: '100%',
        '& legend': {
            display: 'none'
        }
    }
});
