import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldCostsObjectProps } from './types';
import { UIFieldCosts } from 'src/components/popupFormExtended/dynamicField/fieldCosts/UiFieldCosts';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { fieldCostsOp } from 'src/constants/optionsForSelects';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { FieldCostsCurrencyBasicType } from 'src/data/fieldsFormType';

export const FieldCostsObject: FC<FieldCostsObjectProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const [state, setState] = React.useState(field.value);

    const onChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: {
                ...field.value,
                currency: e.target.value as FieldCostsCurrencyBasicType
            }
        };
        setState((prev) => {
            return {
                ...prev,
                currency: e.target.value as FieldCostsCurrencyBasicType
            };
        });
        dispatch({
            type: 'koszty',
            payload: {
                code: keyOfFieldInState,
                value: newValue
            }
        });
    };

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: {
                ...field.value,
                value: e.target.value
            }
        };
        setState((prev) => {
            return {
                ...prev,
                value: e.target.value
            };
        });
        if (onBlur) {
            dispatch({
                type: 'koszty',
                payload: {
                    code: keyOfFieldInState,
                    value: newValue
                }
            });
            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: newValue,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        }
    };

    React.useEffect(() => {
        setState((prev) => {
            return {
                ...prev,
                value: field.value.value
            };
        });
    }, [field.value.value]);

    return (
        <UIFieldCosts
            value={state}
            onChangeSelect={onChangeSelect}
            onChangeInput={(e) => onChangeInput(e, false)}
            onBlurInput={(e) => onChangeInput(e, true)}
            opcje={fieldCostsOp}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
