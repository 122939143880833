import {
    FormState,
    SumsRelationship
} from 'src/components/popupFormExtended/dynamicFormObject/types';
import { CalcSumsF, TempFieldsValues } from '../types';
import { Fields } from 'src/components/popupFormExtended/dynamicField/types';
import { PopupFormRelatedObject } from 'src/data/popupFormTypes';
import { normaliseStringNumber } from 'src/utils';
import {
    FieldCostsBasicTypeValue,
    FieldNumberBasicTypeValue,
    FieldPriceBasicTypeValue,
    FieldPriceNumberBasicTypeValue
} from 'src/data/fieldsFormType';

export type SumsManagerProps = {
    formState: FormState;
    tempFieldsState: {
        [key: string]: Fields;
    };
    tempFieldsValues?: TempFieldsValues;
    sums: SumsRelationship;
};

export class SumsCalcManager {
    formState: FormState;
    fieldsStateToCalcSums: {
        [key: string]: Fields;
    };
    tempFieldsState: {
        [key: string]: Fields;
    };
    tempFieldsValues: TempFieldsValues;
    sums: SumsRelationship;
    constructor({ formState, tempFieldsState, tempFieldsValues, sums }: SumsManagerProps) {
        this.formState = formState;
        this.fieldsStateToCalcSums = {};
        this.sums = sums;
        this.tempFieldsState = tempFieldsState;
        this.tempFieldsValues = tempFieldsValues ?? {
            sums: {
                value: '0'
            }
        };
    }

    runManyRelation() {
        for (const sumKey in this.sums) {
            if (!sumKey.includes('brutto')) {
                this.getFieldsToCalcOne({ sumKey });
                const firstField = Object.values(this.fieldsStateToCalcSums)[0];
                this.sum(firstField);
            }
            this.fieldsStateToCalcSums = {};
        }
        return this.sums;
    }

    async runFieldsWasChanged() {
        for (const key in this.tempFieldsState) {
            this.fieldsStateToCalcSums = {};
            if (this.tempFieldsState[key]?.placement !== 'main') {
                this.getFieldsToCalcOne({
                    field: this.tempFieldsState[key],
                    sumKey: this.tempFieldsState[key]?.sumKey
                });
                this.sum(this.tempFieldsState[key]);
            }
        }
        return this.sums;
    }

    private async getFieldsToCalcOne({ field, sumKey }: { field?: Fields; sumKey?: string }) {
        for (const key in this.formState.fields) {
            if (this.formState.fields[key]?.sumKey === sumKey) {
                this.fieldsStateToCalcSums[key] = this.formState.fields[key];
            }
        }
        if (field) {
            Object.assign(this.fieldsStateToCalcSums, {
                [field?.fieldKey ?? '']: field
            });
        }
    }

    private async sum(field: Fields) {
        const value: SumsRelationship = {};
        let value1 = 0;
        let value2 = 0;
        switch (field?.typ) {
            case 'cena_liczba':
                for (const property in this.fieldsStateToCalcSums) {
                    if (this.checkIsNotDelete(property)) {
                        if (this.fieldsStateToCalcSums[property]?.typ === 'cena_liczba') {
                            value1 =
                                value1 +
                                Number(
                                    (
                                        this.fieldsStateToCalcSums[property]
                                            ?.value as FieldPriceNumberBasicTypeValue
                                    ).total_netto
                                );
                            value2 =
                                value2 +
                                Number(
                                    (
                                        this.fieldsStateToCalcSums[property]
                                            ?.value as FieldPriceNumberBasicTypeValue
                                    ).total_brutto
                                );
                        }
                    }
                }
                value[`sumowanie_gorne_${field.relationId}_${field.kod}_do_porownan`] = value1;
                value[`sumowanie_gorne_${field.relationId}_${field.kod}_do_porownan_brutto`] =
                    value2;
                break;
            case 'cena':
                for (const property in this.fieldsStateToCalcSums) {
                    if (this.checkIsNotDelete(property)) {
                        if (this.fieldsStateToCalcSums[property]?.typ === 'cena') {
                            value1 =
                                value1 +
                                Number(
                                    (
                                        this.fieldsStateToCalcSums[property]
                                            ?.value as FieldPriceBasicTypeValue
                                    ).netto
                                );
                            value2 =
                                value2 +
                                Number(
                                    (
                                        this.fieldsStateToCalcSums[property]
                                            ?.value as FieldPriceBasicTypeValue
                                    ).brutto
                                );
                        }
                    }
                }
                value[`sumowanie_gorne_${field.relationId}_${field.kod}_do_porownan`] = value1;
                value[`sumowanie_gorne_${field.relationId}_${field.kod}_do_porownan_brutto`] =
                    value2;
                break;
            case 'liczba':
                for (const property in this.fieldsStateToCalcSums) {
                    if (this.checkIsNotDelete(property)) {
                        if (this.fieldsStateToCalcSums[property]?.typ === 'liczba') {
                            value1 =
                                value1 +
                                Number(
                                    this.fieldsStateToCalcSums[property]
                                        ?.value as FieldNumberBasicTypeValue
                                );
                        }
                    }
                }

                value[`sumowanie_gorne_${field.relationId}_${field.kod}_do_porownan`] = value1;
                break;
            case 'koszty':
                for (const property in this.fieldsStateToCalcSums) {
                    if (this.checkIsNotDelete(property)) {
                        if (this.fieldsStateToCalcSums[property]?.typ === 'koszty') {
                            value1 =
                                value1 +
                                Number(
                                    (
                                        this.fieldsStateToCalcSums[property]
                                            ?.value as FieldCostsBasicTypeValue
                                    )?.value
                                );
                        }
                    }
                }
                value[`sumowanie_gorne_${field.relationId}_${field.kod}_do_porownan`] = value1;
                break;
            default:
                break;
        }

        if (Object.keys(value).length) {
            this.tempFieldsValues.sums.value = String(value1);
            for (const key in value) {
                this.sums[key] = value[key];
            }
        }
    }
    private checkIsNotDelete(property: string) {
        const key = this.fieldsStateToCalcSums[property]?.startFieldKey + '_powiazanie_usun_obiekt';
        const isToDelete = this.formState.deleteRelationship[key];
        return isToDelete !== '1';
    }

    static createRelationSum(relation: PopupFormRelatedObject) {
        const sums: { [key: string]: unknown } = {};
        relation.fields.forEach((item) => {
            switch (item.typ) {
                case 'cena_liczba': {
                    sums[`sumowanie_gorne_${relation.id}_${item.kod}_do_porownan`] =
                        SumsCalcManager.getSumsFromObjectData(relation, `${item.kod}_total_netto`);
                    sums[`sumowanie_gorne_${relation.id}_${item.kod}_do_porownan_brutto`] =
                        SumsCalcManager.getSumsFromObjectData(relation, `${item.kod}_total_brutto`);
                    break;
                }
                case 'koszty':
                case 'liczba':
                    sums[`sumowanie_gorne_${relation.id}_${item.kod}_do_porownan`] =
                        SumsCalcManager.getSumsFromObjectData(relation, item.kod);
                    break;
                default:
                    break;
            }
        });
        return sums;
    }

    static getSumsFromObjectData(relation: PopupFormRelatedObject, kod: string) {
        return relation.objectsData
            .map((item2) => {
                if (typeof item2?.values[kod] === 'string') {
                    return Number(normaliseStringNumber(item2?.values[kod]));
                }
                return 0;
            })
            .reduce((a, b) => a + b, 0);
    }
}
