import React, { FC } from 'react';

import { UiCheckbox } from 'src/components/shared/atoms/uiInputs/uiCheckbox/UiCheckbox';
import Plus from 'src/components/object/atoms/plus/Plus';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import TrashIcon from 'src/assets/kosz.svg';
import styles from './UiFieldMailMany.module.css';
import { UIFieldMailManyProps } from 'src/components/popupFormExtended/dynamicField/fieldMailMany/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { fields } from 'src/constants/subtitles';
import { UiLabel } from 'src/components/shared/atoms/uiInputs/uiLabel/UiLabel';

export const UIFieldMailMany: FC<UIFieldMailManyProps> = (props) => {
    const { value, onChange, uiFieldCommonProps, addMail, onDelete, uiFieldSharedProps } = props;

    return (
        <div className={styles.wrapper}>
            <div>
                <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
                {value.elements.map((item) => {
                    return (
                        <div key={item.id} className={styles.element}>
                            <p className={styles.p}>
                                {item.adres}
                                {item.isToDelete ? <div className={styles.deleteLine}></div> : null}
                            </p>
                            <div className={styles.elementBox}>
                                <div className={styles.checkbox}>
                                    <p>{fields.fieldMany.firstCheckbox}</p>
                                    <UiCheckbox
                                        sxelwrapper={{
                                            justifyContent: 'start'
                                        }}
                                        sxcheckbox={{
                                            padding: '3px'
                                        }}
                                        value={item.korespondencja}
                                        checked={item.korespondencja}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onChange({
                                                e,
                                                onBlur: true,
                                                type: 'korespondencja',
                                                id: item?.id,
                                                key: 'elements'
                                            })
                                        }
                                    />
                                </div>
                                <div className={styles.checkbox}>
                                    <p>{fields.fieldMany.secondCheckbox}</p>
                                    <UiCheckbox
                                        sxelwrapper={{
                                            justifyContent: 'start'
                                        }}
                                        sxcheckbox={{
                                            padding: '3px'
                                        }}
                                        value={item.wysylka_reklamowa}
                                        checked={item.wysylka_reklamowa}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onChange({
                                                e,
                                                onBlur: true,
                                                type: 'wysylka_reklamowa',
                                                id: item?.id,
                                                key: 'elements'
                                            })
                                        }
                                    />
                                </div>
                                <div className={styles.checkbox}>
                                    <p>{fields.fieldMany.third}</p>
                                    <UiCheckbox
                                        sxelwrapper={{
                                            justifyContent: 'start'
                                        }}
                                        sxcheckbox={{
                                            padding: '3px'
                                        }}
                                        value={item.wysylka_raportow}
                                        checked={item.wysylka_raportow}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onChange({
                                                e,
                                                onBlur: true,
                                                type: 'wysylka_raportow',
                                                id: item?.id,
                                                key: 'elements'
                                            })
                                        }
                                    />
                                </div>
                                <div
                                    onClick={(e: React.MouseEvent<HTMLElement>) =>
                                        onDelete({ e, id: item.id, key: 'elements' })
                                    }
                                    className={styles.deteleTrash}>
                                    <ThemeCompatibleIcon
                                        SvgComponent={TrashIcon}
                                        fontSize={'medium'}
                                        sx={{
                                            width: '13px'
                                        }}
                                    />
                                </div>
                                {item.isToDelete ? <div className={styles.deleteLine}></div> : null}
                            </div>
                        </div>
                    );
                })}
            </div>
            {value.newElements.map((item) => {
                return (
                    <div className={styles.newElement} key={item.id}>
                        <div className={styles.newElementInputBox}>
                            <UiInputNormalTwo
                                {...uiFieldCommonProps}
                                value={item.adres}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onChange({
                                        e,
                                        onBlur: false,
                                        type: 'adres',
                                        id: item?.id,
                                        key: 'newElements'
                                    })
                                }
                                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                    onChange({
                                        e,
                                        onBlur: true,
                                        type: 'adres',
                                        id: item?.id,
                                        key: 'newElements'
                                    })
                                }
                            />
                            <div
                                onClick={(e: React.MouseEvent<HTMLElement>) =>
                                    onDelete({ e, id: item.id, key: 'newElements' })
                                }
                                className={styles.deteleTrash}>
                                <ThemeCompatibleIcon
                                    SvgComponent={TrashIcon}
                                    fontSize={'medium'}
                                    sx={{
                                        width: '13px'
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.newElementCheckbox}>
                            <div className={styles.checkbox}>
                                <UiLabel label={fields.fieldMany.firstCheckbox} />
                                <UiCheckbox
                                    sxelwrapper={{
                                        justifyContent: 'start'
                                    }}
                                    sxcheckbox={{
                                        padding: '3px'
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        onChange({
                                            e,
                                            onBlur: true,
                                            type: 'korespondencja',
                                            id: item?.id,
                                            key: 'newElements'
                                        })
                                    }
                                    value={item.korespondencja}
                                    checked={item.korespondencja}
                                />
                            </div>
                            <div className={styles.checkbox}>
                                <UiLabel label={fields.fieldMany.secondCheckbox} />
                                <UiCheckbox
                                    sxelwrapper={{
                                        justifyContent: 'start'
                                    }}
                                    sxcheckbox={{
                                        padding: '3px'
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        onChange({
                                            e,
                                            onBlur: true,
                                            type: 'wysylka_reklamowa',
                                            id: item?.id,
                                            key: 'newElements'
                                        })
                                    }
                                    value={item.wysylka_reklamowa}
                                    checked={item.wysylka_reklamowa}
                                />
                            </div>
                            <div className={styles.checkbox}>
                                <UiLabel label={fields.fieldMany.third} />
                                <UiCheckbox
                                    sxelwrapper={{
                                        justifyContent: 'start'
                                    }}
                                    sxcheckbox={{
                                        padding: '3px'
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        onChange({
                                            e,
                                            onBlur: true,
                                            type: 'wysylka_raportow',
                                            id: item?.id,
                                            key: 'newElements'
                                        })
                                    }
                                    value={item.wysylka_raportow}
                                    checked={item.wysylka_raportow}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
            <div onClick={addMail} className={styles.add}>
                <Plus style={{ margin: 0 }} />
                <UiLabel label={fields.fieldMany.addNew} />
            </div>
        </div>
    );
};
