import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
    popper: {
        zIndex: 150000
    },
    tooltip: {
        fontSize: 12,
        backgroundColor: palette.background.paper,
        color: palette.text.primary,
        border: `1px solid ${palette.grey[500]}`,
        maxWidth: 220
    }
}));
