import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const RaportView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ObjectView" */ 'src/components/raportScreen/templates/raportView/RaportView'
        )
);

export const RaportMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Raporty');
    return (
        <div>
            <React.Suspense fallback>
                <RaportView />
            </React.Suspense>
        </div>
    );
};
