import React from 'react';
import { useDispatch } from 'react-redux';
import NoteIcon from 'src/assets/ikona_kalendarz.svg';
import OpenBtn from 'src/widgets/shared/components/openButton/OpenButton';
import { toggleOpen } from './store/actions';
import { topMenu } from 'src/constants/subtitles';

import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
const TodoTop = React.lazy(() => import(/* webpackChunkName: "Todo" */ './TodoTop'));

const TodoApp = ({ systemUrl }) => {
    const dispatch = useDispatch();
    const toggleTodoOpen = () => dispatch(toggleOpen());

    const [todo, setTodo] = React.useState(false);
    const handleLoad = () => {
        if (!todo) setTodo(true);
    };
    return (
        <>
            <OpenBtn
                handleOpen={toggleTodoOpen}
                handleLoad={handleLoad}
                tooltipText={topMenu.tooltipOnHover.todo}
                iconComponent={
                    <ThemeCompatibleIcon
                        SvgComponent={NoteIcon}
                        fontSize={'small'}
                        sx={{
                            position: 'absolute',
                            fontSize: '1rem',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)'
                        }}
                    />
                }
            />
            <React.Suspense fallback>
                {todo && <TodoTop toggleTodoOpen={toggleTodoOpen} systemUrl={systemUrl} />}
            </React.Suspense>
        </>
    );
};

export default TodoApp;
