export class DynamicFormObjectDataConverter {
    static getArrayIdAndText(data: { [key: string]: string }) {
        if (typeof data !== 'object' || Array.isArray(data)) return [];
        return Object.entries(data).map((item) => {
            return {
                id: item[0],
                text: item[1]
            };
        });
    }
    static setObjectKeyValue(data: { id: string; text: string }[]) {
        const dataNew: { [key: string]: string } = {
            0: ''
        };
        data.forEach((item) => {
            dataNew[item.id] = item.text;
        });
        return dataNew;
    }
    static getArrayNameAndValue(data: { [key: string]: string }) {
        if (typeof data !== 'object' || Array.isArray(data)) return [];
        return Object.entries(data).map((item) => {
            return {
                value: item[0],
                name: item[1]
            };
        });
    }
}
