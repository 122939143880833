import React, { Reducer } from 'react';

type DatePanel = Date;
type DateSelected = Date | null;
type DateInputVisible = string;
type MinutesSelected = string;
type HoursSelected = string;
type TimeVisible = string;
type SignDivider = string;
type IsPickerOpen = boolean;
type IsErrorDateOpen = boolean;
type IsErrorTimeOpen = boolean;
type IsCheckboxToday = boolean;
type IsInputTime = boolean;
type IsToday = boolean;

export type DatePickerState = {
    datePanel: DatePanel;
    dateSelected: DateSelected;
    dateInputVisible: DateInputVisible;
    minutesSelected: MinutesSelected;
    hoursSelected: HoursSelected;
    timeVisible: TimeVisible;
    signDivider: SignDivider;
    isPickerOpen: IsPickerOpen;
    isErrorDateOpen: IsErrorDateOpen;
    isErrorTimeOpen: IsErrorTimeOpen;
    isCheckboxToday: IsCheckboxToday;
    isInputTime: IsInputTime;
    isToday: IsToday;
};

export enum ActionKind {
    OPEN_PICKER = 'OPEN_PICKER',
    CLOSE_PICKER = 'CLOSE_PICKER',
    TOGGLE_PICKER = 'TOGGLE_PICKER',
    EDIT_INPUT = 'EDIT_INPUT',
    SET_SELECTED_DATE = 'SET_SELECTED_DATE',
    SET_PANEL_DATE = 'SET_PANEL_DATE',
    CLOSE_PICKER_WITH_RESET = 'CLOSE_PICKER_WITH_RESET',
    SET_SELECTED_DATE_WITHOUT_CLOSE = 'SET_SELECTED_DATE_WITHOUT_CLOSE',
    SET_SELECTED_DATE_WITH_CLOSE = 'SET_SELECTED_DATE_WITH_CLOSE',
    CLOSE_ERROR = 'CLOSE_ERROR',
    OPEN_ERROR_DATE = 'OPEN_ERROR_DATE',
    OPEN_ERROR_TIME = 'OPEN_ERROR_TIME',
    SET_TODAY = 'SET_TODAY',
    UNSET_TODAY = 'UNSET_TODAY',
    SET_VISIBLE_TIME = 'SET_VISIBLE_TIME',
    SET_TIME = 'SET_TIME',
    RESET = 'RESET',
    RESET_DATE_SELECTED = 'RESET_DATE_SELECTED'
}

type ActionOpenPicker = {
    type: 'OPEN_PICKER';
};

type ActionTogglePicker = {
    type: 'TOGGLE_PICKER';
};

type ActionClosePicker = {
    type: 'CLOSE_PICKER';
};

type ActionEditInput = {
    type: 'EDIT_INPUT';
    payload: {
        dateInputVisible: DateInputVisible;
    };
};

type ActionClosePickerWithReset = {
    type: 'CLOSE_PICKER_WITH_RESET';
};

type ActionReset = {
    type: 'RESET';
};

type ActionSetSelectedDate = {
    type: 'SET_SELECTED_DATE';
    payload: {
        datePanel: DatePanel;
        dateSelected: DateSelected;
        dateInputVisible: DateInputVisible;
        isToday: IsToday;
        hoursSelected: HoursSelected;
        minutesSelected: MinutesSelected;
        timeVisible: TimeVisible;
    };
};

type ActionSetSelectedDateWithoutClose = {
    type: 'SET_SELECTED_DATE_WITHOUT_CLOSE';
    payload: {
        datePanel: DatePanel;
        dateSelected: DateSelected;
        dateInputVisible: DateInputVisible;
        isToday: IsToday;
        hoursSelected: HoursSelected;
        minutesSelected: MinutesSelected;
    };
};

type ActionSetPanelDate = {
    type: 'SET_PANEL_DATE';
    payload: {
        datePanel: Date;
    };
};

type ActionCloseError = {
    type: 'CLOSE_ERROR';
};

type ActionOpenErrorDate = {
    type: 'OPEN_ERROR_DATE';
};

type ActionOpenErrorTime = {
    type: 'OPEN_ERROR_TIME';
};

type ActionSetToday = {
    type: 'SET_TODAY';
    payload: {
        datePanel: DatePanel;
        dateSelected: DateSelected;
        dateInputVisible: DateInputVisible;
        hoursSelected: HoursSelected;
        minutesSelected: MinutesSelected;
        timeVisible: TimeVisible;
    };
};

type ActionResetDateSelected = {
    type: 'RESET_DATE_SELECTED';
    payload: {
        dateSelected: DateSelected;
    };
};

type ActionUnsetToday = {
    type: 'UNSET_TODAY';
};

type ActionSetVisibleTime = {
    type: 'SET_VISIBLE_TIME';
    payload: {
        timeVisible: TimeVisible;
    };
};

type ActionSetTime = {
    type: 'SET_TIME';
    payload: {
        timeVisible: TimeVisible;
        hoursSelected: HoursSelected;
        minutesSelected: MinutesSelected;
        datePanel: DatePanel;
        dateSelected: DateSelected;
        dateInputVisible: DateInputVisible;
        isToday: IsToday;
    };
};

type ActionSetSelectedDateWithClose = {
    type: 'SET_SELECTED_DATE_WITH_CLOSE';
    payload: {
        datePanel: DatePanel;
        dateSelected: DateSelected;
        dateInputVisible: DateInputVisible;
        hoursSelected: HoursSelected;
        minutesSelected: MinutesSelected;
        isToday: IsToday;
    };
};

export type Action =
    | ActionCloseError
    | ActionClosePicker
    | ActionOpenPicker
    | ActionSetTime
    | ActionSetVisibleTime
    | ActionUnsetToday
    | ActionSetToday
    | ActionOpenErrorTime
    | ActionOpenErrorDate
    | ActionSetPanelDate
    | ActionSetSelectedDateWithoutClose
    | ActionSetSelectedDate
    | ActionReset
    | ActionClosePickerWithReset
    | ActionEditInput
    | ActionTogglePicker
    | ActionOpenPicker
    | ActionSetSelectedDateWithClose
    | ActionResetDateSelected;

export const initialState = {
    dateSelected: null,
    datePanel: new Date(),
    dateInputVisible: '',
    isPickerOpen: false,
    isErrorDateOpen: false,
    isErrorTimeOpen: false,
    isCheckboxToday: false,
    isToday: false,
    isInputTime: false,
    minutesSelected: '00',
    hoursSelected: '00',
    timeVisible: '00:00',
    signDivider: '-'
};

export const reducer: Reducer<DatePickerState, Action> = (state, action) => {
    switch (action.type) {
        case 'OPEN_PICKER':
            return {
                ...state,
                isPickerOpen: true
            };
        case 'TOGGLE_PICKER':
            return {
                ...state,
                isPickerOpen: !state.isPickerOpen
            };
        case 'CLOSE_PICKER':
            return {
                ...state,
                isPickerOpen: false
            };
        case 'CLOSE_PICKER_WITH_RESET':
            return {
                ...state,
                isPickerOpen: false
            };
        case 'EDIT_INPUT':
            return {
                ...state,
                dateInputVisible: action.payload.dateInputVisible
            };

        case 'RESET':
            return {
                ...state,
                isPickerOpen: false,
                isToday: false,
                dateSelected: null,
                dateInputVisible: ''
            };
        case 'SET_SELECTED_DATE':
            return {
                ...state,
                isPickerOpen: false,
                datePanel: action.payload.datePanel,
                dateSelected: action.payload.dateSelected,
                dateInputVisible: action.payload.dateInputVisible,
                isToday: action.payload.isToday
            };
        case 'SET_SELECTED_DATE_WITHOUT_CLOSE':
            return {
                ...state,
                isPickerOpen: true,
                datePanel: action.payload.datePanel,
                dateSelected: action.payload.dateSelected,
                dateInputVisible: action.payload.dateInputVisible,
                hoursSelected: action.payload.hoursSelected,
                minutesSelected: action.payload.minutesSelected,
                isToday: action.payload.isToday
            };
        case 'SET_SELECTED_DATE_WITH_CLOSE': {
            return {
                ...state,
                isPickerOpen: false,
                datePanel: action.payload.datePanel,
                dateSelected: action.payload.dateSelected,
                dateInputVisible: action.payload.dateInputVisible,
                hoursSelected: action.payload.hoursSelected,
                minutesSelected: action.payload.minutesSelected,
                isToday: action.payload.isToday
            };
        }
        case 'SET_PANEL_DATE':
            return {
                ...state,
                datePanel: action.payload.datePanel
            };
        case 'CLOSE_ERROR':
            return {
                ...state,
                isErrorDateOpen: false,
                isErrorTimeOpen: false
            };
        case 'OPEN_ERROR_DATE':
            return {
                ...state,
                isErrorDateOpen: true,
                dateSelected: null,
                isPickerOpen: false
            };
        case 'OPEN_ERROR_TIME':
            return {
                ...state,
                isErrorTimeOpen: true
            };
        case 'SET_TODAY':
            return {
                ...state,
                isToday: true,
                isPickerOpen: false,
                datePanel: action.payload.datePanel,
                dateSelected: action.payload.dateSelected,
                dateInputVisible: action.payload.dateInputVisible,
                timeVisible: action.payload.timeVisible,
                hoursSelected: action.payload.hoursSelected,
                minutesSelected: action.payload.minutesSelected
            };
        case 'UNSET_TODAY':
            return {
                ...state,
                dateSelected: null,
                dateInputVisible: '',
                timeVisible: '00:00',
                hoursSelected: '00',
                minutesSelected: '00',
                isPickerOpen: false,
                isToday: false
            };

        case 'SET_VISIBLE_TIME':
            return {
                ...state,
                timeVisible: action.payload.timeVisible
            };
        case 'SET_TIME':
            return {
                ...state,
                timeVisible: action.payload.timeVisible,
                hoursSelected: action.payload.hoursSelected,
                minutesSelected: action.payload.minutesSelected,
                datePanel: action.payload.datePanel,
                dateSelected: action.payload.dateSelected,
                dateInputVisible: action.payload.dateInputVisible,
                isToday: action.payload.isToday
            };
        case 'RESET_DATE_SELECTED':
            return {
                ...state,
                dateSelected: action.payload.dateSelected
            };
        default:
            return {
                ...state
            };
    }
};
