import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import ScrollPan from 'src/components/shared/organisms/scrollPan/ScrollPan';
import { DatePickerPanelExtendedProps } from 'src/components/shared/atoms/datePicker/DatePickerPanel';
import { ModeEnum } from 'src/components/shared/atoms/datePicker/DatePickerPanel';
import styles from './YearPicker.module.css';

export const YearPicker = ({
    panel,
    years,
    setMode,
    dispatch,
    state
}: DatePickerPanelExtendedProps) => {
    const theme = useTheme();
    const selectedYear = React.useRef<HTMLSpanElement | null>(null);

    useEffect(() => {
        if (selectedYear?.current) {
            selectedYear.current.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
    }, []);

    const setYear = (year: number) => {
        dispatch({
            type: 'SET_PANEL_DATE',
            payload: {
                ...state,
                datePanel: new Date(year, panel.month + panel.changer, 1)
            }
        });
        setMode(ModeEnum.dayMode);
    };

    return (
        <ScrollPan
            size={10}
            minHeight={'120px'}
            maxHeight={'120px'}
            maxWidth={'190px'}
            paddingBottomScroll={'0'}
            minWidth={'190px'}
            showHorizonal={false}
            boxShadow={false}
            thumbColor={'#CECECE'}
            background={'white'}
            marginTopScrollY={'-1em'}
            icon={'arrow_gray.svg'}
            offInitialScroll={true}
            showVertical={true}>
            <div
                style={{
                    color: theme.palette.primary.main
                }}
                className={styles.wrapper}>
                {years.map((el) => {
                    return (
                        <div
                            key={el}
                            onClick={() => setYear(el)}
                            className={styles.box}
                            style={{ color: theme.palette.primary.main }}>
                            <span
                                ref={(element) => {
                                    if (panel.year === el) {
                                        selectedYear.current = element;
                                    }
                                }}
                                className={styles.span}
                                style={{
                                    backgroundColor: `${
                                        el === panel.year && theme.palette.primary.main
                                    }`,
                                    color: `${el === panel.year && '#fff'}`
                                }}>
                                {el}
                            </span>
                        </div>
                    );
                })}
            </div>
        </ScrollPan>
    );
};
