export function createUrlWithParams({
    url,
    arrParams,
    strParams
}: {
    url: string;
    arrParams?: string[][];
    strParams?: string;
}): string {
    let newUrl = url;
    let newParams = new URLSearchParams();
    if (strParams) {
        newParams = new URLSearchParams(strParams);
    }
    if (arrParams) {
        arrParams.forEach((param) => {
            newParams.set(param[0], param[1]);
        });
    }
    if (strParams || arrParams) {
        newUrl += '?' + newParams.toString();
    }
    return newUrl;
}
