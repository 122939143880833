import React, { FC, KeyboardEvent } from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';

import { DynamicAutoCompleteManager } from 'src/components/shared/atoms/dynamicAutoComplete/DynamicAutoCompleteManager';
import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import { ValueOne } from 'src/components/listScreen/organisms/popupContentSearch/reducer';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import { searchForFieldValues } from 'src/api/src/shared/searchForFieldValues';
import { UiAutocompleteInputTwo } from 'src/components/shared/atoms/uiInputs/uiAutocompleteInputTwo/UiAutocompleteInputTwo';
import { useFieldClasses } from 'src/hooks';

export const DynamicAutoComplete: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter,
    confirm
}) => {
    const value = listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        ? listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        : null;
    const text = listSearchFilters[searchField.searchCode]?.fieldFilter?.value?.text
        ? listSearchFilters[searchField.searchCode]?.fieldFilter?.value?.text
        : '';
    const [inputValue, setInputValue] = React.useState(text);
    const [optionToChoose, setOptionToChoose] = React.useState<ValueOne[]>([]);

    const classes = useFieldClasses({ muiType: 'autocompleteOne' });

    const getOptionsFromApi = (value: string) => {
        if (value != null && value.length >= 3) {
            const options = DynamicAutoCompleteManager.prepareOptions(
                searchField,
                listSearchFilters
            );

            searchForFieldValues(value, searchField.autocompleteSearchMode, {
                ...options
            }).then((resp) => {
                if (resp?.success) {
                    const options = resp.data.map((item) => {
                        return {
                            ...item,
                            text: item.name
                        };
                    });
                    setOptionToChoose(options);
                } else {
                    setOptionToChoose([]);
                }
            });
        } else {
            setOptionToChoose([]);
        }
    };

    const setNewValueToParent = (valueFromInput: string) => {
        let value: ValueOne | null = null;
        if (valueFromInput) {
            value = {
                id: null,
                screen: null,
                text: valueFromInput ?? ''
            };
        }
        dispatchFilter({
            type: 'SET_VALUE_ONE',
            payload: {
                code: searchField.searchCode,
                value: value
            }
        });
    };

    return (
        <div style={{ position: 'relative' }}>
            <Autocomplete
                freeSolo
                onInputChange={(_, value: string) => {
                    setInputValue(value);
                    getOptionsFromApi(value);
                    setNewValueToParent(value);
                }}
                options={optionToChoose || null}
                value={value}
                inputValue={inputValue}
                getOptionLabel={(option: any) => {
                    return option.text || '';
                }}
                disablePortal={true}
                onChange={(_, newValue: ValueOne) => {
                    dispatchFilter({
                        type: 'SET_VALUE_ONE',
                        payload: {
                            code: searchField.searchCode,
                            value: newValue
                        }
                    });
                }}
                onKeyDown={(e: KeyboardEvent<any>) => {
                    if (e.keyCode === 13) {
                        confirm && confirm();
                    }
                }}
                clearIcon={
                    <ButtonCross
                        onClick={() => {
                            dispatchFilter({
                                type: 'SET_VALUE_ONE',
                                payload: {
                                    code: searchField.searchCode,
                                    value: null
                                }
                            });
                        }}
                        style={{
                            top: '2px'
                        }}
                    />
                }
                classes={{
                    option: classes.option,
                    listbox: classes.listbox,
                    paper: classes.paper,
                    popper: classes.popper
                }}
                renderInput={(params: AutocompleteRenderInputParams) => {
                    return <UiAutocompleteInputTwo params={params} />;
                }}
            />
        </div>
    );
};
