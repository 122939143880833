import React, { FC } from 'react';
import { useComponentSize } from 'react-use-size';

import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import Frame from 'src/components/listScreen/molecules/frame/Frame';
import { FileManager } from 'src/utils';
import { reduceStr } from 'src/utils';
import styles from './PopupContentPreview.module.css';
import FrameTwo from 'src/components/listScreen/molecules/frame/FrameTwo';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import { usePopupContentPreviewData } from 'src/hooks/src/shared/usePopupContentPreviewData';

type FrameContent = 'one' | 'two';

export type ContentPreviewItem = {
    rawValue: string;
    text: string;
    isSelected?: boolean;
    mozliwe_pobieranie?: 't' | 'f';
};

const types: { [key in FrameContent]: React.FC<{ src: string; width: number; height: number }> } = {
    one: Frame,
    two: FrameTwo
};

const PopupContentPreview: FC<BasePopupContentProps> = ({ popup }) => {
    const { ref, width, height } = useComponentSize();

    const { allDataPreview, setAllDataPreview } = usePopupContentPreviewData(popup.key);

    const [isBefore, setIsBefore] = React.useState(true);
    const [isAfter, setIsAfter] = React.useState(true);

    const [itemPreview, setItemPreview] = React.useState<{
        frame: FrameContent;
        id: string;
        title: string;
        mozliwe_pobieranie?: 't' | 'f';
    }>({
        frame: 'one',
        id: '',
        title: '',
        mozliwe_pobieranie: 't'
    });
    let srcPreview = `./js/ViewerJS/index.html?#../../pobierz_podglad_pliku.php?id=${itemPreview.id}`;

    if (popup.sourceOfTrigger.type === 'instruction') {
        srcPreview = `./js/ViewerJS/index.html?#../../instrukcje/${itemPreview.title}`;
    }
    const FrameContent = types[itemPreview.frame] ? types[itemPreview.frame] : types['one'];

    const changeId = (type: 'before' | 'after') => {
        const index = allDataPreview.findIndex((item) => item.isSelected);
        const tempData = [...allDataPreview];

        if (type === 'before' && index) {
            const newId = allDataPreview[index - 1].rawValue;
            const title = allDataPreview[index - 1].text;
            setItemPreview({
                id: newId,
                title,
                frame: itemPreview.frame === 'one' ? 'two' : 'one'
            });
            tempData[index].isSelected = false;
            tempData[index - 1].isSelected = true;
            setAllDataPreview(tempData);
            if (!tempData[index - 2]) {
                setIsBefore(false);
            }
            setIsAfter(true);
        } else if (type === 'after' && index !== allDataPreview.length - 1) {
            const newId = allDataPreview[index + 1].rawValue;
            const title = allDataPreview[index + 1].text;
            setItemPreview({
                id: newId,
                title,
                frame: itemPreview.frame === 'one' ? 'two' : 'one'
            });
            tempData[index].isSelected = false;
            tempData[index + 1].isSelected = true;
            setAllDataPreview(tempData);
            if (!tempData[index + 2]) {
                setIsAfter(false);
            }
            setIsBefore(true);
        }
    };

    const downloadFile = async () => {
        const data = {
            url: 'pobierz_plik.php',
            payload: [
                {
                    key: 'id',
                    value: itemPreview.id
                }
            ]
        };
        if (popup.sourceOfTrigger.type === 'instruction') {
            data.url = 'ekran_pdf.php';
            data.payload = [
                {
                    key: 'pobierz',
                    value: '1'
                },
                {
                    key: 'id',
                    value: itemPreview.id
                }
            ];
        }

        await FileManager.handlePreviewFile({ url: data.url, payload: data.payload });
    };

    React.useEffect(() => {
        const selected = allDataPreview.find((item) => item.isSelected);
        const selectedIndex = allDataPreview.findIndex((item) => item.isSelected);
        if (selectedIndex !== -1) {
            if (selectedIndex === 0) {
                setIsBefore(false);
            }
            if (selectedIndex === allDataPreview.length - 1) {
                setIsAfter(false);
            }
        }
        if (selected) {
            setItemPreview({
                frame: 'two',
                id: selected.rawValue,
                title: selected.text,
                mozliwe_pobieranie: selected.mozliwe_pobieranie
            });
        }
    }, [allDataPreview]);

    return (
        <div className={styles.wrapper} ref={ref}>
            <div className={styles.bar}>
                {' '}
                {isBefore && allDataPreview.length > 1 ? (
                    <div
                        onClick={() => changeId('before')}
                        style={{
                            cursor: 'pointer'
                        }}>
                        <ThemeCompatibleIcon
                            iconData={{
                                faCode: 'fa-arrow-left',
                                svgFileName: null
                            }}
                            sx={{
                                fontSize: '1.6rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: '10px'
                            }}
                        />
                    </div>
                ) : (
                    <div></div>
                )}
                <p style={{ fontSize: '16px' }}>{reduceStr(itemPreview.title, 90)}</p>
                <div style={{ display: 'flex' }}>
                    {!itemPreview.mozliwe_pobieranie || itemPreview?.mozliwe_pobieranie !== 'f' ? (
                        <div
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => downloadFile()}>
                            <ThemeCompatibleIcon
                                iconData={{
                                    faCode: 'fa-download',
                                    svgFileName: null
                                }}
                                sx={{
                                    fontSize: '1.6rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '15px'
                                }}
                            />
                        </div>
                    ) : null}
                    {isAfter && allDataPreview.length > 1 ? (
                        <div
                            onClick={() => changeId('after')}
                            style={{
                                cursor: 'pointer'
                            }}>
                            <ThemeCompatibleIcon
                                iconData={{
                                    faCode: 'fa-arrow-right',
                                    svgFileName: null
                                }}
                                sx={{
                                    fontSize: '1.6rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '10px'
                                }}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
            <div>
                {itemPreview.id && <FrameContent src={srcPreview} width={width} height={height} />}
            </div>
        </div>
    );
};

export default PopupContentPreview;
