import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiHistoryChangeObjectData } from './types';

export async function fetchHistoryChangeObjectsInitData(
    id: string,
    obiekt: string
): Promise<BaseApiResponse<ApiHistoryChangeObjectData>> {
    const params = new URLSearchParams([
        ['obiekt', obiekt],
        ['id', id]
    ]);
    return simpleQueryAPIData(
        `api_react/src/historyChangeObjectScreen/getInitialDataHistoryChangeObject.php?${params.toString()}`,
        'GET'
    );
}
