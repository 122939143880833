import React, { ChangeEventHandler, MouseEventHandler } from 'react';
import InputMask from 'react-input-mask';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { useComponentSize } from 'react-use-size';
import { UiInputNormalWithIcon } from 'src/components/shared/atoms/uiInputs/uiInputNormalWithIcon/UiInputNormalWithIcon';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import { InputProps } from 'src/components/popupFormExtended/dynamicField/types';

type InputDateProps = {
    label?: string;
    type: string;
    value: string;
    maskChar: string;
    sxinputdate?: SxProps<Theme> | undefined;
    isicon: 'true' | 'false';
    mask: string | Array<string | RegExp>;
    placeholder: string;
    handleReset: MouseEventHandler<HTMLInputElement>;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur: ChangeEventHandler<HTMLInputElement>;
    onClick: MouseEventHandler<HTMLInputElement>;
    onKeyDown: any;
    clearButton: boolean;
    disabled?: boolean;
    archived?: boolean;
    isError?: boolean;
    inputProps?: InputProps;
};

export const InputDate = (props: InputDateProps) => {
    const { ref, width: widthAvailableSpace } = useComponentSize();
    return (
        <div style={{ position: 'relative', width: '100%' }} ref={ref}>
            <InputMask alwaysShowMask={false} {...props}>
                {(inputProps: InputMask) => {
                    return (
                        <UiInputNormalWithIcon
                            {...inputProps}
                            disabled={props.disabled}
                            archived={props.archived}
                            sxinput={{
                                ...props.sxinputdate,
                                input: {
                                    '&::-webkit-input-placeholder': {
                                        paddingLeft: '0px'
                                    },
                                    '&::-ms-input-placeholder': {
                                        paddingLeft: '0px'
                                    }
                                }
                            }}
                        />
                    );
                }}
            </InputMask>
            {props.clearButton && props.value && widthAvailableSpace > 105 && (
                <ButtonCross
                    onClick={props.handleReset}
                    style={{
                        top: `${props.label ? '25px' : '6px'}`,
                        right: '25px'
                    }}
                />
            )}
        </div>
    );
};
