import React, { FC } from 'react';
import { PermissionModuleLevelProps } from './types';
import PermissionsLevelHeader from '../../molecules/permissionsLevelHeader/PermissionsLevelHeader';
import PermissionScreenLevel from '../permissionScreenLevel/PermissionScreenLevel';
import { PERIMISSION_LEVEL_INDENT } from 'src/constants/permissionsScreen';

const PermissionModuleLevel: FC<PermissionModuleLevelProps> = ({
    module,
    unfoldedState,
    toggleFolding,
    ...props
}) => {
    const moduleKey = `module_${module.id}`;
    return (
        <div>
            <PermissionsLevelHeader
                folded={unfoldedState[moduleKey]}
                onClick={() => toggleFolding(moduleKey)}
                title={module.name}
            />
            {unfoldedState[moduleKey] && (
                <div style={{ marginBottom: '0.8rem' }}>
                    {module.screens.map((screen) => (
                        <PermissionScreenLevel
                            {...props}
                            key={screen.id}
                            screen={screen}
                            toggleFolding={toggleFolding}
                            unfoldedState={unfoldedState}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default PermissionModuleLevel;
