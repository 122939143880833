import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldMail } from 'src/components/popupFormExtended/dynamicField/fieldMail/UIFieldMail';
import { FieldLoginObjectProps } from './types';
import { InputValidator } from 'src/components/popupFormExtended/dynamicField/shared/InputValidator';
import { dataSetInputKey } from 'src/constants/dataset';

export const FieldLoginObject: FC<FieldLoginObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        const checkedValue = new InputValidator({
            dynamicFieldT,
            oldValue: state,
            staticActions: ['runSize'],
            newValue: e.target.value
        }).execute();

        setState(checkedValue);
        if (onBlur) {
            dispatch({
                type: 'login',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: checkedValue
                    }
                }
            });
        }
    };

    return (
        <UIFieldMail
            value={state}
            onChange={(e) => onChange(e, false)}
            onBlur={(e) => onChange(e, true)}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
