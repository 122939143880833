import { FieldsPure } from 'src/components/popupFormExtended/dynamicField/types';

export function cloneSafetyFieldState(fieldState: FieldsPure) {
    const fieldStateToReturn: FieldsPure = {
        ...fieldState
    };
    if (typeof fieldState.value === 'object' && fieldState.value !== null) {
        fieldStateToReturn.value = {
            ...fieldState.value
        };
    }
    return fieldStateToReturn;
}
