import { BudgetScreenState, SettledObjectsCodes } from 'src/store/src/budget/budget/types';
import { createFormData } from '../../shared/createFormData';
import { BudgetFormStateRHF } from 'src/hooks/src/budget/useFormBudget';
import { NumberManager } from '../../shared/NumberManager';
import { InvoicedElementDataToCalcCurrencyToInvoiceParam } from '../types';
import { BudgetApiFormDataCreatorUtils } from './BudgetApiFormDataCreatorUtils';
import { BudgetApiFormDataPreparer } from './BudgetApiFormDataPreparer';
import { BudgetApiFormDataObjectIdsAppender } from './BudgetApiFormDataObjectIdsAppender';
import { OBCJECT_CODES_INVOLVED_TO_LIMIT } from 'src/constants/budget';

export class BudgetApiFormDataCreator extends BudgetApiFormDataCreatorUtils {
    budgetState: BudgetScreenState;
    dataState: BudgetFormStateRHF;

    constructor(budgetState: BudgetScreenState, dataState: BudgetFormStateRHF) {
        super(budgetState, dataState);

        this.budgetState = budgetState;
        this.dataState = dataState;
    }

    createApiData() {
        const { data, cost } = new BudgetApiFormDataPreparer(
            this.budgetState,
            this.dataState
        ).prepareDataForFormData();
        new BudgetApiFormDataObjectIdsAppender(
            this.budgetState,
            this.dataState
        ).appendObjectIdentifiersToData(data, this.budgetState.settlementMethods);
        this.appendInvoiceParamsToData(data);

        return { formData: createFormData({ type: 'object', data }), cost };
    }

    /**
     * appending invoice-related params to @param data like: kwota_do_faktury, kurs_faktury, waluta_faktury, waluty_do_faktury
     */
    private appendInvoiceParamsToData(data: { [key: string]: any }) {
        data['kwota_do_faktury'] = this.dataState.globalSummary.amount;
        data['kurs_faktury'] = this.budgetState.invoiceRate;
        data['waluta_faktury'] = this.budgetState.invoiceCurrency;
        data['waluty_do_faktury'] = this.calculateCurrencyToInvoiceParam();
    }

    private calculateCurrencyToInvoiceParam() {
        const currenciesElementsToSum: { [key: string]: number } = {};

        // first loop to obstain elements that we sum
        const invoicedElementsKeys: string[] = [];
        const priceAndCurrencyData: {
            [key: string]: InvoicedElementDataToCalcCurrencyToInvoiceParam;
        } = {};
        this.loopThroughDataStateMain((keyOfField, fieldValue, keyIdSplitted) => {
            if (keyIdSplitted[1] === '1001' && 'checkbox' in fieldValue && fieldValue.checkbox) {
                const objectCode = keyIdSplitted[0].split('#')[0] as SettledObjectsCodes;
                if (objectCode === 'limit_godzin') return;

                const invoicedElementsKey = this.createInvoicedElementsKey(
                    keyIdSplitted,
                    keyOfField
                );
                invoicedElementsKeys.push(invoicedElementsKey);
                priceAndCurrencyData[invoicedElementsKey] = {
                    price: 0,
                    currency: '',
                    rate: 1,
                    objectCode: objectCode,
                    methodId: keyIdSplitted[2],
                    monthDate: parseInt(keyIdSplitted[3])
                };
            }
        });

        // then we fill in second loop array of currency and total price for that invoiced elements
        this.loopThroughDataStateMain((keyOfField, fieldValue, keyIdSplitted) => {
            const elementKey = this.createInvoicedElementsKey(keyIdSplitted, keyOfField);

            if (!invoicedElementsKeys.includes(elementKey)) {
                return;
            }

            switch (keyIdSplitted[1]) {
                case '12':
                    if ('liczba' in fieldValue) {
                        priceAndCurrencyData[elementKey].price = parseFloat(
                            fieldValue.liczba ?? '0'
                        );
                    }
                    break;
                case '14':
                    if ('tekst' in fieldValue) {
                        priceAndCurrencyData[elementKey].currency = this.getCurrencyTypeSymbol(
                            fieldValue.tekst,
                            keyOfField
                        );
                    }
                    break;
                case '10':
                    if ('liczba' in fieldValue) {
                        priceAndCurrencyData[elementKey].rate = parseFloat(
                            fieldValue.liczba ?? '0'
                        );
                    }
                    break;

                default:
                    break;
            }
        });

        // create map from hourLimit to his temporary value so we will substract values of related objects from that
        const hourLimitMap: { [key: string]: number } = {};
        for (const methodId in this.dataState.globalSummaryData) {
            for (const keyOfLimit in this.dataState.globalSummaryData[methodId].limit) {
                hourLimitMap[keyOfLimit] =
                    this.dataState.globalSummaryData[methodId].limit[keyOfLimit].valuePriceTotal;
            }
        }

        // check all priceAndCurrencyData elements, when it belongs to OBCJECT_CODES_INVOLVED_TO_LIMIT, find matching 'limit_godzin' and substract limit godzin value from that element
        for (const invoicedElementData of Object.values(priceAndCurrencyData)) {
            if (!OBCJECT_CODES_INVOLVED_TO_LIMIT.includes(invoicedElementData.objectCode)) {
                continue;
            }

            const relatedHoursLimitId =
                this.dataState.globalSummaryData[invoicedElementData.methodId].monthToLimit?.[
                    invoicedElementData.monthDate
                ];
            if (!relatedHoursLimitId || !hourLimitMap[relatedHoursLimitId]) {
                continue;
            }

            // note hourLimitMap[relatedHoursLimitId] is negative value
            const tempPrice = invoicedElementData.price + hourLimitMap[relatedHoursLimitId];
            hourLimitMap[relatedHoursLimitId] = Math.min(tempPrice, 0);
            invoicedElementData.price = Math.max(tempPrice, 0);
        }

        // now we calculate the sum of prices for all currencies types
        for (const invoicedElementData of Object.values(priceAndCurrencyData)) {
            if (invoicedElementData.currency in currenciesElementsToSum) {
                currenciesElementsToSum[invoicedElementData.currency] +=
                    invoicedElementData.price / invoicedElementData.rate;
            } else {
                currenciesElementsToSum[invoicedElementData.currency] =
                    invoicedElementData.price / invoicedElementData.rate;
            }
        }

        // create string with currency type and total price
        let returnCurrencyToInvoiceString = '';
        for (const [currency, price] of Object.entries(currenciesElementsToSum)) {
            returnCurrencyToInvoiceString += `;${currency}_${NumberManager.round(price, 4)}`;
        }

        return returnCurrencyToInvoiceString;
    }

    private getCurrencyTypeSymbol(currencyText: string | null, keyOfField: string) {
        // not in switch to eslint detect that return won't be null
        if (currencyText === null) {
            return 'PLN';
        }

        switch (true) {
            case keyOfField.startsWith('ryczalty_za_sprawy_waluta_'):
                return `${currencyText}_rzs`;
            case keyOfField.startsWith('koszt_waluta_'):
                return `${currencyText}_k`;
        }

        return currencyText;
    }

    private createInvoicedElementsKey(keyIdSplitted: string[], keyOfField: string) {
        const keyEnd = keyIdSplitted.slice(2).join('!');
        const keyStart = keyIdSplitted.slice(0, 1).join('!');

        // middleKey is created from keyOfField so it contains id of specific object
        // used .split(/\D_/) to split by non-digit and underscore, because keyOfField can contain many uderscores in part of field name, an in id part, for example: koszt_price_vat_78267_01_10_2024
        const middleKey = keyOfField.split(/\D_/).slice(-1)[0];

        return keyStart + '|' + middleKey + '|' + keyEnd;
    }
}
