import React from 'react';
import { DayPicker } from 'src/components/shared/atoms/datePicker/DayPicker';
import { YearPicker } from 'src/components/shared/atoms/datePicker/YearPicker';
import { DatePickerPanel as DatePickerPanelItem } from 'src/components/shared/atoms/datePicker/DatePickerManager';
import { DatePickerHeader } from 'src/components/shared/atoms/datePicker/DatePickerHeader';
import { MonthPicker } from 'src/components/shared/atoms/datePicker/MonthPicker';
import { Action, DatePickerState } from 'src/components/shared/atoms/datePicker/reducer';
import { GetDateFn } from 'src/components/shared/atoms/datePicker/DatePicker';

export enum ModeEnum {
    monthMode,
    yearMode,
    dayMode
}

export type DatePickerPanelProps = {
    dispatch: React.Dispatch<Action>;
    state: DatePickerState;
    panel: DatePickerPanelItem;
    years: number[];
    getDate: GetDateFn;
};

export type DatePickerPanelExtendedProps = DatePickerPanelProps & {
    setMode: React.Dispatch<React.SetStateAction<ModeEnum>>;
};

export const DatePickerPanel = (props: DatePickerPanelProps) => {
    const [mode, setMode] = React.useState<ModeEnum>(ModeEnum.dayMode);

    return (
        <div>
            <DatePickerHeader {...props} setMode={setMode} />
            <div style={{ justifyContent: 'center', marginTop: '10px' }}>
                {mode === ModeEnum.monthMode && <MonthPicker {...props} setMode={setMode} />}
                {mode === ModeEnum.yearMode && (
                    <YearPicker {...props} setMode={setMode}></YearPicker>
                )}
                {mode === ModeEnum.dayMode && <DayPicker {...props} setMode={setMode}></DayPicker>}
            </div>
        </div>
    );
};
