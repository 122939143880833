import { UserApiData } from 'src/store/src/general/user/types';

export const NOT_LOGGED_USER_DATA: UserApiData = {
    grid: null,
    isLogged: false,
    loginStage2fa: false,
    login: null,
    firstName: null,
    lastName: null,
    adminFeatures: false,
    userID: null,
    userType: null,
    chat: false,
    todo: false,
    notes: false,
    timers: false,
    sessionID: '',
    timeToLogout: null,
    colorThemeSystem: null
};
