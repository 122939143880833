import { SystemAction } from 'src/data/types';

const actionPreview: SystemAction = {
    name: 'Podgląd',
    code: 'podglad',
    icon: {
        faCode: '',
        svgFileName: 'podglad.svg'
    },
    colorConditions: null,
    displayConditions: null,
    behaviour: {
        type: 'popup',
        data: {
            template: 'preview',
            data: { fileFieldCode: 'plik' }
        }
    }
};

const actionPreviewWithAttachment: SystemAction = {
    name: 'Podgląd z załącznikami',
    code: 'podglad_z_zal',
    icon: {
        faCode: '',
        svgFileName: 'poglad_zalaczniki.svg'
    },
    colorConditions: null,
    displayConditions: null,
    behaviour: {
        type: 'popup',
        data: {
            template: 'preview',
            data: { fileFieldCode: 'plik_z_zalacznikami' }
        }
    }
};

const actionDownloadOneFile: SystemAction = {
    name: 'Pobierz',
    code: 'pobierz_plik_jeden_plik',
    icon: {
        faCode: 'fa-download',
        svgFileName: 'pobierz.svg'
    },
    colorConditions: null,
    displayConditions: null,
    behaviour: {
        type: 'fileDownload',
        data: {
            url: 'pobierz_plik.php',
            params: '',
            payload: ['id']
        }
    }
};

const actionDownloadManyFiles: SystemAction = {
    name: 'Pobierz',
    code: 'pobierz_plik_wiele_plikow',
    icon: {
        faCode: 'fa-download',
        svgFileName: 'pobierz.svg'
    },
    colorConditions: null,
    displayConditions: null,
    behaviour: {
        type: 'fileDownload',
        data: {
            url: 'pobierz_pliki_zip.php',
            params: '',
            payload: ['id_plikow']
        }
    }
};

const actionDownloadWithAttachment: SystemAction = {
    name: 'Pobierz z załącznikami',
    code: 'pobierz_z_zalacznikami',
    icon: {
        faCode: '',
        svgFileName: 'pobierz_z_zalacznikami.svg'
    },
    colorConditions: null,
    displayConditions: null,
    behaviour: {
        type: 'fileDownload',
        data: {
            url: 'pobierz_pliki_zip.php',
            params: '',
            payload: ['id_plikow']
        }
    }
};

export {
    actionDownloadManyFiles,
    actionDownloadOneFile,
    actionDownloadWithAttachment,
    actionPreview,
    actionPreviewWithAttachment
};
