import * as actionTypes from '../../constants/actions';

/**
 * @function ActionCreators.setNewMessage
 * @description Ustawia flage o nieodczytanych wiadomościach w danym czacie
 * @param {string} roomID - id czatu
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const addGroup = (group) => ({
    type: actionTypes.ADD_GROUP,
    payload: { group }
});

/**
 * @function ActionCreators.setUser
 * @description Ustawia aktualnego użytkownika czatu
 * @param {object} user - obiekt z danymi użytkownika
 * @returns {ReduxAction}
 */
export const setChatUser = (user) => ({
    type: actionTypes.SET_CHAT_USER,
    payload: { user }
});

/**
 * @function ActionCreators.setUsers
 * @description Ustawia dostępnych dla aktualnego użytkownika, pozostałych użytkowników czatu.
 * @param {object[]} users - tablica użytkowników
 * @returns {ReduxAction}
 */
export const setUsers = (users) => ({
    type: actionTypes.SET_USERS,
    payload: { users }
});

/**
 * @function ActionCreators.setGroups
 * @description Ustawia dostępne dla aktualnego użytkownika grupy.
 * @param {object[]} groups - grupy
 * @returns {ReduxAction}
 */
export const setGroups = (groups) => ({
    type: actionTypes.SET_GROUPS,
    payload: { groups }
});

/**
 * @function ActionCreators.setUserActiveStatus
 * @description Ustawia aktywny status dla konkretnego użytkownika.
 * @param {string} id - id użytkownika
 * @param {boolean} boolean - flaga aktywnego statusu
 * @returns {ReduxAction}
 */
export const setUserActiveStatus = (id, boolean) => ({
    type: actionTypes.SET_USER_ACTIVE_STATUS,
    payload: { id, boolean }
});

/**
 * @function ActionCreators.setGroupLastMessage
 * @description Ustawia datę ostatniej wiadomości w danej grupie.
 * @param {any} room - grupa lub użytkownik
 * @param {number} timestamp - data ostatniej wiadomości
 * @returns {ReduxAction}
 */
export const updateLastMessage = (room, timestamp) => {
    return { type: actionTypes.UPDATE_LAST_MESSAGE, payload: { room, timestamp } };
};
