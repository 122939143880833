import React, { FC } from 'react';

import { OpenButtonProps } from './types';

import Badge from '../Badge';
import GeneralOpenButton from 'src/widgets/shared/components/openButton/OpenButton';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import ChatIcon from 'src/assets/ikona_chat.svg';
import OffIcon from '@mui/icons-material/SignalWifiOff';
import { topMenu } from 'src/constants/subtitles';

/**
 * Bezstanowy komponent funkcyjny. Wyswietla przycisk odpowiadający za otwieranie i zamykanie czatu.
 * Wyświetla komponent  [Components/Chat/Badge]{@link Badge}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {boolean} props.chatOpen - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.handleChatOpen - otwiera i zamyka czat.
 * @param {number} props.unread - liczba czatów z nieodczytanymi wiadomościami.
 * @param {boolean} props.disconnected - informacja czy czat jest połączony z serwerem
 * @returns {ReactComponent}
 * @see [Components/Chat/Badge]{@link Badge}
 */
const OpenButton: FC<OpenButtonProps> = ({ handleOpen, unread, disconnected }) => {
    return (
        <GeneralOpenButton
            handleOpen={handleOpen}
            tooltipText={topMenu.tooltipOnHover.chat}
            iconComponent={
                disconnected ? (
                    <OffIcon
                        fontSize="small"
                        sx={{
                            position: 'absolute',
                            fontSize: '1rem',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)'
                        }}
                    />
                ) : (
                    <ThemeCompatibleIcon
                        SvgComponent={ChatIcon}
                        fontSize={'small'}
                        sx={{
                            position: 'absolute',
                            fontSize: '1rem',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)'
                        }}
                    />
                )
            }>
            <Badge count={disconnected ? 0 : unread} size="large" />
        </GeneralOpenButton>
    );
};

export default OpenButton;
