import { createUpdatingRelationEventId, handleDetectUpdateRelation } from 'src/utils';
import { DynamicFormObjectFieldsSetter } from './DynamicFormObjectFieldsSetter';
import { DynamicFormObjectFieldsSetterProps } from './types';

export function doDependentCalculationsAfterFieldChanged({
    keyOfFieldInState,
    popupFormExtendedData,
    dynamicFieldT,
    fieldState,
    prefix,
    relationId
}: Omit<DynamicFormObjectFieldsSetterProps, 'formState'> & {
    keyOfFieldInState: string;
}) {
    return async (dispatch: any, getState: any) => {
        handleDetectUpdateRelation(true, createUpdatingRelationEventId(relationId));
        const fieldSetterResult = await new DynamicFormObjectFieldsSetter({
            formState: getState(),
            fieldState: fieldState,
            dynamicFieldT: dynamicFieldT,
            popupFormExtendedData: popupFormExtendedData,
            prefix: prefix,
            relationId: relationId
        }).executeOne(keyOfFieldInState);
        handleDetectUpdateRelation(false, createUpdatingRelationEventId(relationId));
        if (fieldSetterResult.changed) {
            dispatch({
                type: 'set_state',
                payload: { ...fieldSetterResult.newState }
            });
        }
    };
}
