import { MessageFields } from 'src/api/src/messageScreen/types';
import { DynamicFormObjectDataConverter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectDataConverter';
import { MessageRow, UpdateMessagePayload } from 'src/store/src/messageScreen/message/types';

export class MessageStateManager {
    messageRow: MessageRow[];
    messageRowPrepared: MessageRow[];
    messageFields: MessageFields;
    constructor({
        messageRow,
        messageFields
    }: {
        messageRow?: MessageRow[];
        messageFields?: MessageFields;
    }) {
        this.messageFields = messageFields ?? {};
        this.messageRow = messageRow ?? [];
        this.messageRowPrepared = [];
    }

    setDataFromApi() {
        this.setValues();
        return this.messageRowPrepared;
    }

    private setValues() {
        this.messageRow.forEach((row) => {
            this.setValue({ row });
        });
    }

    private setValue({ row }: { row: MessageRow }) {
        let value = this.messageFields[row.code];
        if (typeof value === 'number') {
            value = String(value);
        }

        switch (row.type) {
            case 'hidden':
                if (typeof value === 'string') {
                    this.messageRowPrepared.push({
                        ...row,
                        value: value
                    });
                } else {
                    this.messageRowPrepared.push({
                        ...row
                    });
                }
                break;
            case 'email':
            case 'text_not_edit':
            case 'text_without_restrict':
            case 'text': {
                if (typeof value === 'string') {
                    this.messageRowPrepared.push({
                        ...row,
                        value: value
                    });
                } else {
                    this.messageRowPrepared.push({
                        ...row
                    });
                }
                break;
            }
            case 'checkbox':
                if (value === '1') {
                    this.messageRowPrepared.push({
                        ...row,
                        value: true
                    });
                } else if (typeof value === 'boolean') {
                    this.messageRowPrepared.push({
                        ...row,
                        value
                    });
                } else {
                    this.messageRowPrepared.push({
                        ...row
                    });
                }
                break;
            case 'template':
            case 'list':
                if (typeof value === 'object' && !Array.isArray(value)) {
                    let newValue = value.value ?? '';
                    // because in types value can also be object
                    if (typeof newValue === 'object') {
                        newValue = newValue.id;
                    }
                    const ops = Object.keys(value.opcje);
                    if (!newValue && ops.length === 1) {
                        newValue = ops[0];
                    }
                    this.messageRowPrepared.push({
                        ...row,
                        value: newValue,
                        opcje: value.opcje ?? {}
                    });
                } else {
                    this.messageRowPrepared.push({
                        ...row
                    });
                }
                break;
            case 'attachment':
                if (Array.isArray(value)) {
                    this.messageRowPrepared.push({
                        ...row,
                        value: value.map((item) => {
                            return {
                                ...item,
                                checked: item.checked === '1'
                            };
                        })
                    });
                } else {
                    this.messageRowPrepared.push({
                        ...row
                    });
                }
                break;
            case 'list_wielokrotna':
                if (typeof value === 'object' && !Array.isArray(value)) {
                    let valueInput: {
                        id: string;
                        text: string;
                    }[];
                    if (typeof value.value === 'object') {
                        valueInput = DynamicFormObjectDataConverter.getArrayIdAndText(value.value);
                    } else {
                        valueInput = [{ id: value.value, text: value.value }];
                    }
                    const opcje = DynamicFormObjectDataConverter.getArrayIdAndText(value.opcje);
                    this.messageRowPrepared.push({
                        ...row,
                        value: valueInput,
                        opcje: opcje
                    });
                } else {
                    this.messageRowPrepared.push({
                        ...row
                    });
                }
                break;
            default:
                this.messageRowPrepared.push({
                    ...row
                });
                break;
        }
    }
    updateData(updateMessagePayload: UpdateMessagePayload) {
        this.updateRows(updateMessagePayload);
        return this.messageRowPrepared;
    }

    private updateRows(updateMessagePayload: UpdateMessagePayload) {
        this.messageRowPrepared = this.messageRow.map((row) => {
            return this.updateRow({ row, updateMessagePayload });
        });
    }

    private updateRow({
        row,
        updateMessagePayload
    }: {
        row: MessageRow;
        updateMessagePayload: UpdateMessagePayload;
    }): MessageRow {
        if (updateMessagePayload.code === row.code) {
            switch (updateMessagePayload.type) {
                case 'string':
                    switch (row.type) {
                        case 'text_not_edit':
                        case 'text_without_restrict':
                        case 'template':
                        case 'list':
                        case 'email':
                        case 'text': {
                            return {
                                ...row,
                                value: updateMessagePayload.value
                            };
                        }
                        default:
                            return row;
                    }
                case 'boolean':
                    switch (row.type) {
                        case 'checkbox': {
                            return {
                                ...row,
                                value: updateMessagePayload.value
                            };
                        }
                        default:
                            return row;
                    }

                case 'files':
                    switch (row.type) {
                        case 'file': {
                            return {
                                ...row,
                                value: updateMessagePayload.value
                            };
                        }
                        default:
                            return row;
                    }
                case 'listObjIdText':
                    switch (row.type) {
                        case 'list_wielokrotna': {
                            return {
                                ...row,
                                value: updateMessagePayload.value
                            };
                        }
                        default:
                            return row;
                    }
                case 'attachment':
                    switch (row.type) {
                        case 'attachment': {
                            return {
                                ...row,
                                value: row.value.map((item) => {
                                    if (item.id === updateMessagePayload.value.id) {
                                        return updateMessagePayload.value;
                                    }
                                    return item;
                                })
                            };
                        }
                        default:
                            return row;
                    }
                default:
                    return row;
            }
        }
        return row;
    }
}
