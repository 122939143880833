import { getFullUrlWithIdOkna } from '../navigationSystem/getFullUrlWithIdOkna';
import { combineFullUrl } from './combineFullUrl';
import { getLegacyCompatibleUrlData } from './getLegacyCompatibleUrlData';

export const getCompatibleUrl = (
    url: string,
    queryParams: URLSearchParams | undefined = undefined,
    addIdOkna: boolean = false
) => {
    const cleanUrlData = getLegacyCompatibleUrlData(url, queryParams);
    if (addIdOkna) {
        return getFullUrlWithIdOkna(cleanUrlData.url, cleanUrlData.queryParams);
    }
    return combineFullUrl(cleanUrlData.url, cleanUrlData.queryParams);
};
