import React, { FC } from 'react';

import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { ActionObjectProps } from 'src/components/object/atoms/actionObject/types';
import { ActionType } from 'src/components/object/atoms/actionType/ActionType';
import styles from './ActionObject.module.css';

export const ActionObject: FC<ActionObjectProps> = ({ action, id, additional, rawColor }) => {
    return (
        <div className={styles.wrapper}>
            <ActionType action={action} id={id} additional={additional}>
                <ThemeCompatibleIcon
                    rawColor={rawColor}
                    iconData={action.icon}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                />
            </ActionType>
        </div>
    );
};
