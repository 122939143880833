import React, { FC } from 'react';
import { ThemeCompatibleIconProps } from './types';
import ThemeCompatibleSvgIcon from 'src/components/shared/atoms/themeCompatibleSvgIcon/ThemeCompatibleSvgIcon';
import ThemeCompatibleFaIcon from 'src/components/shared/atoms/themeCompatibleFaIcon/ThemeCompatibleFaIcon';

/**
 * Provide easy way to act with icons, both fa, and svg based
 */
const ThemeCompatibleIcon: FC<ThemeCompatibleIconProps> = ({
    iconData,
    SvgComponent,
    color = 'primary',
    forceFa = false,
    fontSize = 'medium',
    ...props
}) => {
    return iconData?.faCode && forceFa ? (
        <ThemeCompatibleFaIcon
            iconData={iconData}
            forceFa={forceFa}
            color={color}
            fontSize={fontSize}
            {...props}
        />
    ) : SvgComponent ? (
        <ThemeCompatibleSvgIcon
            SvgComponent={SvgComponent}
            color={color}
            fontSize={fontSize}
            {...props}
        />
    ) : iconData ? (
        <ThemeCompatibleFaIcon
            iconData={iconData}
            forceFa={forceFa}
            color={color}
            fontSize={fontSize}
            {...props}
        />
    ) : null;
};

export default ThemeCompatibleIcon;
