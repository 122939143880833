import React, { FC } from 'react';
import { NavMenuUnfoldProps } from './types';
import { useTheme } from '@mui/material/styles';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { toggleUnfolded } from 'src/store/src/menu';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { UiNavMenuListWrapper } from 'src/components/menu/atoms/uiNavMenuListWrapper/UiNavMenuListWrapper';
import { iconStyleCommon } from '../../atoms/icon/Icon';
import styles from 'src/styles/menu.module.css';
export const NavMenuUnfold: FC<NavMenuUnfoldProps> = () => {
    const theme = useTheme();
    const dispatchRedux = useAppDispatch();
    const menuIsUnfolded = useTypedSelector((state) => state.menu.isUnfolded);

    const toggleUnhold = () => {
        dispatchRedux(toggleUnfolded());
    };
    return (
        <UiNavMenuListWrapper>
            <div
                className={`${styles.paddingY8} ${styles.cursorPointer}`}
                style={{ color: theme.palette.primary.main }}
                onClick={() => {
                    toggleUnhold();
                }}>
                <div className={`${styles.parentLevelBox}`}>
                    <div className={styles.parentLevelIconBox}>
                        <ThemeCompatibleIcon
                            iconData={{
                                faCode: 'fa-arrow-left',
                                svgFileName: 'lewa_strzalka.svg'
                            }}
                            fontSize={'medium'}
                            sx={{
                                ...iconStyleCommon,
                                border: '1px solid transparent',
                                transform: `${!menuIsUnfolded && 'rotate(0.5turn)'}`,
                                paddingLeft: 0
                            }}
                        />
                    </div>
                    {menuIsUnfolded && <p className={styles.p14}>Zwiń</p>}
                </div>
            </div>
        </UiNavMenuListWrapper>
    );
};
