import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Box,
    Alert,
    FormControlLabel,
    Checkbox,
    FormHelperText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { get2faScreenData } from 'src/api/src/2faScreen/get2faScreenData';
import { do2faAuth } from 'src/api/src/2faScreen/do2faAuth';
import { resend2faCode } from 'src/api/src/2faScreen/resend2faCode';
import { cancel2fa } from 'src/api/src/2faScreen/cancel2fa';
import { SecondfaScreenFormConfig } from './types';
import { useOnUserSuccessullyLoggedIn } from 'src/hooks';
import { navigateWithTranslate } from 'src/utils';

export const SecondFAForm = () => {
    const navigate = useNavigate();
    const [formConfig, setFormConfig] = useState<SecondfaScreenFormConfig>();
    const [code, setCode] = useState('');
    const [authorizeDevice, setAuthorizeDevice] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorText, setErrorText] = useState<string | null>(null);

    const onUserSuccessullyLoggedIn = useOnUserSuccessullyLoggedIn();

    // load configuration of a form
    useEffect(() => {
        const fetchData = async () => {
            const response = await get2faScreenData();
            if (!response.success) {
                setErrorText(response.message);
            } else {
                if (response.data.navigateToLogn === true) {
                    navigateWithTranslate(navigate, `/login?message=${response.message}`);
                    return;
                }
                setFormConfig(response.data);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        e.preventDefault();

        const data = await do2faAuth(code, authorizeDevice);

        if (!data.success) {
            setErrorText(data.message);
            setCode('');
            setLoading(false);
            return;
        }

        // if data.success
        onUserSuccessullyLoggedIn();
    };

    const handleResendCode = async () => {
        setLoading(true);
        const data = await resend2faCode();

        if (!data.success) {
            setErrorText(data.message);
            setLoading(false);
            return;
        }

        setLoading(false);
    };

    const handleCancelAuthorization = async () => {
        setLoading(true);
        const data = await cancel2fa();

        if (!data.success) {
            setErrorText(data.message);
            setLoading(false);
            return;
        }

        navigate('/login');
    };

    const commonTextFieldProps = { style: { fontSize: 14 } };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {errorText && (
                <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                    {errorText}
                </Alert>
            )}

            <TextField
                margin="normal"
                required
                fullWidth
                autoFocus
                name="code"
                label="Kod autoryzacyjny"
                type="password"
                id="code"
                value={code}
                inputProps={commonTextFieldProps}
                InputLabelProps={commonTextFieldProps}
                onChange={(e) => setCode(e.target.value)}
            />

            {formConfig?.canAuthorizeDevice && (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={authorizeDevice}
                                onChange={(e) => setAuthorizeDevice(e.target.checked)}
                                inputProps={commonTextFieldProps}
                            />
                        }
                        label="Autoryzuj to urządzenie"
                    />
                    <FormHelperText>
                        Spowoduje to brak wymuszania drugiego etapu autoryzacji na tym urządzeniu
                        przez najbliższe {formConfig.deviceAuthorizationDurationDays} dni.
                    </FormHelperText>
                </>
            )}

            <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}>
                Zaloguj się
            </Button>

            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                {formConfig?.canResendCode && (
                    <Button
                        variant="text"
                        style={{ textWrap: 'nowrap' }}
                        disabled={loading}
                        onClick={handleResendCode}>
                        <u>Wyślij kod ponownie</u>
                    </Button>
                )}
                <Button
                    variant="text"
                    sx={{
                        textWrap: 'nowrap',
                        justifySelf: 'center',
                        ':only-child': {
                            margin: '0 auto'
                        }
                    }}
                    disabled={loading}
                    color="secondary"
                    onClick={handleCancelAuthorization}>
                    <u>Anuluj autoryzację</u>
                </Button>
            </Box>
        </Box>
    );
};
