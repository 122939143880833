import { adjustTimestamp } from 'src/utils';
import { EventsResp } from '../../types';
import { queryServerData } from './queryServerData';
import { CalendarType } from '../../../store/src/calendar/calendar/types';

/**
 * Function fetches calendar events
 */
export async function fetchCalendarData(
    view: CalendarType,
    from: number,
    to: number,
    selectedCategories: number[],
    selectedIDs: number[],
    entityID?: number
): Promise<EventsResp> {
    from = adjustTimestamp(from, 'php');
    to = adjustTimestamp(to, 'php');

    return queryServerData<EventsResp>('api_react/calendar.php', {
        type: 'data',
        from,
        to,
        view,
        selected_categories: selectedCategories,
        selected_ids: selectedIDs,
        entity_id: entityID
    });
}
