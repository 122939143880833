import React, { FC } from 'react';
import { InputBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { UiAutocompleteInputTwoProps } from './types';

export const UiAutocompleteInputTwo: FC<UiAutocompleteInputTwoProps> = (props) => {
    const { params, style } = props;
    const theme = useTheme();

    return (
        <InputBase
            {...params.InputProps}
            {...params}
            sx={{
                border: `solid 1px ${theme.palette.primary.main}`,
                color: theme.palette.text.input,
                borderRadius: '4px',
                paddingLeft: '10px',
                ...style
            }}
        />
    );
};
