import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { HeadRelationProps } from 'src/components/popupFormExtended/headRelation/types';
import styles from './HeadRelation.module.css';
import { UiLabel } from 'src/components/shared/atoms/uiInputs/uiLabel/UiLabel';

export const HeadRelation: FC<HeadRelationProps> = (props) => {
    const { relation } = props;
    const theme = useTheme();
    return (
        <tr>
            {relation?.fields?.map((field, i) => {
                if (field.ukryte === 1) return null;
                return (
                    <td key={i}>
                        <div className={styles.div}>
                            <UiLabel label={field.nazwa} />
                            <p
                                className={styles.arrow}
                                style={{
                                    color: `${theme.palette.text.label}`
                                }}>
                                {' '}
                                {`${field.obowiazkowe === 't' ? '*' : ''}`}
                            </p>
                        </div>
                    </td>
                );
            })}
            <td></td>
        </tr>
    );
};
