import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const TooltipOnHover = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.primary.main}`
    }
}));

export default TooltipOnHover;
