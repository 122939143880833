import { FieldCheckboxStateRHF, FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';

export function getValueBooleanRHF(fieldState: FieldsStateUnionRHF) {
    let value: boolean = false;
    if (!fieldState) return value;
    switch (true) {
        case 'checkbox' in fieldState: {
            const oneObj: Required<FieldCheckboxStateRHF> =
                fieldState as Required<FieldCheckboxStateRHF>;
            return oneObj?.checkbox;
        }
    }
    return value;
}
