import { DynamicFormObjectGlobalValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/DynamicFormObjectGlobalValidator';
import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';

type DynamicFormObjectGlobalMiddlewareHandlerProps = {
    formState: FormState;
};
export class DynamicFormObjectGlobalMiddlewareHandler {
    formState: FormState;
    constructor({ formState }: DynamicFormObjectGlobalMiddlewareHandlerProps) {
        this.formState = formState;
    }
    execute(formActions: FormActions) {
        const data = DynamicFormObjectGlobalValidator.validateOneField(formActions);
        return data;
    }
}
