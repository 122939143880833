export const topMenu = {
    tooltipOnHover: {
        pin: 'Dodaj do Menu',
        plus: 'Dodaj nowy obiekt',
        chat: 'Chat',
        todo: 'Lista To-Do',
        notes: 'Notes',
        timer: 'Licznik czasu'
    },
    button: {
        pinSection: 'Zapisz',
        myReport: {
            run: 'Generuj',
            cancel: 'Anuluj'
        }
    },
    input: {
        myReport: {
            first: 'Od',
            second: 'Do'
        },
        searchBar: 'Szukaj globalnie'
    },
    options: {
        userSection: {
            one: 'Moje Dane',
            two: 'Mój Raport',
            last: 'Wyloguj'
        }
    }
};

export const listScreen = {
    objectParamsError: 'Nieprawdiłowy adres, brak parametru "obiekt"',
    paginationBar: {
        left: {
            textOne: 'Ilość elementów na stronie:',
            textTwo: ' elementów'
        },
        right: {
            textOne: 'pierwsza',
            textTwo: 'ostatnia  ',
            textThree: ' Wybierz'
        }
    },
    getSearchFilterName: 'Nadaj nazwę wyszukania'
};

export const general = {
    defaultFileName: 'Plik',
    getLinkPrompt: 'Skopiuj do schowka: Ctrl+C, Enter',
    redirecting: 'Przekierowywanie...',
    missingCodeParameter: 'Brak parametru "code" '
};

export const globalPopup = {
    contentDelete: {
        one: {
            title: 'Usuwanie obiektu',
            content: 'Czy usunąć obiekt?'
        },
        many: {
            title: 'Usuwanie obiektów',
            content: 'Czy usunąć obiekty?'
        }
    },
    contentDeleteConfirm: {
        one: {
            title: 'Potwierdzenie usunięcia',
            content: 'Operacja jest nieodwracalna. Na pewno?'
        },
        many: {
            title: 'Potwierdzenie usunięcia',
            content: 'Operacja jest nieodwracalna. Na pewno?'
        }
    },
    contentSentInvoiceMail: {
        one: {
            title: 'Wyślij',
            content: 'Wybierz załączniki',
            input: {
                invoice: 'Faktura',
                invoiceAndReport: 'Faktura i raport'
            },
            button: {
                yes: 'Wyślij'
            }
        }
    },
    contentConfirmation: {
        title: 'Potwierdzenie',
        button: {
            yes: 'Tak',
            no: 'Nie'
        }
    },
    contentConfirmationClose: {
        title: 'Potwierdzenie',
        description: 'Czy zapisać dotychczasowe zmiany?',
        button: {
            left: 'Tak',
            center: 'Nie',
            right: 'Powróć do edycji'
        }
    },
    contentInfo: {
        title: 'Informacja',
        button: {
            yes: 'Ok'
        }
    },
    contentWarning: {
        title: 'Informacja',
        button: {
            yes: 'Tak',
            no: 'Nie'
        }
    },
    contentPrompt: {
        title: 'Komunikat systemu',
        button: {
            ok: 'Ok',
            cancel: 'Anuluj'
        }
    },
    contentDynamic: {
        button: {
            yes: 'Zatwierdź'
        }
    },
    contentCompleteTask: {
        one: {
            title: 'Zrealizuj zadanie',
            content: 'Nazwa zadania:',
            input: {
                date: 'Data',
                timeAbsolute: 'Godzina',
                timeRelativeDecimal: 'Poświęcony czas (0,000 h)',
                timeRelativeNormal: 'Poświęcony czas (min)',
                realised: 'Zrealizowano'
            },
            button: {
                yes: 'Zrealizuj zadanie',
                no: 'Anuluj'
            }
        }
    },
    contentArchive: {
        one: {
            title: 'Archiwizowanie obiektu',
            content: 'Czy zaarchiwizować obiekt?'
        },
        many: {
            title: 'Archiwizowanie obiektów',
            content: 'Czy zaarchiwizować obiekty?'
        }
    },
    contentAlert: {
        title: 'Komunikat',
        button: {
            yes: 'Ok'
        }
    },
    contentDefault: {
        title: 'Przepraszamy zawartość chwilowo niedostępna'
    },
    checkTime: {
        content: 'Odliczanie do wylogowania z systemu:',
        content2: 'Jeżeli chcesz kontynuować pracę w systemie kliknij poniżej:',
        button: ' Odśwież sesje'
    },
    contentFormExtended: {
        relationCreator: {
            add: 'Dodaj'
        },
        dynamicFormObject: {
            submitButtonAdd: 'Dodaj',
            submitButtonEdit: 'Zapisz zmiany',
            submitButtonCyclic: 'Zapisz zmiany w serii',
            submitButtonCyclicOnlyNewer: 'Zapisz zmiany w serii(tylko przyszłe)'
        }
    },
    contentInfoChangePassword: {
        title: 'Zmień swoje hasło!',
        button: 'Ok',
        info: 'Dla bezpieczeństwa po pierwszym logowaniu, lub po zmianie hasła przez innego użytkownika,',
        infoBold: 'musisz zmienić swoje hasło!',
        chapterOne: '1. Twoje hasło musi posiadać minimum:',
        chapterTwo: '2. Ponadto sugerujemy aby Twoje hasło:',
        chapterTwoOne: 'a) różniło sie od haseł wykorzystywanych',
        chapterTwoTwo: 'b) nie było używane w innych serwisach internetowych'
    }
};

export const dataPicker = {
    error: 'Nieprawidłowe dane',
    checkbox: {
        label: 'Dziś'
    }
};

export const fields = {
    fieldPriceNumber: {
        one: 'X',
        two: 'netto',
        three: '+ VAT',
        four: 'brutto'
    },
    fieldPrice: {
        one: 'netto',
        two: '+ VAT',
        three: '=',
        four: 'brutto'
    },
    fieldMany: {
        firstCheckbox: 'Korespondencja',
        secondCheckbox: 'Wysyłka reklamowa',
        third: 'Wysyłka raportów',
        addNew: 'Dodaj kolejny adres e-mail'
    },
    fieldFileMany: {
        field: 'Przeciagnij tutaj',
        button: 'Przegladaj'
    },
    fieldFile: {
        field: 'Przeciagnij tutaj',
        button: 'Przegladaj',
        fileNameInput: 'Nazwa pliku wynikowego (bez końcówki)',
        previousVersions: 'Poprzednie wersje :',
        preview: 'Podgląd',
        download: 'Pobierz',
        downloadForWord: 'Pobierz do edycji w MsWord'
    },
    fieldPassword: {
        changePassword: 'Zmień hasło'
    },
    fieldAdress: {
        oneLabel: 'Miasto',
        twoLabel: 'Kod',
        threeLabel: 'Poczta',
        fourLabel: 'Gmina',
        fiveLabel: 'Powiat',
        sixLevel: 'Województwo',
        sevenLabel: 'Państwo'
    },
    fieldDateHour: {
        oneLabel: 'Godzina'
    },
    fieldDateHours: {
        oneLabel: 'Godz. Rozp'
    },
    fieldNumber: {
        oneLabel: 'w min.'
    },
    fieldNip: {
        button: 'Pobierz z GUS'
    },
    fieldNumberSettledToZero: {
        oneLabel: 'z tego rozliczono: ',
        twoLabel: 'pozostało: '
    }
};
