import React, { FC } from 'react';

import { UIFieldOpenPasswordProps } from './types';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { PasswordInput } from '../shared/passwordInput/PasswordInput';

export const UIFieldOpenPassword: FC<UIFieldOpenPasswordProps> = (props) => {
    return (
        <UiWrapper>
            <PasswordInput {...props} />
        </UiWrapper>
    );
};
