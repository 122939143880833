import React from 'react';
import { QueryLinkProps } from './types';
import { NavigateFunction } from 'react-router-dom';
import { getCompatibleUrl } from 'src/utils';

type Props = QueryLinkProps & {
    navigate: NavigateFunction;
};

export class QueryLinkFnManager {
    url: string;
    navigate: NavigateFunction;
    queryParams?: URLSearchParams | undefined;
    confirmPropmpt?: string | null | undefined;
    rightClickBlank?: boolean | undefined;
    newWindow?: boolean | undefined;
    closePopup?: (() => void) | undefined;
    stopPropagation?: boolean | undefined;
    locationState?: any;
    onClick: any;
    onContextMenu: any;
    constructor({
        url,
        navigate,
        queryParams,
        rightClickBlank = true,
        confirmPropmpt,
        newWindow,
        closePopup,
        stopPropagation = false,
        locationState
    }: Props) {
        this.url = url;
        this.navigate = navigate;
        this.queryParams = new URLSearchParams(queryParams);
        this.confirmPropmpt = confirmPropmpt;
        this.rightClickBlank = rightClickBlank;
        this.newWindow = newWindow;
        this.stopPropagation = stopPropagation;
        this.closePopup = closePopup;
        this.locationState = locationState;
        this.onClick = () => {};
        this.onContextMenu = () => {};
    }

    runOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.setOnClickFn(e);
        this.onClick();
    }
    runOnContextMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.setOnContextMenu(e);
        this.onContextMenu();
    }

    setOnContextMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.onContextMenu = () => this.handleRightClick(e);
    }
    setOnClickFn(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (this.confirmPropmpt) {
            this.onClick = this.handleNavigateWithConfirmation;
        } else if (this.newWindow) {
            this.onClick = this.handleNavigateNewWindow;
        } else {
            this.onClick = () => this.handleNavigate(e);
        }
    }

    handleNavigateWithConfirmation = () => {
        this.closePopup && this.closePopup();
        if (window.confirm(this.confirmPropmpt ? this.confirmPropmpt : undefined)) {
            this.navigate(getCompatibleUrl(this.url, this.queryParams, true), {
                state: this.locationState
            });
        }
    };

    handleNavigateNewWindow = () => {
        this.closePopup && this.closePopup();
        window.open(window.basename + getCompatibleUrl(this.url, this.queryParams), '_blank');
    };
    handleNavigate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.closePopup && this.closePopup();
        this.navigate(getCompatibleUrl(this.url, this.queryParams, true), {
            state: this.locationState
        });

        if (this.stopPropagation === true) {
            e.stopPropagation();
        }
    };
    handleRightClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.closePopup && this.closePopup();
        if (this.rightClickBlank) {
            window.open(window.basename + getCompatibleUrl(this.url, this.queryParams), '_blank');
            e.preventDefault();
        }
    };
}
