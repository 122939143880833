import { MessageRow } from 'src/store/src/messageScreen/message/types';
import { consoleConditionally, getAllParamsWindowLocationSearchToObject } from 'src/utils';
import { MessageDataValidator } from './MessageDataValidator';

export class MessageDataApiCreator extends MessageDataValidator {
    messageRow: MessageRow[];
    messageRowHidden: MessageRow[];
    data: {
        validationResult: {
            message: string;
            isValid: boolean;
        };
        messageDataRequest: {
            params: string[][];
            body: { [key: string]: any };
        };
    };
    constructor({
        messageRow,
        messageRowHidden
    }: {
        messageRow?: MessageRow[];
        messageRowHidden?: MessageRow[];
    }) {
        super({
            messageRow,
            messageRowHidden
        });
        this.messageRow = messageRow ?? [];
        this.messageRowHidden = messageRowHidden ?? [];
        this.data = {
            validationResult: {
                message: '',
                isValid: true
            },
            messageDataRequest: {
                params: [],
                body: {}
            }
        };
    }

    createDataForTemplateRequest() {
        this.data.validationResult = this.validate('template');
        this.messageRowHidden.forEach((row) => {
            this.createDataForTemplateRequestParamHidden(row);
        });

        return this.data;
    }

    createDataForMainRequest() {
        this.data.validationResult = this.validate('main');
        this.data.messageDataRequest.body = getAllParamsWindowLocationSearchToObject();
        this.messageRowHidden.forEach((row) => {
            this.createDataForMainOnBody(row);
        });

        this.messageRow.forEach((row) => {
            this.createDataForMainOnBody(row);
        });
        consoleConditionally(this.data, 'data');

        return this.data;
    }

    private createDataForMainOnBody(messageRow: MessageRow) {
        if (messageRow.apiType.includes('main')) {
            switch (messageRow?.type) {
                case 'hidden':
                case 'template':
                case 'list':
                case 'email':
                case 'text':
                case 'text_not_edit':
                case 'text_without_restrict':
                case 'checkbox':
                    this.addBodyItem(messageRow.code, messageRow.value);
                    break;
                case 'list_wielokrotna':
                    this.data.messageDataRequest.body[messageRow.code] = messageRow.value.map(
                        (value) => value.id
                    );
                    break;
                case 'file':
                    this.data.messageDataRequest.body[messageRow.code] = messageRow.value;
                    break;
                case 'attachment':
                    this.data.messageDataRequest.body[messageRow.code] = messageRow.value
                        .filter((item) => item.checked)
                        .map((f) => f.id);
                    break;
                default:
                    break;
            }
        }
    }
    private createDataForTemplateRequestParamHidden(messageRow: MessageRow) {
        if (messageRow?.type === 'hidden' && messageRow.apiType.includes('template')) {
            if (messageRow.dynamicSet) {
                const row = this.messageRow.find(
                    (message) => message.code === messageRow.dynamicSet?.key
                );
                switch (row?.type) {
                    case 'template':
                        this.addParamItem(messageRow.code, String(row.value));
                        break;
                    case 'list_wielokrotna':
                        this.addParamItem(messageRow.code, row.value[0]?.id);
                        break;
                    default:
                        break;
                }
            } else if (messageRow?.value) {
                this.addParamItem(messageRow.code, String(messageRow.value));
            }
        }
    }

    private addParamItem(code: string, value: string) {
        this.data.messageDataRequest.params.push([code, value]);
    }
    private addBodyItem(code: string, value: string | null | number | boolean) {
        if (value === null || value === undefined || value === '') {
            return;
        }
        if (typeof value === 'boolean') {
            this.data.messageDataRequest.body[code] = value ? '1' : '0';
        } else {
            this.data.messageDataRequest.body[code] = String(value);
        }
    }
}
