import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { UtilitiesBarPages } from './types';
import { SystemAction } from 'src/data/types';

/**
 * Fetches information about specific object data
 */
export async function fetchUtilitiesBarOptions(
    screen: string,
    pageType: UtilitiesBarPages,
    objectId?: string
): Promise<BaseApiResponse<SystemAction[]>> {
    const params = new URLSearchParams([
        ['screen', screen],
        ['pageType', pageType]
    ]);
    if (objectId) {
        params.append('objectId', objectId);
    }

    return simpleQueryAPIData(
        `api_react/src/shared/getUtilitiesBarOptions.php?${params.toString()}`,
        'GET'
    );

    // return {
    //     message: 'Zdobyto dane',
    //     success: true,
    //     errorCode: null,
    //     data: [
    //         {
    //             name: 'Exportuj',
    //             code: 'exportuj',
    //             icon: {
    //                 faCode: '',
    //                 svgFileName: 'eksportuj.svg'
    //             },
    //             colorConditions: null,
    //             displayConditions: null,
    //             defaultColor: null,
    //             behaviour: {
    //                 type: 'fileDownload',
    //                 data: {
    //                     url: 'generateScreenDataExcel.php',
    //                     params: '{@listScreenExcelParams}',
    //                     payload: null
    //                 }
    //             }
    //         },
    //         {
    //             name: 'Test',
    //             code: 'filter',
    //             icon: {
    //                 faCode: 'fa-search-plus',
    //                 svgFileName: null
    //             },
    //             colorConditions: null,
    //             displayConditions: null,
    //             defaultColor: null,
    //             behaviour: {
    //                 type: 'filter',
    //                 data: {
    //                     nazwa: {
    //                         orMode: false,
    //                         value: 'test nr 1'
    //                     }
    //                 }
    //             }
    //         },
    //         {
    //             name: 'Edytuj',
    //             code: 'edit',
    //             icon: {
    //                 faCode: 'fa-search-plus',
    //                 svgFileName: null
    //             },
    //             colorConditions: null,
    //             displayConditions: null,
    //             defaultColor: null,
    //             behaviour: {
    //                 type: 'link',
    //                 data: {
    //                     url: 'ekran_masowej_edycji_obiektow.php',
    //                     params: 'id_edycja_masowa={@objectId[all]}&obiekt=zadania',
    //                     confirmText: null,
    //                     newWindow: false
    //                 }
    //             }
    //         },
    //         {
    //             name: 'Filter',
    //             code: 'searchFilter',
    //             icon: {
    //                 faCode: 'fa-search-plus',
    //                 svgFileName: ''
    //             },
    //             colorConditions: null,
    //             displayConditions: null,
    //             defaultColor: null,
    //             behaviour: {
    //                 type: 'searchFilter',
    //                 data: {
    //                     id: 73,
    //                     linkHash: '42a588a3e2b87ee2a7227c0d1162b68a'
    //                 }
    //             }
    //         }
    //     ]
    // };
}
