import { useEffect, useRef, MutableRefObject } from 'react';

export function useLoadOnScroll(loadCb: () => void, done: boolean): MutableRefObject<HTMLElement> {
    const listRef = useRef<HTMLElement>(null!);

    useEffect(() => {
        const container = listRef.current;
        const handleScroll = () => {
            if (container.scrollTop + container.clientHeight > container.scrollHeight - 10) {
                /* stop listening to scroll, and conditionally restore it after tasks loaded */
                listRef.current.removeEventListener('scroll', handleScroll);
                loadCb();
                setTimeout(() => listRef.current.addEventListener('scroll', handleScroll), 100);
            }
        };

        if (container) {
            if (done) {
                /* double checking, theoretically scroll should be disabled via handleScroll */
                container.removeEventListener('scroll', handleScroll);
            } else {
                container.addEventListener('scroll', handleScroll);
            }
        }
        /* remove listener before useEffect fire next time */
        return () => container && container.removeEventListener('scroll', handleScroll);
    }, [listRef, loadCb, done]);
    return listRef;
}
