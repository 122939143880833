import React, { FC } from 'react';
import { FieldHistoryWrapperUnmodifiedProps } from './types';
import FieldHistoryBlock from 'src/components/shared/molecules/fieldHistoryBlock/FieldHistoryBlock';

const FieldHistoryWrapperUnmodified: FC<FieldHistoryWrapperUnmodifiedProps> = ({
    dynamicFieldT,
    field,
    children
}) => {
    return (
        <>
            {children}
            {dynamicFieldT.historia === 't' &&
                ((field.relationId !== '' &&
                    dynamicFieldT.historia_widoczna_w_powiazaniu === 't') ||
                    (field.relationId === '' &&
                        dynamicFieldT.historia_widoczna_na_ekranie === 't')) && (
                    <FieldHistoryBlock {...dynamicFieldT} fieldType={dynamicFieldT.typ} />
                )}
        </>
    );
};

export default FieldHistoryWrapperUnmodified;
