import { DynamicFieldT, PopupFormSection, PopupFormTab } from 'src/data/popupFormTypes';
import { PopupFormRelatedObject, RelatedObjectData } from 'src/data/popupFormTypes.d';
import {
    ColumnInSectionPopupFormExtended,
    DeleteRelationship,
    FormState,
    FormStateFields,
    SumsRelationship,
    TabsPopupFormExtended
} from 'src/components/popupFormExtended/dynamicFormObject/types';
import { Fields, FieldsPure } from 'src/components/popupFormExtended/dynamicField/types';
import { setInitValueField } from 'src/components/form/molecules/formContextProvider/setInitValueField';
import { consoleConditionally, createSharedPropertiesForFieldState } from 'src/utils';
import { SumsCalcManager } from 'src/components/popupFormExtended/fieldGlobalDependent/sumsCalcManager/SumsCalcManager';
import { cloneSafetyFieldState } from 'src/utils';

type DynamicFormObjectHandlerProps = {
    fields?: DynamicFieldT[];
    relations?: PopupFormRelatedObject[];
    relatedObjectData?: RelatedObjectData;
    tabs?: PopupFormTab[];
    sections?: PopupFormSection[];
    state?: FormState;
    prefixFieldKey?: string;
    kod?: string;
    targetScreenCode?: string;
    usedForAddingObject?: boolean;
};

type TypesOfCreateState =
    | 'init'
    | 'add_new_relation_item_with_values'
    | 'delete_relation_item'
    | 'add_new_relation_item_empty';

export class DynamicFormObjectStateHandler {
    prefixFieldKey: string;
    kod: string;
    targetScreenCode: string;
    fieldsFromApi: DynamicFieldT[];
    relationsFromApi: PopupFormRelatedObject[];
    tabsFromApi: PopupFormTab[];
    sectionsFromApi: PopupFormSection[];
    relatedObjectDataFromApi?: RelatedObjectData;
    state: FormState;
    newState: FormState;
    usedForAddingObject: boolean;
    constructor({
        state,
        fields,
        tabs,
        sections,
        relations,
        relatedObjectData,
        prefixFieldKey,
        usedForAddingObject,
        kod,
        targetScreenCode
    }: DynamicFormObjectHandlerProps) {
        this.fieldsFromApi = fields ? fields : [];
        this.relationsFromApi = relations ? relations : [];
        this.sectionsFromApi = sections ? sections : [];
        this.tabsFromApi = tabs ? tabs : [];
        this.kod = kod ? kod : '';
        this.targetScreenCode = targetScreenCode ? targetScreenCode : '';
        this.prefixFieldKey = prefixFieldKey ? prefixFieldKey : '!@#$%^&*';
        this.state = state
            ? state
            : {
                  fieldsForeign: {},
                  fields: {},
                  sumsRelationship: {},
                  deleteRelationship: {},
                  tabs: {},
                  onMountForm: false
              };
        this.newState = {
            fieldsForeign: {},
            fields: {},
            sumsRelationship: {},
            deleteRelationship: {},
            tabs: {},
            onMountForm: false
        };
        this.usedForAddingObject = usedForAddingObject ?? false;
        this.relatedObjectDataFromApi = relatedObjectData;
    }
    handleState(type: TypesOfCreateState) {
        switch (type) {
            // handle init Context Form State
            case 'init':
                this.newState = {
                    onMountForm: true,
                    deleteRelationship: {},
                    tabs: {},
                    ...this.createStateInit()
                };
                this.newState.sumsRelationship = this.updateSums({
                    formState: {
                        ...this.newState
                    }
                });
                this.newState.tabs = this.createTabsInit();
                break;
            // handle field dodanie_powiazania
            case 'add_new_relation_item_with_values':
                this.newState = {
                    ...this.state,
                    fields: this.addFieldsForNewObjectsDataWithData({
                        formFields: this.state.fields,
                        prefixFieldKey: this.prefixFieldKey,
                        kod: this.kod,
                        usedForAddingObject: this.usedForAddingObject
                    })
                };
                this.newState.tabs = this.replaceRelationInTab({
                    targetScreenCode: this.targetScreenCode,
                    relationToReplace: this.relationsFromApi[0],
                    tabsPopup: this.state.tabs
                });
                this.newState.sumsRelationship = this.updateSums({
                    formState: {
                        ...this.newState,
                        sumsRelationship: this.state.sumsRelationship
                    }
                });
                this.newState.onMountForm = true;
                break;
            // handle click trash on item that delete item
            case 'delete_relation_item':
                this.newState.fields = this.clearFields({
                    prefixFieldKey: this.prefixFieldKey,
                    formFields: this.state.fields
                });
                this.newState.tabs = this.clearNewObjectsDataItem({
                    prefixFieldKey: this.prefixFieldKey,
                    tabsPopup: this.state.tabs
                });
                this.newState.deleteRelationship = this.setObjectsDataItemToDelete({
                    prefixFieldKey: this.prefixFieldKey,
                    deleteRelationshipState: this.state.deleteRelationship
                });
                this.newState.sumsRelationship = this.updateSums({
                    formState: {
                        ...this.newState,
                        sumsRelationship: this.state.sumsRelationship
                    }
                });
                //
                this.newState.onMountForm = true;
                break;
            // handle click + on relation expand to add new item
            case 'add_new_relation_item_empty':
                this.newState.fields = this.addFieldsForNewObjectsData({
                    relation: this.relationsFromApi[0],
                    relatedObjectData: this.relatedObjectDataFromApi
                });
                this.newState.tabs = this.replaceRelationInTab({
                    targetScreenCode: this.targetScreenCode,
                    relationToReplace: this.relationsFromApi[0],
                    tabsPopup: this.state.tabs
                });
                this.newState.onMountForm = true;
                break;
            default:
        }
        consoleConditionally(this, 'DynamicFormObjectStateHandler');
        return this.newState;
    }

    // case init

    private createStateInit() {
        const fieldsForeign: FormStateFields = {};
        const fields: FormStateFields = {};
        let sums: SumsRelationship = {};
        this.fieldsFromApi.forEach((field: DynamicFieldT) => {
            const fieldKey = field.kod;
            const fieldState = this.setInitValue({
                field,
                fieldKey
            });
            if (fieldState?.isForeign) {
                fieldsForeign[fieldKey] = fieldState;
            } else {
                fields[fieldKey] = fieldState;
            }
        });
        this.relationsFromApi.forEach((relation) => {
            relation.objectsData.forEach((relatedObjectData) => {
                relation.fields.forEach((field: DynamicFieldT) => {
                    const startFieldKey = `edycja_rpo_${relation.id}_${relatedObjectData.id}`;
                    const fieldKey = `${startFieldKey}_${field.kod}`;
                    const fieldState = this.setInitValue({
                        field,
                        fieldKey,
                        relation,
                        relatedObjectData,
                        startFieldKey
                    });
                    fields[fieldKey] = fieldState;
                });
            });
            relation.newObjectsData.forEach((relatedObjectData, i) => {
                relation.fields.forEach((field: DynamicFieldT) => {
                    const startFieldKey = `nowy_rpo_${relation.id}_${i + 1}`;
                    const fieldKey = `${startFieldKey}_${field.kod}`;
                    const fieldState = this.setInitValue({
                        field,
                        fieldKey,
                        relation,
                        relatedObjectData,
                        startFieldKey
                    });
                    fields[fieldKey] = fieldState;
                });
            });
            Object.assign(sums, SumsCalcManager.createRelationSum(relation));
        });
        return {
            fields,
            fieldsForeign,
            sumsRelationship: sums
        };
    }

    private createTabsInit() {
        const tabs: TabsPopupFormExtended = {};
        this.tabsFromApi.forEach((item) => {
            tabs[item.id] = [];
            const sectionInActualTab = this.sectionsFromApi.filter(
                (section) => section.tabId === item.id
            );
            sectionInActualTab?.forEach((section) => {
                const fields = this.fieldsFromApi.filter((field) => field.id_sekcji === section.id);
                const fieldsWithBelow = this.setFieldsBelow(fields);
                const relations = this.relationsFromApi.filter(
                    (relation) => relation.sectionId === section.id
                );
                const columns = this.assignFieldToColumn(fields);
                const relationsWithPrefixKey = this.setPrefixAndOtherForRelations(relations);

                tabs[item.id].push({
                    fields: fieldsWithBelow,
                    relations: relationsWithPrefixKey,
                    columns
                });
            });
        });
        const filteredTabs: TabsPopupFormExtended = {};
        for (const key in tabs) {
            if (!Array.isArray(tabs[key])) continue;
            for (const item of tabs[key]) {
                if (item.fields.length || item.relations.length) {
                    if (filteredTabs[key]) {
                        filteredTabs[key].push(item);
                    } else {
                        filteredTabs[key] = [item];
                    }
                }
            }
        }
        return filteredTabs;
    }

    private setPrefixAndOtherForRelations(relations: PopupFormRelatedObject[]) {
        return relations?.map((relation) => {
            return {
                ...relation,
                objectsData: relation.objectsData?.map((item) => {
                    return {
                        ...item,
                        prefixKey: `edycja_rpo_${relation.id}_${item.id}`
                    };
                }),
                newObjectsData: relation.newObjectsData.map((item, i) => {
                    return {
                        ...item,
                        id: String(i + 1),
                        prefixKey: `nowy_rpo_${relation.id}_${i + 1}`
                    };
                })
            };
        });
    }

    private assignFieldToColumn(fields: DynamicFieldT[]) {
        const columns: ColumnInSectionPopupFormExtended = {
            left: {
                fields: []
            },
            right: {
                fields: []
            },
            down: { fields: [] }
        };

        fields.forEach((item) => {
            switch (item.kolumna_w_edycji) {
                case '0':
                    columns.down.fields.push(item);
                    break;
                case '1':
                    columns.left.fields.push(item);
                    break;
                case '2':
                    columns.right.fields.push(item);
                    break;
                default:
                    break;
            }
        });
        return columns;
    }

    private setFieldsBelow(fields: DynamicFieldT[]) {
        return fields
            ?.map((field) => {
                let fieldToUpdated = {
                    ...field
                };
                if (field.chowane_pola && Array.isArray(field.chowane_pola)) {
                    const fieldsBelow = fields.filter((itemField) =>
                        field.chowane_pola?.includes(itemField.id)
                    );
                    fieldToUpdated.fieldsBelow = fieldsBelow;
                }

                return fieldToUpdated;
            })
            ?.filter((item) => !item.chowane_pod);
    }

    // case add_rel_with_value

    private addFieldsForNewObjectsDataWithData({
        prefixFieldKey,
        formFields,
        kod,
        usedForAddingObject
    }: {
        prefixFieldKey: string;
        formFields: FormStateFields;
        kod: string;
        usedForAddingObject: boolean;
    }) {
        const fields: FormStateFields = {
            ...formFields
        };
        this.relationsFromApi.forEach((relation) => {
            relation.newObjectsData.forEach((relatedObjectData) => {
                if (relatedObjectData.prefixKey === prefixFieldKey) {
                    relation.fields.forEach((field: DynamicFieldT) => {
                        const fieldKey = `${prefixFieldKey}_${field.kod}`;
                        let fieldStateOfFieldAdd: Fields = null;
                        fieldStateOfFieldAdd = formFields[`${kod}-${field.kod}`];
                        if (fieldStateOfFieldAdd) {
                            const sharedProperties = createSharedPropertiesForFieldState({
                                field,
                                fieldKey,
                                relation,
                                relatedObjectData,
                                startFieldKey: prefixFieldKey,
                                usedForAddingObject
                            });
                            const fieldToAdd: FieldsPure = {
                                ...cloneSafetyFieldState(fieldStateOfFieldAdd),
                                ...sharedProperties
                            };
                            fields[fieldKey] = fieldToAdd;
                        }
                    });
                }
            });
        });
        return fields;
    }

    private replaceRelationInTab({
        targetScreenCode,
        relationToReplace,
        tabsPopup
    }: {
        targetScreenCode: string;
        relationToReplace: PopupFormRelatedObject;
        tabsPopup: TabsPopupFormExtended;
    }) {
        const tabs: TabsPopupFormExtended = {};
        for (const key in tabsPopup) {
            if (Array.isArray(tabsPopup[key])) {
                tabs[key] = tabsPopup[key].map((item) => {
                    return {
                        ...item,
                        relations: item.relations.map((relation) => {
                            if (
                                relationToReplace &&
                                relation.targetScreenCode === targetScreenCode
                            ) {
                                return relationToReplace;
                            }
                            return relation;
                        })
                    };
                });
            }
        }
        return tabs;
    }

    //  case 'delete_rel':

    private clearFields({
        prefixFieldKey,
        formFields
    }: {
        prefixFieldKey: string;
        formFields: FormStateFields;
    }) {
        const fields: FormStateFields = { ...formFields };
        for (const property in formFields) {
            if (property.includes(prefixFieldKey)) {
                if (property.includes('nowy')) {
                    delete fields[property];
                }
            }
        }
        return fields;
    }

    private setObjectsDataItemToDelete({
        prefixFieldKey,
        deleteRelationshipState
    }: {
        prefixFieldKey: string;
        deleteRelationshipState: DeleteRelationship;
    }) {
        const relationToDelete =
            this.state.deleteRelationship[`${prefixFieldKey}_powiazanie_usun_obiekt`];
        const setDelete = relationToDelete === '1' ? '0' : '1';
        const deleteRelationship = {
            ...deleteRelationshipState,
            [`${prefixFieldKey}_powiazanie_usun_obiekt`]: setDelete
        };
        return deleteRelationship;
    }

    private clearNewObjectsDataItem({
        prefixFieldKey,
        tabsPopup
    }: {
        prefixFieldKey: string;
        tabsPopup: TabsPopupFormExtended;
    }) {
        const tabs = {
            ...tabsPopup
        };
        for (const key in tabsPopup) {
            if (Array.isArray(tabsPopup[key])) {
                tabs[key] = tabsPopup[key].map((item) => {
                    return {
                        ...item,
                        relations: item.relations.map((relation) => {
                            return {
                                ...relation,
                                newObjectsData: relation.newObjectsData.filter(
                                    (objectNewData) => objectNewData.prefixKey !== prefixFieldKey
                                )
                            };
                        })
                    };
                });
            }
        }
        return tabs;
    }

    // case add_new_relation_item_empty

    private addFieldsForNewObjectsData({
        relatedObjectData,
        relation
    }: {
        relation?: PopupFormRelatedObject;
        relatedObjectData?: RelatedObjectData;
    }) {
        const fields: FormStateFields = {
            ...this.state.fields
        };
        if (relatedObjectData && relation) {
            Object.assign(
                fields,
                this.createFieldsForRelation({
                    relatedObjectData,
                    relation
                })
            );
        }
        return fields;
    }

    // shared

    private createFieldsForRelation({
        relatedObjectData,
        relation
    }: {
        relation: PopupFormRelatedObject;
        relatedObjectData: RelatedObjectData;
    }) {
        const fields: FormStateFields = {};
        relation.fields.forEach((field: DynamicFieldT) => {
            const startFieldKey = `nowy_rpo_${relation.id}_${relatedObjectData.id}`;
            const fieldKey = `${startFieldKey}_${field.kod}`;
            const fieldState = this.setInitValue({
                startFieldKey,
                field,
                fieldKey,
                relation,
                relatedObjectData
            });
            fields[fieldKey] = fieldState;
        });
        return fields;
    }

    private updateSums({ formState }: { formState: FormState }) {
        return new SumsCalcManager({
            sums: formState.sumsRelationship,
            tempFieldsState: {},
            formState
        }).runManyRelation();
    }

    private setInitValue({
        field,
        fieldKey,
        relation,
        relatedObjectData,
        startFieldKey
    }: {
        field: DynamicFieldT;
        fieldKey: string;
        relation?: PopupFormRelatedObject;
        relatedObjectData?: RelatedObjectData;
        startFieldKey?: string;
    }): Fields {
        const sharedProperties = createSharedPropertiesForFieldState({
            field,
            fieldKey,
            relation,
            relatedObjectData,
            startFieldKey,
            usedForAddingObject: this.usedForAddingObject
        });
        const fieldToReturn: Fields = setInitValueField({
            sharedProperties,
            usedForAddingObject: this.usedForAddingObject,
            relatedObjectData,
            field
        });
        return fieldToReturn;
    }

    static overrideFieldPropertiesOnChange() {
        return {
            isError: false
        };
    }
}
