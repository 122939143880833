import React, { FC } from 'react';

import { DynamicFormContextProvider } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import { ContentProps } from './types.td';
import { DynamicFormObjectWrapper } from 'src/components/popupFormExtended/dynamicFormObjectWrapper/DynamicFormObjectWrapper';

export const Content: FC<ContentProps> = ({ availableWidth, keyPopup }) => {
    return (
        <DynamicFormContextProvider keyPopup={keyPopup}>
            <DynamicFormObjectWrapper availableWidth={availableWidth} keyPopup={keyPopup} />
        </DynamicFormContextProvider>
    );
};
