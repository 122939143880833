import React, { FC } from 'react';

import { UIFieldRelationshipTypeMany } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipTypeMany/UIFieldRelationshipTypeMany';
import { FieldRelationshipTypeManyProps } from './types';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { Fields } from 'src/components/popupFormExtended/dynamicField/types';
import { getTypeUrl } from 'src/utils';
import { BroadcastAddBackPayload } from 'src/components/popupFormExtended/types';
import { createFilterOptions } from '@mui/material';
import { FieldRelationshipTypeAutocompleteOption } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipType/types';
import { FieldRelationshipTypeValueManyItem } from 'src/data/popupFormTypes';
import { useAddBackBroadcastChannel } from 'src/hooks/src/popupFormExtended/useAddBackBroadcastChannel';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { FieldRelationshipTypeManyBasicTypeValue } from 'src/data/fieldsFormType';
import { generateAddBackUrlTwo } from 'src/utils/src/popupFormExtended/generateAddBackUrlTwo';

const filter = createFilterOptions<FieldRelationshipTypeAutocompleteOption>();

export const FieldRelationshipTypeManyObject: FC<FieldRelationshipTypeManyProps> = ({
    field,
    uiFieldCommonProps,
    popupFormExtendedData,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    prefix,
    relationId
}) => {
    const [inputValue, setInputValue] = React.useState('');
    const startAddBackBroadcastChannel = useAddBackBroadcastChannel(dynamicFieldT.kod);

    const [optionToChoose, setOptionToChoose] = React.useState<
        {
            id: string | null;
            text: string;
        }[]
    >([]);
    const url = getTypeUrl({ field: dynamicFieldT });
    const getOptionsFromApi = (value: string) => {
        if (value != null && value.length >= 1) {
            const params = {
                q: value,
                typ: field.value.select
            };
            getFieldAdditionalData({
                url,
                params
            })
                .then((resp) => {
                    if (resp?.data) {
                        setOptionToChoose(resp?.data);
                    } else {
                        setOptionToChoose([]);
                    }
                })
                .catch((err) => {});
        } else {
            setOptionToChoose([]);
        }
    };

    const setFieldValue = (newValue: FieldRelationshipTypeManyBasicTypeValue) => {
        const newField = {
            ...field,
            value: newValue
        };
        dispatch({
            type: 'powiazanie_typ_wielokrotne',
            payload: {
                code: keyOfFieldInState,
                value: newField
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newField,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };

    const handleAddNewObject = () => {
        window.open(generateAddBackUrlTwo(dynamicFieldT.kod, field.value.select));

        startAddBackBroadcastChannel((event: MessageEvent<BroadcastAddBackPayload>) => {
            setFieldValue({
                ...field.value,
                objects: [
                    ...field.value.objects,
                    {
                        id: event.data.id,
                        nazwa: event.data.name,
                        typ: field.value.select,
                        typ_nazwa: field.value.select
                    }
                ]
            });
            setInputValue('');
        });
    };

    return (
        <UIFieldRelationshipTypeMany
            onInputChangeAutocomplete={(_, value: string, reason) => {
                if (reason === 'input') {
                    setInputValue(value);
                    getOptionsFromApi(value);
                }
            }}
            autocompleteFilterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (dynamicFieldT.dodanie_nowego === 't')
                    filtered.push({ id: 'nowy', text: 'Dodaj...' });

                return filtered;
            }}
            opcjeSelect={dynamicFieldT.opcje ? dynamicFieldT.opcje : {}}
            inputValueAutocomplete={inputValue ?? ''}
            onChangeAutocomplete={(_, newValue: { id: string; text: string }) => {
                const found = field.value.objects.find((item) => item.nazwa === newValue.text);
                if (newValue && !found) {
                    if (newValue.id === 'nowy') {
                        handleAddNewObject();
                        return;
                    } else {
                        setInputValue('');
                        const typeName = dynamicFieldT?.opcje?.[field.value?.select];
                        const newField: Fields = {
                            ...field,
                            value: {
                                ...field.value,
                                objects: [
                                    ...field.value.objects,
                                    {
                                        id: newValue.id,
                                        nazwa: newValue.text,
                                        typ: field.value.select,
                                        typ_nazwa: typeName ?? field.value.select
                                    }
                                ]
                            }
                        };
                        dispatch({
                            type: 'powiazanie_typ_wielokrotne',
                            payload: {
                                code: keyOfFieldInState,
                                value: newField
                            }
                        });

                        dispatch(
                            doDependentCalculationsAfterFieldChanged({
                                fieldState: newField,
                                dynamicFieldT: dynamicFieldT,
                                popupFormExtendedData,
                                prefix,
                                relationId,
                                keyOfFieldInState
                            })
                        );
                    }
                }
            }}
            onChangeSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOptionToChoose([]);
                dispatch({
                    type: 'powiazanie_typ_wielokrotne',
                    payload: {
                        code: keyOfFieldInState,
                        value: {
                            ...field,
                            value: {
                                ...field.value,
                                select: e.target.value
                            }
                        }
                    }
                });
            }}
            onClearAutocomplete={() => {
                setInputValue('');
            }}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            value={field.value}
            optionsAutocomplete={optionToChoose}
            onClearItem={(item2: FieldRelationshipTypeValueManyItem) => {
                const filtered = field.value.objects.filter((item) => {
                    if (item2.id === item.id && item2.typ === item.typ) {
                        return false;
                    }
                    return true;
                });
                const filteredElements = field.value.elements.map((item) => {
                    if (item2.id === item.id && item2.typ === item.typ) {
                        return {
                            ...item,
                            isToDelete: true
                        };
                    }
                    return item;
                });
                const newField: Fields = {
                    ...field,
                    value: {
                        ...field.value,
                        elements: filteredElements,
                        objects: filtered
                    }
                };
                dispatch({
                    type: 'powiazanie_typ_wielokrotne',
                    payload: {
                        code: keyOfFieldInState,
                        value: newField
                    }
                });

                dispatch(
                    doDependentCalculationsAfterFieldChanged({
                        fieldState: newField,
                        dynamicFieldT: dynamicFieldT,
                        popupFormExtendedData,
                        prefix,
                        relationId,
                        keyOfFieldInState
                    })
                );
            }}
        />
    );
};
