import { getIngredientsKeySum } from 'src/utils/src/budget/getIngredientsKeySum';

export type TypeOfMatchedKeySum =
    | 'przedcapfee'
    | 'capfee'
    | 'podsumowanieZPol'
    | 'podsumowanieZSum'
    | 'podsumowanieZSumTwo'
    | 'miesiac';

const keySumConditionForPrzedCapfee = [
    'oplatywstepne_za_sprawy',
    'kosztyzastepstwa',
    'ryczalty_za_sprawy',
    'dokumenty_kontrakt',
    'successfees',
    'ryczalt'
];
const keySumConditionForCapfee = ['przedcapfee'];
const keySumConditionForPodsumowanieZSum = ['przedcapfee', 'capfee'];
const keySumConditionForPodsumowanieZSumTwo = ['miesiac', 'rok'];

export function isMatchedKeySum(
    keySum: string,
    keySumInTempState?: string,
    typeOfMatchedKeySum?: TypeOfMatchedKeySum
) {
    if (keySumInTempState) {
        const {
            keySumLevelZero,
            keySumLevelOne,
            keySumLevelTwo,
            keySumLevelThree,
            keySumTempStateZero,
            keySumTempStateOne,
            keySumTempStateTwo,
            keySumTempStateThree,
            keySumTempStateFour
        } = getIngredientsKeySum(keySum, keySumInTempState);
        switch (typeOfMatchedKeySum) {
            case 'podsumowanieZPol':
            case 'przedcapfee': {
                if (
                    keySumConditionForPrzedCapfee.includes(keySumTempStateZero) &&
                    keySumLevelOne === keySumTempStateOne &&
                    keySumLevelTwo === keySumTempStateTwo
                )
                    return true;
                else return false;
            }
            case 'capfee': {
                if (
                    keySumConditionForCapfee.includes(keySumTempStateZero) &&
                    keySumLevelOne === keySumTempStateOne &&
                    keySumLevelTwo === keySumTempStateTwo
                )
                    return true;
                else return false;
            }
            case 'podsumowanieZSum': {
                if (
                    keySumConditionForPodsumowanieZSum.includes(keySumTempStateZero) &&
                    keySumLevelOne === keySumTempStateOne &&
                    keySumLevelTwo === keySumTempStateTwo
                )
                    return true;
                else return false;
            }
            case 'podsumowanieZSumTwo': {
                if (
                    keySumConditionForPodsumowanieZSumTwo.includes(keySumTempStateZero) &&
                    keySumLevelOne === keySumTempStateOne &&
                    keySumLevelTwo === keySumTempStateTwo &&
                    !keySumTempStateFour
                )
                    return true;
                else return false;
            }
            case 'miesiac': {
                if (
                    !keySumInTempState?.includes('limit_godzin') &&
                    keySumLevelZero === keySumTempStateZero &&
                    keySumLevelOne === keySumTempStateOne &&
                    keySumLevelTwo === keySumTempStateTwo &&
                    keySumLevelThree === keySumTempStateThree
                ) {
                    return true;
                } else if (
                    keySumInTempState?.includes('stawkaspecjalna') &&
                    keySumTempStateZero === keySumLevelZero &&
                    keySumTempStateOne === '9' &&
                    keySumTempStateTwo === keySumLevelTwo &&
                    keySumTempStateThree === keySumLevelThree
                ) {
                    return true;
                } else return false;
            }

            default:
                if (keySumInTempState?.includes(keySum)) return true;
                else return false;
        }
    }

    return false;
}
