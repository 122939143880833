import { BudgetHandlerStateGlobalKind } from 'src/store/src/budget/budget/types';

export const checkRunMethodSumInCase = (
    budgetHandlerStateGlobalKind: BudgetHandlerStateGlobalKind,
    caseItemId: string,
    caseItemIdWasInteracted?: string
) => {
    switch (budgetHandlerStateGlobalKind?.kind) {
        case 'getYearsBefore':
            return true;
        default:
            if (caseItemIdWasInteracted === caseItemId) {
                return true;
            }
            return false;
    }
};
