import { CalendarType } from '../../../store/src/calendar/calendar/types';
import { ResourcesResp } from '../../types';
import { queryServerData } from './queryServerData';

/**
 * Fetches selectable calendar resources either employees or reservable assets.
 * Also Fetches  all possible employees initials to display on event cells.
 * Employees resources list can be more limited than all employee initials
 */
export async function fetchResources(
    view: CalendarType,
    entityID?: number
): Promise<ResourcesResp> {
    return queryServerData<ResourcesResp>('api_react/calendar.php', {
        type: 'resources',
        view,
        entity_id: entityID
    });
}
