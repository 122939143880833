import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldDateObjectProps } from './types';
import { GetDate } from 'src/components/shared/atoms/datePicker/DatePicker';
import { UiFieldDate } from 'src/components/popupFormExtended/dynamicField/fieldDate/UiFieldDate';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';

export const FieldDateObject: FC<FieldDateObjectProps> = ({
    field,
    dispatch,
    keyOfFieldInState,
    popupFormExtendedData,
    dynamicFieldT,
    uiFieldCommonProps,
    prefix,
    relationId
}) => {
    const onChange = (data: GetDate) => {
        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: data.dateSelectedNormal
        };
        dispatch({
            type: 'data',
            payload: {
                code: keyOfFieldInState,
                value: newValue
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newValue,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };
    return (
        <UiFieldDate
            value={field.value}
            onChange={onChange}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
