import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './Loader.module.css';
export const LoaderPHP = () => {
    return (
        <div className={styles.wrapper}>
            <CircularProgress color="primary" size={60} />
        </div>
    );
};
