import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiGlobalSearchData } from './types';

export async function fetchGlobalSearchInitData(
    q: string
): Promise<BaseApiResponse<ApiGlobalSearchData>> {
    const params = new URLSearchParams([['q', q]]);
    return simpleQueryAPIData(
        `api_react/src/globalSearchScreen/getInitialDataGlobalSearch.php?${params.toString()}`,
        'GET'
    );
}
