import React, { FC } from 'react';

import { UIFieldNumberSettledToZeroProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import styles from './UiFieldNumberSettledToZero.module.css';
import { fields } from 'src/constants/subtitles';

export const UIFieldNumberSettledToZero: FC<UIFieldNumberSettledToZeroProps> = (props) => {
    const { value, onChange, onBlur, uiFieldCommonProps, uiFieldSharedProps } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.baseValue}
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'baseValue')}
                    onBlur={onBlur}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                <p>{fields.fieldNumberSettledToZero.oneLabel}</p>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.settled}
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'settled')}
                    onBlur={onBlur}
                />
                <p>{fields.fieldNumberSettledToZero.twoLabel}</p>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.restToSettle}
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(e, 'restToSettle')
                    }
                    onBlur={onBlur}
                />
            </div>
        </UiWrapper>
    );
};
