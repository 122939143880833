import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { UIFieldCheckboxProps } from './types';
import { getColorForInputWithAllFlag } from 'src/styles/getColor';
import styles from './UiFieldCheckbox.module.css';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiCheckbox } from 'src/components/shared/atoms/uiInputs/uiCheckbox/UiCheckbox';

export const UiFieldCheckbox: FC<UIFieldCheckboxProps> = (props) => {
    const { value, onClick, uiFieldCommonProps, uiFieldSharedProps } = props;
    const { disabled } = uiFieldCommonProps;
    const theme = useTheme();
    return (
        <div className={styles.wrapper}>
            <UiCheckbox
                onChange={onClick}
                value={value}
                checked={value}
                disabled={disabled}
                sxcheckbox={{
                    color: `${getColorForInputWithAllFlag({
                        theme,
                        flag: { ...uiFieldCommonProps }
                    })}`
                }}
            />{' '}
            <div onClick={onClick} className={styles.pointer}>
                <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            </div>
        </div>
    );
};
