import { ScreenRHF } from 'src/data/commonRHF';
import { FieldKeyErrorRHF, FieldKeyResetRHF } from 'src/data/fieldsReactHookForm';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { SettleObjectId } from 'src/store/src/budget/budget/types';

type HelperRHFGetPropertyKeyFNProps = {
    key: string;
    type: 'id' | 'kod';
    screen: ScreenRHF;
};

type HelperRHFGetPropertiesKeyFNProps = {
    key: string;
    screen: ScreenRHF;
};

type HelperRHFCreateKeyFNProps = {
    id?: string;
    dynamicFieldT: DynamicFieldT;
    screen: ScreenRHF;
};

export class HelperRHF {
    static createKey({ id, dynamicFieldT, screen }: HelperRHFCreateKeyFNProps) {
        switch (screen) {
            case 'massEditObjects':
                return `${id}_${dynamicFieldT.kod}`;
            default:
                return `${dynamicFieldT.kod}`;
        }
    }

    static createKeySpecialStake({
        specialRateOptionCode,
        objectId
    }: {
        objectId: SettleObjectId;
        specialRateOptionCode?: string;
    }) {
        if (!specialRateOptionCode) return '';
        return specialRateOptionCode?.replace('{$item.id}', objectId);
    }

    static createKeysShared({ id, dynamicFieldT, screen }: HelperRHFCreateKeyFNProps): {
        keyError: FieldKeyErrorRHF;
        keyReset: FieldKeyResetRHF;
        keyField: string;
    } {
        const keyField: string = HelperRHF.createKey({ id, dynamicFieldT, screen });
        const keyError: FieldKeyErrorRHF = `${HelperRHF.createKey({
            id,
            dynamicFieldT,
            screen
        })}.isError`;
        const keyReset: FieldKeyResetRHF = `${HelperRHF.createKey({
            id,
            dynamicFieldT,
            screen
        })}.keyReset`;
        return {
            keyError,
            keyReset,
            keyField
        };
    }

    static getPropertyKey({ key, type, screen }: HelperRHFGetPropertyKeyFNProps) {
        const keySplited = key.split('_');
        switch (screen) {
            case 'massEditObjects':
                switch (type) {
                    case 'id':
                        return keySplited[0] ?? null;
                    case 'kod':
                        return keySplited[1] ?? null;
                    default:
                        return null;
                }
            default:
                return null;
        }
    }
    static getPropertiesKey({ key, screen }: HelperRHFGetPropertiesKeyFNProps) {
        const keySplited = key.split('_');
        switch (screen) {
            case 'massEditObjects':
                return [keySplited[0], keySplited?.[1]];
            default:
                return [];
        }
    }
}
