import React from 'react';
import { fetchHistoryReadingObjectInitData } from 'src/api/src/historyReadingObject/fetchHistoryReadingObjectScreenInitData';
import { HistoryReadingObjectData } from 'src/api/src/historyReadingObject/types';
import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';

export const useHistoryReadingObjectDefaultData = () => {
    const id = useSearchParam('id');
    const object = useSearchParam('obiekt');

    const [data, setData] = React.useState<HistoryReadingObjectData>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            if (id && object) {
                const res = await fetchHistoryReadingObjectInitData(id, object);
                if (res.success) {
                    console.log(res);
                    setData(res.data);
                }
            }
            setIsLoading(false);
        })();
    }, []);

    return {
        isLoading,
        data
    };
};
