import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import styles from './UiContainerInputsNormal.module.css';
type Props = {
    sx?: SxProps<Theme>;
};

export const UiContainerInputsNormal: FC<Props> = ({ children, sx }) => {
    return (
        <Box
            sx={{
                ...sx
            }}
            className={styles.wrapper}>
            {children}
        </Box>
    );
};
