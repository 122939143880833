import React, { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material/styles';

import { BeltProps } from './types';
import styles from './Belt.module.css';

const Belt = React.forwardRef<HTMLDivElement, PropsWithChildren<BeltProps>>(
    ({ index, name, children, withoutBorderTop, type }, ref) => {
        const theme = useTheme();

        const calc = `${type === 'section' ? 'calc(100% - 5px)' : '100%'}`;

        if (withoutBorderTop) {
            return (
                <div
                    ref={ref}
                    className={styles.belt}
                    style={{
                        width: calc
                    }}>
                    <p className={styles.p}>{name}</p>
                    {children}
                </div>
            );
        }
        return (
            <div
                ref={ref}
                className={styles.belt}
                style={{
                    width: calc,
                    borderTop: `1px solid ${index ? theme.palette.primary.main : 'transparent'}`
                }}>
                <p className={styles.p}>{name}</p>
                {children}
            </div>
        );
    }
);

Belt.displayName = 'Belt';
export default Belt;
