import { TransformationsState } from 'src/store/src/listScreen/transformations/types';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { ObjectSlice } from 'src/store/src/object/object/types';
import { Additional } from 'src/store/src/object/object/types';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { TemplateMainMonkey } from 'src/utils/src/shared/TemplateMainMonkey';
import { CombinedPropertiesOpionalFromActionBehaviorData, SystemAction } from 'src/data/types';
import { TemplateMainItem } from 'src/utils/src/shared/TemplateMainItem';
import { TemplateMainDolar } from 'src/utils/src/shared/TemplateMainDolar';
import { TreeTableState } from 'src/store/src/listScreen/treeData/types';

const regexD = /\{((\$|#|@|!)(.*?))\}/;
const regexListScreenParams = /(@listScreenParams)/;
const regexListScreenExcelParams = /(@listScreenExcelParams)/;

const specialRegexs: { type: SpecialRegexsTypes; regex: RegExp }[] = [
    {
        type: 'isListScreenExcelParams',
        regex: regexListScreenExcelParams
    },
    {
        type: 'isListScreenParams',
        regex: regexListScreenParams
    }
];

type SpecialRegexsTypes = 'isListScreenExcelParams' | 'isListScreenParams';

type TemplateManagerItemProps = {
    rowData: RowData[];
    objectSlice: ObjectSlice;
    additional: Additional;
    sourceOfTrigger: SourceOfTrigger;
    transformationState: TransformationsState;
    screen?: string | null;
    rowId?: RowDataId | null;
    totalIds?: string[];
    action?: SystemAction;
    treeData?: TreeTableState;
};

type Payload = { key: string; value: string };

type PropertyArr = { key: string; value: string };

type SettingArr = {
    keyPropertyInData: 'payload' | 'description';
};

type SettingStr = {
    keyPropertyInData: 'params' | 'body' | 'url';
};

export class TemplateItemMainManager {
    rowId: string | null;
    screen?: null | string;
    rowData: RowData[];
    objectSlice: ObjectSlice;
    additional: Additional;
    sourceOfTrigger: SourceOfTrigger;
    totalIds?: string[];
    transformationState: TransformationsState;
    action?: SystemAction;
    treeData?: TreeTableState;
    constructor({
        rowData,
        transformationState,
        rowId,
        screen,
        totalIds,
        action,
        objectSlice,
        additional,
        sourceOfTrigger,
        treeData
    }: TemplateManagerItemProps) {
        this.screen = screen;
        this.rowId = rowId ? rowId : null;
        this.rowData = rowData;
        this.totalIds = totalIds;
        this.transformationState = transformationState;
        this.action = action;
        this.additional = additional;
        this.objectSlice = objectSlice;
        this.sourceOfTrigger = sourceOfTrigger;
        this.treeData = treeData;
    }

    findAndReplaceTemplates({ str }: { str: string | undefined | null }) {
        if (!str) return '';
        let i = true;
        let temporaryAllString = str;
        do {
            const itemStr = temporaryAllString.match(regexD);
            if (itemStr) {
                const templateMainItem = new TemplateMainItem({ itemStr: itemStr[0] });
                temporaryAllString = this.manageTemplate({ templateMainItem, temporaryAllString });
            } else {
                i = false;
            }
        } while (i);
        return temporaryAllString;
    }

    manageTemplate({
        templateMainItem,
        temporaryAllString
    }: {
        templateMainItem: TemplateMainItem;
        temporaryAllString: string;
    }) {
        switch (templateMainItem.sign) {
            case '@': {
                return new TemplateMainMonkey({
                    rowData: this.rowData,
                    rowId: this.rowId,
                    totalIds: this.totalIds,
                    templateMainItem,
                    temporaryAllString,
                    transformationState: this.transformationState,
                    screen: this.screen,
                    action: this.action,
                    sourceOfTrigger: this.sourceOfTrigger,
                    additional: this.additional,
                    objectSlice: this.objectSlice,
                    treeData: this.treeData
                }).getData();
            }
            case '$': {
                return new TemplateMainDolar({
                    rowData: this.rowData,
                    rowId: this.rowId,
                    templateMainItem,
                    temporaryAllString,
                    additional: this.additional,
                    sourceOfTrigger: this.sourceOfTrigger,
                    objectSlice: this.objectSlice
                }).getData();
            }
            default:
                return temporaryAllString;
        }
    }

    static checkIsTemplateExist({ str, regex = regexD }: { str: string; regex?: RegExp }) {
        return str.match(regex);
    }

    static getAllTemplatesExisted({
        str,
        specialRegexs
    }: {
        str: string;
        specialRegexs: { type: SpecialRegexsTypes; regex: RegExp }[];
    }) {
        const allTemplate: { [key in SpecialRegexsTypes]?: any } = {};
        specialRegexs.forEach((regex) => {
            allTemplate[regex.type] = TemplateItemMainManager.checkIsTemplateExist({
                str: str,
                regex: regex.regex
            });
        });

        return allTemplate;
    }
}

export type TemplateManagerActionBehavior = {
    type: string;
    data: CombinedPropertiesOpionalFromActionBehaviorData;
};

type TemplateManagerProps = {
    rowData: RowData[];
    objectSlice: ObjectSlice;
    additional: Additional;
    sourceOfTrigger: SourceOfTrigger;
    transformationState: TransformationsState;
    screen?: string | null;
    rowId?: RowDataId | null;
    totalIds?: string[];
    actionBehavior: TemplateManagerActionBehavior;
    action?: SystemAction;
    treeData?: TreeTableState;
    str?: string;
};

export class TemplateMainManager {
    actionBehavior: TemplateManagerActionBehavior;
    params: string;
    body: any;
    url: string;
    locationStateFlag: boolean;
    payload: Payload[];
    templateItemMainManager: TemplateItemMainManager;
    str: string;
    constructor({
        rowData,
        transformationState,
        actionBehavior,
        rowId,
        screen,
        totalIds,
        action,
        objectSlice,
        sourceOfTrigger,
        additional,
        treeData,
        str
    }: TemplateManagerProps) {
        this.actionBehavior = actionBehavior;
        this.params = '';
        this.body = '';
        this.url = '';
        this.locationStateFlag = false;
        this.payload = [];
        this.str = str ?? '';
        this.templateItemMainManager = new TemplateItemMainManager({
            rowData,
            transformationState,
            rowId,
            screen,
            totalIds,
            action,
            additional,
            objectSlice,
            sourceOfTrigger,
            treeData
        });
    }

    handleArr(settingData: SettingArr) {
        const arr: PropertyArr[] = [];
        if (settingData.keyPropertyInData === 'payload') {
            const payloadToParse = this.actionBehavior?.data?.payload
                ? this.actionBehavior?.data?.payload
                : [];
            const payloadParsed = this.preparePayloadData(payloadToParse);
            payloadParsed.forEach((item: Payload) => {
                const obj: any = {};
                for (const property in item) {
                    obj[property] = this.templateItemMainManager.findAndReplaceTemplates({
                        str: item[property as keyof Payload]
                    });
                }
                arr.push(obj);
            });
        }
        return arr;
    }

    handleStr(settingData: SettingStr) {
        return this.templateItemMainManager.findAndReplaceTemplates({
            str: this.actionBehavior?.data[settingData.keyPropertyInData]
        });
    }

    checkSpecialTemplate(str: string) {
        const specialTemplates = TemplateItemMainManager.getAllTemplatesExisted({
            str,
            specialRegexs
        });
        if (specialTemplates.isListScreenExcelParams) {
            this.body = {
                filters: this.templateItemMainManager.transformationState.filters,
                sortingData: this.templateItemMainManager.transformationState.sortingData
            };
        }
        if (specialTemplates.isListScreenParams) {
            this.locationStateFlag = true;
        }
    }

    private preparePayloadData(arr: string[]) {
        const newArr: Payload[] = [];
        try {
            arr.forEach((item) => {
                newArr.push(JSON.parse(item));
            });
        } catch (e) {
            console.error('Nieprawidłowy JSON');
            return [];
        }
        return newArr;
    }

    getData() {
        if (this.actionBehavior?.data?.url) {
            this.url = this.handleStr({
                keyPropertyInData: 'url'
            });
        }
        if (this.actionBehavior?.data?.body) {
            this.body = this.handleStr({
                keyPropertyInData: 'body'
            });
        }

        if (this.actionBehavior?.data?.payload) {
            this.payload = this.handleArr({
                keyPropertyInData: 'payload'
            });
        }

        if (this.actionBehavior?.data?.params) {
            this.params = this.handleStr({
                keyPropertyInData: 'params'
            });

            this.checkSpecialTemplate(this.actionBehavior?.data?.params);
        }

        if (this.str) {
            this.str = this.templateItemMainManager.findAndReplaceTemplates({
                str: this.str
            });
        }

        return {
            str: this.str,
            url: this.url,
            params: this.params,
            body: this.body,
            payload: this.payload,
            locationStateFlag: this.locationStateFlag
        };
    }
}
