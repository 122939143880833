import React from 'react';

import { dispatch } from 'src/store';
import { prepareToMount } from 'src/store/src/budget';
export const useUnMountBudget = () => {
    React.useEffect(() => {
        return () => {
            dispatch(prepareToMount());
        };
    }, []);
};
