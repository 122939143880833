import React, { FC } from 'react';

import { UIFieldDateHourProps } from './types';
import { DatePicker } from 'src/components/shared/atoms/datePicker/DatePicker';
import styles from './UiFieldDateHour.module.css';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { fieldDataHours } from 'src/constants/inputDateHourData';
import { fields } from 'src/constants/subtitles';

export const UiFieldDateHour: FC<UIFieldDateHourProps> = (props) => {
    const {
        value,
        onChangeHour,
        onChangeMinute,
        onChangeDate,
        uiFieldCommonProps,
        uiFieldSharedProps,
        optionsMinutes
    } = props;
    const { isLabel } = uiFieldCommonProps;
    return (
        <div className={styles.wrapper}>
            <UiWrapper>
                <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
                <DatePicker
                    {...uiFieldCommonProps}
                    dateInitial={value.date || undefined}
                    dateFromOuterToUpdate={value.date || undefined}
                    numberPanels="three"
                    getDate={(data) => {
                        onChangeDate(data);
                    }}
                    portalEnabled={true}
                    sxContainerPanels={{ position: 'absolute' }}
                    clearButton={true}
                    inputProps={uiFieldSharedProps.inputProps}
                />
            </UiWrapper>
            <UiWrapper>
                <UiFieldLabel
                    {...uiFieldCommonProps}
                    {...uiFieldSharedProps}
                    name={isLabel ? fields.fieldDateHour.oneLabel : ''}
                    isMandatory={false}
                />
                <UiInputSelectTwo
                    value={value.hour ?? ''}
                    opcje={fieldDataHours}
                    onChange={onChangeHour}
                />
            </UiWrapper>
            <UiWrapper>
                <UiInputSelectTwo
                    opcje={optionsMinutes}
                    value={value.minute ?? ''}
                    onChange={onChangeMinute}
                />
            </UiWrapper>
        </div>
    );
};
