import React, { FC, useMemo } from 'react';

import { FieldProps } from 'src/components/popupFormExtended/field/types';
import { FieldSwitcher } from 'src/components/popupFormExtended/fieldSwitcher/FieldSwitcher';
import { FieldUnmodifiedSwitcher } from 'src/components/popupFormExtended/fieldUnmodifiedSwitcher/FieldUnmodifiedSwitcher';
import { FormContext } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import { Fields, UiFieldCommonProps } from 'src/components/popupFormExtended/dynamicField/types';
import { usePopupState } from 'src/context/globalPopup/popupContext';

export const Field: FC<FieldProps> = (props) => {
    const { formState, dispatchFormState } = React.useContext(FormContext);
    const { popupStateRedux } = usePopupState(props.keyPopup);

    let fieldState: Fields = null;

    const madeUiFieldCommonProps: UiFieldCommonProps = useMemo(
        () => ({
            ...props.uiFieldCommonProps,
            maxlength: props.dynamicFieldT.rozmiar
        }),
        [props.dynamicFieldT.rozmiar, props.uiFieldCommonProps]
    );

    if (props.dynamicFieldT.obce === '1' || props.dynamicFieldT.obce_z_powiazan === '1') {
        fieldState = formState.fieldsForeign[props.keyOfFieldInState];
    } else {
        fieldState = formState.fields[props.keyOfFieldInState];
    }

    const fieldSuperior = formState.fields[props.dynamicFieldT.pole_nadrzedne_kod ?? ''];

    if (fieldState?.hidden) {
        return null;
    }
    if (fieldState?.isModify) {
        return (
            <FieldSwitcher
                {...props}
                popupFormExtendedData={popupStateRedux.popupFormExtendedData}
                dispatchFormState={dispatchFormState}
                screen={popupStateRedux.action?.behaviour.data?.screen}
                fieldSuperior={fieldSuperior}
                switcherFieldState={formState?.fields[props.keyOfFieldInState]}
                uiFieldCommonProps={madeUiFieldCommonProps}
            />
        );
    }

    return (
        <FieldUnmodifiedSwitcher
            {...props}
            uiFieldCommonProps={madeUiFieldCommonProps}
            field={fieldState}
        />
    );
};
