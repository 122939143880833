import { getMassEditObjectsInitData } from 'src/api/src/massEditObjectsScreen/getMassEditObjectsInitData';
import { AppThunk } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { setInitState } from 'src/store/src/massEditObjectsScreen/massEditObjects/massEditObjectsSlice';

export const fetchAndLoadInitialMassEditObjectsScreenData = (): AppThunk => async (dispatch) => {
    const apiResponse = await getMassEditObjectsInitData(window.location.search);
    console.log(apiResponse);
    if (!apiResponse.success) {
        dispatch(setSpinner(false));
        window.location.replace(`/message?tytul=${apiResponse.message}`);
        throw new Error('fetchAndLoadInitialMessageData: not get InitialMessageScreenData ');
    }
    dispatch(setInitState(apiResponse.data));
};
