import React, { FC } from 'react';

import { useTypedSelector, useAppDispatch } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { LegacyCompatibleLinkCreatedOnClick } from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLinkCreatedOnClick';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { LinkActionProps } from 'src/components/shared/molecules/actionType/linkAction/types';
import { useLocation } from 'react-router-dom';

export const LinkAction: FC<LinkActionProps> = ({ children, action, rowId, clickCallback }) => {
    const totalIdsIsActual = useTypedSelector(
        (state) => state.listScreenTableData.totalIdsIsActual
    );
    const location = useLocation();
    const dispatch = useAppDispatch();
    const additional = { sectionId: '', sectionIdx: 0, typeSection: null };
    const sourceOfTrigger: SourceOfTrigger = {
        type: 'list',
        view:
            location.pathname === '/lista-drzewo'
                ? 'drzewo'
                : location.pathname === '/lista-statusy'
                ? 'statusy'
                : 'listy'
    };

    const [isClicked, setIsCliced] = React.useState<boolean>(false);
    const handleConfirm = async () => {
        if (action.isAllIdsRequired && !totalIdsIsActual) {
            setIsCliced(true);
            dispatch(setSpinner(true));
            setTimeout(() => {
                dispatch(setSpinner(false));
            }, 600000);
            return;
        }
        handleSetPopupConfirmationLink();

        clickCallback && clickCallback();
    };

    React.useEffect(() => {
        if (isClicked && totalIdsIsActual) {
            handleSetPopupConfirmationLink();
            setIsCliced(false);
        }
    }, [totalIdsIsActual]);

    const handleSetPopupConfirmationLink = () => {
        dispatch(setSpinner(false));
        new PopupManager({ dispatch, action }).setPopupConfirmLink({
            rowId: rowId ? [rowId] : [],
            additional,
            sourceOfTrigger
        });
    };

    if (action.behaviour.data.confirmText) {
        return <div onClick={handleConfirm}>{children}</div>;
    } else {
        return (
            <LegacyCompatibleLinkCreatedOnClick
                action={action}
                sourceOfTrigger={sourceOfTrigger}
                additional={additional}
                rowId={rowId}>
                {children}
            </LegacyCompatibleLinkCreatedOnClick>
        );
    }
};
