import { ConvertableToParams } from 'src/data/types';
import { checkIfValueIsTypedArray } from './checkIfValueIsTypedArray';

export const convertParamsObjectToStr = (
    paramsObject: ConvertableToParams,
    startParam: string = '?'
) => {
    // not used simply for with urlSearcParams .set() because it not set multiple values for that
    //same key, and system should be compatible with php way to save arrays in search prarms i.e.
    // ?cars[]=Saab&cars[]=Audi

    let paramsString = startParam;

    for (const [key, value] of Object.entries(paramsObject)) {
        // handle array params saving
        if (checkIfValueIsTypedArray<number | string>(value)) {
            for (const element of value) {
                paramsString += `${key}[]=${element}&`;
            }
        } else {
            paramsString += `${key}=${value}&`;
        }
    }

    return paramsString;
};
