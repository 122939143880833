import React from 'react';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { styled } from '@mui/system';

import { dayShort } from 'src/constants/dataPicker';
import { DatePickerPanelExtendedProps } from 'src/components/shared/atoms/datePicker/DatePickerPanel';
import { DateManager } from 'src/utils';
import styles from './DayPicker.module.css';

const DayBox = styled('div')(({ theme }) => ({
    width: '19px',
    height: '19px',
    justify: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: '10px',
    borderRadius: '3px',
    border: '1px solid transparent',
    padding: '1px',
    '&.day': {
        cursor: 'pointer'
    },
    '&.day:hover': {
        backgroundColor: '#eee'
    },
    '&.day.selected': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    '&.day.today': {
        border: `1px solid ${theme.palette.primary.main}`
    },
    '&.header': {
        marginBottom: '7px'
    },
    '&.header:hover': {
        backgroundColor: 'initial',
        color: 'initial'
    },
    '&.disabled': {
        cursor: 'not-allowed',
        opacity: '0.5'
    }
}));

export type Day = {
    dayOfMonth: number;
    selected: boolean;
    isToday: boolean;
    isGhost: boolean;
    dayOfWeek: number;
};

export const DayPicker = ({ panel, dispatch, state, getDate }: DatePickerPanelExtendedProps) => {
    const theme = useTheme();

    const setDay = (day: number) => {
        const newDate = new Date(
            panel.year,
            panel.month,
            day,
            Number(state.hoursSelected),
            Number(state.minutesSelected)
        );
        dispatch({
            type: 'SET_SELECTED_DATE',
            payload: {
                ...state,
                datePanel: newDate,
                dateSelected: newDate,
                dateInputVisible: format(
                    new Date(newDate),
                    `dd${state.signDivider}MM${state.signDivider}yyyy`
                ),
                isToday: DateManager.isToday(newDate)
            }
        });
        getDate({
            dateSelectedNormal: newDate,
            dateSelectedFormatted: format(
                new Date(newDate),
                `dd${state.signDivider}MM${state.signDivider}yyyy`
            ),
            isToday: DateManager.isToday(newDate)
        });
    };

    const setClassDay = (day: Day) => {
        if (day.selected) {
            return 'day selected';
        } else if (day.isToday && !day.selected) {
            return 'day today';
        } else {
            return 'day';
        }
    };

    const days = () => {
        return panel.days.map((el, i) => {
            if (el.isGhost) {
                return <DayBox key={i}></DayBox>;
            }
            return (
                <DayBox key={i} className={setClassDay(el)} onClick={() => setDay(el.dayOfMonth)}>
                    {el.dayOfMonth}
                </DayBox>
            );
        });
    };

    return (
        <div
            style={{
                color: theme.palette.primary.main
            }}
            className={styles.wrapper}>
            {dayShort.map((el) => {
                return (
                    <div className={`${styles.box} ${styles.header}`} key={el}>
                        {el}
                    </div>
                );
            })}
            {days()}
        </div>
    );
};
