import { PopupState } from 'src/store/src/popup/popup/types';
import { initialState } from 'src/context/globalPopup/popupContext';

import { DimensionsSetter } from 'src/context/globalPopup/DimensionsSetter';

type GlobalPopupContextManagerInitProps = {
    popupState: PopupState;
};

export class GlobalPopupContextManager {
    static initContext(init: GlobalPopupContextManagerInitProps) {
        const state = {
            ...initialState
        };
        switch (init.popupState.action?.behaviour.type) {
            case 'popupFormExtended': {
                const { activeTabId, activeTabIdIndex } =
                    GlobalPopupContextManager.setStatePopupFormExtendedInit(init.popupState);
                state.activeTab.id = activeTabId ?? null;
                state.activeTab.index = activeTabIdIndex ?? null;
                state.tabs = init.popupState.popupFormExtendedData?.tabs
                    ? init.popupState.popupFormExtendedData?.tabs
                    : [];
                break;
            }
            default:
                break;
        }

        const dimensions = new DimensionsSetter({
            popupContext: initialState,
            popupState: init.popupState
        }).get();
        state.dimensions = dimensions;

        return state;
    }

    static setStatePopupFormExtendedInit(popupState: PopupState, id?: string) {
        const activeTabIdIndex = GlobalPopupContextManager.setActiveTabIndex(popupState, id);
        return {
            activeTabId: id ? id : popupState.popupFormExtendedData?.tabs[0].id,
            activeTabIdIndex
        };
    }
    static setActiveTabIndex(popupState: PopupState, id?: string) {
        const actualId = id ? id : popupState.popupFormExtendedData?.tabs[0].id;
        return popupState.popupFormExtendedData?.tabs.findIndex((item) => item.id === actualId);
    }
}
