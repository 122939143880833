import {
    CalendarDays,
    CalendarEvents,
    CalendarView,
    Grid,
    NoGridEvent
} from '../../store/src/calendar/calendar/types';
import { divideEvents } from './divideEvents';
import { calculateEnteredHours } from './calculateEnteredHours';
import { processNoGridEvents } from './processNoGridEvents';
import { processGridEvents } from './processGridEvents';
import { ProcessedData } from '../types';
import { CalendarEvent } from '../../api/types';

import { GridStructure } from 'src/utils/src/calendar/GridStructureCreator';

/**
 * gets raw events array, array of calendar days and given view, process and calculates all data to ready to dispatch to store:
 * - UserGridState,
 * - NoGridEvent[],
 * - CalendarEvents,
 * - EnteredHours
 */
export function processData(
    calendarEvents: CalendarEvents,
    events: CalendarEvent[],
    days: CalendarDays,
    view: CalendarView,
    employees: number[],
    gridStructure: GridStructure
): ProcessedData {
    let noGridEvents: NoGridEvent[] | null = null;
    let gridEvents: Grid | null = null;

    // divides events, and ads startDay and endDay timestamps
    const [gridEventsStubs, noGridEventsStubs] = divideEvents(events, days);

    // calculates number of etered hours for each day
    const enteredHours = calculateEnteredHours(events, days, employees);

    // in workweek and week view noGridEvents are displayed
    if (view !== 'month') {
        noGridEvents = processNoGridEvents(noGridEventsStubs, days);
    }
    // console.log(gridEventsStubs,'gridEventsStubs');
    // in workweek and week view noGridEvents are displayed
    if (view !== 'month') {
        // max 3 events in each column in week view, 4 in workweek, unlimited in day view
        let limit = view === 'week' ? 3 : view === 'workweek' ? 4 : undefined;
        gridEvents = processGridEvents(gridEventsStubs, days, gridStructure, limit);
    }

    return {
        enteredHours,
        grid: gridEvents,
        noGrid: noGridEvents,
        events: calendarEvents
    };
}
