import React, { FC } from 'react';

import styles from './TabsWithScroll.module.css';
import { TabsWithScrollProps } from 'src/components/popupFormExtended/tabsWithScroll/types';
import ArrowTwo from 'src/components/object/atoms/arrowTwo/ArrowTwo';
import { Tabs } from 'src/components/popupFormExtended/tabs/Tabs';
import { useTheme } from '@mui/material';

export const TabsWithScroll: FC<TabsWithScrollProps> = (props) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [scroll, setScroll] = React.useState(0);
    const theme = useTheme();
    const [idInterval, setIdInterval] = React.useState<NodeJS.Timeout>();

    const transform = (type: 'left' | 'right') => {
        const step = type === 'left' ? -10 : +10;
        if (ref.current) {
            const id = setInterval(() => {
                if (ref.current) {
                    ref.current.scrollLeft = ref.current.scrollLeft + step;
                    setScroll(ref.current.scrollLeft);
                }
            }, 0);
            setIdInterval(id);
        }
    };

    const clearTransform = () => {
        clearInterval(idInterval);
    };

    return (
        <div className={styles.boxOuter} ref={ref}>
            <div
                className={styles.arrowLeft}
                style={{
                    borderLeft: `1px solid ${theme.palette.primary.main}`
                }}
                onMouseDown={() => transform('left')}
                onMouseUp={clearTransform}>
                <ArrowTwo type="left" />
            </div>
            <Tabs {...props} scroll={scroll} />
            <div
                className={styles.arrowRight}
                style={{
                    borderRight: `1px solid ${theme.palette.primary.main}`
                }}
                onMouseDown={() => transform('right')}
                onMouseUp={clearTransform}>
                <ArrowTwo type="right" />
            </div>
        </div>
    );
};
