import React, { FC } from 'react';

import { DatePicker } from 'src/components/shared/atoms/datePicker/DatePicker';
import styles from './UiFieldDateHours.module.css';
import { UIFieldDateHoursProps } from 'src/components/popupFormExtended/dynamicField/fieldDateHours/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { fields } from 'src/constants/subtitles';
import { StartEndHoursMinutes } from 'src/components/shared/atoms/startEndHoursMinutes/StartEndHoursMinutes';
import { FieldDateHoursManager } from 'src/utils/src/shared/FieldDateHoursManager';

export const UiFieldDateHours: FC<UIFieldDateHoursProps> = (props) => {
    const {
        value,
        onChangeRozpHours,
        onChangeRozpMinutes,
        onChangeZakHours,
        onChangeZakMinutes,
        onChangeDate,
        uiFieldCommonProps,
        uiFieldSharedProps,
        dynamicFieldT
    } = props;
    const { isLabel } = uiFieldCommonProps;

    const dataValues = new FieldDateHoursManager({
        fieldStateValue: value
    }).prepareDataForInputs();

    return (
        <div className={styles.wrapper}>
            <UiWrapper>
                <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
                <DatePicker
                    {...uiFieldCommonProps}
                    dateInitial={value.date || undefined}
                    dateFromOuterToUpdate={value.date || undefined}
                    numberPanels="three"
                    getDate={(data) => {
                        onChangeDate(data);
                    }}
                    portalEnabled={true}
                    sxContainerPanels={{ position: 'absolute' }}
                    clearButton={false}
                    inputProps={uiFieldSharedProps.inputProps}
                />
            </UiWrapper>
            <UiWrapper>
                <UiFieldLabel
                    {...uiFieldCommonProps}
                    {...uiFieldSharedProps}
                    name={isLabel ? fields.fieldDateHours.oneLabel : ''}
                    isMandatory={false}
                />
                <StartEndHoursMinutes
                    dynamicFieldT={dynamicFieldT}
                    hours={dataValues.rozpH}
                    minutes={dataValues.rozpM}
                    onChangeH={onChangeRozpHours}
                    onChangeM={onChangeRozpMinutes}
                />
            </UiWrapper>
            <UiWrapper>
                <UiFieldLabel
                    {...uiFieldCommonProps}
                    {...uiFieldSharedProps}
                    name={isLabel ? 'Godz. Zak.' : ''}
                    isMandatory={false}
                />
                <StartEndHoursMinutes
                    dynamicFieldT={dynamicFieldT}
                    hours={dataValues.zakH}
                    minutes={dataValues.zakM}
                    onChangeH={onChangeZakHours}
                    onChangeM={onChangeZakMinutes}
                />
            </UiWrapper>
        </div>
    );
};
