import * as React from 'react';

import { FieldBankAccountNumber } from 'src/components/popupFormExtended/dynamicField/fieldBankAccountNumber/types';
import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFormObjectNRBValidatorProps, TypeValidateNRB } from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateNRB';
export class DynamicFormObjectNRBValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateNRB;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectNRBValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        for (const key in this.formState.fields) {
            if (this.dataRef.validationResult.isFormValid) {
                const dynamicFieldT = this.popupFormExtendedData.fields.find(
                    (item) => item.kod === this.formState.fields[key]?.kod
                );
                const field = this.formState.fields[key];
                if (
                    dynamicFieldT &&
                    field &&
                    field?.typ === 'numer_rachunku_bankowego' &&
                    dynamicFieldT.wylaczenie_weryfikacji_typu_danych !== 't' &&
                    !field?.hidden
                ) {
                    await this.runValidationOne(field, key);
                }
            } else {
                break;
            }
        }
    }
    private async runValidationOne(field: FieldBankAccountNumber, key: string) {
        const value = typeof field.value === 'string' ? field.value : '';
        const isValid = await this.checkIsValid(value);
        if (!isValid) {
            this.setValidationResult({
                typeOfInfo: 'Warning',
                type: {
                    issue: this.typeFnValidate,
                    message: `W polu ${field.name} znajduje się nieprawidłowy numer rachunku bankowego. Czy chcesz kontynuować?`,
                    title: validationData[this.typeFnValidate].title,
                    id_zakladki: field.id_zakladki,
                    fieldCode: field.kod
                }
            });
        }
    }
    private async checkIsValid(value: string) {
        let nrb = value.replace(/[^0-9]+/g, '');
        const scale = [
            1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25,
            56, 75, 71, 31, 19, 93, 57
        ];

        if (nrb.length == 26) {
            nrb = nrb + '2521';
            nrb = nrb.substring(2) + nrb.substring(0, 2);
            var z = 0;
            for (var i = 0; i < 30; i++) {
                const n = Number(nrb[29 - i]);
                z += n * scale[i];
            }
            if (z % 97 == 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
