import React from 'react';
export function useStyleForName(code: string): React.CSSProperties | undefined {
    switch (code) {
        case 'ryczalt_nazwa':
            return {
                fontWeight: 'bold',
                textAlign: 'left'
            };
        default:
            return;
    }
}
