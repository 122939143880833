import React from 'react';
import { useTypedSelector } from 'src/store';
import {
    BugdetFieldStructure,
    BugdetObjectStructure,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';

export const useGetBudgetObjectStructure = (code: SettledObjectsCodes) => {
    const settledObjectsStructures = useTypedSelector(
        (state) => state.budget.settledObjectsStructures
    );

    return React.useMemo(() => {
        return {
            budgetObjectStrucuture: prepareBugdetObjectStructure(settledObjectsStructures?.[code])
        };
    }, [code, settledObjectsStructures]);
};

export function prepareBugdetObjectStructure(budgetObjectStructure: BugdetObjectStructure) {
    if (budgetObjectStructure?.fields) {
        const sortedArr = [...budgetObjectStructure?.fields]?.sort(
            (a: BugdetFieldStructure, b: BugdetFieldStructure) => Number(a.id) - Number(b.id)
        );

        return {
            ...budgetObjectStructure,
            fields: sortedArr.map((field, i) => {
                if (i === 0) {
                    return {
                        ...field,
                        isPriceWithoutNumber: false
                    };
                }
                if (Number(field.id) > 7) {
                    return {
                        ...field,
                        isPriceWithoutNumber: true,
                        colspan: 1
                    };
                }
                return {
                    ...field,
                    isPriceWithoutNumber: false
                };
            })
        };
    }

    return {
        ...budgetObjectStructure
    };
}
