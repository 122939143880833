import React, { FC } from 'react';

import { UIFieldLocationProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import styles from './UiFieldLocation.module.css';

export const UIFieldLocation: FC<UIFieldLocationProps> = (props) => {
    const { value, onChange, onBlur, uiFieldCommonProps, uiFieldSharedProps } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.latitude}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'latitude')}
                    onBlur={onBlur}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                <p>N</p>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.longitude}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'longitude')}
                    onBlur={onBlur}
                />
                <p>E</p>
            </div>
        </UiWrapper>
    );
};
