export function prepareParamsToApi(params: { [key: string]: any }) {
    const newParams: { [key: string]: string } = {};
    for (const property in params) {
        const value = prepareParam(params[property]);
        if (value === 'NOT_ADD') {
            continue;
        }
        newParams[property] = value;
    }
    return newParams;
}

function prepareParam(value: string | null | number | boolean | undefined): string {
    switch (true) {
        case value === undefined:
        case value === null:
            return 'NOT_ADD';
        case typeof value === 'boolean':
            return value ? '1' : '0';
        case typeof value === 'number':
            return String(value);
        case typeof value === 'string':
            return String(value);
        default:
            return 'NOT_ADD';
    }
}
