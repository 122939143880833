import React from 'react';
import { useTypedSelector } from 'src/store';
import { FestivitiesIconsProvider } from 'src/utils/src/shared/FestivitiesIconsProvider';

const LoginLogoSign = () => {
    const systemName = useTypedSelector((state) => state.system.systemName);
    const systemLogoSign = useTypedSelector((state) => state.system.systemLogoSign);

    const logoFileSrc = React.useMemo(
        () => systemLogoSign ?? new FestivitiesIconsProvider().getLoginSign(),
        [systemLogoSign]
    );

    return (
        <img
            src={logoFileSrc}
            alt={systemName ?? 'System logo'}
            style={{
                width: '5rem',
                height: 'auto',
                padding: '0.5rem',
                objectFit: 'contain'
            }}
        />
    );
};

export default LoginLogoSign;
