import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFormObjectNIPValidatorProps, TypeValidateNIP } from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { FieldNip } from 'src/components/popupFormExtended/dynamicField/fieldNip/types';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateNIP';
export class DynamicFormObjectNIPValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateNIP;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectNIPValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        for (const key in this.formState.fields) {
            if (this.dataRef.validationResult.isFormValid) {
                const dynamicFieldT = this.popupFormExtendedData.fields.find(
                    (item) => item.kod === this.formState.fields[key]?.kod
                );
                const field = this.formState.fields[key];
                if (
                    dynamicFieldT &&
                    field &&
                    field?.typ === 'nip' &&
                    field.value !== '' &&
                    dynamicFieldT.wylaczenie_weryfikacji_typu_danych !== 't'
                ) {
                    await this.runValidationOne(field, key);
                }
            } else {
                break;
            }
        }
    }
    private async runValidationOne(field: FieldNip, key: string) {
        const value = typeof field.value === 'string' ? field.value : '';
        const isValid = await this.checkIsValid(value);
        if (!isValid) {
            this.setValidationResult({
                typeOfInfo: 'Warning',
                type: {
                    issue: this.typeFnValidate,
                    message: `W polu ${field.name} znajduje się nieprawidłowy NIP. Czy chcesz kontynuować?`,
                    title: validationData[this.typeFnValidate].title,
                    id_zakladki: field.id_zakladki,
                    fieldCode: field.kod
                }
            });
        }
    }
    private async checkIsValid(value: string) {
        const nip = value.replace(/[^0-9]+/g, '');
        const scale = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        if (nip.length == 10) {
            let z = 0;
            for (var i = 0; i < 9; i++) {
                const n = Number(nip[i]);
                z += n * scale[i];
            }
            if (z % 11 == Number(nip[9])) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
