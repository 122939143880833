import React, { FC } from 'react';
import { UiGrid } from 'src/components/shared/atoms/uiLayout/uiGrid/UiGrid';
import { Controller, Control } from 'react-hook-form';
import { DynamicField } from 'src/components/shared/molecules/dynamicField/DynamicField';
import { DynamicStateForm } from 'src/components/shared/molecules/dynamicForm/DynamicFormStateHandler';
import { DynamicFormInput } from 'src/components/shared/molecules/dynamicForm/DynamicFormInput';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
import { DynamicFieldT, PopupFormData } from 'src/data/popupFormTypes';

type Props = {
    id: RowDataId;
    control: Control<DynamicStateForm>;
    popupFormData?: PopupFormData;
};

export const DynamicFormItem: FC<Props> = ({ id, control, popupFormData }) => {
    return (
        <div
            style={{
                display: 'flex',
                width: '420px',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
            <UiGrid sx={{ width: '400px' }}>
                {popupFormData?.fields.map((dynamicFieldT: DynamicFieldT) => {
                    if (DynamicFormInput.checkIfDisplay({ field: dynamicFieldT, control })) {
                        return (
                            <Controller
                                key={dynamicFieldT.kod + dynamicFieldT.id}
                                name={id + '-' + dynamicFieldT.kod + '-' + dynamicFieldT.id}
                                control={control}
                                render={(props) => {
                                    return (
                                        <DynamicField
                                            dynamicFieldT={dynamicFieldT}
                                            {...props}
                                            field={props.field}
                                            isCheckboxTodayInDatePicker={true}
                                            isInputTimeInDatePicker={true}
                                        />
                                    );
                                }}
                            />
                        );
                    }
                })}
            </UiGrid>
        </div>
    );
};
