import { ImportPayoffIssuersType } from 'src/store/src/importPayoff/importPayoff/types';

export function createIssuersOptionsImportPayoff(issuers: ImportPayoffIssuersType[]) {
    const opcje: { [key: string]: string } = {};

    issuers.forEach((issuer) => {
        opcje[issuer.id] = issuer.nazwa;
    });
    return opcje;
}
