import { FormState as FormStateAddObject } from 'src/components/form/types';

import { FormState } from 'src/components/popupFormExtended/dynamicFormObject/types';

import { UpdateObjectDataForApi } from 'src/api/types';
import { Fields } from 'src/components/popupFormExtended/dynamicField/types';
import { PopupFormExtendedBehaviorData } from 'src/data/popupFormTypes';
import { fieldTypesNotSendedWhenNotModified } from 'src/constants/popupContentDynamicExtended/apiDataCreation';
import { DateManager } from 'src/utils';

type FormExtendedProps = {
    formState: FormState;
    formbehaviorData: PopupFormExtendedBehaviorData;
};

type AddObjectProps = {
    formState: FormStateAddObject;
};
export class FormObjectDataApiCreator {
    dataApi: UpdateObjectDataForApi;
    oldId: string;
    newId: number;
    constructor() {
        this.dataApi = {};
        this.oldId = 'XXX';
        this.newId = 1;
    }

    prepareDataFormAddObject(props: AddObjectProps) {
        const formState = props.formState;
        const fields: {
            [key: string]: Fields;
        } = {};

        for (const property in formState.fields) {
            const propertiesToAdd = this.prepateField(property, formState.fields[property]);

            Object.assign(fields, propertiesToAdd);
        }

        this.dataApi['nowy'] = {
            ...fields
        };
        return this.dataApi;
    }

    prepareDataForFormExtanded(props: FormExtendedProps) {
        const formState = props.formState;
        const id = props.formbehaviorData.objectId;
        const phpHiddenData = props.formbehaviorData.phpHiddenData;

        const fields: {
            [key: string]: Fields;
        } = {};

        for (const property in formState.fields) {
            const propertyPrepared = this.prepareCodeForNewObjectData(property);
            const propertiesToAdd = this.prepateField(propertyPrepared, formState.fields[property]);

            if (formState.fields[property]?.historyComments)
                propertiesToAdd[`${property}_uwagi`] = formState.fields[property]?.historyComments;

            Object.assign(fields, propertiesToAdd);
        }
        for (const property in formState.sumsRelationship) {
            Object.assign(fields, { [property]: formState.sumsRelationship[property] });
        }
        for (const property in formState.deleteRelationship) {
            const propertiesToAdd = formState.deleteRelationship[property];
            if (propertiesToAdd === '1') {
                Object.assign(fields, { [property]: formState.deleteRelationship[property] });
            }
        }

        // pass this special parameter from action params to api data
        if (phpHiddenData) {
            if (phpHiddenData['elementy_fakturowane'])
                Object.assign(fields, {
                    elementy_fakturowane: phpHiddenData['elementy_fakturowane']
                });
        }

        this.dataApi[id] = {
            ...fields
        };
        return this.dataApi;
    }

    private prepareCodeForNewObjectData(code: string) {
        if (code.includes('nowy')) {
            const splited = code.split('_');
            if (this.oldId === 'XXX') {
                this.oldId = splited[3];
            } else if (this.oldId !== splited[3]) {
                this.oldId = splited[3];
                this.newId = this.newId + 1;
            }
            return `${splited[0]}_${splited[1]}_${splited[2]}_${this.newId}_${splited[4]}`;
        } else {
            return code;
        }
    }

    private prepateField(keyOfField: string, fieldState: Fields) {
        if (
            !fieldState?.initialIsModify &&
            fieldState?.typ &&
            fieldState?.typ in fieldTypesNotSendedWhenNotModified
        )
            return {};

        switch (fieldState?.typ) {
            case 'checkbox':
                return { [keyOfField]: fieldState.value ? 1 : 0 };
            case 'data':
                return { [keyOfField]: DateManager.prepareDayMonthYearFromDate(fieldState.value) };
            case 'data_wzgledna':
                return {
                    [keyOfField]: fieldState.value.date,
                    [`${keyOfField}_zakres`]: fieldState.value.zakres
                };
            case 'data_godzina': {
                const date = DateManager.prepareDayMonthYearFromDate(fieldState.value.date);
                return {
                    [keyOfField]: date,
                    [`${keyOfField}Minute`]:
                        fieldState.value.minute?.length === 1
                            ? '0' + fieldState.value.minute
                            : fieldState.value.minute,
                    [`${keyOfField}Hour`]:
                        fieldState.value.hour?.length === 1
                            ? '0' + fieldState.value.hour
                            : fieldState.value.hour
                };
            }
            case 'powiazanie_typ':
                return {
                    [`${keyOfField}`]: fieldState.value.text,
                    [`${keyOfField}_typ`]: fieldState.value.type,
                    [`${keyOfField}_id`]: fieldState.value.value
                };
            case 'powiazanie_typ_wielokrotne': {
                const data = {
                    [`${keyOfField}_nowe`]: fieldState.value.objects.map((item) => {
                        return `${item.typ}_${item.id}`;
                    }),
                    [`${keyOfField}_typ`]: fieldState.value.select,
                    [`${keyOfField}_usun_pola`]: fieldState.value.elements
                        .filter((item) => {
                            if (item.isToDelete) {
                                if (
                                    fieldState.value.objects.find(
                                        (item2) => item2.id === item.id && item2.typ === item.typ
                                    )
                                ) {
                                    return false;
                                }
                                return true;
                            }
                            return false;
                        })
                        .map((item) => {
                            return `${item.typ}_${item.id}`;
                        })
                };

                if (!data[`${keyOfField}_nowe`].length) delete data[`${keyOfField}_nowe`];
                if (!data[`${keyOfField}_usun_pola`].length) delete data[`${keyOfField}_usun_pola`];
                return data;
            }
            case 'lista_obca':
                return {
                    [`${keyOfField}`]: fieldState.value.value,
                    [`${keyOfField}_tekst`]: fieldState.value.text
                };
            case 'koszty':
                return {
                    [`${keyOfField}`]: fieldState.value.value,
                    [`${keyOfField}_waluta`]: fieldState.value.currency
                };
            case 'lista_wielokrotna_cena':
            case 'lista_wielokrotna':
            case 'lista_obca_wielokrotna':
                return {
                    [`${keyOfField}`]: fieldState.value.values
                        .filter((el) => el.text !== 'WSZYSTKIE')
                        .map((el) => el.id),
                    // + converts boolean to 0/1 number
                    [`${keyOfField}_wszystkie`]: +(
                        fieldState.value.values.findIndex((el) => el.text === 'WSZYSTKIE') !== -1
                    )
                };
            case 'cena_liczba': {
                return {
                    [`${keyOfField}`]: fieldState.value.netto,
                    [`${keyOfField}_brutto`]: fieldState.value.brutto,
                    [`${keyOfField}_liczba`]: fieldState.value.liczba,
                    [`${keyOfField}_vat`]: fieldState.value.vat,
                    [`${keyOfField}_total_brutto`]: fieldState.value.total_brutto,
                    [`${keyOfField}_total_netto`]: fieldState.value.total_netto,
                    [`${keyOfField}_waluta`]: fieldState.value.waluta
                };
            }
            case 'cena':
                return {
                    [`${keyOfField}`]: fieldState.value.netto,
                    [`${keyOfField}_brutto`]: fieldState.value.brutto,
                    [`${keyOfField}_vat`]: fieldState.value.vat,
                    [`${keyOfField}_waluta`]: fieldState.value.waluta
                };
            case 'numer':
                return {
                    [`${keyOfField}_interfix`]: fieldState.value.interfix,
                    [`${keyOfField}_prefix`]: fieldState.value.prefix,
                    [`${keyOfField}_suffix`]: fieldState.value.suffix
                };
            case 'numer_rachunku_bankowego':
                return {
                    [`${keyOfField}`]: fieldState.value
                };
            case 'lista_zalezna':
            case 'lista':
            case 'lista_cena':
                return {
                    [`${keyOfField}`]: fieldState.value.value
                };
            case 'haslo_otwarte':
                return {
                    [`${keyOfField}`]: fieldState.value
                };
            case 'nip': {
                return {
                    [`${keyOfField}`]: fieldState.value
                };
            }
            case 'emaile_wielokrotne': {
                const elements = fieldState.value.elements.map((item) => {
                    const data = {
                        [`${keyOfField}_email_${item.id}_korespondencja`]: item.korespondencja
                            ? '1'
                            : '0',
                        [`${keyOfField}_email_${item.id}_wysylka_raportow`]: item.wysylka_raportow
                            ? '1'
                            : '0',
                        [`${keyOfField}_email_${item.id}_wysylka_reklamowa`]: item.wysylka_reklamowa
                            ? '1'
                            : '0'
                    };
                    if (item.isToDelete) {
                        data[`${keyOfField}_email_${item.id}_usuniecie_adresu`] = '1';
                    }
                    return data;
                });
                const newElements = fieldState.value.newElements
                    .filter((item) => item.adres)
                    .map((item, i) => {
                        return {
                            [`${keyOfField}_nowy_email_${i + 1}`]: item.adres,
                            [`${keyOfField}_nowy_email_${i + 1}_korespondencja`]:
                                item.korespondencja ? '1' : '0',
                            [`${keyOfField}_nowy_email_${i + 1}_wysylka_raportow`]:
                                item.wysylka_raportow ? '1' : '0',
                            [`${keyOfField}_nowy_email_${i + 1}_wysylka_reklamowa`]:
                                item.wysylka_reklamowa ? '1' : '0'
                        };
                    });
                const data = {
                    [`${keyOfField}_adresy_email_edycja`]: fieldState.value.elements.map(
                        (item) => item.id
                    )
                };
                elements.forEach((item) => Object.assign(data, item));
                newElements.forEach((item) => Object.assign(data, item));
                return this.clearZeroProperties(data);
            }
            case 'plik':
                return { [keyOfField]: { id: fieldState.value.id, name: fieldState.value.name } };
            case 'pliki_wielokrotne':
                return { [keyOfField]: fieldState.value.map((f) => ({ id: f.id, name: f.name })) };
            // this fields does not return value to api
            case 'haslo':
            case 'system_data':
                return {};
            case 'data_godziny': {
                const date = DateManager.prepareDayMonthYearFromDate(fieldState.value.date);
                const rozp = fieldState.value?.rozp?.split(':')
                    ? fieldState.value?.rozp?.split(':')
                    : [];
                const zak = fieldState.value?.zak?.split(':')
                    ? fieldState.value?.zak?.split(':')
                    : [];
                return {
                    [keyOfField]: date,
                    [`${keyOfField}_rozpMinute`]: rozp[1]?.length === 1 ? '0' + rozp[1] : rozp[1],
                    [`${keyOfField}_rozpHour`]: rozp[0]?.length === 1 ? '0' + rozp[0] : rozp[0],
                    [`${keyOfField}_zakMinute`]: zak[1]?.length === 1 ? '0' + zak[1] : zak[1],
                    [`${keyOfField}_zakHour`]: zak[0]?.length === 1 ? '0' + zak[0] : zak[0]
                };
            }
            case 'adres':
                return {
                    [keyOfField]: fieldState.value.addressId,
                    [`streetType${keyOfField}`]: fieldState.value.typulicy,
                    [`street${keyOfField}`]: fieldState.value.ulica,
                    [`houseNumber${keyOfField}`]: fieldState.value.nrdomu,
                    [`apartmentNumber${keyOfField}`]: fieldState.value.nrmieszkania,
                    [`city${keyOfField}`]: fieldState.value.miasto,
                    [`code${keyOfField}`]: fieldState.value.kod,
                    [`post${keyOfField}`]: fieldState.value.poczta,
                    [`gmina${keyOfField}`]: fieldState.value.gmina,
                    [`powiat${keyOfField}`]: fieldState.value.powiat,
                    [`voivodship${keyOfField}`]: fieldState.value.wojewodztwo,
                    [`country${keyOfField}`]: fieldState.value.kraj
                };
            case 'polozenie':
                return {
                    [`${keyOfField}_szerokosc`]: fieldState.value.latitude,
                    [`${keyOfField}_dlugosc`]: fieldState.value.longitude
                };
            case 'liczba_rozliczana_do_zera':
                return {
                    [keyOfField]: fieldState.value.baseValue,
                    [`${keyOfField}_rozliczono`]: fieldState.value.settled,
                    [`${keyOfField}_pozostalo`]: fieldState.value.restToSettle
                };
            case 'tekst_z_przedrostkiem':
                return {
                    [keyOfField]: fieldState.value.text,
                    [`${keyOfField}_prefix`]: fieldState.value.prefix
                };
            default:
                return { [keyOfField]: fieldState?.value };
        }
    }
    private clearZeroProperties(obj: { [key: string]: unknown }) {
        return Object.fromEntries(Object.entries(obj).filter(([_, data]) => data !== '0'));
    }
}
