import { DynamicFieldT } from 'src/data/popupFormTypes';

export const massEditObjectsColumnFieldInit: DynamicFieldT[] = [
    {
        code: 'lp',
        nazwa: 'Lp',
        kod: 'lp',
        id: 'x',
        typ: 'tekst',
        typ_lista: 'lp',
        ukryte: '0',
        domyslna_wartosc: '',
        id_sekcji: '0',
        id_zakladki: '0',
        modyfikacja: 0,
        porzadek_elementow: '0'
    }
];
