import { EventBus } from 'src/utils/src/shared/EventBus';
import {
    PopupPayloadsBasic,
    PopupPayloadsListScreenBasic,
    PopupPayloads,
    PopupUpdatePayloads,
    PopupInfoTwoPayloads,
    PopupWarningPayloads,
    PopupsState,
    PopupCallPayloads,
    PopupStateNormal,
    PopupStateBase,
    PopupPromptPayloads,
    PopupConfirmationPayloads
} from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initPopupItem: PopupStateBase = {
    key: '',
    zIndex: 0,
    isPossibleClose: true,
    title: '',
    typeWew: 'default',
    action: null,
    objectId: [],
    message: '',
    messageInjectHtml: false,
    infoList: [],
    popupSuperiorKey: '',
    sourceOfTrigger: {
        type: 'unknown'
    },
    objectAdditional: {
        sectionId: '',
        sectionIdx: 0,
        typeSection: 'none'
    },
    settingFromChildPopup: {},
    popupFormExtendedData: null,
    callPopup: false
};

export const initialState: PopupsState = {
    popupItemState: {}
};

const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        setPopupWithoutData: (state: PopupsState, action: PayloadAction<PopupPayloadsBasic>) => {
            const items = Object.keys(state.popupItemState);
            const zIndex = items.length ? Number(items.pop()) + 1 : 1000;
            const popupItem: PopupStateNormal = {
                ...initPopupItem,
                type: action.payload.type,
                title: action.payload.title ?? initPopupItem.title,
                zIndex,
                key: String(zIndex)
            };
            state.popupItemState[String(zIndex)] = popupItem;
        },

        setPopupListScreenBasic: (
            state: PopupsState,
            action: PayloadAction<PopupPayloadsListScreenBasic>
        ) => {
            const items = Object.keys(state.popupItemState);
            const zIndex = items.length ? Number(items.pop()) + 1 : 1000;

            const popupItem = {
                ...initPopupItem,
                type: action.payload.type,
                action: action.payload.action ?? initPopupItem.action,
                objectId: action.payload.objectId ?? initPopupItem.objectId,
                message: action.payload.message ?? initPopupItem.message,
                zIndex,
                key: String(zIndex)
            };
            state.popupItemState[String(zIndex)] = popupItem;
        },
        updatePopup: (state: PopupsState, action: PayloadAction<PopupUpdatePayloads>) => {
            const statePopup = state.popupItemState[action.payload.key];
            const popupItem = {
                ...statePopup,
                settingFromChildPopup: {
                    ...statePopup.settingFromChildPopup
                }
            };
            state.popupItemState[String(action.payload.key)] = popupItem;
        },

        setPopupInfoTwo: (state: PopupsState, action: PayloadAction<PopupInfoTwoPayloads>) => {
            const items = Object.keys(state.popupItemState);
            const zIndex = items.length ? Number(items.pop()) + 1 : 1000;
            const popupItem = {
                ...initPopupItem,
                zIndex,
                key: String(zIndex),
                type: 'infoTwo' as const,
                title: action.payload.title,
                message: action.payload.message,
                infoList: action.payload.infoList ?? initPopupItem.infoList,
                messageInjectHtml:
                    action.payload.messageInjectHtml ?? initPopupItem.messageInjectHtml,
                popupSuperiorKey: action.payload.popupSuperiorKey ?? initPopupItem.popupSuperiorKey,
                closeCallbackEventId: action.payload.closeCallbackEventId
            };
            state.popupItemState[String(zIndex)] = popupItem;
        },

        setPopupWarning: (state: PopupsState, action: PayloadAction<PopupWarningPayloads>) => {
            const items = Object.keys(state.popupItemState);
            const zIndex = items.length ? Number(items.pop()) + 1 : 1000;
            const popupItem = {
                ...initPopupItem,
                zIndex,
                key: String(zIndex),
                type: 'warning' as const,
                title: action.payload.title,
                message: action.payload.message,
                popupSuperiorKey: action.payload.popupSuperiorKey ?? initPopupItem.popupSuperiorKey,
                messageInjectHtml:
                    action.payload.messageInjectHtml ?? initPopupItem.messageInjectHtml,
                sourceOfTrigger: action.payload.sourceOfTrigger ?? initPopupItem.sourceOfTrigger
            };
            state.popupItemState[String(zIndex)] = popupItem;
        },

        setPopupPrompt: (state: PopupsState, action: PayloadAction<PopupPromptPayloads>) => {
            const items = Object.keys(state.popupItemState);
            const zIndex = items.length ? Number(items.pop()) + 1 : 1000;
            const popupItem = {
                ...initPopupItem,
                ...action.payload,
                zIndex,
                key: String(zIndex),
                type: 'prompt' as const
            };
            state.popupItemState[String(zIndex)] = popupItem;
        },

        setPopupConfirmation: (
            state: PopupsState,
            action: PayloadAction<PopupConfirmationPayloads>
        ) => {
            const items = Object.keys(state.popupItemState);
            const zIndex = items.length ? Number(items.pop()) + 1 : 1000;
            const popupItem = {
                ...initPopupItem,
                ...action.payload,
                zIndex,
                key: String(zIndex),
                type: 'confirmation' as const
            };
            state.popupItemState[String(zIndex)] = popupItem;
        },

        setPopup: (state: PopupsState, action: PayloadAction<PopupPayloads>) => {
            const items = Object.keys(state.popupItemState);
            const zIndex = items.length ? Number(items.pop()) + 1 : 1000;
            const {
                isPossibleClose,
                popupSuperiorKey,
                sourceOfTrigger,
                objectAdditional,
                messageInjectHtml,
                closeCallbackEventId,
                submitCallbackEventId
            } = action.payload;
            const popupItem: PopupStateNormal = {
                ...initPopupItem,
                type: action.payload.type,
                title: action.payload.title ?? initPopupItem.title,
                typeWew: action.payload.typeWew ?? initPopupItem.typeWew,

                // insead blocking false line: isPossibleClose: isPossibleClose ? isPossibleClose : true,
                isPossibleClose: isPossibleClose === undefined || !!isPossibleClose,

                action: action.payload.action ?? initPopupItem.action,
                objectId: action.payload.objectId ?? initPopupItem.objectId,
                message: action.payload.message ?? initPopupItem.message,
                messageInjectHtml: messageInjectHtml ?? initPopupItem.messageInjectHtml,
                infoList: action.payload.infoList ?? initPopupItem.infoList,
                popupFormExtendedData:
                    action.payload.popupFormExtendedData ?? initPopupItem.popupFormExtendedData,
                popupFormData: action.payload.popupFormData ?? initPopupItem.popupFormData,
                alertData: action.payload.alertData,
                zIndex,
                key: String(zIndex),
                popupSuperiorKey: popupSuperiorKey ?? initPopupItem.popupSuperiorKey,
                sourceOfTrigger: sourceOfTrigger ?? initPopupItem.sourceOfTrigger,
                closeCallbackEventId: closeCallbackEventId,
                submitCallbackEventId: submitCallbackEventId,
                objectAdditional: objectAdditional ?? initPopupItem.objectAdditional
            };
            state.popupItemState[String(zIndex)] = popupItem;
        },

        closePopup: (state: PopupsState, action: PayloadAction<string>) => {
            const popupToClose = state.popupItemState[action.payload];
            if (popupToClose && popupToClose.closeCallbackEventId) {
                EventBus.$emit(popupToClose.closeCallbackEventId);
            }

            state.popupItemState = Object.fromEntries(
                Object.entries(state.popupItemState).filter(([key]) => key !== action.payload)
            );
        },
        closePopupAll: (state: PopupsState) => {
            state.popupItemState = {};

            // emit close callbacks about closed popups
            Object.values(state.popupItemState).forEach((popupToClose) => {
                if (popupToClose.closeCallbackEventId)
                    EventBus.$emit(popupToClose.closeCallbackEventId);
            });
        },
        callPopup: (state: PopupsState, action: PayloadAction<PopupCallPayloads>) => {
            const popupState = state.popupItemState[action.payload.key];
            state.popupItemState[String(action.payload.key)] = {
                ...popupState,
                callPopup: action.payload.call
            };
        }
    }
});

export const {
    closePopup,
    callPopup,
    setPopupWithoutData,
    setPopupListScreenBasic,
    setPopup,
    setPopupInfoTwo,
    setPopupWarning,
    setPopupPrompt,
    setPopupConfirmation,
    closePopupAll,
    updatePopup
} = popupSlice.actions;

export default popupSlice.reducer;
