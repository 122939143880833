import { NAVIGATION_START_POINT_SET_KEY } from 'src/constants';

const prependStartScreenOnHistoryStack = () => {
    const state = { ...window.history.state };
    const url = window.location.href;
    window.history.replaceState({ mecenashistoryStartPoint: true }, '', `${window.basename}/`);
    window.history.pushState({ ...state }, '', url);
};

const isActualOnStartScreen = () => {
    return window.location.pathname === '/start';
};

export const handleSettingNavStartPoint = (isLogged: boolean) => {
    if (!window.basename) {
        window.basename = dangerouslyLoadBasename();
    }
    if (!sessionStorage.getItem(NAVIGATION_START_POINT_SET_KEY) && isLogged) {
        if (isActualOnStartScreen()) {
            console.debug(
                'handleSettingNavStartPoint: actual on start screen',
                window.location.pathname,
                window.basename
            );
            window.history.replaceState(
                { ...window.history.state, mecenashistoryStartPoint: true },
                `${window.basename}/start`
            );
        } else {
            console.debug('handleSettingNavStartPoint: prependStartScreenOnHistoryStack');
            prependStartScreenOnHistoryStack();
        }
        console.debug('handleSettingNavStartPoint: set key true');
        sessionStorage.setItem(NAVIGATION_START_POINT_SET_KEY, 'true');
        // for case on opening new card in which is copied sessionStorage with NAVIGATION_START_POINT_SET_KEY=true
    } else if (window.history.length <= 1) {
        console.debug('handleSettingNavStartPoint: prependStartScreenOnHistoryStack');
        prependStartScreenOnHistoryStack();
    }
};

const dangerouslyLoadBasename = () => {
    // slice 0, -1 becouse we assusems that all links are one dimmentional
    return window.location.pathname.split('/').slice(0, -1).join('/');
};
