import { raportColumn } from './../../../constants/raportScreen/raportColumn';
import React from 'react';

import { fetchRaportInitData } from 'src/api/src/raportScreen/fetchRaportScreenInitData';
import { DataRaportRow } from 'src/api/src/raportScreen/types';
import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';

export type DataRaportState = {
    rows: DataRaportRow[];
};

export const useRaportDefaultData = () => {
    const id = useSearchParam('obiekt');

    const [dataRaportState, setDataRaportState] = React.useState<DataRaportState>({
        rows: []
    });
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            if (id) {
                const data = await fetchRaportInitData(id);
                if (data.success) {
                    setDataRaportState({
                        rows: data.data
                    });
                }
            }
            setIsLoading(false);
        })();
    }, []);

    return {
        dataRaportState,
        setDataRaportState,
        isLoading,
        columns: raportColumn
    };
};
