import { Button } from '@mui/material';
import React, { FC } from 'react';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

type Props = {
    onClick: (e: any) => void;
    sx?: SxProps<Theme>;
    preventMultipleClick?: boolean;
    withoutStyles?: boolean;
};

const ButtonCustom: FC<Props> = ({
    children,
    onClick,
    sx,
    preventMultipleClick = false,
    withoutStyles = false
}) => {
    const [clicked, setClicked] = React.useState(false);

    const handleOnClick = (e: any) => {
        if (clicked && preventMultipleClick) return;
        if (preventMultipleClick) setClicked(true);
        onClick(e);
    };
    if (withoutStyles) {
        return <div onClick={handleOnClick}>{children}</div>;
    }
    return (
        <Button
            variant="contained"
            sx={{
                color: (theme) => theme.palette.background.default,
                padding: '6px 50px',
                cursor: 'pointer',
                ...sx
            }}
            onClick={onClick}>
            {children}
        </Button>
    );
};

export default ButtonCustom;
