import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldNumberNormalProps } from 'src/components/popupFormExtended/dynamicField/fieldNumber/types';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { prepareDefaultValueForFieldNumber } from 'src/utils/src/shared/prepareDefaultValueForFieldNumber';
import { UiFieldNumberNormal } from 'src/components/popupFormExtended/dynamicField/fieldNumber/UIFieldNumberNormal';
import { prepareValueToSetForFieldNumberNormal } from 'src/utils/src/shared/prepareValueToSetForFieldNumberNormal';

export const FieldNumberNormal: FC<FieldNumberNormalProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const [state, setState] = React.useState(prepareDefaultValueForFieldNumber(field.value));

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        const { valid, valuePrepared } = prepareValueToSetForFieldNumberNormal({
            newValue: e.target.value,
            oldValue: state,
            dynamicFieldT
        });

        if (!valid) return;
        setState(valuePrepared);

        if (onBlur) {
            const newValue = {
                ...field,
                ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
                value: valuePrepared
            };
            dispatch({
                type: 'liczba',
                payload: {
                    code: keyOfFieldInState,
                    value: newValue
                }
            });
            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: newValue,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        }
    };

    React.useEffect(() => {
        if (JSON.stringify(field.value) !== JSON.stringify(state)) {
            setState(field.value ? field.value : '0');
        }
    }, [field.value]);

    return (
        <UiFieldNumberNormal
            value={state}
            percent={dynamicFieldT.procent === 't'}
            onChange={(e) => onChange(e, false)}
            onBlur={(e) => onChange(e, true)}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
