import { SearchField } from 'src/store/src/listScreen/searchFields/types';
import { ListSearchFilters } from 'src/store/src/listScreen/transformations/types';

export class FilterPopupManager {
    listSearchFiltersIN: ListSearchFilters;
    listSearchFiltersOUT: ListSearchFilters;
    objectsPerPage: number;
    searchFields: SearchField[];
    constructor({
        objectsPerPage,
        listSearchFiltersIN,
        searchFields
    }: {
        objectsPerPage: number;
        listSearchFiltersIN: ListSearchFilters;
        searchFields: SearchField[];
    }) {
        this.listSearchFiltersIN = listSearchFiltersIN;
        this.listSearchFiltersOUT = {};
        this.objectsPerPage = objectsPerPage;
        this.searchFields = searchFields;
    }

    setInitState() {
        this.prepareDataBaseForSearchFields();
        this.addSetFilters();
        return this.listSearchFiltersOUT;
    }

    private prepareDataBaseForSearchFields() {
        this.searchFields.forEach((searchField) => this.prepareDataBaseForSearchField(searchField));
    }

    private prepareDataBaseForSearchField(searchField: SearchField) {
        switch (searchField.searchType) {
            case 'lista_rozwijalna':
                this.listSearchFiltersOUT[searchField.searchCode] = {
                    fieldFilter: {
                        value: searchField.defaultValue ? searchField.defaultValue : null,
                        orMode: false
                    }
                };
                break;
            case 'lista': {
                this.listSearchFiltersOUT[searchField.searchCode] = {
                    fieldFilter: {
                        value:
                            typeof searchField.defaultValue === 'number'
                                ? searchField.defaultValue
                                : null,
                        orMode: false
                    }
                };
                break;
            }
            case 'lista_wielokrotna':
                {
                    let value = [];
                    if (Array.isArray(searchField.defaultValue)) value = searchField.defaultValue;
                    this.listSearchFiltersOUT[searchField.searchCode] = {
                        fieldFilter: {
                            value: value,
                            orMode: false
                        }
                    };
                }
                break;
            case 'liczba':
                {
                    let value = [null, null];
                    if (Array.isArray(searchField.defaultValue)) value = searchField.defaultValue;
                    this.listSearchFiltersOUT[searchField.searchCode] = {
                        fieldFilter: {
                            value: value,
                            orMode: false
                        }
                    };
                }
                break;
            case 'data_pojedyncza':
                this.listSearchFiltersOUT[searchField.searchCode] = {
                    fieldFilter: {
                        value: searchField.defaultValue ? searchField.defaultValue : null,
                        orMode: false
                    }
                };
                break;
            case 'data':
                {
                    let value = { preset: 0, dates: [null, null] };
                    if (
                        searchField.defaultValue &&
                        typeof searchField.defaultValue === 'object' &&
                        'dates' in searchField.defaultValue &&
                        'preset' in searchField.defaultValue &&
                        Array.isArray(searchField.defaultValue.dates)
                    )
                        value = searchField.defaultValue;
                    this.listSearchFiltersOUT[searchField.searchCode] = {
                        fieldFilter: {
                            value: value,
                            orMode: false
                        }
                    };
                }
                break;
            case 'data_wzgledna':
            case 'plik':
            case 'tekst':
            case 'liczba_rozliczana_do_zera':
                this.listSearchFiltersOUT[searchField.searchCode] = {
                    fieldFilter: {
                        value: searchField.defaultValue ? searchField.defaultValue : null,
                        orMode: false
                    }
                };
                break;

            case 'lista_ilosc_na_stronie':
                this.listSearchFiltersOUT[searchField.searchCode] = {
                    fieldFilter: {
                        value: this.objectsPerPage,
                        orMode: false
                    }
                };
                break;
            default:
                this.listSearchFiltersOUT[searchField.searchCode] = {
                    fieldFilter: {
                        value: searchField.defaultValue ? searchField.defaultValue : null,
                        orMode: false
                    }
                };
                break;
        }
    }
    private addSetFilters() {
        const allFilters = {
            ...this.listSearchFiltersOUT,
            ...this.listSearchFiltersIN
        };
        this.listSearchFiltersOUT = allFilters;
    }

    getDataToAPi() {
        for (const property in this.listSearchFiltersIN) {
            const searchField = this.searchFields.find((item) => item.searchCode === property);
            if (searchField) {
                const isSet = this.prepareItemFilter(
                    searchField,
                    this.listSearchFiltersIN[property].fieldFilter
                );
                if (isSet) {
                    this.listSearchFiltersOUT[property] = this.listSearchFiltersIN[property];
                }
            }
        }
        return this.listSearchFiltersOUT;
    }

    private prepareItemFilter(
        searchField: SearchField,
        fieldFilter?: {
            orMode: boolean;
            value: any;
        }
    ) {
        switch (searchField.searchType) {
            case 'lista_rozwijalna':
            case 'liczba_rozliczana_do_zera':
            case 'data_wzgledna':
            case 'lista':
                if (fieldFilter?.orMode || fieldFilter?.value) return true;
                return false;
            case 'lista_wielokrotna':
                if (fieldFilter?.orMode || fieldFilter?.value?.length > 0) return true;
                return false;
            case 'liczba':
            case 'data':
                if (fieldFilter?.value?.preset !== 0) return true;
                if (
                    fieldFilter?.orMode ||
                    (Array.isArray(fieldFilter?.value?.dates) &&
                        !fieldFilter?.value?.dates.every((item: any) => item === null))
                )
                    return true;
                return false;
            case 'data_pojedyncza':
                if (fieldFilter?.orMode || fieldFilter?.value) return true;
                return false;
            case 'plik':
            case 'tekst':
                if (fieldFilter?.orMode || fieldFilter?.value) return true;
                return false;
            case 'lista_ilosc_na_stronie':
                return false;
            default:
                return false;
        }
    }
}
