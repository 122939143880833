import { SystemAction } from 'src/data/types';
import { ListCellRow } from 'src/store/src/object/object/types';

export function checkIsHiddenActionInCardListCell({
    action,
    cellRow
}: {
    action: SystemAction;
    cellRow: ListCellRow;
}): boolean {
    if (action.displayConditions) {
        const cell = cellRow.cellValues[action.displayConditions.fieldCode];
        if (Array.isArray(cell)) {
            const rawValue: undefined = cell?.[0]?.['rawValue'];
            if (typeof rawValue === 'undefined') return false;
            if (action.displayConditions.reverse) {
                if (action.displayConditions.fieldValues.includes(rawValue)) {
                    return true;
                }
            } else {
                if (!action.displayConditions.fieldValues.includes(rawValue)) {
                    return true;
                }
            }
        }
    }
    return false;
}
