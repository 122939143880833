import React, { FC } from 'react';

import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import styles from './PopupContentDynamicExtended.module.css';
import { ContentWrapper } from 'src/components/popupFormExtended/contentWrapper/ContentWrapper';
import { TabsWrapper } from 'src/components/popupFormExtended/tabsWrapper/TabsWrapper';

export const PopupContentDynamicExtended: FC<BasePopupContentProps> = (props) => {
    return (
        <div className={styles.wrapper}>
            <TabsWrapper {...props} />
            <ContentWrapper {...props} />
        </div>
    );
};
