import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiScreenStructure } from './types';

export async function fetchScreenStructure(
    screen: string
): Promise<BaseApiResponse<ApiScreenStructure>> {
    return simpleQueryAPIData(`api_react/src/listScreen/getScreenStructure.php?screen=${screen}`);

    // temporary use mock fixed value
    // return {
    //     message: 'Zdobyto dane',
    //     success: true,
    //     errorCode: null,
    //     data: {
    //         searchFields: [
    //             {
    //                 fieldId: 117,
    //                 searchCode: 'plik',
    //                 name: 'plik',
    //                 searchType: 'plik',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: 'sssss',

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 1,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 1174,
    //                 searchCode: 'plikx',
    //                 name: 'pliks',
    //                 searchType: 'data_pojedyncza',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 117,
    //                 searchCode: 'plik',
    //                 name: 'plik',
    //                 searchType: 'plik',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 masterFieldCode: null,
    //                 fieldId: 499,
    //                 name: 'Wykonujący',
    //                 searchType: 'lista_wielokrotna',
    //                 defaultValue: '',
    //                 orMode: false,
    //                 searchCode: 'pracownik',
    //                 autocompleteSearchMode: null,
    //                 defaultOrValue: false,
    //                 searchOptions: [
    //                     {
    //                         name: '',
    //                         id: 0
    //                     },
    //                     {
    //                         name: 'administrator2 JMK',
    //                         id: 42
    //                     },
    //                     {
    //                         name: 'Dąbek Anna',
    //                         id: 37
    //                     },
    //                     {
    //                         name: 'Gudz Paweł',
    //                         id: 38
    //                     },
    //                     {
    //                         name: 'JMK Administrator',
    //                         id: 3
    //                     },
    //                     {
    //                         name: 'Krajeńska Mal2wina',
    //                         id: 391
    //                     },
    //                     {
    //                         name: 'Krajeńska Ma1lwina',
    //                         id: 392
    //                     },
    //                     {
    //                         name: 'Krajeńska Ma3lwina',
    //                         id: 393
    //                     },
    //                     {
    //                         name: 'Krajeńska Malwi23na',
    //                         id: 394
    //                     },
    //                     {
    //                         name: 'Krajeńska Malwin23a',
    //                         id: 395
    //                     },
    //                     {
    //                         name: 'Krajeńska Malwina1',
    //                         id: 396
    //                     },
    //                     {
    //                         name: 'Miś Andrzej',
    //                         id: 40
    //                     },
    //                     {
    //                         name: 'Miś Tomasz',
    //                         id: 140
    //                     },
    //                     {
    //                         name: 'Miś Miłosz',
    //                         id: 1401
    //                     },
    //                     {
    //                         name: 'Miś Adam',
    //                         id: 1402
    //                     },
    //                     {
    //                         name: 'Miś Ada',
    //                         id: 1403
    //                     },
    //                     {
    //                         name: 'Miś Joanna',
    //                         id: 1405
    //                     },
    //                     {
    //                         name: 'test  user 1',
    //                         id: 43
    //                     },
    //                     {
    //                         name: 'test user 2',
    //                         id: 44
    //                     }
    //                 ],

    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 110,
    //                 searchCode: 'data',
    //                 name: 'data',
    //                 searchType: 'data',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 11,
    //                 searchCode: 'nazwa',
    //                 name: 'nazwa',
    //                 searchType: 'tekst',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 1174,
    //                 searchCode: 'plikx',
    //                 name: 'pliks',
    //                 searchType: 'tekst',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 1174,
    //                 searchCode: 'plikx',
    //                 name: 'pliks',
    //                 searchType: 'tekst',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 1217,
    //                 searchCode: 'data_wzgledna',
    //                 name: 'data_wzgledna',
    //                 searchType: 'data_wzgledna',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: true,
    //                 defaultOrValue: true,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 1215,
    //                 searchCode: 'liczba',
    //                 name: 'liczba',
    //                 searchType: 'liczba',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 1213,
    //                 searchCode: 'liczba_rozliczana_do_zera',
    //                 name: 'liczba_rozliczana_do_zera',
    //                 searchType: 'liczba_rozliczana_do_zera',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 1,
    //                 isRelationForeignField: false
    //             },

    //             {
    //                 fieldId: 121,
    //                 searchCode: 'rodzaj1',
    //                 name: 'rodzaj1',
    //                 searchType: 'lista',
    //                 searchOptions: [
    //                     {
    //                         id: 22,
    //                         name: 'Pizza'
    //                     },
    //                     {
    //                         id: 23,
    //                         name: 'Kebab'
    //                     },
    //                     {
    //                         id: 24,
    //                         name: 'Pizza7'
    //                     },
    //                     {
    //                         id: 25,
    //                         name: 'Kebab6'
    //                     }
    //                 ],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 122,
    //                 searchCode: 'jedzenie',
    //                 name: 'Lista jedzenie',
    //                 searchType: 'lista_rozwijalna',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: 'somefile',
    //                 defaultValue: null,
    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null,
    //                 width: 0.5,
    //                 isRelationForeignField: false,
    //                 additionalFieldForSearchCode: 'rodzaj1',
    //                 additionalFieldForSearchType: 'lista_obca'
    //             },
    //             // example of master related field
    //             {
    //                 fieldId: 124,
    //                 searchCode: 'rodzaj2',
    //                 name: 'Rodzaj 2',
    //                 searchType: 'lista',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: 'somefile',
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: 'rodzaj1',
    //                 width: 0.5,
    //                 masterRelatedOptions: {
    //                     22: [
    //                         {
    //                             id: 1,
    //                             name: 'Na cienkim'
    //                         },
    //                         {
    //                             id: 3,
    //                             name: 'Na grubym'
    //                         }
    //                     ],
    //                     23: [
    //                         {
    //                             id: 2,
    //                             name: 'Pita'
    //                         },
    //                         {
    //                             id: 4,
    //                             name: 'Bułka'
    //                         }
    //                     ]
    //                 },
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: -102,
    //                 searchCode: 'pokaz_archiwizacje',
    //                 name: 'Pokaż',
    //                 searchType: 'lista',
    //                 isRelationForeignField: false,
    //                 searchOptions: [
    //                     {
    //                         name: 'Ukryj zarchiwizowane',
    //                         id: 2
    //                     },
    //                     {
    //                         name: 'Pokaż wszystkie',
    //                         id: 1
    //                     },
    //                     {
    //                         name: 'Pokaż tylko zarchiwizowane',
    //                         id: -1
    //                     }
    //                 ],
    //                 autocompleteSearchMode: null,
    //                 defaultValue: 1,
    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 width: 0.3,
    //                 masterFieldCode: null,
    //                 masterRelatedOptions: null
    //             },
    //             {
    //                 fieldId: 128,
    //                 searchCode: 'rodzaj3',
    //                 name: 'Rodzaj 3',
    //                 searchType: 'lista',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: 'somefile',
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: 'rodzaj2',
    //                 width: 0.5,
    //                 masterRelatedOptions: {
    //                     1: [
    //                         {
    //                             id: 1,
    //                             name: 'Na cienkim1'
    //                         },
    //                         {
    //                             id: 3,
    //                             name: 'Na grubym2'
    //                         }
    //                     ],
    //                     2: [
    //                         {
    //                             id: 2,
    //                             name: 'Pita3'
    //                         },
    //                         {
    //                             id: 4,
    //                             name: 'Bułka4'
    //                         }
    //                     ]
    //                 },
    //                 isRelationForeignField: false
    //             },
    //             {
    //                 fieldId: 238,
    //                 searchCode: 'rodzaj4',
    //                 name: 'Rodzaj3 chain',
    //                 searchType: 'lista_wielokrotna',
    //                 searchOptions: [],
    //                 autocompleteSearchMode: 'somefile',
    //                 defaultValue: null,

    //                 orMode: false,
    //                 defaultOrValue: false,
    //                 masterFieldCode: null,
    //                 width: 0.5,
    //                 masterRelatedOptions: {
    //                     1: [
    //                         {
    //                             id: 1,
    //                             name: 'checkbox1'
    //                         },
    //                         {
    //                             id: 3,
    //                             name: 'checkbox2'
    //                         },
    //                         {
    //                             id: 2,
    //                             name: 'checkbox1'
    //                         },
    //                         {
    //                             id: 3,
    //                             name: 'checkbox2'
    //                         },
    //                         {
    //                             id: 4,
    //                             name: 'checkbox1'
    //                         },
    //                         {
    //                             id: 5,
    //                             name: 'checkbox2'
    //                         },
    //                         {
    //                             id: 1,
    //                             name: 'checkbox1'
    //                         },
    //                         {
    //                             id: 3,
    //                             name: 'checkbox2'
    //                         }
    //                     ],
    //                     2: [
    //                         {
    //                             id: 2,
    //                             name: 'checkbox4'
    //                         },
    //                         {
    //                             id: 4,
    //                             name: 'checkbox5'
    //                         }
    //                     ],
    //                     3: [
    //                         {
    //                             id: 5,
    //                             name: 'checkbox1'
    //                         },
    //                         {
    //                             id: 6,
    //                             name: 'checkbox2'
    //                         }
    //                     ],
    //                     4: [
    //                         {
    //                             id: 7,
    //                             name: 'checkbox4'
    //                         },
    //                         {
    //                             id: 9,
    //                             name: 'checkbox5'
    //                         }
    //                     ]
    //                 },
    //                 isRelationForeignField: false
    //             }
    //         ],
    //         columns: [
    //             {
    //                 code: 'lp',
    //                 name: 'Lp.',
    //                 type: 'lp',
    //                 id: -1,
    //                 fieldType: 'lista_obca_wielokrotna',
    //                 isPinned: true,
    //                 isVisible: true,
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 1,
    //                 actionBlock: 'Razem',
    //                 cellActions: [
    //                     {
    //                         name: 'Zrealizuj zadanie',
    //                         code: 'completeTaskCheckbox',
    //                         icon: {
    //                             svgFileName: null,
    //                             faCode: 'fa-check-square-o'
    //                         },
    //                         colorConditions: [
    //                             {
    //                                 color: 'success',
    //                                 fieldCode: 'status',
    //                                 fieldValues: ['Zrealizowany']
    //                             }
    //                         ],
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'popupForm',
    //                             data: {
    //                                 url: 'updateObject.php?screen=kod_ekranu&objectId={@objectId}',
    //                                 separateForEachSelected: false,
    //                                 title: 'Zrealizuj zadanie',
    //                                 fields: [
    //                                     {
    //                                         nazwa: 'data',
    //                                         kod: 'datarealizacji',
    //                                         typ: 'data_godzina',
    //                                         hidden: false,
    //                                         value: null,
    //                                         size: null,
    //                                         width: 1
    //                                     },
    //                                     {
    //                                         nazwa: 'Poświęcony czas (0,000 h)',
    //                                         kod: 'poswiecanyczas',
    //                                         typ: 'liczba',
    //                                         hidden: false,
    //                                         value: 0,
    //                                         size: null,
    //                                         width: 1,
    //                                         przeliczanie_minut_i_godzin: true
    //                                     },
    //                                     {
    //                                         nazwa: 'Poświęcony czas (0,000 h)',
    //                                         kod: 'test_emptyval',
    //                                         typ: 'data_godziny',
    //                                         hidden: false,
    //                                         value: null,
    //                                         size: null,
    //                                         width: 1
    //                                     },
    //                                     {
    //                                         nazwa: 'Poświęcony czas (0,000 h)',
    //                                         kod: 'test_string',
    //                                         typ: 'data_godziny',
    //                                         hidden: false,
    //                                         value: null,
    //                                         size: null,
    //                                         width: 1
    //                                     },
    //                                     {
    //                                         nazwa: 'Poświęcony czas (0,000 h)',
    //                                         kod: 'test',
    //                                         typ: 'data_godziny',
    //                                         hidden: false,
    //                                         value: {
    //                                             data: '01-01-2023',
    //                                             rozp: '12:12',
    //                                             zak: '13:15'
    //                                         },
    //                                         size: null,
    //                                         width: 1
    //                                     },
    //                                     {
    //                                         nazwa: 'Zrealizowano',
    //                                         kod: 'status',
    //                                         typ: 'checkbox',
    //                                         hidden: true,
    //                                         value: null,
    //                                         size: null,
    //                                         width: 1
    //                                     },
    //                                     {
    //                                         nazwa: 'Nazwa zadania',
    //                                         kod: 'default',
    //                                         typ: 'checkbox',
    //                                         hidden: true,
    //                                         value: '{!nazwa}',
    //                                         size: null,
    //                                         width: 1,
    //                                         isOnlyToDisplay: true
    //                                     },
    //                                     {
    //                                         nazwa: 'Status',
    //                                         kod: 'default',
    //                                         typ: 'checkbox',
    //                                         hidden: true,
    //                                         value: '{!status}',
    //                                         size: null,
    //                                         width: 1,
    //                                         isOnlyToDisplay: true
    //                                     }
    //                                 ]
    //                             }
    //                         }
    //                     },
    //                     {
    //                         name: 'Anuluj',
    //                         code: 'cancelTaskCheckbox',
    //                         icon: {
    //                             svgFileName: null,
    //                             faCode: 'fa-window-close-o'
    //                         },
    //                         defaultColor: null,
    //                         colorConditions: [
    //                             {
    //                                 color: '#ff3333',
    //                                 fieldCode: 'status',
    //                                 fieldValues: ['Anulowany', 'Zrealizowany']
    //                             },
    //                             {
    //                                 color: '#33ff33',
    //                                 fieldCode: 'status',
    //                                 fieldValues: ['Wprowadzony']
    //                             }
    //                         ],

    //                         displayConditions: {
    //                             fieldCode: 'status',
    //                             fieldValues: ['Wprowadzony', 'Zrealizowany']
    //                         },
    //                         behaviour: {
    //                             type: 'apiAction',
    //                             data: {
    //                                 url: 'updateObject.php',
    //                                 params: 'screen=zadania&objectId={@objectId}',
    //                                 body: '{status: 34}',
    //                                 confirmText: '',
    //                                 secondConfirmText: '',
    //                                 showResult: true,
    //                                 refresh: true
    //                             }
    //                         }
    //                     }
    //                 ],
    //                 footerActions: [
    //                     {
    //                         name: 'Zrealizuj zadanie',
    //                         code: 'completeTaskCheckbox',
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         icon: {
    //                             svgFileName: 'zadanie_zrealizowane2.svg',
    //                             faCode: ''
    //                         },
    //                         behaviour: {
    //                             type: 'popupForm',
    //                             data: {
    //                                 url: 'updateObject.php?screen=kod_ekranu&objectId={@objectId}',
    //                                 separateForEachSelected: true,
    //                                 title: 'Zrealizuj zadanie',
    //                                 fields: [
    //                                     {
    //                                         nazwa: 'Data',
    //                                         kod: 'datarealizacji',
    //                                         typ: 'data_godzina',
    //                                         hidden: false,
    //                                         value: null,
    //                                         size: null,
    //                                         width: 1
    //                                     },
    //                                     {
    //                                         nazwa: 'Poswiecony czas',
    //                                         kod: 'poswiecanyczas',
    //                                         typ: 'liczba',
    //                                         hidden: false,
    //                                         value: 0,
    //                                         size: null,
    //                                         przeliczanie_minut_i_godzin: true,
    //                                         width: 1
    //                                     },
    //                                     {
    //                                         nazwa: 'Status',
    //                                         kod: 'status',
    //                                         typ: 'checkbox',
    //                                         hidden: true,
    //                                         value: null,
    //                                         size: null,
    //                                         width: 1
    //                                     },
    //                                     {
    //                                         nazwa: 'Nazwa zadania',
    //                                         kod: 'default',
    //                                         typ: 'checkbox',
    //                                         hidden: true,
    //                                         value: '{!nazwa}',
    //                                         size: null,
    //                                         width: 1,
    //                                         isOnlyToDisplay: true
    //                                     },
    //                                     {
    //                                         nazwa: 'Status',
    //                                         kod: 'default',
    //                                         typ: 'checkbox',
    //                                         hidden: true,
    //                                         value: '{!status}',
    //                                         size: null,
    //                                         width: 1,
    //                                         isOnlyToDisplay: true
    //                                     }
    //                                 ]
    //                             }
    //                         }
    //                     },
    //                     {
    //                         name: 'Anuluj',
    //                         code: 'cancelTaskCheckbox',
    //                         icon: {
    //                             svgFileName: 'zadanie_anulowane2.svg',
    //                             faCode: ''
    //                         },
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'apiAction',
    //                             data: {
    //                                 url: 'updateObject.php',
    //                                 params: 'screen=zadania&objectId={@objectId[selected]}',
    //                                 body: '{status: 34}',
    //                                 confirmText: '',
    //                                 secondConfirmText: '',
    //                                 showResult: false,
    //                                 refresh: true
    //                             }
    //                         }
    //                     },
    //                     {
    //                         name: 'Usuń',
    //                         code: 'delete',
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'apiAction',
    //                             data: {
    //                                 url: 'deleteObject.php',
    //                                 params: 'screen={#obiekt}',
    //                                 body: '',
    //                                 confirmText: 'Czy na pewno chcesz usunąć ten obiekt?',
    //                                 secondConfirmText:
    //                                     'Potwierdź usunięcie obiektu. UWAGA TA OPERACJA JEST NIEODWRACALNA!',
    //                                 showResult: false,
    //                                 refresh: true
    //                             }
    //                         }
    //                     }
    //                 ]
    //             },
    //             {
    //                 code: 'nazwa',
    //                 name: 'Nazwa zadania',
    //                 type: 'text',
    //                 id: 23,
    //                 fieldType: 'text',
    //                 isPinned: false,
    //                 isVisible: true,
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 3,
    //                 actionBlock: null,
    //                 cellActions: [],
    //                 footerActions: []
    //             },
    //             {
    //                 code: 'term',
    //                 name: 'type',
    //                 type: 'text',
    //                 id: 24,
    //                 fieldType: 'lista',
    //                 isPinned: false,
    //                 isVisible: true,
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 2,
    //                 actionBlock: 'Spłać',
    //                 cellActions: [
    //                     {
    //                         name: 'Spłać',
    //                         code: 'pay',
    //                         icon: {
    //                             faCode: '',
    //                             svgFileName: 'ikona_splata.svg'
    //                         },
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'apiAction',
    //                             data: {
    //                                 url: 'payTheInvoice.php',
    //                                 params: 'invoiceId={@objectId}',
    //                                 body: null,
    //                                 confirmText: '',
    //                                 secondConfirmText: '',
    //                                 showResult: false,
    //                                 refresh: true
    //                             }
    //                         }
    //                     }
    //                 ],
    //                 footerActions: [
    //                     {
    //                         name: 'Spłać',
    //                         code: 'pay',
    //                         icon: {
    //                             faCode: '',
    //                             svgFileName: 'ikona_splata.svg'
    //                         },
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'apiAction',
    //                             data: {
    //                                 url: 'payTheInvoice.php',
    //                                 params: 'invoiceId={@objectId}',
    //                                 body: null,
    //                                 confirmText: '',
    //                                 secondConfirmText: '',
    //                                 showResult: false,
    //                                 refresh: true
    //                             }
    //                         }
    //                     }
    //                 ]
    //             },
    //             {
    //                 code: 'new',
    //                 name: 'nowa',
    //                 type: 'text',
    //                 id: 23,
    //                 fieldType: 'powiazanie_obce',
    //                 isPinned: true,
    //                 isVisible: true,
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 4,
    //                 actionBlock: null,
    //                 cellActions: [],
    //                 footerActions: []
    //             },
    //             {
    //                 code: 'file',
    //                 name: 'Pobierz',
    //                 type: 'plik',
    //                 id: 25,
    //                 fieldType: 'plik',
    //                 isPinned: false,
    //                 isVisible: true,
    //                 isSortable: false,
    //                 isFilterable: false,
    //                 order: 5,
    //                 actionBlock: 'Pobierz',
    //                 cellActions: [
    //                     {
    //                         name: 'Pobierz',
    //                         code: 'downloadFile',
    //                         icon: {
    //                             faCode: '',
    //                             svgFileName: 'pobierz_xls.svg'
    //                         },
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'fileDownload',
    //                             data: {
    //                                 url: 'pobierz_plik.php',
    //                                 params: '',
    //                                 payload: [{ key: 'id', value: '{$file}' }]
    //                             }
    //                         }
    //                     },
    //                     {
    //                         name: 'Pobierz z załącznikami',
    //                         code: 'downloadFile',
    //                         icon: {
    //                             faCode: '',
    //                             svgFileName: 'pobierz_xls.svg'
    //                         },
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'fileDownload',
    //                             data: {
    //                                 url: 'pobierz_plik.php',
    //                                 params: '',
    //                                 payload: [{ key: 'id', value: '{$file},{$attachment}' }]
    //                             }
    //                         }
    //                     }
    //                 ],
    //                 footerActions: [
    //                     {
    //                         name: 'Pobierz',
    //                         code: 'downloadFile',
    //                         icon: {
    //                             faCode: '',
    //                             svgFileName: 'pobierz_xls.svg'
    //                         },
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'fileDownload',
    //                             data: {
    //                                 url: 'pobierz_pliki_zip.php',
    //                                 params: '',
    //                                 payload: [{ key: 'id_plikow', value: '[{$file[selected]}]' }]
    //                             }
    //                         }
    //                     }
    //                 ]
    //             },
    //             {
    //                 code: 'send',
    //                 name: 'Wysłana',
    //                 type: 'text',
    //                 isPinned: false,
    //                 isVisible: true,
    //                 id: 26,
    //                 fieldType: 'koszty',
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 10,
    //                 actionBlock: 'Wyślij',
    //                 cellActions: [
    //                     {
    //                         name: 'Wyślij',
    //                         code: 'sendMail',
    //                         icon: {
    //                             faCode: '',
    //                             svgFileName: 'ikona_splata.svg'
    //                         },
    //                         colorConditions: [
    //                             {
    //                                 color: '#ff3333',
    //                                 fieldCode: 'status',
    //                                 fieldValues: ['Anulowany', 'Zrealizowany']
    //                             },
    //                             {
    //                                 color: '#fff345',
    //                                 fieldCode: 'status',
    //                                 fieldValues: ['Wprowadzony']
    //                             }
    //                         ],
    //                         displayConditions: null,
    //                         defaultColor: '#ffffff',
    //                         behaviour: {
    //                             type: 'popupForm',
    //                             data: {
    //                                 url: 'sendInvoiceMails.php?objectIds={@objectId}&template=typ_templatu_maila',
    //                                 separateForEachSelected: false,
    //                                 title: 'Wyślij',
    //                                 fields: [
    //                                     {
    //                                         nazwa: 'Załączniki',
    //                                         kod: 'attachment',
    //                                         typ: 'lista',
    //                                         hidden: false,
    //                                         value: 'invoice',
    //                                         size: null,
    //                                         width: 1,
    //                                         pole_widocznosc: '0',
    //                                         opcje_widocznosc: [],
    //                                         options: [
    //                                             {
    //                                                 name: 'Faktura',
    //                                                 value: 'invoice'
    //                                             },
    //                                             {
    //                                                 name: 'Faktura + raport',
    //                                                 value: 'invoiceAndReport'
    //                                             }
    //                                         ]
    //                                     },
    //                                     {
    //                                         nazwa: 'Załączniki',
    //                                         kod: 'attachment2',
    //                                         typ: 'lista',
    //                                         hidden: false,
    //                                         value: 'invoice',
    //                                         size: null,
    //                                         width: 1,
    //                                         pole_widocznosc: 'attachment',
    //                                         opcje_widocznosc: ['Faktura'],
    //                                         options: [
    //                                             {
    //                                                 name: 'Cos',
    //                                                 value: 'invoice'
    //                                             },
    //                                             {
    //                                                 name: 'Cos dwa',
    //                                                 value: 'invoiceAndReport'
    //                                             }
    //                                         ]
    //                                     }
    //                                 ]
    //                             }
    //                         }
    //                     }
    //                 ],
    //                 footerActions: []
    //             },
    //             {
    //                 code: 'rush',
    //                 name: 'Ponaglenie',
    //                 type: 'text',
    //                 id: 27,
    //                 fieldType: 'chekbox',
    //                 isPinned: false,
    //                 isVisible: true,
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 11,
    //                 actionBlock: 'Wyślij',
    //                 cellActions: [],
    //                 footerActions: []
    //             },
    //             {
    //                 code: 'paying',
    //                 name: 'Pozostało do zapłaty',
    //                 type: 'text',
    //                 id: 28,
    //                 fieldType: 'cena_liczba',
    //                 isPinned: false,
    //                 isVisible: true,
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 6,
    //                 actionBlock: null,
    //                 cellActions: [],
    //                 footerActions: []
    //             },
    //             {
    //                 code: 'recipient',
    //                 name: 'Adresaci',
    //                 type: 'text',
    //                 id: 25,
    //                 fieldType: 'pole_obce',
    //                 isPinned: false,
    //                 isVisible: true,
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 8,
    //                 actionBlock: null,
    //                 cellActions: [],
    //                 footerActions: []
    //             },
    //             {
    //                 code: 'status',
    //                 name: 'Status',
    //                 type: 'text',
    //                 id: 29,
    //                 fieldType: 'koszty',
    //                 isPinned: false,
    //                 isVisible: true,
    //                 isSortable: true,
    //                 isFilterable: true,
    //                 order: 9,
    //                 actionBlock: null,
    //                 cellActions: [],
    //                 footerActions: []
    //             },
    //             {
    //                 code: 'threeDots',
    //                 name: '',
    //                 type: 'threeDots',
    //                 id: -2,
    //                 fieldType: '',
    //                 isPinned: true,
    //                 isVisible: true,
    //                 isSortable: false,
    //                 isFilterable: false,
    //                 order: 7,
    //                 actionBlock: null,
    //                 cellActions: [
    //                     {
    //                         name: 'Edytuj',
    //                         code: 'edit',
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'link',
    //                             data: {
    //                                 url: 'ekran_obiektu.php',
    //                                 params: 'edycja=1&id={@objectId}&obiekt=kod_ekranu',
    //                                 confirmText: '',
    //                                 newWindow: false
    //                             }
    //                         }
    //                     },
    //                     {
    //                         name: 'Archiwizuj',
    //                         code: 'archive',
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'apiAction',
    //                             data: {
    //                                 url: 'archiveObject.php',
    //                                 params: 'screen={#obiekt}',
    //                                 body: '{ objectIds: [{@objectId}] }',
    //                                 confirmText: '',
    //                                 secondConfirmText: null,
    //                                 showResult: false,
    //                                 refresh: true
    //                             }
    //                         }
    //                     },
    //                     {
    //                         name: 'Usuń',
    //                         code: 'delete',
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'apiAction',
    //                             data: {
    //                                 url: 'deleteObject.php',
    //                                 params: 'screen={#obiekt}',
    //                                 body: '',
    //                                 confirmText: 'Czy na pewno chcesz usunąć ten obiekt?',
    //                                 secondConfirmText:
    //                                     'Potwierdź usunięcie obiektu. UWAGA TA OPERACJA JEST NIEODWRACALNA!',
    //                                 showResult: false,
    //                                 refresh: true
    //                             }
    //                         }
    //                     },
    //                     {
    //                         name: 'Klonuj',
    //                         code: 'clone',
    //                         colorConditions: null,
    //                         displayConditions: null,
    //                         defaultColor: null,
    //                         behaviour: {
    //                             type: 'link',
    //                             data: {
    //                                 url: 'ekran_obiektu.php',
    //                                 params: 'edycja=1&id={@objectId}&obiekt=kod_ekranu',
    //                                 confirmText: null,
    //                                 newWindow: false
    //                             }
    //                         }
    //                     }
    //                 ],
    //                 footerActions: []
    //             }
    //         ]
    //     }
    // };
}
