import { AppDispatch, useAppDispatch } from 'src/store';
import { setBank, setIssuer } from 'src/store/src/importPayoff';
import { clearState, setFileId } from 'src/store/src/importPayoff/importPayoff/importPayoffSlice';

type UpdateBank = {
    type: 'updateBank';
    value: string;
};

type UpdateIssuer = {
    type: 'updateIssuer';
    value: string;
};

type UpdateFileId = {
    type: 'updateFileId';
    value: string | null;
};
type ClearState = {
    type: 'clearState';
    value: null;
};

export type UpdateStateFnOuter = UpdateBank | UpdateFileId | UpdateIssuer | ClearState;

type UpdateState = UpdateStateFnOuter & { dispatch: AppDispatch };

export const useUpdateStateImportPayoff = () => {
    const dispatch = useAppDispatch();

    return {
        updateState: (data: UpdateStateFnOuter) =>
            updateState({
                dispatch: dispatch,
                ...data
            })
    };
};

function updateState({ dispatch, type, value }: UpdateState) {
    switch (type) {
        case 'updateBank':
            dispatch(setBank(value));
            break;
        case 'updateFileId':
            dispatch(setFileId(value));
            break;
        case 'updateIssuer':
            dispatch(setIssuer(value));
            break;
        case 'clearState':
            dispatch(clearState(null));
            break;

        default:
            break;
    }
}
