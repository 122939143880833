import { BaseApiResponse } from '../../types';
import { ObjectTabDataFromApi } from 'src/store/src/object/object/types';
import { TabId } from 'src/store/src/object/object/types';
import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';

export async function getObjectScreenTabData({
    objectId,
    tabId,
    screen,
    sectionParams
}: {
    objectId: string;
    screen: string;
    tabId: TabId;
    sectionParams: string[][];
}): Promise<BaseApiResponse<ObjectTabDataFromApi>> {
    const params = new URLSearchParams([
        ['screen', screen],
        ['objectId', objectId],
        ['tabId', tabId],
        ...sectionParams
    ]);
    return simpleQueryAPIData(
        `api_react/src/objectScreen/getObjectScreenTabData.php?${params.toString()}`
    );
}
