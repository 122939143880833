import * as actionTypes from '../../constants/actions';

/**
 * @function ActionCreators.openChat
 * @description Dodaje czat do state.chats
 * @param {object} chat - obiekt czatu
 * @returns {ReduxAction}
 */
export const openChat = (chat) => ({
    type: actionTypes.OPEN_CHAT,
    payload: { chat }
});

/**
 * @function ActionCreators.resetChat
 * @description Usuwa konkretny czat ze state.chats
 * @param {string} roomID - id czatu
 * @returns {ReduxAction}
 */
export const resetChat = (roomID) => ({
    type: actionTypes.RESET_CHAT,
    payload: { roomID }
});

/**
 * @function ActionCreators.prependChatsMessages
 * @description Doładowuje wiadomości do tablicy wiadomości.
 * @param {string} roomID - id czatu
 * @param {objcet[]} messages - tablica obiektów wiadomości
 * @param {number|null} index - id pierwszej wiadomośći lub null jeśli to pierwsza wiadomość w całym czacie.
 * @returns {ReduxAction}
 */
export const prependChatsMessages = (roomID, messages, index) => ({
    type: actionTypes.PREPEND_CHAT_MESSAGES,
    payload: { roomID, messages, index }
});

/**
 * @function ActionCreators.appendChatsMessage
 * @description Dodaje wiadomości na koniec. Tablicy wiadomości.
 * @param {string} roomID - id czatu
 * @param {objcet} message - obiekt wiadomości
 * @returns {ReduxAction}
 */
export const appendChatsMessage = (roomID, message) => ({
    type: actionTypes.APPEND_CHAT_MESSAGE,
    payload: { roomID, message }
});

/**
 * @function ActionCreators.setBottomOffset
 * @description Ustawia bottomOffset czatu
 * @param {string} roomID - id czatu
 * @param {number} value - ilość pixeli
 * @returns {ReduxAction}
 */
export const setBottomOffset = (roomID, value) => ({
    type: actionTypes.SET_BOTTOM_OFFSET,
    payload: { roomID, value }
});

/**
 * @function ActionCreators.setScrolledUp
 * @description Ustawia stan informujący czy dany czat został przeskrolowany do góry.
 * @param {string} roomID - id czatu
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setScrolledUp = (roomID, boolean) => ({
    type: actionTypes.SET_SCROLLED_UP,
    payload: { roomID, boolean }
});

/**
 * @function ActionCreators.setChatWindowLoading
 * @description Ustawia stan ładowania dla konkretnego okna czatu
 * @param {string} roomID - id czatu
 * @param {boolean} boolean - ilość pixeli
 * @returns {ReduxAction}
 */
export const setChatWindowLoading = (roomID, boolean) => ({
    type: actionTypes.SET_CHAT_WINDOW_LOADING,
    payload: { roomID, boolean }
});

/**
 * @function ActionCreators.replaceMessage
 * @description Podmienia wiadomość w czacie. Używane przy kasowaniu istniejących wiadomości.
 * @param {string} roomID - id czatu
 * @param {object} message - nowa wiadomość
 * @returns {ReduxAction}
 */
export const replaceMessage = (roomID, message) => ({
    type: actionTypes.REPLACE_MESSAGE,
    payload: { roomID, message }
});

/**
 * @function ActionCreators.replaceMessagesId
 * @description Podmienia id wiadomości
 * @param {string} roomID - id czatu
 * @param {object[]} idsArr - tablica obiektów z informacjami o id wiadomości
 * @param {string} idsArr.old - id do podmienienia
 * @param {string} idsArr.id - nowe id
 * @returns {ReduxAction}
 */
export const replaceMessagesId = (roomID, idsArr) => ({
    type: actionTypes.REPLACE_MESSAGES_ID,
    payload: { roomID, idsArr }
});

/**
 * @function ActionCreators.setNewMessage
 * @description Ustawia flage o nieodczytanych wiadomościach w danym czacie
 * @param {string} roomID - id czatu
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setNewMessage = (roomID, boolean) => ({
    type: actionTypes.SET_NEW_MESSAGE,
    payload: { roomID, boolean }
});

/**
 * @function ActionCreators.setLastRead
 * @description Dodaje do otwartego czatu listę ostatnich przeczytanych wiadomości.
 * @param {string} roomID - id czatu
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */

export const setLastRead = (roomID, lastRead) => ({
    type: actionTypes.SET_LAST_READ,
    payload: { roomID, lastRead }
});
/**
 * @function ActionCreators.updateLastRead
 * @description Uaktualnia ostatnią przeczytaną wiadomość jednego użytkownika wybranego czatu.
 * @param {string} chatRoomID - roomID czatu
 * @param {string} roomID - roomID użytkownika
 * @param {number|string} lastReadID id ostatnio przeczytanej wiadomości
 * @returns {ReduxAction}
 */
export const updateLastRead = (chatRoomID, roomID, lastReadID) => ({
    type: actionTypes.UPDATE_LAST_READ,
    payload: { roomID, chatRoomID, lastReadID }
});
/**
 * @function ActionCreators.setChatWindowMinimized
 * @description Ustawia stan zminimalizowania okna czatu.
 * @param {string} roomID - id czatu
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setChatWindowMinimized = (roomID, boolean) => ({
    type: actionTypes.SET_CHAT_WINDOW_MINIMIZED,
    payload: { roomID, boolean }
});
