import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles(({ shadows, palette, spacing }) => ({
    window: {
        background: palette.background.default,
        boxSizing: 'border-box',
        zIndex: 50000,
        width: spacing(35),
        height: (props) => (props.minimized ? spacing(5.5) : spacing(41)),
        overflow: 'hidden',
        padding: 0,
        paddingBottom: spacing(0.5),
        margin: 0,
        position: 'fixed',
        borderRadius: '4px 4px 0 0',
        bottom: spacing(0.5),
        display: 'flex',
        flexDirection: 'column',
        right: (props) => props.windowOrder * spacing(36) + spacing(0.5),
        boxShadow: shadows[3],
        transition: 'right .15s ease, height .2s ease !important'
        // '& *': {
        // 	userSelect: 'none',
        // }
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Wyświetla UI pojedyńczego okna czatu. Renderuje przekazane dzieci.
 * @component
 * @category Components
 * @subcategory Chat
 * @name Window
 * @param {object} props - Props komponentu.
 * @param {ReactComponent[] | ReactComponent} props.children - elementy które zostaną wyświetlone w oknie.
 * @param {boolean} props.windowOpen - czy okno ma być wyświetlane.
 * @param {Function} props.handleFocus - funkcja wykonywana po kliknięciu w jakąkolwiek część okna.
 * @param {string} props.id - służy do odróżnienia głównego okna czatu od okien pojedyńczych czatów.
 * @param {number} props.windowOrder - numer oznaczający kolejność wyświetlania okna od prawej strony.
 * @param {boolean} props.minimized - czy okno jest zminimalizowane.
 * @returns {ReactComponent}
 */
const ChatWindow = ({ children, windowOpen, handleFocus, id, windowOrder, minimized }) => {
    const classes = useStyles({ windowOrder, minimized });

    return (
        <Grow in={windowOpen} unmountOnExit>
            <div
                className={id ? [classes.window, 'chat_chat_window'].join(' ') : classes.window}
                onClick={handleFocus}>
                {children}
            </div>
        </Grow>
    );
};

export default ChatWindow;
