const onlyNumbersAndHyphen = /[0-9|-]+$/;
const onlyNumbersAndDotsComma = /[0-9|.|,]+$/;
const onlyNumbersAndDotsCommaMinus = /[0-9|.|,|-]+$/;
const onlyNumbers = /[0-9]+$/;

const divedeEmail = /^(.+)@(.+)$/;

const validateFirstPartEmail = /^"?[\w-_.]*"?$/;
const validateSecondPartEmail = /^[\w-.]*\.[A-Za-z]{2,63}$/;
const validateSecondPartEmailTwo = /^\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\]$/;

export const validateWholeEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const regexsGlobal = {
    onlyNumbersAndHyphen,
    onlyNumbersAndDotsComma,
    onlyNumbers,
    divedeEmail,
    validateFirstPartEmail,
    validateSecondPartEmail,
    validateSecondPartEmailTwo,
    validateWholeEmail,
    onlyNumbersAndDotsCommaMinus
};
