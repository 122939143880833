import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import {
    DynamicFormObjectRelationExtandedForcedValidatorProps,
    TypeValidateRelationExtandedForced
} from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData, PopupFormRelatedObject } from 'src/data/popupFormTypes';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateRelationExtandedForced';

export class DynamicFormObjectRelationExtandedForcedValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateRelationExtandedForced;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectRelationExtandedForcedValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        let relationNotValid: PopupFormRelatedObject | null = null;
        for (const relation of this.popupFormExtendedData.relations) {
            if (relation.wymuszenie_dodania && !relationNotValid) {
                let isValid = false;
                const deleteItem = Object.entries(this.formState.deleteRelationship).filter(
                    ([key]) => key.startsWith(`edycja_rpo_${relation.id}`)
                );
                const deleteValues = deleteItem.filter(([, value]) => value === '1');

                for (const key in this.formState.fields) {
                    if (key.startsWith(`nowy_rpo_${relation.id}`)) {
                        isValid = true;
                        break;
                    } else if (
                        key.startsWith(`edycja_rpo_${relation.id}`) &&
                        (!deleteItem.length || deleteValues.length < relation.objectsData.length)
                    ) {
                        isValid = true;
                        break;
                    }
                }
                if (!isValid) {
                    relationNotValid = relation;
                }
            }
        }
        if (relationNotValid) {
            this.setValidationResult({
                typeOfInfo: 'Error',
                type: {
                    issue: this.typeFnValidate,
                    message: `Prosze podać: ${relationNotValid.name}`,
                    title: validationData[this.typeFnValidate].title,
                    id_zakladki: relationNotValid.tabId
                }
            });
        }
    }
}
