export function createKeyIdMassBill({
    fieldId,
    clientId,
    contractId,
    groupId,
    positionId
}: {
    fieldId: number;
    clientId?: string;
    contractId?: string;
    groupId?: string;
    positionId?: string;
}) {
    let key = `#${fieldId}`;

    if (clientId) {
        key = key + `!${clientId}`;
    }
    if (contractId) {
        key = key + `!${contractId}`;
    }
    if (groupId) {
        key = key + `!${groupId}`;
    }
    if (positionId) {
        key = key + `!${positionId}`;
    }
    return key;
}
