import {
    FieldPropsAddToUiCommonProps,
    FieldsPure
} from 'src/components/popupFormExtended/dynamicField/types';

export const getNeededFieldParamsToUiFieldCommonPropsFull = (
    field: FieldsPure
): FieldPropsAddToUiCommonProps => ({
    isError: field.isError,
    isMandatory: field.isMandatory,
    valueToAccept: field.valueToAccept,
    valueToAcceptText: field.valueToAcceptText,
    name: field.name
});
