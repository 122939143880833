import { phpScreenToComponentUrl as object } from 'src/constants';

export function prepareSubdirectoryPath() {
    let subDirectoryPath = window.location.pathname.replace('/index.html', '');
    if (subDirectoryPath.includes('/lista-statusy')) {
        console.log('');
        subDirectoryPath = subDirectoryPath.replace('/lista-statusy', '');
    }
    for (const key in object) {
        if (subDirectoryPath.includes(object[key])) {
            subDirectoryPath = subDirectoryPath.replace(object[key], '');
        }
    }
    if (subDirectoryPath === '/') {
        return '';
    }
    return subDirectoryPath;
}
