import React, { FC } from 'react';

import { FieldFileManyUnmodifiedProps } from './types';
import { FieldWrapperUnmodified } from 'src/components/popupFormExtended/dynamicField/shared/fieldWrapperUnmodified/FieldWrapperUnmodified';
import { FileUnmodified } from '../fieldFile/fileUnmodified/FileUnmodified';

export const FieldFileManyUnmodified: FC<FieldFileManyUnmodifiedProps> = ({
    dynamicFieldT,
    field,
    uiFieldCommonProps
}) => {
    return (
        <FieldWrapperUnmodified
            dynamicFieldT={dynamicFieldT}
            field={field}
            isLabel={Boolean(uiFieldCommonProps?.isLabel)}>
            {dynamicFieldT.pliki &&
                Object.values(dynamicFieldT.pliki).map(({ dane_pliku }, i) => (
                    <FileUnmodified fileData={dane_pliku} key={i} />
                ))}
        </FieldWrapperUnmodified>
    );
};
