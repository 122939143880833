import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

type GetInBudgetPopupFormExtendedData = {
    params: {
        [key: string]: string | number;
    };
};

export async function getInBudgetPopupFormExtendedData(
    url: string,
    formData: FormData
): Promise<BaseApiResponse<GetInBudgetPopupFormExtendedData>> {
    return simpleQueryAPIData(url, 'POST', formData, false, undefined, undefined, false);
}
