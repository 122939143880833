import React, { FC } from 'react';
import { StartupAnimation } from 'src/components/startupscreen/pages/startupAnimation/StartupAnimation';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const StartupView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "StartupView" */ 'src/components/startupscreen/templates/startupView/StartupView'
        )
);

/** Main component for the startup screen, React.suspense is used mainly to postpone loading
 *  initial data in StartupView, so useRunAllHooksOnEachRenderReactPage check if user is logged and
 *  maybe do proper naviagation first
 */
export const StartupMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage();

    return (
        <div
            style={{
                position: 'relative'
            }}>
            <StartupAnimation />
            <React.Suspense fallback>
                <StartupView />
            </React.Suspense>
        </div>
    );
};
