import React from 'react';
import { fetchHistoryChangeObjectsInitData } from 'src/api/src/historyChangeObjectScreem/fetchHistoryChangeObjectInitData';
import { HistoryChangeObjectData } from 'src/api/src/historyChangeObjectScreem/types';
import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';

export const useHistoryChangeObjectDefaultData = () => {
    const obiekt = useSearchParam('obiekt');
    const id = useSearchParam('id');
    const [data, setData] = React.useState<HistoryChangeObjectData>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            if (id && obiekt) {
                const res = await fetchHistoryChangeObjectsInitData(id, obiekt);
                if (res.success) {
                    setData(res.data);
                }
                setIsLoading(false);
            }
        })();
    }, []);

    return {
        isLoading,
        data
    };
};
