import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiConfigData, ApiSaveConfigData } from './types';

export async function saveConfigData(
    configData: ApiSaveConfigData
): Promise<BaseApiResponse<ApiConfigData>> {
    return simpleQueryAPIData(
        'api_react/src/configurationScreen/saveConfiguration.php',
        'POST',
        configData
    );
}
