import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { fetchAndLoadThemeColors } from 'src/store/src/menu';
import styles from './ThemeColorMiniMenu.module.css';
import { ThemeColor } from 'src/store/src/menu/menu/types';
import { updateColorTheme } from 'src/store/src/general';
import { saveThemeColor } from 'src/api/src/menu/saveThemeColor';
import { setPopupInfoTwo } from 'src/store/src/popup';

const ThemeColorMiniMenu = () => {
    const colorThemes = useTypedSelector((state) => state.menu.colorThemes);
    const userColorTheme = useTypedSelector((state) => state.user.colorThemeSystem);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // if data is not loaded, load it
        if (colorThemes === null) {
            dispatch(fetchAndLoadThemeColors());
        }
    }, []);

    const changeTheme = useCallback(
        async (theme: ThemeColor) => {
            const initialColor = userColorTheme;
            dispatch(updateColorTheme(theme.hash));

            // save in api
            const response = await saveThemeColor(theme.id);
            if (!response.success) {
                dispatch(
                    setPopupInfoTwo({
                        title: 'Nie udało się wykonać zmiany koloru',
                        message: response.message
                    })
                );
                dispatch(updateColorTheme(initialColor));
            }
        },
        [userColorTheme, dispatch]
    );

    // when null data is not loaded yet to state, when is emty array do not show this section at all
    if (colorThemes === null || colorThemes.length === 0) {
        return null;
    }

    return (
        <div>
            <p>Motywy:</p>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {colorThemes.map((theme) => (
                    <div
                        key={theme.id}
                        onClick={() => changeTheme(theme)}
                        className={styles.colorBall}
                        style={{
                            backgroundColor: theme.hash
                        }}>
                        {theme.hash === userColorTheme && <span className={styles.checkmark} />}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ThemeColorMiniMenu;
