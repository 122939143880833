import React, { FC } from 'react';

import { useAppDispatch, AppDispatch } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { ApiActionBehavior } from 'src/data/types';
import { ApiActionProps } from './types';
import { useRefreshObjectData } from 'src/hooks/src/object/useRefreshObjectData';
import { useGetUrlBodyParamsForApiAction } from 'src/hooks';

export const ApiAction: FC<ApiActionProps> = ({ children, action, additional }) => {
    const behaviour = action.behaviour as ApiActionBehavior;
    const dispatch: AppDispatch = useAppDispatch();
    const sourceOfTrigger: SourceOfTrigger = { type: 'object', typeWew: additional.typeSection };
    const refreshObjectScreenData = useRefreshObjectData();
    const { getUrlBodyParams } = useGetUrlBodyParamsForApiAction({
        action,
        behaviour,
        sourceOfTrigger,
        additional
    });
    const handleApiAction = async () => {
        if (behaviour?.data?.confirmText) {
            new PopupManager({ dispatch, action }).setPopupConfirmApi({
                rowId: [],
                behaviour,
                additional,
                sourceOfTrigger
            });
            return;
        }
        const { params, body, url } = getUrlBodyParams();
        const urlAll = url + '?' + params;
        dispatch(setSpinner(true));
        const resp = await simpleQueryAPIData(urlAll, 'POST', body, false);
        if (resp.success) {
            if (behaviour.data.showResult) {
                new PopupManager({ dispatch: dispatch, action: action }).setPopupInfo('Wykonano');
            }
            refreshObjectScreenData(null);
        }
        dispatch(setSpinner(false));
    };

    return (
        <ButtonCustom
            onClick={() => handleApiAction()}
            withoutStyles={true}
            preventMultipleClick={true}>
            {children}
        </ButtonCustom>
    );
};
