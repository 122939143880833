import { createTheme, Theme } from '@material-ui/core/styles';

declare module 'styled-components' {
    export interface DefaultTheme extends Theme {}
}

declare module '@material-ui/core/styles/createTheme' {
    interface ThemeOptions {
        // themeName?: string;
    }
}

export const styledCreateCustomTheme = (mainColor: string) => {
    return createTheme({
        palette: {
            type: 'light',
            primary: {
                main: mainColor
            },
            secondary: {
                main: '#000000'
            },
            success: {
                main: '#1a911a'
            },
            error: {
                main: '#c52f2f'
            },
            divider: 'rgb(0 0 0 / 12%)',
            background: {
                default: '#ffffff',
                paper: '#f6f6f6'
            },
            text: {
                primary: '#000000',
                secondary: mainColor,
                disabled: '#DEDEDE',
                hint: '#B9B9B9'
            }
        },
        typography: {
            h1: {
                fontSize: '0.875rem', // 14 px
                color: mainColor
            },
            h2: {
                fontSize: '0.875rem' // 12 px
            },
            h3: {
                fontSize: '0.75rem' // 12 px
            },
            h4: {
                fontSize: '0.75rem' // 12 px
            },
            h5: {
                fontSize: '0.75rem' // 12 px
            },
            h6: {
                fontSize: '0.75rem' // 12 px
            },
            body1: {
                fontSize: '0.75rem' // 12 px
            },
            subtitle1: {
                fontSize: '0.625rem' // 10 px
            },
            subtitle2: {
                fontSize: '0.5rem' // 8px
            },
            fontFamily: 'Roboto'
        },
        themeColor: {
            light: '#ffffff',
            main: mainColor,
            dark: '#000000'
        }
    });
};

export type ThemeType = ReturnType<typeof createTheme>;
