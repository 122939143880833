import React from 'react';
import { FinancesBlockData } from 'src/store/src/startupScreen/startupScreen/types';
import { createAnimationStat } from 'src/utils';

export type UseCalcAndDrawChartFinancesStartupScreen = {
    sumCosts: number;
    sumBill: number;
};

export function useCalcAndDrawChartFinancesStartupScreen(
    data: FinancesBlockData
): UseCalcAndDrawChartFinancesStartupScreen {
    const {
        billRatio,
        plannedToBillRatio,
        costsRatio,
        generalCostsRatio,
        personalCostsRatio,
        sumBill,
        sumCosts
    } = callDraw(data);

    React.useEffect(() => {
        const billone = document.getElementById('billone');
        const animationOne = createAnimationStat(billRatio);
        const billtwo = document.getElementById('billtwo');
        const animationTwo = createAnimationStat(plannedToBillRatio);
        const costone = document.getElementById('costone');
        const animationThree = createAnimationStat(costsRatio);
        const costtwo = document.getElementById('costtwo');
        const animationFour = createAnimationStat(generalCostsRatio);
        const costthree = document.getElementById('costthree');
        const animationFive = createAnimationStat(personalCostsRatio);
        if (billone && billtwo && costone && costtwo && costthree) {
            billone.setAttribute('height', String(billRatio));
            billone.appendChild(animationOne);
            billtwo.setAttribute('height', String(plannedToBillRatio));
            billtwo.appendChild(animationTwo);
            costone.setAttribute('height', String(costsRatio));
            costone.appendChild(animationThree);
            costtwo.setAttribute('height', String(generalCostsRatio));
            costtwo.appendChild(animationFour);
            costthree.setAttribute('height', String(personalCostsRatio));
            costthree.appendChild(animationFive);
        }
    }, []);
    return { sumBill, sumCosts };
}

function callDraw(data: FinancesBlockData) {
    const billedAmountN = Number(data.billedAmount);
    const plannedToBillAmountN = Number(data.plannedToBillAmount);
    const costsN = Number(data.costs);
    const generalCostsN = Number(data.generalCosts);
    const personalCostsN = Number(data.personalCosts);

    if (!billedAmountN && !plannedToBillAmountN && !costsN && !generalCostsN && !personalCostsN) {
        return {
            billRatio: 0,
            plannedToBillRatio: 0,
            costsRatio: 0,
            generalCostsRatio: 0,
            personalCostsRatio: 0,
            sumCosts: 0,
            sumBill: 0
        };
    }

    const sumCosts = Number(costsN) + Number(generalCostsN) + Number(personalCostsN);
    const sumBill = Number(billedAmountN) + Number(plannedToBillAmountN);
    let billHigher = true;
    let ratio = sumCosts / sumBill;
    if (sumCosts > sumBill) {
        billHigher = false;
        ratio = sumBill / sumCosts;
    }
    const plannedToBillRatio = 100;
    const billRatio = Math.floor((billedAmountN / sumBill) * 100);
    const costsRatio = 100;
    const generalCostsRatio = Math.floor(((generalCostsN + personalCostsN) / sumCosts) * 100);
    const personalCostsRatio = Math.floor((personalCostsN / sumCosts) * 100);
    if (billHigher) {
        return {
            billRatio,
            plannedToBillRatio,
            costsRatio: costsRatio * ratio,
            generalCostsRatio: generalCostsRatio * ratio,
            personalCostsRatio: personalCostsRatio * ratio,
            sumCosts,
            sumBill
        };
    } else {
        return {
            billRatio: billRatio * ratio,
            plannedToBillRatio: plannedToBillRatio * ratio,
            costsRatio,
            generalCostsRatio,
            personalCostsRatio,
            sumCosts,
            sumBill
        };
    }
}
