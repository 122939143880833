export function isIncludedToSum({
    keySumInTempState,
    keySum
}: {
    keySumInTempState: string;
    keySum: string;
}) {
    const keySumInTempStateArr = keySumInTempState?.split('!').length;
    const keySumArr = keySum?.split('!').length + 1;
    if (keySumInTempState.includes(keySum) && keySumInTempStateArr === keySumArr) {
        return true;
    }
    return false;
}
