import { DynamicFieldT } from 'src/data/popupFormTypes';

export function prepareParamsForFnGetFieldAddionalData({
    type,
    value,
    select,
    valueSuperior,
    dynamicFieldT
}: {
    type: 'lista_obca' | 'powiazanie_typ' | 'lista_obca_wielokrotna';
    value: string;
    select?: string;
    valueSuperior?: string;
    dynamicFieldT: DynamicFieldT;
}) {
    let params = {};
    switch (type) {
        case 'powiazanie_typ':
            params = {
                q: value,
                typ: select,
                id_pola_z_warunkiem: dynamicFieldT.id
            };
            break;
        case 'lista_obca':
            if (dynamicFieldT.pole_nadrzedne_typ) {
                params =
                    dynamicFieldT.pole_nadrzedne_typ === 'lista_obca'
                        ? {
                              q: valueSuperior,
                              typ_nadrzedne: dynamicFieldT.pole_nadrzedne_tabela_opcji,
                              typ_podrzedne: dynamicFieldT.tabela_opcji,
                              id_pola: dynamicFieldT.id,
                              filtr: value
                          }
                        : {
                              q: valueSuperior,
                              typ_nadrzedne: dynamicFieldT.pole_nadrzedne_typ,
                              typ_podrzedne: dynamicFieldT.tabela_opcji,
                              id_pola: dynamicFieldT.id,
                              filtr: value
                          };
            } else {
                params = {
                    q: value,
                    typ: dynamicFieldT.tabela_opcji,
                    id_pola: dynamicFieldT.id
                };
            }
            break;
        case 'lista_obca_wielokrotna':
            if (dynamicFieldT.pole_nadrzedne_typ) {
                params =
                    dynamicFieldT.pole_nadrzedne_typ === 'lista_obca'
                        ? {
                              q: valueSuperior,
                              typ: dynamicFieldT.pole_nadrzedne_tabela_opcji,
                              kod: dynamicFieldT.tabela_opcji,
                              pole: dynamicFieldT.id,
                              filtr: value
                          }
                        : {
                              q: valueSuperior,
                              typ: dynamicFieldT.pole_nadrzedne_typ,
                              kod: dynamicFieldT.tabela_opcji,
                              pole: dynamicFieldT.id,
                              filtr: value
                          };
            } else {
                params = {
                    q: value,
                    typ: dynamicFieldT.tabela_opcji,
                    pole: dynamicFieldT.id
                };
            }
            break;
        default:
            break;
    }

    return params;
}
