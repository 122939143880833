import { Box } from '@mui/material';
import React from 'react';
import { useTypedSelector } from 'src/store';
import { FestivitiesIconsProvider } from 'src/utils/src/shared/FestivitiesIconsProvider';

const LoginHeader = () => {
    const systemLogo = useTypedSelector((state) => state.system.systemLogo);
    const systemLogoClient = useTypedSelector((state) => state.system.systemLogoClient);

    return (
        <Box
            sx={{
                justifyContent: 'start',
                display: 'grid',
                gridTemplateColumns: { xs: '1fr 0fr 1fr', sm: '1fr 0.5fr 1fr' },
                mb: '7vh'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'start',
                    margin: '0.5rem'
                }}>
                <img
                    src={systemLogo ?? new FestivitiesIconsProvider().getLoginHeaderLogo()}
                    alt="System logo"
                    style={{
                        width: '100%',
                        maxWidth: '25rem',
                        height: 'auto',
                        maxHeight: '10vh',
                        objectFit: 'contain'
                    }}
                />
            </Box>
            <div></div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'flex-end',
                    margin: '0.5rem'
                }}>
                {systemLogoClient && (
                    <img
                        src={systemLogoClient}
                        alt="Client logo"
                        style={{
                            width: '100%',
                            maxWidth: '25rem',
                            height: 'auto',
                            maxHeight: '10vh',
                            objectFit: 'contain'
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default LoginHeader;
