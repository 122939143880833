import React, { FC } from 'react';

import {
    FieldMailManyObjectProps,
    OnChangeFieldMailManyFn,
    OnDeleteFieldMailManyFn
} from './types';
import { UIFieldMailMany } from 'src/components/popupFormExtended/dynamicField/fieldMailMany/UIFieldMailMany';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';
import { FieldMailManyElBasicType } from 'src/data/fieldsFormType';

export const FieldMailManyObject: FC<FieldMailManyObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);

    const onChange = ({ type, e, id, key, onBlur }: OnChangeFieldMailManyFn) => {
        let val: string | boolean = e.target.value;

        if (type !== 'adres') {
            val = e.target.checked;
        }
        const newElements = state[key].map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    [type]: val
                };
            }
            return item;
        });

        setState((prev) => {
            return {
                ...prev,
                [key]: newElements
            };
        });
        if (onBlur) {
            addToStore(newElements, key);
        }
    };

    const onDelete = ({ e, key, id }: OnDeleteFieldMailManyFn) => {
        let newElements: FieldMailManyElBasicType[] = [];
        if (key === 'elements') {
            newElements = state.elements.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        isToDelete: item.isToDelete === true ? false : true
                    };
                }
                return item;
            });
        } else {
            newElements = state.newElements
                .filter((item) => item.id !== id)
                .map((item, i) => {
                    return {
                        ...item,
                        id: String(i + 1)
                    };
                });
        }
        setState((prev) => {
            return {
                ...prev,
                [key]: newElements
            };
        });
        addToStore(newElements, key);
    };

    const addMail = () => {
        const noweEl = field.value.newElements ?? [];

        const newElements = [
            ...noweEl,
            {
                adres: '',
                adresat_id: '',
                adresat_typ: '',
                opis: '',
                id: String(noweEl.length + 1),
                korespondencja: true,
                wysylka_raportow: true,
                wysylka_reklamowa: true,
                isAdded: true
            }
        ];

        const newValue = {
            ...state,
            newElements
        };
        addToStore(newElements, 'newElements');
        setState(newValue);
    };

    const addToStore = (newValue: FieldMailManyElBasicType[], key: 'elements' | 'newElements') => {
        const payloadValue = {
            ...field,
            value: {
                ...state,
                [key]: newValue
            }
        };
        dispatch({
            type: 'emaile_wielokrotne',
            payload: {
                code: keyOfFieldInState,
                value: payloadValue
            }
        });
    };

    return (
        <UIFieldMailMany
            value={state}
            onChange={onChange}
            onDelete={onDelete}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            addMail={addMail}
            uiFieldSharedProps={{
                dynamicFieldT
            }}
        />
    );
};
