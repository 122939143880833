import React, { ChangeEventHandler } from 'react';
import InputMask from 'react-input-mask';
import { UiInputNormal } from 'src/components/shared/atoms/uiInputs/uiInputNormal/UiInputNormal';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

type InputTimeProps = {
    label?: string;
    type: string;
    value: string;
    maskChar: string;
    sxinputtime?: SxProps<Theme> | undefined;
    placeholder: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
};

export const InputTime = (props: InputTimeProps) => {
    return (
        <InputMask mask="99:99" alwaysShowMask={false} {...props}>
            {(inputProps: InputMask) => (
                <UiInputNormal
                    {...inputProps}
                    sxinput={{
                        ...props.sxinputtime,
                        input: {
                            '&::-webkit-input-placeholder': {
                                paddingLeft: '0px'
                            },
                            '&::-ms-input-placeholder': {
                                paddingLeft: '0px'
                            }
                        }
                    }}
                />
            )}
        </InputMask>
    );
};
