import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { GlobalPopupContextManager } from 'src/context/globalPopup/GlobalPopupContextManager';
import { TabProps } from 'src/components/popupFormExtended/tab/types';
import { usePopupState } from 'src/context/globalPopup/popupContext';
import styles from './Tab.module.css';

export const Tab: FC<TabProps> = ({ id, index, name, keyPopup }) => {
    const theme = useTheme();
    const { popupStateRedux, dispatchPopupStateContext, popupStateContext } =
        usePopupState(keyPopup);
    const ref2 = React.useRef<HTMLDivElement>(null);
    const activeTabIdIndex = GlobalPopupContextManager.setActiveTabIndex(popupStateRedux, id);

    const setTab = () => {
        if (activeTabIdIndex === undefined) {
            console.error('Tab: activeTabIdIndex is undefined');
            return;
        }

        dispatchPopupStateContext({
            type: 'SET_ACTIVE_TAB_ID_AND_INDEX',
            payload: {
                activeTabId: id,
                activeTabIdIndex
            }
        });
    };

    React.useEffect(() => {
        if (id === popupStateContext.activeTab.id && ref2.current) {
            const dimensions = ref2.current.getBoundingClientRect();
            const offsetLeft = ref2.current.offsetLeft;
            if (popupStateContext.activeTab.positionLeft !== dimensions.left) {
                dispatchPopupStateContext({
                    type: 'SET_ACTIVE_TAB_POSITION',
                    payload: {
                        positionLeft: dimensions.left,
                        offsetLeft,
                        width: dimensions.width
                    }
                });
            }
        }
    }, [popupStateContext.activeTab]);

    if (popupStateContext.tabsLayout.name === 'WITHOUTSCROLL') {
        return (
            <div
                className={styles.tab}
                onClick={setTab}
                ref={ref2}
                style={{
                    boxShadow: `-1px -1px 0px 0px ${
                        !index ? theme.palette.primary.main : 'transparent'
                    }`,
                    borderRight: `1px solid  ${theme.palette.primary.main}`,
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                    borderTop: 'transparent',
                    color: '1px solid black'
                }}>
                <p className={styles.text}>{name}</p>
            </div>
        );
    }

    return (
        <div
            className={styles.tab}
            onClick={setTab}
            ref={ref2}
            style={{
                borderLeft: `1px solid  ${!index ? theme.palette.primary.main : 'transparent'}`,
                borderRight: `1px solid  ${theme.palette.primary.main}`,
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                borderTop: 'transparent',
                color: '1px solid black'
            }}>
            <p className={styles.text}>{name}</p>
        </div>
    );
};
