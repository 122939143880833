import { MassBillData } from 'src/api/src/massBill/types';

export function createAdditionalParamsMassBill(massBill: MassBillData) {
    const data: { [key: string]: any } = {};
    data['klienci[]'] = [];
    if (massBill.params.grupowanie === '1') {
        massBill.client?.forEach((client) => {
            data['klienci[]'].push(`${client.tab}_${client.id}`);
            data[`kontrakty_${client.tab}_${client.id}[]`] = [];
            client.kontrakty.forEach((contract) => {
                data[`kontrakty_${client.tab}_${client.id}[]`].push(`${contract.id}`);
                data[`grupy_${client.tab}_${client.id}_${contract.id}[]`] = [];
                contract.grupy.forEach((group) => {
                    data[`grupy_${client.tab}_${client.id}_${contract.id}[]`].push(`${group.id}`);
                    data[`pozycje_${client.tab}_${client.id}_${contract.id}_${group.id}[]`] = [];
                    group.pozycje.forEach((position) => {
                        data[
                            `pozycje_${client.tab}_${client.id}_${contract.id}_${group.id}[]`
                        ].push(`${position.id}`);
                    });
                });
            });
        });
    } else {
        data['pozycje[]'] = [];
        massBill.position.forEach((positionItem) => {
            data['pozycje[]'].push(positionItem.id);
        });
    }

    return {
        ...massBill,
        params: {
            ...massBill.params,
            ...data
        }
    };
}
