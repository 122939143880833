import React, { FC } from 'react';
import { PermissionViewProps } from './types';
import PermissionsForm from '../../organisms/permissionsForm/PermissionsForm';
import UiWrapperView from 'src/components/shared/atoms/uiLayout/uiWrapperView/UiWrapperView';
import { UiWrapperViewTop } from 'src/components/shared/atoms/uiLayout/uiWrapperViewTop/UiWrapperViewTop';
import { UtilitiesBarRouter } from 'src/components/listScreen/organisms/utilityBarRouter/UtilityBarRouter';
import { NormalScroll } from 'src/components/shared/molecules/normalScroll/NormalScroll';

const PermissionsView: FC<PermissionViewProps> = ({ permissionScreenState }) => {
    return (
        <UiWrapperView>
            <UiWrapperViewTop>
                <div>
                    <UtilitiesBarRouter pageType="ekran_uprawnien" />
                </div>
            </UiWrapperViewTop>
            <NormalScroll height="100%" width="100%">
                <PermissionsForm permissionScreenState={permissionScreenState} />
            </NormalScroll>
        </UiWrapperView>
    );
};

export default PermissionsView;
