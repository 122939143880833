import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';
import { BaseApiResponse } from '../../types';
import { ApiDataImportPayoff } from 'src/api/src/importPayoff/types';

export async function fetchPayoffImportInitData(): Promise<BaseApiResponse<ApiDataImportPayoff>> {
    return simpleQueryAPIData(
        'api_react/src/importPayoff/getInitialDataForImportPayoff.php',
        'GET'
    );
}
