import React, { FC, useMemo } from 'react';
import Belt from 'src/components/popupFormExtended/belt/Belt';
import { Relations } from 'src/components/popupFormExtended/relations/Relations';
import { SectionProps } from 'src/components/popupFormExtended/sections/types';
import { FormContext } from '../dynamicFormObject/DynamicFormContextProvider';
import { GlobalPopup } from 'src/context/globalPopup/popupContext';
import { Columns } from 'src/components/popupFormExtended/columns/Columns';
import { useComponentSize } from 'react-use-size';

export const Section: FC<SectionProps> = (props) => {
    const { globalPopupState } = React.useContext(GlobalPopup);
    const { index, section } = props;
    const { formState } = React.useContext(FormContext);

    const { ref: beltRef } = useComponentSize();

    // check if display section
    const showSection = useMemo(() => {
        if (section.relations.length > 0) return true;

        const visibleField = section.fields.findIndex(
            (dynamicFieldT) =>
                !(dynamicFieldT.obce === '1' || dynamicFieldT.obce_z_powiazan === '1'
                    ? formState.fieldsForeign[dynamicFieldT.kod]?.hidden
                    : formState.fields[dynamicFieldT.kod]?.hidden)
        );

        return visibleField !== -1;
    }, [section, formState.fields, formState.fieldsForeign]);

    if (!showSection) return null;

    const withoutBorderTop = section.fields.length === 0;

    return (
        <Belt index={index} withoutBorderTop={withoutBorderTop} ref={beltRef}>
            <Columns
                columns={section.columns}
                fieldWidth={globalPopupState.dimensions.fieldWidth}
                {...props}
            />
            {section.relations.length ? <Relations {...props} /> : null}
        </Belt>
    );
};
