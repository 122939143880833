import { FieldFile } from 'src/components/popupFormExtended/dynamicField/fieldFile/types';
import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';

type FieldDependentFileManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldFile;
};

export class FieldDependentFileManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldFile;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentFileManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    // in order to simulate 'WpiszNazwePliku' system
    async zmienWartosciPolZNadrzednegoModTextForm() {
        await this.getDataForSuperior();
        await this.checkIfIsReturnValuesForChild();
    }

    private async getDataForSuperior() {
        if (this.fieldWasTriggeredDynamicFieldT?.pola_domyslne_z_nadrzednego === undefined) return;

        for (const field of this.fieldWasTriggeredDynamicFieldT?.pola_domyslne_z_nadrzednego) {
            this.valuesFromSuperior[field.kod] = {
                value: this.fieldWasTriggered.value.name,
                text: this.fieldWasTriggered.value.name
            };
        }
        this.isCaptureValuesFromSuperior = true;
    }
}
