import { useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { useSearchParam } from './useSearchParam';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import { updatePageTitleWithScreenName } from 'src/store/src/general';

export const useLoadScreenParamPageTitle = () => {
    const dispatch = useAppDispatch();
    const screenParam = useSearchParam('obiekt');
    const modules = useTypedSelector((store) => store.menu.menuStructureData.modules);

    useEffect(() => {
        // loading page title
        if (modules == null) return;
        const screenObj = MenuStateManager.getActualScreen({
            menuData: modules,
            screen: screenParam
        });
        if (screenObj) dispatch(updatePageTitleWithScreenName(screenObj?.name));
    }, [dispatch, screenParam, modules]);
};
