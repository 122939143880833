import { useTypedSelector } from 'src/store';
import { ImportPayoffState } from 'src/store/src/importPayoff/importPayoff/types';

export const useGetInitDataImportPayoff = () => {
    const stateImportPayoff = useTypedSelector((state) => state.importPayoffSlice);
    const isDisplayIssuers = checkIsDisplayIssers(stateImportPayoff);
    return { stateImportPayoff, isDisplayIssuers };
};
function checkIsDisplayIssers(stateImportPayoff: ImportPayoffState) {
    const bank = stateImportPayoff.banks.find((bank) => bank.value === stateImportPayoff.bank);
    if (bank?.autoIssuer === '0') {
        return true;
    }
    return false;
}
