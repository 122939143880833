import React, { FC, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputBase } from '@mui/material';

import { FieldValueToAcceptSectionProps } from './types';
import { SelectIcon } from '../uiInputSelectTwo/SelectIcon';
import { useTheme } from '@mui/material/styles';
import { sxInputV1Default } from 'src/components/shared/atoms/uiInputs/sxDefault';

const FieldValueToAcceptSection: FC<FieldValueToAcceptSectionProps> = ({
    valueToAccept,
    textToAccept,
    onAcceptValue
}) => {
    const theme = useTheme();

    const useStyles = makeStyles({
        input: {
            '&.Mui-disabled': {
                '-webkit-text-fill-color': 'black !important'
            }
        }
    });
    const classes = useStyles();

    const inputBaseClasses = useMemo(
        () => ({
            input: classes.input
        }),
        [classes.input]
    );

    const sxinput = useMemo(
        () => ({
            ...sxInputV1Default,
            border: `solid 1px ${theme.palette.primary.main}`,
            width: '100%'
        }),
        [theme.palette.primary.main]
    );

    const buttonSx = useMemo(
        () => ({ width: 'auto', transform: 'rotate(180deg)', minWidth: '16px' }),
        []
    );

    return (
        <>
            <Button onClick={onAcceptValue} sx={buttonSx}>
                <SelectIcon />
            </Button>
            <InputBase
                sx={sxinput}
                classes={inputBaseClasses}
                disabled={true}
                value={textToAccept ?? valueToAccept}
            />
        </>
    );
};

export default React.memo(FieldValueToAcceptSection);
