import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const HistoryReadingObjectView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "HistoryReadingObjectView" */ 'src/components/historyReadingObjectScreen/templates/historyReadingObjectView/HistoryReadingObjectView'
        )
);

export const HistoryReadingObjectMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Historia odczytów');
    return (
        <div>
            <React.Suspense fallback>
                <HistoryReadingObjectView />
            </React.Suspense>
        </div>
    );
};
