import { transformStringKeysToBooleans } from '../utils';
import { Middleware } from 'redux';

/**
 * @category Redux
 * @memberof Middleware
 * @function pgBool
 * @description Przekształca akcje która posiada własności meta.pg_bools. Jest to tablica ze stringami zawierające klucze których
 * wartość należy przekształcić z stringów 'f', 't' na wartości false i true.
 * @returns {Function}
 */
const pgBoolMiddleware: Middleware = (_) => (next) => (action) => {
    const keys = action?.meta?.pg_bools;
    if (keys && action?.payload) {
        action.payload = transformStringKeysToBooleans(action.payload, keys);
        delete action.meta.pg_bools;
    }
    return next(action);
};

export default pgBoolMiddleware;
