import React, { FC } from 'react';

import { UiWrapperProps } from './types';

export const UiWrapper: FC<UiWrapperProps> = ({ children, style }) => {
    return (
        <div
            style={{
                ...style
            }}>
            {children}
        </div>
    );
};
