/* We use react-redux shallowEqual but, changed to compare participants prop (which is array of numbers) after stringified.
 * This way two equal but referentially differnet arrays can be compared.
 */
function is(x: any, y: any) {
    if (x === y) {
        return x !== 0 || y !== 0 || 1 / x === 1 / y;
    } else {
        return x !== x && y !== y;
    }
}

export const compareEventProps = (prev: any, next: any): boolean => {
    const prevKeys = Object.keys(prev);
    const nextKeys = Object.keys(next);

    if (prevKeys.length !== nextKeys.length) return false;

    for (let i = 0; i < prevKeys.length; i++) {
        if (
            prevKeys[i] === 'participants' &&
            !is(prev[prevKeys[i]].toString(), next[prevKeys[i]].toString())
        )
            return false;
        else if (
            !Object.prototype.hasOwnProperty.call(next, prevKeys[i]) ||
            !is(prev[prevKeys[i]], next[prevKeys[i]])
        ) {
            return false;
        }
    }

    return true;
};
