import { DynamicFieldT } from 'src/data/popupFormTypes';

type ArrayOptions = {
    id: string;
    text: string;
}[];

export function safeGetArrayOptionsWithAddNewObjectOption(
    options: ArrayOptions,
    field: DynamicFieldT
): ArrayOptions {
    if (field.dodanie_nowego === 't' && options.findIndex((e) => e.id === 'nowy') === -1)
        return [...options, { id: 'nowy', text: 'Dodaj...' }];
    return options;
}
