import { BudgetHandlerStateGlobalKind } from 'src/store/src/budget/budget/types';

export const checkRunMethodSumInMonthForCaseLimit = (
    monthIdLimit: string[],
    budgetHandlerStateGlobalKind: BudgetHandlerStateGlobalKind,
    monthId: string
) => {
    switch (budgetHandlerStateGlobalKind?.kind) {
        case 'getYearsBefore':
            return true;
        default:
            if (monthIdLimit.includes(String(monthId))) {
                return true;
            }
            return false;
    }
};
