import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldSystemDateObjectProps } from './types';
import { UIFieldSystemDate } from 'src/components/popupFormExtended/dynamicField/fieldSystemDate/UIFieldSystemDate';

export const FieldSystemDateObject: FC<FieldSystemDateObjectProps> = ({
    dynamicFieldT,
    field,
    uiFieldCommonProps
}) => {
    return (
        <UIFieldSystemDate
            value={field.value}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT
            }}
        />
    );
};
