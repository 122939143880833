import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldPrice } from 'src/components/popupFormExtended/dynamicField/fieldPrice/UIFieldPrice';
import { FieldPriceObjectProps } from './types';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { FieldPriceChangeType, FieldPriceManager } from 'src/utils/src/shared/FieldPriceManager';
import { replaceStr } from 'src/utils';

export const FieldPriceObject: FC<FieldPriceObjectProps> = ({
    field,
    popupFormExtendedData,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    prefix,
    relationId
}) => {
    const [state, setState] = React.useState(field.value);
    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        changeType: FieldPriceChangeType,
        onBlur: boolean
    ) => {
        let valueTemp = e.target.value;
        if (changeType === 'brutto' || changeType === 'netto') {
            valueTemp = replaceStr({
                str: e.target.value,
                replaceTypes: ['commaForDotAll', 'onlyDigitAll']
            });
        }
        const newVal = new FieldPriceManager({
            oldValue: field.value,
            value: valueTemp,
            changeType
        }).calc();
        setState((prev) => {
            return {
                ...prev,
                ...newVal
            };
        });
        if (onBlur) {
            const fieldState = {
                ...field,
                value: newVal
            };
            dispatch({
                type: 'cena',
                payload: {
                    code: keyOfFieldInState,
                    value: fieldState
                }
            });

            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        }
    };

    React.useEffect(() => {
        setState(field.value);
    }, [field.value]);

    return (
        <UIFieldPrice
            value={state}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            onChangeNetto={(e) => onChange(e, 'netto', false)}
            onChangeVat={(e) => onChange(e, 'vat', true)}
            onChangeBrutto={(e) => onChange(e, 'brutto', false)}
            onChangeCurrency={(e) => onChange(e, 'currency', true)}
            onBlurBrutto={(e) => onChange(e, 'brutto', true)}
            onBlurNetto={(e) => onChange(e, 'netto', true)}
            onBlurCurrency={() => {}}
            onBlurVat={() => {}}
        />
    );
};
