import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const InstructionView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "InstructionView" */ 'src/components/instructionScreen/templates/InstructionView/InstructionView'
        )
);

export const InstructionMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Instrukcje');

    return (
        <div>
            <React.Suspense fallback>
                <InstructionView />
            </React.Suspense>
        </div>
    );
};
