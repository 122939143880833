import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiScreenColumnsConfiguration } from './types';

/**
 * Update information about columns pin and visibility
 */
export async function updateListScreenConfiguration(
    screen: string,
    columnConfig: ApiScreenColumnsConfiguration,
    isCondensedView: boolean
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        `api_react/src/listScreen/updateListScreenConfiguration.php?isCondensedView=${
            isCondensedView ? 1 : 0
        }`,
        'POST',
        {
            screen,
            columns: columnConfig
        }
    );

    //temporary use mock fixed value
    // return {
    //     message: 'Zaktualizowano',
    //     success: true,
    //     errorCode: null,
    //     data: null
    // };
}
