import React, { FC } from 'react';

import { FieldNumberObjectProps } from 'src/components/popupFormExtended/dynamicField/fieldNumber/types';
import { FieldNumberWithCalcMinuteHour } from 'src/components/popupFormExtended/dynamicField/fieldNumber/FIeldNumberWithCalcMinuteHour';
import { FieldNumberNormal } from 'src/components/popupFormExtended/dynamicField/fieldNumber/FieldNumberNormal';

export const FieldNumberObject: FC<FieldNumberObjectProps> = (props) => {
    const { dynamicFieldT } = props;
    if (dynamicFieldT.przeliczanie_minut_i_godzin === 't') {
        return <FieldNumberWithCalcMinuteHour {...props} />;
    }
    return <FieldNumberNormal {...props} />;
};
