import React, { FC } from 'react';
import { PermissionFunctionColumnProps } from './types';
import PermissionModuleLevel from '../permissionModuleLevel/PermissionModuleLevel';

const PermissionFunctionColumn: FC<PermissionFunctionColumnProps> = ({ func, ...props }) => {
    return (
        <ul style={{ padding: 0 }}>
            {func.modules.map((module) => (
                <PermissionModuleLevel
                    {...props}
                    key={module.id}
                    module={module}
                    functionId={func.id}
                />
            ))}
        </ul>
    );
};

export default PermissionFunctionColumn;
