import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { DatePicker, GetDate } from 'src/components/shared/atoms/datePicker/DatePicker';
import { InputTime } from 'src/components/shared/atoms/datePicker/InputTime';
import style from './style.module.css';

import { DynamicDateHoursProps } from './types';

export const DynamicDateHours: FC<DynamicDateHoursProps> = ({
    dynamicFieldT,
    field,
    fieldValue
}) => {
    const value = fieldValue.value;
    const theme = useTheme();
    return (
        <div className={style.wrapper}>
            <DatePicker
                dateInitial={value?.data}
                dateFromOuterToUpdate={value?.data}
                getDate={(data: GetDate) => {
                    field.onChange({
                        ...fieldValue,
                        value: {
                            ...fieldValue.value,
                            data: data.dateSelectedNormal
                        }
                    });
                }}
                labelDate={dynamicFieldT.nazwa}
                numberPanels={'three'}
                isCheckboxToday={false}
                isInputTime={false}
                sxContainerPanels={{ position: 'fixed', transform: 'translateY(10%)' }}
                portalEnabled={true}
            />

            <div className={style.ml}>
                <InputTime
                    label="godz. rozp."
                    type={'text'}
                    placeholder="gg:mm"
                    sxinputtime={{ width: '62px', color: theme.palette.text.input }}
                    maskChar={''}
                    value={value?.rozp || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange({
                            ...fieldValue,
                            value: {
                                ...fieldValue.value,
                                rozp: e.target.value
                            }
                        });
                    }}
                />
            </div>
            <div className={style.ml}>
                <InputTime
                    label="godz. zak."
                    type={'text'}
                    placeholder="gg:mm"
                    sxinputtime={{ width: '60px', color: theme.palette.text.input }}
                    maskChar={''}
                    value={value?.zak || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange({
                            ...fieldValue,
                            value: {
                                ...fieldValue.value,
                                zak: e.target.value
                            }
                        });
                    }}
                />
            </div>
        </div>
    );
};
