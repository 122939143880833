import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
    root: {
        background: 'rgba(99,99,99,.1)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5005
    }
}));

/**
 * @component
 * @category Components
 * @subcategory Shared
 * @description Bezstanowy komponent funkcyjny. Wyświetla animowany loader.
 * @returns {ReactComponent}
 */
const Spinner = () => {
    const classes = useStyles();
    return (
        <div className={classes.root} onMouseDown={(e) => e.stopPropagation()}>
            <CircularProgress color="primary" />
        </div>
    );
};

export default Spinner;
