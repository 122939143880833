import React, { FC } from 'react';

import { UIFieldCostsProps } from './types';
import styles from './UiFieldCosts.module.css';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { StaticHider } from 'src/components/shared/atoms/staticHider/StaticHider';

export const UIFieldCosts: FC<UIFieldCostsProps> = (props) => {
    const {
        value,
        opcje,
        onBlurInput,
        onChangeSelect,
        onChangeInput,
        uiFieldCommonProps,
        uiFieldSharedProps
    } = props;
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.value ?? 0}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    type="number"
                    alignTextRight={true}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                <StaticHider dynamicFieldT={uiFieldSharedProps.dynamicFieldT} type="waluta">
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.currency}
                        opcje={opcje}
                        onChange={onChangeSelect}
                    />
                </StaticHider>
            </div>
        </UiWrapper>
    );
};
