import { DynamicFieldT } from 'src/data/popupFormTypes';
import { FieldBasicListManyStateRHF } from 'src/data/fieldsReactHookForm';
import { FieldDependentFieldDefaultRHF } from 'src/components/form/molecules/fieldDependent/FieldDependentFieldDefaultRHF';
import {
    FieldDependentFunctionRHF,
    FieldDependentRHFProps,
    FieldDependentRowRHF,
    FieldDependentTempStateRHF
} from 'src/components/form/molecules/fieldDependent/types';

export type FieldDependentFieldBasicListManyRHFProps = FieldDependentRHFProps & {
    tempState: FieldDependentTempStateRHF;
    fieldWasTriggeredDynamicFieldT: DynamicFieldT;
    dependentFunctionDynamicType: FieldDependentFunctionRHF;
    fieldWasTriggered: FieldBasicListManyStateRHF;
    row?: FieldDependentRowRHF;
};
export class FieldDependentFieldBasicListManyRHF extends FieldDependentFieldDefaultRHF {
    fieldWasTriggeredWew: FieldBasicListManyStateRHF;
    constructor({
        dynamicFieldsT,
        formStateHandlers,
        tempState,
        fieldWasTriggeredDynamicFieldT,
        dependentFunctionDynamicType,
        fieldWasTriggered,
        row,
        staticData
    }: FieldDependentFieldBasicListManyRHFProps) {
        super({
            dynamicFieldsT,
            formStateHandlers,
            tempState,
            fieldWasTriggeredDynamicFieldT,
            dependentFunctionDynamicType,
            fieldWasTriggered,
            row,
            staticData
        });
        this.fieldWasTriggeredWew = fieldWasTriggered;
    }
    async zmienPole() {
        await this.checkAndChangeFieldChangeStateOfField();
    }

    private async checkAndChangeFieldChangeStateOfField() {
        let opcje = this.fieldWasTriggeredWew.basic_list_many.opcje ?? {};

        const data = await this.getValueToSetForChangeField();
        opcje = [{ id: '0', text: 'WSZYSTKIE' }, ...data.optionAutoComplete];
        const values = this.fieldWasTriggeredWew.basic_list_many.values.filter((item) => {
            if (opcje.find((item2) => item2.text === item.text)) {
                return true;
            }
            return false;
        });
        this.setTempState(this.fieldWasTriggeredCode, {
            basic_list_many: {
                opcje,
                values
            }
        });
    }
}
