import React, { ChangeEventHandler } from 'react';
import InputMask from 'react-input-mask';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { InputProps } from 'src/components/popupFormExtended/dynamicField/types';

type UiInputMaskProps = {
    label?: string;
    type: string;
    value: string;
    maskChar: string;
    sxinputtime?: SxProps<Theme> | undefined;
    placeholder?: string;
    archived?: boolean;
    disabledWew?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur: ChangeEventHandler<HTMLInputElement>;
    mask: string;
    inputProps?: InputProps;
};

export const UiInputMask = (props: UiInputMaskProps) => {
    return (
        <InputMask alwaysShowMask={false} {...props}>
            {(inputProps: InputMask) => (
                <UiInputNormalTwo
                    {...inputProps}
                    sxinput={{
                        ...props.sxinputtime,
                        input: {
                            '&::-webkit-input-placeholder': {
                                paddingLeft: '0px'
                            },
                            '&::-ms-input-placeholder': {
                                paddingLeft: '0px'
                            }
                        }
                    }}
                    onChange={props.onChange}
                />
            )}
        </InputMask>
    );
};
