import React, { FC } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import style from './style.module.css';
import { useFieldClasses } from 'src/hooks';
import { getTypeUrl } from 'src/utils';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { DynamicListForeignManyProps } from 'src/components/shared/atoms/dynamicListForeignMany/types';
import { AutocompleteOption } from 'src/data/uiTypes/autocompleteTypes';
import { DynamicLabel } from 'src/components/shared/atoms/dynamicLabel/DynamicLabel';
import { UiAutocompleteInputTwo } from 'src/components/shared/atoms/uiInputs/uiAutocompleteInputTwo/UiAutocompleteInputTwo';

export const DynamicListForeignMany: FC<DynamicListForeignManyProps> = ({
    dynamicFieldT,
    field,
    fieldValue
}) => {
    const value = fieldValue.value;
    const classes = useFieldClasses({ muiType: 'autocompleteOne' });
    const [inputValue, setInputValue] = React.useState('');

    const [options, setOptions] = React.useState(fieldValue.value.opcje);

    const onChange = (data: AutocompleteOption[]) => {
        const values = calculateValues(data);
        field.onChange({
            ...fieldValue,
            value: {
                ...fieldValue.value,
                values: values
            }
        });
    };

    const calculateValues = (newValues: AutocompleteOption[]) => {
        if (dynamicFieldT.wyszukiwanie_rozwijalna === 't') return [...newValues];

        if (newValues.findIndex((el) => el.text === 'WSZYSTKIE') !== -1) {
            return [...options];
        }
        if (fieldValue.value.values.findIndex((el) => el.id === '0') !== -1) return [];

        return [...newValues];
    };

    const getOptionsFromApi = (value: string) => {
        let valueSuperior: string | null = '';

        let params = {};
        if (dynamicFieldT.pole_nadrzedne_typ) {
            params =
                dynamicFieldT.pole_nadrzedne_typ === 'lista_obca'
                    ? {
                          q: valueSuperior,
                          typ: dynamicFieldT.pole_nadrzedne_tabela_opcji,
                          kod: dynamicFieldT.tabela_opcji,
                          pole: dynamicFieldT.id,
                          filtr: value
                      }
                    : {
                          q: valueSuperior,
                          typ: dynamicFieldT.pole_nadrzedne_typ,
                          kod: dynamicFieldT.tabela_opcji,
                          pole: dynamicFieldT.id,
                          filtr: value
                      };
        } else {
            params = {
                q: value,
                typ: dynamicFieldT.tabela_opcji,
                pole: dynamicFieldT.id
            };
        }
        const url = getTypeUrl({ field: dynamicFieldT });
        if (value != null && value.length > 0) {
            getFieldAdditionalData({
                url,
                params: params
            })
                .then((resp) => {
                    if (resp?.data) {
                        setOptionsWithAll(resp.data);
                    } else {
                        setOptionsWithAll([]);
                    }
                })
                .catch((err) => {});
        } else {
            setOptionsWithAll([]);
        }
    };

    const setOptionsWithAll = (options: AutocompleteOption[]) => {
        setOptions([{ id: '0', text: 'WSZYSTKIE' }, ...options]);
    };

    return (
        <div className={style.wrapper}>
            <DynamicLabel label={dynamicFieldT.nazwa} />
            <div className={style.box}>
                <Autocomplete
                    onInputChange={(_, value: string) => {
                        setInputValue(value);
                        getOptionsFromApi(value);
                    }}
                    multiple
                    popupIcon={''}
                    options={options || null}
                    value={fieldValue.value.values}
                    inputValue={inputValue}
                    getOptionLabel={(option: AutocompleteOption) => {
                        return option?.text;
                    }}
                    renderOption={(props, option: AutocompleteOption) => {
                        return (
                            <li {...props} key={option?.id}>
                                {option?.text}
                            </li>
                        );
                    }}
                    disablePortal={false}
                    onChange={(_, data: AutocompleteOption[]) => onChange(data)}
                    clearIcon={
                        value.values.length ? (
                            <ButtonCross
                                onClick={() => {
                                    field.onChange({
                                        ...fieldValue?.value,
                                        value: {
                                            ...fieldValue.value,
                                            values: []
                                        }
                                    });
                                }}
                                style={{
                                    top: '2px'
                                }}
                            />
                        ) : (
                            <></>
                        )
                    }
                    classes={{
                        option: classes.option,
                        listbox: classes.listbox,
                        paper: classes.paper,
                        popper: classes.popper
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => {
                        return <UiAutocompleteInputTwo params={params} />;
                    }}
                    filterOptions={(options) => {
                        const filtred = options.filter((item) => {
                            if (fieldValue.value.values.find((item2) => item2.id === item.id)) {
                                return false;
                            }
                            return true;
                        });
                        return filtred;
                    }}
                />
            </div>
        </div>
    );
};
