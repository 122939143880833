import React, { FC } from 'react';

import styles from './UiFieldPrice.module.css';
import { UIFieldPriceProps } from './types';

import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { fieldCostsOp, optionsVat } from 'src/constants/optionsForSelects';
import { fields } from 'src/constants/subtitles';
import { StaticHider } from 'src/components/shared/atoms/staticHider/StaticHider';

export const UIFieldPrice: FC<UIFieldPriceProps> = (props) => {
    const {
        value,
        onChangeVat,
        onChangeBrutto,
        onChangeNetto,
        onChangeCurrency,
        onBlurBrutto,
        onBlurNetto,
        onBlurCurrency,
        onBlurVat,
        uiFieldCommonProps,
        uiFieldSharedProps
    } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.netto}
                    onChange={onChangeNetto}
                    onBlur={onBlurNetto}
                    alignTextRight={true}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                <p className={styles.p}>{fields.fieldPrice.one}</p>
                <StaticHider dynamicFieldT={uiFieldSharedProps.dynamicFieldT} type="waluta">
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.waluta}
                        opcje={fieldCostsOp}
                        onChange={onChangeCurrency}
                        onBlur={onBlurCurrency}
                    />
                </StaticHider>
                <StaticHider dynamicFieldT={uiFieldSharedProps.dynamicFieldT} type="vat">
                    <p className={styles.p} style={{ whiteSpace: 'nowrap' }}>
                        {fields.fieldPrice.two}
                    </p>
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.vat}
                        options={optionsVat}
                        onChange={onChangeVat}
                        onBlur={onBlurVat}
                    />
                </StaticHider>
                <p className={styles.p}>{fields.fieldPrice.three}</p>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.brutto}
                    onChange={onChangeBrutto}
                    onBlur={onBlurBrutto}
                    alignTextRight={true}
                />
                <p className={styles.p}>{fields.fieldPrice.four}</p>
            </div>
        </UiWrapper>
    );
};
