import { NavigateFunction } from 'react-router-dom';
import { BrowserPageLocationState, ConvertableToParams, PageLocationState } from 'src/data/types';

/**
 * remove query params from actual url address (with help of window.history.replaceState)
 */
export const moveQueryParamsToLocationState = <
    T extends BrowserPageLocationState<
        PageLocationState<ConvertableToParams, ConvertableToParams, any>
    >
>(
    paramsToRemove: string[],
    store: T['store'],
    navigate: NavigateFunction
) => {
    const params = new URLSearchParams(window.location.search);
    const legacyParamObj: { [key: string]: string } = {};

    paramsToRemove.forEach((paramName) => {
        const paramValue = params.get(paramName);
        if (paramValue !== null) legacyParamObj[paramName] = paramValue;
        params.delete(paramName);
    });

    if (Object.keys(legacyParamObj).length !== 0) {
        // change params and change state
        navigate(`${window.location.pathname.replace(window.basename ?? '', '')}?${params}`, {
            state: { store, legacyParams: legacyParamObj },
            replace: true
        });
    }
};
