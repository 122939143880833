import { useEffect, useState } from 'react';

import { generateId } from '../../../utils/index';

/**
 * hook generuje id dla danego labelu, robi to tylko przy pierwszym renderze komponentu, lub gdy zmieni się label
 */
export function useGeneratedID(label: string) {
    const [id, setId] = useState<string>(null!);
    useEffect(() => {
        setId(generateId(label));
    }, [label]);
    return id;
}
