import React, { FC } from 'react';
import { PermissionTabsLevelProps } from './types';
import PermissionsLevelHeader from '../../molecules/permissionsLevelHeader/PermissionsLevelHeader';
import PermissionsField from '../../molecules/permissionsField/PermissionsField';
import PermissionsItemHeader from '../../molecules/permissionsItemHeader/PermissionsItemHeader';
import PermissionSectionsLevel from '../permissionSectionsLevel/PermissionSectionsLevel';
import { PERIMISSION_LEVEL_INDENT } from 'src/constants/permissionsScreen';

const PermissionTabsLevel: FC<PermissionTabsLevelProps> = ({
    tabs,
    screenId,
    unfoldedState,
    toggleFolding,
    ...props
}) => {
    const tabsKey = `tabs_${screenId}`;

    return (
        <div style={{ marginLeft: PERIMISSION_LEVEL_INDENT }}>
            <PermissionsLevelHeader
                folded={unfoldedState[tabsKey]}
                onClick={() => toggleFolding(tabsKey)}
                title="Zakładki"
            />
            {unfoldedState[tabsKey] &&
                tabs.map((tab) => (
                    <ul key={tab.id}>
                        <PermissionsItemHeader text={tab.name} />
                        <PermissionsField item={tab} type="visible" level="tab" {...props} />
                        <PermissionSectionsLevel
                            {...props}
                            tabId={tab.id}
                            sections={tab.sections}
                            unfoldedState={unfoldedState}
                            toggleFolding={toggleFolding}
                        />
                    </ul>
                ))}
        </div>
    );
};

export default PermissionTabsLevel;
