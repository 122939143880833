import React, { FC, useState } from 'react';
import { Box, Button, Collapse } from '@mui/material';
import ShortcutsPinSectionScreenList from 'src/components/menu/organisms/shortcutsPinSecionScreenList/ShortcutsPinSectionScreenList';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { updateMenuPins } from 'src/store/src/menu';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import { ModuleMenuData } from 'src/store/src/menu/menu/types';
import { ShortcutsPinSectionModuleListProps } from 'src/components/menu/organisms/shortcutsPinSection/types';
import { topMenu } from 'src/constants/subtitles';
import { updateShortcuts } from 'src/api/src/menu/updateShortcuts';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import Icon from 'src/assets/arrow.svg';
import styles from './ShortcutsPinSectionModuleList.module.css';
import { setSpinner, closeSpinner } from 'src/store/src/general/system/systemSlice';

const ShortcutsPinSectionModuleList: FC<ShortcutsPinSectionModuleListProps> = ({ onClose }) => {
    const modules = useTypedSelector((state) => state.menu.menuStructureData.modules);
    const dispatchRedux = useAppDispatch();
    const [listModule, setListModule] = useState<ModuleMenuData[]>(
        MenuStateManager.filterVisibleOnlyWhenEditing(modules)
    );
    const [unfoldedModule, setUnfoldedModule] = useState('');
    const updatePinnedScreensInListModule_localState = (screenToUpdate: number) => {
        const updatedListModule = listModule.map((module) => {
            return {
                ...module,
                screens: module.screens.map((screen) => {
                    if (screen.id === screenToUpdate) {
                        return {
                            ...screen,
                            isSelectedToShortcut: !screen.isSelectedToShortcut
                        };
                    }
                    return screen;
                })
            };
        });
        setListModule(updatedListModule);
    };

    const updatePinnedScreensInListModule_globalState = async () => {
        // dispatchRedux(setSpinner());
        const { pinnedIds } = MenuStateManager.getPinnedScreensIds(listModule);
        const response = await updateShortcuts(pinnedIds);
        if (response?.success) {
            dispatchRedux(updateMenuPins(pinnedIds));
        }
        //dispatchRedux(closeSpinner());
        onClose();
    };

    const unfoldModule = (module: string) => {
        if (module === unfoldedModule) setUnfoldedModule('');
        else setUnfoldedModule(module);
    };
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            border={1}
            padding={3}
            sx={{
                borderColor: (theme) => theme.palette.primary.main,
                borderRadius: '4px',
                color: (theme) => theme.palette.primary.main,
                background: (theme) => theme.palette.background.default
            }}>
            {listModule.map((item) => {
                const isUnfolded = unfoldedModule === item.name;
                return (
                    <div key={item.id} className={styles.listModuleWrapper}>
                        <div
                            onClick={() => unfoldModule(item.name)}
                            className={styles.listModuleWrapperInner}>
                            <ThemeCompatibleIcon
                                SvgComponent={Icon}
                                sx={{
                                    fontSize: '0.8rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    transform: `${
                                        isUnfolded ? 'rotate(0.75turn)' : 'rotate(0.5turn)'
                                    }`,
                                    marginTop: '3px'
                                }}
                            />
                            <p className={styles.p}>{item.name}</p>
                        </div>
                        <Collapse
                            sx={{
                                padding: 0
                            }}
                            in={isUnfolded}
                            timeout={500}>
                            <ShortcutsPinSectionScreenList
                                screens={item.screens}
                                updatePinnedScreensInListModule={
                                    updatePinnedScreensInListModule_localState
                                }
                            />
                        </Collapse>
                    </div>
                );
            })}
            <div className={styles.buttonWrapper}>
                <Button
                    variant="contained"
                    sx={{
                        width: '100%',
                        color: (theme) => theme.palette.background.default,
                        padding: '4px'
                    }}
                    onClick={updatePinnedScreensInListModule_globalState}>
                    <p className={styles.buttonText}>{topMenu.button.pinSection}</p>
                </Button>
            </div>
        </Box>
    );
};

export default ShortcutsPinSectionModuleList;
