import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { budgetSingleFields } from 'src/constants/budget/budgetSingleFields';
import { createKeyFieldBudget, createKeySumBudget } from 'src/utils';
import {
    BugdetMonthLevel,
    BugdetSettlementMethodLevel,
    BugdetCaseLevel,
    MethodLevelType,
    MonthLevelType
} from 'src/store/src/budget/budget/types';

type BudgetSetterStateMethodProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};

export class BudgetFieldAdditionalSetter extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetSetterStateMethodProps) {
        super({ budget, operationalState });
    }

    setForCase({
        method,
        month,
        caseItem,
        methodLevelType,
        monthLevelType
    }: {
        method: BugdetSettlementMethodLevel;
        month: BugdetMonthLevel;
        caseItem: BugdetCaseLevel;
        monthLevelType: MonthLevelType;
        methodLevelType: MethodLevelType;
    }) {
        const field = budgetSingleFields['invoice_all_in_case'];
        let isCheked = false;
        const arr =
            this.operationalState.dataToCalcAdditionalField.invoiceAllInCase?.[month.id]?.[
                caseItem.id
            ];
        if (Array.isArray(arr)) {
            const arrFilteredOnlyCheckedObject = arr.filter((item) => item);
            if (arrFilteredOnlyCheckedObject.length === arr.length) {
                isCheked = true;
            }
        }
        const { keySum } = createKeySumBudget({
            fieldId: field.id,
            methodId: method.id,
            monthId: month.id,
            methodLevelType,
            monthLevelTypeId: caseItem.id,
            monthLevelType
        });
        const { keyField } = createKeyFieldBudget({
            name: field.name,
            objectId: caseItem.id,
            methodId: method.id,
            monthDate: month.data_rozp
        });
        this.operationalState.fieldsTemp[keyField] = {
            checkbox: isCheked,
            keyId: keySum,
            keyReset: 1
        };
    }
}
