import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { useAppDispatch } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Button } from '@mui/material';
import ContentScroll from 'src/components/shared/molecules/contentScroll/ContentScroll';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import styles from './PopupContentInfoAlertUpdateMessage.module.css';
import { AlertTemplateUpdatesMessage } from 'src/store/src/general/alerts/types';
import { clearFirst } from 'src/store/src/general';

type PopupContentAlertUpdateMessageProps = BasePopupContentProps & {
    alertData: AlertTemplateUpdatesMessage;
};

export const PopupContentInfoAlertUpdateMessage: FC<PopupContentAlertUpdateMessageProps> = ({
    popup,
    alertData
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const onOkButtonClick = () => {
        dispatch(clearFirst());
        dispatch(closePopup(popup.key));
    };

    return (
        <PopupTemplateThird title={alertData.data.title}>
            <div className={styles.wrapper}>
                <ContentScroll maxWidth={800} maxHeight={500}>
                    <div className={styles.content}>
                        <p
                            style={{
                                color: theme.palette.primary.main,
                                fontSize: '14px'
                            }}
                            dangerouslySetInnerHTML={{ __html: alertData.data.textToinject }}></p>
                    </div>
                </ContentScroll>
                <div className={styles.box}>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={onOkButtonClick}>
                        <p>{globalPopup.contentInfo.button.yes}</p>
                    </Button>
                </div>
            </div>
        </PopupTemplateThird>
    );
};
