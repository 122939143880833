import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiRelationCardsOrder } from './types';

/**
 * Update information about columns pin and visibility
 */
export async function updateRelationCardsOrder(
    screen: string,
    relationsOrder: ApiRelationCardsOrder[]
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        `api_react/src/objectScreen/updateRelationCardsOrder.php?screen=${screen}`,
        'POST',
        relationsOrder
    );

    //temporary use mock fixed value
    // return {
    //     message: 'Zaktualizowano',
    //     success: true,
    //     errorCode: null,
    //     data: null
    // };
}
