import React, { FC, useEffect } from 'react';

interface UseDynamicSVGImportOptions {
    onCompleted?: (
        name: string,
        SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> | undefined
    ) => void;
    onError?: (err: Error) => void;
    onStart?: () => void;
}

/**
 * Dynamic import Svg from /src/assets folder by given name, set that component to ref @iconRef
 * @param fileName svg file name or path from assets without extension
 * @param iconRef ref to wchich load imported component
 * @param options event handlers
 */
export function useDynamicSVGImport(
    fileName: string,
    iconRef: React.MutableRefObject<React.FC<React.SVGProps<SVGSVGElement>> | undefined>,
    options: UseDynamicSVGImportOptions = {}
) {
    const { onCompleted, onError, onStart } = options;
    useEffect(() => {
        onStart?.();
        const importIcon = async (): Promise<void> => {
            try {
                iconRef.current = (await import(`src/assets/${fileName}`)).default;
            } catch (err: any) {
                iconRef.current = undefined;
                await onError?.(err);
            } finally {
                onCompleted?.(fileName, iconRef.current);
            }
        };
        importIcon();
    }, [fileName, onCompleted, onError, onStart, iconRef]);
}
