import React, { FC, forwardRef, useRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import InputB from '@mui/material/InputBase';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import CallendarIcon from 'src/assets/ikona_kalendarz.svg';
import { useTheme } from '@mui/material/styles';
import { UiInputNormalProps } from '../types';
import { UiInputElWrapper } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElWrapper/UiInputElWrapper';
import { UiInputElLabel } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElLabel/UiInputElLabel';
import { sxInputV1Default, sxThemeCompatibleIconV1Default } from '../sxDefault';

const UiInputNormalComp = forwardRef<HTMLInputElement, UiInputNormalProps>((props, inputRef) => {
    const { typeIcon = 'svg', type = 'text' } = props;
    const theme = useTheme();

    const sxinput = {
        border: `solid 1px ${theme.palette.primary.main}`,
        ...sxInputV1Default,
        color: theme.palette.text.input,
        ...props.sxinput
    };
    const sxthemecompatibleicon = {
        ...sxThemeCompatibleIconV1Default,
        ...props.sxthemecompatibleicon
    };
    if (props.isicon === 'true') {
        return (
            <UiInputElWrapper {...props}>
                {props.label && <UiInputElLabel {...props} />}
                <InputB
                    inputRef={inputRef}
                    sx={sxinput}
                    style={{
                        WebkitAppearance: 'none'
                    }}
                    type={type}
                    endAdornment={
                        typeIcon === 'svg' ? (
                            <InputAdornment position="end" sx={props.sxinputadornment}>
                                <ThemeCompatibleIcon
                                    SvgComponent={props.icon ? props.icon : CallendarIcon}
                                    fontSize={'medium'}
                                    sx={sxthemecompatibleicon}
                                />
                            </InputAdornment>
                        ) : (
                            <InputAdornment position="end" sx={props.sxinputadornment}>
                                {props.iconComponent}
                            </InputAdornment>
                        )
                    }
                    {...props}
                />
            </UiInputElWrapper>
        );
    }
    return (
        <UiInputElWrapper {...props}>
            {props.label && <UiInputElLabel {...props} />}
            <InputB sx={sxinput} inputRef={inputRef} {...props} type={type} />
        </UiInputElWrapper>
    );
});
UiInputNormalComp.displayName = 'UiInputNormal';
export const UiInputNormal = UiInputNormalComp;
