import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NAV_BACK_LEVEL } from 'src/constants';
import { legacyCompatibleNavigateBack, legacyCompatibleNavigateForward } from 'src/utils';
import { useSearchParam, useUpdateObjectsData } from 'src/hooks';
import { useAppDispatch } from 'src/store';
import { fetchAndLoadInitialListScreenData } from 'src/store/src/listScreen';
import { fetchAndLoadUtilitiesBarActions } from 'src/store/src/utilitiesBar/utilitiesBar/utilitiesbarSlice';
import { NavigateActionProps } from 'src/components/shared/molecules/actionType/navigateAction/types';

const NavigateAction: FC<NavigateActionProps> = ({ children, behaviour }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const screen = useSearchParam('obiekt');
    const location = useLocation();
    const updateObjects = useUpdateObjectsData();

    const refreshData = () => {
        if (screen) {
            switch (location.pathname) {
                case '/lista':
                case '/lista-drzewo':
                case '/lista-statusy':
                    dispatch(fetchAndLoadInitialListScreenData(screen));
                    updateObjects({
                        saveLocationState: false,
                        checkViewInHook: true,
                        forceRefreshData: true
                    });
                    dispatch(fetchAndLoadUtilitiesBarActions(screen, 'ekran_obiektow'));
                    break;
                default:
                    console.error('NavigateAction: refresh unsupported for current pathname');
                    break;
            }
        }
    };

    const handleNavigate = () => {
        switch (behaviour.data.target) {
            case 'back':
                legacyCompatibleNavigateBack(navigate);
                break;
            case 'forward':
                legacyCompatibleNavigateForward(navigate);
                break;
            case 'refresh':
                window.location.reload();
                // refreshData();
                break;
            default:
                break;
        }
    };

    // return conditionaly because forward action shouldn't be show when can't perform forward
    // navigation
    return behaviour.data.target === 'forward' &&
        parseInt(sessionStorage.getItem(NAV_BACK_LEVEL) ?? '') <= 0 ? (
        <div></div>
    ) : (
        <div onClick={() => handleNavigate()}>{children}</div>
    );
};

export default NavigateAction;
