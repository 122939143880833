import React from 'react';
import ButtonTwo from '@mui/material/Button';
import TimerIcon from '@material-ui/icons/Timer';
import { Divider } from '@mui/material';

/**
 * Bezstanowy komponent funkcyjny. Wyświetla przycisk który pozwala dodać stoper
 * @component
 * @category Components
 * @subcategory Timers
 * @param {object} props - Propsy komponentu
 * @param {Function} props.clickHandler - akcja wywoływana po kliknięciu w przycisk
 * @returns {ReactComponent}
 * @see [Components/Timers/Timers]{@link Timers}
 */
const AddTimer = ({ clickHandler }) => {
    return (
        <div
            style={{
                width: '100%',
                textAlign: 'center'
            }}>
            <ButtonTwo
                color="primary"
                variant="outlined"
                size="large"
                sx={{ fontSize: 21, margin: '32px', padding: '0px 26px' }}
                onClick={clickHandler}
                endIcon={<TimerIcon />}>
                Dodaj
            </ButtonTwo>
            <Divider />
        </div>
    );
};

export default AddTimer;
