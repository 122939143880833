import React, { FC, useCallback } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldRelationshipTypeAutocompleteOption, FieldRelationshipTypeProps } from './types';
import { UIFieldRelationshipType } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipType/UIFieldRelationshipType';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { clearTextFromApiForOptionAutocomplete, createFormData, getTypeUrl } from 'src/utils';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { BroadcastAddBackPayload } from '../../types';
import { useAddBackBroadcastChannel } from 'src/hooks/src/popupFormExtended/useAddBackBroadcastChannel';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { FieldRelationshipTypeBasicTypeValue } from 'src/data/fieldsFormType';
import { generateAddBackUrlTwo } from 'src/utils/src/popupFormExtended/generateAddBackUrlTwo';
import { EventBus } from 'src/utils/src/shared/EventBus';

const filter = createFilterOptions<FieldRelationshipTypeAutocompleteOption>();

export const FieldRelationshipTypeObject: FC<FieldRelationshipTypeProps> = ({
    field,
    uiFieldCommonProps,
    popupFormExtendedData,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    prefix,
    relationId
}) => {
    const [inputValue, setInputValue] = React.useState(field.value?.text ?? '');
    const [optionToChoose, setOptionToChoose] = React.useState<
        FieldRelationshipTypeAutocompleteOption[]
    >(
        field.value?.value
            ? [
                  {
                      id: field.value?.value,
                      text: field.value?.text
                  }
              ]
            : []
    );

    const startAddBackBroadcastChannel = useAddBackBroadcastChannel(dynamicFieldT.kod);

    const url = getTypeUrl({ field: dynamicFieldT });

    const getOptionsFromApi = useCallback(
        (value: string) => {
            if (value != null && value.length > 0) {
                const params = {
                    q: value,
                    typ: field.value.select,
                    id_pola_z_warunkiem: dynamicFieldT.id
                };
                const formData = createFormData({ type: 'object', data: params });
                getFieldAdditionalData({
                    url,
                    params: params,
                    body: formData
                })
                    .then((res) => {
                        if (res.data) {
                            // setOptionToChoose(res.data);
                            setOptionToChoose(clearTextFromApiForOptionAutocomplete(res.data));
                        } else {
                            setOptionToChoose([]);
                        }
                    })
                    .catch((err) => {
                        setOptionToChoose([]);
                    });
            } else {
                setOptionToChoose([]);
            }
        },
        [dynamicFieldT.id, field.value.select, url]
    );

    const onBlur = (e: React.FocusEvent<any>) => {};

    const setFieldValue = useCallback(
        (newValue: FieldRelationshipTypeBasicTypeValue) => {
            const newField = {
                ...field,
                value: newValue
            };
            dispatch({
                type: 'powiazanie_typ',
                payload: {
                    code: keyOfFieldInState,
                    value: newField
                }
            });
            EventBus.$emit('1');
            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: newField,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        },
        [
            dispatch,
            dynamicFieldT,
            field,
            keyOfFieldInState,
            popupFormExtendedData,
            prefix,
            relationId
        ]
    );

    const handleAddNewObject = useCallback(() => {
        window.open(generateAddBackUrlTwo(dynamicFieldT.kod, field.value.select));

        startAddBackBroadcastChannel((event: MessageEvent<BroadcastAddBackPayload>) => {
            setFieldValue({
                ...field.value,
                value: event.data.id,
                text: event.data.name
            });
            setInputValue(event.data.name);
            getOptionsFromApi(event.data.name);
        });
    }, [dynamicFieldT, field, getOptionsFromApi, setFieldValue, startAddBackBroadcastChannel]);

    const onChangeAutocomplete = useCallback(
        (_: React.SyntheticEvent<Element, Event>, newValue: { id: string; text: string }) => {
            if (newValue) {
                if (newValue.id === 'nowy') {
                    handleAddNewObject();
                    // to prevent have 'nowy' value in field
                    return;
                } else {
                    setFieldValue({
                        ...field.value,
                        value: newValue.id,
                        text: newValue.text
                    });
                }
            } else {
                dispatch({
                    type: 'powiazanie_typ',
                    payload: {
                        code: keyOfFieldInState,
                        value: {
                            ...field,
                            value: {
                                ...field.value,
                                value: null,
                                text: ''
                            }
                        }
                    }
                });
            }
        },
        [dispatch, keyOfFieldInState, field, handleAddNewObject, setFieldValue]
    );

    React.useEffect(() => {
        if (field.value.text !== inputValue) {
            setInputValue(field.value.text ?? '');
        }
    }, [field.value.text]);

    const onChangeSelect = useCallback(
        (e, data) => {
            dispatch({
                type: 'powiazanie_typ',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: {
                            ...field.value,
                            select: e.target.value,
                            type: data.isEmptyValue ? '' : e.target.value,
                            isEmptyValue: data.isEmptyValue,
                            // in order to clear selected item when changed item type
                            value: '',
                            text: ''
                        }
                    }
                }
            });
            setOptionToChoose([]);
        },
        [dispatch, keyOfFieldInState, field]
    );

    return (
        <UIFieldRelationshipType
            onInputChangeAutocomplete={(_, value: string, reason) => {
                if (reason === 'input') {
                    if (field.value.value && value !== field.value.text) {
                        setFieldValue({
                            ...field.value,
                            value: null,
                            text: value
                        });
                    }

                    if (value !== 'Dodaj...') {
                        setInputValue(value);
                        getOptionsFromApi(value);
                    }
                }
            }}
            onBlur={onBlur}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            opcjeSelect={dynamicFieldT.opcje ? dynamicFieldT.opcje : {}}
            inputValueAutocomplete={inputValue}
            onChangeAutocomplete={onChangeAutocomplete}
            onChangeSelect={onChangeSelect}
            onClearAutocomplete={() => {
                setInputValue('');
            }}
            value={field.value}
            optionsAutocomplete={optionToChoose}
            autocompleteFilterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (dynamicFieldT.dodanie_nowego === 't')
                    filtered.push({ id: 'nowy', text: 'Dodaj...' });

                return filtered;
            }}
        />
    );
};
