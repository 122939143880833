import React from 'react';
import { useTypedSelector } from 'src/store';

export function useChoosenMethod() {
    const settlementMethods = useTypedSelector((state) => state.budget.settlementMethods);
    const settlementMethodVisibleId = useTypedSelector(
        (state) => state.budget.settlementMethodVisibleId
    );
    return React.useMemo(() => {
        return settlementMethods?.find(
            (settlementMethod) => settlementMethod.id === settlementMethodVisibleId
        );
    }, [settlementMethods, settlementMethodVisibleId]);
}
