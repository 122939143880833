import React, { FC } from 'react';

import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';
import { useLoadInitialMessageScreenData } from 'src/hooks/src/messageScreen/useLoadInitialMessageScreenData';

const MessageScreenView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "CreatingScreenView" */ 'src/components/messageScreen/templates/messsageView/MessageView'
        )
);

export const MessageMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Wiadomość');
    useLoadInitialMessageScreenData();
    return (
        <div>
            <React.Suspense fallback>
                <MessageScreenView />
            </React.Suspense>
        </div>
    );
};
