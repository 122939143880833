import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export { massBillSlice } from '../../rootReducer';

export {
    setInitBudgetData,
    setIsMounting,
    clearState,
    setUpdateClient,
    setUpdateWorker,
    setUpdateParams
} from './massBill/massBillSlice';
