import { FieldShared } from 'src/components/popupFormExtended/dynamicField/types';
import { DynamicFieldT, PopupFormRelatedObject, RelatedObjectData } from 'src/data/popupFormTypes';
import { assignSumKey } from 'src/utils/src/shared/assignSumKey';
import { calcIsHiddenOfField } from 'src/utils/src/shared/calcIsHiddenOfField';
import { setIsForeignOfField } from 'src/utils/src/shared/setIsForeignOfField';
import { setIsModifyOfField } from 'src/utils/src/shared/setIsModifyOfField';

export function createSharedPropertiesForFieldState({
    field,
    fieldKey,
    relation,
    relatedObjectData,
    startFieldKey,
    usedForAddingObject
}: {
    field: DynamicFieldT;
    fieldKey: string;
    usedForAddingObject: boolean;
    relation?: PopupFormRelatedObject;
    relatedObjectData?: RelatedObjectData;
    startFieldKey?: string;
}) {
    const hidden = calcIsHiddenOfField(field, usedForAddingObject);
    const modify = setIsModifyOfField(
        field,
        usedForAddingObject,
        relatedObjectData ? { edit: relatedObjectData.editable } : undefined
    );
    const sharedProperties: FieldShared = {
        isForeign: setIsForeignOfField(field),
        hidden: hidden,
        initialHidden: hidden,
        isModify: modify,
        initialIsModify: modify,
        wielkosc: 1,
        id: field.id,
        id_zakladki: relation ? relation.tabId : field.id_zakladki,
        id_sekcji: field.id_sekcji,
        kod: field.kod,
        fieldKey,
        startFieldKey,
        relationId: relation?.id ?? '',
        relatedObjectId: relatedObjectData?.id,
        isMandatory: field?.obowiazkowe === 't',
        mandatoryAlternative: field?.obowiazkowe_alternatywne
            ? field.obowiazkowe_alternatywne.split(',')
            : [],
        name: field.nazwa,
        isError: false,
        isValid: true,
        historyComments: null,
        isAddExtend: field.isAddExtend,
        placement: field.placement,
        sumKey: assignSumKey({ field, relation })
    };
    return sharedProperties;
}
