import React, { FC } from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';

import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import styles from './UiFieldListForeign.module.css';
import { UIFieldListForeignProps } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { useFieldClasses } from 'src/hooks';
import { UiAutocompleteInputThree } from 'src/components/shared/atoms/uiInputs/uiAutocompleteInputThree/UiAutocompleteInputThree';

export const UIFieldListForeign: FC<UIFieldListForeignProps> = (props) => {
    const {
        value,
        onChange,
        uiFieldCommonProps,
        uiFieldSharedProps,
        onBlur,
        onChangeAutocomplete,
        onInputChangeAutocomplete,
        optionsAutocomplete,
        onClearAutocomplete,
        inputValueAutocomplete,
        wyszukiwanie_rozwijalna,
        autocompleteFilterOptions,
        options
    } = props;
    const { disabled } = uiFieldCommonProps;

    const classes = useFieldClasses({ muiType: 'autocompleteOne' });
    const width = uiFieldCommonProps?.maxWidth ? uiFieldCommonProps?.maxWidth : 460;
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                {wyszukiwanie_rozwijalna === 't' ? (
                    <Autocomplete
                        freeSolo
                        onInputChange={onInputChangeAutocomplete}
                        onBlur={(e) => onBlur(e)}
                        options={optionsAutocomplete || null}
                        value={{
                            id: value.value,
                            text: inputValueAutocomplete
                        }}
                        inputValue={inputValueAutocomplete}
                        getOptionLabel={(option: any) => {
                            return option?.text;
                        }}
                        // to set proper key in case of duplicated texts
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.text}
                                </li>
                            );
                        }}
                        filterOptions={autocompleteFilterOptions}
                        disablePortal={false}
                        onChange={onChangeAutocomplete}
                        clearIcon={
                            value.text ? (
                                <ButtonCross
                                    onClick={onClearAutocomplete}
                                    style={{
                                        top: '2px'
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                        disabled={disabled}
                        classes={{
                            option: classes.option,
                            listbox: classes.listbox,
                            paper: classes.paper,
                            popper: classes.popper
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => {
                            return (
                                <UiAutocompleteInputThree
                                    {...uiFieldCommonProps}
                                    params={params}
                                    inputProps={{
                                        ...params.inputProps,
                                        ...uiFieldSharedProps.inputProps
                                    }}
                                />
                            );
                        }}
                    />
                ) : (
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.value}
                        options={options}
                        onChange={onChange}
                        sxinput={{
                            maxWidth:
                                uiFieldSharedProps.dynamicFieldT.kolumna_w_edycji === '0'
                                    ? width * 2 + 30 + 'px'
                                    : width + 'px'
                        }}
                        inputProps={uiFieldSharedProps.inputProps}
                    />
                )}
            </div>
        </UiWrapper>
    );
};
