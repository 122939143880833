import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@mui/icons-material/Pause';
import Tooltip from '@material-ui/core/Tooltip';
import { Zoom } from '@mui/material';

const useStyles = makeStyles(({ palette, spacing }) => ({
    timer_timer_btn: {
        width: spacing(5),
        height: spacing(5),
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    timer_timer_btn_icon: {
        width: spacing(4.5),
        height: spacing(4.5),
        color: palette.primary.main
    }
}));

const PauseBtn = (props) => {
    const classes = useStyles();

    const { id, pause } = props;

    return (
        <Tooltip
            arrow
            TransitionComponent={Zoom}
            className={classes.timer_btn_tooltip}
            title="pauza">
            <IconButton className={classes.timer_timer_btn} onClick={() => pause(id)}>
                <PauseIcon
                    sx={{
                        marginTop: '2px',
                        color: (theme) => theme.palette.primary.main,
                        fontSize: 45
                    }}
                />
            </IconButton>
        </Tooltip>
    );
};

export default PauseBtn;
