import React, { FC } from 'react';

import { UiConfigOrderListItemWrapperProps } from './types';
import styles from './styles.module.css';

export const UiConfigOrderListItemWrapper: FC<UiConfigOrderListItemWrapperProps> = ({
    children,
    style,
    i,
    title
}) => {
    return (
        <div className={styles.wrapper} style={style}>
            <p className={styles.p}>
                {i}. {title}
            </p>
            <div></div>
            {children}
        </div>
    );
};
