import { TemplateMainItem } from 'src/utils/src/shared/TemplateMainItem';

export class TemplateGlobalMainManager {
    checkIsTemplateExist({ str, regex }: { str: string; regex: RegExp }) {
        if (typeof str !== 'string') return null;
        return str.match(regex);
    }
    getTemplateItem(str?: string | null) {
        return new TemplateMainItem({ itemStr: str ? str : '' });
    }
    replaceContent({
        contentToReplace,
        templateMainItem,
        temporaryAllString
    }: {
        contentToReplace?: string | null;
        templateMainItem: TemplateMainItem;
        temporaryAllString: string;
    }) {
        const contToReplace = contentToReplace ? contentToReplace : '';
        if (contentToReplace === undefined) console.error('replaceContent - brak danych');
        return temporaryAllString.replace(templateMainItem.contentClearAll, contToReplace);
    }
}
