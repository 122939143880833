import { DatePickerState } from 'src/components/shared/atoms/datePicker/reducer';

type DatePickerPanelProps = {
    date: Date;
    dateSelected: Date | null;
    changer: number;
};

export type Day = {
    dayOfMonth: number;
    selected: boolean;
    isToday: boolean;
    isGhost: boolean;
    dayOfWeek: number;
};

export class DatePickerPanel {
    date: Date;
    dateSelected: Date | null;
    isDateSelected: boolean;
    daySelected: number;
    yearSelected: number;
    monthSelected: number;
    dayToday: number;
    yearToday: number;
    monthToday: number;
    year: number;
    month: number;
    days: Day[];
    ghostDays: Day[];
    daysInMonth: number;
    changer: number;
    constructor({ date, dateSelected, changer }: DatePickerPanelProps) {
        this.date = date;
        this.dateSelected = dateSelected;
        this.changer = this.setChanger(changer);
        this.isDateSelected = false;
        this.daySelected = 0;
        this.yearSelected = 0;
        this.monthSelected = 0;
        this.dayToday = new Date().getDate();
        this.yearToday = new Date().getFullYear();
        this.monthToday = new Date().getMonth();
        this.year = this.setYear(date, changer);
        this.month = this.setMonth(date, changer);
        this.daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
        this.days = [];
        this.ghostDays = [];
        this.checkAndSetDateSelected();
        this.createGhostDays();
        this.createDays();
    }

    checkAndSetDateSelected() {
        if (this.dateSelected) {
            this.daySelected = this.dateSelected.getDate();
            this.yearSelected = this.dateSelected.getFullYear();
            this.monthSelected = this.dateSelected.getMonth();
            this.isDateSelected = true;
        }
    }

    setMonth(date: Date, changer: number) {
        const month = date.getMonth();
        if (month === 0 && changer === -1) {
            return 11;
        }
        if (month === 11 && changer === 1) {
            return 0;
        }
        return month + changer;
    }
    setYear(date: Date, changer: number) {
        const month = date.getMonth();
        const year = date.getFullYear();
        if (month === 0 && changer === -1) {
            return year - 1;
        }
        if (month === 11 && changer === 1) {
            return year + 1;
        }
        return year;
    }

    setChanger(changer: number) {
        switch (changer) {
            case -1:
                return 1;
            case 1:
                return -1;
            default:
                return 0;
        }
    }

    createGhostDays() {
        const firstDayNumberDayOfWeek = this.getNumberOfDayOfWeekForFirstDayMonth(
            this.year,
            this.month,
            1
        );
        const numberGhost = firstDayNumberDayOfWeek === 0 ? 6 : firstDayNumberDayOfWeek - 1;
        for (let index = 0; index < numberGhost; index++) {
            this.ghostDays.push({
                dayOfMonth: -1,
                selected: false,
                isToday: false,
                isGhost: true,
                dayOfWeek: -1
            });
        }
    }

    createDays() {
        const setSelected = this.yearSelected === this.year && this.month === this.monthSelected;
        const setToday = this.yearToday === this.year && this.month === this.monthToday;
        this.days = [...this.ghostDays];
        for (let index = 1; index <= this.daysInMonth; index++) {
            this.days.push({
                dayOfMonth: index,
                selected:
                    setSelected && this.isDateSelected && this.daySelected === index ? true : false,
                isToday: setToday && this.dayToday === index ? true : false,
                isGhost: false,
                dayOfWeek: this.getNumberOfDayOfWeekForFirstDayMonth(this.year, this.month, index)
            });
        }
    }
    getNumberOfDayOfWeekForFirstDayMonth(year: number, month: number, day: number) {
        return new Date(year, month, day).getDay();
    }
}

type DatePickerManagerProps = {
    state: DatePickerState;
    numberPanels?: 'one' | 'three';
};

export class DatePickerManager {
    state: DatePickerState;
    years: number[];
    numberPanels: 'one' | 'three';
    datePickerPanel: DatePickerPanel[];
    constructor({ numberPanels, state }: DatePickerManagerProps) {
        this.state = state;
        this.years = [];
        this.numberPanels = numberPanels ? numberPanels : 'one';
        this.datePickerPanel = [];
        this.createPanels();
        this.createYears();
    }

    createPanels() {
        if (this.numberPanels === 'three') {
            for (let index = -1; index < 2; index++) {
                this.datePickerPanel.push(
                    new DatePickerPanel({
                        date: this.state.datePanel,
                        dateSelected: this.state.dateSelected,
                        changer: index
                    })
                );
            }
        } else {
            this.datePickerPanel.push(
                new DatePickerPanel({
                    date: this.state.datePanel,
                    dateSelected: this.state.dateSelected,
                    changer: 0
                })
            );
        }
    }
    createYears() {
        const startYear = this.state.datePanel.getFullYear() - 100;
        const years: number[] = [];
        for (let index = startYear; index < startYear + 200; index++) {
            years.push(index);
        }
        this.years.push(...years);
    }
    getData() {
        return {
            panel: this.datePickerPanel,
            years: this.years
        };
    }
}
