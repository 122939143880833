import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { FieldCosts } from 'src/components/popupFormExtended/dynamicField/fieldCosts/types';
import { NumberManager } from 'src/utils/src/shared/NumberManager';

type FieldDependentCostsManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldCosts;
};

export class FieldDependentCostsManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldCosts;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentCostsManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async przeliczWartosc() {
        if (
            this.fieldWasTriggered?.updatedNumber === undefined ||
            (this.fieldWasTriggered?.updatedNumber !== undefined &&
                this.fieldWasTriggered.updatedNumber < 5)
        ) {
            if (!this.fieldWasTriggered.isUpdatedCalc) {
                await this.calc();
                await this.runNested(this.fieldWasTriggeredDynamicFieldT);
            }
        }
    }

    private async calc() {
        const data = this.getValuesToSet();

        let result = 0;
        let update = false;
        let value1 = isNaN(data.one.number) ? 0 : data.one.number;
        let value2 = isNaN(data.two.number) ? 0 : data.two.number;
        let currency = 'PLN';

        if (this.fieldSuperiorOneDynamicFieldT?.pole_do_wyliczen_1_procent === 't') {
            value1 = value1 / 100;
        }
        if (this.fieldSuperiorTwoDynamicFieldT?.pole_do_wyliczen_2_procent === 't') {
            value2 = value2 / 100;
        }

        if (data.one.currency) {
            currency = data.one.currency;
        } else if (data.two.currency) {
            currency = data.two.currency;
        }

        switch (this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie) {
            case '=':
                result = value1;
                update = true;
                break;
            case '+':
            case '-':
            case '*':
            case '/':
                {
                    const datanew = NumberManager.calcArithmeticValue(
                        value1,
                        value2,
                        this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie
                    );
                    result = Math.round(datanew * 10) / 10;
                    update = true;
                }
                break;
            default:
                break;
        }
        const value = {
            ...this.fieldWasTriggered,
            value: {
                ...this.fieldWasTriggered.value,
                value: String(result),
                currency
            }
        };
        if (update) {
            this.setTempFieldsState(value, 'isUpdatedCalc');
            this.isRunNested = true;
        }
    }
}
