import React, { FC } from 'react';

import { DynamicFormButton } from 'src/components/shared/atoms/dynamicFormButton/DynamicFormButton';
import { DynamicFormObjectGlobalValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/DynamicFormObjectGlobalValidator';
import { DynamicFormObjectProps } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { FormContext } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import { DynamicFormObjectFieldsSetter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectFieldsSetter';
import { PopupFormExtendedBehavior } from 'src/data/popupFormTypes';
import { usePopupState } from 'src/context/globalPopup/popupContext';
import styles from './DynamicFormObject.module.css';
import { Section } from 'src/components/popupFormExtended/sections/Section';
import { globalPopup } from 'src/constants/subtitles';
import { callPopup, closePopup, setPopupInfoTwo } from 'src/store/src/popup';
import { useLocation } from 'react-router-dom';
import { ADD_BACK_BROADCAST_CHANNEL } from 'src/constants';
import { useSafeNavigate, useSearchParam } from 'src/hooks';
import { BroadcastAddBackPayload } from '../types';
import { DynamicFormObjectHistoryCommentsManage } from './DynamicFormObjectHistoryCommentsManage';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { PopupBehavior, SystemAction } from 'src/data/types';
import { useAppDispatch } from 'src/store';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { sendPopupFormExtendedEndRequestAdd } from 'src/api/src/popupFormExtended/sendPopupFormExtendedEndRequestAdd';
import { sendPopupFormExtendedEndRequestEdit } from 'src/api/src/popupFormExtended/sendPopupFormExtendedEndRequestEdit';
import { unlockObject } from 'src/api/src/popupFormExtended/unlockObject';
import { consoleConditionally, getCompatibleUrl } from 'src/utils';
import { LoaderWewTwo } from 'src/components/shared/organisms/loader/LoaderWewTwo';
import { FormObjectDataApiCreator } from 'src/components/form/molecules/formObjectDataApiCreator/FormObjectDataApiCreator';
import { fetchAndHandleCalendarData } from 'src/store/src/calendar';
import { ErrorApiResponse } from 'src/api/types';
import { fetchAndLoadUtilitiesBarActions } from 'src/store/src/utilitiesBar/utilitiesBar/utilitiesbarSlice';
import { InternalApiErrorCodes } from 'src/api/src/simpleQueryAPIData';
import { budgetActionCodeNotPermitedToNavigate } from 'src/constants/budget/budgetActionCodeNotPermitedToNavigate';

export enum SUBMIT_MODES {
    normal = 0,
    cyclic = 1,
    cyclicOnlyNewer = 2
}

export const DynamicFormObject: FC<DynamicFormObjectProps> = ({ availableWidth, keyPopup }) => {
    const {
        popupStateContext,
        popupStateRedux,
        dispatchPopupStateRedux,
        dispatchPopupStateContext
    } = usePopupState(keyPopup);
    const { formState, dispatchFormState } = React.useContext(FormContext);
    const isSubmmitedRef = React.useRef(false);
    const makeSubmitSuccessfully = React.useRef(false);
    const [onMounted, setOnMounted] = React.useState(false);
    const popupFormExtendedBehavior = popupStateRedux?.action
        ?.behaviour as PopupFormExtendedBehavior;
    const popupFormExtendedData = popupStateRedux.popupFormExtendedData;

    const actualTabId = popupStateContext.activeTab.id ? popupStateContext.activeTab.id : 0;
    const sections = formState.tabs[actualTabId];
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();
    consoleConditionally(popupStateContext, 'popupStateContext');
    consoleConditionally(popupStateRedux, 'popupStateRedux');
    // hooks used for 'dodawanie_zwrotne' system
    const location = useLocation();
    const parrentFieldCode = useSearchParam('dodawanie_zwrotne');
    const parrentWindowName = useSearchParam('window_name');
    const paramScreen = useSearchParam('obiekt');
    const paramObjectId = useSearchParam('id');

    const forAdding = popupFormExtendedBehavior.data.objectId === 'nowy';

    // function acts as wrapper for isSubmmitedRef lock from many clicking on button
    const onSubmit = async (mode: SUBMIT_MODES) => {
        if (isSubmmitedRef.current) return;
        isSubmmitedRef.current = true;
        await doSubmitform(mode);
        isSubmmitedRef.current = false;
    };

    const doSubmitform = async (mode: SUBMIT_MODES) => {
        if (popupFormExtendedData === null) {
            console.log('DynamicFormObject: submit, popupFormExtendedData is null');
            return;
        }

        await new DynamicFormObjectHistoryCommentsManage(
            dispatchFormState,
            dispatch,
            formState.fields,
            popupFormExtendedData
        ).execute();

        const validationResult = await new DynamicFormObjectGlobalValidator({
            formState,
            dispatchFormState,
            dispatchPopupStateRedux,
            dispatchPopupStateContext,
            popupFormExtendedData,
            popupStateContext,
            popupStateRedux,
            submitMode: mode,
            placementRun: 'global'
        }).execute();

        if (!validationResult.isFormValid) return;

        const formDataToApi = new FormObjectDataApiCreator().prepareDataForFormExtanded({
            formState,
            formbehaviorData: popupFormExtendedBehavior.data
        });
        consoleConditionally(formDataToApi, 'dataToApi - VALIDATION CORRECT');
        if (!forAdding) {
            const res = await sendPopupFormExtendedEndRequestEdit(
                popupFormExtendedData.url,
                mode,
                formDataToApi,
                navigate
            );

            if (res.errorCode === InternalApiErrorCodes.Navigation) {
                dispatch(closePopup(keyPopup));
                return;
            }

            if (!res.success) {
                handleBadResponse(res);
                return;
            }

            if (location.pathname === '/lista') {
                navigate(
                    getCompatibleUrl(
                        'ekran_obiektu.php',
                        new URLSearchParams([
                            ['id', popupFormExtendedBehavior.data.objectId],
                            ['obiekt', popupFormExtendedData.screen]
                        ])
                    )
                );
            }

            // to refresh utilities bar after edited actual object
            if (
                location.pathname === '/obiekt' &&
                paramScreen &&
                paramObjectId &&
                paramScreen === popupFormExtendedData.screen
            ) {
                dispatch(
                    fetchAndLoadUtilitiesBarActions(paramScreen, 'ekran_obiektu', paramObjectId)
                );
            }

            if (location.pathname === '/obiekt' && paramScreen === 'pracownicy') {
                window.location.reload();
            }

            makeSubmitSuccessfully.current = true;
        } else {
            const res = await sendPopupFormExtendedEndRequestAdd(
                popupFormExtendedData.url,
                mode,
                formDataToApi['nowy']
            );
            if (!res.success) {
                handleBadResponse(res);
                return;
            }

            const { id: newObjectId, name: newObjectName } = res.data;

            // handle set password for new added object
            await handleSetPasswordAfterAdd(newObjectId);

            if (location.pathname === '/kalendarz') {
                dispatch(fetchAndHandleCalendarData());
            }

            if (location.pathname === '/budzet') {
                if (
                    popupStateRedux.submitCallbackEventId &&
                    popupStateRedux.action?.code === 'add_task_in_budget'
                ) {
                    EventBus.$emit(popupStateRedux.submitCallbackEventId);
                }
            }

            // for 'dodawanie_zwrotne' system
            if (location.pathname === '/dodaj-obiekt') {
                if (parrentFieldCode !== null && newObjectId) {
                    new BroadcastChannel(ADD_BACK_BROADCAST_CHANNEL).postMessage({
                        id: newObjectId,
                        windowName: parrentWindowName,
                        name: newObjectName,
                        targetFieldCode: parrentFieldCode
                    } as BroadcastAddBackPayload);
                }
            }

            // to navigate to newly added object screen
            if (
                parrentFieldCode === null &&
                location.pathname !== '/kalendarz' &&
                !budgetActionCodeNotPermitedToNavigate.includes(popupStateRedux.action?.code ?? '')
            ) {
                navigate(
                    getCompatibleUrl(
                        'ekran_obiektu.php',
                        new URLSearchParams([
                            ['id', newObjectId],
                            ['obiekt', popupFormExtendedData.screen]
                        ])
                    ),
                    null,
                    undefined,
                    undefined,
                    location.pathname === '/dodaj-obiekt'
                );
            }

            makeSubmitSuccessfully.current = true;
        }

        dispatch(closePopup(keyPopup));
    };

    const handleBadResponse = (response: ErrorApiResponse<any>) => {
        dispatchPopupStateRedux(
            setPopupInfoTwo({
                message: response.message,
                title: 'Błąd',
                messageInjectHtml: true
            })
        );
        dispatch(closePopup(keyPopup));
    };

    const handleSetPasswordAfterAdd = (newObjectId: string) =>
        new Promise((res, _) => {
            const passwordField = Object.values(formState.fields).find(
                (field) => field?.typ === 'haslo'
            );
            if (!passwordField) {
                res(false);
                return;
            }
            const dynamicFieldTPassword = popupFormExtendedData?.fields.find(
                (field) => field.kod === passwordField.kod
            );
            if (!dynamicFieldTPassword) {
                res(false);
                return;
            }

            if (!dynamicFieldTPassword.dodawanie || dynamicFieldTPassword.dodawanie === 'f') {
                res(false);
                return;
            }

            // make change password popup action
            const popupBehaviour: PopupBehavior = {
                type: 'popup',
                data: {
                    template: 'changePassword',
                    data: {
                        passwordfield: dynamicFieldTPassword,
                        objectId: newObjectId,
                        screen: popupStateRedux.action?.behaviour.data?.screen,
                        lockExit: passwordField.isMandatory,
                        userPasswordValidation: true,
                        infoText: 'Proszę ustaw hasło dla nowo dodanego obiektu'
                    }
                }
            };
            const actionPreview: SystemAction = {
                name: 'Ustaw hasło',
                code: 'change password_file_password',
                colorConditions: null,
                displayConditions: null,
                behaviour: popupBehaviour
            };

            // prepare listener on close change password popup, so that function will be resolved
            // when user will end setting that object password
            const closePopupEventId = `changePasswordAfterObjectAdd-${newObjectId}`;
            EventBus.$on(closePopupEventId, () => {
                EventBus.$off(closePopupEventId);
                res(true);
            });
            // open popup for change password
            new PopupManager({ dispatch, action: actionPreview }).setPopupTemplate({
                behaviour: popupBehaviour,
                rowId: '0',
                sourceOfTrigger: {
                    type: 'popupFormExtended',
                    fileId: '',
                    fileName: ''
                },
                closeCallbackEventId: closePopupEventId
            });
        });

    React.useEffect(() => {
        if (popupStateRedux.callPopup) {
            dispatch(callPopup({ key: popupStateRedux.key, call: true }));
            onSubmit(SUBMIT_MODES.normal);
        }
    }, [popupStateRedux.callPopup]);

    React.useEffect(() => {
        (async () => {
            if (popupFormExtendedData === null) {
                console.log('DynamicFormObject: submit, popupFormExtendedData is null');
                return;
            }

            await new DynamicFormObjectFieldsSetter({
                formState,
                popupFormExtendedData
            }).executeAllOnMount(dispatchFormState);
            setOnMounted(true);
        })();

        return () => {
            unlockActualEditedObject();
            if (location.pathname === '/dodaj-obiekt') {
                // close window when open in new tab, by option in field at editing object
                if (parrentFieldCode !== null) {
                    window.close();
                }
                // case for cancel adding from budget screen
                else if (!makeSubmitSuccessfully.current) {
                    window.history.back();
                }
            }
        };
    }, []);

    const unlockActualEditedObject = async () => {
        if (popupFormExtendedBehavior.data.objectId !== 'nowy') {
            await unlockObject(
                popupFormExtendedBehavior.data.screen,
                popupFormExtendedBehavior.data.objectId
            );
        }
    };

    if (!onMounted || !formState.onMountForm) {
        return <LoaderWewTwo />;
    }

    return (
        <form className={styles.wrapper} name="objectScreen" onSubmit={(e) => e.preventDefault()}>
            <div>
                {sections?.map((section, i) => {
                    return (
                        <Section
                            usedForAddingObject={popupFormExtendedBehavior.data.objectId === 'nowy'}
                            key={i}
                            section={section}
                            index={i}
                            availableWidth={availableWidth}
                            keyPopup={keyPopup}
                        />
                    );
                })}
            </div>
            <div
                className={styles.box}
                style={{
                    width: availableWidth + 'px'
                }}>
                <DynamicFormButton
                    submit={true}
                    onClick={() => onSubmit(SUBMIT_MODES.normal)}
                    styleWrapper={{ paddingBottom: '5px', width: 'auto' }}
                    styleButton={{ padding: ' 6px 20px' }}>
                    {forAdding
                        ? globalPopup.contentFormExtended.dynamicFormObject.submitButtonAdd
                        : globalPopup.contentFormExtended.dynamicFormObject.submitButtonEdit}
                </DynamicFormButton>
                {popupFormExtendedData?.cyclic && (
                    <>
                        <DynamicFormButton
                            submit={true}
                            onClick={() => onSubmit(SUBMIT_MODES.cyclic)}
                            styleWrapper={{
                                paddingBottom: '5px',
                                width: 'auto'
                            }}
                            styleButton={{ padding: ' 6px 20px' }}>
                            {globalPopup.contentFormExtended.dynamicFormObject.submitButtonCyclic}
                        </DynamicFormButton>
                        <DynamicFormButton
                            submit={true}
                            onClick={() => onSubmit(SUBMIT_MODES.cyclicOnlyNewer)}
                            styleWrapper={{
                                paddingBottom: '5px',
                                width: 'auto'
                            }}
                            styleButton={{ padding: ' 6px 20px' }}>
                            {
                                globalPopup.contentFormExtended.dynamicFormObject
                                    .submitButtonCyclicOnlyNewer
                            }
                        </DynamicFormButton>
                    </>
                )}
            </div>
        </form>
    );
};
