const regex = /(\{@(.*?)\}|\{\$(.*?)\}|\{!(.*?)\}|\{%(.*?)\})/;
const regexOption = /(allVisible|all|selected)/;
const regexFlag = /\[(.*?)\]/;

type TemplateMainItemProps = {
    itemStr: string;
};

export type TemplateMainItemReturn = {
    content: string;
    contentClearAll: string;
    contentClear: string;
    sign: string;
    isFoundTemplate: boolean;
    outputContent: string;
    option: string;
};

export class TemplateMainItem {
    content: string;
    contentClearAll: string;
    contentClear: string;
    contentClearWithoutFlag: string;
    sign: string;
    isFoundTemplate: boolean;
    outputContent: string;
    option: string;
    constructor({ itemStr }: TemplateMainItemProps) {
        this.content = itemStr;
        this.contentClearAll = '';
        this.contentClear = '';
        this.contentClearWithoutFlag = '';
        this.sign = '';
        this.isFoundTemplate = false;
        this.outputContent = '';
        this.option = this.getProperty(itemStr, regexOption);
        this.setContent();
    }

    private setContent() {
        let found: null | string[] = null;
        found = this.content.match(regex);
        if (found) {
            this.contentClearAll = found[0];

            if (found[0].includes('@')) {
                this.contentClear = found[2];
                this.sign = '@';
            } else if (found[0].includes('$')) {
                this.contentClear = found[3];
                this.sign = '$';
            } else if (found[0].includes('!')) {
                this.contentClear = found[4];
                this.sign = '!';
            } else if (found[0].includes('%')) {
                this.contentClear = found[5];
                this.sign = '%';
            }
            this.isFoundTemplate = true;
            this.contentClearWithoutFlag = this.contentClear.replace(regexFlag, '');
        }
    }

    private getProperty(itemStr: string, regex: RegExp) {
        const property = itemStr.match(regex);
        if (property) {
            return property[0];
        }
        return '';
    }

    replaceTemplateWithContent(dataToReplace: string) {
        this.outputContent = this.content.replace(this.contentClearAll, dataToReplace);
        return this.outputContent;
    }
    getData(): TemplateMainItemReturn {
        return {
            content: this.content,
            sign: this.sign,
            contentClear: this.contentClear,
            contentClearAll: this.contentClearAll,
            isFoundTemplate: this.isFoundTemplate,
            option: this.option,
            outputContent: this.outputContent
        };
    }
}
