import React from 'react';
import { BasicColumn, BasicRow } from 'src/data/table';

export type BookCorrespondenceRowState = {
    id: string;
    url: string;
    fields: {
        [code: string]: any;
    };
};

export const useBookCorrespondenceRowState = ({
    row,
    columns,
    id
}: {
    row: BasicRow;
    columns: BasicColumn[];
    id: string;
}): {
    rowState: BookCorrespondenceRowState;
    setRowState: React.Dispatch<React.SetStateAction<BookCorrespondenceRowState>>;
} => {
    const [rowState, setRowState] = React.useState<BookCorrespondenceRowState>(
        init(row, columns, id)
    );

    return { rowState, setRowState };
};

function init(row: BasicRow, columns: BasicColumn[], id: string) {
    const state: BookCorrespondenceRowState = { id: '', url: '', fields: {} };

    state.id = id;

    columns.forEach((column) => {
        const cellBody = row.cellValues[column.code];

        switch (column.columnType) {
            case 'text':
                break;
            case 'button':
                state.url = cellBody?.[0]?.rawValue;
                break;
            default:
                state.fields[column.code] = cellBody?.[0]?.rawValue;
                break;
        }
    });
    return state;
}
