import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';

import { useAppDispatch } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { clearFirst } from 'src/store/src/general';
import { globalPopup } from 'src/constants/subtitles';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import { AlertTemplateDefault } from 'src/store/src/general/alerts/types';

type PopupContentTypeWew2faProps = BasePopupContentProps & {
    alertData: AlertTemplateDefault;
};

export const PopupContentTypeWew2fa: FC<PopupContentTypeWew2faProps> = ({ popup, alertData }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleOnClick = () => {
        dispatch(closePopup(popup.key));
        dispatch(clearFirst());
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <Typography
                sx={{
                    color: theme.palette.primary.main,
                    paddingBottom: '10px'
                }}>
                {alertData.data.popup.title}
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'auto',
                    gridTemplateColumns: 'auto',
                    gap: '30px',
                    justifyContent: 'center',
                    paddingTop: '10px'
                }}>
                <Button
                    variant="contained"
                    sx={{
                        color: (theme) => theme.palette.background.default,
                        padding: '6px 40px',
                        cursor: 'pointer'
                    }}
                    onClick={handleOnClick}>
                    <Typography>{globalPopup.contentAlert.button.yes}</Typography>
                </Button>
            </Box>
        </Box>
    );
};
