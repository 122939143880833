import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { createKeyFieldBudget } from 'src/utils/src/budget/createKeyFieldBudget';
import { createTimestampByAddThreeZero } from 'src/utils/src/budget/createTimestampByAddThreeZero';
import { isLastMonthLastYear } from './isLastMonthLastYear';

type BudgetHandlerTemplateMonthLimitProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};

export class BudgetHandlerTemplateMonthLimit extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetHandlerTemplateMonthLimitProps) {
        super({ budget, operationalState });
    }

    execute() {
        let temp: string[] = [];
        const templateMonthLimit: { [key: number]: string[] } = {};
        const templateMonthLimitStartTimestampWhereIsLimit: { [key: number]: string } = {};

        let tempTimestamp = '0';
        this.budget.settlementMethods.forEach((method) => {
            if (method.rozliczanielimitgodzin) {
                const methodWhichKeyMonthIdToUpdateMonthsForCaseLimit: string[][] = [];
                let keyMonthIdToUpdateMonthsForCaseLimit: string[] = [];
                method.years.forEach((year, indexYear) => {
                    const months = year?.months ?? [];
                    year?.months?.forEach((month, indexMonth) => {
                        if (month.hoursLimit) {
                            methodWhichKeyMonthIdToUpdateMonthsForCaseLimit.push(
                                keyMonthIdToUpdateMonthsForCaseLimit
                            );
                            keyMonthIdToUpdateMonthsForCaseLimit = [];
                            temp = [];
                            this.budget.settledObjectsStructures['limit_godzin']?.fields?.forEach(
                                (field) => {
                                    if (!field?.onlyText && field.code === '0') {
                                        const { keyField } = createKeyFieldBudget({
                                            name: field?.name,
                                            objectId: month?.hoursLimit?.id ?? '',
                                            monthDate: month?.data_rozp,
                                            methodId: method.id
                                        });
                                        temp.push(keyField);
                                    }
                                }
                            );
                            templateMonthLimit[createTimestampByAddThreeZero(month.id)] = temp;
                            templateMonthLimitStartTimestampWhereIsLimit[
                                createTimestampByAddThreeZero(month.id)
                            ] = String(createTimestampByAddThreeZero(month.id));
                            tempTimestamp = String(createTimestampByAddThreeZero(month.id));
                        } else {
                            templateMonthLimit[createTimestampByAddThreeZero(month.id)] = temp;
                            templateMonthLimitStartTimestampWhereIsLimit[
                                createTimestampByAddThreeZero(month.id)
                            ] = tempTimestamp;
                        }
                        keyMonthIdToUpdateMonthsForCaseLimit.push(String(month.id));
                        if (isLastMonthLastYear(method.years, months, indexYear, indexMonth)) {
                            methodWhichKeyMonthIdToUpdateMonthsForCaseLimit.push(
                                keyMonthIdToUpdateMonthsForCaseLimit
                            );
                        }
                    });
                });
                // add operational state keys which will be used to update memo and protect unneeded recalculations sums
                this.operationalState.templateKeyMonthIdToUpdateMonthsForCaseLimit[method.id] =
                    methodWhichKeyMonthIdToUpdateMonthsForCaseLimit;
                //
                if (this.operationalState.templateMonthLimit[method.id]) {
                    this.operationalState.templateMonthLimit[method.id] = {
                        ...this.operationalState.templateMonthLimit[method.id],
                        ...templateMonthLimit
                    };
                } else {
                    this.operationalState.templateMonthLimit[method.id] = {
                        ...templateMonthLimit
                    };
                }
                if (this.operationalState.templateMonthLimitStartTimestampWhereIsLimit[method.id]) {
                    this.operationalState.templateMonthLimitStartTimestampWhereIsLimit[method.id] =
                        {
                            ...this.operationalState.templateMonthLimitStartTimestampWhereIsLimit[
                                method.id
                            ],
                            ...templateMonthLimitStartTimestampWhereIsLimit
                        };
                } else {
                    this.operationalState.templateMonthLimitStartTimestampWhereIsLimit[method.id] =
                        {
                            ...templateMonthLimitStartTimestampWhereIsLimit
                        };
                }
            }
        });
    }

    moveTemplateMonthLimitToMonthsIdIncluded() {
        this.budget.settlementMethods.forEach((method) => {
            if (!(method.id in this.operationalState.templateMonthLimit)) return;
            if (!(method.id in this.operationalState.globalSummaryData)) return;
            const monthToLimit: { [key: number]: string } = {};
            for (const monthDate in this.operationalState.templateMonthLimit[method.id]) {
                // use / 1000 to change time in ms to sec
                monthToLimit[parseInt(monthDate) / 1000] =
                    this.operationalState.templateMonthLimit[method.id][monthDate][0];
            }
            this.operationalState.globalSummaryData[method.id].monthToLimit = monthToLimit;
        });
    }
}
