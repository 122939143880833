import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateSecond from 'src/components/shared/organisms/popupTemplateSecond/PopupTemplateSecond';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Typography } from '@mui/material';
import { useSearchParam } from 'src/hooks';
import { ApiActionBehavior } from 'src/data/types';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { useUpdateObjectsData } from 'src/hooks';
import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import { TemplateMainManager } from 'src/utils/src/shared/TemplateMainManager';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventBus } from 'src/utils/src/shared/EventBus';

export const PopupContentConfirmApi: FC<BasePopupContentProps> = ({ popup }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const screen = useSearchParam('obiekt');
    const behaviour = popup.action?.behaviour as ApiActionBehavior;
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const objectSlice = useTypedSelector((state) => state.object);
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const updateObjects = useUpdateObjectsData();

    const confirm = async () => {
        const { params, body, url } = new TemplateMainManager({
            rowData,
            transformationState,
            rowId: popup.objectId[0] ? popup.objectId[0] : null,
            actionBehavior: behaviour as ApiActionBehavior,
            screen,
            action: popup.action ? popup.action : undefined,
            objectSlice,
            sourceOfTrigger: popup.sourceOfTrigger,
            additional: popup.objectAdditional
        }).getData();

        const urlAll = url + '?' + params;
        dispatch(setSpinner(true));
        const resp = await simpleQueryAPIData(urlAll, 'POST', body, false);
        if (resp.success) {
            if (behaviour.data.refresh) {
                if (location.pathname === '/lista') {
                    updateObjects({ saveLocationState: false, forceRefreshData: true });
                } else {
                    // because object screen need in some cases reload utilities bar which is unusual
                    window.location.reload();
                }
            }
            if (behaviour.data.showResult) {
                new PopupManager({ dispatch: dispatch, action: popup.action }).setPopupInfo(
                    resp.message ? resp.message : 'Wykonano'
                );
            }
            if (behaviour.data.navigateBack) {
                navigate(-1);
            }
        } else {
            new PopupManager({ dispatch: dispatch, action: popup.action }).setPopupInfo(
                resp.message ? resp.message : 'Nie wykonano'
            );
        }
        dispatch(setSpinner(false));
        dispatch(closePopup(popup.key));
    };

    const close = async () => {
        if (popup.closeCallbackEventId) {
            EventBus.$emit(popup.closeCallbackEventId);
        }
        dispatch(closePopup(popup.key));
    };
    return (
        <PopupTemplateSecond
            title={globalPopup.contentConfirmation.title}
            buttonLeftTxt={globalPopup.contentConfirmation.button.yes}
            buttonRightTxt={globalPopup.contentConfirmation.button.no}
            buttonRightOnClick={() => close()}
            buttonLeftOnClick={() => confirm()}>
            <Typography
                sx={{
                    color: theme.palette.primary.main
                }}>
                {behaviour?.data?.secondConfirmText
                    ? behaviour?.data?.secondConfirmText
                    : behaviour?.data?.confirmText}
            </Typography>
        </PopupTemplateSecond>
    );
};
