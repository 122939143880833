import { useState, useEffect } from 'react';

const mqls = [
    window.matchMedia('screen and (min-width: 1025px)'),
    window.matchMedia('screen and (min-width: 1369px)'),
    window.matchMedia('screen and (min-width: 1701px)')
];

/**
 * @category Hooks
 * @subcategory Shared
 * @function useDetectWindowSize
 * @description Hook odpowiadający za wyliczanie wartości viewportu
 * @returns {'mobile'|'medium'|'large'|'xlarge'} viewport
 */
export default function useDetectWindowSize() {
    const [viewport, setViewport] = useState('');

    useEffect(() => {
        const handleViewport = () => {
            if (mqls[2].matches) {
                setViewport('xlarge');
            } else if (mqls[1].matches) {
                setViewport('large');
            } else if (mqls[0].matches) {
                setViewport('medium');
            } else {
                setViewport('small');
            }
        };

        handleViewport();
        mqls.forEach((mql) => mql.addListener(handleViewport));

        return () => mqls.forEach((mql) => mql.removeListener(handleViewport));
    }, []);

    return viewport;
}
