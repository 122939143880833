import { FieldListForeignOptions } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/types';
import { FieldListForeignBasicTypeValue } from 'src/data/fieldsFormType';
import { DynamicFieldT } from 'src/data/popupFormTypes';

export const getFieldForeignValueWithSelectedSoleOption = (
    baseValue: FieldListForeignBasicTypeValue,
    options: FieldListForeignOptions,
    dynamicFieldT: DynamicFieldT
): FieldListForeignBasicTypeValue | null => {
    if (dynamicFieldT.automatyczne_wybranie_jedynej_opcji !== 't') return null;
    const clearOptions = options.filter((opt) => opt.id !== '0' && opt.id !== 'nowy');
    if (
        clearOptions.length !== 1 ||
        (baseValue.value && baseValue.value !== '0' && baseValue.value !== 'nowy')
    )
        return null;
    return {
        ...baseValue,
        value: clearOptions[0].id,
        text: clearOptions[0].text
    };
};
