import React from 'react';
import { useGetBugdetShowTimeApproved } from 'src/hooks/src/budget/useGetBugdetShowTimeApproved';
import { BugdetObjectStructure } from 'src/store/src/budget/budget/types';

export const usePrepareFields = (budgetObjectStrucuture: BugdetObjectStructure) => {
    const { showTimeApproved } = useGetBugdetShowTimeApproved();

    const fields = React.useMemo(() => {
        if (showTimeApproved && budgetObjectStrucuture.name !== 'Zadania') {
            return budgetObjectStrucuture.fields.map((field) => {
                if (field.id === '5.5') {
                    return {
                        ...field,
                        colspan: 2
                    };
                }
                return field;
            });
        }
        return budgetObjectStrucuture.fields;
    }, []);
    return fields;
};
