import * as actionTypes from '../../constants/actions';
import rustle from '../../assets/rustle.mp3';
import bubble from '../../assets/bubble.mp3';
import { playSound } from '../../../shared/utils';

/**
 * @category Redux
 * @namespace ActionCreators
 * @description Kreatory akcji to funkcje które, zwracają Reduxowe obiekty akcji, lub funkcje "thunks". Akcje to obiekty, które muszą posiadać własność
 * 'type' oraz często posiadają wlasność 'payload', która służy do przekazywania potrzebnych do obsłużenia akcji informacji.
 * @example
 * // action object
 * const action = {type: 'RESET_APP'}
 * @example
 * // action object
 * const action = {type: 'SET_APP_LOADING', payload: true}
 * @example
 * // action creator
 * const setChatOpen = (boolean) => {type: 'SET_CHAT_OPEN', payload: boolean}
 * @example
 * // thunk action creator
 * const setNotification = (notification) => (dispatch, getState) => {
 *	 if(getState().app.sounds){
 *		playSound(bubbleSound);
 *	 }
 *	 dispatch({type: actionTypes.SET_NOTIFICATION, payload: {notification}});
 * };
 * @see [redux actionCreators]{@link https://redux.js.org/basics/actions#action-creators}
 * @see [redux actions]{@link https://redux.js.org/basics/actions}
 * @see [redux thunks]{@link https://redux.js.org/advanced/async-actions}
 */

const rustleSound = new Audio(rustle);
const bubbleSound = new Audio(bubble);

/**
 * @function ActionCreators.closeChatWindow
 * @description Thunk, wywołuje akcje zamknięcia okna czatu. Odtwarza dźwięk jeśli nie wyłączono dzwięków.
 * @param {string} id - windowID okna czatu
 * @returns {ThunkAction}
 */
export const closeChatWindow = (id) => (dispatch, getState) => {
    const {
        app: { sounds }
    } = getState();

    if (sounds) {
        playSound(rustleSound);
    }

    dispatch({
        type: actionTypes.CLOSE_CHAT_WINDOW,
        payload: { id }
    });
};

/**
 * @function ActionCreators.setChatOpen
 * @description Otwiera lub zamyka czat.
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setChatOpen = (boolean) => ({ type: actionTypes.SET_CHAT_OPEN, payload: { boolean } });

/**
 * @function ActionCreators.setDisconnected
 * @description Ustawia stan disconnected czatu .
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setDisconnected = (boolean) => ({
    type: actionTypes.SET_DISCONNECTED,
    payload: { boolean }
});

/**
 * @function ActionCreators.replaceTempID
 * @description Zmienia roomID grupy.
 * @param {string} tempID - stare id
 * @param {string} roomID - nowe id
 * @returns {ReduxAction}
 */
export const replaceTempID = (tempID, roomID) => ({
    type: actionTypes.REPLACE_TEMPORARY_ID,
    payload: { roomID, tempID }
});

/**
 * @function ActionCreators.cancelError
 * @description Anuluje stan błędu aplikacji
 * @returns {ReduxAction}
 */
export const cancelError = () => ({ type: actionTypes.CANCEL_ERROR });

/**
 * @function ActionCreators.setActiveStatus
 * @description Ustawia widoczność dla innych użytkowników
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setActiveStatus = (boolean) => ({
    type: actionTypes.SET_ACTIVE_STATUS,
    payload: { boolean }
});

/**
 * @function ActionCreators.setSounds
 * @description Definiuje czy aplikacja ma odtwarzać dźwięki
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setSounds = (boolean) => ({ type: actionTypes.SET_SOUNDS, payload: { boolean } });

/**
 * @function ActionCreators.setBusy
 * @description Ustawia stan nie przeszkadzać dla aplikacji
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setBusy = (boolean) => ({ type: actionTypes.SET_BUSY, payload: { boolean } });

/**
 * @function ActionCreators.setLoadingState
 * @description Ustawia stan ładowania dla głównego okna aplikacji
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setLoadingState = (boolean) => ({
    type: actionTypes.SET_LOADING_STATE,
    payload: { boolean }
});

/**
 * @function ActionCreators.removeUnreadChat
 * @description Anuluje liczbe nieodczytanych wiadomości dla danego czatu
 * @param {string} id - id czatu
 * @returns {ReduxAction}
 */
export const removeUnreadChat = (id) => ({ type: actionTypes.REMOVE_UNREAD_CHAT, payload: { id } });

/**
 * @function ActionCreators.toggleSearch
 * @description Ustawia tryb wyszukiwania dla użytkowników lub grup
 * @param {string} type - informacja czego dotyczy wyszukiwanie
 * @returns {ReduxAction}
 */
export const toggleSearch = (type) => ({ type: actionTypes.TOGGLE_SEARCH, payload: { type } });

/**
 * @function ActionCreators.setSearchQuery
 * @description Ustawia query wyszukiwania dla użytkowników lub grup
 * @param {string} type - informacja czego dotyczy wyszukiwanie
 * @returns {ReduxAction}
 */
export const setSearchQuery = (type, value) => ({
    type: actionTypes.SET_SEARCH_QUERY,
    payload: { type, value }
});

/**
 * @function ActionCreators.addActiveChat
 * @description Dodaje do listy aktywnynych czatow konkretny czat
 * @param {string} roomID - id czatu
 * @returns {ReduxAction}
 */
export const addActiveChat = (roomID) => ({
    type: actionTypes.ADD_ACTIVE_CHAT,
    payload: { roomID }
});

/**
 * @function ActionCreators.removeActiveChat
 * @description Usuwa z listy aktywnynych czatow konkretny czat.
 * @param {string} roomID - id czatu
 * @returns {ReduxAction}
 */
export const removeActiveChat = (roomID) => ({
    type: actionTypes.REMOVE_ACTIVE_CHAT,
    payload: { roomID }
});

/**
 * @function ActionCreators.setActiveChats
 * @description Ustawia listę aktywnynych czatow.
 * @param {string[]} activeChats - lista id aktywnych czatów
 * @returns {ReduxAction}
 */
export const setActiveChats = (activeChats) => ({
    type: actionTypes.SET_ACTIVE_CHATS,
    payload: { activeChats }
});

/**
 * @function ActionCreators.setTabActive
 * @description Ustawia aktualną kartę na aktywną, lub nieaktywną (synchronizacja z innymi kartami)
 * @param {boolean} boolean
 * @returns {ReduxAction}
 */
export const setTabActive = (boolean) => ({
    type: actionTypes.SET_TAB_ACTIVE,
    payload: { boolean }
});

/**
 * @function ActionCreators.setCallback
 * @description Ustawia w stanie aplikacji callback, wywoływany lub kasowany przez inne akcje.
 * @param {Function} cb - callback do wywołania później.
 * @returns {ReduxAction}
 */
export const setCallback = (cb) => ({ type: actionTypes.SET_CALLBACK, payload: { cb } });

/**
 * @function ActionCreators.executeCallback
 * @description Wywołuje i usuwa ustawiony wcześniej callback
 * @returns {ReduxAction}
 */
export const executeCallback = () => ({ type: actionTypes.EXECUTE_CALLBACK });

/**
 * @function ActionCreators.setHiddenWindows
 * @description Ustawia tablice okien które nie mieszczą się aktualnie na ekranie.
 * @param {string[]} windows - tablica windowID
 * @returns {ReduxAction}
 */
export const setHiddenWindows = (windows) => ({
    type: actionTypes.SET_HIDDEN_WINDOWS,
    payload: { windows }
});

/**
 * @function ActionCreators.setNotifications
 * @description Ustawia tablice powiadomień systemowych, które pojawią się po kolei jako notyfikacje.
 * @param {string[]} list - lista notyfikacji
 * @returns {ReduxAction}
 */
export const setNotifications = (list) => ({
    type: actionTypes.SET_NOTIFICATIONS,
    payload: { list }
});

/**
 * @function ActionCreators.setNotification
 * @description Wyświetla powiadomienie. Odtwarza dźwięk.
 * @param {string} notification - powiadomienie
 * @returns {ThunkAction}
 */
export const setNotification = (notification) => (dispatch, getState) => {
    if (getState().app.sounds) {
        playSound(bubbleSound);
    }
    dispatch({ type: actionTypes.SET_NOTIFICATION, payload: { notification } });
};

/**
 * @function ActionCreators.setUnreadChats
 * @description Ustawia ilośc nieodczytanych wiadomości dla istniejących czatów.
 * @param {Object.<string,number>} list - słownik z kluczami w postaci roomID konwersacji i wartościami jako ilością nieodczytanych wiadomości w tej konwersacji.
 * @param {boolean} [initial=false] - informacja czy obiekt nieodczytanych wiadomości jest przekazywany przy synchronizacji klienta czy przy nieodczytanej wiadomości, domyślnie ustawione na wiadomość.
 * @returns {ThunkAction}
 */
export const setUnreadChats =
    (list, initial = false) =>
    (dispatch, getState) => {
        /* Show only those messages you can read */
        const { users, groups } = getState().chatUser;
        const filteredList = {};
        for (const key in list) {
            if (
                Object.values(users).find((u) => u.roomID === key) ||
                Object.values(groups).find((g) => g.roomID === key)
            ) {
                console.log(list[key]);
                filteredList[key] = list[key];
            }
        }

        dispatch({ type: actionTypes.SET_UNREAD_CHATS, payload: { list: filteredList, initial } });
    };
/**
 * @function ActionCreators.removeNotification
 * @description Ustawia aktualne powiadomienie i jeśli tablica powiadomień nie jest pusta, ustawia timer z kolejnym powiadomieniem do wyświetlenia.
 * @param {string} notification - powiadomienie
 * @returns {ThunkAction}
 */
export const removeNotification = (notification) => (dispatch, getState) => {
    const { notificationsList } = getState().app;

    dispatch({ type: actionTypes.REMOVE_NOTIFICATION, payload: { notification } });

    if (notificationsList.length > 1) {
        const nextNotification = notificationsList[1];
        setTimeout(() => dispatch(setNotification(nextNotification)), 800);
    }
};
