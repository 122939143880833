import React, { FC, useEffect } from 'react';
import ErrorPage from 'src/components/shared/pages/errorPage/ErrorPage';
import { listScreen } from 'src/constants/subtitles';
import { useSearchParam, useRunAllHooksOnEachRenderReactPage } from 'src/hooks';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { ObjectListProps } from './types';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import { Loader } from 'src/components/shared/organisms/loader/Loader';
import styles from './ObjectList.module.css';
import { updatePageTitleWithScreenName } from 'src/store/src/general';

const ObjectListView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ObjectListView" */ 'src/components/listScreen/templates/objectList/ObjectListView'
        )
);

const ObjectList: FC<ObjectListProps> = ({ view }) => {
    const dispatch = useAppDispatch();
    const menuData = useTypedSelector((state) => state.menu.menuStructureData.modules);
    const [error, setError] = React.useState(false);
    const screen = useSearchParam('obiekt');

    useRunAllHooksOnEachRenderReactPage();

    useEffect(() => {
        const screenObj = MenuStateManager.getActualScreen({ menuData, screen });
        if (!screenObj && menuData?.length) {
            setError(true);
        }
        if (screenObj) {
            dispatch(updatePageTitleWithScreenName(screenObj.name));
        }

        return () => {
            setError(false);
        };
    }, [menuData, screen]);

    if (!menuData?.length) {
        return <Loader />;
    }
    if (error) {
        return <ErrorPage message={listScreen.objectParamsError} />;
    }
    return (
        <div className={styles.wrapper}>
            {screen ? (
                <React.Suspense fallback>
                    <ObjectListView view={view} />
                </React.Suspense>
            ) : (
                <ErrorPage message={listScreen.objectParamsError} />
            )}
        </div>
    );
};

export default ObjectList;
