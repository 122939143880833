import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import {
    DynamicFormObjectCalendarCollisionValidatorProps,
    TypeValidateCalendarCollision
} from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData, SimpleCalendarVariablesFieldData } from 'src/data/popupFormTypes';
import { getValuesFromFields } from 'src/utils';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateCalendarCollision';
export class DynamicFormObjectCalendarCollisionValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateCalendarCollision;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectCalendarCollisionValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        const ev = this.popupFormExtendedData.environmentVariables;
        if (ev.kalendarz_wykluczane === 't') {
            const codeStart = ev.kalendarz_pole_data_rozp?.kod
                ? ev.kalendarz_pole_data_rozp?.kod
                : '';
            const codeEnd = ev.kalendarz_pole_data_zak?.kod ? ev.kalendarz_pole_data_zak?.kod : '';
            const codes = [codeStart, codeEnd];
            const codesStudents = ev.kalendarz_pola_uczestnikow?.map((item) => item.kod);
            const codesResources = ev.kalendarz_pola_zasobow?.map((item) => item.kod);
            if (codesStudents) {
                codes.push(...codesStudents);
            }
            if (codesResources) {
                codes.push(...codesResources);
            }
            const states = codes.map((item) => this.formState.fields[item]);

            const params = this.getParams();

            const res = await getFieldAdditionalData({
                url: 'json_sprawdz_kolizje_kalendarza.php',
                method: 'GET',
                params: params
            });

            if (res.data && res.data.collision) {
                this.setValidationResult({
                    typeOfInfo: 'Warning',
                    type: {
                        issue: this.typeFnValidate,
                        id_zakladki: states[0]?.id_zakladki ?? null,
                        message: res.data.message + '. Czy chcesz kontynować?',
                        title: validationData[this.typeFnValidate].title,
                        fieldsCode: codes
                    }
                });
            }
        }
    }

    private getParams() {
        let params = {};
        const ev = this.popupFormExtendedData.environmentVariables;
        const codeStart = ev.kalendarz_pole_data_rozp?.kod;
        const codeEnd = ev.kalendarz_pole_data_zak?.kod;
        if (codeStart && codeEnd) {
            const startDate =
                codeStart !== codeEnd
                    ? getValuesFromFields(this.formState.fields[codeStart]).timestampAll / 1000
                    : getValuesFromFields(this.formState.fields[codeStart]).timestampAll / 1000;
            const endDate =
                codeStart !== codeEnd
                    ? getValuesFromFields(this.formState.fields[codeEnd]).timestampAll / 1000
                    : getValuesFromFields(this.formState.fields[codeStart]).timestampAllEndHour /
                      1000;

            params = {
                id_obiektu: this.fieldRelatedObjectData.id,
                ekran: this.fieldRelatedObjectData.screen,
                data_rozp: startDate,
                data_zak: endDate,
                status: this.getStatus(),
                uczestnicy: this.getStudentsOrResource(ev.kalendarz_pola_uczestnikow).join('_'),
                zasoby: this.getStudentsOrResource(ev.kalendarz_pola_zasobow).join('_')
            };
        }
        return params;
    }

    private getStatus() {
        let status = '0';
        if (
            this.popupFormExtendedData.environmentVariables.widok_tabeli_statusow_pole_statusu &&
            this.popupFormExtendedData.environmentVariables.widok_tabeli_statusow_pole_statusu in
                this.formState.fields
        ) {
            const field =
                this.formState.fields[
                    this.popupFormExtendedData.environmentVariables
                        .widok_tabeli_statusow_pole_statusu
                ];
            if (field && field.typ === 'lista' && field.value.value) status = field.value.value;
        }
        return status;
    }

    private getStudentsOrResource(
        data?: SimpleCalendarVariablesFieldData[] | SimpleCalendarVariablesFieldData[]
    ) {
        let ids: (string | null)[] = [];
        data?.forEach((item) => {
            const fieldState = this.formState.fields[item.kod];
            switch (fieldState?.typ) {
                case 'lista_obca':
                    if (fieldState.value.value) {
                        ids = [...ids, fieldState.value.value];
                    }
                    break;
                case 'lista_obca_wielokrotna':
                    ids = [...ids, ...fieldState.value.values.map((el) => el.id)];
                    break;
                default:
                    break;
            }
        });
        return [...new Set(ids)];
    }
}
