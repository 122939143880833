import { ListCellRow } from 'src/store/src/object/object/types';

type GetCellRawValueOrTextFromListCellRowProps = {
    key: string;
    type: 'text' | 'rawValue';
    onlyFirstValue: boolean;
    cellRow: ListCellRow;
};

export function getCellRawValueOrTextFromListCellRow({
    key,
    type,
    onlyFirstValue,
    cellRow
}: GetCellRawValueOrTextFromListCellRowProps) {
    const cell = cellRow.cellValues[key];
    if (Array.isArray(cell)) {
        if (onlyFirstValue) {
            return cell[0][type];
        } else {
            const rawValue = cell?.map((item) => item[type]);
            return rawValue;
        }
    }
}
