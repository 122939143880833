import React, { FC } from 'react';
import { useTheme } from '@mui/system';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { NavMenuAddProps } from './types';
import AnimableMenuIcon from '../animableMenuIcon/AnimableMenuIcon';
import { UiNavMenuListWrapper } from 'src/components/menu/atoms/uiNavMenuListWrapper/UiNavMenuListWrapper';
import styles from 'src/styles/menu.module.css';
import { iconStyleCommon } from '../../atoms/icon/Icon';

export const NavMenuAdd: FC<NavMenuAddProps> = ({ url, params, fs, marginLeft, text, height }) => {
    const theme = useTheme();
    const menuIsUnfolded = useTypedSelector((state) => state.menu.isUnfolded);

    return (
        <div>
            <UiNavMenuListWrapper>
                <div className={styles.childLevelBox} style={{ color: theme.palette.primary.main }}>
                    <div
                        className={styles.innerChildBox}
                        style={{
                            marginLeft: marginLeft,
                            minHeight: height
                        }}>
                        <LegacyCompatibleLink url={url} queryParams={params} stopPropagation={true}>
                            <div className={styles.iconWraper}>
                                <AnimableMenuIcon
                                    iconData={{ faCode: 'fa-plus', svgFileName: '' }}
                                    sx={{
                                        ...iconStyleCommon,
                                        border: '1px solid transparent'
                                    }}
                                    lockWhite={false}
                                />
                            </div>
                            {menuIsUnfolded && (
                                <p style={{ fontSize: fs }} className={styles.menuText}>
                                    {text}
                                </p>
                            )}
                        </LegacyCompatibleLink>
                    </div>
                </div>
            </UiNavMenuListWrapper>
        </div>
    );
};
