import React, { FC } from 'react';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { UtilityBarOptionViewHorizontalProps } from './types';

import styles from './UtilityBarOptionViewHorizontal.module.css';
/**
 * Describes main menu, handle all mechanics related to menu data
 */
export const UtilityBarOptionHorizontalView: FC<UtilityBarOptionViewHorizontalProps> = ({
    name,
    icon
}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperInner}>
                <ThemeCompatibleIcon
                    iconData={icon}
                    fontSize={'small'}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                />
            </div>
            <p className={` ${styles.text}`}>{name}</p>
        </div>
    );
};
