import React, { FC } from 'react';
import { Box, Divider } from '@mui/material';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { ShortcutItemProps } from './types';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import WrapperIcon from 'src/components/shared/atoms/wrapperIcon/WrapperIcon';
import TooltipOnHover from 'src/components/shared/molecules/tooltipOnHover/TooltipOnHover';
import { getUrlParams } from 'src/utils/src/shared/getUrlParams';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { ubdateModule } from 'src/store/src/menu';
import styles from './ShortcutItem.module.css';

const ShortcutItem: FC<ShortcutItemProps> = ({
    getDims,
    content,
    titleIsVisible,
    toCut,
    showDivider
}) => {
    const strs = content.name.split(' ');
    const icon = React.useRef<any>();
    const title = React.useRef<any>();
    const dispatchRedux = useAppDispatch();
    const menuStructureData = useTypedSelector((state) => state.menu.menuStructureData);
    React.useEffect(() => {
        if (
            title?.current?.offsetWidth &&
            title?.current?.offsetWidth > 0 &&
            icon?.current?.offsetWidth &&
            icon?.current?.offsetWidth > 0 &&
            getDims !== undefined
        ) {
            getDims(title.current?.offsetWidth || 0, icon.current?.offsetWidth || 0);
        }
    }, [icon.current?.offsetWidth, title.current?.offsetWidth]);

    const setMenuState = () => {
        MenuStateManager.ubdateMenuModuleData({
            menuStructureData,
            dispatch: dispatchRedux,
            ubdateModule,
            screenSelected: content.id,
            moduleSelected: null
        });
    };

    return (
        <Box sx={{ display: 'flex', visibility: toCut ? 'visible' : 'hidden' }}>
            <TooltipOnHover
                title={content.name}
                disableFocusListener={titleIsVisible}
                disableHoverListener={titleIsVisible}
                disableTouchListener={titleIsVisible}>
                <div className={styles.wrapperIcon} ref={icon}>
                    <WrapperIcon>
                        <LegacyCompatibleLink {...getUrlParams(content)} outerFn={setMenuState}>
                            <ThemeCompatibleIcon
                                iconData={content.icon}
                                sx={{
                                    position: 'absolute',
                                    fontSize: '1rem',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    color: 'primary.main'
                                }}
                            />
                        </LegacyCompatibleLink>
                    </WrapperIcon>
                </div>
            </TooltipOnHover>
            {titleIsVisible && (
                <div className={styles.wrapperText} ref={title}>
                    <LegacyCompatibleLink {...getUrlParams(content)}>
                        <p key={strs[0]} className={`${styles.text}`}>
                            {strs[0]}
                        </p>
                    </LegacyCompatibleLink>
                    <div className={styles.wrapperTextInner}>
                        {strs.map((str, i) => {
                            if (i) {
                                return (
                                    <div key={i}>
                                        <LegacyCompatibleLink {...getUrlParams(content)}>
                                            <p className={`${styles.text} `}>{str}</p>
                                        </LegacyCompatibleLink>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            )}
            {showDivider && (
                <Divider
                    orientation="vertical"
                    sx={{
                        paddingRight: {
                            xs: '5px',
                            lg: 1
                        },
                        paddingLeft: '0px',
                        height: '30px',
                        marginTop: '2px'
                    }}
                    flexItem
                />
            )}
        </Box>
    );
};

export default ShortcutItem;
