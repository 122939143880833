import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

export async function changePassword(
    userId: string,
    userScreen: string,
    additionalVerfication: boolean,
    newPassword: string,
    actualUserPassword: string
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        `api_react/src/shared/changePassword.php?userId=${userId}&userScreen=${userScreen}&additionalVerfication=${
            additionalVerfication ? 1 : 0
        }`,
        'POST',
        {
            newPassword,
            actualPassword: actualUserPassword
        }
    );
}
