import { FieldText } from 'src/components/popupFormExtended/dynamicField/fieldText/types';
import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';

type FieldDependentTekstManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldText;
};

export class FieldDependentTekstManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldText;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentTekstManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }
}
