import { budgetStableFields } from 'src/constants/budget/budgetStableFields';
import { FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import {
    BudgetOperationalState,
    BudgetOperationalStateFormFieldsStateKeys
} from 'src/hooks/src/budget/useFormBudget';
import { SettledObjectsCodes } from 'src/store/src/budget/budget/types';
import { BudgetGetterOperationalState } from 'src/utils/src/budget/BudgetGetterOperationalState';

import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { createKeyFieldBudget } from 'src/utils/src/budget/createKeyFieldBudget';
import { updateKeyReset } from 'src/utils/src/shared/updateKeyReset';

type BudgetSetterOperationalStateProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};
export class BudgetSetterOperationalState extends BudgetGetterOperationalState {
    constructor({ budget, operationalState }: BudgetSetterOperationalStateProps) {
        super({ budget, operationalState });
    }

    protected setToOSStructure(code: SettledObjectsCodes) {
        this.operationalState.structure = this.budget.settledObjectsStructures[code];
    }

    protected setToOSFieldsStructure({
        settled,
        settledInThisSettlingObject,
        code
    }: {
        settled: boolean;
        code: SettledObjectsCodes;
        settledInThisSettlingObject?: boolean;
    }) {
        if (this.operationalState.structure) {
            if (settled) {
                if (settledInThisSettlingObject) {
                    this.operationalState.fieldsStructure = [
                        ...this.operationalState.structure.fields,
                        budgetStableFields[code]
                    ];
                } else {
                    this.operationalState.fieldsStructure = [
                        ...this.operationalState.structure.fields
                    ];
                }
            }

            if (budgetStableFields[code]) {
                this.operationalState.fieldsStructure = [
                    ...this.operationalState.structure.fields,
                    budgetStableFields[code]
                ];
            } else {
                console.error('Lack of stable fields in budgetStableFields');
            }
            return [...this.operationalState.structure.fields];
        }
    }

    protected updateOSBothTempStateField(data: {
        name: string;
        objectId: string;
        monthId?: string;
        newState: FieldsStateUnionRHF;
        updateReset?: boolean;
        methodId: string;
    }) {
        const { keyField } = createKeyFieldBudget(data);
        const fieldState: FieldsStateUnionRHF = this.getFieldStateFromOS(data);
        const stateToUpdate: FieldsStateUnionRHF = {
            ...fieldState,
            ...data.newState
        };
        if (data.updateReset) {
            stateToUpdate.keyReset = updateKeyReset(fieldState.keyReset);
        }
        Object.assign(this.operationalState.fieldsTempFromRHF, { [keyField]: stateToUpdate });
        Object.assign(this.operationalState.fieldsTemp, { [keyField]: stateToUpdate });
    }

    protected updateOSByKeyField({
        newState,
        keyField,
        operationalStateKeys,
        updateReset
    }: {
        newState: FieldsStateUnionRHF;
        keyField: string;
        operationalStateKeys: BudgetOperationalStateFormFieldsStateKeys[];
        updateReset?: boolean;
    }) {
        const fieldState: FieldsStateUnionRHF =
            this.getFieldStateFromOSByKeyField(keyField, 'fieldsTempFromRHF') ?? {};

        const stateToUpdate: FieldsStateUnionRHF = {
            keyReset: 1,
            ...fieldState,
            ...newState
        };
        if (updateReset) {
            stateToUpdate.keyReset = updateKeyReset(fieldState.keyReset);
        }
        operationalStateKeys.forEach((key) => {
            Object.assign(this.operationalState[key], { [keyField]: stateToUpdate });
        });
    }

    sortObjectsToCalcLimit() {
        for (const key in this.operationalState.objectsToCalcLimit) {
            this.operationalState.objectsToCalcLimit[key].sort((a, b) => {
                return a.timestamp - b.timestamp;
            });
        }
    }
}
