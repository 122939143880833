import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { PopupFormExtendedApiData } from 'src/data/popupFormTypes';
import { KeyValueObject } from 'src/data/types';

export async function getPopupFormExtendedData(
    screen: string,
    objectId: string,
    additionalParameters?: string,
    hiddenData?: KeyValueObject
): Promise<BaseApiResponse<PopupFormExtendedApiData>> {
    const params = new URLSearchParams(additionalParameters);
    params.set('screen', screen);
    params.set('objectId', objectId);

    return simpleQueryAPIData(
        `api_react/src/popupFormExtended/getPopupFormExtendedData.php?${params.toString()}`,
        'POST',
        hiddenData,
        true
    );
}
