import { ObjectSlice } from 'src/store/src/object/object/types';
import { RowData } from 'src/store/src/listScreen/tableData/types';
import { PopupState } from 'src/store/src/popup/popup/types';
import { TemplateMainItem } from 'src/utils/src/shared/TemplateMainItem';
import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { ObjectDataManager } from 'src/store/src/object/object/ObjectDataManager';
import { getCellRawValueOrTextFromListCellRow } from 'src/utils';

type DynamicFormHelperProps = {
    objectSlice: ObjectSlice;
    rowData: RowData[];
    rowId?: string;
    popupState: PopupState;
};

type GetCheckTemplateRawValueTextFProps = {
    str: string;
    sign: string;
    type: 'text' | 'rawValue';
};

type GetRawValueTextFProps = {
    str: string;
    sign: string;
    type: 'text' | 'rawValue';
};

export class DynamicFormHelper {
    objectSlice: ObjectSlice;
    rowData: RowData[];
    popupState: PopupState;
    rowId?: string;
    constructor({ objectSlice, rowData, popupState, rowId }: DynamicFormHelperProps) {
        this.popupState = popupState;
        this.objectSlice = objectSlice;
        this.rowData = rowData;
        this.rowId = rowId;
    }

    getCheckTemplateRawValueOrText({ str, sign, type }: GetCheckTemplateRawValueTextFProps) {
        let content = str ? str : '';
        const templateData = new TemplateMainItem({ itemStr: str }).getData();
        switch (sign) {
            case '!':
                {
                    let textFromRow: string = '';
                    switch (this.popupState?.sourceOfTrigger.type) {
                        case 'list':
                            {
                                const rawValues = new TableDataManager({
                                    rowData: this.rowData,
                                    rowId: this.rowId ?? this.popupState.objectId[0]
                                }).getTextOrRawValue({ key: templateData.contentClear, type });
                                if (Array.isArray(rawValues)) {
                                    textFromRow = rawValues.join(',');
                                }
                            }
                            break;
                        case 'object':
                            {
                                const rawValues = new ObjectDataManager({
                                    objectSlice: this.objectSlice,
                                    additional: this.popupState.objectAdditional
                                }).getCellRawValueOrText({
                                    key: templateData.contentClear,
                                    type,
                                    onlyFirstValue: false
                                });
                                if (Array.isArray(rawValues)) {
                                    textFromRow = rawValues.join(',');
                                }
                            }
                            break;
                        case 'startupCardList':
                            textFromRow = getCellRawValueOrTextFromListCellRow({
                                key: templateData.contentClear,
                                type: 'text',
                                onlyFirstValue: true,
                                cellRow: this.popupState.sourceOfTrigger.cellRow
                            });
                            break;
                        default:
                            break;
                    }
                    if (!textFromRow) {
                        content = new TemplateMainItem({ itemStr: str }).replaceTemplateWithContent(
                            ''
                        );
                    } else {
                        content = new TemplateMainItem({ itemStr: str }).replaceTemplateWithContent(
                            textFromRow
                        );
                    }
                }
                break;
            default:
                break;
        }

        return content;
    }
    getRawValueOrText({ str, sign, type }: GetRawValueTextFProps) {
        let content: any = null;
        const templateData = new TemplateMainItem({ itemStr: str }).getData();
        switch (sign) {
            case '$':
                {
                    let value;
                    switch (this.popupState.sourceOfTrigger.type) {
                        case 'list':
                            value = new TableDataManager({
                                rowData: this.rowData,
                                rowId: this.rowId ?? this.popupState.objectId[0]
                            }).getTextOrRawValue({ key: templateData.contentClear, type });

                            break;
                        case 'object':
                            value = new ObjectDataManager({
                                objectSlice: this.objectSlice,
                                additional: this.popupState.objectAdditional
                            }).getCellRawValueOrText({
                                key: templateData.contentClear,
                                type,
                                onlyFirstValue: false
                            });
                            break;
                        default:
                            break;
                    }
                    if (value) {
                        content = value;
                    }
                }
                break;
            default:
                break;
        }
        return content;
    }
}
