import { useEffect } from 'react';
import { AppDispatch, fetchAndHandleCalendarData } from '../../../store/src/calendar/index';

/**
 * Hook mounts global function that is then called after creation of new object
 */
export function useObjectCreationHandler(dispatch: AppDispatch) {
    useEffect(() => {
        window.HandlePopupResult_obiekt_kalendarza = function (result) {
            dispatch(fetchAndHandleCalendarData());
        };
    }, [dispatch]);
}
