import {
    BudgetSingleFieldsCodes,
    BugdetFormVisibleFieldStructureAll
} from 'src/store/src/budget/budget/types';

export const budgetSingleFields: {
    [key in BudgetSingleFieldsCodes]: BugdetFormVisibleFieldStructureAll;
} = {
    invoice_all_in_case: {
        id: '1002',
        code: 'x',
        name: 'invoice_all_in_case_{$item.id}_{$miesiac.data_rozp}',
        type: 'checkbox'
    }
};
