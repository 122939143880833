import React, { FC } from 'react';

import { UIFieldObjectNumberProps } from './types';
import { UiFieldLabel } from '../shared/uiFieldLabel/UiFieldLabel';
import styles from './UIFieldObjectNumber.module.css';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

export const UIFieldObjectNumber: FC<UIFieldObjectNumberProps> = (props) => {
    const {
        value,
        onChangePrefix,
        onBlurPrefix,
        onChangeSuffix,
        onBlurSuffix,
        onChangeInterfix,
        onBlurInterfix,
        showPrefix,
        showInterfix,
        showSuffix,
        textBeforeInterFix,
        textAfterInterFix,
        prefixOptions,
        interfixOptions,
        suffixOptions,
        uiFieldCommonProps,
        uiFieldSharedProps
    } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                {showPrefix && (
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.prefix}
                        opcje={prefixOptions}
                        onChange={onChangePrefix}
                        onBlur={onBlurPrefix}
                    />
                )}
                <p className={styles.p}>{textBeforeInterFix}</p>
                {showInterfix && (
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.interfix}
                        opcje={interfixOptions}
                        onChange={onChangeInterfix}
                        onBlur={onBlurInterfix}
                    />
                )}
                <p className={styles.p}>{textAfterInterFix}</p>
                {showSuffix && (
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.suffix}
                        opcje={suffixOptions}
                        onChange={onChangeSuffix}
                        onBlur={onBlurSuffix}
                    />
                )}
            </div>
        </UiWrapper>
    );
};
