import { NavigateFunction } from 'react-router-dom';
import { NAV_DO_NOT_CLEAR_BACK_LEVEL, NAV_BACK_LEVEL } from 'src/constants';
import { makeEditSafeNavigation } from 'src/utils';

export const legacyCompatibleNavigateBack = (navigate: NavigateFunction) => {
    // lock going back before first mecenas entry in history
    if (
        window.history.state?.mecenashistoryStartPoint ??
        window.history.state?.usr?.mecenashistoryStartPoint
    ) {
        console.info('Cannot go back, this is last mecenas page in history');
        return;
    }

    makeEditSafeNavigation(() => {
        // leave information in sessionStorage to display forward button
        sessionStorage.setItem(NAV_DO_NOT_CLEAR_BACK_LEVEL, '1');
        const backLevel = parseInt(sessionStorage.getItem(NAV_BACK_LEVEL) ?? '');
        sessionStorage.setItem(NAV_BACK_LEVEL, (backLevel + 1).toString());

        navigate(-1);
    });
};
