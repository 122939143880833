import React, { FC } from 'react';
import { Checkbox } from '@mui/material';
import { UiInputCheckboxProps } from 'src/components/shared/atoms/uiInputs/types';
import { UiInputElWrapper } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElWrapper/UiInputElWrapper';
import { UiInputElLabel } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElLabel/UiInputElLabel';

export const UiCheckbox: FC<UiInputCheckboxProps> = (props) => {
    return (
        <UiInputElWrapper
            {...props}
            wew={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Checkbox sx={props.sxcheckbox} {...props} />
            {props.label && <UiInputElLabel {...props} />}
        </UiInputElWrapper>
    );
};
