import { AnyJSONResponse } from '../types';

/**
 * Fetches information from server
 */
export async function simpleQueryAnyText({
    url,
    method = 'GET',
    contentType = 'text/plain',
    bodyContent = undefined,
    parseBody = true,
    additionalFetchinit
}: {
    url: string;
    method?: 'GET' | 'POST' | 'DELETE';
    contentType?: string;
    bodyContent?: any;
    parseBody?: boolean;
    additionalFetchinit?: object;
}): Promise<string> {
    let parsedBody: any = bodyContent;
    if (bodyContent && parseBody) {
        parsedBody = JSON.stringify(bodyContent);
    }

    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': contentType
        },
        body: parsedBody,
        ...additionalFetchinit
    });

    const dataPrimary = await response.text();

    return dataPrimary;
}
