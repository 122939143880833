import React, { FC } from 'react';

import { useAppDispatch } from 'src/store';
import { LinkActionProps } from 'src/components/object/atoms/actionType/linkAction/types';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { LegacyCompatibleLinkCreatedOnClick } from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLinkCreatedOnClick';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';

export const LinkAction: FC<LinkActionProps> = ({ children, action, additional, id }) => {
    const dispatch = useAppDispatch();

    const handleConfirm = async () => {
        handleSetPopupConfirmationLink();
    };
    const sourceOfTrigger: SourceOfTrigger = {
        type: 'object',
        typeWew: additional.typeSection
    };

    const handleSetPopupConfirmationLink = () => {
        dispatch(setSpinner(false));
        new PopupManager({ dispatch, action }).setPopupConfirmLink({
            rowId: [id],
            sourceOfTrigger,
            additional
        });
    };

    if (action.behaviour.data.confirmText) {
        return <div onClick={handleConfirm}>{children}</div>;
    } else {
        return (
            <LegacyCompatibleLinkCreatedOnClick
                action={action}
                rowId={id}
                additional={additional}
                sourceOfTrigger={sourceOfTrigger}>
                {children}
            </LegacyCompatibleLinkCreatedOnClick>
        );
    }
};
