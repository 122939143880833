import { FieldCheckboxStateRHF, FieldNumberStateRHF } from 'src/data/fieldsReactHookForm';
import {
    BugdetFieldTypes,
    BugdetMonthLevel,
    BugdetSettlementMethodLevel
} from 'src/store/src/budget/budget/types';
import {
    CreateKeySumSharedProps,
    createKeySumBudget
} from 'src/utils/src/budget/createKeySumBudget';
import { getStableFieldsSums } from 'src/utils/src/budget/getStableFieldsSums';
import { BudgetStableFieldSumType } from 'src/constants/budget/budgetStableFieldSum';
import {
    divideMainPartSumKey,
    getKeySumToMatchForSpecialStakeForCaseItem,
    getTypeOfFieldFromKeyInBudget,
    getVat,
    isIncludedToSum
} from 'src/utils';
import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { TypeOfMatchedKeySum, isMatchedKeySum } from 'src/utils/src/budget/isMatchedKeySum';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { createTimestampByAddThreeZero } from 'src/utils/src/budget/createTimestampByAddThreeZero';

// Class working on onChange and onMount

export type CalcSumFn = CreateKeySumSharedProps & {
    type: BudgetStableFieldSumType;
    method: BugdetSettlementMethodLevel;
    month?: BugdetMonthLevel;
    typeOfMatchedKeySum?: TypeOfMatchedKeySum;
};

type BudgetHandlerStateSumProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};
type SumDataTempCondition = {
    value: number;
    keyId: string;
};

type SumDataTempSpecialStake = {
    value: boolean;
    keyId: string;
};

type SumDataTemp = {
    sum: number;
    sumsCondition: SumDataTempCondition[];
    specialStake: SumDataTempSpecialStake[];
};

function getSumDataTempInit(): SumDataTemp {
    return {
        sum: 0,
        sumsCondition: [],
        specialStake: []
    };
}

export const prefixKeySumSpecialStake = 'stawkaspecjalna';
const prefixKeySumLimitHour = 'limit_godzin';
const prefixKeySumsConditions = ['kontakty', 'rozprawy', 'zadania'];
export class BudgetHandlerStateSum extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetHandlerStateSumProps) {
        super({ budget, operationalState });
    }

    calcSumFromField(data: CalcSumFn) {
        const { type, method, month, typeOfMatchedKeySum } = data;
        const dataStable = getStableFieldsSums({ type });

        if (dataStable.isSum) {
            dataStable.fields?.forEach((field) => {
                if (!field.empty && !field.text) {
                    const { keySum } = createKeySumBudget({
                        ...data,
                        fieldId: field.id,
                        methodId: method.id,
                        monthId: month?.id
                    });
                    if (keySum && method?.id) {
                        if (field.code) {
                            this.operationalState.fieldsTemp[keySum] = {
                                liczba: String(method[field.code]),
                                keyReset: 1
                            };
                        } else {
                            const data: SumDataTemp = getSumDataTempInit();

                            for (const keyState in this.operationalState.fieldsTemp) {
                                if (this.operationalState.fieldsTemp?.[keyState]?.keyId) {
                                    if (
                                        isMatchedKeySum(
                                            keySum,
                                            this.operationalState.fieldsTemp?.[keyState].keyId,
                                            typeOfMatchedKeySum
                                        )
                                    ) {
                                        if (
                                            !this.operationalState.fieldsTemp[keyState]
                                                .isExcludedFromSum
                                        ) {
                                            const fieldType =
                                                getTypeOfFieldFromKeyInBudget(keyState);
                                            this.prepereSumDataFromField({
                                                data,
                                                keyState,
                                                fieldType
                                            });
                                        }
                                    }
                                }
                            }
                            this.operationalState.sumTemp[keySum] = {
                                liczba: String(this.sumAllWithoutLimit({ data })),
                                keyReset: 1
                            };
                        }
                    }
                }
            });
        }
    }

    calcSumMonthFromField(data: CalcSumFn) {
        const { type, method, month, typeOfMatchedKeySum } = data;
        const dataStable = getStableFieldsSums({ type });
        if (dataStable.isSum) {
            dataStable.fields?.forEach((field) => {
                if (!field.empty && !field.text) {
                    const { keySum } = createKeySumBudget({
                        ...data,
                        fieldId: field.id,
                        methodId: method.id,
                        monthId: month?.id
                    });
                    if (keySum && method?.id) {
                        if (field.code) {
                            this.operationalState.fieldsTemp[keySum] = {
                                liczba: String(method[field.code]),
                                keyReset: 1
                            };
                        } else {
                            if (field.id === '12' || field.id === '15') {
                                const index = field.id === '12' ? 0 : 1;
                                const data: SumDataTemp = getSumDataTempInit();
                                for (const keyState in this.operationalState.fieldsTemp) {
                                    if (this.operationalState.fieldsTemp?.[keyState]?.keyId) {
                                        if (
                                            isMatchedKeySum(
                                                keySum,
                                                this.operationalState.fieldsTemp?.[keyState].keyId,
                                                typeOfMatchedKeySum
                                            )
                                        ) {
                                            if (
                                                !this.operationalState.fieldsTemp[keyState]
                                                    .isExcludedFromSum
                                            ) {
                                                const fieldType =
                                                    getTypeOfFieldFromKeyInBudget(keyState);
                                                this.prepereSumDataFromField({
                                                    data,
                                                    keyState,
                                                    fieldType
                                                });
                                            }
                                        }
                                    }
                                }
                                const keyLimit =
                                    this.operationalState.templateMonthLimit?.[method.id]?.[
                                        createTimestampByAddThreeZero(month?.id ?? '')
                                    ]?.[index];
                                this.operationalState.sumTemp[keySum] = {
                                    liczba: String(this.sumWithLimit({ data, keyLimit })),
                                    keyReset: 1
                                };
                            }
                        }
                    }
                }
            });
        }
    }

    calcSumFromSum(data: CalcSumFn) {
        const { type, method, month } = data;
        const dataStable = getStableFieldsSums({ type });
        if (dataStable.isSum) {
            dataStable.fields?.forEach((field) => {
                if (!field.empty && !field.text) {
                    const { keySum } = createKeySumBudget({
                        ...data,
                        fieldId: field.id,
                        methodId: method.id,
                        monthId: month?.id
                    });
                    if (keySum && method?.id) {
                        if (field.code) {
                            this.operationalState.fieldsTemp[keySum] = {
                                liczba: String(method[field.code]),
                                keyReset: 1
                            };
                        } else {
                            const data: SumDataTemp = getSumDataTempInit();
                            for (const keySumInTempState in this.operationalState.sumTemp) {
                                if (isIncludedToSum({ keySumInTempState, keySum })) {
                                    this.prepereSumDataFromSum({
                                        data,
                                        keyState: keySumInTempState
                                    });
                                }
                            }
                            this.operationalState.sumTemp[keySum] = {
                                liczba: String(this.sumAllWithoutLimit({ data })),
                                keyReset: 1
                            };
                        }
                    }
                }
            });
        }
    }

    calcBeforeCapfee(data: CalcSumFn) {
        const { type, method, month } = data;
        const dataStable = getStableFieldsSums({ type });
        if (dataStable.isSum) {
            dataStable.fields?.forEach((field) => {
                if (!field.empty && !field.text) {
                    const { keySum } = createKeySumBudget({
                        ...data,
                        fieldId: field.id,
                        methodId: method.id,
                        monthId: month?.id
                    });
                    switch (field.id) {
                        case '12':
                        case '15':
                            {
                                const data: SumDataTemp = getSumDataTempInit();
                                for (const keySumInTempState in this.operationalState.sumTemp) {
                                    if (
                                        isMatchedKeySum(
                                            keySum,
                                            keySumInTempState,
                                            'podsumowanieZSumTwo'
                                        )
                                    ) {
                                        this.prepereSumDataFromSum({
                                            data,
                                            keyState: keySumInTempState
                                        });
                                    }
                                }
                                for (const keyState in this.operationalState.fieldsTemp) {
                                    if (this.operationalState.fieldsTemp?.[keyState]?.keyId) {
                                        if (
                                            isMatchedKeySum(
                                                keySum,
                                                this.operationalState.fieldsTemp?.[keyState].keyId,
                                                'podsumowanieZPol'
                                            )
                                        ) {
                                            if (
                                                !this.operationalState.fieldsTemp[keyState]
                                                    .isExcludedFromSum
                                            ) {
                                                const fieldType =
                                                    getTypeOfFieldFromKeyInBudget(keyState);
                                                this.prepereSumDataFromField({
                                                    data,
                                                    keyState,
                                                    fieldType
                                                });
                                            }
                                        }
                                    }
                                }

                                this.operationalState.sumTemp[keySum] = {
                                    liczba: String(this.sumAllWithoutLimit({ data })),
                                    keyReset: 1
                                };
                            }
                            break;

                        default:
                            break;
                    }
                }
            });
        }
    }

    calcCapFeeFromSum(data: CalcSumFn) {
        const { type, method, month } = data;
        const dataStable = getStableFieldsSums({ type });
        if (dataStable.isSum) {
            dataStable.fields?.forEach((field) => {
                if (!field.empty && !field.text) {
                    const { keySum } = createKeySumBudget({
                        ...data,
                        fieldId: field.id,
                        methodId: method.id,
                        monthId: month?.id
                    });
                    switch (field.id) {
                        case '9':
                            {
                                const data = this.getCapFee(field.id);
                                this.operationalState.sumTemp[keySum] = {
                                    liczba: String(data),
                                    keyReset: 1
                                };
                            }
                            break;
                        case '12':
                        case '15':
                            {
                                const dataMinus = this.getCapFee(field.id);
                                const data: SumDataTemp = getSumDataTempInit();
                                for (const keySumInTempState in this.operationalState.sumTemp) {
                                    if (isMatchedKeySum(keySum, keySumInTempState, 'capfee')) {
                                        this.prepereSumDataFromSum({
                                            data,
                                            keyState: keySumInTempState
                                        });
                                    }
                                }

                                this.operationalState.sumTemp[keySum] = {
                                    liczba: String(dataMinus - this.sumAllWithoutLimit({ data })),
                                    keyReset: 1
                                };
                            }
                            break;

                        default:
                            break;
                    }
                }
            });
        }
    }

    calcSummary(data: CalcSumFn) {
        const { type, method, month } = data;
        const dataStable = getStableFieldsSums({ type });
        if (dataStable.isSum) {
            dataStable.fields?.forEach((field) => {
                if (!field.empty && !field.text) {
                    const { keySum } = createKeySumBudget({
                        ...data,
                        fieldId: field.id,
                        methodId: method.id,
                        monthId: month?.id
                    });
                    switch (field.id) {
                        case '12':
                        case '15':
                            {
                                const dataMinus = this.getCapFee(field.id);
                                const data: SumDataTemp = getSumDataTempInit();
                                if (dataMinus) {
                                    for (const keySumInTempState in this.operationalState.sumTemp) {
                                        if (
                                            isMatchedKeySum(
                                                keySum,
                                                keySumInTempState,
                                                'podsumowanieZSum'
                                            )
                                        ) {
                                            this.prepereSumDataFromSum({
                                                data,
                                                keyState: keySumInTempState
                                            });
                                        }
                                    }
                                } else {
                                    for (const keySumInTempState in this.operationalState.sumTemp) {
                                        if (
                                            isMatchedKeySum(
                                                keySum,
                                                keySumInTempState,
                                                'podsumowanieZSumTwo'
                                            )
                                        ) {
                                            this.prepereSumDataFromSum({
                                                data,
                                                keyState: keySumInTempState
                                            });
                                        }
                                    }
                                    for (const keyState in this.operationalState.fieldsTemp) {
                                        if (this.operationalState.fieldsTemp?.[keyState]?.keyId) {
                                            if (
                                                isMatchedKeySum(
                                                    keySum,
                                                    this.operationalState.fieldsTemp?.[keyState]
                                                        .keyId,
                                                    'podsumowanieZPol'
                                                )
                                            ) {
                                                if (
                                                    !this.operationalState.fieldsTemp[keyState]
                                                        .isExcludedFromSum
                                                ) {
                                                    const fieldType =
                                                        getTypeOfFieldFromKeyInBudget(keyState);
                                                    this.prepereSumDataFromField({
                                                        data,
                                                        keyState,
                                                        fieldType
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                                this.operationalState.sumTemp[keySum] = {
                                    liczba: String(this.sumAllWithoutLimit({ data })),
                                    keyReset: 1
                                };
                            }
                            break;

                        default:
                            break;
                    }
                }
            });
        }
    }

    private getCapFee(id: string) {
        if (this.operationalState.method?.capfee) {
            switch (id) {
                case '9':
                    return this.operationalState.method.capfee.capfee;
                case '12':
                    return (
                        this.operationalState.method.capfee.capfee *
                        this.operationalState.method.capfee.capfee_kurs
                    );
                case '15':
                    return (
                        this.operationalState.method.capfee.capfee *
                        this.operationalState.method.capfee.capfee_kurs *
                        getVat(this.operationalState.method.capfee.capfee_vat)
                    );
                default:
                    return 0;
            }
        }
        return 0;
    }

    private prepereSumDataFromField({
        data,
        keyState,
        fieldType
    }: {
        data: SumDataTemp;
        keyState: string;
        fieldType: BugdetFieldTypes;
    }) {
        switch (fieldType) {
            case 'liczba': {
                const oneObj: Required<FieldNumberStateRHF> = this.operationalState.fieldsTemp[
                    keyState
                ] as Required<FieldNumberStateRHF>;
                if (oneObj?.liczba) {
                    this.distributeSumData({
                        data,
                        keyId: oneObj.keyId,
                        valueNumber: Number(oneObj?.liczba),
                        valueBoolean: false
                    });
                }
                break;
            }
            case 'checkbox': {
                const oneObj: Required<FieldCheckboxStateRHF> = this.operationalState.fieldsTemp[
                    keyState
                ] as Required<FieldCheckboxStateRHF>;
                if (typeof oneObj?.checkbox === 'boolean') {
                    this.distributeSumData({
                        data,
                        keyId: oneObj.keyId,
                        valueNumber: 0,
                        valueBoolean: oneObj?.checkbox
                    });
                }
                break;
            }
        }
    }

    private prepereSumDataFromSum({ data, keyState }: { data: SumDataTemp; keyState: string }) {
        const fieldType = 'liczba';
        switch (fieldType) {
            case 'liczba': {
                const oneObj: Required<FieldNumberStateRHF> = this.operationalState.sumTemp[
                    keyState
                ] as Required<FieldNumberStateRHF>;
                data.sum = data.sum + Number(oneObj?.liczba);
                break;
            }
        }
    }

    private distributeSumData({
        data,
        keyId,
        valueNumber,
        valueBoolean
    }: {
        data: SumDataTemp;
        keyId: string;
        valueNumber: number;
        valueBoolean: boolean;
    }) {
        const { prefix } = divideMainPartSumKey(keyId);
        if (prefix === prefixKeySumLimitHour) {
            // data.limit = data.limit + valueNumber;
        } else if (prefix === prefixKeySumSpecialStake) {
            data.specialStake.push({
                value: valueBoolean,
                keyId
            });
        } else if (prefixKeySumsConditions.includes(prefix)) {
            data.sumsCondition.push({ value: valueNumber, keyId });
        } else {
            data.sum = data.sum + valueNumber;
        }
    }

    private sumAllWithoutLimit({ data }: { data: SumDataTemp }) {
        return data.sumsCondition.reduce(function (acc, sumCondition) {
            return acc + sumCondition.value;
        }, data.sum);
    }

    private sumWithLimit({ data, keyLimit }: { data: SumDataTemp; keyLimit: string }) {
        const limit = this.getValueNumberFromOSByKeyField(keyLimit, 'limitTempToCalc');
        let sum: number = 0;
        let sumBeSubjectedToLimit = 0;
        let sumNotBeSubjectedToLimit = data.sum;
        data.sumsCondition.forEach((sumCondition) => {
            const keyToMath = getKeySumToMatchForSpecialStakeForCaseItem(sumCondition.keyId);
            let isSpecialStake = false;
            data.specialStake.forEach((specialStake) => {
                if (specialStake.keyId.includes(keyToMath)) {
                    isSpecialStake = specialStake.value;
                }
            });
            if (isSpecialStake) {
                sumNotBeSubjectedToLimit += sumCondition.value;
            } else {
                sumBeSubjectedToLimit += sumCondition.value;
            }
        });
        const summedInLimit = sumBeSubjectedToLimit + limit;
        if (summedInLimit > 0) {
            sum = sumNotBeSubjectedToLimit + summedInLimit;
            this.updateOSByKeyField({
                newState: { liczba: String(0) },
                keyField: keyLimit,
                operationalStateKeys: ['limitTempToCalc']
            });
        } else {
            sum = sumNotBeSubjectedToLimit;
            this.updateOSByKeyField({
                newState: { liczba: String(summedInLimit) },
                keyField: keyLimit,
                operationalStateKeys: ['limitTempToCalc']
            });
        }
        return sum;
    }
}
