import { TemplateGlobalMainManager } from './TemplateGlobalMainManager';
import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { TransformationManager } from 'src/store/src/listScreen/transformations/TransformationManager';
import { ObjectDataManager } from 'src/store/src/object/object/ObjectDataManager';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { TransformationsState } from 'src/store/src/listScreen/transformations/types';
import { SystemAction } from 'src/data/types';
import { ObjectSlice } from 'src/store/src/object/object/types';
import { Additional } from 'src/store/src/object/object/types';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { TemplateMainItem } from 'src/utils/src/shared/TemplateMainItem';
import { TreeTableState } from 'src/store/src/listScreen/treeData/types';
import { TreeDataManager } from 'src/store/src/listScreen/treeData/TreeDataManager';

const regexObjId = /(@objectId)/;
const regexListScreenParams = /(@listScreenParams)/;
const regexListScreenExcelParams = /(@listScreenExcelParams)/;

type TemplateMonkeyProps = {
    temporaryAllString: string;
    templateMainItem: TemplateMainItem;
    rowData: RowData[];
    totalIds?: string[];
    rowId?: RowDataId | null;
    transformationState: TransformationsState;
    screen?: null | string;
    action?: SystemAction;
    objectSlice: ObjectSlice;
    additional: Additional;
    sourceOfTrigger: SourceOfTrigger;
    treeData?: TreeTableState;
};

export class TemplateMainMonkey extends TemplateGlobalMainManager {
    temporaryAllString: string;
    templateMainItem: TemplateMainItem;
    rowData: RowData[];
    rowId?: RowDataId | null;
    objectSlice: ObjectSlice;
    additional: Additional;
    sourceOfTrigger: SourceOfTrigger;
    transformationState: TransformationsState;
    screen?: null | string;
    totalIds?: string[];
    action?: SystemAction;
    treeData?: TreeTableState;

    constructor({
        templateMainItem,
        rowData,
        rowId,
        temporaryAllString,
        transformationState,
        screen,
        totalIds,
        action,
        objectSlice,
        additional,
        sourceOfTrigger,
        treeData
    }: TemplateMonkeyProps) {
        super();
        this.templateMainItem = templateMainItem;
        this.temporaryAllString = temporaryAllString;
        this.rowData = rowData;
        this.rowId = rowId;
        this.totalIds = totalIds;
        this.objectSlice = objectSlice;
        this.additional = additional;
        this.sourceOfTrigger = sourceOfTrigger;
        this.transformationState = transformationState;
        this.screen = screen;
        this.action = action;
        this.treeData = treeData;
    }
    getData() {
        switch (this.sourceOfTrigger.type) {
            case 'startupCardList':
                if (this.templateMainItem.content.match(regexObjId)) {
                    return this.replaceContent({
                        contentToReplace: this.sourceOfTrigger.cellRow.id,
                        templateMainItem: this.templateMainItem,
                        temporaryAllString: this.temporaryAllString
                    });
                }
                return '';
            case 'object':
                if (this.templateMainItem.content.match(regexObjId)) {
                    const data = new ObjectDataManager({
                        objectSlice: this.objectSlice,
                        additional: this.additional
                    });
                    return this.replaceContent({
                        contentToReplace: data.listCellRow?.id,
                        templateMainItem: this.templateMainItem,
                        temporaryAllString: this.temporaryAllString
                    });
                }
                return '';
            case 'list':
                if (this.templateMainItem.content.match(regexObjId)) {
                    const ids =
                        this.sourceOfTrigger.view === 'drzewo'
                            ? new TreeDataManager({
                                  treeNode: this.treeData?.treeNode
                              }).getAllObjectIds()
                            : new TableDataManager({
                                  rowData: this.rowData,
                                  rowId: this.rowId,
                                  totalIds: this.totalIds,
                                  action: this.action
                              }).getRowsIdBaseOnTemplate2(this.templateMainItem);
                    return this.replaceContent({
                        contentToReplace: ids.join(','),
                        templateMainItem: this.templateMainItem,
                        temporaryAllString: this.temporaryAllString
                    });
                } else if (this.templateMainItem.content.match(regexListScreenParams)) {
                    const str = new TransformationManager({
                        transformationState: this.transformationState
                    }).getPagingAsParams({ merge: true });
                    return this.replaceContent({
                        contentToReplace: str,
                        templateMainItem: this.templateMainItem,
                        temporaryAllString: this.temporaryAllString
                    });
                } else if (this.templateMainItem.content.match(regexListScreenExcelParams)) {
                    const str = new TransformationManager({
                        transformationState: this.transformationState
                    }).getPagingAsParams({
                        keys: ['actualPage', 'objectsPerPage']
                    });
                    return this.replaceContent({
                        contentToReplace: str + `&screen=${this.screen}`,
                        templateMainItem: this.templateMainItem,
                        temporaryAllString: this.temporaryAllString
                    });
                }
                return '';
            default:
                return '';
        }
    }
}
