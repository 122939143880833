import React, { FC, useMemo } from 'react';

import styles from './ColumnDown.module.css';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { Field } from 'src/components/popupFormExtended/field/Field';
import { ColumnDownProps } from 'src/components/popupFormExtended/columnDown/types';
import { UiFieldCommonProps } from '../dynamicField/types';

export const ColumnDown: FC<ColumnDownProps> = ({
    column,
    fieldWidth,
    keyPopup,
    usedForAddingObject
}) => {
    const uiFieldCommonProps: UiFieldCommonProps = useMemo(
        () => ({
            isLabel: true,
            usedForAddingObject: usedForAddingObject,
            maxWidth: fieldWidth
        }),
        [usedForAddingObject, fieldWidth]
    );

    return (
        <div className={styles.column}>
            {column.fields?.map((field: DynamicFieldT) => (
                <Field
                    key={field.id}
                    keyPopup={keyPopup}
                    dynamicFieldT={field}
                    keyOfFieldInState={field.kod}
                    usedForAddingObject={usedForAddingObject}
                    uiFieldCommonProps={uiFieldCommonProps}
                />
            ))}
        </div>
    );
};
