import * as React from 'react';

import { FieldsPure } from 'src/components/popupFormExtended/dynamicField/types';
import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFormObjectCollisionValidatorProps, TypeValidateCollision } from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData, DynamicFieldT } from 'src/data/popupFormTypes';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';

import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateCollision';
// można testować na ekranie osoby dla pól nazwisko, nip, pesel
export class DynamicFormObjectCollisionValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    typeFnValidate: TypeValidateCollision;
    fieldRelatedObjectData: FieldRelatedObjectData;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectCollisionValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        for (const key in this.formState.fields) {
            if (this.dataRef.validationResult.isFormValid) {
                const dynamicFieldT = this.popupFormExtendedData.fields.find(
                    (item) => item.kod === this.formState.fields[key]?.kod
                );
                const field = this.formState.fields[key];
                if (dynamicFieldT && dynamicFieldT.weryfikacja_duplikatu === 't' && field) {
                    await this.runValidationOne(field, dynamicFieldT, key);
                }
            } else {
                break;
            }
        }
    }
    private async runValidationOne(field: FieldsPure, dynamicFieldT: DynamicFieldT, key: string) {
        let wartosc = field?.value;

        if (field?.kod === 'nazwisko') {
            wartosc = wartosc + ' ' + this.formState.fields['imie']?.value;
        }
        const params = {
            id_obiektu: this.fieldRelatedObjectData.id,
            ekran: this.fieldRelatedObjectData.screen,
            kod_pola: field?.kod,
            wartosc
        };
        const res = await getFieldAdditionalData({
            url: 'json_weryfikacja_duplikatu.php',
            method: 'GET',
            params: params
        });

        if (res.data && !res.data.valid) {
            const typeOfInfo =
                dynamicFieldT.weryfikacja_duplikatu_wykluczenie_calkowite === 't'
                    ? 'Error'
                    : 'Warning';
            if (typeOfInfo === 'Error') {
                this.setValidationResult({
                    typeOfInfo,
                    type: {
                        id_zakladki: field.id_zakladki,
                        issue: this.typeFnValidate,
                        message: res.data.message,
                        title: validationData[this.typeFnValidate].title,
                        fieldCode: field.kod
                    }
                });
            } else {
                this.setValidationResult({
                    typeOfInfo,
                    type: {
                        id_zakladki: field.id_zakladki,
                        issue: this.typeFnValidate,
                        title: validationData[this.typeFnValidate].title,
                        message: res.data.message + '. Czy chcesz kontynować?',
                        fieldCode: field.kod
                    }
                });
            }

            if (typeOfInfo === 'Error') {
                this.setError(key, field);
            }
        }
    }
}
