import React, { useCallback, useEffect, useMemo } from 'react';
import { Dispatch } from 'redux';

import { useTypedSelector, useAppDispatch } from 'src/store';
import { setLoading, updateBlocksConfig } from 'src/store/src/startupScreen';
import { closePopup, setPopupInfoTwo } from 'src/store/src/popup';
import { UseConfigVisibilityBlockStartupScreenProps } from './types';
import { StartupScreenConfig } from 'src/api/src/startupScreen/types';
import { updateStartupScreenConfiguraton } from 'src/api/src/startupScreen/updateStartupScreenConfiguraton';

export const useConfigVisibilityBlockStartupScreen = ({
    keyPopup
}: UseConfigVisibilityBlockStartupScreenProps) => {
    const [blocksConfig, setBlocksConfig] = React.useState<StartupScreenConfig>({});
    const startupScreen = useTypedSelector((state) => state.startupScreen);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const configMap: StartupScreenConfig = {};
        for (let i = 0; i < startupScreen.blocks.length; i++) {
            configMap[startupScreen.blocks[i].code] = {
                order: i,
                visible: startupScreen.blocks[i].visible
            };
        }
        setBlocksConfig(configMap);
    }, [startupScreen]);

    const blocksToPresent = useMemo(() => {
        return startupScreen.blocks
            .map((block) => ({
                code: block.code,
                title: block.title,
                visible: blocksConfig[block.code]?.visible ?? block.visible
            }))
            .sort((a, b) => blocksConfig[a.code]?.order - blocksConfig[b.code]?.order);
    }, [startupScreen, blocksConfig]);

    const updateBlockVisibility = (code: string) =>
        updateBlockVisibilityInState(code, setBlocksConfig);

    const updateBlockOrder = (code: string, direction: 'up' | 'down') =>
        updateBlockOrderInState(code, direction, setBlocksConfig);

    const saveChanges = useCallback(
        () => saveBlocksConfig({ blocksConfig, dispatch, keyPopup }),
        [blocksConfig, dispatch, keyPopup]
    );

    return { blocksToPresent, updateBlockVisibility, updateBlockOrder, saveChanges };
};

function updateBlockVisibilityInState(
    code: string,
    setBlocksConfig: React.Dispatch<React.SetStateAction<StartupScreenConfig>>
) {
    setBlocksConfig((prev) => {
        return {
            ...prev,
            [code]: {
                ...prev[code],
                visible: !prev[code].visible
            }
        };
    });
}

function updateBlockOrderInState(
    code: string,
    direction: 'up' | 'down',
    setBlocksConfig: React.Dispatch<React.SetStateAction<StartupScreenConfig>>
) {
    setBlocksConfig((prev) => {
        const clickedBlockOrder = prev[code].order;
        const otherBlockOrder = direction === 'up' ? clickedBlockOrder - 1 : clickedBlockOrder + 1;
        const otherBlockCode = Object.keys(prev).find((key) => prev[key].order === otherBlockOrder);

        if (!otherBlockCode) return prev;

        return {
            ...prev,
            [code]: {
                ...prev[code],
                order: otherBlockOrder
            },
            [otherBlockCode]: {
                ...prev[otherBlockCode],
                order: clickedBlockOrder
            }
        };
    });
}

function saveBlocksConfig({
    blocksConfig,
    dispatch,
    keyPopup
}: {
    blocksConfig: StartupScreenConfig;
    dispatch: Dispatch;
    keyPopup: string;
}) {
    (async () => {
        dispatch(setLoading(true));

        const response = await updateStartupScreenConfiguraton(blocksConfig);

        if (response.success) {
            dispatch(updateBlocksConfig(blocksConfig));
        } else {
            dispatch(
                setPopupInfoTwo({
                    message: response.message,
                    title: 'Błąd zapisu konfiguracji'
                })
            );
        }
        dispatch(closePopup(keyPopup));
    })();
}
