import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Zoom } from '@mui/material';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
    timer_dialog_root: {
        position: 'fixed',
        zIndex: 50007,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    timer_dialog_modal: {
        fontFamily: '"Lato", sans-serif',
        borderRadius: spacing(0.5),
        minWidth: 400,
        background: palette.background.default,
        [breakpoints.down('xs')]: {
            minWidth: 300
        }
    },
    timer_dialog_title: {
        padding: `${spacing(2)}px ${spacing(3)}px`,
        color: palette.text.primary,
        '& h2': {
            margin: 0,
            lineHeight: 1.5,
            fontWeight: 600,
            fontSize: 20
        }
    },
    timer_dialog_content: {
        padding: `${spacing(1)}px ${spacing(3)}px`,
        color: palette.text.secondary,
        '& p': {
            margin: 0,
            marginBottom: spacing(1),
            fontSize: 16,
            letterSpacing: '0.00938em',
            lineHeight: 1.5
        }
    },
    timer_dialog_action: {
        padding: spacing(1),
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie dialogu potwierdzenia.
 * @component
 * @category Components
 * @subcategory Shared
 * @param {object} props - Propsy komponentu
 * @param {boolean} props.open - Wyznacza czy komponent jest wyświetlany
 * @param {Function} props.handleAccept - Akcja wywoływana po potwierdzeniu.
 * @param {Function} props.handleReject - Akcja wywoływana po potwierdzeniu.
 * @returns {ReactComponent}
 */
const AcceptDialog = ({ open, handleAccept, handleReject }) => {
    const classes = useStyles();

    /**
     * @memberof AcceptDialog
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook efektów - dodaje event nasłuchujący wciśnięcia klawisza escape, które spowoduje zamknięcie dialogu.
     */
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 27) handleReject();
        };

        if (open) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, handleReject]);

    return createPortal(
        open && (
            <div className={classes.timer_dialog_root} onClick={handleReject}>
                <Zoom in={open}>
                    <div className={classes.timer_dialog_modal}>
                        <div className={classes.timer_dialog_title}>
                            <h2>Jesteś pewny?</h2>
                        </div>
                        <div className={classes.timer_dialog_content}>
                            <p>Ta akcja jest nieodwracalna. Potwierdź, że na pewno tego chcesz.</p>
                        </div>
                        <div className={classes.timer_dialog_action}>
                            <Button onClick={handleReject} color="primary" autoFocus>
                                Anuluj
                            </Button>
                            <Button onClick={handleAccept} color="secondary">
                                ok
                            </Button>
                        </div>
                    </div>
                </Zoom>
            </div>
        ),
        document.body
    );
};

export default AcceptDialog;
