import { Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { ErrorApiResponse } from 'src/api/types';
import { setPopup } from 'src/store/src/popup';
import { setSpinner } from 'src/store/src/general/system/systemSlice';

type HandleWithCloseSpinner = {
    dispatch: Dispatch<PayloadAction<any>>;
    err: { message: ErrorApiResponse<any>['message'] };
};

export class ApiError {
    static handleWithCloseSpinner({ dispatch, err }: HandleWithCloseSpinner) {
        dispatch(
            setPopup({
                type: 'info',
                message: err.message
            })
        );
        dispatch(setSpinner(false));
    }
    static handle({ dispatch, err }: HandleWithCloseSpinner) {
        dispatch(
            setPopup({
                type: 'info',
                message: err.message
            })
        );
    }
}
