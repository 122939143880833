import {
    TreeNode,
    TreeNodeUbdated,
    Setting,
    ZeroLevel,
    FirstLevel,
    SecondLevel
} from 'src/store/src/listScreen/treeData/types';
import { defaultSetting } from 'src/constants/treeData';
import { zeroLevel, firstLevel, secondLevel } from './treeDataSlice';

type Props = {
    treeNode?: TreeNode[];
    width?: number;
    ubdatedIdChildrenUnfolded?: number;
    treeNodeUbdated?: TreeNodeUbdated[];
    setting?: Setting;
};

const defaultWidth = 500;
export class TreeDataManager {
    treeNode: TreeNode[];
    treeNodeUbdated: TreeNodeUbdated[];
    setting: Setting;
    spaceCalculator: SpaceCalculator;
    ubdatedIdChildrenUnfolded: null | number;
    revalidate: boolean;
    width: number;
    constructor({ treeNode, width, ubdatedIdChildrenUnfolded, treeNodeUbdated, setting }: Props) {
        this.treeNode = treeNode ? treeNode : [];
        this.treeNodeUbdated = treeNodeUbdated ? treeNodeUbdated : [];
        this.spaceCalculator = new SpaceCalculator({ treeNode, width });
        this.width = width ? width : defaultWidth;
        this.setting = {
            width: width ? width : defaultWidth,
            allLevelIsUnfolded: false,
            zeroLevel: {
                ...zeroLevel
            },
            firstLevel: {
                ...firstLevel
            },
            secondLevel: {
                ...secondLevel
            },
            typeNodeIsBlockArr: [],
            view: 'ALL',
            revalidateNotChecked: true,
            ...setting
        };
        this.ubdatedIdChildrenUnfolded = ubdatedIdChildrenUnfolded
            ? ubdatedIdChildrenUnfolded
            : null;
        this.revalidate = false;
    }

    run() {
        this.firstStep();
        this.secondStep();
        return {
            treeNodeUbdated: this.treeNodeUbdated,
            setting: this.setting
        };
    }

    private firstStep() {
        this.spaceCalculator.create();
        this.setting.zeroLevel = this.spaceCalculator.zeroLevel;
        this.setting.firstLevel = this.spaceCalculator.firstLevel;
        this.setting.secondLevel = this.spaceCalculator.secondLevel;
        this.setting.typeNodeIsBlockArr = this.spaceCalculator.typeNodeIsBlockArr;
        this.setView();
    }

    private secondStep() {
        const treeNode = [
            {
                values: [],
                children: this.treeNode
            }
        ];
        this.treeNodeUbdated = this.addPropertiesToTreeNodePrimaryUbdated({
            treeNode: treeNode,
            level: 0,
            indexParent: -1
        });
    }

    private setId(iterator: number) {
        return Math.random() + iterator;
    }
    private setIsChildrenUnfolded(level: number) {
        return this.setting.allLevelIsUnfolded || level !== 3;
    }

    private setLevel(level: number): number {
        if (this.setting.view === 'FROM_THIRD_LEVEL') {
            if (level === 1 || level === 2) {
                return 3;
            }
        }
        if (this.setting.view === 'WITH_FIRST_LEVEL') {
            if (level === 2) {
                return 3;
            }
        }
        return level;
    }
    private addPropertiesToTreeNodePrimaryUbdated({
        treeNode,
        level,
        indexParent
    }: {
        treeNode: TreeNode[];
        level: number;
        indexParent: number;
    }): TreeNodeUbdated[] {
        const levelUbdated = this.setLevel(level);
        const dat = treeNode.map((item, i): TreeNodeUbdated => {
            const data: TreeNodeUbdated = {
                ...item,
                id: this.setId(i),
                level: levelUbdated,
                indexParent,
                isChildrenUnfolded: this.setIsChildrenUnfolded(levelUbdated),
                isChildrenHaveItems: item.children.length ? true : false,
                children: this.addPropertiesToTreeNodePrimaryUbdated({
                    treeNode: item.children,
                    level: levelUbdated + 1,
                    indexParent: i
                })
            };
            return data;
        });
        return dat;
    }

    private setView() {
        if (this.setting.typeNodeIsBlockArr[1] && !this.setting.typeNodeIsBlockArr[2]) {
            this.setting.view = 'WITH_FIRST_LEVEL';
        }
        if (!this.setting.typeNodeIsBlockArr[1] && !this.setting.typeNodeIsBlockArr[2]) {
            this.setting.view = 'FROM_THIRD_LEVEL';
        }
    }

    checkWidth() {
        if (this.width) {
            this.revalidate = true;
            this.setting.revalidateNotChecked = false;
        }
    }

    revalidateSetting() {
        this.checkWidth();
        if (this.revalidate) {
            this.firstStep();
            this.secondStep();
        }

        return {
            treeNodeUbdated: this.treeNodeUbdated,
            setting: this.setting,
            revalidate: this.revalidate
        };
    }

    changeUnfold({ treeNodeUbdated, all }: { treeNodeUbdated: TreeNodeUbdated[]; all: boolean }) {
        return treeNodeUbdated.map((item): TreeNodeUbdated => {
            let isChildrenUnfolded =
                item.id === this.ubdatedIdChildrenUnfolded
                    ? !item.isChildrenUnfolded
                    : item.isChildrenUnfolded;
            if (all) {
                isChildrenUnfolded = this.setting.allLevelIsUnfolded || item.level !== 3;
            }
            return {
                ...item,
                isChildrenUnfolded,
                children: this.changeUnfold({ treeNodeUbdated: item.children, all })
            };
        });
    }

    // handle / change data store from user interaction

    // change unfold notes for one note (use id => ubdatedIdChildrenUnfolded)
    toggleChildrenUnfold() {
        return this.changeUnfold({ treeNodeUbdated: this.treeNodeUbdated, all: false });
    }
    // change unfold all notes or all is unfolded or set default unfolded 3 level

    toggleThirdLevelChildrenUnfold() {
        if (this.setting.allLevelIsUnfolded) {
            this.setting.allLevelIsUnfolded = false;
            return {
                treeNodeUbdated: this.changeUnfold({
                    treeNodeUbdated: this.treeNodeUbdated,
                    all: true
                }),
                setting: this.setting
            };
        } else {
            this.setting.allLevelIsUnfolded = true;
            return {
                treeNodeUbdated: this.changeUnfold({
                    treeNodeUbdated: this.treeNodeUbdated,
                    all: true
                }),
                setting: this.setting
            };
        }
    }

    // helpers for template system

    getAllObjectIds() {
        const ids: string[] = [];
        for (const child of this.treeNode) {
            this.appendValueIds(ids, child);
        }
        return ids;
    }

    appendValueIds(array: string[], node: TreeNode) {
        for (const value of node.values) {
            if (value.id !== undefined) array.push(value.id);
        }
        for (const child of node.children) {
            this.appendValueIds(array, child);
        }
    }
}

class SpaceCalculator {
    treeNode: TreeNode[];
    width: number;
    minRequiredspaceCalculator: number[][];
    minRequiredspaceCalculatorReduced: number[];
    numberSecondLevelChildrenArr: number[];
    temporaryDividedSpaceWithSecondLevel: number[];
    typeNodeIsBlockArr: boolean[];
    zeroLevel: ZeroLevel;
    firstLevel: FirstLevel;
    secondLevel: SecondLevel;
    numberSecondLevelChildrenReduced: number;
    spaceForItemSecondLevelChild: number;
    isPosibleSetSecondLevel: boolean;
    constructor({ treeNode, width }: Props) {
        this.treeNode = treeNode ? treeNode : [];
        this.width = width ? width : defaultWidth;
        this.minRequiredspaceCalculator = [];
        this.minRequiredspaceCalculatorReduced = [];
        this.numberSecondLevelChildrenArr = [];
        this.temporaryDividedSpaceWithSecondLevel = [];
        this.isPosibleSetSecondLevel = true;
        this.zeroLevel = {
            groupX: [],
            divideSpace: []
        };
        this.firstLevel = {
            rectHeight: [],
            rectWidth: [],
            boxWidth: [],
            groupY: 0,
            groupX: [],
            bottomY: [],
            centralBottomX: [],
            centralBettweenFirstSecondY: [],
            listIfHaveTitle: []
        };
        this.secondLevel = {
            groupY: [],
            groupX: [],
            centralTopX: []
        };
        this.typeNodeIsBlockArr = [false, true, true];
        this.numberSecondLevelChildrenReduced = 0;
        this.spaceForItemSecondLevelChild = 0;
    }

    private setListIfHaveTitle() {
        this.treeNode.forEach((item) => {
            this.firstLevel.listIfHaveTitle.push(item.title ? true : false);
        });
    }

    private setMinRequiredspaceCalculator() {
        const minRequiredSpaceForFirstLevel: number[] = [];
        const minRequiredSpaceForSecondLevel: number[] = [];
        this.treeNode.forEach((item) => {
            minRequiredSpaceForFirstLevel.push(this.setMinRequiredSpace(item.values.length, 1));
            minRequiredSpaceForSecondLevel.push(this.setMinRequiredSpace(item.children.length, 2));
        });
        this.minRequiredspaceCalculator[1] = minRequiredSpaceForFirstLevel;
        this.minRequiredspaceCalculator[2] = minRequiredSpaceForSecondLevel;

        this.minRequiredspaceCalculator.forEach((item, i) => {
            this.minRequiredspaceCalculatorReduced[i] = item.reduce((acc, cur) => acc + cur, 0);
        });
    }

    private setMinRequiredSpace(length: number, level: number) {
        switch (level) {
            case 1:
                return length === 1
                    ? defaultSetting.minOneItemWidth + defaultSetting.minLeftRightMargin
                    : length *
                          (defaultSetting.minManyItemWidth + defaultSetting.minLeftRightMargin);
            default:
                return (
                    length * (defaultSetting.minManyItemWidth + defaultSetting.minLeftRightMargin)
                );
        }
    }

    private setNumberSecondLevelChildren() {
        this.treeNode.forEach((item) => {
            const length = item.children.length > 1 ? item.children.length - 1 : 1;
            this.numberSecondLevelChildrenArr.push(length);
        });
        this.numberSecondLevelChildrenReduced = this.numberSecondLevelChildrenArr.reduce(
            (acc, cur) => acc + cur,
            0
        );
    }

    private setTemporaryDividedSpace() {
        this.spaceForItemSecondLevelChild = this.width / this.numberSecondLevelChildrenReduced;
        this.numberSecondLevelChildrenArr.forEach((item) => {
            const space = item * this.spaceForItemSecondLevelChild;
            this.temporaryDividedSpaceWithSecondLevel.push(space);
        });
    }

    private checkIsSpaceForFirstLevelBlock() {
        let isSpace = true;
        this.minRequiredspaceCalculator[1].forEach((item, i) => {
            if (item > this.temporaryDividedSpaceWithSecondLevel[i]) {
                isSpace = false;
            }
        });
        if (!isSpace) {
            this.isPosibleSetSecondLevel = false;
            this.typeNodeIsBlockArr[2] = false;
            if (this.minRequiredspaceCalculatorReduced[1] > this.width) {
                this.typeNodeIsBlockArr[1] = false;
            }
        }
    }

    private checkIsSpaceForSecondLevelBlock() {
        if (this.isPosibleSetSecondLevel) {
            this.minRequiredspaceCalculator[2].forEach((item, i) => {
                if (
                    this.typeNodeIsBlockArr[1] &&
                    item > this.temporaryDividedSpaceWithSecondLevel[i]
                ) {
                    this.typeNodeIsBlockArr[2] = false;
                }
            });
        }
    }

    private divideSpaceForFirstLevel() {
        if (this.isPosibleSetSecondLevel) {
            this.zeroLevel.divideSpace = this.temporaryDividedSpaceWithSecondLevel;
        } else {
            const spaceToDivideOnItem =
                (this.width - this.minRequiredspaceCalculatorReduced[1]) /
                this.minRequiredspaceCalculator[1].length;
            this.minRequiredspaceCalculator[1].forEach((item) => {
                this.zeroLevel.divideSpace.push(item + spaceToDivideOnItem);
            });
        }
    }

    private setFirstLevelSettingOne() {
        this.treeNode.forEach((item, i) => {
            const rectHeight = item.title
                ? defaultSetting.firstLevel.rectHeight
                : defaultSetting.firstLevel.rectHeightWithoutTitle;
            const rectWidth =
                item.values.length > 1
                    ? defaultSetting.boxWidthManyColumn * item.values.length
                    : defaultSetting.boxWidthOneColumn;
            const boxWidth =
                item.values.length > 1
                    ? defaultSetting.boxWidthManyColumn
                    : defaultSetting.boxWidthOneColumn;
            this.firstLevel.rectHeight.push(rectHeight);
            this.firstLevel.rectWidth.push(rectWidth);
            this.firstLevel.boxWidth.push(boxWidth);
        });
    }

    private setFirstLevelSettingTwo() {
        let y = 0;
        this.zeroLevel.divideSpace.forEach((item, i) => {
            const centralBottomX = item / 2;
            const groupXToSet = centralBottomX - this.firstLevel.rectWidth[i] / 2;
            let centralBottomXToSet = centralBottomX;
            if (!this.typeNodeIsBlockArr[2]) {
                centralBottomXToSet = groupXToSet + defaultSetting.threeLevel.lineFromLeft;
            }
            this.firstLevel.centralBottomX.push(centralBottomXToSet);
            this.firstLevel.groupX.push(groupXToSet);
            this.zeroLevel.groupX.push(y);
            y = y + item;
        });
        this.firstLevel.rectHeight.forEach((item) => {
            const bottomY = item + this.firstLevel.groupY;
            this.firstLevel.bottomY.push(bottomY);
            this.firstLevel.centralBettweenFirstSecondY.push(
                bottomY + defaultSetting.bettweenFirstSecondHeight
            );
        });
    }

    private setSecondLevelSettingOne() {
        if (this.typeNodeIsBlockArr[1]) {
            this.treeNode.forEach((item, i) => {
                const groupX: number[] = [];
                const groupY: number[] = [];
                const centralTopX: number[] = [];
                const spaceForItem = this.zeroLevel.divideSpace[i] / item.children.length;
                const leftMargin = (spaceForItem - defaultSetting.boxWidthOneColumn) / 2;
                item.children.forEach((item2, y) => {
                    const groupXItem = y * spaceForItem + leftMargin;
                    groupX.push(groupXItem);
                    centralTopX.push(groupXItem + defaultSetting.boxWidthOneColumn / 2);
                    groupY.push(
                        this.firstLevel.centralBettweenFirstSecondY[i] +
                            defaultSetting.bettweenFirstSecondHeight
                    );
                });
                this.secondLevel.groupX.push(groupX);
                this.secondLevel.centralTopX.push(centralTopX);
                this.secondLevel.groupY.push(groupY);
            });
        } else {
            this.secondLevel.groupX.push([]);
            this.secondLevel.centralTopX.push([]);
            this.secondLevel.groupY.push([]);
        }
    }

    create() {
        this.setListIfHaveTitle();
        this.setMinRequiredspaceCalculator();
        this.setNumberSecondLevelChildren();
        this.setTemporaryDividedSpace();
        this.checkIsSpaceForFirstLevelBlock();
        this.checkIsSpaceForSecondLevelBlock();
        this.divideSpaceForFirstLevel();
        this.setFirstLevelSettingOne();
        this.setFirstLevelSettingTwo();
        this.setSecondLevelSettingOne();
    }
}
