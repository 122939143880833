import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import styles from './ProgressBar.module.css';
import { useTypedSelector } from 'src/store';

export const ProgressBar: FC = () => {
    const { palette } = useTheme();
    const progressBar = useTypedSelector((state) => state.progressBar);
    return (
        <div
            className={styles.container}
            style={{ display: progressBar.isShowed ? 'flex' : 'none' }}>
            <div className={styles.div} style={{ border: ` 1px solid ${palette.primary.main}` }}>
                <div
                    className={styles.belt}
                    style={{ width: progressBar.progress, background: palette.primary.main }}>
                    <p className={styles.p}>{progressBar.progress}</p>
                </div>
            </div>
        </div>
    );
};
