import { doPayoffImport } from 'src/api/src/importPayoff/doPayoffImport';
import { useSafeNavigate } from 'src/hooks/src/shared/useSafeNavigate';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { setPopup, setPopupInfoTwo } from 'src/store/src/popup';
import { generateToken } from 'src/utils';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { useUpdateStateImportPayoff } from './useUpdateStateImportPayoff';
import React from 'react';

export const useLoadImportPayoff = () => {
    const { updateState } = useUpdateStateImportPayoff();
    const navigate = useSafeNavigate();
    const dispatch = useAppDispatch();
    const stateImportPayoff = useTypedSelector((state) => state.importPayoffSlice);

    const loadImportPayoff = async () => {
        if (!stateImportPayoff.fileId) {
            dispatch(
                setPopupInfoTwo({
                    title: 'Błąd',
                    message: 'Proszę wskaż plik do importu'
                })
            );
            return;
        }

        const res = await doPayoffImport(
            stateImportPayoff.fileId,
            stateImportPayoff.bank,
            stateImportPayoff.issuer
        );
        const closeCallbackEventId = `redirect-close-${generateToken()}`;

        if (res.success) {
            dispatch(
                setPopup({
                    type: 'info',
                    message: res.message,
                    closeCallbackEventId
                })
            );
        }
        EventBus.$on(closeCallbackEventId, () => {
            EventBus.$off(closeCallbackEventId);
            navigate('/lista?obiekt=wplaty&screen=wplaty');
        });
    };

    React.useEffect(() => {
        return () => {
            updateState({ type: 'clearState', value: null });
        };
    }, []);
    return { loadImportPayoff };
};
