import React, { FC } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import styles from './UIFieldRelationshipTypeMany.module.css';
import { UIFieldRelationshipTypeManyProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { useFieldClasses } from 'src/hooks';
import { UiAutocompleteInputThree } from 'src/components/shared/atoms/uiInputs/uiAutocompleteInputThree/UiAutocompleteInputThree';

export const UIFieldRelationshipTypeMany: FC<UIFieldRelationshipTypeManyProps> = (props) => {
    const {
        onInputChangeAutocomplete,
        inputValueAutocomplete,
        onChangeAutocomplete,
        onClearAutocomplete,
        optionsAutocomplete,
        autocompleteFilterOptions,
        opcjeSelect,
        onChangeSelect,
        onClearItem,
        value,
        uiFieldCommonProps,
        uiFieldSharedProps
    } = props;
    const { disabled } = uiFieldCommonProps;
    const classes = useFieldClasses({ muiType: 'autocompleteOne' });

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputSelectTwo
                    value={value.select}
                    opcje={opcjeSelect}
                    onChange={onChangeSelect}
                />
                <Autocomplete
                    sx={{
                        paddingLeft: '10px'
                    }}
                    freeSolo
                    onInputChange={onInputChangeAutocomplete}
                    options={optionsAutocomplete || null}
                    inputValue={inputValueAutocomplete}
                    getOptionLabel={(option: any) => {
                        return option?.text;
                    }}
                    disablePortal={false}
                    filterOptions={autocompleteFilterOptions}
                    onChange={onChangeAutocomplete}
                    clearIcon={
                        inputValueAutocomplete ? (
                            <ButtonCross
                                onClick={onClearAutocomplete}
                                style={{
                                    top: '-2px'
                                }}
                            />
                        ) : (
                            <></>
                        )
                    }
                    disabled={disabled}
                    classes={{
                        option: classes.option,
                        listbox: classes.listbox,
                        paper: classes.paper,
                        popper: classes.popper
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => {
                        return (
                            <UiAutocompleteInputThree
                                {...uiFieldCommonProps}
                                params={params}
                                inputProps={{
                                    ...params.inputProps,
                                    ...uiFieldSharedProps.inputProps
                                }}
                            />
                        );
                    }}
                />
            </div>
            {value.objects.length ? (
                <div className={styles.list}>
                    {value.objects.map((item) => {
                        return (
                            <div key={item.id} className={styles.item}>
                                <LegacyCompatibleLink
                                    url="ekran_obiektu.php"
                                    queryParams={
                                        new URLSearchParams([
                                            ['id', item.id ?? ''],
                                            ['obiekt', item.typ ?? '']
                                        ])
                                    }
                                    newWindow={true}>
                                    {item.typ_nazwa ? (
                                        <p>
                                            {item.typ_nazwa}:
                                            {item.nazwa!?.length > 40
                                                ? item.nazwa?.substring(0, 40) + '...'
                                                : item.nazwa}
                                        </p>
                                    ) : item.nazwa!?.length > 40 ? (
                                        item.nazwa?.substring(0, 40) + '...'
                                    ) : (
                                        item.nazwa
                                    )}
                                </LegacyCompatibleLink>
                                <div className={styles.boxDelete}>
                                    <ButtonCross onClick={() => onClearItem(item)} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </UiWrapper>
    );
};
