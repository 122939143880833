import React, { FC } from 'react';

import FieldValueToAcceptWrapper from '../shared/fieldValueToAcceptWrapper/FieldValueToAcceptWrapper';
import { UIFieldListProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

const UIFieldListComp: FC<UIFieldListProps> = (props) => {
    const { value, onChange, uiFieldCommonProps, uiFieldSharedProps, onAcceptValue } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <FieldValueToAcceptWrapper
                value={value.value}
                valueToAccept={uiFieldCommonProps.valueToAccept}
                textToAccept={uiFieldCommonProps.valueToAcceptText}
                onAcceptValue={onAcceptValue}>
                <UiInputSelectTwo
                    {...uiFieldCommonProps}
                    value={value.value}
                    options={props.options}
                    onChange={onChange}
                    inputProps={uiFieldSharedProps.inputProps}
                />
            </FieldValueToAcceptWrapper>
        </UiWrapper>
    );
};

export const UIFieldList = React.memo(UIFieldListComp);
