import React, { FC } from 'react';

import PopupTemplateHead from 'src/components/shared/molecules/popupTemaplateHead/PopupTemplateHead';
import { useTypedSelector } from 'src/store';
import { DynamicFormHelper } from 'src/components/shared/molecules/dynamicForm/DynamicFormHelper';
import { reduceStr } from 'src/utils';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
import { PopupState } from 'src/store/src/popup/popup/types';

type Props = {
    id: RowDataId;
    popup: PopupState;
};

export const DynamicTitle: FC<Props> = ({ popup, id }) => {
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const objectSlice = useTypedSelector((state) => state.object);

    const titleTemplated = new DynamicFormHelper({
        objectSlice,
        rowData,
        popupState: popup,
        rowId: id
    }).getCheckTemplateRawValueOrText({
        str: popup.title,
        type: 'text',
        sign: '!'
    });

    return <PopupTemplateHead title={reduceStr(titleTemplated, 55)} />;
};
