import { AnyJSONResponse } from '../types';

/**
 * Fetches information from server
 */
export async function simpleQueryAnyJson<T>(
    url: string,
    method: 'GET' | 'POST' | 'DELETE' = 'GET',
    bodyContent: any = undefined,
    parseBody: boolean = true,
    addContentTypeJson: boolean = true
): Promise<AnyJSONResponse<T>> {
    let parsedBody: any = bodyContent;
    if (bodyContent && parseBody) {
        parsedBody = JSON.stringify(bodyContent);
    }

    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: addContentTypeJson
            ? {
                  'Content-Type': 'application/json'
              }
            : undefined,
        body: parsedBody
    });

    const dataPrimary = await response.text();

    let dataParsed = null;
    try {
        dataParsed = JSON.parse(dataPrimary);
    } catch (e) {
        console.error(`simpleQueryAnyJson: parse error ${e}`);
        return { data: null, success: false, error: e as string };
    }

    return { data: dataParsed, success: true };
}
