import { FieldListStateRHF } from 'src/data/fieldsReactHookForm';
import {
    BudgetFieldsStateUnionRHF,
    BudgetGetValuesFn,
    BudgetSetValueFn
} from 'src/hooks/src/budget/useFormBudget';
import { BugdetFieldTypes } from 'src/store/src/budget/budget/types';

type SetValueBudgetPropsRHF = {
    getValues: BudgetGetValuesFn;
    setValue: BudgetSetValueFn;
};

export type SetValueFnBudgetPropsRHF = {
    keyField: string;
    methodId: string;
};

type SetValueStringFnBudgetPropsRHF = {
    fieldType: BugdetFieldTypes;
    value: string;
};

type SetValueDateFnBudgetPropsRHF = {
    fieldType: BugdetFieldTypes;
    value: Date | null;
};

type SetValueCheckboxFnBudgetPropsRHF = {
    fieldType: BugdetFieldTypes;
    value: boolean;
};

export class BudgetSetValueHandlerRHF {
    getValues: BudgetGetValuesFn;
    setValue: BudgetSetValueFn;
    field: BudgetFieldsStateUnionRHF;
    keyField: `${string}.${string}`;
    constructor(data: SetValueBudgetPropsRHF) {
        this.getValues = data.getValues;
        this.setValue = data.setValue;
        this.field = {};
        this.keyField = '.';
    }

    run({ keyField, methodId }: SetValueFnBudgetPropsRHF) {
        this.field = this.getValues(`main.${methodId}.${keyField}`);
        this.keyField = `${methodId}.${keyField}`;
        return this;
    }

    setString({ fieldType, value }: SetValueStringFnBudgetPropsRHF) {
        switch (fieldType) {
            case 'tekst_bez_ograniczen': {
                this.setValue(`main.${this.keyField}.tekst_bez_ograniczen`, value);
                break;
            }
            case 'tekst': {
                this.setValue(`main.${this.keyField}.tekst`, value);
                break;
            }
            case 'liczba': {
                this.setValue(`main.${this.keyField}.liczba`, value);
                break;
            }
            case 'basic': {
                this.setValue(`main.${this.keyField}.basic`, value);
                break;
            }
            case 'lista': {
                const oneObj: Required<FieldListStateRHF> = this
                    .field as Required<FieldListStateRHF>;
                this.setValue(`main.${this.keyField}.lista`, {
                    opcje: oneObj.lista.opcje,
                    value
                });
                break;
            }
        }
    }
    setDate({ fieldType, value }: SetValueDateFnBudgetPropsRHF) {
        switch (fieldType) {
            case 'data': {
                this.setValue(`main.${this.keyField}.data`, value);
                break;
            }
        }
    }

    setBoolean({ fieldType, value }: SetValueCheckboxFnBudgetPropsRHF) {
        switch (fieldType) {
            case 'checkbox': {
                this.setValue(`main.${this.keyField}.checkbox`, value);
                break;
            }
        }
    }
}
