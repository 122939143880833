import { UtilitiesBarRouterProps } from './types';
import {
    unFoldNodes,
    foldNodes,
    setDefaultView,
    setStatusView,
    setTreeView,
    listActionAll,
    listActionObject,
    config,
    configOrder,
    listActionBookCorrespondence,
    listActionConfig,
    listActionSendMessage,
    listActionHelp,
    listActionMassEditObjects,
    listActionObjectsNotBill,
    listActionImportPayoff,
    listActionBudget,
    listActionBudgetAll,
    listStartup,
    listPermissions,
    startupBlockConfigVisbility,
    listActionBudgetMin,
    listActionMassBill,
    listActionObjectsNotBillOnlyBeforeMonth
} from 'src/constants/listInteralAction';
import { ListScreenViews, SystemAction } from 'src/data/types';
import { ModuleMenuData } from 'src/store/src/menu/menu/types';
import { TreeTableState } from 'src/store/src/listScreen/treeData/types';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import { ActiveTabData } from 'src/store/src/object/object/types';
import { UserState } from 'src/store/src/general/user/types';

type UtilityBarInternalActionGeneratorProps = {
    props: UtilitiesBarRouterProps;
    modules: ModuleMenuData[];
    treeTableState: TreeTableState;
    activeTabData: ActiveTabData;
    screen: string | null;
    internalNotesExists: boolean;
    userSlice: UserState;
    alsoActual: boolean;
};
export class UtilityBarInternalActionGenerator {
    props: UtilitiesBarRouterProps;
    modules: ModuleMenuData[];
    treeTableState: TreeTableState;
    screen: string | null;
    internalActions: SystemAction[];
    activeTabData: ActiveTabData;
    internalNotesExists: boolean;
    userSlice: UserState;
    alsoActual: boolean;
    constructor({
        props,
        modules,
        treeTableState,
        activeTabData,
        screen,
        internalNotesExists,
        userSlice,
        alsoActual
    }: UtilityBarInternalActionGeneratorProps) {
        this.props = props;
        this.modules = modules;
        this.internalNotesExists = internalNotesExists;
        this.treeTableState = treeTableState;
        this.screen = screen;
        this.userSlice = userSlice;
        this.activeTabData = activeTabData;
        this.internalActions = [];
        this.alsoActual = alsoActual;
    }

    private setViewForDefault() {
        const screenObj = MenuStateManager.getActualScreen({
            menuData: this.modules,
            screen: this.screen
        });
        if (screenObj) {
            if (screenObj.alternativeView === 'drzewo') {
                this.internalActions.push(setTreeView);
            } else if (screenObj.alternativeView === 'statusy') {
                this.internalActions.push(setStatusView);
            }
        }
    }

    private getActionsBaseOnViewForList(view: ListScreenViews) {
        switch (view) {
            case 'drzewo':
                this.internalActions.push(setDefaultView);
                this.internalActions.push(
                    this.treeTableState.setting.allLevelIsUnfolded ? foldNodes : unFoldNodes
                );
                break;
            case 'statusy':
                this.internalActions.push(setDefaultView);
                break;
            default:
                this.setViewForDefault();
                this.internalActions.push(config);
                break;
        }
    }

    private getActionsConditionalForObject() {
        this.activeTabData?.sections.forEach((section) => {
            if (
                section.relationDisplayMode === 'karta' &&
                Array.isArray(section.relations) &&
                section.relations?.length > 1 &&
                !this.internalActions.find((item) => item.code === 'configOrder')
            ) {
                this.internalActions.push(configOrder);
            }
        });
    }
    run() {
        switch (this.props.pageType) {
            case 'ekran_obiektow':
                this.internalActions.push(...listActionAll);
                this.getActionsBaseOnViewForList(this.props.view);
                break;
            case 'ekran_obiektu':
                this.internalActions.push(...listActionObject);
                this.getActionsConditionalForObject();
                break;
            case 'ekran_szablonow_generowanych':
            case 'ekran_ksiazek_korespondencji':
            case 'ekran_pdf':
            case 'ekran_video':
            case 'ekran_raportow':
            case 'ekran_enadawca_tworzenie_zbioru':
            case 'ekran_enadawca_tworzenie_przesylki':
            case 'ekran_esad_tworzenie_paczki':
            case 'ekran_esad_tworzenie_paczki_wnioskow':
            case 'wysylka_dokumentow':
            case 'ekran_tagow':
            case 'ekran_historii_odczytów_obiektu':
            case 'global_search':
                this.internalActions.push(...listActionBookCorrespondence);
                break;
            case 'ekran_budzetu':
                if (this.screen && ['faktury', 'proformy'].includes(this.screen)) {
                    this.internalActions.push(...listActionBudgetMin);
                } else {
                    if (this.internalNotesExists) {
                        this.internalActions.push(...listActionBudgetAll);
                    } else {
                        this.internalActions.push(...listActionBudget);
                    }
                }

                break;
            case 'ekran_startowy':
                this.internalActions.push(...listStartup);
                if (this.userSlice.userType === 'pracownicy') {
                    this.internalActions.push(startupBlockConfigVisbility);
                }
                break;
            case 'ekran_masowej_edycji_obiektow':
                this.internalActions.push(...listActionMassEditObjects);
                break;
            case 'ekran_wiadomosc':
                this.internalActions.push(...listActionSendMessage);
                break;
            case 'ekran_masowe_fakturowanie':
                this.internalActions.push(...listActionMassBill);
                break;
            case 'ekran_pomocy':
                this.internalActions.push(...listActionHelp);
                break;
            case 'ekran_zmian_obiektu':
                this.internalActions.push(...listActionObject);
                break;
            case 'ekran_obiekty_do_fakturowania':
                if (this.alsoActual) {
                    this.internalActions.push(...listActionObjectsNotBillOnlyBeforeMonth);
                } else {
                    this.internalActions.push(...listActionObjectsNotBill);
                }
                break;
            case 'ekran_konfiguracji':
                this.internalActions.push(...listActionConfig);
                break;
            case 'import_wplat':
                this.internalActions.push(...listActionImportPayoff);
                break;
            case 'ekran_uprawnien':
                this.internalActions.push(...listPermissions);
                break;
            default:
                break;
        }
        return this.internalActions;
    }
}
