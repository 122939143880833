import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import styles from './UiGrid.module.css';
type Props = {
    sx?: SxProps<Theme>;
};
// odpowiada za szerokość kolumny / sekcji (wszystkie dzieci mają mieć ustawione width na %), ponadto reguluje oddzielenie wierszy (inputów na wysokość), do potencjalnej rozbudowy
// define width column / section  (child must have set width on %), define space vertical beatween row (beatween input on height), component is to potencial expand

export const UiGrid: FC<Props> = ({ children, sx }) => {
    return (
        <Box
            sx={{
                ...sx
            }}
            className={styles.wrapper}>
            {children}
        </Box>
    );
};
