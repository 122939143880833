import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldPassword } from 'src/components/popupFormExtended/dynamicField/fieldPassword/UIFieldPassword';
import { FieldPasswordObjectProps } from './types';
import { PopupBehavior, SystemAction } from 'src/data/types';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { useAppDispatch } from 'src/store';
import { dataSetInputKey } from 'src/constants/dataset';

export const FieldPasswordObject: FC<FieldPasswordObjectProps> = ({
    dynamicFieldT,
    field,
    uiFieldCommonProps,
    popupFormExtendedData,
    keyOfFieldInState,
    screen
}) => {
    const dispatch = useAppDispatch();

    const openPopup = () => {
        const popupBehaviour: PopupBehavior = {
            type: 'popup',
            data: {
                template: 'changePassword',
                data: {
                    passwordfield: dynamicFieldT,
                    objectId: popupFormExtendedData.objectId,
                    screen,
                    lockExit: false,
                    userPasswordValidation: true
                }
            }
        };
        const actionPreview: SystemAction = {
            name: 'Zmiana hasła',
            code: 'change password_file_password',
            colorConditions: null,
            displayConditions: null,
            behaviour: popupBehaviour
        };
        new PopupManager({ dispatch, action: actionPreview }).setPopupTemplate({
            behaviour: popupBehaviour,
            rowId: '0',
            sourceOfTrigger: {
                type: 'popupFormExtended',
                fileId: '',
                fileName: ''
            }
        });
    };

    if (uiFieldCommonProps?.usedForAddingObject) {
        return null;
    }

    // case when editing object
    return (
        <UIFieldPassword
            changePasswordCallback={openPopup}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT
            }}
        />
    );
};
