import React, { FC } from 'react';

import { FieldRelationshipTypeManyUnmodifiedProps } from './types';
import { FieldWrapperUnmodified } from 'src/components/popupFormExtended/dynamicField/shared/fieldWrapperUnmodified/FieldWrapperUnmodified';

export const FieldRelationshipTypeManyUnmodified: FC<FieldRelationshipTypeManyUnmodifiedProps> = ({
    dynamicFieldT,
    uiFieldCommonProps,
    field
}) => {
    return (
        <FieldWrapperUnmodified
            dynamicFieldT={dynamicFieldT}
            field={field}
            isLabel={Boolean(uiFieldCommonProps?.isLabel)}>
            <div>
                {field.value.objects.map((el, i) => (
                    <p key={i}>
                        {el.typ}: {el.nazwa}
                    </p>
                ))}
            </div>
        </FieldWrapperUnmodified>
    );
};
