import React, { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';

import { UiInputElWrapper } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElWrapper/UiInputElWrapper';
import { SelectIcon } from 'src/components/shared/atoms/uiInputs/uiInputSelect/SelectIcon';
import { sxInputV1Default } from 'src/components/shared/atoms/uiInputs/sxDefault';
import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import { SearchField } from 'src/store/src/listScreen/searchFields/types';
import { ListSearchFilters } from 'src/store/src/listScreen/transformations/types';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useMenuPropsPaperClass, sxInputSelect } from 'src/styles/dynamicSelectStyles';

const getOptions = (searchField: SearchField, listSearchFilters: ListSearchFilters) => {
    let options = searchField.searchOptions;
    if (searchField.masterFieldCode) {
        const masterFieldCodeValue =
            listSearchFilters[searchField.masterFieldCode]?.fieldFilter?.value;
        if (masterFieldCodeValue) {
            const masteredOptions = searchField.masterRelatedOptions?.[masterFieldCodeValue];
            if (Array.isArray(masteredOptions)) options = masteredOptions;
        }
    }
    return options;
};

export const DynamicListSearch: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const theme = useTheme();

    const options = getOptions(searchField, listSearchFilters);
    const value = listSearchFilters[searchField.searchCode]?.fieldFilter?.value;

    const sxinputselect = {
        ...sxInputV1Default,
        ...sxInputSelect(theme.palette.primary.main),
        color: theme.palette.text.input
    };

    const classes = useMenuPropsPaperClass(theme.palette.primary.main);

    React.useEffect(() => {
        if (searchField.masterFieldCode && value) {
            const masterFieldCodeValue =
                listSearchFilters[searchField.masterFieldCode]?.fieldFilter?.value;
            const arr = searchField.masterRelatedOptions?.[masterFieldCodeValue];
            if (!arr?.find((item) => item.id === Number(value))) {
                dispatchFilter({
                    type: 'SET_VALUE_THREE',
                    payload: {
                        code: searchField.searchCode,
                        value: null
                    }
                });
            }
        }
    }, [listSearchFilters, searchField, value]);

    return (
        <UiInputElWrapper>
            <Select
                sx={sxinputselect}
                MenuProps={{ classes: { paper: classes.paper } }}
                onChange={(e: SelectChangeEvent) => {
                    dispatchFilter({
                        type: 'SET_VALUE_THREE',
                        payload: {
                            code: searchField.searchCode,
                            value: Number(e.target.value) === 100000 ? null : Number(e.target.value)
                        }
                    });
                }}
                value={value}
                IconComponent={SelectIcon}>
                {searchField.searchCode !== 'ilosc_wynikow' && (
                    <MenuItem
                        value={100000}
                        sx={{
                            height: '30px'
                        }}></MenuItem>
                )}
                {options?.map((option: { name: string; id: string | number }) => {
                    return (
                        <MenuItem key={option.id.toString()} value={option.id}>
                            {option.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </UiInputElWrapper>
    );
};
