import isSameDay from 'date-fns/isSameDay';

import { CalendarDays } from '../../store/src/calendar/calendar/types';
import { DayEvent, GridDays, EventStub } from '../types';

/**
 * Calculates row on calendar user for given time and given timeslot
 * @param position - rows are calculated differently based if start or end position of event is calculated,
 * eg. event from 0:00 to 0:05 will have start row: 0 and end row 0, while event from 0:05 will have start row 1.
 * It affects only border values for any given slot.
 * @param slot - base unit (in minutes) of event height. Must be factor of 60 (no remainder)
 */
export function calculateCalendarRow(
    hour: number,
    minute: number,
    position: 'start' | 'end',
    slot = 5
) {
    // offset applies for border values and end position
    const offset = position === 'end' && !(minute % slot) ? -1 : 0;
    // how many full timeslots fits in given minutes
    const slots = Math.floor((minute + offset) / slot);

    // express hours as slots and add minutes as slots
    return hour * (60 / slot) + slots;
}

/**
 * Forms DayEvent, calculates event vertical dimensions: height, startRow, endRow
 */
function createDayEvent(ev: EventStub, dayTimestamp: number): DayEvent {
    const startTimeStr = ev.startHour;
    const endTimeStr = ev.endHour;
    /* from string eg "14:30" to numbers 14 hours and 30 minutes */
    const [startHours, startMinutes] = startTimeStr.split(':').map((el) => parseInt(el));
    const [endHours, endMinutes] = endTimeStr.split(':').map((el) => parseInt(el));

    /* start row as index in array of quaters form 0 at 0:00-0:14 to 95 at 23:45-23-49 */
    let startRow = calculateCalendarRow(startHours, startMinutes, 'start');
    let endRow = calculateCalendarRow(endHours, endMinutes, 'end');

    // if event starts before current day, for that day it starts at 0:00
    if (!isSameDay(ev.startDayTimestamp, dayTimestamp)) {
        startRow = 0;
    }

    // if event ends after current day, for that day it ends at 23:59
    if (!isSameDay(ev.endDayTimestamp, dayTimestamp)) {
        /* five minutes slots */
        endRow = 24 * 12 - 1;
    }

    /* Height in rows */
    const height = endRow - startRow + 1;

    return { ...ev, startRow, endRow, height };
}

/**
 * Distributes events over corresponding days, create separate events for those that spans over one day, calculate vertical dimensions
 */
export function createGridDays(events: EventStub[], days: CalendarDays): GridDays {
    // create empty dictionary with calednarDays timestamps as keys, to store events
    const dayEvents = days.reduce((obj, day) => {
        obj[day.startTimestamp] = [];
        return obj;
    }, {} as GridDays);

    events.forEach((e) => {
        for (let i = 0; i < days.length; i++) {
            const dayTs = days[i].startTimestamp;
            // event takes place in current day
            if (e.startDayTimestamp <= dayTs && e.endDayTimestamp >= dayTs) {
                // create and push event
                dayEvents[dayTs].push(createDayEvent(e, dayTs));
            }

            // break if ends that day
            if (isSameDay(e.endDayTimestamp, dayTs)) {
                break;
            }
        }
    });

    return dayEvents;
}
