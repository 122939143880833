import React, { FC } from 'react';
import { InputBase } from '@mui/material';

import { UiAutocompleteInputThreeProps } from './types';
import { useStyleInlineOne } from 'src/hooks/src/shared/useStyleInlineOne';

export const UiAutocompleteInputThree: FC<UiAutocompleteInputThreeProps> = (props) => {
    const { params, inputProps, style } = props;
    const inlineStyle = useStyleInlineOne({ ...props });
    return (
        <InputBase
            {...params.InputProps}
            {...params}
            inputProps={{
                ...params.inputProps,
                ...inputProps
            }}
            sx={{
                ...inlineStyle,
                borderRadius: '4px',
                paddingLeft: '10px',
                ...style
            }}
        />
    );
};
