import { SystemAction } from 'src/data/types';

const regex = /(@listScreenExcelParams|@objectId\[all])/g;

type PrypereDataFromApi = {
    systemAction: SystemAction[];
};

export class UtilitiesBarManager {
    static prepareDataFromApi({ systemAction }: PrypereDataFromApi) {
        return systemAction.map((action) => {
            let isAllIdsRequired = false;
            if (action.behaviour.data?.params?.match(regex)) isAllIdsRequired = true;
            action.behaviour.data?.payload?.forEach((element: string) => {
                if (element.match(regex)) isAllIdsRequired = true;
            });
            return {
                ...action,
                isAllIdsRequired
            };
        });
    }
}
