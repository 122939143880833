import React from 'react';
import { Box } from '@mui/material';
import { useTypedSelector } from 'src/store';
import CircularProgress from '@mui/material/CircularProgress';

export const Loader = () => {
    const spinner = useTypedSelector((state) => state.system.spinnerRun);
    if (!spinner) {
        return <> </>;
    }
    return (
        <Box
            sx={{
                position: 'fixed',
                width: '100%',
                height: '100vh',
                top: 0,
                left: 0,
                background: 'rgba(255,255,255, 0.5)',
                zIndex: 10000000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <CircularProgress color="primary" size={60} />
        </Box>
    );
};
