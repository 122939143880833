import { MessageRow } from 'src/store/src/messageScreen/message/types';

export const messageScreenRowHidden: MessageRow[] = [
    {
        code: 'id_listy',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'id_szablonu',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        dynamicSet: {
            key: 'id_szablonu'
        },
        apiType: ['template']
    },
    {
        code: 'id_pliku',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'id_pliku_not_checked',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'id_klienta',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'typ_klienta',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'email',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['template']
    },
    {
        code: 'emaile',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['template']
    },
    {
        code: 'obiekt',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'id_obiektu',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'obiekty_dodatkowe_do_powiazania',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'inni',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'tu',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'wiele_maili',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'id_plikow',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'email_pobrany_z_pola',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['template']
    },
    {
        code: 'id_dokumentow_do_oznaczenia',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main']
    },
    {
        code: 'zmien_status_obiektu',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main']
    },
    {
        code: 'kod_pola_plik',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['main', 'template']
    },
    {
        code: 'id_pliku_zapytanieztu',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['template']
    },
    {
        code: 'awizacja',
        isVisibilityDependent: false,
        type: 'hidden',
        value: null,
        apiType: ['template']
    }
];
