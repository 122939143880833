import { ConfigRow, LoginableScreen } from 'src/api/src/configurationScreen/types';

const configStableRow: ConfigRow[] = [
    {
        code: 'nazwa_ekranu',
        name: ''
    },
    {
        code: 'hasla_dlugosc',
        name: ''
    },
    {
        code: 'hasla_dlugosc_maksymalna',
        name: ''
    },
    {
        code: 'hasla_duze_litery',
        name: ''
    },
    {
        code: 'hasla_male_litery',
        name: ''
    },
    {
        code: 'hasla_znaki_specjalne',
        name: ''
    },
    {
        code: 'hasla_cyfry',
        name: ''
    },
    {
        code: 'hasla_blokuj_popularne',
        name: ''
    },
    {
        code: 'hasla_ilosc_prob_do_blokady_czasowej',
        name: ''
    },
    {
        code: 'hasla_czas_blokady_po_nieprawidlowym_hasle_w_min',
        name: ''
    },
    {
        code: 'hasla_czestotliwosc_zmiany_hasla_w_dniach',
        name: ''
    },
    {
        code: 'hasla_wylogowanie_po_minutach',
        name: ''
    },
    {
        code: 'hasla_2fa',
        name: ''
    },
    {
        code: 'hasla_2fa_email',
        name: ''
    },
    {
        code: 'hasla_2fa_aplikacje',
        name: ''
    }
];

const configStableColumn: LoginableScreen[] = [
    {
        id: 'x',
        name: 'Pole hasła z ekranu:',
        fields: {
            // hasla_dlugosc: {
            //     typeField: 'text',
            //     value: 'Długość hasła minimalna:'
            // },
            // hasla_dlugosc_maksymalna: {
            //     typeField: 'text',
            //     value: 'Długość hasła maksymalna [0 jeśli brak]:'
            // },
            // hasla_duze_litery: {
            //     typeField: 'text',
            //     value: 'Duże litery:'
            // }
        }
    }
];

export { configStableRow, configStableColumn };
