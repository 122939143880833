import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldFileManyObjectProps } from 'src/components/popupFormExtended/dynamicField/fieldFileMany/types';
import { UIFieldFileMany } from 'src/components/popupFormExtended/dynamicField/fieldFileMany/UIFieldFileMany';
import { FileManager } from 'src/utils';
import { setPopupInfoTwo } from 'src/store/src/popup';
import { useAppDispatch } from 'src/store';

export const FieldFileManyObject: FC<FieldFileManyObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);
    const [progress, setProgress] = React.useState<number | undefined>(undefined);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    const appDispatch = useAppDispatch();

    const onChange = (fileList: FileList) => {
        const files = Array.from(fileList);
        console.log(fileList, 'fileList');
        setErrorMessage(undefined);
        loadFiles(files);
    };

    const loadFiles = async (files: File[]) => {
        if (
            files.findIndex(
                (file) => state.findIndex((stateFile) => file.name === stateFile.name) !== -1
            ) !== -1
        ) {
            setErrorMessage('Nie można przesłać ponownie tego samego pliku!');
            return;
        }

        const newFiles = files.map((file, i) => ({
            id: '',
            name: file.name,
            originalFileName: file.name,
            loading: true,
            idWew: String(file.lastModified) + String(file.size) + i
        }));

        setState((prev) => {
            const newState = [...prev, ...newFiles];

            dispatch({
                type: 'pliki_wielokrotne',
                payload: {
                    code: keyOfFieldInState,
                    value: { ...field, value: newState }
                }
            });

            return newState;
        });
        setProgress(0);
        const res = await FileManager.uploadFiles(
            'api_react/src/popupFormExtended/uploadFiles.php',
            files,
            (progress: number) => setProgress(progress * 100)
        );
        setProgress(undefined);
        console.log(res, 'res');
        if (!res.success) {
            setErrorMessage('Nie udało się przesłać plików!');
            appDispatch(
                setPopupInfoTwo({
                    message: res.message,
                    title: 'Błąd przesyłania plików',
                    messageInjectHtml: true
                })
            );

            setState((prev) => {
                const newState = prev.filter((f) => !f.loading);

                dispatch({
                    type: 'pliki_wielokrotne',
                    payload: {
                        code: keyOfFieldInState,
                        value: { ...field, value: newState }
                    }
                });

                return newState;
            });
            return;
        }
        const entries = res?.data ? Object.entries(res?.data) : [];
        setState((prev) => {
            const newValue = [
                ...prev.map((file) => {
                    const found = entries.find((item) => item[0] === file.idWew);
                    if (file.loading && found) {
                        return {
                            id: found[1].id,
                            name: found[1].name,
                            originalFileName: found[1].name + '.' + found[1].ext,
                            loading: false,
                            idWew: found[0]
                        };
                    }
                    return file;
                })
            ];
            dispatch({
                type: 'pliki_wielokrotne',
                payload: {
                    code: keyOfFieldInState,
                    value: { ...field, value: newValue }
                }
            });

            return newValue;
        });
    };

    const onDeleteFile = (_: React.MouseEvent<HTMLDivElement>, id: string) => {
        const newValue = state.filter((item) => item.id !== id);
        setState(newValue);
        dispatch({
            type: 'pliki_wielokrotne',
            payload: {
                code: keyOfFieldInState,
                value: { ...field, value: newValue.filter((file) => !file.loading) }
            }
        });
    };

    return (
        <UIFieldFileMany
            value={state}
            progress={progress}
            errorMessage={errorMessage}
            onChange={onChange}
            onDeleteFile={onDeleteFile}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT
            }}
        />
    );
};
