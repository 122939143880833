import { getGusDataByNip } from 'src/api/src/shared/getGusDataByNip';
import { DynamicFormDispatch } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { GUS_SESSION_SESSIONSTORAGE_KEY } from 'src/constants';
import { FormState } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { doDependentCalculationsAfterFieldChanged } from './doDependentCalculationsAfterFieldChanged';

type DynamicFormObjectGusDataLoaderResponse =
    | {
          success: true;
          isData: boolean;
      }
    | {
          success: false;
          message: string;
      };

export class DynamicFormObjectGusDataLoader {
    dispatch: DynamicFormDispatch;
    fieldId: string;

    formState: FormState;
    popupFormExtendedData: PopupFormExtendedData;

    constructor(
        dispatch: DynamicFormDispatch,
        fieldId: string,
        formState: FormState,
        popupFormExtendedData: PopupFormExtendedData
    ) {
        this.dispatch = dispatch;
        this.fieldId = fieldId;

        this.formState = formState;
        this.popupFormExtendedData = popupFormExtendedData;
    }
    async execute(
        nipCode: string,
        mode: 'add' | 'edit'
    ): Promise<DynamicFormObjectGusDataLoaderResponse> {
        const nipCodePrepared = this.prepareNIP(nipCode);
        const gusSession = this.getGusSession();

        const response = await getGusDataByNip(nipCodePrepared.trim(), this.fieldId, gusSession);

        if (!response.success) {
            console.error(
                'DynamicFormObjectGusDataLoader: getGusSession cannot get success response, error message: ',
                response.message
            );
            return { success: false, message: response.message };
        }
        if (response.data.gusSession) this.saveGusSession(response.data.gusSession);

        if (mode === 'edit')
            this.dispatch({
                type: 'set_values_to_accept',
                payload: response.data
            });
        else {
            await this.dispatch({
                type: 'update_field_values',
                payload: response.data.values
            });

            // make all necessary field dependance calculations(for example to show some fields)
            for (const fieldCode in response.data.values) {
                const dynamicFieldT = this.popupFormExtendedData.fields.find(
                    (item) => item.kod === fieldCode
                );

                this.dispatch(
                    doDependentCalculationsAfterFieldChanged({
                        fieldState: this.formState.fields[fieldCode],
                        dynamicFieldT,
                        popupFormExtendedData: this.popupFormExtendedData,
                        keyOfFieldInState: fieldCode
                    })
                );
            }
        }

        return { success: true, isData: !Array.isArray(response.data.values) };
    }

    private prepareNIP(nip: string) {
        if (typeof nip === 'string') {
            return nip.replaceAll(' ', '').replaceAll('-', '');
        }
        return nip;
    }

    getGusSession() {
        return sessionStorage.getItem(GUS_SESSION_SESSIONSTORAGE_KEY) ?? undefined;
    }
    saveGusSession(code: string) {
        return sessionStorage.setItem(GUS_SESSION_SESSIONSTORAGE_KEY, code);
    }
}
