import React from 'react';
import { useTheme } from '@mui/material/styles';

import { sxInputV1Default } from 'src/components/shared/atoms/uiInputs/sxDefault';
import { getColorForInputWithAllFlag } from 'src/styles/getColor';
import { UiFieldFlags } from 'src/components/popupFormExtended/dynamicField/types';

type UseFieldClassesProps = UiFieldFlags;

export function useStyleInlineOne(props: UseFieldClassesProps) {
    const theme = useTheme();

    const sxinputselect = React.useMemo(
        () => ({
            ...sxInputV1Default,
            border: `solid 1px ${getColorForInputWithAllFlag({
                theme,
                flag: {
                    archived: props.archived,
                    disabled: props.disabled,
                    isError: props.isError,
                    disabledWew: props.disabledWew
                }
            })}`,
            color: props.archived ? theme.palette.gray.second : theme.palette.text.input
        }),
        [props.archived, props.disabled, props.isError, props.disabledWew, theme]
    );
    return sxinputselect;
}
