import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Zoom } from '@mui/material';

const useStyles = makeStyles(({ palette }) => ({
    timer_select_btn_root: {
        color: palette.text.hint,
        width: 30,
        height: 30,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Wyświetla przycisk do anulowania przypisania sprawy do stopera.
 * @component
 * @category Components
 * @subcategory Timers
 * @param {object} props -  Props komponentu.
 * @param {string} props.info -  Informacja wyświetlana w tooltipie.
 * @param {Function} props.cancelAssign -  anuluje przypisanie
 * @returns {ReactComponent}
 * @see [Components/Timers/AssignForm]{@link AssignForm}
 */
const CancelAssignBtn = ({ cancelAssign, info, children }) => {
    const classes = useStyles();

    return (
        <Tooltip arrow TransitionComponent={Zoom} title={info}>
            <IconButton className={classes.timer_select_btn_root} onClick={cancelAssign}>
                {children}
            </IconButton>
        </Tooltip>
    );
};

export default CancelAssignBtn;
