import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import styles from './UICheckboxWewSquare.module.css';
import { UICheckboxWewSquareProps } from './types';

const UICheckboxWewSquare: FC<UICheckboxWewSquareProps> = ({
    checked,
    onClick,
    styleWew,
    styleZew
}) => {
    const theme = useTheme();
    return (
        <div
            style={{
                border: `1px solid ${theme.palette.primary.main}`,
                ...styleZew
            }}
            className={styles.boxThree}
            onClick={onClick}>
            {' '}
            {checked && (
                <div
                    style={{
                        background: theme.palette.primary.main,
                        ...styleWew
                    }}
                    className={styles.boxFour}
                />
            )}
        </div>
    );
};

export default UICheckboxWewSquare;
