import React, { FC, useMemo } from 'react';

import { FileUnmodifiedProps } from './types';
import styles from './UiFileUnmodified.module.css';
import { PopupBehavior, SystemAction } from 'src/data/types';
import { ActionType } from 'src/components/shared/molecules/actionType/ActionType';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { useAppDispatch } from 'src/store';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { fields } from 'src/constants/subtitles';

export const FileUnmodified: FC<FileUnmodifiedProps> = ({ fileData }) => {
    const dispatch = useAppDispatch();
    const isWord = fileData.ext === 'doc' || fileData.ext === 'docx';

    const actionDownload: SystemAction = useMemo(
        () => ({
            name: 'Pobierz',
            code: 'download_file_umodified',
            colorConditions: null,
            displayConditions: null,
            behaviour: {
                type: 'fileDownload',
                data: {
                    url: 'pobierz_plik.php',
                    params: '',
                    payload: [`{ "key": "id", "value": "${fileData.id}" }`]
                }
            }
        }),
        [fileData]
    );

    const actionWord: SystemAction = useMemo(
        () => ({
            name: 'Pobierz do edycji w MsWord',
            code: 'download_file_word_umodified',
            colorConditions: null,
            displayConditions: null,
            behaviour: {
                type: 'fileDownload',
                data: {
                    url: 'pobierz_plik.php',
                    params: '',
                    payload: [
                        `{ "key": "id", "value": "${fileData.id}" }`,
                        '{ "key": "word", "value": "1" }'
                    ]
                }
            }
        }),
        [fileData]
    );

    const handleSetPopupTemplate = () => {
        if (!fileData) {
            console.error("FieldFileUnmodified: cannot get file data 'dane_pliku'!");
            return;
        }

        const popupBehaviour: PopupBehavior = {
            type: 'popup',
            data: {
                template: 'preview',
                data: {
                    fileFieldCode: 'plik'
                }
            }
        };
        const actionPreview = {
            name: 'Podgląd',
            code: 'preview_file_umodified',
            colorConditions: null,
            displayConditions: null,
            behaviour: popupBehaviour
        };
        new PopupManager({ dispatch, action: actionPreview }).setPopupTemplate({
            behaviour: popupBehaviour,
            rowId: '1',
            sourceOfTrigger: {
                type: 'popupFormExtended',
                fileId: fileData.id,
                fileName: fileData.nazwa
            }
        });
    };

    return (
        <div className={styles.fileUnmodifiedWrapper}>
            <div className={styles.flexColumn}>
                <p>
                    {fileData.nazwa}.{fileData.ext}:
                </p>
                <p>({fileData.data})</p>
            </div>
            <ActionType action={actionDownload} rowId={null}>
                <div className={styles.unmodifiedAction}>
                    <ThemeCompatibleIcon
                        iconData={{ faCode: 'fa-download', svgFileName: 'pobierz.svg' }}
                        fontSize={'small'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    />
                    <p className={styles.textCenter}>{fields.fieldFile.download}</p>
                </div>
            </ActionType>
            {isWord && (
                <ActionType action={actionWord} rowId={null}>
                    <div className={styles.unmodifiedAction}>
                        <ThemeCompatibleIcon
                            iconData={{
                                faCode: 'fa-download',
                                svgFileName: 'plik_tekstowy.svg'
                            }}
                            fontSize={'small'}
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        />
                        <p className={styles.textCenter}>{fields.fieldFile.downloadForWord}</p>
                    </div>
                </ActionType>
            )}
            <div onClick={() => handleSetPopupTemplate()}>
                <div className={styles.unmodifiedAction}>
                    <ThemeCompatibleIcon
                        iconData={{ faCode: 'fa-eye', svgFileName: 'podglad.svg' }}
                        fontSize={'small'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    />
                    <p className={styles.textCenter}>{fields.fieldFile.preview}</p>
                </div>
            </div>
        </div>
    );
};
