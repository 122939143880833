import React, { FC } from 'react';

import { SumsRelationProps } from 'src/components/popupFormExtended/sumsRelation/types';
import { SumsSwitcher } from 'src/components/popupFormExtended/sumsSwitcher/SumsSwitcher';
import { DynamicFieldT } from 'src/data/popupFormTypes';

export const SumsRelation: FC<SumsRelationProps> = (props) => {
    const { relation } = props;

    if (!relation.sumsIsDisplay) {
        return null;
    }
    return (
        <tr
            style={{
                minHeight: '66px',
                position: 'relative'
            }}>
            {relation?.fields?.map((field: DynamicFieldT) => {
                if (field.ukryte === 1) return null;
                return (
                    <td key={field.id}>
                        <SumsSwitcher
                            dynamicFieldT={field}
                            keyOfFieldInState={`sumowanie_gorne_${relation.id}_${field.kod}_do_porownan`}
                        />
                    </td>
                );
            })}
            <td></td>
        </tr>
    );
};
