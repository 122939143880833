import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';

import { FieldDateHour } from 'src/components/popupFormExtended/dynamicField/fieldDateHour/types';
import { DateManager, intToHoutMinute } from 'src/utils';
import { NumberManager } from 'src/utils/src/shared/NumberManager';
import { JS_TIMESTAMP_HOUR } from 'src/constants/timestamps';

type FieldDependentDateManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldDateHour;
};

export class FieldDependentDateHourManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldDateHour;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentDateManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async przeliczWartosc() {
        if (
            this.fieldWasTriggered?.updatedNumber === undefined ||
            (this.fieldWasTriggered?.updatedNumber !== undefined &&
                this.fieldWasTriggered.updatedNumber < 5)
        ) {
            if (!this.fieldWasTriggered.isUpdatedCalc) {
                await this.calc();
                await this.runNested(this.fieldWasTriggeredDynamicFieldT);
            }
        }
    }

    private async calc() {
        const data = this.getValuesToSet();

        let newValue = 0;

        let value1 = 0;
        let value2 = 0;
        let update = false;
        switch (this.fieldSuperiorOne?.typ) {
            case 'data':
                value1 = data.one.timestampWithoutHourMinute;
                break;
            case 'data_godzina':
                value1 = data.one.timestampAll;
                break;
            case 'liczba':
                if (!isNaN(data.one.number)) {
                    value1 = data.one.number * JS_TIMESTAMP_HOUR;
                }
                break;
            default:
                break;
        }

        switch (this.fieldSuperiorTwo?.typ) {
            case 'data':
                value2 = data.two.timestampWithoutHourMinute;
                break;
            case 'data_godzina':
                value2 = data.two.timestampAll;
                break;
            case 'liczba':
                if (!isNaN(data.two.number)) {
                    value2 = data.two.number * JS_TIMESTAMP_HOUR;
                }
                break;
            case 'lista_obca_wielokrotna':
                value2 = data.two.numbersCheckbox;
                break;
            default:
                break;
        }

        switch (this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie) {
            case '+':
            case '-':
            case '/':
            case '*':
                newValue = NumberManager.calcArithmeticValue(
                    value1,
                    value2,
                    this.fieldWasTriggeredDynamicFieldT.wyliczenie_dzialanie
                );
                update = true;
                break;
            case '=bezgodzin':
                newValue =
                    data.one.timestampWithoutHourMinute +
                    DateManager.setTimestamFromHours(this.fieldWasTriggered.value.hour ?? 0) +
                    DateManager.setTimestamFromMinutes(this.fieldWasTriggered.value.minute ?? 0);
                update = true;
                break;
            case '=':
                newValue = data.one.timestampAll;
                update = true;
                break;
            default:
                break;
        }
        const minute = intToHoutMinute(
            new Date(newValue).getMinutes(),
            'minute',
            this.fieldWasTriggeredDynamicFieldT?.przedzial_minutowy
        );
        const minuteWithoutFirstZero = minute[0] === '0' ? minute.slice(1) : minute;

        const newValue2 = {
            minute: String(minuteWithoutFirstZero),
            hour: String(new Date(newValue).getHours()),
            date: new Date(newValue)
        };

        const value = {
            ...this.fieldWasTriggered,
            value: newValue2
        };
        if (update && newValue > 2) {
            this.setTempFieldsState(value, 'isUpdatedCalc');
            this.isRunNested = true;
        }
    }
}
