import { ColorFromApi } from 'src/data/types';
import { UI } from 'src/utils/src/list/manegersDataForInner/UI';

export function setColorRow({
    color,
    index,
    isCondensedView
}: {
    color: ColorFromApi;
    index?: number;
    isCondensedView: boolean;
}): string {
    if (isCondensedView && !color) {
        if (typeof index === 'number' && index % 2) return '#E8E8E8';
        else return '#fff';
    } else {
        return UI.chooseColor(color) || '#fff';
    }
}
