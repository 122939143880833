import React, { FC } from 'react';

import { UiConfigOrderListArrowProps } from './types';
import Arrow from 'src/components/object/atoms/arrow/Arrow';
import { UiConfigOrderListArrowBox } from '../uiConfigOrderListArrowBox/UiConfigOrderListArrowBox';

export const UiConfigOrderListArrow: FC<UiConfigOrderListArrowProps> = ({ isDown, onClick }) => {
    return (
        <UiConfigOrderListArrowBox onClick={onClick}>
            <Arrow
                isUnFolded={isDown}
                style={{
                    top: isDown ? '2px' : '-2px',
                    left: 0,
                    padding: '3px',
                    alignSelf: !isDown ? 'left' : ''
                }}
            />
        </UiConfigOrderListArrowBox>
    );
};
