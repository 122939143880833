import { getCardTreeElementsFlatten } from 'src/utils/src/shared/getCardTreeElementsFlatten';
import {
    actionPreview,
    actionDownloadOneFile,
    actionDownloadManyFiles,
    actionPreviewWithAttachment,
    actionDownloadWithAttachment
} from 'src/constants/stableActionsForRelationCardTreeElement';
import { SystemAction } from 'src/data/types';
import { RelationTreeCardElement } from 'src/store/src/object/object/types';
import {
    getCardTreeElementsFileIds,
    getCardTreeElementsFileIdsFromAttachments,
    getCardTreeElementsFileIdsNumbers
} from 'src/utils';

export type UseGetDataActionsCardTreeStarupScreen = {
    actions: SystemAction[];
    elements: RelationTreeCardElement[];
    isHasOneFile: boolean;
    ids: string;
};

type UseGetActionsCardTreeStarupScreenProps = {
    element: RelationTreeCardElement;
};

export const useGetDataActionsCardTreeStarupScreen = ({
    element
}: UseGetActionsCardTreeStarupScreenProps): UseGetDataActionsCardTreeStarupScreen => {
    const { actions, elements, isHasOneFile, ids } = new StartupElementContentDataActionsCreator({
        element
    }).getData();
    return { actions, elements, isHasOneFile, ids };
};

type ElementContentActionsCreatorProps = {
    element: RelationTreeCardElement;
};

export class StartupElementContentDataActionsCreator {
    actions: SystemAction[];
    element: RelationTreeCardElement;
    flattenElements: RelationTreeCardElement[];
    isHasAttachment: boolean;
    isHasOneFile: boolean;
    ids: string;
    constructor({ element }: ElementContentActionsCreatorProps) {
        this.element = element;
        this.flattenElements = getCardTreeElementsFlatten([element]);
        this.actions = [];
        this.ids = '';
        this.isHasAttachment = false;
        this.isHasOneFile = false;
        this.run();
    }

    private run() {
        this.checkAttachment();
        this.getIds();
        this.checkOneFile();
        this.setBasic('preview');
        this.setWithAttachment('preview');
        this.setBasic('download');
        this.setWithAttachment('download');
    }

    private checkAttachment() {
        for (const element of this.flattenElements) {
            if (element.attachmentsData.length) {
                this.isHasAttachment = true;
                break;
            }
        }
    }

    private getIds() {
        this.ids = getCardTreeElementsFileIds(this.flattenElements, this.element.fileId);
        if (this.isHasAttachment) {
            this.ids = getCardTreeElementsFileIdsFromAttachments(this.flattenElements, this.ids);
        }
    }

    private checkOneFile() {
        const numbers = getCardTreeElementsFileIdsNumbers(this.ids);
        if (numbers < 2) {
            this.isHasOneFile = true;
        }
    }

    private setBasic(type: 'preview' | 'download') {
        switch (type) {
            case 'preview':
                this.actions.push(actionPreview);
                break;

            case 'download':
                switch (this.element.screenCode) {
                    case 'dokumenty':
                        this.actions.push(actionDownloadOneFile);
                        break;
                    default:
                        if (this.isHasOneFile) {
                            this.actions.push(actionDownloadOneFile);
                        } else {
                            this.actions.push(actionDownloadManyFiles);
                        }
                        break;
                }
                break;
            default:
                break;
        }
    }

    private setWithAttachment(type: 'preview' | 'download') {
        switch (type) {
            case 'preview':
                if (this.isHasAttachment) this.actions.push(actionPreviewWithAttachment);
                break;
            case 'download':
                if (this.isHasAttachment) this.actions.push(actionDownloadWithAttachment);
                break;
            default:
                break;
        }
    }

    getData() {
        return {
            actions: this.actions,
            isHasOneFile: this.isHasOneFile,
            elements: this.flattenElements,
            ids: this.ids
        };
    }
}
