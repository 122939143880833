import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';
import { useLoadInitialConfigScreenData } from 'src/hooks/src/configScreen/useLoadInitialConfigScreenData';

const ConfigView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ConfigView" */ 'src/components/configScreen/templates/configView/ConfigView'
        )
);

export const ConfigMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Konfiguracja');
    useLoadInitialConfigScreenData();

    return (
        <div>
            <React.Suspense fallback>
                <ConfigView />
            </React.Suspense>
        </div>
    );
};
