import { useEffect } from 'react';
import { useFirstMountState } from './useFirstMountState';

// code from https://github.com/streamich/react-use

export const useUpdateEffect: typeof useEffect = (effect, deps) => {
    const isFirstMount = useFirstMountState();

    useEffect(() => {
        if (!isFirstMount) {
            return effect();
        }
    }, deps);
};

export default useUpdateEffect;
