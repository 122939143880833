import { useMemo } from 'react';
import { SystemAction } from 'src/data/types';
import { useTypedSelector } from 'src/store';
import { SettledObjectsCodes } from 'src/store/src/budget/budget/types';
import { convertParamsObjectToStr } from 'src/utils';

export const useCreateActionAddObject = (caseId: string, code: SettledObjectsCodes) => {
    const paramsAddObj = useTypedSelector((state) => state.budget.paramsAddObj);

    const params = paramsAddObj?.[code];

    const makeAddObjectAction = useMemo(
        (): SystemAction => ({
            name: 'Dodaj obiekt',
            code: 'add_task_in_budget',
            colorConditions: null,
            displayConditions: null,
            behaviour: {
                type: 'popupFormExtended',
                data: {
                    screen: code,
                    objectId: 'nowy',
                    additionalParameters: convertParamsObjectToStr(
                        params ?? {},
                        `window_name=${window.name}&powiazanie_id=${caseId}&`
                    )
                }
            }
        }),
        [caseId, code, params]
    );

    return makeAddObjectAction;
};
