import React, { FC } from 'react';

import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { FormContext } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import TrashIcon from 'src/assets/kosz.svg';
import styles from './RelationCancel.module.css';
import { RelationCancelProps } from 'src/components/popupFormExtended/relationCancel/types';

export const RelationCancel: FC<RelationCancelProps> = ({ prefixFieldKey }) => {
    const { formState, dispatchFormState } = React.useContext(FormContext);
    const relationToDelete =
        formState.deleteRelationship[`${prefixFieldKey}_powiazanie_usun_obiekt`];

    const isEdit = prefixFieldKey.includes('edycja');

    const onClick = () => {
        const updatedState = new DynamicFormObjectStateHandler({
            state: formState,
            prefixFieldKey
        }).handleState('delete_relation_item');
        console.log(updatedState);

        if (isEdit) {
            dispatchFormState({
                type: 'update_state',
                payload: {
                    sumsRelationship: updatedState.sumsRelationship,
                    deleteRelationship: updatedState.deleteRelationship
                }
            });
        } else {
            dispatchFormState({
                type: 'update_state',
                payload: {
                    tabs: updatedState.tabs,
                    sumsRelationship: updatedState.sumsRelationship,
                    fields: updatedState.fields
                }
            });
        }
    };

    return (
        <td onClick={onClick}>
            {relationToDelete === '1' ? <div className={styles.delete}></div> : null}
            <div
                onClick={onClick}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontSize: '16px'
                }}>
                {' '}
                <ThemeCompatibleIcon
                    SvgComponent={TrashIcon}
                    fontSize={'medium'}
                    sx={{
                        width: '13px'
                    }}
                />
            </div>
        </td>
    );
};
