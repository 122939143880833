import React from 'react';
import convert from 'react-from-dom';

export const SvgFromText: React.FC<React.SVGProps<SVGSVGElement> & { text: string }> = ({
    text,
    ...props
}) => {
    let svgProps: { [key: string]: string } = {};
    let paths;
    const regex = /<svg ([^>]+)>(.+)<\/svg>/gm;

    text = text.replace(/(\r\n|\n|\r)/gm, '')?.replace(/\s{2+}/g, '');

    let m = regex.exec(text);

    let pathText;
    if (m) {
        m![1]?.split('" ')?.forEach((attr) => {
            const [key, value] = attr?.split('=');
            let cleanVal = value?.replace(/"/g, '');
            svgProps[key] = cleanVal;
        });

        pathText = m![2];
        pathText = pathText?.trim();
    }

    // // remove fill
    const regexFill = /fill="([^ ]+)"/gm;
    if (pathText) {
        pathText = pathText?.replace(regexFill, '');
        //convert path . /> to <path d="..." ></path>
        const regexPath = /<path ([^>]+)\/>/gm;
        pathText = pathText?.replace(regexPath, '<path $1></path>');
    }
    paths = convert(`<g>${pathText}</g>`);

    return (
        <svg {...svgProps} {...props}>
            {paths}
        </svg>
    );
};
