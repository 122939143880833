import { title } from 'process';

export const validationData = {
    validateRequirement: {
        message: 'Pola obowiązkowe nieuzupełnione:',
        title: 'Weryfikacja pól obowiazkowych'
    },
    validateLogin: {
        title: 'Weryfikacja loginu'
    },
    validateDate: {
        title: 'Weryfikacja daty'
    },
    validateNRB: {
        title: 'Weryfikacja rachunku bankowego'
    },
    validateNIP: {
        title: 'Weryfikacja numeru NIP'
    },
    validatePESEL: {
        title: 'Weryfikacja numeru PESEL'
    },
    validatePossibleOptions: {
        title: 'Weryfikacja dostępnych opcji pól'
    },
    validateInvoiceCurrency: {
        title: 'Weryfikacja waluty faktury',
        message: 'Waluta faktury jest odmienna od waluty rachunku. Czy chcesz kontytuować?'
    },
    validateCollision: {
        title: 'Weryfikacja duplikatu'
    },
    validateCalendarCollision: {
        title: 'Weryfikacja Terminu w kalendarzu'
    },
    validateFileLoading: {
        title: 'Weryfikacja przesyłania plików'
    },
    validateDateCorrectness: {
        title: 'Weryfikacja daty zakończenia',
        message: 'Data zakończenia musi być późniejsza niż data rozpoczęcia'
    },
    validateInvoiceBill: {
        title: 'Weryfikacja daty faktury'
    },
    validateMail: {
        title: 'Weryfikacja emaila'
    },
    validateRelationExtandedForced: {
        title: 'Walidacja powiazania rozszerzonego'
    },
    validateDateWrite: {
        title: 'Walidacja daty wystawienia'
    }
};
