export class DataFromApiConverter {
    static getBoolean(value: any) {
        if (value === 1 || value === '1' || value === 't' || value === true) {
            return true;
        }
        return false;
    }
    static getStringifiedNumber(value: any) {
        if (typeof value === 'number') return String(value);
        if (typeof value !== 'string') return null;

        const newValue = DataFromApiConverter.getNumberFiedNumber(value);
        if (!Number.isNaN(newValue)) return String(newValue);

        return null;
    }

    static getNumberFiedNumber(value: any) {
        if (typeof value !== 'string') return null;

        const newValue = String(value).replaceAll(',', '.').replace(' ', '');
        return Number(newValue);
    }

    static getNumber(valueFromApi: any) {
        let value = valueFromApi;

        if (typeof value === 'string') {
            value = value.replaceAll(',', '.').replace(' ', '');
        }
        if (isNaN(value) || value === null) {
            value = 0;
        }
        return Number(value);
    }
    static getNumberString(valueFromApi: any) {
        return String(DataFromApiConverter.getNumber(valueFromApi));
    }
}
