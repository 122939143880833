import { SelectOption } from 'src/data/uiTypes/selectTypes';

export type UseOptionsSortedType = 'alphabetical';

export function useOptionsSorted(options: SelectOption[], sort?: UseOptionsSortedType) {
    switch (sort) {
        case 'alphabetical':
            return options.sort((a, b) => a.name.localeCompare(b.name));
        default:
    }
    return options;
}
