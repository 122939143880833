import { DateManager } from 'src/utils/src/shared/DateManager';

export function checkTimestampIsInMonth(
    timestampStartMonth: number | string,
    timestampToCheck: number
) {
    const timestampStart = Number(timestampStartMonth);
    const timestampEnd = DateManager.addAmountOfMonthTimestampToTimestampAbsolute(
        Number(timestampStartMonth)
    );
    if (timestampToCheck >= timestampStart && timestampToCheck < timestampEnd) {
        return true;
    }
    return false;
}
