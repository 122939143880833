import { FieldPlacement } from 'src/components/shared/atoms/fieldMinWidth/types';
import { DynamicFieldT } from 'src/data/popupFormTypes';

export const getMinWidthForField = (field: DynamicFieldT, fieldPlacement?: FieldPlacement) => {
    switch (field.typ) {
        case 'data_godzina':
        case 'nip':
            return '300px';
        case 'cena':
        case 'cena_liczba':
            return '400px';
        case 'lista_obca_wielokrotna':
        case 'lista_wielokrotna':
        case 'lista_wielokrotna_cena':
            return '300px';
        case 'powiazanie_typ':
        case 'powiazanie_typ_wielokrotne':
            switch (fieldPlacement) {
                case 'massEditObjects':
                    return '400px';
                default:
                    return '280px';
            }
        case 'tekst_bez_ograniczen':
        case 'koszty':
            return '200px';
        case 'pliki_wielokrotne':
            return '260px';
        case 'liczba_rozliczana_do_zera':
            return '350px';
        case 'data':
        case 'lista':
        case 'lista_cena':
        case 'lista_zalezna':
            switch (fieldPlacement) {
                case 'massEditObjects':
                    return '150px';
                default:
                    return '100px';
            }
        case 'lista_obca':
            return '200px';
        case 'liczba':
        case 'tekst':
            switch (fieldPlacement) {
                case 'massEditObjects':
                    return '200px';
                default:
                    return '100px';
            }
        case 'email':
            switch (fieldPlacement) {
                case 'massEditObjects':
                    return '200px';
                default:
                    return '100px';
            }
        case 'checkbox':
            return '50px';
        case 'tekst_z_przedrostkiem':
        case 'data_wzgledna':
            return '150px';
        default:
            return 'auto';
    }
};
