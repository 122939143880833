import { DynamicFieldT } from './../../../data/popupFormTypes.d';

export function getTypeUrl({ field }: { field: DynamicFieldT }) {
    switch (field.xml_path) {
        case 'xml_ogolnie_dotyczy.php':
            return 'json_ogolnie_dotyczy.php';
        case 'xml_ogolnie_podrzedne.php':
            return 'json_ogolnie_podrzedne.php';
        default:
            return 'json_ogolnie_dotyczy.php';
    }
}
