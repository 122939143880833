/**
 * colors used for system entities
 */
export enum SYSTEM_COLORS {
    Primary = '#1976d2',
    PrimaryDark = '#115293',
    Secondary = '#e33371',
    SecondaryDark = '#9a0036',
    Error = '#f44336',
    Warning = '#ff9800',
    Info = '#64b5f6',
    Success = '#4caf50',
    Purple = '#9c27b0',
    Cyan = '#00bcd4',
    CyanLight = '#84ffff',
    Teal = '#1de9b6',
    LightGreen = '#8bc34a',
    Lime = '#cddc39',
    Amber = '#ffca28',
    DeepOrange = '#ff7043',
    BlueGray = '#78909c',
    Brown = '#795548'
}

/**
 * colors used for system entities statuses
 */
export enum STATUS_COLORS {
    Normal = '#403c3e',
    Done = '#2e7d32',
    Late = '#d50000',
    Canceled = '#757575'
}

export type SystemColors = keyof typeof SYSTEM_COLORS;
export type StatusColors = keyof typeof STATUS_COLORS;
