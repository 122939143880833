import React, { FC } from 'react';
import { PermissionsFieldProps } from './types';
import { UiCheckbox } from 'src/components/shared/atoms/uiInputs/uiCheckbox/UiCheckbox';
import { InitialPermissionsFormStateCreator } from 'src/utils/src/permissionsScreen/InitialPermissionsFormStateCreator';
import { PermissionsScreenManager } from 'src/utils';
import { PERIMISSION_LEVEL_INDENT } from 'src/constants/permissionsScreen';
import { useController } from 'react-hook-form';

const PermissionsField: FC<PermissionsFieldProps> = ({
    functionId,
    item,
    type,
    level,
    control
}) => {
    const key = InitialPermissionsFormStateCreator.buildFormStateKey(functionId, item, type, level);

    const { field } = useController({ control, name: key });

    return (
        <UiCheckbox
            label={PermissionsScreenManager.getPermissionTypeText(type, level)}
            value={field.value}
            checked={field.value}
            onChange={field.onChange}
            sxelwrapper={{
                justifyContent: 'flex-start',
                marginLeft: PERIMISSION_LEVEL_INDENT,
                padding: 0
            }}
            sxcheckbox={{ padding: '2px' }}
        />
    );
};

export default React.memo(PermissionsField);
