import React, { FC } from 'react';
import { Box } from '@mui/material';
import { UiInputSharedProps } from 'src/components/shared/atoms/uiInputs/types';
import styles from './UiInputElWrapper.module.css';

export const UiInputElWrapper: FC<UiInputSharedProps> = (props) => {
    return (
        <Box
            className={styles.wrapper}
            sx={{
                ...props.wew,
                ...props.sxelwrapper
            }}>
            {props.children}
        </Box>
    );
};
