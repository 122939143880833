import { TemplateItem } from 'src/utils/src/shared/TemplateItem';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
export class TemplateGlobalManager {
    checkIsTemplateExist({ str, regex }: { str: string; regex: RegExp }) {
        if (typeof str !== 'string') return null;
        return str.match(regex);
    }
    getTemplateItem(str?: string | null) {
        return new TemplateItem({ itemStr: str ? str : '' });
    }
    replaceContent({
        contentToReplace,
        templateItem,
        temporaryAllString
    }: {
        contentToReplace?: string | null;
        templateItem: TemplateItem;
        temporaryAllString: string;
    }) {
        const contToReplace = contentToReplace ? contentToReplace : '';
        return temporaryAllString.replace(templateItem.content, contToReplace);
    }

    getTextOrRawValue({
        rowData,
        rowId,
        templateItem,
        type
    }: {
        templateItem: TemplateItem;
        rowId?: RowDataId | null;
        rowData: RowData[];
        type: 'text' | 'rawValue';
    }) {
        const rowFound = rowData.find((item) => item.id === rowId);
        if (rowFound) {
            const rawValue = rowFound?.cellValues[templateItem.contentClearWIthoutFlag]?.map(
                (item) => item[type]
            );
            if (Array.isArray(rawValue)) return rawValue.join(',');
            return rawValue;
        }
        return null;
    }
}
