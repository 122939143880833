const regexSign = /([@|#|$|!])/;
const regexOption = /(allVisible|all|selected)/;
const regexFlag = /\[(.*?)\]/;

type TemplateItemProps = {
    itemStr: string;
};
export class TemplateItem {
    content: string;
    contentClear: string;
    contentClearWIthoutFlag: string;
    sign: string;
    option: string;
    isDolar: boolean;
    constructor({ itemStr }: TemplateItemProps) {
        this.content = itemStr;
        this.contentClear = itemStr.substring(2).slice(0, -1);
        this.contentClearWIthoutFlag = itemStr.substring(2).slice(0, -1).replace(regexFlag, '');
        this.sign = this.getProperty(itemStr, regexSign);
        this.option = this.getProperty(itemStr, regexOption);
        this.isDolar = false;
        this.checkIsDolar();
        this.returnData();
    }
    getProperty(itemStr: string, regex: RegExp) {
        const property = itemStr.match(regex);
        if (property) {
            return property[0];
        }
        return '';
    }
    checkIsDolar() {
        if (this.sign.includes('$')) this.isDolar = true;
    }
    returnData() {
        return {
            content: this.content,
            sign: this.sign,
            option: this.option,
            contentClear: this.contentClear
        };
    }
}
