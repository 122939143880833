import React, { FC } from 'react';
import { Container } from '@mui/material';

export const LoginLayoutWrapper: FC = ({ children }) => {
    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            {children}
        </Container>
    );
};
