import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';

import { FormValidationDataRefRHF } from 'src/components/form/molecules/formValidator/types';
import { dataSetInputKey } from 'src/constants/dataset';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';
import { ScreenRHF } from 'src/data/commonRHF';
import { FieldsStateRHF } from 'src/data/fieldsReactHookForm';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { setPopupInfoTwo } from 'src/store/src/popup';
import { PopupPayloadsAll } from 'src/store/src/popup/popup/types';
import { EventHandler } from 'src/utils/src/shared/EventHandler';
import { HelperRHF } from 'src/utils/src/shared/HelperRHF';

type FormValidationResultHanderRHFProps = {
    dynamicFieldT: DynamicFieldT[];
    formValidationDataRef: FormValidationDataRefRHF;
    dispatchPopupStateRedux: Dispatch<PayloadAction<PopupPayloadsAll>>;
    getValues: UseFormGetValues<FieldsStateRHF>;
    reset: UseFormReset<FieldsStateRHF>;
    screen: ScreenRHF;
};

export class FormValidationResultHanderRHF {
    dynamicFieldT: DynamicFieldT[];
    formValidationDataRef: FormValidationDataRefRHF;
    dispatchPopupStateRedux: Dispatch<PayloadAction<PopupPayloadsAll>>;
    getValues: UseFormGetValues<FieldsStateRHF>;
    reset: UseFormReset<FieldsStateRHF>;
    screen: ScreenRHF;
    constructor({
        dynamicFieldT,
        formValidationDataRef,
        dispatchPopupStateRedux,
        reset,
        getValues,
        screen
    }: FormValidationResultHanderRHFProps) {
        this.dynamicFieldT = dynamicFieldT;
        this.formValidationDataRef = formValidationDataRef;
        this.dispatchPopupStateRedux = dispatchPopupStateRedux;
        this.reset = reset;
        this.getValues = getValues;
        this.screen = screen;
    }

    async exec() {
        await this.runOne();
    }

    private async runOne() {
        if (
            typeof this.dispatchPopupStateRedux === 'function' &&
            typeof this.reset === 'function'
        ) {
            if (this.formValidationDataRef.validationResult.isNotValid) {
                return new Promise<boolean>((res) => {
                    switch (this.formValidationDataRef.validationResult.typeFnValidates) {
                        case 'validateRequirement':
                            {
                                const fieldsArray = Object.keys(
                                    this.formValidationDataRef.fieldsNotValid
                                );
                                const { eventScrollId } = EventHandler.setFieldFocusON({
                                    scrollKey:
                                        'key' +
                                        this.formValidationDataRef.validationResult.scrollKey,
                                    res,
                                    dataKey: dataSetInputKey
                                });

                                this.dispatchPopupStateRedux(
                                    setPopupInfoTwo({
                                        message:
                                            validationData[
                                                this.formValidationDataRef.validationResult
                                                    .typeFnValidates
                                            ].message,
                                        title: validationData[
                                            this.formValidationDataRef.validationResult
                                                .typeFnValidates
                                        ].title,
                                        infoList: fieldsArray.map((item) => {
                                            const code =
                                                HelperRHF.getPropertyKey({
                                                    key: item,
                                                    type: 'kod',
                                                    screen: this.screen
                                                }) ?? undefined;
                                            const field = this.dynamicFieldT.find(
                                                (item) => item.kod === code
                                            );
                                            if (field) {
                                                return field.nazwa;
                                            }
                                            return code;
                                        }),
                                        closeCallbackEventId: eventScrollId
                                    })
                                );

                                const dataNew: FieldsStateRHF = {};
                                fieldsArray?.forEach((key) => {
                                    dataNew[key] = {
                                        ...this.getValues(key),
                                        isError: true
                                    };
                                });
                                this.reset((prev) => ({ ...prev, ...dataNew }));
                            }
                            break;
                        default:
                            break;
                    }
                });
            }
        }
    }
}
