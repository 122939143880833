import { BudgetStableFieldSum } from 'src/store/src/budget/budget/types';

export type BudgetStableFieldSumType =
    | 'rok'
    | 'sprawa'
    | 'sprawy'
    | 'projekt'
    | 'projekty'
    | 'miesiac'
    | 'przedcapfee'
    | 'capfee'
    | 'podsumowanie';

export const budgetStableFieldSum: {
    [key in BudgetStableFieldSumType]: BudgetStableFieldSum[];
} = {
    rok: [
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '15',
            colspan: 1,
            empty: false
        }
    ],
    sprawa: [
        {
            id: '1',
            colspan: 4,
            text: 'Suma z ',
            empty: false
        },
        // Poświecony czas
        {
            id: '5',
            colspan: 1,
            empty: false
        },
        {
            id: '6',
            colspan: 1,
            empty: false
        },
        // Godziny do faktury
        {
            id: '7',
            colspan: 1,
            empty: false
        },
        {
            id: '9',
            colspan: 1,
            empty: true
        },
        // Cena netto
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '13',
            colspan: 1,
            empty: true
        },
        // Cena brutto
        {
            id: '15',
            colspan: 1,
            empty: false
        },
        {
            id: '20',
            colspan: 1,
            empty: true
        }
    ],
    sprawy: [
        {
            id: '1',
            colspan: 6,
            text: 'Suma ze spraw',
            empty: false
        },
        {
            id: '2',
            colspan: 1,
            empty: true
        },
        // Cena netto
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '13',
            colspan: 1,
            empty: true
        },
        // Cena brutto
        {
            id: '15',
            colspan: 1,
            empty: false
        },
        {
            id: '20',
            colspan: 1,
            empty: true
        }
    ],
    projekt: [
        {
            id: '1',
            colspan: 6,
            text: 'Suma z ',
            empty: false
        },
        {
            id: '2',
            colspan: 1,
            empty: true
        },
        // Cena netto
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '13',
            colspan: 1,
            empty: true
        },
        // Cena brutto
        {
            id: '15',
            colspan: 1,
            empty: false
        },
        {
            id: '20',
            colspan: 1,
            empty: true
        }
    ],
    projekty: [
        {
            id: '1',
            colspan: 6,
            text: 'Suma z Projektów',
            empty: false
        },
        {
            id: '2',
            colspan: 1,
            empty: true
        },
        // Cena netto
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '13',
            colspan: 1,
            empty: true
        },
        // Cena brutto
        {
            id: '15',
            colspan: 1,
            empty: false
        },
        {
            id: '20',
            colspan: 1,
            empty: true
        }
    ],
    miesiac: [
        {
            id: '1',
            colspan: 6,
            text: 'Suma ',
            empty: false
        },
        {
            id: '2',
            colspan: 1,
            empty: true
        },
        // Cena netto
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '13',
            colspan: 1,
            empty: true
        },
        // Cena brutto
        {
            id: '15',
            colspan: 1,
            empty: false
        },
        {
            id: '20',
            colspan: 1,
            empty: true
        }
    ],
    przedcapfee: [
        {
            id: '1',
            colspan: 6,
            text: 'Przed Cap Fee',
            empty: false
        },
        {
            id: '2',
            colspan: 1,
            empty: true
        },
        // Cena netto
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '13',
            colspan: 1,
            empty: true
        },
        // Cena brutto
        {
            id: '15',
            colspan: 1,
            empty: false
        },
        {
            id: '20',
            colspan: 1,
            empty: true
        }
    ],
    capfee: [
        {
            id: '1',
            colspan: 7,
            text: 'Cap Fee',
            empty: false
        },
        {
            id: '2',
            colspan: 1,
            empty: true
        },
        // Cena sztuka
        {
            id: '9',
            colspan: 1,
            empty: false,
            code: 'capfee'
        },
        // Cena netto
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '13',
            colspan: 1,
            empty: true
        },
        // Cena brutto
        {
            id: '15',
            colspan: 1,
            empty: false
        },
        {
            id: '20',
            colspan: 1,
            empty: true
        }
    ],
    podsumowanie: [
        {
            id: '1',
            colspan: 6,
            text: 'Podsumowanie',
            empty: false
        },
        {
            id: '2',
            colspan: 1,
            empty: true
        },
        // Cena netto
        {
            id: '12',
            colspan: 1,
            empty: false
        },
        {
            id: '13',
            colspan: 1,
            empty: true
        },
        // Cena brutto
        {
            id: '15',
            colspan: 1,
            empty: false
        },
        {
            id: '20',
            colspan: 1,
            empty: true
        }
    ]
};
