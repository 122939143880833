import {
    FieldBasicStateRHF,
    FieldDateStateRHF,
    FieldListStateRHF,
    FieldNumberStateRHF,
    FieldsStateUnionRHF
} from 'src/data/fieldsReactHookForm';

export function getValueNumberRHF(fieldState: FieldsStateUnionRHF) {
    let value: number = 0;
    if (!fieldState) return value;
    switch (true) {
        case 'liczba' in fieldState: {
            const oneObj: Required<FieldNumberStateRHF> =
                fieldState as Required<FieldNumberStateRHF>;
            if (oneObj?.liczba) {
                value = Number(oneObj.liczba);
            }
            break;
        }
        case 'basic' in fieldState: {
            const oneObj: Required<FieldBasicStateRHF> = fieldState as Required<FieldBasicStateRHF>;
            if (oneObj?.basic) {
                value = Number(oneObj.basic);
            }
            break;
        }
        case 'lista' in fieldState: {
            const oneObj: Required<FieldListStateRHF> = fieldState as Required<FieldListStateRHF>;
            if (oneObj?.lista) {
                value = Number(oneObj?.lista?.value);
            }
            break;
        }
        case 'data' in fieldState: {
            const oneObj: Required<FieldDateStateRHF> = fieldState as Required<FieldDateStateRHF>;
            if (oneObj?.data) {
                value = new Date(oneObj?.data).getTime();
            }
            break;
        }
    }
    return value;
}
