type ReplaceTypes = 'commaForDotAll' | 'clearWhiteSpaces' | 'onlyPositiveDigitAll' | 'onlyDigitAll';

type ReplaceStrFnPros = {
    str: string;
    replaceTypes: ReplaceTypes[];
};

export function replaceStr({ str, replaceTypes }: ReplaceStrFnPros) {
    let newStr = str;
    replaceTypes.forEach((type) => {
        switch (type) {
            case 'commaForDotAll':
                newStr = newStr.replaceAll(',', '.');
                break;
            case 'clearWhiteSpaces':
                newStr = newStr.replaceAll(' ', '');
                break;
            case 'onlyPositiveDigitAll':
                newStr = newStr.replaceAll(/[^0-9.]/g, '');
                break;
            case 'onlyDigitAll':
                {
                    const firstCharakter = newStr.slice(0, 1);
                    if (firstCharakter === '-') {
                        newStr = '-' + newStr.replaceAll(/[^0-9.]/g, '');
                    } else {
                        newStr = newStr.replaceAll(/[^0-9.]/g, '');
                    }
                }
                break;
            default:
                break;
        }
    });

    return newStr;
}
