import { BaseApiResponse } from '../../types';
import { ObjectDataFromApi } from 'src/store/src/object/object/types';
import { TabId } from 'src/store/src/object/object/types';
import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';

export async function getInitialObjectScreenData({
    objectId,
    tabId,
    screen,
    sectionParams
}: {
    objectId: string;
    tabId?: TabId;
    screen: string;
    sectionParams?: string[][];
}): Promise<BaseApiResponse<ObjectDataFromApi>> {
    const params = new URLSearchParams([
        ['screen', screen],
        ['objectId', objectId],
        ['tabId', tabId ? tabId : ''],
        ...(sectionParams ?? [])
    ]);

    return simpleQueryAPIData(
        `api_react/src/objectScreen/getInitialObjectScreenData.php?${params.toString()}`
    );
}
