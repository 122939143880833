import React, { FC } from 'react';

import { UiConfigOrderListsWrapperProps } from './types';
import styles from './styles.module.css';

export const UiConfigOrderListsWrapper: FC<UiConfigOrderListsWrapperProps> = ({
    children,
    style
}) => {
    return (
        <div className={styles.wrapper} style={style}>
            {children}
        </div>
    );
};
