import React, { FC, Reducer } from 'react';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { NavMenuItemProps } from './types';
import { useTheme } from '@mui/system';
import { ubdateModule } from 'src/store/src/menu';
import adminEdit from 'src/assets/ikonka_konfiguracji.svg';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { getUrlParams } from 'src/utils/src/shared/getUrlParams';
import AnimableMenuIcon from '../animableMenuIcon/AnimableMenuIcon';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import styles from 'src/styles/menu.module.css';
import { iconStyleCommon } from '../../atoms/icon/Icon';

const NavMenuItem: FC<NavMenuItemProps> = ({ isAdmin = false, isSelected, screen }) => {
    const theme = useTheme();
    const dispatchRedux = useAppDispatch();
    const menuStructureData = useTypedSelector((state) => state.menu.menuStructureData);
    const isUnfolded = useTypedSelector((state) => state.menu.isUnfolded);

    const adminParams = () => {
        const url: string = '/ekran_edycji_ekranu.php';

        const queryParams = new URLSearchParams(`ekran=${screen.id}`);

        return { url: url, queryParams: queryParams };
    };

    const [showAdminIcon, setShowAdminIcon] = React.useState<boolean>(false);

    const enterItem = (): void => {
        isAdmin === true && setShowAdminIcon(true);
    };

    const leaveItem = (): void => {
        isAdmin === true && setShowAdminIcon(false);
    };

    const setMenuState = () => {
        MenuStateManager.ubdateMenuModuleData({
            menuStructureData,
            dispatch: dispatchRedux,
            ubdateModule,
            screenSelected: screen.id,
            moduleSelected: null
        });
    };
    return (
        <div>
            <LegacyCompatibleLink
                {...getUrlParams(screen)}
                outerFn={setMenuState}
                rightClickBlank={true}>
                <div
                    className={`${styles.childLevelBox}`}
                    style={{
                        color: theme.palette.primary.main,
                        backgroundColor: `${isSelected ? '#c9c9c9' : 'inherit'}`
                    }}
                    onMouseEnter={() => {
                        enterItem();
                    }}
                    onMouseLeave={() => {
                        leaveItem();
                    }}>
                    <div className={styles.menuPosition}>
                        <div className={styles.iconWraper}>
                            <div
                                className={
                                    showAdminIcon
                                        ? `${styles.adminIconShow}`
                                        : `${styles.adminIconHide}`
                                }>
                                <div className={styles.absolute}>
                                    <LegacyCompatibleLink
                                        {...adminParams()}
                                        outerFn={setMenuState}
                                        stopPropagation={true}>
                                        <div className={`${styles.svgIcon} `}>
                                            <ThemeCompatibleIcon
                                                SvgComponent={adminEdit}
                                                sx={{
                                                    ...iconStyleCommon
                                                }}
                                            />
                                        </div>
                                    </LegacyCompatibleLink>
                                </div>
                            </div>

                            <div>
                                <AnimableMenuIcon
                                    iconData={screen.icon}
                                    sx={{
                                        ...iconStyleCommon,
                                        border: '1px solid transparent'
                                    }}
                                    lockWhite={false}
                                />
                            </div>
                        </div>
                        {isUnfolded && (
                            <p className={`${styles.menuText}  ${styles.font13}`}>{screen.name}</p>
                        )}
                    </div>
                </div>
            </LegacyCompatibleLink>
        </div>
    );
};

export default React.memo(NavMenuItem);
