import { InputValidator } from 'src/components/popupFormExtended/dynamicField/shared/InputValidator';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { TimeManager } from 'src/utils/src/shared/TimeManager';

export function prepareValueToSetForFieldNumberWithCalcMinuteHour({
    dynamicFieldT,
    oldValue,
    newValue,
    type
}: {
    oldValue: string;
    newValue: string;
    type: 'decimal' | 'normal';
    dynamicFieldT: DynamicFieldT;
}) {
    let valid = true;
    let valuePrepared = '';

    const checkedValue = new InputValidator({
        dynamicFieldT,
        oldValue,
        staticActions: ['runSize', 'runOnlyNumberAndDotCommna'],
        newValue
    }).execute();

    let valueStr = checkedValue ? checkedValue.replaceAll(',', '.') : '';

    if (isNaN(Number(valueStr))) {
        valid = false;
    }
    if (valid) {
        if (type === 'decimal') {
            valuePrepared = valueStr;
        } else {
            const value = TimeManager.setMinutesAbsoluteToHour(Number(valueStr));
            valuePrepared = String(value.toFixed(3));
        }
    }
    return {
        valid,
        valuePrepared
    };
}
