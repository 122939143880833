import React, { FC } from 'react';

import styles from './UiFieldDateRelative.module.css';
import { UIFieldDateRelativeProps } from 'src/components/popupFormExtended/dynamicField/fieldDateRelative/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputMask } from 'src/components/popupFormExtended/dynamicField/shared/uiInputMask/UiInputMask';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

export const UiFieldDateRelative: FC<UIFieldDateRelativeProps> = (props) => {
    const {
        value,
        onBlurInput,
        onChangeInput,
        onChangeSelect,
        opcje,
        uiFieldCommonProps,
        uiFieldSharedProps
    } = props;
    const { disabled } = uiFieldCommonProps;
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputMask
                    {...uiFieldCommonProps}
                    value={value.date ?? ''}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    type="tekst"
                    maskChar={''}
                    mask="999"
                    disabledWew={disabled}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                <UiInputSelectTwo
                    {...uiFieldCommonProps}
                    value={value.zakres}
                    opcje={opcje}
                    onChange={onChangeSelect}
                />
            </div>
        </UiWrapper>
    );
};
