import React, { FC, KeyboardEvent } from 'react';
import { useForm } from 'react-hook-form';

import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup, setPopupInfoTwo } from 'src/store/src/popup';
import { PopupTemplateFour } from 'src/components/shared/organisms/popupTemplateFour/PopupTemplateFour';
import {
    DynamicStateFormHandler,
    DynamicStateForm
} from 'src/components/shared/molecules/dynamicForm/DynamicFormStateHandler';
import { globalPopup } from 'src/constants/subtitles';
import { DynamicFormItem } from 'src/components/shared/molecules/dynamicForm/DynamicFormItem';
import { DynamicFormDataApiCreator } from 'src/components/shared/molecules/dynamicForm/DynamicFormDataApiCreator';
import { DynamicTitle } from 'src/components/shared/molecules/dynamicForm/DynamicTitle';
import { DynamicDecscription } from 'src/components/shared/molecules/dynamicDescription/DynamicDescription';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { useSafeNavigate, useUpdateObjectsData } from 'src/hooks';
import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import { DynamicFormButton } from 'src/components/shared/atoms/dynamicFormButton/DynamicFormButton';
import { PopupFormBehavior } from 'src/data/popupFormTypes';
import { useRefreshObjectData } from 'src/hooks/src/object/useRefreshObjectData';
import { getCompatibleUrl } from 'src/utils';
import { useLocation } from 'react-router-dom';

export const PopupContentDynamic: FC<BasePopupContentProps> = ({
    heightAvailable,
    widthAvailable,
    popup
}) => {
    const dispatch = useAppDispatch();
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const objectSlice = useTypedSelector((state) => state.object);
    const behaviour = popup?.action?.behaviour as PopupFormBehavior;
    const navigate = useSafeNavigate();
    const location = useLocation();
    const [submitted, setSubmitted] = React.useState(false);
    const updateObjects = useUpdateObjectsData();
    const refreshObjectScreenData = useRefreshObjectData();
    const { control, handleSubmit } = useForm({
        defaultValues: new DynamicStateFormHandler({
            fields: popup.popupFormData?.fields,
            rowData: rowData,
            object: objectSlice,
            popupState: popup
        }).createInitState()
    });

    const onSubmit = async (data: DynamicStateForm) => {
        if (submitted) return;

        setSubmitted(true);

        const dataToApi = new DynamicFormDataApiCreator({
            state: data,
            fields: popup.popupFormData?.fields ?? []
        }).getData();
        switch (popup?.sourceOfTrigger.type) {
            case 'addObjectShortcuts':
                {
                    dispatch(setSpinner(true));
                    const res = await simpleQueryAPIData<any>(
                        behaviour.data.url,
                        'POST',
                        dataToApi['nowy'],
                        true
                    );

                    dispatch(setSpinner(false));
                    if (res.success) {
                        const newObjectId = res?.data?.id;
                        const object =
                            behaviour.data.screen ??
                            new URLSearchParams(behaviour.data.url.split('?')[1]).get('screen');
                        navigate(
                            getCompatibleUrl(
                                'ekran_obiektu.php',
                                new URLSearchParams([
                                    ['id', newObjectId],
                                    ['obiekt', object]
                                ])
                            ),
                            null,
                            undefined,
                            undefined,
                            location.pathname === '/dodaj-obiekt'
                        );
                        dispatch(closePopup(popup.key));
                    } else {
                        setSubmitted(false);
                        dispatch(
                            setPopupInfoTwo({
                                message: res.message,
                                title: 'Błąd'
                            })
                        );
                    }
                }
                break;
            case 'startupCardList': {
                dispatch(setSpinner(true));
                const res = await simpleQueryAPIData(behaviour.data.url, 'POST', dataToApi, true);
                if (res.success) {
                    popup?.sourceOfTrigger?.updateData();
                }
                dispatch(setSpinner(false));
                if (behaviour?.data.showResult) {
                    new PopupManager({ dispatch, action: popup?.action }).setPopupInfo(res.message);
                }
                dispatch(closePopup(popup.key));
                break;
            }
            default:
                {
                    dispatch(setSpinner(true));
                    const res = await simpleQueryAPIData(
                        behaviour.data.url,
                        'POST',
                        dataToApi,
                        true
                    );
                    if (res.success) {
                        if (window.location.pathname.includes('/lista')) {
                            updateObjects({ saveLocationState: false, forceRefreshData: true });
                        } else {
                            refreshObjectScreenData(null);
                        }
                    }
                    dispatch(setSpinner(false));
                    if (behaviour?.data.showResult) {
                        new PopupManager({ dispatch, action: popup?.action }).setPopupInfo(
                            res.message
                        );
                    }
                    dispatch(closePopup(popup.key));
                }
                break;
        }
    };

    const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    return (
        <PopupTemplateFour
            maxHeight={heightAvailable > 650 ? 600 : heightAvailable - 50}
            maxWidth={widthAvailable > 700 ? 450 : widthAvailable - 20}>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                {popup.objectId.map((id: RowDataId) => {
                    return (
                        <div
                            key={id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <DynamicTitle id={id} popup={popup} />
                            <DynamicDecscription id={id} popup={popup} />
                            <DynamicFormItem
                                id={id}
                                control={control}
                                popupFormData={popup.popupFormData}
                            />
                        </div>
                    );
                })}
                <DynamicFormButton>{globalPopup.contentDynamic.button.yes}</DynamicFormButton>
            </form>
        </PopupTemplateFour>
    );
};
