import { StartupBlock } from 'src/store/src/startupScreen/startupScreen/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

export async function fetchStartupScreenDataForBlock(
    params: string
): Promise<BaseApiResponse<Required<StartupBlock['data']>>> {
    return simpleQueryAPIData(
        `api_react/src/startupScreen/getStartupScreenData.php?${params}`,
        'GET'
    );
}
