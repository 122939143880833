import { Box } from '@mui/material';
import { fontSizeTypeToRemNum } from 'src/constants/fontSizeTypeToRemNum';
import styled from 'styled-components';
import { FontSizeWrapperProps } from './types';

export const FontSizeWrapper = styled(Box)<FontSizeWrapperProps>(({ fontsizetype }) =>
    fontsizetype in fontSizeTypeToRemNum
        ? `font-size: ${fontSizeTypeToRemNum[fontsizetype]}rem;`
        : ''
);
