import { fetchPayoffImportInitData } from 'src/api/src/importPayoff/fetchPayoffImportInitData';
import { AppThunk } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { setInitData } from 'src/store/src/importPayoff/importPayoff/importPayoffSlice';

export const fetchAndLoadPayoffImportInitData = (): AppThunk => async (dispatch) => {
    const apiResponse = await fetchPayoffImportInitData();
    console.log(apiResponse);
    if (!apiResponse.success) {
        dispatch(setSpinner(false));
        window.location.replace(`/message?tytul=${apiResponse.message}`);
        throw new Error('fetchAndLoadInitialMessageData: not get InitialImportPayoffScreenData ');
    }
    dispatch(setInitData(apiResponse.data));
};
