import { RelatedObjectDataManager } from 'src/components/popupFormExtended/relationCreator/RelatedObjectDataManager';
import {
    DynamicFieldT,
    PopupFormExtendedData,
    PopupFormRelatedObject
} from 'src/data/popupFormTypes';

export function convertPopupFormExtendedDataFromApi(popupFormExtendedData?: PopupFormExtendedData) {
    if (popupFormExtendedData) {
        const fields = popupFormExtendedData.fields.map((item) => {
            return {
                ...item,
                isAddExtend: item.typ === 'dodanie_powiazania',
                placement: 'main' as const,
                id_sekcji: item.id_sekcji === '0' ? '177' : item.id_sekcji
            };
        });
        const data = {
            ...popupFormExtendedData,
            fields: [...fields, ...getFieldForAddExd(fields)],
            // fields: [...fields],
            relations: handleRelation(popupFormExtendedData.relations)
        };
        return data;
    }
}

function getFieldForAddExd(field: DynamicFieldT[]) {
    const fieldToAdd: DynamicFieldT[] = [];
    const fieldTypeAddExd = field.filter((item) => item.typ === 'dodanie_powiazania');

    fieldTypeAddExd.forEach((item) => {
        if (item.powiazanie) {
            const fields = item.powiazanie.fields.map((field) => {
                return {
                    ...field,
                    kod: item.kod + '-' + field.kod,
                    isAddExtend: true,
                    placement: 'main' as const
                };
            });
            fieldToAdd.push(...fields);
        }
    });
    return fieldToAdd;
}

function handleRelation(relations: PopupFormRelatedObject[]) {
    return relations.map((relation) => {
        if (
            Array.isArray(relation.objectsData) &&
            !relation.objectsData.length &&
            !relation.newObjectsData.length &&
            relation.wymuszenie_dodania
        ) {
            const { relatedObjectData } = new RelatedObjectDataManager({
                relation
            }).getData();
            return {
                ...relation,
                newObjectsData: [relatedObjectData]
            };
        }

        return relation;
    });
}
