import { UseFormGetValues } from 'react-hook-form';
import { FieldsStateRHF } from 'src/data/fieldsReactHookForm';
import { updateKeyReset } from 'src/utils/src/shared/updateKeyReset';

export function updateKeyResetRHF({
    getValues,
    key
}: {
    key: string;
    getValues: UseFormGetValues<FieldsStateRHF>;
}) {
    const stateBefore = getValues(key);
    return updateKeyReset(stateBefore?.keyReset);
}
