import { BaseApiResponse } from 'src/api/types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

export async function saveQrCodeSecret(
    userID: string,
    userType: string,
    secret: string
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData('api_react/src/qrCodeScreen/saveQrCodeSecret.php', 'POST', {
        userID,
        userType,
        secret
    });
}
