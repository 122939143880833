import { normaliseStringNumber } from 'src/utils/src/shared/normaliseStringNumber';

export function formatNumberSpan({
    number,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
}: {
    number: string | number;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}) {
    const numberToFormat =
        typeof number === 'string' ? Number(normaliseStringNumber(number)) : number;
    const numberFormatHandler = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits,
        maximumFractionDigits
    });
    return numberFormatHandler.format(numberToFormat);
}
