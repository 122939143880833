import React from 'react';
import WithTheme from './components/shared/templates/withTheme/WithTheme';
import WithStore from './components/shared/templates/withStore/WithStore';
import WithPickers from './components/shared/templates/withPickers/WithPickers';
import Router from './components/shared/pages/router/Router';
import HeadManager from './components/shared/templates/headManager/HeadManager';
import './styles/main.css';

const App = () => {
    return (
        <WithStore>
            <WithTheme>
                <WithPickers>
                    <Router />
                    <HeadManager />
                </WithPickers>
            </WithTheme>
        </WithStore>
    );
};

export default App;
