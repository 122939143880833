import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch } from 'src/store';
import { callPopup, closePopup, closePopupAll } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Typography } from '@mui/material';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import PopupTemplateFive from 'src/components/shared/organisms/popupTemplateFive/PopupTemplateFive';

export const PopupContentConfirmationClose: FC<BasePopupContentProps> = ({ popup }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const save = () => {
        dispatch(closePopup(popup.key));
        dispatch(callPopup({ key: popup.popupSuperiorKey, call: true }));
    };

    return (
        <PopupTemplateFive
            title={globalPopup.contentConfirmationClose.title}
            buttonLeftTxt={globalPopup.contentConfirmationClose.button.left}
            buttonCenterTxt={globalPopup.contentConfirmationClose.button.center}
            buttonRightTxt={globalPopup.contentConfirmationClose.button.right}
            buttonLeftOnClick={save}
            buttonCenterOnClick={() => dispatch(closePopupAll())}
            buttonRightOnClick={() => dispatch(closePopup(popup.key))}>
            <Typography
                sx={{
                    color: theme.palette.primary.main,
                    fontSize: '14px'
                }}>
                {globalPopup.contentConfirmationClose.description}
            </Typography>
        </PopupTemplateFive>
    );
};
