import { regexsGlobal } from 'src/constants/regex';

export function checkMailIsValid(value: string) {
    if (value?.length === 0) {
        return true;
    }

    const dividedMail = value.match(regexsGlobal.divedeEmail);
    if (!dividedMail) return false;
    if (dividedMail[1]) {
        const matchedFirstPart = dividedMail[1].match(regexsGlobal.validateFirstPartEmail);
        if (!matchedFirstPart) return false;
    }
    if (dividedMail[2]) {
        const matchedSecondPart = dividedMail[2].match(regexsGlobal.validateSecondPartEmail);
        if (!matchedSecondPart) {
            const matchedSecondPartTwo = dividedMail[2].match(
                regexsGlobal.validateSecondPartEmailTwo
            );
            if (!matchedSecondPartTwo) return false;
        }
        return true;
    }
    return false;
}
