import { useCallback } from 'react';
import { getPopupFormData } from 'src/api/src/shared/getPopupFormData';
import { AppDispatch, useAppDispatch } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { setPopupInfoTwo } from 'src/store/src/popup';

export const useLoadPopupFormFields = () => {
    const dispatch: AppDispatch = useAppDispatch();

    return useCallback(
        async (actionId: string) => {
            dispatch(setSpinner(true));
            const actionData = await getPopupFormData(actionId);

            if (!actionData.success) {
                dispatch(
                    setPopupInfoTwo({
                        message: actionData.message,
                        title: 'Nie można otworzyć formularza'
                    })
                );
                dispatch(setSpinner(false));
                return actionData;
            }

            return actionData;
        },
        [dispatch]
    );
};
