import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_OBJECTS_PER_PAGE } from 'src/constants';
import {
    TransformationsState,
    ListSearchFilters,
    ListSortProperty,
    ListPagingData,
    ListSearchColumnFilters,
    ListSearchFieldFilters
} from './types';

export const initialState: TransformationsState = {
    filters: {},
    sortingData: [],
    paging: {
        actualPage: 1,
        objectsPerPage: INITIAL_OBJECTS_PER_PAGE,
        totalRows: 0 // numbers of all data rows for actual filters, used to calculate how many
        // pages can be displayed
    }
};

const transformationsState = createSlice({
    name: 'listScreenTransformations',
    initialState,
    reducers: {
        // be aware that action don't update objects table, if you want to update table too, use
        // updateObjectsData
        setFilters: (state: TransformationsState, action: PayloadAction<ListSearchFilters>) => {
            state.filters = action.payload;
        },
        setFieldFilters: (
            state: TransformationsState,
            action: PayloadAction<ListSearchFieldFilters>
        ) => {
            for (const code in action.payload) {
                state.filters[code].fieldFilter = action.payload[code];
            }
        },
        setColumnFilters: (
            state: TransformationsState,
            action: PayloadAction<ListSearchColumnFilters>
        ) => {
            for (const code in action.payload) {
                state.filters[code].columnValueFilter = action.payload[code];
            }
        },
        setSorting: (state: TransformationsState, action: PayloadAction<ListSortProperty[]>) => {
            state.sortingData = action.payload;
        },
        setPaging: (state: TransformationsState, action: PayloadAction<ListPagingData>) => {
            state.paging = action.payload;
        },
        setActualPage: (state: TransformationsState, action: PayloadAction<number>) => {
            state.paging.actualPage = action.payload;
        },
        setObjectsPerPage: (state: TransformationsState, action: PayloadAction<number>) => {
            state.paging.objectsPerPage = action.payload;
        },
        setTotalRows: (state: TransformationsState, action: PayloadAction<number>) => {
            state.paging.totalRows = action.payload;
        }
    }
});

export const {
    setFilters,
    setColumnFilters,
    setSorting,
    setPaging,
    setActualPage,
    setObjectsPerPage,
    setTotalRows
} = transformationsState.actions;

export default transformationsState.reducer;
