import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STATIC_SEARCH_FIELDS } from 'src/constants';
import { SearchFieldsState } from './types';

export const initialState: SearchFieldsState = [];

const searchFieldsState = createSlice({
    name: 'listScreenSearchFields',
    initialState,
    reducers: {
        setSearchFields: (state: SearchFieldsState, action: PayloadAction<SearchFieldsState>) => {
            return [...action.payload, ...STATIC_SEARCH_FIELDS];
        }
    }
});

export const { setSearchFields } = searchFieldsState.actions;

export default searchFieldsState.reducer;
