import React, { FC } from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { InputBase } from '@mui/material';

import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import {
    AutocompleteClicedOption,
    UIFieldListForeignManyProps
} from 'src/components/popupFormExtended/dynamicField/fieldListForeignMany/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { AutocompleteOption } from 'src/data/uiTypes/autocompleteTypes';
import { useFieldClasses } from 'src/hooks';
import { useStyleInlineOne } from 'src/hooks/src/shared/useStyleInlineOne';

export const UIFieldListForeignMany: FC<UIFieldListForeignManyProps> = (props) => {
    const {
        value,
        onChange,
        uiFieldCommonProps,
        uiFieldSharedProps,
        onInputChange,
        options,
        textInputValue,
        autocompleteFilterOptions
    } = props;
    const { disabled } = uiFieldCommonProps;

    const classes = useFieldClasses({ muiType: 'autocompleteOne' });
    const inlineStyle = useStyleInlineOne({ ...uiFieldCommonProps });

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <Autocomplete
                sx={{ position: 'relative' }}
                multiple
                popupIcon={''}
                options={options}
                onChange={(
                    _,
                    data: AutocompleteOption[],
                    __,
                    clicedOption: AutocompleteClicedOption
                ) => onChange(data, clicedOption)}
                value={value.values}
                inputValue={textInputValue}
                disableCloseOnSelect={true}
                disablePortal={false}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.text}
                        </li>
                    );
                }}
                getOptionLabel={(option: AutocompleteOption) => option?.text}
                clearIcon={
                    value.values.length ? (
                        <ButtonCross
                            onClick={() => {}}
                            style={{
                                top: '2px'
                            }}
                        />
                    ) : (
                        <></>
                    )
                }
                classes={{
                    option: classes.option,
                    listbox: classes.listbox,
                    paper: classes.paper,
                    popper: classes.popper
                }}
                disabled={disabled}
                renderInput={(params: AutocompleteRenderInputParams) => {
                    return (
                        <InputBase
                            {...params}
                            {...params.InputProps}
                            inputProps={{
                                ...params.inputProps,
                                ...uiFieldSharedProps.inputProps
                            }}
                            onChange={(e) => onInputChange(e.target.value)}
                            sx={{
                                ...inlineStyle,
                                borderRadius: '4px',
                                paddingLeft: '10px',
                                height: 'auto'
                            }}
                        />
                    );
                }}
                filterOptions={autocompleteFilterOptions}
            />
        </UiWrapper>
    );
};
