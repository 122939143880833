import React, { FC } from 'react';

import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { UtilityBarOptionViewProps } from './types';
import { UtilityBarOptionUi } from 'src/components/listScreen/molecules/utilityBarOptionUi/UtilityBarOptionUi';

const UtilitiesBarOptionView: FC<UtilityBarOptionViewProps> = ({ name, icon }) => {
    return (
        <UtilityBarOptionUi name={name}>
            <ThemeCompatibleIcon
                iconData={icon}
                fontSize={'medium'}
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            />
        </UtilityBarOptionUi>
    );
};

export default UtilitiesBarOptionView;
