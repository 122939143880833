import { TransformationsState } from 'src/store/src/listScreen/transformations/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { StatusTableAPIData } from './types';

/**
 * Fetches information about specific object data
 */
export async function getStatusTableViewData(
    screen: string,
    transformationsState: TransformationsState
): Promise<BaseApiResponse<StatusTableAPIData>> {
    const params = new URLSearchParams([
        ['screen', screen],
        ['page', transformationsState.paging.actualPage.toString()],
        ['objectsPerPage', transformationsState.paging.objectsPerPage.toString()]
    ]);
    const body = {
        filters: transformationsState.filters,
        sortingData: transformationsState.sortingData
    };
    return simpleQueryAPIData(
        `api_react/src/listScreen/getStatusTableViewData.php?${params.toString()}`,
        'POST',
        body
    );
    // return {
    //     message: 'Zdobyto dane',
    //     success: true,
    //     errorCode: null,
    //     data: {
    //         totalRows: 165,
    //         statusFieldCode: 'status',
    //         dislikeStatus: {
    //             id: 5,
    //             text: 'Stracono'
    //         },
    //         likeStatus: {
    //             id: 6,
    //             text: 'Sprzedano'
    //         },
    //         statuses: [
    //             {
    //                 id: 1,
    //                 text: 'Nowy'
    //             },
    //             {
    //                 id: 2,
    //                 text: 'Skontaktowany'
    //             },
    //             {
    //                 id: 3,
    //                 text: 'W trakcie rozmów'
    //             },
    //             {
    //                 id: 4,
    //                 text: 'Negocjacje'
    //             }
    //         ],
    //         objects: [
    //             {
    //                 id: 20,
    //                 status: 1,
    //                 headerText: 'Kupno wapna',
    //                 footerText: '02.09.2022',
    //                 fields: [
    //                     {
    //                         value: 'Tomasz Jan',
    //                         title: 'Klient',
    //                         link: {
    //                             url: 'ekran_obiektu.php',
    //                             params: 'id=20&obiekt=osoby'
    //                         }
    //                     },
    //                     {
    //                         value: 'Tomasz Jan',
    //                         title: 'Ososba kotaktowa',
    //                         link: {
    //                             url: 'ekran_obiektu.php',
    //                             params: 'id=20&obiekt=osoby'
    //                         }
    //                     },
    //                     {
    //                         value: '001 002 003',
    //                         icon: {
    //                             svgFileName: null,
    //                             faCode: 'fa-commenting'
    //                         }
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 21,
    //                 status: 1,
    //                 headerText: 'Przetarg targowy',
    //                 footerText: '01.09.2022',
    //                 fields: [
    //                     {
    //                         value: 'Tomasz Man',
    //                         title: 'Klient',
    //                         link: {
    //                             url: 'ekran_obiektu.php',
    //                             params: 'id=21&obiekt=osoby'
    //                         }
    //                     },
    //                     {
    //                         value: 'Jan Man',
    //                         title: 'Ososba kotaktowa',
    //                         link: {
    //                             url: 'ekran_obiektu.php',
    //                             params: 'id=20&obiekt=osoby'
    //                         }
    //                     },
    //                     {
    //                         value: '001 002 004',
    //                         icon: {
    //                             svgFileName: 'komunikacja.svg',
    //                             faCode: 'fa-commenting'
    //                         }
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 22,
    //                 status: 2,
    //                 headerText: 'Przetarg targowy2',
    //                 footerText: '01.09.2022',
    //                 fields: [
    //                     {
    //                         value: 'Tomasz Man',
    //                         title: 'Klient',
    //                         link: {
    //                             url: 'ekran_obiektu.php',
    //                             params: 'id=21&obiekt=osoby'
    //                         }
    //                     },
    //                     {
    //                         value: 'Jan Man',
    //                         title: 'Ososba kotaktowa',
    //                         link: {
    //                             url: 'ekran_obiektu.php',
    //                             params: 'id=20&obiekt=osoby'
    //                         }
    //                     },
    //                     {
    //                         value: '001 002 004',
    //                         icon: {
    //                             svgFileName: 'komunikacja.svg',
    //                             faCode: 'fa-commenting'
    //                         }
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 23,
    //                 status: 4,
    //                 headerText: 'Przetarg budowy',
    //                 footerText: '31.08.2021',
    //                 fields: [
    //                     {
    //                         value: 'Tomasz Man',
    //                         title: 'Klient',
    //                         link: {
    //                             url: 'ekran_obiektu.php',
    //                             params: 'id=21&obiekt=osoby'
    //                         }
    //                     },
    //                     {
    //                         value: 'Jan Man',
    //                         title: 'Ososba kotaktowa',
    //                         link: {
    //                             url: 'ekran_obiektu.php',
    //                             params: 'id=20&obiekt=osoby'
    //                         }
    //                     },
    //                     {
    //                         value: '001 002 004',
    //                         icon: {
    //                             svgFileName: 'komunikacja.svg',
    //                             faCode: 'fa-commenting'
    //                         }
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // };
}
