import { RefObject, useLayoutEffect, useRef, useState } from 'react';

/**
 * Hook uses Resize Observer to watch passed element width and height
 */
export function useObserveElementSize<T extends HTMLElement>(ref: RefObject<T>) {
    const { current } = ref;
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const observer = useRef<ResizeObserver>(
        new ResizeObserver((entries) => {
            setHeight(entries[0].contentRect.height);
            setWidth(entries[0].contentRect.width);
        })
    );

    useLayoutEffect(() => {
        if (current) {
            observer.current.observe(current);
        }
        return () => {
            observer.current.disconnect();
        };
    }, [current]);

    return { height, width };
}
