import { DynamicFieldT } from 'src/data/popupFormTypes';
import { regexsGlobal } from 'src/constants/regex';

type InputValidatorAction =
    | 'runSize'
    | 'runOnlyNumberAndDotCommnaMinus'
    | 'runOnlyNumberAndDotCommna'
    | 'runOnlyNumbersAndHyphen'
    | 'runOnlyNumbers';

type InputValidatorProps = {
    dynamicFieldT: DynamicFieldT;
    staticActions: InputValidatorAction[];
    oldValue: string | null;
    newValue: string;
};

export class InputValidator {
    dynamicFieldT: DynamicFieldT;
    staticActions: InputValidatorAction[];
    oldValue: string | null;
    newValue: string;
    isValid: boolean;
    constructor({ dynamicFieldT, staticActions, oldValue, newValue }: InputValidatorProps) {
        this.dynamicFieldT = dynamicFieldT;
        this.staticActions = staticActions;
        this.oldValue = oldValue;
        this.newValue = newValue;
        this.isValid = true;
    }

    execute() {
        if (!this.newValue.length) {
            return this.newValue;
        }
        this.staticActions.forEach((fn) => this[fn]());
        return this.isValid ? this.newValue : this.oldValue;
    }

    private runSize() {
        switch (this.dynamicFieldT.typ) {
            case 'nip':
            case 'tekst':
            case 'haslo_otwarte':
                if (this.dynamicFieldT.rozmiar) {
                    const val = this.dynamicFieldT.rozmiar.split(',');
                    if (this.newValue.length > Number(val[0])) {
                        this.isValid = false;
                    }
                }
                break;
            case 'liczba':
                {
                    const value = this.newValue.replace('-', '');
                    if (this.dynamicFieldT.rozmiar) {
                        const val = this.dynamicFieldT.rozmiar.split(',');
                        if (value.length > Number(val[0])) {
                            this.isValid = false;
                        }
                    }
                }
                break;
            default:
                break;
        }
    }
    private runOnlyNumberAndDotCommnaMinus() {
        switch (this.dynamicFieldT.typ) {
            case 'liczba':
                if (!regexsGlobal.onlyNumbersAndDotsCommaMinus.test(this.newValue)) {
                    this.isValid = false;
                }
                break;
            default:
                break;
        }
    }
    private runOnlyNumberAndDotCommna() {
        switch (this.dynamicFieldT.typ) {
            case 'liczba':
                if (!regexsGlobal.onlyNumbersAndDotsComma.test(this.newValue)) {
                    this.isValid = false;
                }
                break;
            default:
                break;
        }
    }
    private runOnlyNumbersAndHyphen() {
        switch (this.dynamicFieldT.typ) {
            case 'nip':
                if (!regexsGlobal.onlyNumbersAndHyphen.test(this.newValue)) {
                    this.isValid = false;
                }
                break;
            default:
                break;
        }
    }
    private runOnlyNumbers() {
        switch (this.dynamicFieldT.typ) {
            case 'pesel':
                if (!regexsGlobal.onlyNumbers.test(this.newValue)) {
                    this.isValid = false;
                }
                break;
            default:
                break;
        }
    }
}
