import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useTypedSelector } from 'src/store';
import { PopupFormExtendedProps } from 'src/components/shared/molecules/actionType/popupFormExtended/types';
import { TemplateMainManager } from 'src/utils/src/shared/TemplateMainManager';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { useOpenPopupFormExtended } from 'src/hooks';
import { SystemAction } from 'src/data/types';
import { ProcesedPopupFormExtendedObjectId } from 'src/data/popupFormTypes';
import { generateToken } from 'src/utils';
import { EventBus } from 'src/utils/src/shared/EventBus';

const PopupFormExtended: FC<PopupFormExtendedProps> = ({
    children,
    action,
    rowId,
    clickCallback
}) => {
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const objectSlice = useTypedSelector((state) => state.object);
    const additional = { sectionId: '', sectionIdx: 0, typeSection: null };
    const sourceOfTrigger: SourceOfTrigger = { type: 'list' };
    const location = useLocation();

    const openPopupformExtended = useOpenPopupFormExtended();

    const handleSetPopupForm = async () => {
        if (action.behaviour.type !== 'popupFormExtended') {
            console.error('PopupFormExtended: action type is not valid');
            return;
        }
        const { str } = new TemplateMainManager({
            rowId,
            rowData,
            transformationState,
            actionBehavior: action.behaviour,
            action,
            objectSlice,
            sourceOfTrigger,
            additional,
            str: action.behaviour.data.objectId
        }).getData();

        const actionNew: SystemAction = {
            ...action,
            behaviour: {
                ...action.behaviour,
                data: {
                    ...action.behaviour.data,
                    objectId: str as ProcesedPopupFormExtendedObjectId
                }
            }
        };

        const closeCallbackEventId = `popupformExtend-${generateToken()}`;
        const submitCallbackEventId = `popupformExtendSubmit-${generateToken()}`;

        openPopupformExtended(actionNew, closeCallbackEventId, submitCallbackEventId);
        if (location.pathname === '/budzet') {
            return new Promise<boolean | null>((res, _) => {
                EventBus.$on(submitCallbackEventId, () => {
                    EventBus.$off(submitCallbackEventId);
                    clickCallback && clickCallback();
                });
            });
        } else {
            clickCallback && clickCallback();
        }
    };

    return <div onClick={() => handleSetPopupForm()}>{children}</div>;
};

export default PopupFormExtended;
