import React, { FC } from 'react';

import { Button, Card, CardContent, Typography, Avatar, Container } from '@mui/material';
import { QrCodeViewProps } from './types';
import { LoaderWewTwo } from 'src/components/shared/organisms/loader/LoaderWewTwo';

const QrCodeView: FC<QrCodeViewProps> = ({ base64QrCode, saveCallback }) => {
    return (
        <Container component="main" maxWidth="sm" sx={{ ml: 0 }}>
            <Card sx={{ mt: 8, ml: 8 }}>
                <CardContent
                    sx={{
                        m: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Typography component="h1" variant="h5" sx={{ mb: 2 }} fontSize="large">
                        Zeskanuj poniższy kod QR w aplikacji:
                    </Typography>

                    {/* Displaying the Base64 encoded QR code as an image */}
                    {base64QrCode ? (
                        <Avatar
                            variant="square"
                            src={`data:image/svg+xml;base64,${base64QrCode}`}
                            alt="QR Code"
                            sx={{ width: '100%', height: 'auto', mb: 2 }}
                        />
                    ) : (
                        <LoaderWewTwo />
                    )}

                    <Button
                        variant="contained"
                        disabled={!base64QrCode}
                        color="primary"
                        fullWidth
                        onClick={saveCallback}
                        sx={{ mt: 3 }}>
                        Potwierdzam zeskanowanie kodu
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default QrCodeView;
