import { ColorFromApi } from 'src/data/types';
import PinGrayIcon from 'src/assets/pinezka_szara1.svg';
import PinClaretIcon from 'src/assets/pinezka_bordowa.svg';
import PinWhiteIcon from 'src/assets/pinezka_biala.svg';

export class UI {
    static chooseColor(color: ColorFromApi) {
        return color ?? '#ffffff';
    }
    static chooseIconForPinned(
        statusIconPinned: 'isPinned' | 'isActive' | 'isDisabled' | undefined
    ) {
        if (statusIconPinned === 'isPinned') {
            return PinWhiteIcon;
        } else if (statusIconPinned === 'isDisabled') {
            return PinGrayIcon;
        } else {
            return PinClaretIcon;
        }
    }
}
