import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TreeTableState, TreeSetData, TreeNodeUbdated, TreeUbdateData } from './types';

export const zeroLevel = {
    groupX: [],
    divideSpace: []
};

export const firstLevel = {
    rectHeight: [],
    rectWidth: [],
    boxWidth: [],
    groupY: 0,
    groupX: [],
    bottomY: [],
    centralBottomX: [],
    centralBettweenFirstSecondY: [],
    listIfHaveTitle: []
};

export const secondLevel = {
    groupY: [],
    groupX: [],
    centralTopX: []
};

export const initialState: TreeTableState = {
    treeNode: [],
    treeNodeUbdated: [],
    setting: {
        width: 900,
        allLevelIsUnfolded: false,
        typeNodeIsBlockArr: [],
        zeroLevel: {
            ...zeroLevel
        },
        firstLevel: {
            ...firstLevel
        },
        secondLevel: {
            ...secondLevel
        },
        view: 'ALL',
        revalidateNotChecked: true
    }
};

const treeTableState = createSlice({
    name: 'listTreeTableData',
    initialState,
    reducers: {
        setData: (state: TreeTableState, action: PayloadAction<TreeSetData>) => {
            state.treeNode = action.payload.treeNode;
            state.treeNodeUbdated = action.payload.treeNodeUbdated;
            state.setting = action.payload.setting;
        },
        ubdateData: (state: TreeTableState, action: PayloadAction<TreeUbdateData>) => {
            state.treeNodeUbdated = action.payload.treeNodeUbdated;
            state.setting = action.payload.setting;
        },
        ubdateTreeNode: (state: TreeTableState, action: PayloadAction<TreeNodeUbdated[]>) => {
            state.treeNodeUbdated = action.payload;
        },
        clearTreeTableData: (state: TreeTableState) => {
            state.treeNode = [];
            state.treeNodeUbdated = [];
            state.setting = {
                width: 900,
                allLevelIsUnfolded: false,
                typeNodeIsBlockArr: [],
                zeroLevel: {
                    ...zeroLevel
                },
                firstLevel: {
                    ...firstLevel
                },
                secondLevel: {
                    ...secondLevel
                },
                view: 'ALL',
                revalidateNotChecked: true
            };
        }
    }
});

export const { setData, ubdateData, ubdateTreeNode, clearTreeTableData } = treeTableState.actions;

export default treeTableState.reducer;
