import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import {
    DynamicFormObjectPESELValidatorProps,
    TypeValidatePESEL
} from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectPESELValidator/types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { FieldPesel } from 'src/components/popupFormExtended/dynamicField/fieldPesel/types';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validatePESEL';
export class DynamicFormObjectPESELValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidatePESEL;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectPESELValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        for (const key in this.formState.fields) {
            if (this.dataRef.validationResult.isFormValid) {
                const dynamicFieldT = this.popupFormExtendedData.fields.find(
                    (item) => item.kod === this.formState.fields[key]?.kod
                );
                const field = this.formState.fields[key];
                if (
                    dynamicFieldT &&
                    field &&
                    field?.typ === 'pesel' &&
                    dynamicFieldT.wylaczenie_weryfikacji_typu_danych !== 't'
                ) {
                    await this.runValidationOne(field, key);
                }
            } else {
                break;
            }
        }
    }
    private async runValidationOne(field: FieldPesel, key: string) {
        const value = typeof field.value === 'string' ? field.value : '';
        const isValid = await this.checkIsValid(value);
        if (!isValid) {
            this.setValidationResult({
                typeOfInfo: 'Warning',
                type: {
                    issue: this.typeFnValidate,
                    message: `W polu ${field.name} znajduje się nieprawidłowy PESEL. Czy chcesz kontynuować?`,
                    title: validationData[this.typeFnValidate].title,
                    id_zakladki: field.id_zakladki,
                    fieldCode: field.kod
                }
            });
        }
    }
    private async checkIsValid(value: string) {
        const pesel = value.replace(/[^0-9]+/g, '');
        const scale = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
        if (pesel.length === 11) {
            let z = 0;
            for (var i = 0; i < 10; i++) {
                const n = Number(pesel[i]);
                z += n * scale[i];
            }
            if (z % 10 === Number(pesel[10])) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
