import React, { FC, useCallback } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import {
    FieldAdressAutoCompleteOptions,
    FieldAdressObjectProps,
    FieldAdressPostCode,
    FieldAdresssCity
} from './types';
import { UIFieldAdress } from 'src/components/popupFormExtended/dynamicField/fieldAdress/UIFieldAdress';
import { useLoadAddressOptions } from 'src/hooks';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import {
    cityMock,
    postCodeMock
} from 'src/components/popupFormExtended/dynamicField/fieldAdress/mockData';
import { dataSetInputKey } from 'src/constants/dataset';
import { consoleConditionally } from 'src/utils';
import { FieldAdressBasicTypeValue } from 'src/data/fieldsFormType';

export const FieldAdressObject: FC<FieldAdressObjectProps> = ({
    dynamicFieldT,
    field,
    uiFieldCommonProps,
    dispatch,
    keyOfFieldInState
}) => {
    const [state, setState] = React.useState(field.value);
    const [autocompleteOptions, setAutocompleteOptions] =
        React.useState<FieldAdressAutoCompleteOptions>({
            miasto: [],
            kod: []
        });
    consoleConditionally(state, 'fieldAdress');
    const [valueCity, setValueCity] = React.useState<FieldAdresssCity>({
        ...cityMock,
        text: field.value.miasto
    });
    const [valueCodePost, setValueCodePost] = React.useState<FieldAdressPostCode>({
        ...postCodeMock,
        text: field.value.kod,
        code: field.value.kod,
        codeFull: field.value.kod
    });

    const dispatchToState = useCallback(
        (values: { [key: string]: any }) => {
            const newValue = {
                ...field,
                value: {
                    ...field.value,
                    ...values
                }
            };
            dispatch({
                type: 'adres',
                payload: {
                    code: keyOfFieldInState,
                    value: newValue
                }
            });
        },
        [dispatch, field, keyOfFieldInState]
    );

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        onBlur: boolean,
        element?: keyof FieldAdressBasicTypeValue
    ) => {
        const stateCopy = { ...state };
        if (element) {
            stateCopy[element] = e.target.value;
            setState(stateCopy);
        }

        if (onBlur) {
            dispatchToState(stateCopy);
        }
    };

    const onAcceptValue = useCallback(
        (element: keyof FieldAdressBasicTypeValue) => {
            if (element === 'miasto') {
                setValueCity({
                    name: field.valueToAccept[element],
                    fullName: field.valueToAccept[element],
                    text: field.valueToAccept[element],
                    id: ''
                });
            }
            const stateCopy = { ...state };
            stateCopy[element] = field.valueToAccept[element];
            const fieldCopy = { ...field };
            delete fieldCopy.valueToAccept[element];
            delete fieldCopy.valueToAcceptText[element];
            setState(stateCopy);
            dispatchToState(stateCopy);
        },
        [dispatchToState, field, state]
    );

    const onChangeAutocompleteCity = async (
        _: React.SyntheticEvent<Element, Event>,
        city: FieldAdresssCity
    ) => {
        await getOptionsForPostCode({ city: city.fullName, cityId: city.id });
        const newCity = {
            ...city,
            text: city.fullName
        };
        setValueCity(newCity);

        dispatchToState({ miasto: city.name });
    };

    const onChangeAutocompletePostCode = (
        _: React.SyntheticEvent<Element, Event>,
        adressFromApi: FieldAdressPostCode
    ) => {
        const adress = {
            ...adressFromApi,
            text: adressFromApi.code,
            ulica: adressFromApi.ulica?.length ? adressFromApi.ulica : state.ulica
        };

        setValueCodePost(adress);
        dispatchToState({
            kod: adress.code,
            gmina: adress.gmina,
            ulica: adress.ulica,
            powiat: adress.powiat,
            poczta: adress.gmina,
            wojewodztwo: adress.wojewodztwo
        });
    };

    const { voivodships, countries } = useLoadAddressOptions();

    const getOptionsFromApi = async (value: string) => {
        if (value != null && value.length > 2) {
            const resp = await getFieldAdditionalData({
                url: 'json_adresy_miasta.php',
                params: { q: value }
            });
            if (resp.data) {
                setAutocompleteOptions((prev) => ({
                    ...prev,
                    miasto: resp.data.map((item: FieldAdresssCity) => {
                        return {
                            ...item,
                            text: item.fullName
                        };
                    })
                }));
                await getOptionsForPostCode({ city: value });
            }
        } else {
            setAutocompleteOptions({ miasto: [], kod: [] });
        }
    };

    const getOptionsForPostCode = async ({ city, cityId }: { city: string; cityId?: string }) => {
        const params: { [key: string]: string } = { miasto: city, ulica: field.value.ulica };
        if (cityId) {
            params.id_miasta = cityId;
        }
        const resp = await getFieldAdditionalData({
            url: 'json_kody_pocztowe.php',
            params
        });
        if (resp.data) {
            setAutocompleteOptions((prev) => ({
                ...prev,
                kod: resp.data.map((item: FieldAdressPostCode) => {
                    return {
                        ...item,
                        text: item.codeFull
                    };
                })
            }));
        }
    };

    React.useEffect(() => {
        if (JSON.stringify(field.value) !== JSON.stringify(state)) {
            setState(field.value);
            if (field.value.miasto !== valueCity.name) {
                setValueCity({
                    id: '',
                    fullName: field.value.miasto,
                    name: field.value.miasto,
                    text: field.value.miasto
                });
            }
            if (field.value.kod !== valueCodePost.text) {
                setValueCodePost((prev) => {
                    return {
                        ...prev,
                        text: field.value.kod,
                        code: field.value.kod,
                        codeFull: field.value.kod
                    };
                });
            }
        }
    }, [field.value]);

    return (
        <UIFieldAdress
            valueCity={valueCity}
            valuePostCode={valueCodePost}
            value={state}
            onChange={(e, element) => onChange(e, false, element)}
            onChangeSelect={(e, element) => onChange(e, true, element)}
            onBlur={(e) => onChange(e, true)}
            onAcceptValue={onAcceptValue}
            voivodships={voivodships}
            countries={countries}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            onClearCityAutocomplete={() => {
                setValueCity(cityMock);
                setAutocompleteOptions({
                    miasto: [],
                    kod: []
                });
                dispatchToState({
                    miasto: ''
                });
            }}
            onClearPostCodeAutocomplete={() => {
                setValueCodePost(postCodeMock);
                dispatchToState({
                    kod: ''
                });
            }}
            optionsAutocomplete={autocompleteOptions}
            onChangeCityAutocomplete={onChangeAutocompleteCity}
            onChangePostCodeAutocomplete={onChangeAutocompletePostCode}
            onInputChangeCityAutocomplete={(_, value: string, reason) => {
                setValueCity({
                    ...cityMock,
                    name: value,
                    fullName: value,
                    text: value
                });
                if (reason === 'input') {
                    getOptionsFromApi(value);
                }
            }}
            onBlurCity={() => {
                dispatchToState({
                    miasto: valueCity.name
                });
            }}
            onBlurPostCode={() => {
                dispatchToState({
                    kod: valueCodePost.code
                });
            }}
            onInputChangePostCodeAutocomplete={(_, value: string) => {
                setValueCodePost({
                    ...postCodeMock,
                    code: value,
                    codeFull: value,
                    text: value
                });
            }}
        />
    );
};
