import { RelationTreeCardElement } from 'src/store/src/object/object/types';

export function getCardTreeElementsFileIds(
    elements: RelationTreeCardElement[],
    idElementTop: string
): string {
    return elements.reduce((accumulator, currentValue) => {
        if (!currentValue.fileId) return accumulator;
        if (idElementTop === currentValue.fileId) return accumulator;
        if (accumulator) {
            return accumulator + ',' + currentValue.fileId;
        } else return currentValue.fileId;
    }, idElementTop);
}
