import React from 'react';

import { FieldDependentSetterRHF } from 'src/components/form/molecules/fieldDependent/FieldDependentSetterRHF';
import {
    FieldDependentRowRHF,
    FieldDepenedentFormStateHandlersRHF
} from 'src/components/form/molecules/fieldDependent/types';
import { FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import { DynamicFieldT } from 'src/data/popupFormTypes';

import { useTypedSelector } from 'src/store';

type RunDependentMassEditObjectsItemProps = {
    fieldValue: FieldsStateUnionRHF;
    keyValue: string;
    formStateHandlers: FieldDepenedentFormStateHandlersRHF;
    dynamicFieldT: DynamicFieldT;
    row: FieldDependentRowRHF;
};

export const useDependentMassEditObjectsItem = () => {
    const massEditObjects = useTypedSelector((state) => state.massEditObjects);

    async function runDependentMassEditObjectsItem({
        fieldValue,
        keyValue,
        formStateHandlers,
        row,
        dynamicFieldT
    }: RunDependentMassEditObjectsItemProps) {
        await new FieldDependentSetterRHF({
            formStateHandlers,
            tempState: {
                [keyValue]: {
                    ...fieldValue,
                    isError: false
                }
            },
            dynamicFieldsT: massEditObjects.columnField,
            staticData: {
                type: 'massEditObjects',
                rows: massEditObjects.row
            }
        }).onChangeItem({
            row,
            dynamicFieldT
        });
    }

    return {
        runDependentMassEditObjectsItem
    };
};
