import React from 'react';

import { PasswordRequirementsCheckResult } from 'src/components/shared/molecules/popupContentChangePassword/PasswordRequirementsChecker';
import { DynamicFieldT } from 'src/data/popupFormTypes';

export const useRequirementsEnabled = (field: DynamicFieldT) => {
    const requirementsEnabledData = React.useMemo(() => {
        const passwordFieldRequirements: PasswordRequirementsCheckResult = {
            hasla_blokuj_popularne: field.hasla_blokuj_popularne === 't',
            hasla_cyfry: field.hasla_cyfry === 't',
            hasla_duze_litery: field.hasla_duze_litery === 't',
            hasla_male_litery: field.hasla_male_litery === 't',
            hasla_znaki_specjalne: field.hasla_znaki_specjalne === 't',
            hasla_dlugosc: parseInt(field.hasla_dlugosc ?? '') > 0,
            hasla_dlugosc_maksymalna: parseInt(field.hasla_dlugosc_maksymalna ?? '') > 0
        };

        return {
            fields: passwordFieldRequirements,
            anyPresent: Object.values(passwordFieldRequirements).some((el) => el)
        };
    }, [field]);

    return requirementsEnabledData;
};
