import { DynamicFieldT, PopupFormRelatedObject } from 'src/data/popupFormTypes';

export function assignSumKey({
    field,
    relation
}: {
    field: DynamicFieldT;
    relation?: PopupFormRelatedObject;
}) {
    if (relation) {
        switch (field.typ) {
            case 'cena':
            case 'cena_liczba':
            case 'liczba':
            case 'koszty':
                return `sumowanie_gorne_${relation.id}_${field.kod}_do_porownan`;
            default:
                break;
        }
    }
}
