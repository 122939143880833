import { CalendarType } from '../../../store/src/calendar/calendar/types';
import { CategoriesResp } from '../../types';
import { queryServerData } from './queryServerData';

/**
 * Function fetches and avialable and selected categories categories of displayable calendar events
 */
export async function fetchCategories({
    view,
    choosenSelectedCategories,
    entityID
}: {
    view: CalendarType;
    entityID?: number;
    choosenSelectedCategories?: string[];
}): Promise<CategoriesResp> {
    return queryServerData<CategoriesResp>('api_react/calendar.php', {
        type: 'categories',
        view,
        entity_id: entityID,
        choosenSelectedCategories
    });
}
