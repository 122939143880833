/**
 * Add dom element to parent and wait for element to load
 * @param {*} domElement
 * @param {*} elementParent
 * @returns
 */
export const waitForElementLoad = (domElement: HTMLElement) =>
    new Promise((resolve, _) => {
        domElement.onload = resolve;
        domElement.onerror = resolve;
    });
