import React, { Reducer } from 'react';

export type SearchState = {
    [code: string]: {
        fieldFilter: {
            orMode: boolean;
            value: any;
        };
    };
};

export enum ActionKind {
    SET_FILTER = 'SET_FILTER',
    SET_ORMODE = 'SET_ORMODE',
    SET_VALUE_ONE = 'SET_VALUE_ONE',
    SET_VALUE_TWO = 'SET_VALUE_TWO',
    SET_VALUE_THREE = 'SET_VALUE_THREE',
    SET_VALUE_FOUR = 'SET_VALUE_FOUR',
    SET_VALUE_FIVE = 'SET_VALUE_FIVE',
    SET_VALUE_FIVE_PRESET = 'SET_VALUE_FIVE_PRESET',
    SET_VALUE_SIX = 'SET_VALUE_SIX',
    SET_VALUE_SEVEN = 'SET_VALUE_SEVEN',
    SET_VALUE_EIGHT = 'SET_VALUE_EIGHT',
    SET_VALUE_NINE = 'SET_VALUE_NINE'
}

type ActionSetFilter = {
    type: 'SET_FILTER';
    payload: SearchState;
};

type ActionSetOrmode = {
    type: 'SET_ORMODE';
    payload: {
        code: string;
        ormode: boolean;
    };
};

export type ValueOne = {
    id: string | null;
    screen: string | null;
    text: string;
};

export type ValueTwo = number[];

type ActionSetValueOne = {
    type: 'SET_VALUE_ONE';
    payload: {
        code: string;
        value: ValueOne | null;
    };
};

type ActionSetValueTwo = {
    type: 'SET_VALUE_TWO';
    payload: {
        code: string;
        // value: number;
        value: number[];
    };
};

type ActionSetValueThree = {
    type: 'SET_VALUE_THREE';
    payload: {
        code: string;
        value: number | null;
    };
};

type ActionSetValueFour = {
    type: 'SET_VALUE_FOUR';
    payload: {
        code: string;
        value: {
            data: string;
            loadToday: boolean;
        } | null;
    };
};

type ActionSetValueFive = {
    type: 'SET_VALUE_FIVE';
    payload: {
        code: string;
        index: number;
        value: {
            data: string;
            loadToday: boolean;
        } | null;
    };
};
type ActionSetValueFivePreset = {
    type: 'SET_VALUE_FIVE_PRESET';
    payload: {
        code: string;
        value: number | null;
    };
};

type ActionSetValueSix = {
    type: 'SET_VALUE_SIX';
    payload: {
        code: string;
        value: string | null;
    };
};

type ActionSetValueSeven = {
    type: 'SET_VALUE_SEVEN';
    payload: {
        code: string;
        index: number;
        value: number | null;
    };
};

type ActionSetValueEight = {
    type: 'SET_VALUE_EIGHT';
    payload: {
        code: string;
        value: {
            number: number | string;
            unit: string;
        } | null;
    };
};

type ActionSetValueNine = {
    type: 'SET_VALUE_NINE';
    payload: {
        code: string;
        index: number;
        value: number | null;
    };
};

export type Actions =
    | ActionSetFilter
    | ActionSetOrmode
    | ActionSetValueOne
    | ActionSetValueTwo
    | ActionSetValueThree
    | ActionSetValueFour
    | ActionSetValueFive
    | ActionSetValueFivePreset
    | ActionSetValueSix
    | ActionSetValueSeven
    | ActionSetValueEight
    | ActionSetValueNine;

export const initialState = {};

export const reducer: Reducer<SearchState, Actions> = (state, action) => {
    switch (action.type) {
        case ActionKind.SET_FILTER:
            return {
                ...state,
                ...action.payload
            };
        case ActionKind.SET_ORMODE:
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        value: state[action.payload.code]?.fieldFilter?.value,
                        orMode: action.payload.ormode
                    }
                }
            };
        case ActionKind.SET_VALUE_ONE:
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: action.payload.value
                    }
                }
            };
        case ActionKind.SET_VALUE_TWO: {
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: action.payload.value
                    }
                }
            };
        }
        case ActionKind.SET_VALUE_THREE: {
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: action.payload.value
                    }
                }
            };
        }
        case ActionKind.SET_VALUE_FOUR: {
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: action.payload.value
                    }
                }
            };
        }
        case ActionKind.SET_VALUE_FIVE: {
            let value = state[action.payload.code]?.fieldFilter?.value
                ? {
                      ...state[action.payload.code]?.fieldFilter?.value,
                      dates: [...state[action.payload.code]?.fieldFilter?.value?.dates]
                  }
                : { preset: 0, dates: [null, null] };
            console.log(value, action.payload.value);
            value.dates[action.payload.index] = action.payload.value;
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code].fieldFilter.orMode,
                        value: value
                    }
                }
            };
        }
        case ActionKind.SET_VALUE_FIVE_PRESET: {
            let value = state[action.payload.code]?.fieldFilter?.value
                ? { ...state[action.payload.code]?.fieldFilter?.value }
                : { preset: 0, dates: [null, null] };
            value.preset = action.payload.value;
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: value
                    }
                }
            };
        }
        case ActionKind.SET_VALUE_SIX: {
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: action.payload.value ? action.payload.value : null
                    }
                }
            };
        }
        case ActionKind.SET_VALUE_SEVEN: {
            let value = state[action.payload.code]?.fieldFilter?.value
                ? [...state[action.payload.code]?.fieldFilter?.value]
                : [];
            value[action.payload.index] = action.payload.value;
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: value
                    }
                }
            };
        }
        case ActionKind.SET_VALUE_EIGHT: {
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: action.payload.value
                    }
                }
            };
        }
        case ActionKind.SET_VALUE_NINE: {
            let value = state[action.payload.code]?.fieldFilter?.value
                ? [...state[action.payload.code]?.fieldFilter?.value]
                : [null, null, null, null, null, null];
            value[action.payload.index] = action.payload.value;
            const filteredVal = value.filter((item) => item !== null);
            return {
                ...state,
                [action.payload.code]: {
                    fieldFilter: {
                        orMode: state[action.payload.code]?.fieldFilter?.orMode ?? false,
                        value: filteredVal.length ? value : null
                    }
                }
            };
        }
        default:
            return {
                ...state
            };
    }
};
