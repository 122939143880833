import React, { FC } from 'react';
import ShortcutsMenu from 'src/components/menu/organisms/shortcutsMenu/ShortcutsMenu';
import styles from './ShortcutsMenuWrapper.module.css';

const ShortcutsMenuWrapper: FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperInner}>
                <ShortcutsMenu />
            </div>
        </div>
    );
};

export default React.memo(ShortcutsMenuWrapper);
