import { BaseApiResponse, UpdateObjectDataForApiObjectFields } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { SUBMIT_MODES } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObject';
import { PopupFormExtendedEndRequestResponseData } from './types';

export async function sendPopupFormExtendedEndRequestAdd(
    url: string,
    mode: SUBMIT_MODES,
    data: UpdateObjectDataForApiObjectFields
): Promise<BaseApiResponse<PopupFormExtendedEndRequestResponseData>> {
    const splittedUrl = url.split('?');
    const params =
        splittedUrl[1] === '' ? `cyclicMode=${mode}` : `${splittedUrl[1]}&cyclicMode=${mode}`;
    return simpleQueryAPIData(`${splittedUrl[0]}?${params}`, 'POST', data, true, {
        alert: false,
        navigation: false
    });
}
