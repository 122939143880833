import { InputValidator } from 'src/components/popupFormExtended/dynamicField/shared/InputValidator';
import { DynamicFieldT } from 'src/data/popupFormTypes';

export function prepareValueToSetForFieldNumberNormal({
    dynamicFieldT,
    oldValue,
    newValue
}: {
    oldValue: string;
    newValue: string;
    dynamicFieldT: DynamicFieldT;
}) {
    let valid = true;
    let valuePrepared = '';

    const checkedValue = new InputValidator({
        dynamicFieldT,
        oldValue,
        staticActions: ['runSize', 'runOnlyNumberAndDotCommnaMinus'],
        newValue
    }).execute();

    valuePrepared = checkedValue ? checkedValue.replaceAll(',', '.') : '';
    if (valuePrepared === '-') {
        return {
            valid: true,
            valuePrepared
        };
    }
    if (isNaN(Number(valuePrepared))) {
        valid = false;
    }
    return {
        valid,
        valuePrepared
    };
}
