import { useEffect } from 'react';
import { fetchAndLoadInitialObjectData } from 'src/store/src/object/sharedActions';
import { dispatch } from 'src/store';
import { useSearchParam } from '../shared/useSearchParam';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { resetData } from 'src/store/src/object';
import { getSectionParams } from 'src/utils';
import { fetchAndLoadUtilitiesBarActions } from 'src/store/src/utilitiesBar/utilitiesBar/utilitiesbarSlice';

export const useLoadInitialObjectData = () => {
    const screen = useSearchParam('obiekt');
    const id = useSearchParam('id');

    useEffect(() => {
        if (screen === null || id === null) {
            console.error('useLoadInitialObjectData: obiekt or id params are null');
            return;
        }

        const loadData = async () => {
            dispatch(resetData());
            dispatch(setSpinner(true));
            // id_zakladki param is loaded differently, to not fire all object data reload on clicking at tabs
            const tabId = new URLSearchParams(window.location.search).get('id_zakladki');
            // load section params
            const sectionParams = getSectionParams();
            await dispatch(fetchAndLoadInitialObjectData(screen, id, tabId, sectionParams));
            await dispatch(
                fetchAndLoadUtilitiesBarActions(screen, 'ekran_obiektu', id ?? undefined)
            );
            dispatch(setSpinner(false));
        };

        loadData();
    }, [screen, id]);
};
