export function protectToLongTxtInSelectOptions(
    options: {
        [key: string]: string;
    },
    maxLength: number
): {
    [key: string]: string;
} {
    const newOptions: {
        [key: string]: string;
    } = {};

    for (const key in options) {
        if (options[key].length > maxLength) {
            newOptions[key] = options[key].substring(0, maxLength) + '...';
        } else {
            newOptions[key] = options[key];
        }
    }

    return newOptions;
}
