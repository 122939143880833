import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';
import { MuiTypes } from 'src/data/muiStylesTypes';

type UseFieldClassesProps = {
    muiType: MuiTypes;
};

export function useFieldClasses({ muiType }: UseFieldClassesProps) {
    const theme = useTheme();

    let useStyles = makeStyles({
        option: {},
        listbox: {},
        paper: {},
        popper: {}
    });

    switch (muiType) {
        case 'autocompleteOne':
            useStyles = makeStyles({
                option: {
                    minHeight: '30px !important'
                },
                listbox: {
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important'
                },
                paper: {
                    background: '#fff !important',
                    marginTop: '1px',
                    border: `solid 1px ${theme.palette.primary.main}`
                },
                popper: {
                    position: 'absolute',
                    zIndex: 0
                }
            });
            break;
        case 'selectOne':
            useStyles = makeStyles({
                listbox: {},
                popper: {},
                option: {},
                paper: {
                    background: '#fff!important',
                    fontWeight: 'bold',
                    border: `solid 1px ${theme.palette.primary.main}`,
                    transform: 'translateX(-2px)'
                }
            });
            break;
        default:
            break;
    }

    const classes = useStyles();
    return classes;
}
