import React, { FC } from 'react';

import FieldValueToAcceptWrapper from '../shared/fieldValueToAcceptWrapper/FieldValueToAcceptWrapper';
import { UIFieldTextProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';

export const UIFieldText: FC<UIFieldTextProps> = (props) => {
    const { value, onChange, onBlur, uiFieldCommonProps, uiFieldSharedProps, onAcceptValue } =
        props;
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <FieldValueToAcceptWrapper
                value={value}
                valueToAccept={uiFieldCommonProps.valueToAccept}
                onAcceptValue={onAcceptValue}>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value ?? ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={uiFieldSharedProps.inputProps}
                    endAdornment={
                        props.value ? (
                            <ButtonCross
                                onClick={() => {
                                    props.onClear();
                                }}
                                style={{
                                    top: '5px'
                                }}
                            />
                        ) : null
                    }
                />
            </FieldValueToAcceptWrapper>
        </UiWrapper>
    );
};
