import {
    BudgetStableFieldSumType,
    budgetStableFieldSum
} from 'src/constants/budget/budgetStableFieldSum';

export function getStableFieldsSums({ type }: { type: BudgetStableFieldSumType }) {
    const fields = budgetStableFieldSum[type];

    if (fields) {
        return {
            isSum: true,
            fields
        };
    }
    return {
        isSum: false
    };
}
