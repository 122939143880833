import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

import { FieldProps } from 'src/components/popupFormExtended/field/types';
import styles from './UiWrapperFieldTwo.module.css';

type Props = FieldProps & {
    sx?: SxProps<Theme>;
};

// definiuje % szerokość inputów (całego bloku np. przypadku datepickera) biorąc parametr z width z api - uwaga jesli sie doda width w sx to zostanie nadpisany
// ustawia fieldkey, który służy do scrollowania dla pola przy walidacji obowiazkowosci

// define % width inputs (whole block example datepicker) gets parametr from api - attention if pass in sx property width you overwrite api prop
// define fieldkey, which help catch field to itself have to be scrolled when validation requirement run

export const UiWrapperFieldTwo: FC<Props> = ({
    children,
    sx,
    dynamicFieldT,
    keyOfFieldInState
}) => {
    const width = dynamicFieldT?.width ? dynamicFieldT?.width : 1;
    return (
        <Box
            sx={{
                width: `${width * 100}%`,
                ...sx
            }}
            className={styles.wrapper}
            data-fieldkey={keyOfFieldInState}>
            {children}
        </Box>
    );
};
