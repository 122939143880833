import { SearchFieldsState } from 'src/store/src/listScreen/searchFields/types';
import { numberRowsPerPage } from './list';

const STATIC_SEARCH_FIELDS: SearchFieldsState = [
    {
        fieldId: -2,
        searchCode: 'zmiana_obiektu_do',
        name: 'Ostatnia zmiana obiektu do',
        searchType: 'data_pojedyncza',
        searchOptions: [],
        autocompleteSearchMode: null,
        defaultValue: null,
        orMode: false,
        defaultOrValue: false,
        width: 0.5,
        masterFieldCode: null,
        masterRelatedOptions: null,
        isRelationForeignField: false
    },
    {
        fieldId: -3,
        searchCode: 'zmiana_obiektow_powiazanych_do',
        name: 'Ostatnia zmiana obiektów powiązanych do',
        searchType: 'data_pojedyncza',
        searchOptions: [],
        autocompleteSearchMode: null,
        defaultValue: null,
        orMode: false,
        defaultOrValue: false,
        width: 0.5,
        masterFieldCode: null,
        masterRelatedOptions: null,
        isRelationForeignField: false
    },
    {
        fieldId: -5,
        searchCode: 'ilosc_wynikow',
        name: 'Ilość wyników',
        searchType: 'lista_ilosc_na_stronie',
        searchOptions: numberRowsPerPage.map((number) => ({ name: number.toString(), id: number })),
        autocompleteSearchMode: null,
        defaultValue: null,
        orMode: false,
        defaultOrValue: false,
        width: 0.2,
        masterFieldCode: null,
        masterRelatedOptions: null,
        isRelationForeignField: false
    }
];

export default STATIC_SEARCH_FIELDS;
