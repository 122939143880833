import React, { useReducer, createContext, Reducer } from 'react';

import { PopupState } from 'src/store/src/popup/popup/types';
import { GlobalPopupContextManager } from 'src/context/globalPopup/GlobalPopupContextManager';
import { TabId } from 'src/store/src/object/object/types';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { dimensionsPopupFormExtended } from 'src/constants/popupContentDynamicExtended/dimensions';
import { PopupFormTab } from 'src/data/popupFormTypes';

export type ConfigPropertyObj = {
    [code: string]: {
        isPinned: boolean;
        isVisible: boolean;
    };
};

export type ConfigPropertyArrayItem = {
    id: string;
    fieldType: string;
    code: string;
    isPinned: boolean;
    isVisible: boolean;
    name: string;
    statusIconPinned?: 'isDisabled' | 'isActive' | 'isPinned';
};

export type ConfigState = {
    limitIsAchieved: boolean;
    propertyCol: ConfigPropertyArrayItem[];
    propertyColObj: ConfigPropertyObj;
    isCondensedView: boolean;
};

export type DimensionsPopup = {
    isFixedWidth: boolean;
    maxContentWidth: number;
    width: number;
    breakpointWidth: number;
    fieldWidth: number;
};

export type TabsLayout = {
    isBoxGhost: boolean;
    name: 'WITHSCROLL' | 'WITHOUTSCROLL';
    wrapperGrid: string;
    left: number;
};

export type ActiveTab = {
    id: string | null;
    index: number | null;
    positionLeft: number;
    offsetLeft: number;
    width: number;
};

export type ScrollKey = string;

export type GlobalPopupState = {
    config: ConfigState;
    dimensions: DimensionsPopup;
    tabs: PopupFormTab[];
    tabsLayout: TabsLayout;
    activeTab: ActiveTab;
    scrollKey: ScrollKey;
};

export enum ActionKind {
    SET_WITHOUT_DATA = 'SET_WITHOUT_DATA',
    SET_CONFIG = 'SET_CONFIG',
    TOGGLE_CONFIG_IS_CONDENSED_VIEW = 'TOGGLE_CONFIG_IS_CONDENSED_VIEW',
    SET_DYNAMIC_EXTENDED = 'SET_DYNAMIC_EXTENDED',
    SET_ACTIVE_TAB_ID_AND_INDEX = 'SET_ACTIVE_TAB_ID_AND_INDEX',
    SET_ACTIVE_TAB_ID_AND_INDEX_AND_SCROLLKEY = 'SET_ACTIVE_TAB_ID_AND_INDEX_AND_SCROLLKEY',
    SET_SCROLLKEY = 'SET_SCROLLKEY',
    SET_ACTIVE_TAB_POSITION = 'SET_ACTIVE_TAB_POSITION',
    SET_WIDTH = 'SET_WIDTH',
    SET_TAB_WITH_SCROLL = 'SET_TAB_WITH_SCROLL',
    SET_TAB_WITHOUT_SCROLL = 'SET_TAB_WITHOUT_SCROLL'
}
export interface Action {
    type: keyof typeof ActionKind;
    payload: GlobalPopupState;
}

export type ActionSetWithoutData = {
    type: 'SET_WITHOUT_DATA';
    payload: {
        config: ConfigState;
    };
};

export type ActionSetConfig = {
    type: 'SET_CONFIG';
    payload: {
        config: ConfigState;
    };
};

export type ActionToggleConfigIsCondensedView = {
    type: 'TOGGLE_CONFIG_IS_CONDENSED_VIEW';
    payload: null;
};

export type ActionSetDynamicExtended = {
    type: 'SET_DYNAMIC_EXTENDED';
    payload: {
        activeTabId?: TabId;
        popupState: PopupState;
    };
};

export type ActionSetActiveTabIdAndIndex = {
    type: 'SET_ACTIVE_TAB_ID_AND_INDEX';
    payload: {
        activeTabId?: TabId;
        activeTabIdIndex: number;
    };
};

export type ActionSetActiveTabIdAndIndexAndScrollKey = {
    type: 'SET_ACTIVE_TAB_ID_AND_INDEX_AND_SCROLLKEY';
    payload: {
        activeTabId?: TabId;
        activeTabIdIndex: number;
        scrollKey: ScrollKey;
    };
};

export type ActionSetScrollKey = {
    type: 'SET_SCROLLKEY';
    payload: {
        scrollKey: ScrollKey;
    };
};

type ActionSetActiveTabPosition = {
    type: 'SET_ACTIVE_TAB_POSITION';
    payload: {
        positionLeft: number;
        offsetLeft: number;
        width: number;
    };
};

type ActionSetWidth = {
    type: 'SET_WIDTH';
    payload: number;
};

type ActionTabsWithScroll = {
    type: 'SET_TAB_WITH_SCROLL';
};

const withScroll: TabsLayout = {
    isBoxGhost: true,
    name: 'WITHSCROLL',
    wrapperGrid: '40px auto 40px',
    left: 40
};

export type Actions =
    | ActionSetConfig
    | ActionToggleConfigIsCondensedView
    | ActionSetDynamicExtended
    | ActionSetActiveTabIdAndIndex
    | ActionSetActiveTabPosition
    | ActionSetWidth
    | ActionTabsWithScroll
    | ActionSetActiveTabIdAndIndexAndScrollKey
    | ActionSetScrollKey;

export type GlobalPopupContextProps = {
    popupState: PopupState;
};

export const reducerGlobalPopupState: Reducer<GlobalPopupState, Actions> = (state, action) => {
    switch (action.type) {
        case ActionKind.SET_CONFIG:
            return {
                ...state,
                config: action.payload.config
            };
        case ActionKind.TOGGLE_CONFIG_IS_CONDENSED_VIEW:
            return {
                ...state,
                config: {
                    ...state.config,
                    isCondensedView: !state.config.isCondensedView
                }
            };
        case ActionKind.SET_ACTIVE_TAB_ID_AND_INDEX: {
            return {
                ...state,
                activeTab: {
                    ...state.activeTab,
                    id: action.payload.activeTabId ? action.payload.activeTabId : null,
                    index: action.payload.activeTabIdIndex
                }
            };
        }
        case ActionKind.SET_ACTIVE_TAB_POSITION: {
            return {
                ...state,
                activeTab: {
                    ...state.activeTab,
                    width: action.payload.width,
                    positionLeft: action.payload.positionLeft,
                    offsetLeft: action.payload.offsetLeft
                }
            };
        }
        case ActionKind.SET_WIDTH: {
            return {
                ...state,
                dimensions: {
                    ...state.dimensions,
                    width: action.payload
                }
            };
        }
        case ActionKind.SET_TAB_WITH_SCROLL: {
            return {
                ...state,
                tabsLayout: withScroll
            };
        }
        case ActionKind.SET_ACTIVE_TAB_ID_AND_INDEX_AND_SCROLLKEY:
            return {
                ...state,
                activeTab: {
                    ...state.activeTab,
                    id: action.payload.activeTabId ? action.payload.activeTabId : null,
                    index: action.payload.activeTabIdIndex
                },
                scrollKey: action.payload.scrollKey
            };
        case ActionKind.SET_SCROLLKEY:
            state.scrollKey = action.payload.scrollKey;
            return state;

        default:
            return {
                ...state
            };
    }
};

export const initialState: GlobalPopupState = {
    config: {
        limitIsAchieved: false,
        propertyCol: [],
        propertyColObj: {},
        isCondensedView: false
    },
    activeTab: {
        id: '0',
        index: 0,
        positionLeft: 0,
        offsetLeft: 0,
        width: 0
    },
    tabsLayout: {
        isBoxGhost: false,
        name: 'WITHOUTSCROLL',
        wrapperGrid: 'auto',
        left: 0
    },
    tabs: [],
    dimensions: {
        isFixedWidth: true,
        width: 0,
        ...dimensionsPopupFormExtended.normal
    },
    scrollKey: ''
};

const GlobalPopup = createContext<{
    globalPopupState: GlobalPopupState;
    dispatchGlobalPopupContext: React.Dispatch<Actions>;
}>({
    globalPopupState: initialState,
    dispatchGlobalPopupContext: () => null
});

const GlobalPopupProvider: React.FC<GlobalPopupContextProps> = ({ children, popupState }) => {
    const [globalPopupState, dispatchGlobalPopupContext] = useReducer(
        reducerGlobalPopupState,
        { popupState },
        GlobalPopupContextManager.initContext
    );
    return (
        <GlobalPopup.Provider value={{ globalPopupState, dispatchGlobalPopupContext }}>
            {children}
        </GlobalPopup.Provider>
    );
};

export { GlobalPopupProvider, GlobalPopup };

export const usePopupState = (keyPopup: string) => {
    const { globalPopupState, dispatchGlobalPopupContext } = React.useContext(GlobalPopup);
    const popups = useTypedSelector((state) => state.popup.popupItemState);
    const dispatch = useAppDispatch();
    const popup = popups[keyPopup];
    return {
        popupStateRedux: popup,
        dispatchPopupStateRedux: dispatch,
        popupStateContext: globalPopupState,
        dispatchPopupStateContext: dispatchGlobalPopupContext
    };
};
