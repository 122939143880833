import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';
import { BaseApiResponse } from '../../types';

export async function doPayoffImport(
    fileId: string,
    bank: string,
    issuer: string
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        `api_react/src/importPayoff/doPayoffImport.php?fileId=${fileId}&bank=${bank}&issuer=${issuer}`,
        'POST'
    );
}
