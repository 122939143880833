import { divideMainPartSumKey } from 'src/utils/src/budget/divideMainPartSumKey';

export function getIngredientsKeySum(keySum: string, keySumInTempState?: string) {
    const { rest: mainKeySum } = divideMainPartSumKey(keySum);
    const { rest: mainKeySumInTempState } = divideMainPartSumKey(keySumInTempState);

    if (mainKeySum.length && mainKeySumInTempState.length) {
        const keySplited = mainKeySum?.split('!');
        const keyInTempStateSplited = mainKeySumInTempState?.split('!');
        return {
            keySumLevelZero: keySplited?.[0],
            keySumLevelOne: keySplited?.[1],
            keySumLevelTwo: keySplited?.[2],
            keySumLevelThree: keySplited?.[3],
            keySumLevelFour: keySplited?.[4],
            keySumLevelFive: keySplited?.[5],
            keySumTempStateZero: keyInTempStateSplited?.[0],
            keySumTempStateOne: keyInTempStateSplited?.[1],
            keySumTempStateTwo: keyInTempStateSplited?.[2],
            keySumTempStateThree: keyInTempStateSplited?.[3],
            keySumTempStateFour: keyInTempStateSplited?.[4],
            keySumTempStateFive: keyInTempStateSplited?.[5]
        };
    } else if (mainKeySum.length) {
        const keySplited = mainKeySum?.split('!');
        return {
            keySumLevelZero: keySplited?.[0],
            keySumLevelOne: keySplited?.[1],
            keySumLevelTwo: keySplited?.[2],
            keySumLevelThree: keySplited?.[3],
            keySumLevelFour: keySplited?.[4],
            keySumLevelFive: keySplited?.[5],
            keySumTempStateZero: 'null',
            keySumTempStateOne: 'null',
            keySumTempStateTwo: 'null',
            keySumTempStateThree: 'null',
            keySumTempStateFour: 'null',
            keySumTempStateFive: 'null'
        };
    }
    return {
        keySumLevelZero: '',
        keySumLevelOne: '',
        keySumLevelTwo: '',
        keySumLevelThree: '',
        keySumLevelFour: '',
        keySumLevelFive: '',
        keySumTempStateZero: 'null',
        keySumTempStateOne: 'null',
        keySumTempStateTwo: 'null',
        keySumTempStateThree: 'null',
        keySumTempStateFour: 'null',
        keySumTempStateFive: 'null'
    };
}
