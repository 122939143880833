import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, shadows, spacing }) => ({
    widget_root: {
        position: 'fixed',
        zIndex: (props) => (props.open ? 50001 : 50000),
        top: (props) => 50 + (props.order * spacing(4.8) + props.order * 5),
        right: 0,
        borderRadius: '4px 0 0 0',
        height: spacing(60),
        width: spacing(40),
        transform: 'translateX(100%)',
        transition: 'transform .35s ease-out',
        borderLeft: '1px solid ' + palette.text.disabled,
        boxShadow: 'none',
        '&.widget_open': {
            boxShadow: shadows[3],
            transform: 'translateX(0)',
            borderLeft: 'none'
        },
        '& input': {
            border: 'none',
            backgroundColor: 'transparent !important',
            '&:focus': {
                border: 'none !important',
                boxShadow: 'none !important',
                outline: 'none'
            }
        }
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie wysuwanego panelu z prawej strony ekranu
 * @component
 * @category Components
 * @subcategory Shared
 * @param {object} props - Props komponentu.
 * @param {number} props.order - kolejność ułożenia na osi Y. Od 0
 * @param {boolean} props.open - kolejność ułożenia na osi Y. Od 0
 * @param {ReactComponent[]} props.children - dzieci komponentu.
 * @returns {ReactComponent}
 */
const WidgetContainer = ({ order, open, children }) => {
    const classes = useStyles({ order, open });
    return (
        <div
            className={open ? [classes.widget_root, 'widget_open'].join(' ') : classes.widget_root}>
            {children}
        </div>
    );
};
export default WidgetContainer;
