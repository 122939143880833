import React from 'react';

import { fetchTagInitData } from 'src/api/src/tagScreen/fetchTagScreenInitData';
import { TagScreenData } from 'src/api/src/tagScreen/types';
import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';

export const useTagDefaultData = () => {
    const obiekt = useSearchParam('obiekt');

    const [data, setData] = React.useState<TagScreenData | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            if (obiekt) {
                const res = await fetchTagInitData(obiekt);
                if (res.success) {
                    setData(res.data);
                    console.log(res.data);
                }
            }
            setIsLoading(false);
        })();
    }, []);

    return {
        isLoading,
        data
    };
};
