import React, { FC, useEffect, useRef } from 'react';

import { usePopupState } from 'src/context/globalPopup/popupContext';
import styles from './TabsWrapper.module.css';
import { TabsWrapperProps } from 'src/components/popupFormExtended/tabsWrapper/types';
import { TabsWithScroll } from 'src/components/popupFormExtended/tabsWithScroll/TabsWithScroll';
import { TabsWithoutScroll } from 'src/components/popupFormExtended/tabsWithoutScroll/TabsWithoutScroll';
import { useTheme } from '@mui/material';
import { PopupFormExtendedBehavior } from 'src/data/popupFormTypes';
import { useRefreshObjectData } from 'src/hooks/src/object/useRefreshObjectData';
import { useLocation } from 'react-router-dom';

export const TabsWrapper: FC<TabsWrapperProps> = (props) => {
    const { popup } = props;
    const theme = useTheme();

    const { popupStateRedux, dispatchPopupStateContext, popupStateContext } = usePopupState(
        popup.key
    );
    const behaviour = popupStateRedux?.action?.behaviour as PopupFormExtendedBehavior;

    const refreshObjectScreenData = useRefreshObjectData();
    const location = useLocation();

    // needed for cleanup to open proper tab in object screen at popup close
    const activeTabIdRef = useRef(popupStateContext.activeTab.id);

    useEffect(() => {
        activeTabIdRef.current = popupStateContext.activeTab.id;
    }, [popupStateContext.activeTab.id]);

    useEffect(() => {
        loadTabFromObjectParams();

        // clearup function to set tab with activeTabId in objectScreen
        return () => {
            if (
                activeTabIdRef.current &&
                behaviour.data.objectId !== 'nowy' &&
                location.pathname === '/obiekt' &&
                popoupScreenMatchWithParamsScreen()
            )
                refreshObjectScreenData(activeTabIdRef.current);
        };
    }, []);

    const loadTabFromObjectParams = () => {
        if (!popoupScreenMatchWithParamsScreen()) return;
        const tabIdFromParam = new URLSearchParams(window.location.search).get('id_zakladki');
        if (!tabIdFromParam) return;
        // if tab is not present in popupStateContext.dynamicExtended.tabs do not load it, because
        // that means tab have empty content
        if (!popupStateContext.tabs.find((item) => item.id === tabIdFromParam)) return;

        if (popupStateRedux.popupFormExtendedData === null) {
            console.error('Tabs: loadTabFromObjectParams popupFormExtendedData is null');
            return;
        }

        const activeTabIdIndex = popupStateRedux.popupFormExtendedData.tabs.findIndex(
            (tabData) => tabData.id === tabIdFromParam
        );

        dispatchPopupStateContext({
            type: 'SET_ACTIVE_TAB_ID_AND_INDEX',
            payload: {
                activeTabId: tabIdFromParam,
                activeTabIdIndex
            }
        });
    };

    const popoupScreenMatchWithParamsScreen = () =>
        popupStateRedux.action?.behaviour &&
        popupStateRedux.action?.behaviour.type === 'popupFormExtended' &&
        new URLSearchParams(window.location.search).get('obiekt') ===
            popupStateRedux.action.behaviour.data.screen;

    React.useEffect(() => {
        if (popupStateContext.scrollKey) {
            const el1: HTMLElement | null = document.querySelector(
                `[data-fieldkey=${popupStateContext.scrollKey}]`
            );
            el1?.scrollIntoView();
            dispatchPopupStateContext({
                type: 'SET_SCROLLKEY',
                payload: {
                    scrollKey: ''
                }
            });
        }
    }, [popupStateContext.scrollKey]);

    return (
        <div
            className={styles.tabs}
            style={{
                maxWidth: popupStateContext.dimensions.width + 80 + 'px',
                gridTemplateColumns: popupStateContext.tabsLayout.wrapperGrid,
                width: popupStateContext.tabsLayout.name === 'WITHOUTSCROLL' ? 'auto' : '100%',
                boxShadow: `0px -1px 0px 0px ${theme.palette.primary.main}`
            }}>
            {popupStateContext.tabsLayout.isBoxGhost && <div></div>}
            {popupStateContext.tabsLayout.name === 'WITHOUTSCROLL' ? (
                <TabsWithoutScroll {...props} />
            ) : (
                <TabsWithScroll {...props} />
            )}
            {popupStateContext.tabsLayout.isBoxGhost && <div></div>}
        </div>
    );
};
