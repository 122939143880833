import { MessageRow, MessageValidationCorrectEmail, MessageValidationRequirement } from './types';
import { AutocompleteOption } from 'src/data/uiTypes/autocompleteTypes';
import { checkMailIsValid, getArrayOfItemSplited } from 'src/utils';

type MessageValidationType = 'template' | 'main' | 'none';

export class MessageDataValidator {
    messageRow: MessageRow[];
    messageRowHidden: MessageRow[];
    validationResult: {
        message: string;
        isValid: boolean;
    };
    type: MessageValidationType;
    constructor({
        messageRow,
        messageRowHidden
    }: {
        messageRow?: MessageRow[];
        messageRowHidden?: MessageRow[];
    }) {
        this.messageRow = messageRow ?? [];
        this.messageRowHidden = messageRowHidden ?? [];
        this.validationResult = {
            message: '',
            isValid: true
        };
        this.type = 'none';
    }
    protected validate(type: MessageValidationType) {
        this.type = type;
        switch (type) {
            case 'template':
                this.validateTemplate();
                break;
            case 'main':
                this.validateMain();
                break;
            default:
                break;
        }
        return this.validationResult;
    }
    private validateTemplate() {
        const rowTemplate = this.messageRow.find((item) => item.code === 'id_szablonu');
        if (rowTemplate && this.validationResult.isValid) {
            this.validateRow(rowTemplate);
        }
    }

    private validateMain() {
        this.messageRow.forEach((item) => {
            if (this.validationResult.isValid && item.validation) {
                this.validateRow(item);
            }
        });
    }

    private validateRow(row: MessageRow) {
        row.validation?.forEach((validationOne) => {
            if (validationOne.omitValidionType && validationOne.omitValidionType === this.type)
                return;
            switch (validationOne.type) {
                case 'requirement':
                    this.validateRequirement({ row, validation: validationOne });
                    break;
                case 'correctEmail':
                    this.validateCorrectMail({ row, validation: validationOne });
                    break;
                default:
                    break;
            }
        });
    }

    private validateRequirement({
        row,
        validation
    }: {
        row: MessageRow;
        validation: MessageValidationRequirement;
    }) {
        switch (row.type) {
            case 'text':
            case 'template':
                if (!row.value) {
                    this.validationResult.isValid = false;
                    this.validationResult.message = validation.info;
                }
                break;
            case 'email':
                if (!row.value) {
                    let isNotValid = true;
                    validation?.alternativeFieldCode?.forEach((code) => {
                        const { value } = this.getValueForeign(code);
                        if (value) {
                            isNotValid = false;
                        }
                    });

                    if (isNotValid) {
                        const { autoCompleteOption } = this.getValueForeign(
                            validation.conditionField?.code
                        );

                        if (
                            !autoCompleteOption.length ||
                            autoCompleteOption.find(
                                (item) => item.id === validation.conditionField?.value
                            )
                        ) {
                            this.validationResult.isValid = false;
                            this.validationResult.message = validation.info;
                        }
                    }
                }
                break;
            default:
                break;
        }
    }

    private validateCorrectMail({
        row,
        validation
    }: {
        row: MessageRow;
        validation: MessageValidationCorrectEmail;
    }) {
        switch (row.type) {
            case 'email':
                if (row.value) {
                    const emailSplit = getArrayOfItemSplited({
                        str: row.value,
                        splitter: [';', ',', ':', '/', '|']
                    });
                    for (let index = 0; index < emailSplit.length; index++) {
                        if (!checkMailIsValid(emailSplit[index])) {
                            this.validationResult.isValid = false;
                            this.validationResult.message =
                                validation.info + ' ' + emailSplit[index];
                            break;
                        }
                    }
                }
                break;
            default:
                break;
        }
    }

    private getValueForeign(code?: string) {
        const data: {
            value: string | null;
            autoCompleteOption: AutocompleteOption[];
        } = {
            value: null,
            autoCompleteOption: []
        };
        const rowForeign = this.messageRow.find((item) => item.code === code);
        switch (rowForeign?.type) {
            case 'email':
                data.value = rowForeign.value;
                break;
            case 'list_wielokrotna':
                data.autoCompleteOption = rowForeign.value;
                break;
            default:
                break;
        }
        return data;
    }
}
