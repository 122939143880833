import { useRef } from 'react';

// code from https://github.com/streamich/react-use

export function useFirstMountState(): boolean {
    const isFirst = useRef(true);

    if (isFirst.current) {
        isFirst.current = false;

        return true;
    }

    return isFirst.current;
}
