import React, { FC, useMemo } from 'react';

import { useTypedSelector } from 'src/store';
import { StartupAnimationProps } from './types';
import SyndykLogo from 'src/assets/logo/SYNDYKnapis.svg';
import styles from './styles.module.css';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import MecenasLogoStartup from 'src/assets/logo/mecens_pion.svg';
import MecenasChristmasLogoStartup from 'src/assets/logo/logo_swieta_pion.svg';
import { FestivitiesIconsProvider } from 'src/utils/src/shared/FestivitiesIconsProvider';

export const StartupAnimation: FC<StartupAnimationProps> = () => {
    const systemName = useTypedSelector((state) => state.system.systemName);
    const startupLoadingImage = useTypedSelector((state) => state.system.startupLoadingImage);

    const svgComponent = useMemo(
        () =>
            startupLoadingImage
                ? undefined
                : systemName === 'Syndyk.iT'
                ? SyndykLogo
                : new FestivitiesIconsProvider().getStartupLogo(
                      MecenasLogoStartup,
                      MecenasChristmasLogoStartup
                  ),
        [startupLoadingImage]
    );

    return (
        <ThemeCompatibleIcon
            SvgComponent={svgComponent}
            iconData={
                startupLoadingImage ? { svgFileName: startupLoadingImage, faCode: '' } : undefined
            }
            wrapperCommonProps={{ className: styles.wrapper }}
            sx={{ width: '100%', height: '100%' }}
        />
    );
};
