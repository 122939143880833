import { simpleQueryAnyJson } from 'src/api/src/simpleQueryAnyJson';
import { InputsState } from './types';
import { PasswordRequirementsCheckResult } from './PasswordRequirementsChecker';

export type CorrectnessCheckResult = {
    valid: boolean;
    message: string;
};

export default class PasswordValidator {
    inputsState: InputsState;
    userPasswordValidation: boolean;
    userTab: string;
    objectId: string;

    constructor(
        inputsState: InputsState,
        userPasswordValidation: boolean,
        userTab: string,
        objectId: string
    ) {
        this.inputsState = inputsState;
        this.userPasswordValidation = userPasswordValidation;
        this.userTab = userTab;
        this.objectId = objectId;
    }

    async checkPasswordCorrectnessOnBlur(): Promise<CorrectnessCheckResult> {
        if (this.inputsState.newPassword !== this.inputsState.newPasswordRepeat)
            return {
                valid: false,
                message: 'Hasła różnią się między sobą!'
            };

        if (
            this.userPasswordValidation &&
            this.inputsState.actualPassword === '' &&
            this.inputsState.newPassword !== '' &&
            this.inputsState.newPasswordRepeat !== ''
        ) {
            return {
                valid: false,
                message: 'Dla dodatkowej weryfikacji podaj swoje hasło !!'
            };
        }

        const response = await simpleQueryAnyJson<{ code: number; response: string }>(
            'json_chk_pw.php',
            'POST',
            {
                chk_pw_other: 1,
                q: encodeURIComponent(this.inputsState.newPassword),
                obiekt: '',
                user_tab: this.userTab,
                user_id: this.objectId
            }
        );
        if (!response.success) {
            return { valid: false, message: 'API error' };
        }
        if (response.data && response.data.code !== 200) {
            return { valid: false, message: response.data.response + '.' };
        }

        if (this.userPasswordValidation) {
            const response2 = await simpleQueryAnyJson<{ code: number; response: string }>(
                'json_chk_pw.php',
                'POST',
                {
                    chk_pw: 1,
                    q: encodeURIComponent(this.inputsState.actualPassword),
                    obiekt: '',
                    user_tab: this.userTab,
                    user_id: ''
                }
            );
            if (!response2.success) {
                return { valid: false, message: 'API error' };
            }
            if (response2.data && response2.data.code !== 200) {
                return { valid: false, message: response2.data.response + '.' };
            }
        }

        return { valid: true, message: 'OK' };
    }

    async checkPasswordCorrectnessOnSubmit(
        requirementsEnabled: PasswordRequirementsCheckResult,
        requirementsState: PasswordRequirementsCheckResult
    ): Promise<CorrectnessCheckResult> {
        if (!requirementsState.hasla_dlugosc) {
            return { valid: false, message: 'Twoje hasło jest za krótkie !' };
        }
        if (requirementsEnabled.hasla_male_litery && !requirementsState.hasla_male_litery) {
            return { valid: false, message: 'Twoje hasło nie posiada małych liter !' };
        }
        if (requirementsEnabled.hasla_duze_litery && !requirementsState.hasla_duze_litery) {
            return { valid: false, message: 'Twoje hasło nie posiada wielkich liter !' };
        }
        if (requirementsEnabled.hasla_znaki_specjalne && !requirementsState.hasla_znaki_specjalne) {
            return { valid: false, message: 'Twoje hasło nie posiada znaków specjalnych !' };
        }
        if (requirementsEnabled.hasla_cyfry && !requirementsState.hasla_cyfry) {
            return { valid: false, message: 'Twoje hasło nie posiada cyfr !' };
        }

        const firstLevelValidResult = await this.checkPasswordCorrectnessOnBlur();
        if (!firstLevelValidResult.valid) return firstLevelValidResult;

        if (this.inputsState.actualPassword !== '') {
            const response = await simpleQueryAnyJson<{ code: number; response: string }>(
                'json_chk_pw.php',
                'POST',
                {
                    chk_pw: 1,
                    q: encodeURIComponent(this.inputsState.actualPassword),
                    obiekt: '',
                    user_tab: this.userTab,
                    user_id: '' // because in ekran_zmiany_hasla.php is commented line with set user_id_zalogowany
                }
            );
            if (!response.success) {
                return { valid: false, message: 'API error' };
            }
            if (response.data && response.data.code !== 200) {
                return { valid: false, message: response.data.response + '.' };
            }
        }
        return { valid: true, message: 'OK' };
    }
}
