import { ScreenMenuData } from 'src/store/src/menu/menu/types';
export const getUrlParams = (screen: ScreenMenuData) => {
    const url: string = screen?.link ? `${screen.link.url}` : 'ekran_obiektow.php';

    const params = screen.link?.params
        .map((el) => {
            return `${el.key}=${encodeURIComponent(el.value)}`;
        })
        .join('&');

    const queryParams = screen?.link
        ? new URLSearchParams(params)
        : new URLSearchParams(`obiekt=${screen.id}&screen=${screen.code}`);

    return { url: url, queryParams: queryParams };
};
