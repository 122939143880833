import React, { FC } from 'react';
import { PlusProps } from './types';
import { useTheme } from '@mui/material/styles';
import styles from './Plus.module.css';

const Plus: FC<PlusProps> = ({ style, disabled }) => {
    const theme = useTheme();
    return (
        <div
            className={`${styles.plus} ${styles.alt}`}
            style={{
                background: `conic-gradient(from 90deg at 2px 2px,transparent 90deg,${
                    disabled ? theme.palette.gray : theme.palette.primary.main
                } 0) 
        calc(100% + 2px/2) calc(100% + 2px/2)/
        calc(50%  + 2px)   calc(50%  + 2px)`,
                ...style
            }}></div>
    );
};

export default Plus;
