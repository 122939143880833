import React, { FC } from 'react';

type FrameProps = {
    src: string;
    width: number;
    height: number;
};

const Frame: FC<FrameProps> = ({ src, width, height }) => {
    return (
        <div>
            {
                <iframe
                    id="podglad_pliku_plik"
                    src={src}
                    width={width - 80}
                    height={height - 90}
                    allowFullScreen></iframe>
            }
        </div>
    );
};

export default Frame;
