import React from 'react';
import { UiInputTwoOptionEntry } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/types';
import { isObject } from 'src/utils';

type UseOptionsForSelectProps = {
    options?: UiInputTwoOptionEntry[];
    opcje?: {
        [key: string]: string;
    };
};

export const useOptionsForSelect = ({ options, opcje }: UseOptionsForSelectProps) => {
    return React.useMemo(() => {
        const optionsToReturn = options ? options : [];
        if (!optionsToReturn.length && opcje) {
            const ops = opcje ? opcje : {};
            if (isObject(ops)) {
                for (const property in ops) {
                    optionsToReturn.push({ name: ops[property], value: property });
                }
            }
        }
        return optionsToReturn;
    }, [options, opcje]);
};
