import { id } from 'date-fns/locale';
import {
    ObjectSlice,
    ObjectDataFromApi,
    ActiveTab,
    ChangeRelationsOrderProps,
    ActiveTabDataWithId,
    ActiveSublistDataForDispatch
} from './types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: ObjectSlice = {
    tabs: [],
    activeTab: {
        id: '0',
        positionLeft: 0,
        offsetLeft: 0,
        scroll: 0,
        width: 200,
        wewWidth: 0
    },
    footerData: {},
    activeTabData: null
};

const objectSlice = createSlice({
    name: 'object',
    initialState,
    reducers: {
        resetData: (state: ObjectSlice) => {
            state.tabs = initialState.tabs;
            state.activeTab = initialState.activeTab;
            state.activeTabData = initialState.activeTabData;
            state.footerData = initialState.footerData;
        },
        setInitData: (state: ObjectSlice, action: PayloadAction<ObjectDataFromApi>) => {
            state.tabs = action.payload.tabs;
            state.activeTabData = action.payload.activeTabData;
            state.activeTab = {
                ...state.activeTab,
                id: action.payload.activeTabId
            };
            state.footerData = action.payload.footerData;
        },
        setTabData: (state: ObjectSlice, action: PayloadAction<ActiveTabDataWithId>) => {
            state.activeTabData = action.payload.activeTabData;
            state.activeTab = {
                ...state.activeTab,
                id: action.payload.tabId
            };
        },
        setSublistData: (
            state: ObjectSlice,
            action: PayloadAction<ActiveSublistDataForDispatch>
        ) => {
            const newSections = state.activeTabData?.sections
                ? [...state.activeTabData?.sections]
                : [];
            const section = newSections[action.payload.sectionIdx];
            if (section.relationDisplayMode === 'lista') {
                if (section.relations === null) {
                    section.relations = {
                        activeSublistData: action.payload.activeSublistData,
                        activeSublistId: action.payload.sublistId,
                        sublists: []
                    };
                }
                section.relations.activeSublistData = action.payload.activeSublistData;
                section.relations.activeSublistId = action.payload.sublistId;
            }
            newSections[action.payload.sectionIdx] = section;
            state.activeTabData = {
                ...state.activeTabData,
                sections: newSections
            };
        },
        setActiveTabDimensions: (state: ObjectSlice, action: PayloadAction<ActiveTab>) => {
            state.activeTab.offsetLeft = action.payload.offsetLeft;
            state.activeTab.width = action.payload.width;
            state.activeTab.positionLeft = action.payload.positionLeft;
        },
        setActiveTabScroll: (state: ObjectSlice, action: PayloadAction<number>) => {
            state.activeTab.scroll = action.payload;
        },
        setActiveTabWewWidth: (state: ObjectSlice, action: PayloadAction<number>) => {
            state.activeTab.wewWidth = action.payload;
        },
        changeRelationsOrder: (
            state: ObjectSlice,
            action: PayloadAction<ChangeRelationsOrderProps>
        ) => {
            if (!state.activeTabData) return;

            action.payload.forEach((sectionSorted) => {
                const section = state?.activeTabData?.sections.find(
                    (section) => section.id === sectionSorted.id
                );

                if (section && section.relationDisplayMode === 'karta') {
                    section.relations.sort((cardA, cardB) => {
                        for (const item of sectionSorted.item) {
                            if (item.id === cardA.id) return -1;
                            if (item.id === cardB.id) return 1;
                        }
                        return 0;
                    });
                }
            });
            // for (const id in action.payload) {
            //     const section = state.activeTabData.sections.find((item) => item.id === id);

            //     if (section && section.relationDisplayMode === 'karta') {
            //         // section.relations.sort((cardA, cardB) => {
            //         //     for (const item of action.payload[id]) {
            //         //         if (item.id === cardA.id) return -1;
            //         //         if (item.id === cardB.id) return 1;
            //         //     }
            //         //     return 0;
            //         // });
            //     }
            // }
        },
        clearObjectData: (state: ObjectSlice) => {
            state.tabs = [];
            state.activeTab = {
                id: '0',
                positionLeft: 0,
                offsetLeft: 0,
                scroll: 0,
                width: 200,
                wewWidth: 0
            };
            state.footerData = {};
            state.activeTabData = null;
        }
    }
});

export const {
    setInitData,
    setTabData,
    setSublistData,
    setActiveTabDimensions,
    setActiveTabScroll,
    setActiveTabWewWidth,
    resetData,
    changeRelationsOrder,
    clearObjectData
} = objectSlice.actions;

export default objectSlice.reducer;
