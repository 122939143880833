import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';
import { BaseApiResponse } from '../../types';
import { ApiInstructionData } from './types';

export async function fetchObjectNotBillInitData({
    params
}: {
    params: URLSearchParams;
}): Promise<BaseApiResponse<ApiInstructionData>> {
    return simpleQueryAPIData(
        `api_react/src/objectNotBillScreen/getInitialData.php?${params?.toString()}`,
        'GET'
    );
}
