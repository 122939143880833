export function convertNumberToStatisticFormat(number: number): string {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'PLN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
        .format(number)
        .replaceAll('.', ' ');
}
