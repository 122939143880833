import { TemplateKeyMonthIdToUpdateMonthsForCaseLimit } from 'src/hooks/src/budget/useFormBudget';

export const getKeyMonthIdToUpdateMonthsForCaseLimit = (
    templateKeyMonthIdToUpdateMonthsForCaseLimit: TemplateKeyMonthIdToUpdateMonthsForCaseLimit,
    methodId: string,
    monthId: string
) => {
    return (
        templateKeyMonthIdToUpdateMonthsForCaseLimit[methodId]?.find((arr) =>
            arr.includes(String(monthId))
        ) ?? []
    );
};
