export const tinymce_configArray = [
    {
        mode: 'none',

        width: '780',

        height: '300',

        menubar: false,

        //theme : "advanced",

        language: 'pl',

        plugins: [
            'hr,spellchecker,pagebreak,table,save,image,autosave,lists,advlist,link,emoticons,insertdatetime,preview,media,searchreplace,print,contextmenu,paste,directionality,fullscreen,visualchars,nonbreaking,template,wordcount,textcolor'
        ],

        toolbar: [
            'bold italic underline strikethrough alignleft aligncenter alignright alignjustify fontselect fontsizeselect help',

            'bullist numlist | outdent indent blockquote | undo redo | link unlink anchor cleanup code | insertdate inserttime | forecolor backcolor ',

            'table | hr | subscript superscript | charmap emoticons | ltr rtl'
        ],

        // Example content CSS (should be your site CSS)

        extended_valid_elements:
            'table[src|width|height|name|align|style|border|cellpadding|cellspacing|bgcolor]',

        autosave_ask_before_unload: false,

        paste_data_images: true

        //theme : "advanced",

        //toolbar : "bold,italic,underline",
    }
];

export const tinymce_notes = {
    height: '100%',
    menubar: false,
    language: 'pl',
    plugins: ['lists'],
    toolbar: 'bold italic underline bullist numlist',
    extended_valid_elements:
        'table[src|width|height|name|align|style|border|cellpadding|cellspacing|bgcolor]',
    autosave_ask_before_unload: false,
    paste_data_images: true
};
