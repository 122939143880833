import React, { FC, useEffect, useState } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldListForeignMany } from 'src/components/popupFormExtended/dynamicField/fieldListForeignMany/UiFieldForeignMany';
import {
    AutocompleteClicedOption,
    FieldListForeignManyObjectProps
} from 'src/components/popupFormExtended/dynamicField/fieldListForeignMany/types';

import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { getTypeUrl } from 'src/utils';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { AutocompleteOption } from 'src/data/uiTypes/autocompleteTypes';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';

export const FieldListForeignObjectMany: FC<FieldListForeignManyObjectProps> = ({
    field,
    dynamicFieldT,
    keyOfFieldInState,
    dispatch,
    popupFormExtendedData,
    uiFieldCommonProps,
    prefix,
    relationId,
    fieldSuperior
}) => {
    const [options, setOptions] = useState(field.value.opcje);
    const [inputValue, setInputValue] = React.useState('');
    useEffect(() => {
        setOptions(field.value.opcje);
    }, [field.value.opcje]);

    const onChange = (data: AutocompleteOption[], clickedOption: AutocompleteClicedOption) => {
        const values = calculateValues(data, clickedOption);
        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: {
                ...field.value,
                values: values
            }
        };
        dispatch({
            type: 'lista_obca_wielokrotna',
            payload: {
                code: keyOfFieldInState,
                value: newValue
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newValue,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );

        setInputValue('');
    };

    const calculateValues = (
        newValues: AutocompleteOption[],
        clickedOption: AutocompleteClicedOption
    ) => {
        // for wyszukiwanie_rozwijalna mode it wil be better "wszystkie" option work as "normal"
        // option, not setting all available values
        if (dynamicFieldT.wyszukiwanie_rozwijalna === 't') return [...newValues];

        const foundAll = newValues.find((item) => item.text === 'WSZYSTKIE');

        if (foundAll && clickedOption?.option?.text === 'WSZYSTKIE') {
            return [...options];
        }

        if (!foundAll && clickedOption?.option?.text === 'WSZYSTKIE') {
            return [];
        }

        return [...newValues];
    };

    const onInputChange = (text: string) => {
        if (dynamicFieldT.wyszukiwanie_rozwijalna === 't') {
            setInputValue(text);
            getOptionsFromApi(text);
        }
    };

    const getOptionsFromApi = (value: string) => {
        let valueSuperior: string | null = '';
        switch (fieldSuperior?.typ) {
            case 'lista_obca':
            case 'powiazanie_typ':
                valueSuperior = fieldSuperior.value.value;
                break;
            default:
                break;
        }
        let params = {};
        if (dynamicFieldT.pole_nadrzedne_typ) {
            params =
                dynamicFieldT.pole_nadrzedne_typ === 'lista_obca'
                    ? {
                          q: valueSuperior,
                          typ: dynamicFieldT.pole_nadrzedne_tabela_opcji,
                          kod: dynamicFieldT.tabela_opcji,
                          pole: dynamicFieldT.id,
                          filtr: value
                      }
                    : {
                          q: valueSuperior,
                          typ: dynamicFieldT.pole_nadrzedne_typ,
                          kod: dynamicFieldT.tabela_opcji,
                          pole: dynamicFieldT.id,
                          filtr: value
                      };
        } else {
            params = {
                q: value,
                typ: dynamicFieldT.tabela_opcji,
                pole: dynamicFieldT.id
            };
        }
        const url = getTypeUrl({ field: dynamicFieldT });
        if (value != null && value.length > 0) {
            getFieldAdditionalData({
                url,
                params: params
            })
                .then((resp) => {
                    if (resp?.data) {
                        setOptionsWithAll(resp.data);
                    } else {
                        setOptionsWithAll([]);
                    }
                })
                .catch((err) => {});
        } else {
            setOptionsWithAll([]);
        }
    };

    const setOptionsWithAll = (options: AutocompleteOption[]) => {
        setOptions([{ id: '0', text: 'WSZYSTKIE' }, ...options]);
    };

    return (
        <UIFieldListForeignMany
            value={field.value}
            options={options}
            textInputValue={inputValue}
            onChange={onChange}
            onInputChange={onInputChange}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            autocompleteFilterOptions={(options) => {
                const filtred = options.filter((item) => {
                    if (field.value.values.find((item2) => item2.id === item.id)) {
                        return false;
                    }
                    return true;
                });
                return filtred;
            }}
        />
    );
};
