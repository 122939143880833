import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseApiResponse } from 'src/api/types';
import { AppThunk } from 'src/store';
import { AlertsState, AlertsResponse } from './types';
import { API_UNLOGGED_USER_ERROR_CODE } from 'src/constants';

export const initialState: AlertsState = {
    alerts: []
};

const alertsSlice = createSlice({
    name: 'alertData',
    initialState,
    reducers: {
        setAlerts: (state, action: PayloadAction<AlertsResponse>) => {
            state.alerts = action.payload.map((a) => ({ ...a, loaded: false }));
        },
        clearFirst: (state) => {
            state.alerts = state.alerts.filter((item, i) => i && item);
        },
        setFirstAlertLoaded: (state) => {
            state.alerts[0].loaded = true;
        }
    }
});

export const handleAlertsApiResponse =
    (apiResponse: BaseApiResponse<AlertsResponse>): AppThunk =>
    async (dispatch) => {
        if (apiResponse.data && apiResponse.success) {
            dispatch(setAlerts(apiResponse.data));
        } else if (apiResponse.errorCode === API_UNLOGGED_USER_ERROR_CODE) {
            console.error('Error', apiResponse.message);
        } else {
            throw new Error(apiResponse.message);
        }
    };

export const { setAlerts, clearFirst, setFirstAlertLoaded } = alertsSlice.actions;

export default alertsSlice.reducer;
