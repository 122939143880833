import { useAppDispatch } from 'src/store';
import { setPopupConfirmation } from 'src/store/src/popup';
import { generateToken } from 'src/utils';
import { EventBus } from 'src/utils/src/shared/EventBus';

export const useRunConfirmationPopup = () => {
    const dispatch = useAppDispatch();

    const run = async ({
        title,
        message,
        messageInjectHtml
    }: {
        title: string;
        message: string;
        messageInjectHtml?: boolean;
    }) => {
        return new Promise<boolean | null>((res, _) => {
            const closeCallbackEventId = `confirm-close-${generateToken()}`;
            const submitCallbackEventId = `confirm-submit-${generateToken()}`;
            dispatch(
                setPopupConfirmation({
                    closeCallbackEventId,
                    submitCallbackEventId,
                    title,
                    message,
                    messageInjectHtml
                })
            );
            EventBus.$on(closeCallbackEventId, () => {
                EventBus.$off(submitCallbackEventId);
                EventBus.$off(closeCallbackEventId);
                res(false);
            });
            EventBus.$on(submitCallbackEventId, () => {
                EventBus.$off(closeCallbackEventId);
                EventBus.$off(submitCallbackEventId);
                res(true);
            });
        });
    };
    return run;
};
