/**
 * Maps old screen(tpl files) names to component router paths used to replace them
 */
export const phpScreenToComponentUrl: { [key: string]: string } = {
    'ekran_startowy.php': '/start',
    'ekran_obiektow.php': '/lista',
    'ekran_obiektu.php': '/obiekt',
    'ekran_kalendarza_osobistego.php': '/kalendarz',
    'ekran_budzetu.php': '/budzet',
    // 'ekran_masowe_fakturowanie.php': '/masowe-fakturowanie',
    'ekran_masowej_edycji_obiektow.php': '/masowa-edycja-obiektow',
    'ekran_obiekty_do_fakturowania.php': '/obiekty-niezafakturowane',
    'ekran_konfiguracji.php': '/konfiguracja',
    'ekran_ksiazek_korespondencji.php': '/ksiegi-korespondencji',
    'ekran_szablonow_generowanych.php': '/szablony-generowane',
    'ekran_enadawca_tworzenie_zbioru.php': '/tworzenie-zbioru-przesylek',
    'ekran_enadawca_tworzenie_przesylki.php': '/tworzenie-przesylki-z-dokumentow',
    'ekran_esad_tworzenie_paczki.php': '/tworzenie-paczki-pozwow',
    'ekran_esad_tworzenie_paczki_wnioskow.php': '/tworzenie-paczki-wnioskow',
    'ekran_raportow.php': '/raporty',
    'ekran_wiadomosc.php': '/wiadomosc',
    'wysylka_dokumentow.php': '/wysylka-dokumentow',
    'ekran_pomocy_obiektu.php': '/pomoc',
    'ekran_pdf.php': '/instrukcje-pomoc',
    'ekran_video.php': '/video-pomoc',
    'ekran_uprawnien.php': '/uprawnienia',
    'wyciagi_import.php': '/import-wplat',
    'ekran_kodu_do_2fa.php': '/kod-2fa',
    'ekran_tagow_obiektu.php': '/tagi',
    'ekran_zmian_obiektu.php': '/historia-zmian-obiektu',
    'ekran_odczytow_obiektu.php': '/historia-odczytow-obiektu',
    'global_search.php': '/globalne-szukanie'
};
