import { StoreDataForAddressField } from 'src/components/popupFormExtended/dynamicField/fieldAdress/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

/**
 * decode given code to navigation system data
 */
export async function getDataForAddressField(): Promise<BaseApiResponse<StoreDataForAddressField>> {
    return simpleQueryAPIData('api_react/src/popupFormExtended/getDataForAddressField.php', 'GET');
}
