import React from 'react';
import { useTheme } from '@mui/material/styles';
import { DatePickerPanelExtendedProps } from 'src/components/shared/atoms/datePicker/DatePickerPanel';
import { ModeEnum } from 'src/components/shared/atoms/datePicker/DatePickerPanel';
import { monthShort } from 'src/constants/dataPicker';
import styles from './MonthPicker.module.css';

export const MonthPicker = ({ panel, setMode, state, dispatch }: DatePickerPanelExtendedProps) => {
    const theme = useTheme();

    const setMonth = (month: number) => {
        dispatch({
            type: 'SET_PANEL_DATE',
            payload: {
                ...state,
                datePanel: new Date(panel.year, month + panel.changer, 1)
            }
        });
        setMode(ModeEnum.dayMode);
    };

    return (
        <div
            style={{
                color: theme.palette.primary.main
            }}
            className={styles.wrapper}>
            {monthShort.map((el, index) => {
                return (
                    <div
                        key={el}
                        className={styles.box}
                        style={{ color: theme.palette.primary.main }}
                        onClick={() => {
                            setMonth(index);
                        }}>
                        <span
                            className={styles.span}
                            style={{
                                backgroundColor: `${
                                    index === panel.month && theme.palette.primary.main
                                }`,
                                color: `${index === panel.month && '#fff'}`
                            }}>
                            {el}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
