import React, { FC } from 'react';
import { navigateByCode } from 'src/utils';
import { useSafeNavigate } from 'src/hooks';
import { SearchFilterActionProps } from 'src/components/shared/molecules/actionType/searchFilterAction/types';

const SearchFilterAction: FC<SearchFilterActionProps> = ({ children, behaviour }) => {
    const navigate = useSafeNavigate();

    const handleClick = async () => {
        const code = behaviour.data.linkHash;
        if (!code)
            return console.error(
                `SearchFilterAction: brak parametru linkHash w danych zachowania akcji: ${behaviour}`
            );
        const nav_status = await navigateByCode(code, navigate);
        if (!nav_status.success) {
            console.error(
                `SearchFilterAction: api nie może odkodować adresu z kodu(unmaskUrl), wiadomość zwrotna: ${nav_status.message}`
            );
        }
    };

    return <div onClick={() => handleClick()}>{children}</div>;
};

export default SearchFilterAction;
