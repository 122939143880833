import { prepareSubdirectoryPath } from 'src/utils/src/shared/prepareSubdirectoryPath';
import { generateToken } from '../shared/generateToken';

export const generateAddBackUrlTwo = (addBack: string, obiekt: string) => {
    window.name = window.name ?? generateToken();

    const params = new URLSearchParams([
        ['window_name', window.name],
        ['obiekt', obiekt ?? ''],
        ['dodawanie_zwrotne', addBack]
    ]);

    const subdirectoryPath = prepareSubdirectoryPath();
    return `${subdirectoryPath}/dodaj-obiekt?${params.toString()}`;
};
