import React, { FC } from 'react';

import styles from './UIFieldAdress.module.css';
import { streetTypes } from 'src/constants/optionsForSelects';
import FieldValueToAcceptWrapper from '../shared/fieldValueToAcceptWrapper/FieldValueToAcceptWrapper';
import { UIFieldAdressProps } from 'src/components/popupFormExtended/dynamicField/fieldAdress/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import {
    Autocomplete,
    AutocompleteInputChangeReason,
    AutocompleteRenderInputParams
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import { fields } from 'src/constants/subtitles';
import { UiLabel } from 'src/components/shared/atoms/uiInputs/uiLabel/UiLabel';
import { UiAutocompleteInputTwo } from 'src/components/shared/atoms/uiInputs/uiAutocompleteInputTwo/UiAutocompleteInputTwo';

export const UIFieldAdress: FC<UIFieldAdressProps> = (props) => {
    const {
        value,
        onChange,
        onBlur,
        onChangeSelect,
        onAcceptValue,
        onChangeCityAutocomplete,
        onChangePostCodeAutocomplete,
        onInputChangePostCodeAutocomplete,
        onInputChangeCityAutocomplete,
        onClearCityAutocomplete,
        onClearPostCodeAutocomplete,
        onBlurCity,
        onBlurPostCode,
        uiFieldCommonProps,
        voivodships,
        countries,
        uiFieldSharedProps,
        valueCity,
        valuePostCode
    } = props;

    const useStyles = makeStyles({
        root: {
            width: '100%'
        },
        option: {
            minHeight: '30px!important'
        },
        listbox: {
            paddingTop: '0px!important',
            paddingBottom: '0px!important'
        },
        paper: {
            background: '#fff!important',
            marginTop: '1px'
        },
        popper: {
            position: 'absolute',
            zIndex: 0
        }
    });

    const classes = useStyles();

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box1}>
                <FieldValueToAcceptWrapper
                    value={value.typulicy}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.typulicy}
                    onAcceptValue={() => onAcceptValue('typulicy')}>
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.typulicy}
                        options={streetTypes}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChangeSelect(e, 'typulicy')
                        }
                    />
                </FieldValueToAcceptWrapper>
                <FieldValueToAcceptWrapper
                    value={value.ulica}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.ulica}
                    onAcceptValue={() => onAcceptValue('ulica')}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={value.ulica}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'ulica')}
                        onBlur={onBlur}
                        inputProps={uiFieldSharedProps.inputProps}
                    />
                </FieldValueToAcceptWrapper>
                <FieldValueToAcceptWrapper
                    value={value.nrdomu}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.nrdomu}
                    onAcceptValue={() => onAcceptValue('nrdomu')}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={value.nrdomu}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'nrdomu')}
                        onBlur={onBlur}
                    />
                </FieldValueToAcceptWrapper>
                <UiLabel label="/" />
                <FieldValueToAcceptWrapper
                    value={value.nrmieszkania}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.nrmieszkania}
                    onAcceptValue={() => onAcceptValue('nrmieszkania')}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={value.nrmieszkania}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(e, 'nrmieszkania')
                        }
                        onBlur={onBlur}
                    />
                </FieldValueToAcceptWrapper>
            </div>
            <div className={styles.box2}>
                <UiLabel label={fields.fieldAdress.oneLabel} />
                <FieldValueToAcceptWrapper
                    value={value.miasto}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.miasto}
                    onAcceptValue={() => onAcceptValue('miasto')}>
                    <Autocomplete
                        freeSolo
                        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => onBlurCity(e)}
                        onInputChange={(
                            e: React.SyntheticEvent<Element, Event>,
                            value: string,
                            reason: AutocompleteInputChangeReason
                        ) => onInputChangeCityAutocomplete(e, value, reason)}
                        options={props.optionsAutocomplete.miasto || []}
                        value={valueCity}
                        getOptionLabel={(option: any) => {
                            return option?.text;
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.text}
                                </li>
                            );
                        }}
                        clearIcon={
                            valueCity.text ? (
                                <ButtonCross
                                    onClick={onClearCityAutocomplete}
                                    style={{
                                        top: '-4px'
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                        disablePortal={false}
                        onChange={onChangeCityAutocomplete}
                        classes={{
                            root: classes.root,
                            option: classes.option,
                            listbox: classes.listbox,
                            paper: classes.paper,
                            popper: classes.popper
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => {
                            return <UiAutocompleteInputTwo params={params} />;
                        }}
                    />
                </FieldValueToAcceptWrapper>
            </div>
            <div className={styles.box3}>
                <UiLabel label={fields.fieldAdress.twoLabel} />
                <FieldValueToAcceptWrapper
                    value={value.kod}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.kod}
                    onAcceptValue={() => onAcceptValue('kod')}>
                    <Autocomplete
                        freeSolo
                        onInputChange={(
                            e: React.SyntheticEvent<Element, Event>,
                            value: string,
                            reason: AutocompleteInputChangeReason
                        ) => onInputChangePostCodeAutocomplete(e, value, reason)}
                        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                            onBlurPostCode(e)
                        }
                        options={props.optionsAutocomplete.kod || []}
                        value={valuePostCode}
                        getOptionLabel={(option: any) => {
                            return option?.text;
                        }}
                        clearIcon={
                            valuePostCode.code ? (
                                <ButtonCross
                                    onClick={onClearPostCodeAutocomplete}
                                    style={{
                                        top: '-4px'
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.codeFull}>
                                    {option.text}
                                </li>
                            );
                        }}
                        disablePortal={false}
                        onChange={onChangePostCodeAutocomplete}
                        classes={{
                            root: classes.root,
                            option: classes.option,
                            listbox: classes.listbox,
                            paper: classes.paper,
                            popper: classes.popper
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => {
                            return <UiAutocompleteInputTwo params={params} />;
                        }}
                    />
                </FieldValueToAcceptWrapper>
                <UiLabel label={fields.fieldAdress.threeLabel} />
                <FieldValueToAcceptWrapper
                    value={value.poczta}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.poczta}
                    onAcceptValue={() => onAcceptValue('poczta')}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={value.poczta}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'poczta')}
                        onBlur={onBlur}
                    />
                </FieldValueToAcceptWrapper>
            </div>
            <div className={styles.box3}>
                <UiLabel label={fields.fieldAdress.fourLabel} />
                <FieldValueToAcceptWrapper
                    value={value.gmina}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.gmina}
                    onAcceptValue={() => onAcceptValue('gmina')}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={value.gmina}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'gmina')}
                        onBlur={onBlur}
                    />
                </FieldValueToAcceptWrapper>
                <UiLabel label={fields.fieldAdress.fiveLabel} />
                <FieldValueToAcceptWrapper
                    value={value.powiat}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.powiat}
                    onAcceptValue={() => onAcceptValue('powiat')}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={value.powiat}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'powiat')}
                        onBlur={onBlur}
                    />
                </FieldValueToAcceptWrapper>
            </div>
            <div className={styles.box2}>
                <UiLabel label={fields.fieldAdress.sixLevel} />
                <FieldValueToAcceptWrapper
                    value={value.wojewodztwo}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.wojewodztwo}
                    onAcceptValue={() => onAcceptValue('wojewodztwo')}>
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.wojewodztwo}
                        options={voivodships}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChangeSelect(e, 'wojewodztwo')
                        }
                    />
                </FieldValueToAcceptWrapper>
            </div>
            <div className={styles.box2}>
                <UiLabel label={fields.fieldAdress.sevenLabel} />
                <FieldValueToAcceptWrapper
                    value={value.kraj}
                    valueToAccept={uiFieldCommonProps.valueToAccept?.kraj}
                    textToAccept={uiFieldCommonProps.valueToAcceptText?.kraj}
                    onAcceptValue={() => onAcceptValue('kraj')}>
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.kraj}
                        options={countries}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChangeSelect(e, 'kraj')
                        }
                    />
                </FieldValueToAcceptWrapper>
            </div>
        </UiWrapper>
    );
};
