import React, { FC } from 'react';
import { FieldHistoryBlockProps } from './types';
import styles from './FieldHistoryBlock.module.css';
import Tooltip from 'src/components/calendar/atoms/tooltip/Tooltip';
import { ActionObject } from 'src/components/object/atoms/actionObject/ActionObject';

const FieldHistoryBlock: FC<FieldHistoryBlockProps> = ({
    historia_widoczna_z_biezacym,
    wartosci_historyczne,
    historia_widoczna_data,
    historia_widoczny_uzytkownik,
    historia_uwagi_widoczne_w_polu,
    procent,
    domyslna_wartosc_prefix,
    fieldType,
    opcje,
    additional
}) => {
    const getHistoryValue = (value: string) => {
        if (
            fieldType === 'lista' ||
            fieldType === 'lista_cena' ||
            fieldType === 'lista_obca' ||
            fieldType === 'powiazanie_typ' ||
            fieldType === 'plik' ||
            fieldType === 'lista_zalezna'
        ) {
            if (!opcje) {
                console.error('FieldHistoryBlock: field opcje are empty');
                return '';
            }
            return opcje[value];
        }

        if (fieldType === 'tekst_z_przedrostkiem') return `${domyslna_wartosc_prefix}${value}`;

        return value;
    };

    return (
        <div className={styles.wrapper}>
            <p>{historia_widoczna_z_biezacym === 't' ? 'Historia' : 'Poprzednie'} wartości:</p>
            <div className={styles.entriesBlock}>
                {wartosci_historyczne?.map((historyValue, i) => (
                    <div
                        key={i}
                        className={styles.historyValue}
                        style={{
                            lineHeight: `${historyValue?.actions?.length ? '30px' : 'auto'}`
                        }}>
                        {historia_widoczna_data === 't' && historyValue.data}{' '}
                        {getHistoryValue(historyValue.wartosc_pola)}
                        {procent === 't' && '%'}{' '}
                        {historia_widoczny_uzytkownik === 't' && `(${historyValue.uzytkownik})`}
                        {historia_uwagi_widoczne_w_polu === 't' && (
                            <p className={styles.historyMessage}>{historyValue.uwagi}</p>
                        )}
                        {additional &&
                            historyValue?.actions?.map((action, key2) => {
                                return (
                                    <Tooltip title={action.name} key={key2}>
                                        <ActionObject
                                            action={action}
                                            id={''}
                                            additional={{
                                                ...additional
                                            }}
                                        />
                                    </Tooltip>
                                );
                            })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FieldHistoryBlock;
