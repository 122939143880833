import React, { FC } from 'react';

import { FieldFileUnmodifiedProps } from './types';
import { FieldWrapperUnmodified } from 'src/components/popupFormExtended/dynamicField/shared/fieldWrapperUnmodified/FieldWrapperUnmodified';
import FieldFileUnmodifiedVersionRow from './FieldFileUnmodifiedVersionRow';
import { fields } from 'src/constants/subtitles';
import { FileUnmodified } from './fileUnmodified/FileUnmodified';

export const FieldFileUnmodified: FC<FieldFileUnmodifiedProps> = ({
    dynamicFieldT,
    field,
    uiFieldCommonProps
}) => {
    if (!dynamicFieldT.domyslna_wartosc) return <p>Brak pliku</p>;

    return (
        <FieldWrapperUnmodified
            dynamicFieldT={dynamicFieldT}
            field={field}
            isLabel={Boolean(uiFieldCommonProps?.isLabel)}>
            {dynamicFieldT.dane_pliku && <FileUnmodified fileData={dynamicFieldT.dane_pliku} />}
            {dynamicFieldT?.wersje && dynamicFieldT.wersje.length > 0 && (
                <div>
                    <p>{fields.fieldFile.previousVersions}</p>
                    <div>
                        {dynamicFieldT.wersje.map((el) => (
                            <FieldFileUnmodifiedVersionRow
                                key={el.id}
                                date={el.data}
                                id={el.id}
                                user={el.uzytkownik}
                            />
                        ))}
                    </div>
                </div>
            )}
        </FieldWrapperUnmodified>
    );
};
