import React from 'react';

export function useIsShowedOnce() {
    const observerRef = React.useRef<IntersectionObserver | null>(null);
    const ref = React.useRef<HTMLDivElement>(null);
    const [isShowed, setIsShowed] = React.useState(false);

    React.useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsShowed(true);
            }
        });
    }, []);

    React.useEffect(() => {
        if (ref.current) {
            observerRef?.current?.observe(ref.current);
        }

        return () => {
            observerRef?.current?.disconnect();
        };
    }, [ref]);

    return {
        isShowed,
        ref
    };
}
