import React, { FC } from 'react';
import PopupTemplateFirst from 'src/components/shared/organisms/popupTemplateFirst/PopupTemplateFirst';
import styles from './styles.module.css';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import { useConfigVisibilityBlockStartupScreen, usePressKeyWithKeyCode } from 'src/hooks';
import { UiConfigOrderListWrapper } from 'src/components/shared/atoms/uiLayout/uiConfigOrderListWrapper/UiConfigOrderListWrapper';
import { UiConfigOrderList } from 'src/components/shared/atoms/uiLayout/uiConfigOrderList/UiConfigOrderList';

export const PopupContentStartupBlocksConfig: FC<BasePopupContentProps> = ({ popup }) => {
    const { blocksToPresent, updateBlockVisibility, updateBlockOrder, saveChanges } =
        useConfigVisibilityBlockStartupScreen({
            keyPopup: popup.key
        });

    usePressKeyWithKeyCode(saveChanges, 'Enter');

    return (
        <PopupTemplateFirst
            onClick={() => {
                saveChanges();
            }}
            buttonTxt={'Zapisz'}
            title={'Konfiguracja - widok i kolejność'}>
            <div className={styles.wrapper}>
                <UiConfigOrderListWrapper>
                    <UiConfigOrderList
                        data={blocksToPresent}
                        onArrowClick={(item, direction) => updateBlockOrder(item.code, direction)}
                        withCheckboxes={true}
                        onCheckboxClick={(item) => updateBlockVisibility(item.code)}
                    />
                </UiConfigOrderListWrapper>
            </div>
        </PopupTemplateFirst>
    );
};
