import { fetchStartupScreenInitData } from 'src/api/src/startupScreen/fetchStartupScreenInitData';
import { AppThunk } from 'src/store';
import { setInitData } from 'src/store/src/startupScreen/index';
import { ApiError } from 'src/utils';

export const fetchAndLoadInitialDataStartupScreen = (): AppThunk => async (dispatch) => {
    const apiResponse = await fetchStartupScreenInitData();
    if (!apiResponse.success) {
        ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
        throw new Error('fetchAndLoadInitialStartupScreen: not get InitialDataStartupScreen data');
    }
    dispatch(setInitData(apiResponse.data));
};
