import { AppThunk } from 'src/store';
import { getUserSetting } from 'src/api/src/shared/fetchUserSetting';
import { getSystemConfig } from 'src/api/src/shared/fetchSystemConfig';
import { getAlerts } from 'src/api/src/shared/fetchAlerts';
import { handleUserApiDataResponse } from '../general/user/userSlice';
import {
    handleSystemConfigApiResponse,
    setInitialDataLoaded,
    setSpinner,
    closeSpinner
} from '../general/system/systemSlice';
import { handleAlertsApiResponse } from '../general/alerts/alertsSlice';
import { NAVIGATION_START_POINT_SET_KEY } from 'src/constants';
import { setLoggedOffUser } from 'src/store/src/general';
import { setTopPlusActions } from '../menu';
import { fetchTopPlusActions } from 'src/api/src/menu/fetchTopPlusActions';

// Fetches resources and categories sets them in store, then fetches calendar data
export const loadInitialData =
    (): AppThunk<Promise<boolean>> =>
    async (dispatch): Promise<boolean> => {
        try {
            // await logInDevelopment();
            dispatch(setSpinner(true));
            const requests: [
                ReturnType<typeof getUserSetting>,
                ReturnType<typeof getSystemConfig>
                // ReturnType<typeof fetchTopPlusActions>
            ] = [getUserSetting(), getSystemConfig()];
            // await for them to check if none failed
            const [userSettingData, systemConfigData] = await Promise.all(requests);

            // set system config data
            dispatch(handleSystemConfigApiResponse(systemConfigData));
            // set user settings if they are good
            dispatch(handleUserApiDataResponse(userSettingData));

            if (!userSettingData.success) {
                dispatch(setLoggedOffUser());
            } else {
                if (userSettingData.data.isLogged) {
                    await dispatch(loadInitialDataForLoggedUser());
                }
            }

            dispatch(closeSpinner());
            dispatch(setInitialDataLoaded());
        } catch (e: any) {
            dispatch(setLoggedOffUser());
            sessionStorage.removeItem(NAVIGATION_START_POINT_SET_KEY);
            window.location.reload();
            return false;
        }
        return true;
    };

export const loadInitialDataForLoggedUser = (): AppThunk => async (dispatch) => {
    try {
        const requestsSecond: [
            ReturnType<typeof fetchTopPlusActions>,
            ReturnType<typeof getAlerts>
        ] = [fetchTopPlusActions(), getAlerts()];
        const [topPlusActions, alertsData] = await Promise.all(requestsSecond);
        dispatch(setTopPlusActions(topPlusActions));
        dispatch(handleAlertsApiResponse(alertsData));
    } catch (e: any) {
        console.error('Initial data load for logged user failed:', e.message);
    }
};
