import React, { useEffect, useReducer, KeyboardEvent } from 'react';
import { Box, Checkbox } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

import { Error } from 'src/components/shared/atoms/datePicker/Error';
import { DatePickerPanel } from 'src/components/shared/atoms/datePicker/DatePickerPanel';
import { DatePickerManager } from 'src/components/shared/atoms/datePicker/DatePickerManager';
import { reducer, initialState } from 'src/components/shared/atoms/datePicker/reducer';
import { DatePickerInputManager } from 'src/components/shared/atoms/datePicker/DatePickerInputManager';
import { InputDate } from 'src/components/shared/atoms/datePicker/InputDate';
import { InputTime } from 'src/components/shared/atoms/datePicker/InputTime';
import { DateManager } from 'src/utils';
import { UiContainerInputsNormal } from 'src/components/shared/atoms/uiLayout/uiContainerInputsNormal/UiContainerInputsNormal';

import { TimeInputManager } from 'src/components/shared/atoms/datePicker/TimeInputManager';
import Portal from '@mui/material/Portal';
import { dataPicker } from 'src/constants/subtitles';
import styles from './DatePicker.module.css';
import { DynamicFieldType } from 'src/data/popupFormTypes';
import { InputProps } from 'src/components/popupFormExtended/dynamicField/types';
import { CrossClose } from 'src/components/shared/atoms/crossClose/CrossClose';

export type GetDate = {
    dateSelectedNormal: Date | null;
    dateSelectedFormatted: string;
    isToday: boolean;
};

export type GetDateFn = (props: GetDate) => void;

type DatePickerProps = {
    dateInitial?: Date | string | null;
    // dateInitial is date which set all date initial, format date is new Date() or formated ddMMyyyy or dd-MMy-yyy or dd/MM/yyyy but is must be primary day later month and later year (4 sign)
    dateFromOuterToUpdate?: Date | string | null;
    typ?: DynamicFieldType;
    timeInitial?: Date | string;
    isCheckboxToday?: boolean;
    // add checkbox for set today date by user
    checkboxTodayInitialValue?: boolean;
    // now checkboxToday is no button for set today value, but input containing data, so system should load his default value
    numberPanels?: 'one' | 'three';
    // set number of panel mouth - default is one
    getDate: GetDateFn;
    // get selected date, we have two values 1.dateSelectedNormal: Date | null 2.dateSelectedFormatted: string dd-MMy-yyy
    // funcion is executed when user click on day in daypicker or click checkbox today or type in input immediately corecct date
    labelDate?: string;
    isIcon?: 'true' | 'false';
    isInputTime?: boolean;
    sxInputDate?: SxProps<Theme>;
    sxInputTime?: SxProps<Theme>;
    sxUiContainerInputsNormal?: SxProps<Theme>;
    sxContainerPanels?: SxProps<Theme>;
    signDivider?: string;
    portalEnabled?: boolean;
    isDisabledLabel?: boolean;
    clearButton?: boolean;
    disabled?: boolean;
    archived?: boolean;
    isError?: boolean;
    inputProps?: InputProps;
};

export const DatePicker = ({
    dateInitial,
    timeInitial,
    numberPanels,
    isCheckboxToday,
    getDate,
    labelDate,
    isIcon = 'true',
    isInputTime,
    sxInputDate,
    sxInputTime,
    sxUiContainerInputsNormal,
    sxContainerPanels,
    portalEnabled = false,
    signDivider,
    isDisabledLabel,
    checkboxTodayInitialValue,
    clearButton = false,
    disabled,
    archived,
    isError,
    dateFromOuterToUpdate,
    typ,
    inputProps
}: DatePickerProps) => {
    const theme = useTheme();
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        isCheckboxToday: isCheckboxToday ? isCheckboxToday : initialState.isCheckboxToday,
        isInputTime: isInputTime ? isInputTime : initialState.isInputTime,
        isToday: !!checkboxTodayInitialValue,
        signDivider: signDivider ? signDivider : initialState.signDivider,
        datePanel: dateInitial
            ? DateManager.formatedDateToNewDate(dateInitial)
            : initialState.datePanel,
        dateSelected: dateInitial
            ? DateManager.formatedDateToNewDate(dateInitial)
            : initialState.dateSelected,
        dateInputVisible: dateInitial
            ? format(new Date(DateManager.formatedDateToNewDate(dateInitial)), 'ddMMyyyy')
            : '',
        timeVisible: timeInitial
            ? DateManager.setTimeString(timeInitial)
            : initialState.timeVisible,
        minutesSelected: timeInitial
            ? DateManager.setMinutesString(timeInitial)
            : initialState.minutesSelected,
        hoursSelected: timeInitial
            ? DateManager.setHourString(timeInitial)
            : initialState.hoursSelected
    });

    useEffect(() => {
        // to load in datepicker proper today date

        if (state.isToday || (state.dateSelected && DateManager.isToday(state.dateSelected))) {
            new DatePickerInputManager({
                // used false, because DatePickerInputManager should have false to set toDay as true idk why
                value: 'false',
                dispatch,
                state,
                getDate
            }).handleCheckbox();
        }
    }, []);

    useEffect(() => {
        // to update date when is field dependent form another field
        if (dateFromOuterToUpdate instanceof Date) {
            switch (typ) {
                case 'data':
                    {
                        const timestampOuter = dateFromOuterToUpdate.setHours(0, 0, 0, 0);
                        const timestampWew = state.dateSelected?.setHours(0, 0, 0, 0);
                        if (timestampOuter !== timestampWew) {
                            dispatch({
                                type: 'SET_SELECTED_DATE',
                                payload: {
                                    datePanel: dateFromOuterToUpdate
                                        ? dateFromOuterToUpdate
                                        : initialState.datePanel,
                                    dateSelected: dateFromOuterToUpdate
                                        ? dateFromOuterToUpdate
                                        : initialState.dateSelected,
                                    dateInputVisible: dateFromOuterToUpdate
                                        ? format(new Date(dateFromOuterToUpdate), 'ddMMyyyy')
                                        : '',
                                    isToday: DateManager.isToday(dateFromOuterToUpdate),
                                    timeVisible: dateFromOuterToUpdate
                                        ? DateManager.setTimeString(dateFromOuterToUpdate)
                                        : initialState.timeVisible,
                                    minutesSelected: dateFromOuterToUpdate
                                        ? DateManager.setMinutesString(dateFromOuterToUpdate)
                                        : initialState.minutesSelected,
                                    hoursSelected: dateFromOuterToUpdate
                                        ? DateManager.setHourString(dateFromOuterToUpdate)
                                        : initialState.hoursSelected
                                }
                            });
                        }
                    }
                    break;
                default:
                    {
                        const timestampOuter = dateFromOuterToUpdate.getTime();
                        const timestampWew = state.dateSelected?.getTime();
                        if (timestampOuter !== timestampWew) {
                            dispatch({
                                type: 'SET_SELECTED_DATE',
                                payload: {
                                    datePanel: dateFromOuterToUpdate
                                        ? dateFromOuterToUpdate
                                        : initialState.datePanel,
                                    dateSelected: dateFromOuterToUpdate
                                        ? dateFromOuterToUpdate
                                        : initialState.dateSelected,
                                    dateInputVisible: dateFromOuterToUpdate
                                        ? format(new Date(dateFromOuterToUpdate), 'ddMMyyyy')
                                        : '',
                                    isToday: DateManager.isToday(dateFromOuterToUpdate),
                                    timeVisible: dateFromOuterToUpdate
                                        ? DateManager.setTimeString(dateFromOuterToUpdate)
                                        : initialState.timeVisible,
                                    minutesSelected: dateFromOuterToUpdate
                                        ? DateManager.setMinutesString(dateFromOuterToUpdate)
                                        : initialState.minutesSelected,
                                    hoursSelected: dateFromOuterToUpdate
                                        ? DateManager.setHourString(dateFromOuterToUpdate)
                                        : initialState.hoursSelected
                                }
                            });
                        }
                    }
                    break;
            }
        } else if (dateFromOuterToUpdate === undefined || dateFromOuterToUpdate === null) {
            dispatch({
                type: 'RESET'
            });
        }
    }, [dateFromOuterToUpdate]);

    const handleClick = () => {
        if (disabled) return;
        dispatch({
            type: 'TOGGLE_PICKER'
        });
    };
    const handleOnChangeInputDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        new DatePickerInputManager({
            value: e.target.value,
            dispatch,
            state,
            getDate
        }).handleInput();
    };

    const handleOnBlurInputDate = (e: React.FocusEvent<HTMLInputElement>) => {
        new DatePickerInputManager({
            value: e.target.value,
            dispatch,
            state,
            getDate
        }).handleOnBlurInput();
    };

    const handleKeyEnter = (e: any) => {
        if (disabled) return;
        if (e.key === 'Enter' || e.key === 'Tab') {
            new DatePickerInputManager({
                value: e.target.value,
                dispatch,
                state,
                getDate
            }).handleInput(true);
        }
    };

    const handleReset = () => {
        if (disabled) return;
        new DatePickerInputManager({
            value: '',
            dispatch,
            state,
            getDate
        }).handleReset();
    };

    const handleKeyCheckbox = (e: KeyboardEvent<any>) => {
        if (disabled) return;
        if (!state.isCheckboxToday) return;
        if (e.key === 'Enter') {
            new DatePickerInputManager({
                value: 'false',
                dispatch,
                state,
                getDate
            }).handleCheckbox();
        }
    };

    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        if (!state.isCheckboxToday) return;
        new DatePickerInputManager({
            value: e.target.value,
            dispatch,
            state,
            getDate
        }).handleCheckbox();
    };

    const handleOnChangeInputTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        new TimeInputManager({
            value: e.target.value,
            dispatch,
            state,
            getDate
        }).handleInput();
    };

    const handleClickAway = () => {
        dispatch({
            type: 'CLOSE_PICKER_WITH_RESET'
        });
    };

    const closePanels = () => {
        dispatch({
            type: 'CLOSE_PICKER'
        });
    };
    const data = new DatePickerManager({
        state,
        numberPanels
    }).getData();

    const anchorRef = React.useRef<HTMLDivElement | null>(null);

    const sxPositionPortal = React.useMemo(() => {
        return () => {
            return portalEnabled
                ? {
                      left: anchorRef?.current
                          ? `${anchorRef.current.getBoundingClientRect().left - 3}px`
                          : '30px',
                      top: anchorRef?.current
                          ? `${anchorRef.current.getBoundingClientRect().top + 30}px`
                          : '30px',
                      zIndex: '1001'
                  }
                : { zIndex: '100' };
        };
    }, [portalEnabled]);
    return (
        <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
            <div ref={anchorRef} className={styles.wrapper}>
                <UiContainerInputsNormal
                    sx={{
                        gridTemplateColumns: `${state.isInputTime ? '50% 50%' : '100%'}`,
                        ...sxUiContainerInputsNormal
                    }}>
                    <div className={styles.box}>
                        <InputDate
                            label={isDisabledLabel ? '' : labelDate}
                            type={'text'}
                            value={state.dateInputVisible}
                            sxinputdate={sxInputDate}
                            isicon={isIcon}
                            maskChar={''}
                            mask={`99${state.signDivider}99${state.signDivider}9999`}
                            placeholder={`dd${state.signDivider}mm${state.signDivider}yyyy`}
                            onChange={handleOnChangeInputDate}
                            onBlur={handleOnBlurInputDate}
                            onClick={handleClick}
                            onKeyDown={handleKeyEnter}
                            handleReset={handleReset}
                            clearButton={clearButton}
                            disabled={disabled}
                            archived={archived}
                            isError={isError}
                            inputProps={inputProps}
                        />
                        {state.isCheckboxToday && (
                            <div className={styles.box2}>
                                <div className={styles.box3}>
                                    <p
                                        style={{
                                            color: theme.palette.text.label
                                        }}>
                                        {dataPicker.checkbox.label}
                                    </p>
                                    <Checkbox
                                        onChange={handleChangeCheckbox}
                                        onKeyDown={handleKeyCheckbox}
                                        value={state.isToday}
                                        checked={state.isToday}
                                        sx={{
                                            borderRadius: '2px',
                                            border: '1px',
                                            padding: '0',
                                            marginLeft: '2px'
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {state.isErrorDateOpen && <Error state={state} dispatch={dispatch} />}
                    </div>
                    {state.isInputTime && (
                        <div className={styles.wrapper}>
                            <InputTime
                                maskChar={''}
                                type={'text'}
                                label={isDisabledLabel ? '' : 'Godzina'}
                                value={state.timeVisible}
                                sxinputtime={sxInputTime}
                                placeholder="gg:mm"
                                onChange={handleOnChangeInputTime}
                            />
                            {state.isErrorTimeOpen && <Error state={state} dispatch={dispatch} />}
                        </div>
                    )}
                </UiContainerInputsNormal>
                {state.isPickerOpen && portalEnabled && (
                    <Portal>
                        <Box
                            sx={{
                                position: 'absolute',
                                background: 'white',
                                boxShadow:
                                    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                                ...sxPositionPortal(),
                                ...sxContainerPanels
                            }}>
                            <div
                                style={{
                                    display: 'flex'
                                }}>
                                {data.panel.map((item, i) => (
                                    <div
                                        key={i}
                                        style={{
                                            borderTop: `1px solid ${theme.palette.primary.main}`,
                                            borderBottom: `1px solid ${theme.palette.primary.main}`,
                                            borderLeft:
                                                i === 0
                                                    ? ` 1px solid ${theme.palette.primary.main}`
                                                    : '',
                                            borderRight: `1px solid ${theme.palette.primary.main}`,
                                            paddingRight: '20px',
                                            paddingLeft: '20px',
                                            paddingBottom: '20px',
                                            paddingTop: '10px',
                                            position: 'relative'
                                        }}>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: '1px',
                                                top: '1px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={closePanels}>
                                            <CrossClose width={3} height={14} />
                                        </div>
                                        <DatePickerPanel
                                            panel={item}
                                            years={data.years}
                                            dispatch={dispatch}
                                            state={state}
                                            getDate={getDate}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Box>
                    </Portal>
                )}
                {state.isPickerOpen && !portalEnabled && (
                    <Box
                        sx={{
                            position: 'absolute',
                            background: 'white',
                            boxShadow:
                                '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                            ...sxPositionPortal(),
                            ...sxContainerPanels
                        }}>
                        <div
                            style={{
                                display: 'flex'
                            }}>
                            {data.panel.map((item, i) => (
                                <div
                                    key={i}
                                    style={{
                                        borderTop: `1px solid ${theme.palette.primary.main}`,
                                        borderBottom: `1px solid ${theme.palette.primary.main}`,
                                        borderLeft:
                                            i === 0
                                                ? ` 1px solid ${theme.palette.primary.main}`
                                                : '',
                                        borderRight: `1px solid ${theme.palette.primary.main}`,
                                        paddingRight: '20px',
                                        paddingLeft: '20px',
                                        paddingBottom: '20px',
                                        paddingTop: '10px',
                                        position: 'relative'
                                    }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '1px',
                                            top: '1px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={closePanels}>
                                        <CrossClose width={3} height={14} />
                                    </div>
                                    <DatePickerPanel
                                        panel={item}
                                        years={data.years}
                                        dispatch={dispatch}
                                        state={state}
                                        getDate={getDate}
                                    />
                                </div>
                            ))}
                        </div>
                    </Box>
                )}
            </div>
        </ClickAwayListener>
    );
};
