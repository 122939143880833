import { getVat } from 'src/utils';

import { FieldPriceBasicTypeValue } from 'src/data/fieldsFormType';

export type FieldPriceChangeType = 'vat' | 'netto' | 'brutto' | 'currency';

export class FieldPriceManager {
    newValue: FieldPriceBasicTypeValue;
    changeType: FieldPriceChangeType;
    value: string;
    vatOptional?: string;
    constructor({
        oldValue,
        changeType,
        value,
        vatOptional
    }: {
        oldValue: FieldPriceBasicTypeValue;
        changeType: FieldPriceChangeType;
        value: string;
        vatOptional?: string;
    }) {
        this.newValue = oldValue;
        this.changeType = changeType;
        this.value = value;
        this.vatOptional = vatOptional;
        this.checkIfOptionalVat();
    }

    calc() {
        switch (this.changeType) {
            case 'netto':
                this.newValue.netto = this.value;
                this.calcBrutto();
                break;
            case 'currency':
                this.newValue.waluta = this.value;
                break;
            case 'vat':
                this.newValue.vat = this.value;
                this.calcBrutto();
                break;
            case 'brutto':
                this.newValue.brutto = this.value;
                this.calcNetto();
                break;
            default:
                break;
        }
        return this.newValue;
    }

    private checkIfOptionalVat() {
        if (this.vatOptional) {
            this.newValue.vat = this.vatOptional;
        }
    }

    private calcBrutto() {
        const tempValue = this.newValue.netto === '-' ? '0' : this.newValue.netto;
        const newValue = (Number(tempValue) * getVat(this.newValue.vat)).toFixed(2);
        this.newValue.brutto = String(newValue);
    }

    private calcNetto() {
        const tempValue = this.newValue.brutto === '-' ? '0' : this.newValue.brutto;
        const newValue = (Number(tempValue) / getVat(this.newValue.vat)).toFixed(4);
        this.newValue.netto = String(newValue);
    }
}
