const JS_TIMESTAMP_MINUTE = 60000;
const JS_TIMESTAMP_HOUR = 3600000;
const JS_TIMESTAMP_DAY = 86400000;
const JS_TIMESTAMP_WEEK = 604800000;
const JS_TIMESTAMP_MONTH = 2592000000;
const JS_TIMESTAMP_YEAR = 31536000000;

export {
    JS_TIMESTAMP_DAY,
    JS_TIMESTAMP_MINUTE,
    JS_TIMESTAMP_HOUR,
    JS_TIMESTAMP_WEEK,
    JS_TIMESTAMP_MONTH,
    JS_TIMESTAMP_YEAR
};
