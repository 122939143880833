import React from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const Calendar = React.lazy(
    () => import(/* webpackChunkName: "Calendar" */ '../../../calendar/pages/calendar/Calendar')
);

const CalendarTop = () => {
    useRunAllHooksOnEachRenderReactPage('Kalendarz');

    return (
        <>
            <React.Suspense fallback>
                <Calendar />
            </React.Suspense>
        </>
    );
};

export default CalendarTop;
