import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Typography, Box, Button } from '@mui/material';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { TransformationManager } from 'src/store/src/listScreen/transformations/TransformationManager';
import { ApiActionBehavior, LinkBehavior } from 'src/data/types';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import { TemplateMainManager } from 'src/utils/src/shared/TemplateMainManager';
import { useSearchParam } from 'src/hooks';

export const PopupContentConfirmLink: FC<BasePopupContentProps> = ({ popup }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const screen = useSearchParam('obiekt');
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const totalIds = useTypedSelector((state) => state.listScreenTableData.totalIds);
    const objectSlice = useTypedSelector((state) => state.object);
    const behaviour = popup.action?.behaviour as LinkBehavior;

    const { params, locationStateFlag } = new TemplateMainManager({
        rowData,
        rowId: popup.objectId[0],
        transformationState,
        actionBehavior: popup.action?.behaviour as ApiActionBehavior,
        action: popup.action ? popup.action : undefined,
        objectSlice,
        screen,
        sourceOfTrigger: popup.sourceOfTrigger,
        additional: popup.objectAdditional,
        totalIds
    }).getData();

    const locationState = new TransformationManager({
        transformationState
    }).getDataLocationState(locationStateFlag);

    return (
        <PopupTemplateThird title={globalPopup.contentConfirmation.title}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        paddingBottom: '10px'
                    }}>
                    {behaviour?.data.confirmText}
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateRows: 'auto',
                        gridTemplateColumns: 'auto auto',
                        gap: '30px',
                        justifyContent: 'center',
                        paddingTop: '10px'
                    }}>
                    <LegacyCompatibleLink
                        url={behaviour?.data?.url}
                        queryParams={new URLSearchParams(params)}
                        newWindow={behaviour?.data?.newWindow}
                        closePopup={() => dispatch(closePopup(popup.key))}
                        locationState={locationState}>
                        <Button
                            variant="contained"
                            sx={{
                                color: (theme) => theme.palette.background.default,
                                padding: '6px 40px',
                                cursor: 'pointer'
                            }}>
                            <Typography>{globalPopup.contentConfirmation.button.yes}</Typography>
                        </Button>
                    </LegacyCompatibleLink>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={() => dispatch(closePopup(popup.key))}>
                        <Typography>{globalPopup.contentConfirmation.button.no}</Typography>
                    </Button>
                </Box>
            </Box>
        </PopupTemplateThird>
    );
};
