import { RowData, ColumnDataEntries, RowDataId } from './types';
import { SystemAction } from 'src/data/types';
import { TemplateItem } from 'src/utils/src/shared/TemplateItem';
import { TemplateMainItem } from 'src/utils/src/shared/TemplateMainItem';
import { TemplateManagerDolar } from 'src/utils/src/shared/TemplateManagerDolar ';

// TableDataManager jest podstawowym obiektem tworzącym, przetwarzającym, pobierającym dane ze slice listScreenTableData w redux
// TableDataManager is basic object create, convert, load data from  slice listScreenTableData in redux

export type ListItem = {
    screen: string;
    objectId: string;
};

export type ListItemWithFields = {
    screen: string;
    objectId: string;
    fields: {
        [key: string]: any;
    };
};

interface TableDataManagerProps {
    rowData?: RowData[];
    columnData?: ColumnDataEntries;
    screen?: string | null;
    rowId?: RowDataId | null;
    action?: SystemAction | null;
    totalIds?: string[];
}

export class TableDataManager {
    rowData: RowData[];
    columnData: ColumnDataEntries;
    selectedRows: RowData[];
    selectedRowsId: RowDataId[];
    allRowsId: RowDataId[];
    managedRowsId: RowDataId[];
    screen: string;
    rowId: RowDataId | null;
    action: SystemAction | null;
    totalIds: string[];
    constructor({ rowData, columnData, screen, action, rowId, totalIds }: TableDataManagerProps) {
        this.rowData = rowData ? rowData : [];
        this.totalIds = totalIds ? totalIds : [];
        this.columnData = columnData ? columnData : {};
        this.action = action ? action : null;
        this.rowId = rowId ? rowId : null;
        this.screen = screen ? screen : '';
        this.managedRowsId = [];
        this.allRowsId = [];
        this.selectedRowsId = [];
        this.selectedRows = [];
    }

    static setChangedRowSelected({ rowData, rowsData }: { rowData: RowData; rowsData: RowData[] }) {
        return rowsData.map((item) => {
            if (item.id === rowData?.id) {
                return {
                    ...item,
                    selected: !item.selected
                };
            }
            return item;
        });
    }

    static checkIfAnyoneRowNotSelected({ rowsData }: { rowsData: RowData[] }) {
        return rowsData.some((row) => !row.selected);
    }

    static setChangedRowsSelected({ rowsData }: { rowsData: RowData[] }) {
        const everySelected = rowsData.every((item) => item.selected);
        if (everySelected) {
            return rowsData.map((item) => {
                return {
                    ...item,
                    selected: false
                };
            });
        }
        const anyoneSelected = rowsData.find((item) => item.selected);
        if (anyoneSelected) {
            return rowsData.map((item) => {
                return {
                    ...item,
                    selected: true
                };
            });
        }
        return rowsData.map((item) => {
            return {
                ...item,
                selected: !item.selected
            };
        });
    }

    static checkIsAnyOneRowSelected({ rowsData }: { rowsData: RowData[] }) {
        let isAnyOneRowSelected = false;
        rowsData.forEach((item) => {
            if (item.selected) {
                isAnyOneRowSelected = true;
            }
        });
        return isAnyOneRowSelected;
    }

    getTextOrRawValue({ key, type }: { key: string; type: 'text' | 'rawValue' }) {
        const rowFound = this.rowData.find((item) => item.id === this.rowId);
        if (rowFound) {
            const rawValue = rowFound?.cellValues[key]?.map((item) => item[type]);
            return rawValue;
        }
        return null;
    }

    findRowBaseOnId(id: RowDataId | null) {
        return this.rowData.find((item) => item.id === id);
    }

    getSelectedRows() {
        if (this.rowId) {
            const rowFound = this.findRowBaseOnId(this.rowId);
            if (rowFound) {
                this.selectedRows.push(rowFound);
                return [rowFound];
            }
            return [];
        }
        this.selectedRows = this.rowData.filter((item) => item.selected);
        return this.selectedRows;
    }

    getAllRowsId() {
        this.allRowsId = this.rowData.map((row) => row.id);
        return this.allRowsId;
    }

    getRowsIdBaseOnTemplate2(item: TemplateMainItem) {
        if (item.option === 'all') {
            this.managedRowsId = this.totalIds;
        } else if (item.option === 'allVisible') {
            this.managedRowsId = this.getAllRowsId();
        } else {
            this.managedRowsId = this.getFilteredRowsId();
        }
        return this.managedRowsId;
    }

    getRawValues2(item: TemplateMainItem) {
        const arr: any = [];
        this.getRowsIdBaseOnTemplate2(item);
        this.managedRowsId.forEach((managedRowId) => {
            const rowFind = this.findRowBaseOnId(managedRowId);
            arr.push(
                rowFind?.cellValues[item.contentClearWithoutFlag]?.map((item) => item.rawValue)
            );
        });
        return arr;
    }

    getRawValue(item: TemplateItem) {
        const rowFound = this.findRowBaseOnId(this.rowId);
        if (rowFound) {
            return rowFound?.cellValues[item.contentClearWIthoutFlag]?.map((item) => item.rawValue);
        }
        return null;
    }

    getFilteredRowsId(): RowDataId[] {
        const rows = this.getSelectedRows();
        const filteredRows = TableDataManager.filterRowsBaseOnDisplayConditions(rows, this.action);
        return TableDataManager.mapRowsToRowsId(filteredRows);
    }

    static filterRowsBaseOnDisplayConditions(rowData: RowData[], action: SystemAction | null) {
        return rowData.filter((row) => !TableDataManager.checkIsHidden({ row, rowData, action }));
    }

    static mapRowsToRowsId(rowsData: RowData[]) {
        return rowsData.map((rowData) => rowData.id);
    }

    getRawValueWithIdAndColumnCode() {
        const rowFind = this.findRowBaseOnId(this.rowId);
        const codeColumn = this.action?.behaviour?.data?.data?.fileFieldCode;
        if (rowFind && codeColumn) {
            const rawValue = rowFind.cellValues?.[codeColumn][0]?.rawValue;
            const text = rowFind.cellValues?.[codeColumn][0]?.text;
            if (rawValue && text) {
                if (Array.isArray(rawValue) && Array.isArray(text)) {
                    return {
                        rawValue: rawValue[0],
                        text: text[0]
                    };
                }
                return {
                    rawValue,
                    text
                };
            }
            return null;
        }
        return null;
    }

    getRaw(id: RowDataId | null | undefined) {
        return this.rowData.find((item) => item.id === id);
    }

    checkActionConditionsForOneRow() {
        const conditions: {
            isHidden: boolean;
            isSetColor: boolean;
            color: undefined | string;
        } = {
            isHidden: false,
            isSetColor: false,
            color: undefined
        };
        const row = this.rowData.find((item) => item.id === this.rowId);

        conditions.isHidden = TableDataManager.checkIsHidden({
            row,
            rowData: this.rowData,
            action: this.action
        });

        if (this.action?.colorConditions) {
            this.action?.colorConditions.forEach((item) => {
                const rowValues = row?.cellValues[item.fieldCode]?.map((item) => item.rawValue);
                rowValues?.forEach((item2) => {
                    if (!conditions.isSetColor) {
                        if (item.fieldValues.findIndex((fv) => fv == item2) !== -1) {
                            conditions.color = item.color;
                            conditions.isSetColor = true;
                        }
                    }
                });
            });
        }

        if (this.action?.defaultColor && !conditions.isSetColor) {
            conditions.isSetColor = true;
            conditions.color = this.action?.defaultColor;
        }

        return { ...conditions };
    }

    static checkIsHidden({
        row,
        rowData,
        action
    }: {
        row: RowData | undefined;
        action: SystemAction | null;
        rowData: RowData[];
    }) {
        let isHidden = false;
        if (action?.displayConditions) {
            const columnCode = action?.displayConditions?.fieldCode
                ? action?.displayConditions?.fieldCode
                : '';
            const fieldValues = new TemplateManagerDolar({
                rowData: rowData,
                rowId: row?.id,
                templateStrArr: action?.displayConditions?.fieldValues,
                typeSearch: 'rawValue'
            }).getMany();

            // used !! to convert boolean | undefined to boolean
            isHidden = TableDataManager.calcIsHidden(
                row,
                columnCode,
                fieldValues,
                !!action?.displayConditions?.reverse
            );
        }
        return isHidden;
    }

    static calcIsHidden(
        row: RowData | undefined,
        columnCode: string,
        fieldValues: string[],
        reverse: boolean
    ): boolean {
        const valuesUnderSearchColumn = row?.cellValues[columnCode];
        // make for future in case of somebody would like to have display condition checking if is no object somewhere
        if (!valuesUnderSearchColumn) {
            console.info(
                `TableDataManager: value in row ${row?.id} for column ${columnCode} is null, display not changed`
            );
            // === works like xnor
            return reverse === fieldValues.includes('null');
        }

        // normalize rawValue to be or rawValue from single cell rawValue, or rawValue array
        let normalizedRawValue: any | any[];
        if (valuesUnderSearchColumn.length > 1) {
            console.warn(
                `TableDataManager: Cannot chcek displayCondition, because column ${columnCode} has many values ${row?.cellValues[columnCode]}, behavoiur at this situation is not clear`
            );
            // when row has many cells, make flat map with rawValues
            normalizedRawValue = valuesUnderSearchColumn.flatMap((item) => item.rawValue);
        } else {
            normalizedRawValue = valuesUnderSearchColumn[0].rawValue;
        }
        // if rawValue is array make json with it, so api can prepare example of field Value to check with array
        const rawValueToCheck = Array.isArray(normalizedRawValue)
            ? JSON.stringify(normalizedRawValue)
            : normalizedRawValue;

        // hid if meet conditions considering reverse argument
        if (reverse) {
            for (const item of fieldValues) {
                if (item === rawValueToCheck) {
                    return true;
                }
            }
        } else {
            if (!fieldValues.includes(rawValueToCheck)) {
                return true;
            }
        }
        return false;
    }
}
