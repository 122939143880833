import React, { FC } from 'react';
import styles from './MainMenuView.module.css';
import { MENU_HEADER_HEIGHT } from 'src/constants';
import { NavigationMenuScrollable } from 'src/components/menu/organisms/navigationMenu/NavigationMenuScrollable';
import TopMenu from 'src/components/menu/organisms/topMenu/TopMenu';
import 'src/styles/scroll.css';

const MainMenuView: FC = ({ children }) => {
    return (
        <div className={styles.topContainer}>
            <TopMenu />
            <div className={styles.sectionBottom}>
                {/* left navigation menu */}
                <NavigationMenuScrollable />
                {/* proper children block */}
                <div
                    style={{
                        overflow: 'auto',
                        height: `calc(100vh - ${MENU_HEADER_HEIGHT})`,
                        width: '100%'
                    }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default MainMenuView;
