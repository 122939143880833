import React, { FC } from 'react';

import { UiInputNormal } from 'src/components/shared/atoms/uiInputs/uiInputNormal/UiInputNormal';
import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';

export const DynamicRangeNumberSearch: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const value = listSearchFilters[searchField.searchCode]?.fieldFilter?.value;
    const value1 = value?.[0] ?? '';
    const value2 = value?.[1] ?? '';
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '20px' }}>
            <UiInputNormal
                value={value1}
                type={'number'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchFilter({
                        type: 'SET_VALUE_SEVEN',
                        payload: {
                            code: searchField.searchCode,
                            index: 0,
                            value: Number(e.target.value)
                        }
                    });
                }}
                typeIcon="component"
                isicon={value1 === 0 || value1 ? 'true' : 'false'}
                iconComponent={
                    <ButtonCross
                        onClick={() => {
                            dispatchFilter({
                                type: 'SET_VALUE_SEVEN',
                                payload: {
                                    code: searchField.searchCode,
                                    index: 0,
                                    value: null
                                }
                            });
                        }}
                        style={{ top: '5px' }}
                    />
                }
            />
            <UiInputNormal
                value={value2}
                type={'number'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatchFilter({
                        type: 'SET_VALUE_SEVEN',
                        payload: {
                            code: searchField.searchCode,
                            index: 1,
                            value: Number(e.target.value)
                        }
                    });
                }}
                typeIcon="component"
                isicon={value2 === 0 || value2 ? 'true' : 'false'}
                iconComponent={
                    <ButtonCross
                        onClick={() => {
                            dispatchFilter({
                                type: 'SET_VALUE_SEVEN',
                                payload: {
                                    code: searchField.searchCode,
                                    index: 1,
                                    value: null
                                }
                            });
                        }}
                        style={{ top: '5px' }}
                    />
                }
            />
        </div>
    );
};
