import { DynamicFieldT } from 'src/data/popupFormTypes';
import { MassEditObjectsRow } from 'src/store/src/massEditObjectsScreen/massEditObjects/types';

export function getValueFromFieldApiValues({
    dynamicFieldT,
    row,
    kod
}: {
    dynamicFieldT: DynamicFieldT;
    row: MassEditObjectsRow;
    kod: string;
}) {
    if (dynamicFieldT.obce === '1') {
        return row.fieldApiValues[kod];
    }
    return row.fieldApiValues[kod];
}
