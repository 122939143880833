import React, { FC } from 'react';
import Box from '@mui/material/Box';

import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import { UiInputNormal } from 'src/components/shared/atoms/uiInputs/uiInputNormal/UiInputNormal';

export const DynamicNumberFromZeroSearch: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const emptyDefault = [null, null, null, null, null, null];

    const value: number[] | null[] = listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        ? listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        : emptyDefault;

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                gap: '10px'
            }}>
            {value.map((item, i) => {
                const value = item ?? '';
                return (
                    <Box
                        key={i}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end'
                        }}>
                        <UiInputNormal
                            value={value}
                            type={'number'}
                            label={(i === 2 ? 'Rozliczono' : '') || (i === 4 ? 'Pozostało' : '')}
                            onChange={(event: React.SyntheticEvent) => {
                                const value = (event.target as HTMLSelectElement).value
                                    ? Number((event.target as HTMLSelectElement).value)
                                    : null;
                                dispatchFilter({
                                    type: 'SET_VALUE_NINE',
                                    payload: {
                                        code: searchField.searchCode,
                                        index: i,
                                        value: value
                                    }
                                });
                            }}
                            typeIcon="component"
                            isicon={value === 0 || value ? 'true' : 'false'}
                            iconComponent={
                                <ButtonCross
                                    onClick={() => {
                                        dispatchFilter({
                                            type: 'SET_VALUE_NINE',
                                            payload: {
                                                index: i,
                                                code: searchField.searchCode,
                                                value: null
                                            }
                                        });
                                    }}
                                    style={{ top: '5px' }}
                                />
                            }
                        />
                    </Box>
                );
            })}
        </Box>
    );
};
