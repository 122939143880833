import React, { FC } from 'react';
import { PermissionOptionsLevelProps } from './types';
import PermissionsLevelHeader from '../../molecules/permissionsLevelHeader/PermissionsLevelHeader';
import PermissionsField from '../../molecules/permissionsField/PermissionsField';
import PermissionsItemHeader from '../../molecules/permissionsItemHeader/PermissionsItemHeader';
import { PERIMISSION_LEVEL_INDENT } from 'src/constants/permissionsScreen';

const PermissionOptionsLevel: FC<PermissionOptionsLevelProps> = ({
    options,
    screenId,
    unfoldedState,
    toggleFolding,
    ...props
}) => {
    const foldingKey = `options_${screenId}`;

    return (
        <div style={{ marginLeft: PERIMISSION_LEVEL_INDENT }}>
            <PermissionsLevelHeader
                folded={unfoldedState[foldingKey]}
                onClick={() => toggleFolding(foldingKey)}
                title="Opcje specjalne"
            />
            {unfoldedState[foldingKey] &&
                options.map((option) => (
                    <ul key={option.id}>
                        <PermissionsItemHeader text={option.name} />
                        <PermissionsField item={option} type="visible" level="option" {...props} />
                    </ul>
                ))}
        </div>
    );
};

export default PermissionOptionsLevel;
