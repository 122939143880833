import React, { FC } from 'react';

import styles from './UiWrapperViewTop.module.css';
import { UiWrapperViewTopProps } from 'src/components/shared/atoms/uiLayout/uiWrapperViewTop/types';

export const UiWrapperViewTop: FC<UiWrapperViewTopProps> = ({ children, style }) => {
    return (
        <div
            className={styles.wrapper}
            style={{
                ...style
            }}>
            {children}
        </div>
    );
};
