import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { SmallMessageProps } from './types';
import styles from './styles.module.css';

export const SmallMessage: FC<SmallMessageProps> = ({ text = 'Wykonano', style, isDisplayed }) => {
    const theme = useTheme();

    if (isDisplayed) {
        return (
            <div
                className={styles.wrapper}
                style={{
                    color: theme.palette.primary.main,
                    ...style
                }}>
                <div className={styles.box}>{text}</div>
            </div>
        );
    }
    return null;
};
