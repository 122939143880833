import { useEffect } from 'react';

type Func = (this: Window, ev: KeyboardEvent) => void;

export const usePressKey = (func: Func) => {
    useEffect(() => {
        window.addEventListener('keydown', func);
        return function cleanupListener() {
            window.removeEventListener('keydown', func);
        };
    }, []);
};
