export interface RgbObject {
    r: number;
    g: number;
    b: number;
}

/**
 * converts hex color string to rgb, returns null if uncorrect string was passed
 */
export function hexToRgb(hex: string): RgbObject | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null;
}

type Shade = 'light' | 'dark';
/**
 * returns lighter or darker shade for color values in range from 0-255
 */
export function calculateShadeVal(val: number, shade: Shade = 'light'): number {
    if (val < 0 || val > 255) throw new TypeError('Val parameter must integer in range 0-255');
    const step = shade === 'light' ? (255 - val) / 10 : -(val / 10);
    return Math.floor(val + 2 * step);
}

/**
 * takes hex color string as argument returns objet with light, main and dark variants of given color.
 */
export function createThemeColorShades(hex: string) {
    const rgb = hexToRgb(hex);

    if (!rgb) {
        throw new TypeError(`Argument ${hex} is not a valid hex color value`);
    }

    return {
        light: `rgb(${calculateShadeVal(rgb.r, 'light')},${calculateShadeVal(
            rgb.g,
            'light'
        )},${calculateShadeVal(rgb.b, 'light')})`,
        main: `rgb(${rgb.r},${rgb.g},${rgb.b})`,
        dark: `rgb(${calculateShadeVal(rgb.r, 'dark')},${calculateShadeVal(
            rgb.g,
            'dark'
        )},${calculateShadeVal(rgb.b, 'dark')})`
    };
}
