import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Action, DatePickerState } from 'src/components/shared/atoms/datePicker/reducer';
import { useTheme } from '@mui/material/styles';
import { dataPicker } from 'src/constants/subtitles';
import styles from './Error.module.css';
type Props = {
    dispatch: React.Dispatch<Action>;
    state: DatePickerState;
};

export const Error = ({ dispatch, state }: Props) => {
    const theme = useTheme();
    useEffect(() => {
        setTimeout(() => {
            dispatch({
                type: 'CLOSE_ERROR'
            });
        }, 2000);
    }, []);

    return (
        <div
            style={{
                border: `2px solid ${theme.palette.error.main}`
            }}
            className={styles.wrapper}>
            <div className={styles.box}>
                <Typography sx={{ color: theme.palette.error.main, fontWeight: 'bold' }}>
                    {dataPicker.error}
                </Typography>
            </div>
        </div>
    );
};
