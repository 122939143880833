import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { TypeValidatePossibleOptions } from './types';
import { DynamicFormObjectGlobalValidatorBaseProps, FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validatePossibleOptions';

/**
 * Validator to handle komunikat_o_dostepnych_opcjach param,
 * which is used to show warning when there are possible options to choose in field, but user
 * didn't choose any
 */
export class DynamicFormPossibleOptionsValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    typeFnValidate: TypeValidatePossibleOptions;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        dataRef
    }: DynamicFormObjectGlobalValidatorBaseProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        for (const key in this.formState.fields) {
            const field = this.formState.fields[key];
            if (
                field &&
                field?.typ === 'lista_obca_wielokrotna' &&
                field.value.values.length === 0 &&
                field.value.opcje.findIndex((option) => option.id !== '0') > -1 // >because there is option "all" with id 0 added always, so when there is option with id other than 0 it will be some options here
            ) {
                const dynamicFieldT = this.popupFormExtendedData.fields.find(
                    (item) => item.kod === this.formState.fields[key]?.kod
                );
                if (dynamicFieldT && dynamicFieldT.komunikat_o_dostepnych_opcjach === 't') {
                    this.setValidationResult({
                        typeOfInfo: 'Warning',
                        type: {
                            issue: this.typeFnValidate,
                            message: `W polu "${field.name}" znajdują się opcje do wyboru. Czy chcesz kontynuować?`,
                            title: validationData[this.typeFnValidate].title,
                            id_zakladki: field.id_zakladki,
                            fieldCode: field.kod
                        },
                        scrollKey: key
                    });
                    break;
                }
            }
        }
    }
}
