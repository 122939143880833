import { RowData, CellData } from 'src/store/src/listScreen/tableData/types';

export const fakeRowData: RowData = {
    id: '-1',
    color: null,
    selected: false,
    cellValues: {}
};
export const fakeCellData: CellData = {
    text: '',
    rawValue: ''
};
