import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { TemplateGlobalManager } from 'src/utils/src/shared/TemplateGlobalManager';

const regexTwo = /\{\$(.*?)\}/;

type TemplateManageDolarProps = {
    templateStr?: string;
    templateStrArr?: string[];
    rowData: RowData[];
    rowId?: RowDataId | null;
    typeSearch: 'text' | 'rawValue';
};

export class TemplateManagerDolar extends TemplateGlobalManager {
    templateStr: string;
    templateStrArr: string[];
    rowData: RowData[];
    rowId?: RowDataId | null;
    typeSearch: 'text' | 'rawValue';
    constructor({
        rowData,
        rowId,
        templateStr,
        templateStrArr,
        typeSearch
    }: TemplateManageDolarProps) {
        super();
        this.templateStr = templateStr ? templateStr : '';
        this.templateStrArr = templateStrArr ? templateStrArr : [];
        this.rowData = rowData;
        this.rowId = rowId;
        this.typeSearch = typeSearch;
    }
    getOne(str?: string) {
        const content = str ? str : this.templateStr;
        const match = this.checkIsTemplateExist({
            str: content,
            regex: regexTwo
        });
        if (match) {
            const templateItem = this.getTemplateItem(match?.[0]);
            const rawValue = this.getTextOrRawValue({
                rowData: this.rowData,
                rowId: this.rowId,
                type: this.typeSearch,
                templateItem
            });
            return this.replaceContent({
                contentToReplace: rawValue,
                templateItem: templateItem,
                temporaryAllString: content
            });
        }
        return content;
    }
    getMany() {
        return this.templateStrArr.map((item) => {
            return this.getOne(item);
        });
    }
}
