import React from 'react';

import { OrderListData, UiConfigOrderListProps } from './types';
import { UiConfigOrderListWrapper } from '../uiConfigOrderListWrapper/UiConfigOrderListWrapper';
import { UiConfigOrderListItemWrapper } from '../uiConfigOrderListItemWrapper/UiConfigOrderListItemWrapper';
import { UiConfigOrderListArrowsWrapper } from '../uiConfigOrderListArrowsWrapper/UiConfigOrderListArrowsWrapper';
import { UiConfigOrderListArrowWrapper } from '../uiConfigOrderListArrowWrapper/UiConfigOrderListArrowWrapper';
import { UiConfigOrderListArrow } from '../uiConfigOrderListArrow/UiConfigOrderListArrow';
import UICheckboxWewSquare from '../../uiInputs/uiCheckboxWewSquare/UICheckboxWewSquare';
import styles from './styles.module.css';

export const UiConfigOrderList = <T extends OrderListData>({
    data,
    onArrowClick,
    withCheckboxes,
    onCheckboxClick
}: UiConfigOrderListProps<T>) => {
    return (
        <UiConfigOrderListWrapper>
            {data.map((item, i) => {
                return (
                    <UiConfigOrderListItemWrapper i={i} title={item.title} key={item.title + i}>
                        {withCheckboxes ? (
                            <div className={styles.box}>
                                <UICheckboxWewSquare
                                    checked={!!item.visible}
                                    onClick={() => {
                                        onCheckboxClick(item);
                                    }}
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <UiConfigOrderListArrowsWrapper
                            style={{
                                justifyContent: `${i !== data.length - 1 && 'flex-end'}`
                            }}>
                            <UiConfigOrderListArrowWrapper>
                                {i !== 0 && (
                                    <UiConfigOrderListArrow
                                        isDown={true}
                                        onClick={() => onArrowClick(item, 'up')}
                                    />
                                )}
                            </UiConfigOrderListArrowWrapper>
                            <UiConfigOrderListArrowWrapper>
                                {i !== data.length - 1 && (
                                    <UiConfigOrderListArrow
                                        isDown={false}
                                        onClick={() => onArrowClick(item, 'down')}
                                    />
                                )}
                            </UiConfigOrderListArrowWrapper>
                        </UiConfigOrderListArrowsWrapper>
                    </UiConfigOrderListItemWrapper>
                );
            })}
        </UiConfigOrderListWrapper>
    );
};
