import React from 'react';
import { dispatch } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { fetchAndLoadPayoffImportInitData } from 'src/store/src/importPayoff/sharedActions';

export const useLoadInitialImportPayoffScreenData = () => {
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setSpinner(true));

            await dispatch(fetchAndLoadPayoffImportInitData());
            dispatch(setSpinner(false));
        };

        loadData();
        return () => {};
    }, []);
};
