import React, { FC } from 'react';
import { ArrowTwoProps } from './types';
import { useTheme } from '@mui/material/styles';
import styles from './ArrowTwo.module.css';

const ArrowTwo: FC<ArrowTwoProps> = ({ style, type }) => {
    const theme = useTheme();
    return (
        <div
            className={`${styles.arrow} ${type === 'right' ? styles.right : styles.left} 
            }`}
            style={{
                border: `solid ${theme.palette.primary.main}`,
                borderWidth: '0 2px 2px 0',
                ...style
            }}></div>
    );
};

export default ArrowTwo;
