import React, { FC } from 'react';
import { Button } from '@mui/material';
import { OpenButtonProps } from './types';
import WrapperIcon from 'src/components/shared/atoms/wrapperIcon/WrapperIcon';
import TooltipOnHover from 'src/components/shared/molecules/tooltipOnHover/TooltipOnHover';

/**
 * Bezstanowy komponent funkcyjny. Przycisk do otwierania widżetu stoperów.
 */

const OpenBtn: FC<OpenButtonProps> = ({
    handleOpen,
    iconComponent,
    tooltipText,
    children,
    handleLoad
}) => {
    return (
        <TooltipOnHover
            title={tooltipText}
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -2]
                        }
                    }
                ]
            }}>
            <Button
                variant="text"
                disableRipple
                sx={{ padding: 0, borderRadius: '50%', minWidth: 0 }}
                onClick={handleOpen}
                onMouseEnter={handleLoad ? handleLoad : () => {}}>
                <WrapperIcon>{iconComponent}</WrapperIcon>
                {children}
            </Button>
        </TooltipOnHover>
    );
};

export default OpenBtn;
