import { forIn } from 'lodash';
import { FormState } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFieldT, PopupFormRelatedObject, RelatedObjectData } from 'src/data/popupFormTypes';

const blankRelation: PopupFormRelatedObject = {
    addOption: false,
    fields: [],
    id: '',
    name: '',
    tabId: '',
    targetScreenCode: '',
    newObjectsData: [],
    objectsData: [],
    sectionId: '',
    numberEntries: false,
    wylaczenie_dodawania: false,
    wymuszenie_dodania: false
};

const blankRelatedObjectData = {
    id: '',
    archived: false,
    editable: true,
    isNotToDelete: false,
    prefixKey: '',
    values: {}
};

type RelatedObjectDataManagerProps = {
    relation?: PopupFormRelatedObject;
    activeTabId?: string | null;
    targetScreenCode?: string;
    formState?: FormState;
};

export class RelatedObjectDataManager {
    relation?: PopupFormRelatedObject;
    relationToReturn: PopupFormRelatedObject;
    formState?: FormState;
    activeTabId?: string | null;
    targetScreenCode?: string;
    values: { [key: string]: '' };
    relatedObjectDataToReturn: RelatedObjectData;
    constructor({
        relation,
        activeTabId,
        formState,
        targetScreenCode
    }: RelatedObjectDataManagerProps) {
        this.relation = relation;
        this.relationToReturn = blankRelation;
        this.relatedObjectDataToReturn = blankRelatedObjectData;
        this.targetScreenCode = targetScreenCode;
        this.formState = formState;
        this.values = {};
        this.activeTabId = activeTabId;
        this.setRelation();
    }
    getData() {
        this.prepareBlankObjectData();
        return {
            relatedObjectData: this.relatedObjectDataToReturn,
            relation: this.relationToReturn
        };
    }

    private setRelation() {
        if (this.relation) {
            this.relationToReturn = this.relation;
        } else if (this.formState && this.activeTabId && this.targetScreenCode) {
            for (const key in this.formState.tabs) {
                this.formState.tabs[key]?.forEach((item) => {
                    for (const relation of item.relations) {
                        if (relation.targetScreenCode === this.targetScreenCode) {
                            this.relationToReturn = relation;
                            break;
                        }
                    }
                });
            }
        } else {
            console.error('RelatedObjectDataManager: Nie prawidłowa relacja');
        }
    }

    private prepareBlankObjectData() {
        let sequenceId = 1;
        if (this.relationToReturn.newObjectsData.length) {
            sequenceId =
                Number(
                    this.relationToReturn.newObjectsData[
                        this.relationToReturn.newObjectsData.length - 1
                    ]?.id
                ) + 1;
        }
        const objectData: RelatedObjectData = {
            ...blankRelatedObjectData,
            id: `${Number(sequenceId)}`,
            prefixKey: `nowy_rpo_${this.relationToReturn.id}_${sequenceId}`
        };
        this.relationToReturn.fields.forEach((dynamicFieldT) => this.prepareFields(dynamicFieldT));
        objectData.values = this.values;
        this.relatedObjectDataToReturn = objectData;
    }

    private prepareFields(dynamicField: DynamicFieldT) {
        switch (dynamicField.typ) {
            case 'cena_liczba':
                this.values[`${dynamicField.kod}`] = '';
                this.values[`${dynamicField.kod}_liczba`] = '';
                this.values[`${dynamicField.kod}_total_brutto`] = '';
                this.values[`${dynamicField.kod}_total_netto`] = '';
                this.values[`${dynamicField.kod}_vat`] = '';
                this.values[`${dynamicField.kod}_waluta`] = '';
                break;

            default:
                this.values[`${dynamicField.kod}`] = '';
                break;
        }
    }
}
