import React, { FC } from 'react';
import { useAppDispatch, AppDispatch, useTypedSelector } from 'src/store';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { ApiActionSaveConfigProps } from './types';
import { saveConfigData } from 'src/api/src/configurationScreen/saveConfig';
import { createDateApiForActionSaveConfigScreen } from 'src/utils';
import { fetchAndLoadInitialConfigScreenData } from 'src/store/src/configScreen/sharedAcions';

const ApiActionSaveConfig: FC<ApiActionSaveConfigProps> = ({ children, action }) => {
    const configState = useTypedSelector((state) => state.configScreen);
    const dispatch: AppDispatch = useAppDispatch();

    const handleApiAction = async () => {
        const data = createDateApiForActionSaveConfigScreen({ configState });
        dispatch(setSpinner(true));
        const resp = await saveConfigData(data);
        await dispatch(fetchAndLoadInitialConfigScreenData());
        dispatch(setSpinner(false));
        if (!resp.success) {
            new PopupManager({ dispatch: dispatch, action: action }).setPopupInfo(
                resp.message ? resp.message : 'Nie wykonano'
            );
        }
    };

    return (
        <ButtonCustom onClick={() => handleApiAction()} withoutStyles={true}>
            {children}
        </ButtonCustom>
    );
};

export default ApiActionSaveConfig;
