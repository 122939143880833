import { FormValidationDataRefRHF } from 'src/components/form/molecules/formValidator/types';
import { ScreenRHF } from 'src/data/commonRHF';
import {
    FieldBasicListManyStateRHF,
    FieldBasicListStateRHF,
    FieldBasicStateRHF,
    FieldCheckboxStateRHF,
    FieldDateHourStateRHF,
    FieldDateHoursStateRHF,
    FieldDateStateRHF,
    FieldFileStateRHF,
    FieldListForeignManyStateRHF,
    FieldListForeignStateRHF,
    FieldListStateRHF,
    FieldMailStateRHF,
    FieldNumberStateRHF,
    FieldPriceStateRHF,
    FieldRelationTypeManyStateRHF,
    FieldRelationTypeStateRHF,
    FieldTekstStateRHF,
    FieldTekstWithoutRestrictStateRHF,
    FieldsStateUnionRHF
} from 'src/data/fieldsReactHookForm';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { normaliseStringNumber } from 'src/utils';
import { HelperRHF } from 'src/utils/src/shared/HelperRHF';

type FormValidatorRequirementProps = {
    formValidationDataRef: FormValidationDataRefRHF;
    screen: ScreenRHF;
};

export type TypeValidateRequirement = 'validateRequirement';

export class FormValidatorRequirementRHF {
    formValidationDataRef: FormValidationDataRefRHF;
    screen: ScreenRHF;
    constructor({ formValidationDataRef, screen }: FormValidatorRequirementProps) {
        this.formValidationDataRef = formValidationDataRef;
        this.screen = screen;
    }

    async exec() {
        for (const key in this.formValidationDataRef.fieldsToValid) {
            const code = HelperRHF.getPropertyKey({ key, type: 'kod', screen: this.screen });
            const dynamicFieldT: DynamicFieldT | null =
                this.formValidationDataRef.dynamicFieldT.find((field) => field.kod === code);
            if (dynamicFieldT && dynamicFieldT.obowiazkowe === 't') {
                const isValid = await this.checkIsValid(
                    this.formValidationDataRef.fieldsToValid[key],
                    key
                );
                if (!isValid && this.formValidationDataRef.fieldsToValid[key]) {
                    Object.assign(this.formValidationDataRef.fieldsNotValid, {
                        [key]: this.formValidationDataRef.fieldsToValid[key]
                    });
                }
            }
        }
        const keys = Object.keys(this.formValidationDataRef.fieldsNotValid);
        if (keys.length) {
            this.formValidationDataRef.validationResult = {
                ...this.formValidationDataRef.validationResult,
                isNotValid: true,
                typeFnValidates: 'validateRequirement',
                scrollKey: keys[0]
            };
        }
    }

    private async checkIsValid(field: FieldsStateUnionRHF, fieldKey: string) {
        let isValid = true;

        switch (true) {
            case 'powiazanie_typ' in field:
                {
                    const oneObj: Required<FieldRelationTypeStateRHF> =
                        field as Required<FieldRelationTypeStateRHF>;
                    {
                        const code = HelperRHF.getPropertyKey({
                            key: fieldKey,
                            type: 'kod',
                            screen: this.screen
                        });
                        const dynamicFieldT = this.formValidationDataRef.dynamicFieldT.find(
                            (item) => item.kod === code
                        );
                        if (dynamicFieldT) {
                            if (
                                dynamicFieldT?.dozwolona_opcja_pusta !== 't' &&
                                !oneObj.powiazanie_typ.value
                            ) {
                                isValid = false;
                            } else if (
                                dynamicFieldT?.dozwolona_opcja_pusta === 't' &&
                                (!oneObj.powiazanie_typ.value ||
                                    oneObj.powiazanie_typ.value === '0') &&
                                !oneObj.powiazanie_typ.isEmptyValue
                            ) {
                                isValid = false;
                            }
                        }
                    }
                }
                break;
            case 'powiazanie_typ_wielokrotne' in field: {
                const oneObj: Required<FieldRelationTypeManyStateRHF> =
                    field as Required<FieldRelationTypeManyStateRHF>;
                if (!oneObj.powiazanie_typ_wielokrotne.objects.length) {
                    isValid = false;
                }
                break;
            }
            case 'data' in field: {
                const oneObj: Required<FieldDateStateRHF> = field as Required<FieldDateStateRHF>;
                if (!oneObj.data) {
                    isValid = false;
                }
                break;
            }
            case 'data_godzina' in field: {
                const oneObj: Required<FieldDateHourStateRHF> =
                    field as Required<FieldDateHourStateRHF>;
                if (!oneObj.data_godzina.date) {
                    isValid = false;
                }
                break;
            }
            case 'plik' in field: {
                const oneObj: Required<FieldFileStateRHF> = field as Required<FieldFileStateRHF>;
                if (oneObj.plik.id === '' || oneObj.plik.name === '') {
                    isValid = false;
                }
                break;
            }
            case 'data_godziny' in field: {
                const oneObj: Required<FieldDateHoursStateRHF> =
                    field as Required<FieldDateHoursStateRHF>;
                if (!oneObj.data_godziny.date) {
                    isValid = false;
                }
                break;
            }
            case 'lista_obca_wielokrotna' in field: {
                const oneObj: Required<FieldListForeignManyStateRHF> =
                    field as Required<FieldListForeignManyStateRHF>;
                if (!oneObj.lista_obca_wielokrotna.values.length) {
                    isValid = false;
                }
                break;
            }
            case 'basic_list_many' in field: {
                const oneObj: Required<FieldBasicListManyStateRHF> =
                    field as Required<FieldBasicListManyStateRHF>;
                if (!oneObj.basic_list_many.values.length) {
                    isValid = false;
                }
                break;
            }
            case 'basic_list' in field: {
                const oneObj: Required<FieldBasicListStateRHF> =
                    field as Required<FieldBasicListStateRHF>;
                if (oneObj.basic_list.value === '0' || oneObj.basic_list.value === '-1') {
                    isValid = false;
                }
                break;
            }
            case 'basic' in field: {
                const oneObj: Required<FieldBasicStateRHF> = field as Required<FieldBasicStateRHF>;
                if (!oneObj.basic) {
                    isValid = false;
                }
                break;
            }
            case 'tekst' in field: {
                const oneObj: Required<FieldTekstStateRHF> = field as Required<FieldTekstStateRHF>;
                if (!oneObj.tekst) {
                    isValid = false;
                }
                break;
            }
            case 'email' in field: {
                const oneObj: Required<FieldMailStateRHF> = field as Required<FieldMailStateRHF>;
                if (!oneObj.email) {
                    isValid = false;
                }
                break;
            }
            case 'tekst_bez_ograniczen' in field: {
                const oneObj: Required<FieldTekstWithoutRestrictStateRHF> =
                    field as Required<FieldTekstWithoutRestrictStateRHF>;
                if (!oneObj.tekst_bez_ograniczen) {
                    isValid = false;
                }
                break;
            }
            case 'checkbox' in field: {
                const oneObj: Required<FieldCheckboxStateRHF> =
                    field as Required<FieldCheckboxStateRHF>;
                if (!oneObj.checkbox) {
                    isValid = false;
                }
                break;
            }
            case 'lista' in field: {
                const oneObj: Required<FieldListStateRHF> = field as Required<FieldListStateRHF>;
                if (oneObj.lista.value === '0' || oneObj.lista.value === '-1') {
                    isValid = false;
                }
                break;
            }
            case 'liczba' in field: {
                const oneObj: Required<FieldNumberStateRHF> =
                    field as Required<FieldNumberStateRHF>;
                if (!oneObj.liczba || !Number(oneObj.liczba)) {
                    isValid = false;
                }
                break;
            }
            case 'cena' in field: {
                const oneObj: Required<FieldPriceStateRHF> = field as Required<FieldPriceStateRHF>;
                if (!oneObj.cena.netto || Number(normaliseStringNumber(oneObj.cena.netto)) === 0) {
                    isValid = false;
                }
                break;
            }
            case 'lista_obca' in field: {
                const oneObj: Required<FieldListForeignStateRHF> =
                    field as Required<FieldListForeignStateRHF>;
                if (!oneObj.lista_obca.value || oneObj.lista_obca.value === '0') {
                    isValid = false;
                }
                break;
            }
            default:
                break;
        }

        return isValid;
    }
}
