import React, { FC, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { NavMenuListProps } from './types';
import adminEdit from 'src/assets/ikonka_konfiguracji.svg';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { UiNavMenuListWrapper } from 'src/components/menu/atoms/uiNavMenuListWrapper/UiNavMenuListWrapper';
import { getUrlParams } from 'src/utils/src/shared/getUrlParams';
import { getCompatibleUrl } from 'src/utils';
import { ubdateModuleUnhold } from 'src/store/src/menu';
import { iconStyleCommon } from '../../atoms/icon/Icon';
import styles from 'src/styles/menu.module.css';
import { useTheme } from '@mui/material/styles';
import { useSafeNavigate } from 'src/hooks';

const NavMenuList: FC<NavMenuListProps> = ({
    item,
    isAdmin,
    children,
    isExpanded = false,
    isEdit
}) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const menuIsUnfolded = useTypedSelector((state) => state.menu.isUnfolded);
    const navigate = useSafeNavigate();
    const dispatchRedux = useAppDispatch();
    const theme = useTheme();

    const adminParams = () => {
        const url: string = '/ekran_edycji_modulu.php';

        const queryParams = new URLSearchParams(`modul=${item.id}`);

        return { url: url, queryParams: queryParams };
    };

    const visibleScreen = item.screens.filter((item) => !item.visibleOnlyWhenEditing);

    const expandModule = () => {
        dispatchRedux(ubdateModuleUnhold(item.id));
    };

    return (
        <UiNavMenuListWrapper>
            <Box
                sx={{
                    display: 'block',
                    cursor: 'pointer',
                    padding: '7px 0',
                    position: 'relative',
                    zIndex: 1,
                    color: `${isExpanded ? 'white' : theme.palette.primary.main}!important`,
                    backgroundColor: `${isExpanded ? theme.palette.primary.main : 'white'}`,
                    '&:hover': {
                        backgroundColor: '#e8e8e8 !important',
                        color: `${theme.palette.primary.main} !important`
                    }
                }}
                onClick={(e) => {
                    expandModule();
                    if (visibleScreen.length === 1 && !isEdit) {
                        const { url, queryParams } = getUrlParams(visibleScreen[0]);
                        navigate(getCompatibleUrl(url, queryParams, true), null, e);
                    }
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    if (visibleScreen.length === 1) {
                        const { url, queryParams } = getUrlParams(item.screens[0]);
                        window.open(window.basename + getCompatibleUrl(url, queryParams), '_blank');
                    }
                }}
                onMouseOver={() => {
                    if (isAdmin) {
                        setIsHover(true);
                    }
                }}
                onMouseLeave={() => {
                    if (isAdmin) {
                        setIsHover(false);
                    }
                }}>
                <div className={styles.parentLevelBox}>
                    <div className={styles.parentLevelIconBox}>
                        {!isHover ? (
                            <ThemeCompatibleIcon
                                iconData={item.icon}
                                fontSize={'large'}
                                color="inherit"
                                sx={{
                                    ...iconStyleCommon,
                                    border: '1px solid transparent',
                                    paddingLeft: 0
                                }}
                            />
                        ) : (
                            <LegacyCompatibleLink
                                {...adminParams()}
                                stopPropagation={true}
                                outerFn={expandModule}>
                                <div className={` ${styles.wrapperSvgIcon} `}>
                                    <ThemeCompatibleIcon
                                        SvgComponent={adminEdit}
                                        sx={{ ...iconStyleCommon }}
                                    />
                                </div>
                            </LegacyCompatibleLink>
                        )}
                    </div>
                    {menuIsUnfolded && (
                        <Typography className={styles.p14}> {item.name} </Typography>
                    )}
                </div>
            </Box>
            <div>{children}</div>
        </UiNavMenuListWrapper>
    );
};

export default React.memo(NavMenuList);
