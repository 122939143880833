import React, { FC } from 'react';
import { Typography, Box } from '@mui/material';
import { useSearchParam } from 'src/hooks';
import { useTheme } from '@mui/material/styles';
import UtilityBarOptionView from 'src/components/listScreen/molecules/utilityBarOptionView/UtilityBarOptionView';

/**
 * Display error message to user eg. invalid url
 */

const action = {
    behaviour: {
        type: 'navigate',
        data: {
            target: 'back'
        }
    },
    code: '',
    colorConditions: null,
    defaultColor: null,
    displayConditions: null,
    icon: {
        faCode: '',
        svgFileName: 'wroc.svg'
    },
    name: 'Wróć'
};

const MessageDiePage: FC = () => {
    const theme = useTheme();
    const title = useSearchParam('tytul');
    const content = useSearchParam('tekst');

    const prev = () => {
        window.history.back();
    };

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ alignItems: 'left', display: 'flex' }}>
                <Box onClick={prev}>
                    <UtilityBarOptionView name={action.name} icon={action.icon} />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '50%',
                    alignItems: 'center',
                    display: 'grid',
                    justifyContent: 'center'
                }}>
                <Box>
                    <Box
                        sx={{
                            borderBottom: `1px solid ${theme.palette.primary.main}`,
                            borderTop: `1px solid ${theme.palette.primary.main}`,
                            padding: '10px 200px'
                        }}>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '16px',
                                fontWeight: '600',
                                color: theme.palette.primary.main
                            }}>
                            <p dangerouslySetInnerHTML={{ __html: title ?? '' }}></p>
                        </Typography>
                    </Box>

                    <Typography
                        sx={{
                            paddingTop: '30px',
                            textAlign: 'center',
                            fontSize: '14px',
                            color: theme.palette.primary.main
                        }}>
                        <p dangerouslySetInnerHTML={{ __html: content ?? '' }}></p>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default MessageDiePage;
