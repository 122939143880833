import { ConvertableToParams } from 'src/data/types';

export const convertParamsObjectToStrTwo = (
    paramsObject: ConvertableToParams,
    startParam: string = '?',
    excludedKeys: string[] = []
) => {
    let paramsString = startParam;

    for (const [key, value] of Object.entries(paramsObject)) {
        if (typeof value !== 'object') {
            if (excludedKeys.length) {
                if (!excludedKeys.includes(key)) {
                    paramsString += `${key}=${value}&`;
                }
            } else {
                paramsString += `${key}=${value}&`;
            }
        }
    }

    return paramsString;
};
