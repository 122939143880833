export const dimensionsPopupFormExtended = {
    normal: {
        maxContentWidth: 800,
        breakpointWidth: 930,
        fieldWidth: 375
    },
    bigger: { maxContentWidth: 1000, breakpointWidth: 1130, fieldWidth: 460 },
    biggest: {
        maxContentWidth: 1200,
        breakpointWidth: 1330,
        fieldWidth: 460
    }
};
