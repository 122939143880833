import { useEffect } from 'react';

/**
 * Periodically run given function
 * @param fn - any function to call in interval
 * @param interval - time in seconds
 * @param startImmediately - if run function before first timeout pass, immediately after hook call
 */
export function useInterval(fn: Function, interval: number, startImmediately: boolean = false) {
    useEffect(() => {
        if (startImmediately) fn();

        // should be rememberd via closure
        const intervalID = setInterval(() => fn(), interval * 1000);

        return () => clearInterval(intervalID);
    }, [interval]);
}
