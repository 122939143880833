import {
    FieldDependentRHFProps,
    FieldDependentTempStateRHF,
    FieldDepenedentFormStateHandlersRHF
} from 'src/components/form/molecules/fieldDependent/types';

import { FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { updateKeyResetRHF } from 'src/utils';

type FieldDependentHelperRHFProps = FieldDependentRHFProps & {
    tempState: FieldDependentTempStateRHF;
};

export class FieldDependentHelperRHF {
    dynamicFieldsT: DynamicFieldT[];
    formStateHandlers: FieldDepenedentFormStateHandlersRHF;
    tempState: FieldDependentTempStateRHF;
    constructor({ dynamicFieldsT, formStateHandlers, tempState }: FieldDependentHelperRHFProps) {
        this.dynamicFieldsT = dynamicFieldsT;
        this.formStateHandlers = formStateHandlers;
        this.tempState = tempState;
    }

    findDynamicFieldTById(id: string) {
        return this.dynamicFieldsT.find((item) => item.id === id);
    }

    findStateOfFieldsByCode(key: string): FieldsStateUnionRHF {
        if (this.tempState[key]) {
            return this.tempState[key];
        }
        return this.formStateHandlers.getValues(key);
    }
    setTempState(key: string, state: FieldsStateUnionRHF) {
        this.tempState[key] = {
            ...this.formStateHandlers.getValues(key),
            ...state,
            keyReset: updateKeyResetRHF({ key, getValues: this.formStateHandlers.getValues })
        };
    }
}
