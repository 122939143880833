import { SettledObjectsCodes } from 'src/store/src/budget/budget/types';
import { BudgetCodesForTotalCalc } from 'src/utils/src/budget/types';

export const budgetStableCodesForTotalCalc: {
    [key in SettledObjectsCodes]: BudgetCodesForTotalCalc;
} = {
    kontakty: {
        liczba: 'ilosc',
        cena: 'cena',
        vat: 'vat',
        kurs: 'kurs'
    },
    kontakty_ryczalty: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    koszty: {
        liczba: 'liczba',
        cena: 'kwota',
        vat: 'kwota_vat',
        kurs: 'kurs'
    },
    rozprawy: {
        liczba: 'ilosc',
        cena: 'cena',
        vat: 'vat',
        kurs: 'kurs'
    },
    rozprawy_ryczalty: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    zadania: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'vat',
        kurs: 'kurs'
    },
    kosztyzastepstwa: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    ryczalty_za_sprawy: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    oplatywstepne_za_sprawy: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    dokumenty: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    dokumenty_kontrakt: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    successfees: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    ryczalt_miesiac: {
        liczba: 'ryczalt_ilosc',
        cena: 'ryczalt',
        vat: 'ryczalt_vat',
        kurs: 'ryczalt_kurs'
    },
    ryczalt: {
        liczba: 'ryczalt_ilosc',
        cena: 'ryczalt',
        vat: 'ryczalt_vat',
        kurs: 'ryczalt_kurs'
    },
    limit_godzin: {
        liczba: 'limit_godzin_ilosc',
        cena: 'limit_godzin',
        vat: 'limit_godzin_vat',
        kurs: 'limit_godzin_kurs'
    },
    fakturowaneelicencje: {
        liczba: 'liczbaistawka_liczba',
        cena: 'liczbaistawka',
        vat: 'liczbaistawka_vat',
        kurs: null // as I look there is no 'kurs' in this object
    },
    etapyprojektow: {
        liczba: 'liczba',
        cena: 'cena',
        vat: 'cena_vat',
        kurs: 'kurs'
    },
    koszty_projektow: {
        liczba: 'liczba',
        cena: 'kwota',
        vat: null, // as I look there is no 'vat' in this object
        kurs: null // as I look there is no 'kurs' in this object
    }
};
