import { AppDispatch } from 'src/store';
import { BudgetScreenState, BugdetSettlementMethodLevel } from 'src/store/src/budget/budget/types';
import { BudgetFormStateRHF, BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BudgetSetterStateMethod } from 'src/utils/src/budget/BudgetSetterStateMethod';
import { BudgetHandlerGlobalSummaryStructure } from 'src/utils/src/budget/BudgetHandlerGlobalSummaryStructure';
import { BudgetHandlerCalcGlobalSummaryDataPrepareAll } from 'src/utils/src/budget/BudgetHandlerCalcGlobalSummaryDataPrepareAll';
import { BudgetHandlerCalcGlobalSummaryMain } from 'src/utils/src/budget/BudgetHandlerCalcGlobalSummaryMain';
import { BudgetHandlerTemplateMonthLimit } from 'src/utils/src/budget/BudgetHandlerTemplateMonthLimit';
import { consoleConditionally } from 'src/utils/src/shared/consoleConditionally';
// Class working on only onMount

export type BudgetSetterStateGlobalNotSharedProps = {
    dispatch: AppDispatch;
};

export type BudgetSetterStateGlobalProps = {
    budget: BudgetScreenState;
    operationalState?: BudgetOperationalState;
};

/**
 * Class for creating initial state for global budget form
 */
export class BudgetSetterStateGlobal extends BudgetSetterStateMethod {
    state: BudgetFormStateRHF;
    dispatch: AppDispatch;
    constructor({
        budget,
        operationalState = {
            callPlacement: 'budgetSetterStateGlobal',
            dataToCalcAdditionalField: {
                invoiceAllInCase: {}
            },
            fieldsTempFromRHF: {},
            fieldsTemp: {},
            templateKeyMonthIdToUpdateMonthsForCaseLimit: {},
            sumTemp: {},
            limitTemp: {},
            limitTempToCalc: {},
            fieldsStructure: [],
            objectsToCalcLimit: {},
            templateMonthLimit: {},
            templateMonthLimitStartTimestampWhereIsLimit: {},
            globalSummary: { hours: 0, hoursSpecial: 0, amount: 0 },
            globalSummaryData: {},
            globalSummaryDataLimit: {},
            globalSummaryDataObject: {},
            globalSummaryDataLimitToCalc: {}
        },
        dispatch
    }: BudgetSetterStateGlobalProps & BudgetSetterStateGlobalNotSharedProps) {
        super({ budget, operationalState });
        this.state = {
            main: {},
            globalSummary: {
                hours: 0,
                hoursSpecial: 0,
                amount: 0
            },
            globalSummaryData: {}
        };
        this.dispatch = dispatch;
    }

    private asignTempStateToState(id: string) {
        Object.assign(this.state.main, {
            [id]: {
                ...this.operationalState.fieldsTemp,
                ...this.operationalState.limitTemp,
                ...this.operationalState.sumTemp
            }
        });
    }

    private resetState() {
        this.operationalState.objectsToCalcLimit = [];
        this.operationalState.fieldsTempFromRHF = {};
        this.operationalState.fieldsTemp = {};
        this.operationalState.sumTemp = {};
        this.operationalState.limitTemp = {};
        this.operationalState.limitTempToCalc = {};
    }

    private prepareGlobalSummaryDataStructure() {
        new BudgetHandlerGlobalSummaryStructure({
            budget: this.budget,
            operationalState: this.operationalState
        }).execute();
    }

    private prepareGlobalSummaryDataPrepare(method: BugdetSettlementMethodLevel) {
        new BudgetHandlerCalcGlobalSummaryDataPrepareAll({
            budget: this.budget,
            operationalState: this.operationalState,
            method
        }).executeAll();
    }

    private prepareGlobalSummaryDataMain() {
        new BudgetHandlerCalcGlobalSummaryMain({
            budget: this.budget,
            operationalState: this.operationalState
        }).execute();
    }

    private runTemplateMonthLimit() {
        new BudgetHandlerTemplateMonthLimit({
            budget: this.budget,
            operationalState: this.operationalState
        }).execute();
    }

    execute() {
        this.runTemplateMonthLimit();
        this.prepareGlobalSummaryDataStructure();

        this.budget.settlementMethods.forEach((method) => {
            this.resetState();
            this.runMethod(method);
            this.prepareGlobalSummaryDataPrepare(method);
            this.asignTempStateToState(method.id);
        });

        this.prepareGlobalSummaryDataMain();
        this.state.globalSummary = this.operationalState.globalSummary;
        this.state.globalSummaryData = this.operationalState.globalSummaryData;

        new BudgetHandlerTemplateMonthLimit({
            budget: this.budget,
            operationalState: this.operationalState
        }).moveTemplateMonthLimitToMonthsIdIncluded();
        consoleConditionally(this.operationalState, 'operational');
        consoleConditionally(this.state, 'initState');
        return this.state;
    }
}
