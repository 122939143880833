import React, { FC, useMemo } from 'react';
import { QueryLinkProps } from './types';
import { combineFullUrl, getFullUrlWithIdOkna } from 'src/utils';
import { useSafeNavigate } from 'src/hooks';
import styles from './QueryLink.module.css';

/**
 * Wrapper component for react-router navigation purpouses, works as link with separate
 * queryparams args
 */
const QueryLink: FC<QueryLinkProps> = ({
    url,
    queryParams,
    rightClickBlank = true,
    confirmPropmpt,
    newWindow,
    closePopup,
    children,
    stopPropagation = false,
    locationState,
    autoPointerStyles = false,
    outerFn
}) => {
    const navigate = useSafeNavigate();

    /**
     * used useMemo to memorize value between rerenders when don't change any of dependencies
     * [url, queryParams]
     */
    const fullUrlWithIdOkna = useMemo(() => {
        return getFullUrlWithIdOkna(url, queryParams);
    }, [url, queryParams]);

    const getFullUrl = useMemo(() => {
        const params = queryParams;
        params?.delete('id_okna');
        return combineFullUrl(url, queryParams);
    }, [url, queryParams]);

    const handleNavigateNewWindow = () => {
        outerFn && outerFn();
        closePopup && closePopup();
        window.open(window.basename + getFullUrl, '_blank');
    };

    const handleNavigate = (e: React.SyntheticEvent) => {
        outerFn && outerFn();
        closePopup && closePopup();
        navigate(fullUrlWithIdOkna, locationState, e, stopPropagation);
    };

    const handleNavigateWithConfirmation = (e: React.SyntheticEvent) => {
        closePopup && closePopup();
        if (window.confirm(confirmPropmpt ? confirmPropmpt : undefined)) {
            navigate(fullUrlWithIdOkna, locationState, e, stopPropagation);
        }
    };

    const handleRightClick = (e: MouseEvent) => {
        closePopup && closePopup();
        if (rightClickBlank) {
            window.open(window.basename + getFullUrl, '_blank');
            e.preventDefault();
        }

        stopPropagation && e.stopPropagation();
    };

    // map every children in order to add navigate action on click
    return (
        <>
            {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) {
                    return child;
                } else if (confirmPropmpt) {
                    return (
                        <child.type
                            {...child.props}
                            className={autoPointerStyles ? styles.pointer : child.props.className}
                            onClick={handleNavigateWithConfirmation}
                            onContextMenu={handleRightClick}
                        />
                    );
                } else if (newWindow) {
                    return (
                        <child.type
                            {...child.props}
                            className={autoPointerStyles ? styles.pointer : child.props.className}
                            onClick={handleNavigateNewWindow}
                            onContextMenu={handleRightClick}
                        />
                    );
                }
                return (
                    <child.type
                        {...child.props}
                        className={autoPointerStyles ? styles.pointer : child.props.className}
                        onClick={handleNavigate}
                        onContextMenu={handleRightClick}
                    />
                );
            })}
        </>
    );
};

export default QueryLink;
