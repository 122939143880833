type HiddenDataFormValue = string | { key: string; filename: string; type: string };
type SerializedFormData = { [key: string]: HiddenDataFormValue } & {
    [key: `${string}[]`]: HiddenDataFormValue[];
};
export type SerializedFormDataEntries = Iterable<
    [string | `${string}[]`, HiddenDataFormValue | HiddenDataFormValue[]]
>;

class IndexedDBFileManager {
    key: string;
    db: IDBDatabase | null;

    static dbVersion = 1;

    constructor(key: string) {
        this.key = key;
        this.db = null;
    }

    async getFile() {
        await this.openDB();
        // Open a transaction to the database
        var transaction = this.db?.transaction(['files'], 'readwrite');

        return new Promise<Blob | null>((res, _) => {
            if (!transaction) {
                res(null);
                this.db?.close();
                return;
            }

            transaction.objectStore('files').get(this.key).onsuccess = (event) => {
                if (event?.target) {
                    res((event.target as any).result as Blob);
                }

                if (!transaction) return;
                transaction.objectStore('files').delete(this.key);
                this.db?.close();
            };
        });
    }

    async openDB() {
        return new Promise((res, _) => {
            // Create/open database
            var request = indexedDB.open('tempFiles', IndexedDBFileManager.dbVersion);

            request.onsuccess = (_) => {
                this.db = request.result;

                this.db.onerror = function (_) {
                    console.error('Error creating/accessing IndexedDB database');
                };

                // Interim solution for Google Chrome to create an objectStore. Will be deprecated
                if (typeof (this.db as any).setVersion === 'function') {
                    if (this.db.version !== IndexedDBFileManager.dbVersion) {
                        var setVersion = (this.db as any).setVersion(
                            IndexedDBFileManager.dbVersion
                        );
                        setVersion.onsuccess = async function () {
                            this.createObjectStore(this.db);
                            res(true);
                        };
                    } else {
                        res(true);
                    }
                } else {
                    res(true);
                }
            };

            // For future use. Currently only in latest Firefox versions
            request.onupgradeneeded = async (event) => {
                this.createObjectStore((event.target as any).result);
                if (!this.db) this.db = (event.target as any).result;
                res(true);
            };
        });
    }

    async createObjectStore(db: IDBDatabase) {
        // Create an objectStore
        if (!db.objectStoreNames.contains('files')) db.createObjectStore('files');
    }
}

/**
 * transform queryParams data into FormData
 * @returns formdata with values corresponding to queryParams
 */
export const makeFormData = async (
    queryParams: URLSearchParams,
    omitPhpUrl = true,
    phpHiddenFormData: string | null = null
) => {
    var formdata = new FormData();

    if (phpHiddenFormData) {
        await makeFormDataFromEntries(
            formdata,
            getPhpHiddenFormDataEntries(phpHiddenFormData),
            omitPhpUrl
        );
    }

    // load queryParams later because they are more important than and could override values from
    // phpHiddenFormData
    await makeFormDataFromEntries(formdata, queryParams.entries(), omitPhpUrl);

    return formdata;
};

export const getPhpHiddenFormDataEntries = (
    phpHiddenFormData: string
): SerializedFormDataEntries => {
    let phpSerializedDataObject: SerializedFormData = {};
    try {
        phpSerializedDataObject = JSON.parse(phpHiddenFormData);
    } catch (e) {
        console.warn('makeFormData(): phpHiddenFormData JSON parse error');
    }
    return Object.entries(phpSerializedDataObject);
};

const makeFormDataFromEntries = async (
    formdata: FormData,
    phpSerializedDataObjectEntries: SerializedFormDataEntries,
    omitPhpUrl: boolean
) => {
    for (const [key, value] of phpSerializedDataObjectEntries) {
        if (omitPhpUrl && key === 'php_url') continue;

        if (value) {
            if (key.endsWith('[]')) {
                for (const innerValue of value as HiddenDataFormValue[]) {
                    formdata.append(key, await loadValueForFormData(innerValue));
                }
            } else {
                formdata.append(key, await loadValueForFormData(value as HiddenDataFormValue));
            }
        }
    }
};

const loadValueForFormData = async (innerValue: HiddenDataFormValue) => {
    if (typeof innerValue == 'object') {
        const blob = await new IndexedDBFileManager(innerValue.key).getFile();
        if (!blob) {
            console.error('loadValueForFormData: recivied null blob');
            return '';
        }

        // // firefox return from fetch file object with full data, not like chrome
        // if (isFirefox) return blob;

        const file = new File([blob], innerValue.filename, { type: blob.type });
        return file;
    }
    return innerValue;
};
