import React, { FC } from 'react';
import { Button } from '@mui/material';

import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { globalPopup } from 'src/constants/subtitles';
import styles from './PopupContentTypeWewInfoChangePassword.module.css';
import { PopupContentTypeWewInfoChangePasswordProps } from 'src/components/shared/molecules/popupContentTypeWewInfoChangePassword/types';
import { usePressKeyWithKeyCode, useRequirementsEnabled } from 'src/hooks';
import { PopupBehavior, SystemAction } from 'src/data/types';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { AppDispatch, useAppDispatch } from 'src/store';
import { closePopup } from 'src/store/src/popup';

export const PopupContentTypeWewInfoChangePassword: FC<
    PopupContentTypeWewInfoChangePasswordProps
> = ({ alertData, popup }) => {
    const field = alertData.data.passwordfield;
    const screen = alertData.data.screen;
    const objectId = alertData.data.objectId;
    const info = alertData.data.descriptionText;
    const requirementsEnabled = useRequirementsEnabled(field);
    const dispatch: AppDispatch = useAppDispatch();

    const onClick = () => {
        const popupBehaviour: PopupBehavior = {
            type: 'popup',
            data: {
                template: 'changePassword',
                data: {
                    passwordfield: field,
                    objectId,
                    screen,
                    lockExit: true,
                    userPasswordValidation: alertData.data.additionalVerfication
                }
            }
        };
        const actionPreview: SystemAction = {
            name: 'Zmiana hasła',
            code: 'change password_file_password',
            colorConditions: null,
            displayConditions: null,
            behaviour: popupBehaviour
        };
        dispatch(closePopup(popup.key));
        new PopupManager({ dispatch, action: actionPreview }).setPopupTemplate({
            behaviour: popupBehaviour,
            rowId: '0',
            sourceOfTrigger: {
                type: 'popupFormExtended',
                fileId: '',
                fileName: ''
            }
        });
    };

    usePressKeyWithKeyCode(onClick, 'Enter');

    return (
        <PopupTemplateThird title={globalPopup.contentInfoChangePassword.title}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.block}>
                        <p>{info}</p>
                    </div>
                    <div>
                        <p className={styles.pBold}>
                            {globalPopup.contentInfoChangePassword.chapterOne}
                        </p>
                        {requirementsEnabled.fields.hasla_dlugosc && (
                            <p>- musi się składać z min {field.hasla_dlugosc} znaków</p>
                        )}
                        {requirementsEnabled.fields.hasla_dlugosc_maksymalna && (
                            <p>- musi się składać z max {field.hasla_dlugosc_maksymalna} znaków</p>
                        )}
                        {requirementsEnabled.fields.hasla_male_litery && <p>- min 1 mała litera</p>}
                        {requirementsEnabled.fields.hasla_duze_litery && (
                            <p>- min 1 wielka litera</p>
                        )}
                        {requirementsEnabled.fields.hasla_cyfry && <p>- min 1 cyfra</p>}
                        {requirementsEnabled.fields.hasla_znaki_specjalne && (
                            <p>- min 1 znak specjalny, np: !,@,#..</p>
                        )}
                        {requirementsEnabled.fields.hasla_blokuj_popularne && (
                            <p>- popularne hasło np: password</p>
                        )}
                    </div>
                    <div className={styles.list}>
                        <p className={styles.pBold}>
                            {globalPopup.contentInfoChangePassword.chapterTwo}
                        </p>
                        <p>{globalPopup.contentInfoChangePassword.chapterTwoOne}</p>
                        <p>{globalPopup.contentInfoChangePassword.chapterTwoTwo}</p>
                    </div>
                </div>
                <div className={styles.box}>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={onClick}>
                        <p>{globalPopup.contentInfo.button.yes}</p>
                    </Button>
                </div>
            </div>
        </PopupTemplateThird>
    );
};
