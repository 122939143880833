import React, { FC } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import InputB from '@mui/material/InputBase';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import CallendarIcon from 'src/assets/ikona_kalendarz.svg';
import { UiInputNormalProps } from '../types';
import { UiInputElWrapper } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElWrapper/UiInputElWrapper';
import { UiInputElLabel } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElLabel/UiInputElLabel';
import { sxInputV1Default, sxThemeCompatibleIconV1Default } from '../sxDefault';
import { useStyleInlineOne } from 'src/hooks/src/shared/useStyleInlineOne';

export const UiInputNormalWithIcon: FC<UiInputNormalProps> = (props) => {
    const { typeIcon = 'svg', type = 'text' } = props;
    const inlineStyle = useStyleInlineOne({ ...props });

    return (
        <UiInputElWrapper {...props}>
            {props.label && <UiInputElLabel {...props} />}
            <InputB
                sx={{
                    ...inlineStyle,
                    ...sxInputV1Default,
                    ...props.sxinput
                }}
                style={{
                    WebkitAppearance: 'none'
                }}
                type={type}
                endAdornment={
                    typeIcon === 'svg' ? (
                        <InputAdornment position="end" sx={props.sxinputadornment}>
                            <ThemeCompatibleIcon
                                SvgComponent={props.icon ? props.icon : CallendarIcon}
                                fontSize={'medium'}
                                sx={{
                                    ...sxThemeCompatibleIconV1Default,
                                    ...props.sxthemecompatibleicon
                                }}
                            />
                        </InputAdornment>
                    ) : (
                        <InputAdornment position="end" sx={props.sxinputadornment}>
                            {props.iconComponent}
                        </InputAdornment>
                    )
                }
                inputProps={props.inputProps}
                {...props}
            />
        </UiInputElWrapper>
    );
};
