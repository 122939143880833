import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StartupScreenConfig } from 'src/api/src/startupScreen/types';

import { StartupBlock, StartupScreenState } from 'src/store/src/startupScreen/startupScreen/types';

export const initialState: StartupScreenState = {
    blocks: [],
    isLoading: true
};

const startupSlice = createSlice({
    name: 'startup',
    initialState,
    reducers: {
        setInitData: (state: StartupScreenState, action: PayloadAction<StartupBlock[]>) => {
            state.isLoading = false;
            state.blocks = action.payload;
        },
        updateBlocksConfig: (
            state: StartupScreenState,
            action: PayloadAction<StartupScreenConfig>
        ) => {
            state.isLoading = false;
            state.blocks = state.blocks
                .map((block) => {
                    return {
                        ...block,
                        visible: action.payload[block.code].visible
                    };
                })
                .sort((a, b) => action.payload[a.code].order - action.payload[b.code].order);
        },
        setLoading: (state: StartupScreenState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
});

export const { setInitData, updateBlocksConfig, setLoading } = startupSlice.actions;

export default startupSlice.reducer;
