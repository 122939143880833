import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserApiData, UserState } from './types';
import { AppThunk } from '../../calendar/index';
import { API_UNLOGGED_USER_ERROR_CODE, NOT_LOGGED_USER_DATA } from 'src/constants';
import { BaseApiResponse } from 'src/api/types';
import { fetchAndHandleCalendarData } from 'src/store/src/calendar';
import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';
import * as actionTypes from 'src/widgets/chat/constants/actions';
import { update } from 'lodash';

export const initialState: UserState = {
    grid: null,
    isLogged: null,
    loginStage2fa: false,
    login: null,
    firstName: null,
    lastName: null,
    adminFeatures: false,
    userID: null,
    userType: null,
    chat: false,
    todo: false,
    notes: false,
    timers: false,
    sessionID: '',
    userNameToDisplay: '',
    timeToLogout: null,
    colorThemeSystem: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setGrid: (state, action: PayloadAction<'HALFHOUR' | 'HOUR'>) => {
            state.grid = action.payload;
        },
        setLoggedOffUser: () => ({ ...initialState, isLogged: false }),
        setUser: (state, action: PayloadAction<UserState>) => {
            const {
                grid,
                isLogged,
                login,
                firstName,
                lastName,
                adminFeatures,
                userID,
                userType,
                chat,
                todo,
                notes,
                timers,
                userNameToDisplay,
                sessionID,
                timeToLogout,
                loginStage2fa,
                colorThemeSystem
            } = action.payload;
            state.grid = grid;
            state.isLogged = isLogged;
            state.loginStage2fa = loginStage2fa;
            state.login = login;
            state.firstName = firstName;
            state.lastName = lastName;
            state.adminFeatures = adminFeatures;
            state.userID = userID;
            state.userType = userType;
            state.chat = chat;
            state.todo = todo;
            state.notes = notes;
            state.timers = timers;
            state.userNameToDisplay = userNameToDisplay;
            state.sessionID = sessionID;
            state.timeToLogout = timeToLogout;
            state.colorThemeSystem = colorThemeSystem;
        },
        updateColorTheme: (state, action: PayloadAction<string | null>) => {
            state.colorThemeSystem = action.payload;
        }
    },
    extraReducers: {
        // normal redux reducers to be compatible with chat
        [actionTypes.SET_USER]: (state, action: PayloadAction<{ user: UserState }>) => {
            const { payload } = action;

            return {
                ...state,
                ...payload.user
            };
        }
    }
});

export const handleUserSetting = (user: UserApiData) => {
    const userToSet = {
        ...user,
        userNameToDisplay:
            user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : `${user.login}`,
        grid: user.grid ?? 'HALFHOUR'
    };

    return setUser(userToSet);
};

export const handleUserSetGrid =
    (grid: string): AppThunk =>
    async (dispatch) => {
        const payload2 = {
            grid: grid
        };
        try {
            const res: any = await simpleQueryAPIData(
                'api_react/userSettings.php',
                'POST',
                payload2
            );
            if (res?.success) {
                const gridToSet = res.data?.grid ? res.data?.grid : 'HALFHOUR';
                dispatch(setGrid(gridToSet));
                dispatch(fetchAndHandleCalendarData());
            }
        } catch (e) {
            console.log(e, 'GridSet is not working');
        }
    };

/**
 * try to set proper user data by given response from api, handle error situations, and setting
 * data when user has no acces, so is not logged
 * @param apiResponse response from api with user data
 */
export const handleUserApiDataResponse =
    (apiResponse: BaseApiResponse<UserApiData>): AppThunk =>
    async (dispatch) => {
        if (!apiResponse.success || !apiResponse.data) {
            // if errorCode 1 or API_UNLOGGED_USER_ERROR_CODE it is unauthorized access, so set unlogged user data
            if (
                apiResponse.errorCode === 1 ||
                apiResponse.errorCode === API_UNLOGGED_USER_ERROR_CODE
            ) {
                dispatch(handleUserSetting(NOT_LOGGED_USER_DATA));
            } else {
                throw new Error(apiResponse.message);
            }
        } else {
            dispatch(handleUserSetting(apiResponse.data));
        }
    };

export const { setGrid, setUser, setLoggedOffUser, updateColorTheme } = userSlice.actions;

export default userSlice.reducer;
