import React, { FC } from 'react';
import { DynamicNumberProps } from 'src/components/shared/atoms/dynamicNumber/types';
import { TimeManager } from 'src/utils/src/shared/TimeManager';
import { UiInputNormal } from 'src/components/shared/atoms/uiInputs/uiInputNormal/UiInputNormal';
import { NumberManager } from 'src/utils/src/shared/NumberManager';
import { UiContainerInputsNormal } from 'src/components/shared/atoms/uiLayout/uiContainerInputsNormal/UiContainerInputsNormal';

export const DynamicNumber: FC<DynamicNumberProps> = ({
    field,
    isDisabledLabel,
    dynamicFieldT,
    fieldValue
}) => {
    const { nazwa, przeliczanie_minut_i_godzin } = dynamicFieldT;
    const value = fieldValue.value;

    const data = {
        formattedValue:
            NumberManager.countDecimals(value) > 3
                ? String(Number(value).toFixed(3))
                : String(value)
    };
    const ubdateTimeDecimal = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(e.target.value))) return;
        field.onChange({
            ...fieldValue,
            value: e.target.value
        });
    };

    const ubdateTimeNormal = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = TimeManager.setMinutesAbsoluteToHour(Number(e.target.value));
        field.onChange({
            ...fieldValue,
            value: String(value.toFixed(3))
        });
    };

    if (przeliczanie_minut_i_godzin === 't') {
        return (
            <UiContainerInputsNormal>
                <UiInputNormal
                    label={isDisabledLabel ? '' : nazwa}
                    onChange={ubdateTimeDecimal}
                    value={data.formattedValue}
                />
                <UiInputNormal
                    label={isDisabledLabel ? '' : 'Poświęcony czas w min.'}
                    onChange={ubdateTimeNormal}
                    value={TimeManager.devideHoursToAbsoluteMinutes(Number(data.formattedValue))}
                />
            </UiContainerInputsNormal>
        );
    }

    return <UiInputNormal label={nazwa} onchange={ubdateTimeDecimal} value={data.formattedValue} />;
};
