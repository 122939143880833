import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MessageState, UpdateMessagePayload } from './types';
import { ApiMessageData } from 'src/api/src/messageScreen/types';
import { messageScreenRowWithoutIdList } from 'src/constants/messageScreen/messageScreenRow';
import { MessageStateManager } from 'src/store/src/messageScreen/message/MessageStateManager';
import { messageScreenRowHidden } from 'src/constants/messageScreen/messageScreenRowHidden';

export const initialState: MessageState = {
    fields: {},
    row: [],
    rowHidden: [],
    isLoading: true
};

const messageSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setMessageData: (state: MessageState, action: PayloadAction<ApiMessageData>) => {
            const messageRow = messageScreenRowWithoutIdList;
            return {
                ...state,
                isLoading: false,
                row: new MessageStateManager({
                    messageRow,
                    messageFields: action.payload.fields
                }).setDataFromApi(),
                rowHidden: new MessageStateManager({
                    messageRow: messageScreenRowHidden,
                    messageFields: action.payload.fields
                }).setDataFromApi(),
                template: action.payload.template,
                fields: action.payload.fields
            };
        },
        updateMessageDataField: (
            state: MessageState,
            action: PayloadAction<UpdateMessagePayload>
        ) => {
            return {
                ...state,
                row: new MessageStateManager({
                    messageRow: state.row
                }).updateData(action.payload)
            };
        }
    }
});

export const { setMessageData, updateMessageDataField } = messageSlice.actions;

export default messageSlice.reducer;
