import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import styles from './PopupTemplateHead.module.css';

type Props = {
    title: string;
};

const PopupTemplateHead: FC<Props> = ({ title }) => {
    const theme = useTheme();

    return (
        <div
            style={{
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                borderTop: `1px solid ${theme.palette.primary.main}`
            }}
            className={styles.wrapper}>
            <p
                style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold'
                }}
                className={`${styles.p}`}>
                {title}
            </p>
        </div>
    );
};

export default PopupTemplateHead;
