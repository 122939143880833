import differenceInDays from 'date-fns/differenceInDays';
import { CalendarDays, NoGridEvent } from '../../store/src/calendar/calendar/types';
import { EventStub } from '../types';

/**
 * Takes event stubs and create well formed NoGridEvents. It adds left offset and width percent values
 */
export function processNoGridEvents(events: EventStub[], days: CalendarDays): NoGridEvent[] {
    /* calendar days length */
    const length = days.length;

    /* empty result to fill */
    const result: NoGridEvent[] = [];

    for (const ev of events) {
        /* How many days from start */
        const startOffset = differenceInDays(ev.startDayTimestamp, days[0].startTimestamp);

        /* How many days from end */
        const endOffset = differenceInDays(days[length - 1].startTimestamp, ev.endDayTimestamp);

        /* Percentage width */
        const width = ((length - startOffset - endOffset) / length) * 100;

        /* Percentage left offset */
        const leftOffset = (startOffset / length) * 100;

        /* Pull out properties needed */
        const { endDayTimestamp, id, startDayTimestamp } = ev;

        result.push({ endDayTimestamp, id, startDayTimestamp, leftOffset, width, type: ev.type });
    }

    return result.sort((a, b) => {
        if (a.endDayTimestamp - b.endDayTimestamp === 0)
            return a.startDayTimestamp - b.startDayTimestamp;
        return a.endDayTimestamp - b.endDayTimestamp;
    });
}
