import React from 'react';
import { useTypedSelector } from 'src/store';
import Chat from 'src/widgets/chat/containers/Chat';
import NotesApp from 'src/widgets/notes/containers/NotesApp';
import useDetectWindowSize from 'src/widgets/shared/hooks/useDetectWindowSize';
import TimersApp from 'src/widgets/timers/containers/TimersApp';
import TodoApp from 'src/widgets/todo/TodoApp';
import { ThemeProvider } from '@material-ui/styles';
import { createWidgetsTheme } from 'src/styles/widgetsTheme';
import styles from './WidgetsSection.module.css';

const WidgetsSection = () => {
    const userState = useTypedSelector((state) => state.user);
    const { systemUrl, nodeUrl, color } = useTypedSelector((state) => state.system);

    const viewport = useDetectWindowSize();

    const config = {
        system_url: systemUrl ?? '',
        node_url: nodeUrl ?? '',
        userID: userState.userID?.toString() ?? '',
        userType: userState.userType ?? '',
        sessionID: userState.sessionID,
        todo: userState.todo
    };

    return (
        <div className={styles.wrapper}>
            <ThemeProvider theme={createWidgetsTheme(viewport, color)}>
                {userState.chat && <Chat viewport={viewport} config={config} />}
                {userState.notes && <NotesApp viewport={viewport} systemUrl={systemUrl} />}
                {userState.todo && <TodoApp systemUrl={systemUrl} />}
                {userState.timers && <TimersApp viewport={viewport} />}
            </ThemeProvider>
        </div>
    );
};

export default React.memo(WidgetsSection);
