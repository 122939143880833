import {
    GroupFieldSection,
    RelationCardSection,
    RelationGroupSection,
    RelationListSection,
    RelationCardList,
    RelationCardTree,
    RelationGroup,
    ObjectScreenSection,
    ListCellRow,
    ListCardSublist,
    ObjectSlice,
    RelationTreeCardElement,
    ObjectScreenField,
    CellValue
} from 'src/store/src/object/object/types';
import { Additional } from 'src/store/src/object/object/types';
import {
    getCardTreeElementsFileIds,
    getCardTreeElementsFileIdsNumbers,
    getCardTreeElementsFlatten
} from 'src/utils';

type ObjectDataManagerProps = {
    objectSlice: ObjectSlice;
    additional: Additional;
};
export class ObjectDataManager {
    objectSlice: ObjectSlice;
    additional: Additional;
    groupFieldSection?: GroupFieldSection;
    relationListSection?: RelationListSection;
    relationCardSection?: RelationCardSection;
    relationGroupSection?: RelationGroupSection;
    relationCardList?: RelationCardList;
    relationCardTree?: RelationCardTree;
    relationTreeCardElement?: RelationTreeCardElement;
    relationTreeCardElementsFlatten?: RelationTreeCardElement[];
    relationTreeCardElementsFileIds?: string;
    relationTreeCardElementsFileIdsNumbers?: number;
    relationGroup?: RelationGroup;
    objectScreenSection?: ObjectScreenSection;
    objectScreenFields?: ObjectScreenField[];
    objectScreenField?: ObjectScreenField;
    listCardSublist?: ListCardSublist;
    listCellRow?: ListCellRow;
    listCellRowCellValues?: CellValue[];
    cellValue?: CellValue;
    constructor({ objectSlice, additional }: ObjectDataManagerProps) {
        this.objectSlice = objectSlice;
        this.additional = additional;

        this.prepareDataFromAdditional();
    }

    protected prepareDataFromAdditional() {
        this.getSection();
        this.getFields();
        this.getField();
        this.getRelationCard();
        this.getRelationGroup();
        this.getRelationCardSublist();
        this.getListCellRow();
        this.getListCellRowCellValues();
        this.getCellValue();
        this.getRelationTreeCardElement();
        this.getRelationTreeCardElement();
        this.getRelationTreeCardElementsFlatten();
        this.getRelationTreeCardElementsFileIds();
        this.getRelationTreeCardElementsFileIdsNumbers();
    }

    protected getSection() {
        this.objectScreenSection = this.objectSlice.activeTabData?.sections.find(
            (item) => item.id === this.additional.sectionId
        );
        switch (this.objectScreenSection?.relationDisplayMode) {
            case 'grupa':
                this.relationGroupSection = this.objectScreenSection;
                break;
            case 'none':
                this.groupFieldSection = this.objectScreenSection;
                break;
            case 'karta':
                this.relationCardSection = this.objectScreenSection;
                break;
            case 'lista':
                this.relationListSection = this.objectScreenSection;
                break;
            default:
                break;
        }
    }

    protected getFields() {
        this.objectScreenFields = this.objectScreenSection?.fields;
    }

    protected getField() {
        if (this.additional.fieldIdx !== undefined && this.objectScreenFields) {
            this.objectScreenField = this.objectScreenFields.find(
                (data, index) => index === this.additional.fieldIdx
            );
        }
    }

    protected getRelationGroup() {
        this.relationGroup = this.relationGroupSection?.relations?.find(
            (item) => item.id === this.additional.relationId
        );
    }

    protected getRelationCard() {
        const relationCard = this.relationCardSection?.relations?.find(
            (item) => item.id === this.additional.relationId
        );
        switch (relationCard?.type) {
            case 'list':
                this.relationCardList = relationCard;
                break;
            case 'tree':
                this.relationCardTree = relationCard;
                break;
            default:
                break;
        }
    }

    protected getRelationCardSublist() {
        if (this.relationCardList && this.additional.sublistIdx !== undefined) {
            this.listCardSublist = this.relationCardList.sublists[this.additional.sublistIdx];
        }
    }

    protected getListCellRow() {
        if (this.relationListSection) {
            this.listCellRow = this.relationListSection.relations?.activeSublistData.rows.find(
                (row) => row.id === this.additional.listCellRowId
            );
        } else if (this.relationGroup) {
            this.listCellRow = this.relationGroup.rows.find(
                (row) => row.id === this.additional.listCellRowId
            );
        } else if (this.listCardSublist) {
            this.listCellRow = this.listCardSublist.values.find(
                (row) => row.id === this.additional.listCellRowId
            );
        }
    }

    protected getListCellRowCellValues() {
        if (this.listCellRow && this.additional.cellBodyColumnCode) {
            this.listCellRowCellValues =
                this.listCellRow.cellValues[this.additional.cellBodyColumnCode];
        }
    }

    protected getCellValue() {
        if (this.objectScreenField && this.additional.fieldValueIdx !== undefined) {
            this.cellValue = this.objectScreenField.values.find(
                (data, index) => index === this.additional.fieldValueIdx
            );
        }
        if (this.listCellRowCellValues && this.additional.listCellRowCellValueIdx !== undefined) {
            this.cellValue = this.listCellRowCellValues.find(
                (data, index) => index === this.additional.listCellRowCellValueIdx
            );
        }
    }

    protected getRelationTreeCardElement() {
        if (this.relationCardTree && this.additional.relationCardTreeElementId) {
            this.relationTreeCardElement = this.findElementByIdRecursive(
                this.relationCardTree.elements,
                this.additional.relationCardTreeElementId
            );
        }
    }
    protected findElementByIdRecursive(
        array: RelationTreeCardElement[],
        id: string
    ): RelationTreeCardElement | undefined {
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element.id === id) {
                return element;
            } else {
                if (element.elements) {
                    const found = this.findElementByIdRecursive(element.elements, id);

                    if (found) {
                        return found;
                    }
                }
            }
        }
    }
    protected getRelationTreeCardElementsFlatten() {
        if (this.relationTreeCardElement) {
            this.relationTreeCardElementsFlatten = getCardTreeElementsFlatten([
                this.relationTreeCardElement
            ]);
        }
    }

    protected getRelationTreeCardElementsFileIds() {
        if (this.relationTreeCardElementsFlatten) {
            this.relationTreeCardElementsFileIds = getCardTreeElementsFileIds(
                this.relationTreeCardElementsFlatten,
                this.additional.relationCardTreeElementFileId ?? ''
            );
        }
    }

    protected getRelationTreeCardElementsFileIdsNumbers() {
        if (this.relationTreeCardElementsFileIds) {
            this.relationTreeCardElementsFileIdsNumbers = getCardTreeElementsFileIdsNumbers(
                this.relationTreeCardElementsFileIds
            );
        }
    }

    getCellRawValueOrText({
        key,
        type,
        onlyFirstValue
    }: {
        key: string;
        type: 'text' | 'rawValue';
        onlyFirstValue: boolean;
    }) {
        if (this.listCellRow) {
            const cell = this.listCellRow.cellValues[key];
            if (Array.isArray(cell)) {
                if (onlyFirstValue) {
                    return cell[0][type];
                } else {
                    const rawValue = cell?.map((item) => item[type]);
                    return rawValue;
                }
            }
        }
        if (this.cellValue) {
            return this.cellValue[type];
        }
        return null;
    }

    getData() {
        return this;
    }
}
