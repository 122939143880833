import {
    BudgetScreenState,
    SettledSingleElements,
    SettleObject,
    HoursLimitData
} from 'src/store/src/budget/budget/types';
import { updateMemoMonthKeyInBudget } from './updateMemoMonthKeyInBudget';

export function toggleAllHiddenSettledObject(state: BudgetScreenState) {
    return state.settlementMethods.map((method) => {
        return {
            ...method,
            settledElements: method.settledElements.map((settledElement) => {
                return {
                    ...settledElement,
                    objects: settledElement?.objects?.map((object) =>
                        handleObjectVisibilityOnChange(object, state)
                    )
                };
            }),
            settledSingleElements: hanndleSingleElementsVisibilityOnChange(
                method.settledSingleElements,
                state
            ),
            years: method.years.map((year) => {
                return {
                    ...year,
                    months: year?.months?.map((month) => {
                        return {
                            ...month,
                            memoKey: updateMemoMonthKeyInBudget(month.memoKey),
                            settledElements: month?.settledElements?.map((settledElement) => {
                                return {
                                    ...settledElement,
                                    objects: settledElement?.objects?.map((object) =>
                                        handleObjectVisibilityOnChange(object, state)
                                    )
                                };
                            }),
                            settledSingleElements: hanndleSingleElementsVisibilityOnChange(
                                month.settledSingleElements,
                                state
                            ),
                            hoursLimit: handleHoursLimitVisibilityOnChange(
                                month?.hoursLimit,
                                state
                            ),
                            cases: month?.cases?.map((caseItem) => {
                                return {
                                    ...caseItem,
                                    settledElements: caseItem?.settledElements?.map(
                                        (settledElement) => {
                                            return {
                                                ...settledElement,
                                                objects: settledElement?.objects?.map((object) =>
                                                    handleObjectVisibilityOnChange(object, state)
                                                )
                                            };
                                        }
                                    )
                                };
                            }),
                            projects: month?.projects?.map((project) => {
                                return {
                                    ...project,
                                    settledElements: project?.settledElements?.map(
                                        (settledElement) => {
                                            return {
                                                ...settledElement,
                                                objects: settledElement?.objects?.map((object) =>
                                                    handleObjectVisibilityOnChange(object, state)
                                                )
                                            };
                                        }
                                    )
                                };
                            })
                        };
                    })
                };
            })
        };
    });
}

function handleObjectVisibilityOnChange(object: SettleObject, state: BudgetScreenState) {
    if (object.isCanChangeVisibility) {
        return {
            ...object,
            hidden: state.isAllSettledObjectHidden
        };
    }
    return {
        ...object
    };
}
function hanndleSingleElementsVisibilityOnChange(
    el: SettledSingleElements,
    state: BudgetScreenState
): SettledSingleElements {
    const data = {
        ...el
    };

    for (const key in el) {
        const settleElement = el?.[key as keyof SettledSingleElements];
        if (settleElement.isCanChangeVisibility) {
            data[key as keyof SettledSingleElements] = {
                ...settleElement,
                hidden: state.isAllSettledObjectHidden
            };
        }
    }
    return data;
}

function handleHoursLimitVisibilityOnChange(
    hoursLimit: HoursLimitData | undefined,
    state: BudgetScreenState
) {
    if (hoursLimit && hoursLimit.isCanChangeVisibility) {
        return {
            ...hoursLimit,
            hidden: state.isAllSettledObjectHidden
        };
    }
    return hoursLimit;
}
