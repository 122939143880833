import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import { useTypedSelector } from 'src/store';
import styles from './UiNavMenuListWrapper.module.css';
type Props = {};
export const UiNavMenuListWrapper: FC<Props> = ({ children }) => {
    const menuIsUnfolded = useTypedSelector((state) => state.menu.isUnfolded);

    const theme = useTheme();
    return (
        <Box
            className={styles.listWrapper}
            sx={{
                '&:after': {
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                    width: `${menuIsUnfolded ? '80%' : '60%'}`,
                    zIndex: '10' //fixmenu
                }
            }}>
            {children}
        </Box>
    );
};
