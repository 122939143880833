import { CHRISTMASS_LOGO_END_MM_DD, CHRISTMASS_LOGO_START_MM_DD } from 'src/constants';
import defaultMecenasLogo from 'src/assets/logo/logo.png';
import christmasMecenasLogo from 'src/assets/logo/logo_swieta.png';

export class FestivitiesIconsProvider {
    now: Date;
    currentYear: number;

    constructor() {
        this.now = new Date();
        this.currentYear = this.now.getFullYear();
    }

    private isChristmas() {
        const christmas_start = new Date(`${this.currentYear}-${CHRISTMASS_LOGO_START_MM_DD}`);
        const christmas_end = new Date(`${this.currentYear}-${CHRISTMASS_LOGO_END_MM_DD}`);

        // Check if current date is within the range
        return this.now >= christmas_start && this.now <= christmas_end;
    }

    getMenuLogo(): string {
        if (this.isChristmas()) {
            return christmasMecenasLogo;
        }

        return defaultMecenasLogo;
    }

    getLoginHeaderLogo(): string {
        if (this.isChristmas()) {
            return './grafika_new/loga_swieta/logo_swieta.png';
        }

        return './grafika_new/logo_mecenasit.png';
    }

    getLoginSign(): string {
        if (this.isChristmas()) {
            return './grafika_new/loga_swieta/logo_swieta_ikona.png';
        }

        return './grafika_new/mecenasit_logo_znak.svg';
    }

    // used logos as parameters to get rid of possible heavy imports on every use of this class
    getStartupLogo<T>(logoBase: T, logoChristmass: T): T {
        if (this.isChristmas()) {
            return logoChristmass;
        }

        return logoBase;
    }
}
