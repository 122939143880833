import React, { useState } from 'react';
import OpenTimersBtn from 'src/widgets/shared/components/openButton/OpenButton';
import { topMenu } from 'src/constants/subtitles';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import TimerIcon from 'src/assets/ikona_stoper.svg';
import TimerTop from './TimerTop';

const TimersApp = ({ viewport }) => {
    const [timersOpen, setTimersOpen] = useState(false);
    const [timer, setTimer] = useState(false);
    const handleTimersOpen = () => {
        setTimersOpen((prev) => !prev);
    };

    const handleLoad = () => {
        if (!timer) setTimer(true);
    };
    return (
        <>
            <OpenTimersBtn
                handleOpen={handleTimersOpen}
                handleLoad={handleLoad}
                tooltipText={topMenu.tooltipOnHover.timer}
                iconComponent={
                    <ThemeCompatibleIcon
                        SvgComponent={TimerIcon}
                        fontSize={'small'}
                        sx={{
                            position: 'absolute',
                            fontSize: '1rem',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)'
                        }}
                    />
                }
            />
            <TimerTop viewport={viewport} handleOpen={handleTimersOpen} timersOpen={timersOpen} />
        </>
    );
};
export default TimersApp;
