import { PopupFormExtendedData, PopupFormRelatedObject } from 'src/data/popupFormTypes';
import { FormState } from 'src/components/popupFormExtended/dynamicFormObject/types';
import {
    FieldsPure,
    FlagsFieldUpdated,
    Fields
} from 'src/components/popupFormExtended/dynamicField/types';
import { TempFieldsState } from 'src/components/popupFormExtended/fieldGlobalDependent/types';
import { FieldExtenderProps } from './types';

export class FieldExtender {
    formState: FormState;
    popupFormExtendedData: PopupFormExtendedData;
    tempFieldsState: TempFieldsState;
    prefix?: string;
    relationId?: string;
    relation?: PopupFormRelatedObject;
    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        prefix,
        relationId
    }: FieldExtenderProps) {
        this.formState = formState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.tempFieldsState = tempFieldsState;
        this.relationId = relationId;
        this.prefix = prefix;
        this.checkAndSetRelation();
    }

    private checkAndSetRelation() {
        if (this.relationId) {
            this.relation = this.popupFormExtendedData.relations.find(
                (item) => item.id === this.relationId
            );
        }
    }

    protected getScreen() {
        if (this.relation) return this.relation.targetScreenCode;

        return this.popupFormExtendedData.screen;
    }

    protected findDynamicFieldTById(id: string) {
        let dynamicFieldT = this.popupFormExtendedData.fields.find((item) => item.id === id);
        if (!dynamicFieldT && this.relation) {
            dynamicFieldT = this.relation.fields.find((item) => item.id === id);
        }
        if (dynamicFieldT) {
            return dynamicFieldT;
        }
        return null;
    }

    protected findStateOfFieldsByCode(code?: string) {
        let stateOfField: Fields | undefined;
        if (code) {
            const allCode = this.prefix ? this.prefix + code : code;

            for (const item in this.tempFieldsState) {
                if (item === allCode) {
                    stateOfField = this.tempFieldsState[item];
                }
            }
            if (!stateOfField) {
                for (const item in this.formState.fields) {
                    if (item === allCode) {
                        stateOfField = this.formState.fields[item];
                    }
                }
            }
        }
        return stateOfField;
    }

    protected findStateOfFieldsById(id?: string) {
        let stateOfField: Fields | undefined;
        if (id) {
            for (const item in this.tempFieldsState) {
                if (this.tempFieldsState[item]?.id === id) {
                    stateOfField = this.tempFieldsState[item];
                }
            }
            if (!stateOfField) {
                for (const item in this.formState.fields) {
                    if (this.formState.fields[item]?.id === id) {
                        stateOfField = this.formState.fields[item];
                    }
                }
            }
        }
        return stateOfField;
    }

    protected setTempFieldsState(fieldState: FieldsPure, type: FlagsFieldUpdated) {
        let updatedNumber = fieldState.updatedNumber ?? 0;
        updatedNumber++;

        switch (type) {
            case 'isUpdatedDepedententDefault':
                this.tempFieldsState[fieldState.fieldKey] = {
                    ...fieldState,
                    isUpdatedDepedententDefault: true,
                    updatedNumber
                };
                break;
            case 'isUpdatedVisibility':
                this.tempFieldsState[fieldState.fieldKey] = {
                    ...fieldState,
                    isUpdatedVisibility: true,
                    updatedNumber
                };
                break;
            case 'isUpdatedDepedentent':
                this.tempFieldsState[fieldState.fieldKey] = {
                    ...fieldState,
                    isUpdatedDepedentent: true,
                    updatedNumber
                };
                break;
            case 'isUpdatedCalc':
                this.tempFieldsState[fieldState.fieldKey] = {
                    ...fieldState,
                    isUpdatedCalc: true,
                    updatedNumber
                };
                break;
            case 'isUpdatedValueFromSuperior':
                this.tempFieldsState[fieldState.fieldKey] = {
                    ...fieldState,
                    isUpdatedValueFromSuperior: true,
                    updatedNumber
                };
                break;
            case 'withoutFlag':
                this.tempFieldsState[fieldState.fieldKey] = {
                    ...fieldState,
                    updatedNumber
                };
                break;
            default:
                break;
        }
    }
}
