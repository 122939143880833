import React, { FC } from 'react';

import { UiCheckbox } from 'src/components/shared/atoms/uiInputs/uiCheckbox/UiCheckbox';
import { DynamicCheckboxProps } from 'src/components/shared/atoms/dynamicCheckbox/types';

export const DynamicCheckbox: FC<DynamicCheckboxProps> = ({ field, dynamicFieldT, fieldValue }) => {
    return (
        <>
            <UiCheckbox
                label={dynamicFieldT.nazwa}
                {...field}
                onChange={() =>
                    field.onChange({
                        ...fieldValue,
                        value: !fieldValue.value
                    })
                }
                checked={fieldValue.value}
            />
        </>
    );
};
