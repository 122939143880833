import React, { FC, useMemo } from 'react';

import { FieldListForeignUnmodifiedProps } from './types';
import { FieldWrapperUnmodified } from 'src/components/popupFormExtended/dynamicField/shared/fieldWrapperUnmodified/FieldWrapperUnmodified';
import { useSearchParam } from 'src/hooks';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import styles from './FieldListForeignUnmodified.module.css';

export const FieldListForeignUnmodified: FC<FieldListForeignUnmodifiedProps> = ({
    dynamicFieldT,
    uiFieldCommonProps,
    field
}) => {
    const screen = useSearchParam('obiekt');

    const linkData = useMemo(() => {
        if (dynamicFieldT.link === 't')
            return {
                id: dynamicFieldT.link_id ?? '',
                screen: screen ?? ''
            };
        // if reporoducing old tpl if strpos($item.tabela_opcji,'_')==false
        if (!dynamicFieldT.tabela_opcji || dynamicFieldT.tabela_opcji.indexOf('_') < 1)
            return {
                id: `${dynamicFieldT.domyslna_wartosc}`,
                screen: dynamicFieldT.tabela_opcji ?? ''
            };
        return null;
    }, [
        dynamicFieldT.link_id,
        dynamicFieldT.link,
        dynamicFieldT.domyslna_wartosc,
        dynamicFieldT.tabela_opcji,
        screen
    ]);

    return (
        <FieldWrapperUnmodified
            dynamicFieldT={dynamicFieldT}
            field={field}
            isLabel={Boolean(uiFieldCommonProps?.isLabel)}>
            {linkData ? (
                <LegacyCompatibleLink
                    url="ekran_obiektu.php"
                    queryParams={
                        new URLSearchParams([
                            ['id', linkData.id],
                            ['obiekt', linkData.screen]
                        ])
                    }>
                    <p className={styles.pointer}>{field.value.text}</p>
                </LegacyCompatibleLink>
            ) : (
                <p>{field.value.text}</p>
            )}
        </FieldWrapperUnmodified>
    );
};
