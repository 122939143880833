import { AppThunk } from 'src/store';
import { getInitialObjectScreenData } from 'src/api/src/object/getInitialObjectScreenData';
import {
    clearObjectData,
    setInitData,
    setSublistData,
    setTabData
} from 'src/store/src/object/object/objectSlice';
import { RelationId, TabId } from 'src/store/src/object/object/types';
import { getObjectScreenTabData } from 'src/api/src/object/getObjectScreenTabData';
import { getObjectScreenSublistData } from 'src/api/src/object/getObjectScreenSublistData';
import { ApiError } from 'src/utils';

export const fetchAndLoadInitialObjectData =
    (screen: string, id: string, tabId: string | null, sectionParams?: string[][]): AppThunk =>
    async (dispatch) => {
        if (!id || !screen) {
            console.error('fetchAndLoadInitialObjectData: cannot get id and obiekt params');
            return;
        }

        const apiResponse = await getInitialObjectScreenData({
            tabId: tabId ?? undefined,
            objectId: id,
            screen: screen,
            sectionParams
        });
        if (!apiResponse.success) {
            // instead of window.location.replace(`/message?tytul=${apiResponse.message}`); show popup, to not crash logout made by CheckTime.tsx
            ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
            throw new Error('fetchAndLoadInitialObjectData: not get InitialObjectScreenData ');
        }
        dispatch(setInitData(apiResponse.data));
    };

export const fetchAndLoadObjectScreenTabData =
    (tabId: TabId, sectionParams: string[][]): AppThunk =>
    async (dispatch) => {
        const queryString = window.location.search;

        const screen = new URLSearchParams(queryString).get('obiekt');
        const id = new URLSearchParams(queryString).get('id');
        if (!id || !screen) {
            console.error('fetchAndLoadObjectScreenTabData: cannot get id and obiekt params');
            return;
        }
        // TODO: to remove

        const apiResponse = await getObjectScreenTabData({
            tabId: tabId,
            objectId: id,
            screen: screen,
            sectionParams
        });
        if (!apiResponse.success) {
            throw new Error('fetchAndLoadInitialObjectData: not get InitialObjectScreenData ');
        }
        dispatch(setTabData({ ...apiResponse.data, tabId: tabId }));
    };

export const fetchAndLoadObjectScreenSublistData =
    (sublistId: RelationId, sectionIdx: number): AppThunk =>
    async (dispatch) => {
        const queryString = window.location.search;

        const screen = new URLSearchParams(queryString).get('obiekt');
        const id = new URLSearchParams(queryString).get('id');
        if (!id || !screen) {
            console.error('fetchAndLoadObjectScreenTabData: cannot get id and obiekt params');
            return;
        }
        // TODO: to remove

        const apiResponse = await getObjectScreenSublistData({
            sublistId: sublistId,
            objectId: id,
            screen: screen
        });
        if (!apiResponse.success) {
            throw new Error('fetchAndLoadInitialObjectData: not get InitialObjectScreenData ');
        }
        dispatch(setSublistData({ ...apiResponse.data, sectionIdx, sublistId }));
    };

export const clearObjectScreenData = (): AppThunk => async (dispatch) => {
    dispatch(clearObjectData());
};
