import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldNumberSettledToZero } from 'src/components/popupFormExtended/dynamicField/fieldNumberSettledToZero/UIFieldNumberSettledToZero';
import { FieldNumberSettledToZeroObjectProps } from 'src/components/popupFormExtended/dynamicField/fieldNumberSettledToZero/types';
import { DataFromApiConverter } from '../../../../utils/src/shared/DataFromApiConverter';
import { dataSetInputKey } from 'src/constants/dataset';
import { FieldNumberSettledToZeroBasicTypeValue } from 'src/data/fieldsFormType';

export const FieldNumberSettledToZeroObject: FC<FieldNumberSettledToZeroObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);

    // make calculation to end value match "baseValue - settled = restToSettle"
    function calcValue(
        value: FieldNumberSettledToZeroBasicTypeValue,
        changedKey: keyof FieldNumberSettledToZeroBasicTypeValue
    ) {
        const baseValue = DataFromApiConverter.getNumberFiedNumber(value.baseValue);
        if (baseValue === null) return value;

        if (changedKey === 'restToSettle') {
            const restToSettle = DataFromApiConverter.getNumberFiedNumber(value.restToSettle);
            if (restToSettle !== null) value.settled = String(baseValue - restToSettle);
            return value;
        }

        const settled = DataFromApiConverter.getNumberFiedNumber(value.settled);
        if (settled !== null) value.restToSettle = String(baseValue - settled);
        return value;
    }

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        inputType: keyof FieldNumberSettledToZeroBasicTypeValue
    ) => {
        setState((state) =>
            calcValue(
                {
                    ...state,
                    [inputType]: e.target.value
                },
                inputType
            )
        );
    };

    return (
        <UIFieldNumberSettledToZero
            value={state}
            onChange={onChange}
            onBlur={(_) => {
                dispatch({
                    type: 'liczba_rozliczana_do_zera',
                    payload: {
                        code: keyOfFieldInState,
                        value: {
                            ...field,
                            value: state
                        }
                    }
                });
            }}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
