import React, { FC } from 'react';

import { useTypedSelector, useAppDispatch } from 'src/store';
import { ApiActionBehavior, FileDownloadBehavior } from 'src/data/types';
import { FileManager } from 'src/utils';
import { setProgress } from 'src/store/src/general';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { FileDownloadHelper } from 'src/components/object/atoms/actionType/fileDownload/FileDownloadHelper';
import { FileDownloadProps } from './types';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { TemplateMainManager } from 'src/utils/src/shared/TemplateMainManager';

export const FileDownload: FC<FileDownloadProps> = ({ children, action, additional, id }) => {
    const behaviour = action.behaviour as FileDownloadBehavior;
    const dispatch = useAppDispatch();
    const objectSlice = useTypedSelector((state) => state.object);
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const [isDownloaded, setIsDownloaded] = React.useState<boolean>(false);
    const sourceOfTrigger: SourceOfTrigger = { type: 'object', typeWew: additional.typeSection };

    const downloadFromSectionCard = async () => {
        const payload = new FileDownloadHelper({
            objectSlice,
            additional
        }).getPayloadForSectionCardForDownload({
            id,
            action
        });
        await FileManager.handleDownloadFile({
            url: behaviour?.data?.url,
            payload,
            dispatch,
            setProgress,
            setSpinner
        });
    };
    const download = async () => {
        const { payload, params } = new TemplateMainManager({
            rowData,
            transformationState,
            actionBehavior: action.behaviour as ApiActionBehavior,
            action,
            objectSlice,
            sourceOfTrigger,
            additional
        }).getData();
        await FileManager.handleDownloadFile({
            url: behaviour?.data?.url,
            payload: payload,
            params,
            dispatch,
            setProgress,
            setSpinner
        });
    };

    const handleFileDownload = async () => {
        if (isDownloaded) return;
        if (additional.isCardTree) {
            setIsDownloaded(true);
            await downloadFromSectionCard();
        } else {
            await download();
        }
    };

    return <div onClick={() => handleFileDownload()}>{children}</div>;
};
