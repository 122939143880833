import { ListCellRow, ListColumn } from 'src/store/src/object/object/types';
import { formatNumberSpan } from 'src/utils/src/shared/formatNumberSpan';
import { getVat } from 'src/utils/src/shared/getVat';
import { normaliseStringNumber } from 'src/utils/src/shared/normaliseStringNumber';

type SumCellManagerProps = {
    column: ListColumn;
    rows: ListCellRow[];
};

export class SumCellManager {
    column: ListColumn;
    rows: ListCellRow[];
    numbersToSumOne: number[];
    numbersToSumTwo: number[];
    sums: string[];
    constructor({ rows, column }: SumCellManagerProps) {
        this.column = column;
        this.rows = rows;
        this.numbersToSumOne = [];
        this.numbersToSumTwo = [];
        this.sums = [];
    }
    execute() {
        this.choose();
        return this.sums;
    }

    private choose() {
        switch (this.column.columnType) {
            case 'cena_liczba': {
                this.calcPriceNumber();
                break;
            }
            case 'cena': {
                this.calcPrice();
                break;
            }
            case 'liczba':
                this.calcNumber();
                break;
            case 'koszty':
                this.calcCosts();
                break;
            default:
                break;
        }
    }

    private calcPrice() {
        const divided = this.divideByCurrency();
        for (const key in divided) {
            let currency = 'PLN';
            divided[key].forEach((item) => {
                const data = item.cellValues?.[this.column.code]?.[0]?.['rawValue'];
                currency = data[`${this.column.code}_waluta`];
                if (data[this.column.code] && data[`${this.column.code}_vat`]) {
                    const num = Number(normaliseStringNumber(data[this.column.code]));
                    this.numbersToSumOne.push(num);
                    this.numbersToSumTwo.push(num * getVat(data[`${this.column.code}_vat`]));
                }
            });
            const sumNetto = this.formatSum(this.getSums(this.numbersToSumOne));
            const sumBrutto = this.formatSum(this.getSums(this.numbersToSumTwo));
            this.numbersToSumOne = [];
            this.numbersToSumTwo = [];
            this.sums.push(`Razem: ${sumNetto} netto ${sumBrutto} brutto ${currency}`);
        }
    }

    private calcPriceNumber() {
        const divided = this.divideByCurrency();
        for (const key in divided) {
            let currency = 'PLN';
            divided[key].forEach((item) => {
                const data = item.cellValues?.[this.column.code]?.[0]?.['rawValue'];
                currency = data[`${this.column.code}_waluta`];
                if (
                    data[this.column.code] &&
                    data[`${this.column.code}_vat`] &&
                    data[`${this.column.code}_liczba`]
                ) {
                    const num =
                        Number(normaliseStringNumber(data[this.column.code])) *
                        Number(normaliseStringNumber(data[`${this.column.code}_liczba`]));
                    this.numbersToSumOne.push(num);
                    this.numbersToSumTwo.push(num * getVat(data[`${this.column.code}_vat`]));
                }
            });
            const sumTotalNetto = this.formatSum(this.getSums(this.numbersToSumOne));
            const sumTotalBrutto = this.formatSum(this.getSums(this.numbersToSumTwo));
            this.numbersToSumOne = [];
            this.numbersToSumTwo = [];
            this.sums.push(`Razem: ${sumTotalNetto} netto ${sumTotalBrutto} brutto ${currency}`);
        }
    }

    private calcNumber() {
        this.rows.forEach((item) => {
            const number = item.cellValues?.[this.column.code]?.[0]?.['rawValue'];
            if (number && (typeof number === 'string' || typeof number === 'number')) {
                this.numbersToSumOne.push(Number(normaliseStringNumber(number)));
            }
        });
        const sum = this.formatSum(this.getSums(this.numbersToSumOne));
        this.sums.push(`Razem: ${sum}`);
    }

    private calcCosts() {
        const divided = this.divideByCurrency();

        for (const key in divided) {
            let currency = 'PLN';
            divided[key].forEach((item) => {
                const costs =
                    item.cellValues?.[this.column.code]?.[0]?.['rawValue']?.[this.column.code];
                currency =
                    item.cellValues?.[this.column.code]?.[0]?.['rawValue']?.[
                        `${this.column.code}_waluta`
                    ];
                if (costs) {
                    this.numbersToSumOne.push(Number(normaliseStringNumber(costs)));
                }
            });

            const sum = this.formatSum(this.getSums(this.numbersToSumOne));
            this.numbersToSumOne = [];
            this.sums.push(`Razem: ${sum} ${currency}`);
        }
    }

    private divideByCurrency() {
        const divided: { [key: string]: ListCellRow[] } = {};
        this.rows.forEach((item) => {
            const currency =
                item.cellValues?.[this.column.code]?.[0]?.['rawValue']?.[
                    `${this.column.code}_waluta`
                ];
            if (currency) {
                if (divided[currency]) {
                    divided[currency].push(item);
                } else {
                    divided[currency] = [item];
                }
            }
        });
        return divided;
    }

    private getSums(numbers: number[]) {
        return numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    private formatSum(sum: number) {
        return formatNumberSpan({ number: sum });
    }
}
