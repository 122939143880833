import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const BookCorrespondenceView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "BookCorrespondenceView" */ 'src/components/bookCorrespondence/templates/bookCorrespondenceView/BookCorrespondenceView'
        )
);

export const BookCorrespondenceMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Księgi korespondencji');

    return (
        <div>
            <React.Suspense fallback>
                <BookCorrespondenceView />
            </React.Suspense>
        </div>
    );
};
