import { getBudgetInitData } from 'src/api/src/budget/getBudgetInitData';
import { AppThunk } from 'src/store';
import { setInitBudgetData } from 'src/store/src/budget/budget/budgetSlice';
import { ApiError } from 'src/utils';

export const fetchAndLoadInitialBudgetScreenData =
    (obiekt: string, id: string, isLoadedBeforeYears: boolean): AppThunk =>
    async (dispatch) => {
        const apiResponse = await getBudgetInitData(obiekt, id, isLoadedBeforeYears ? '1' : '0');

        if (!apiResponse.success) {
            // instead of window.location.replace(`/message?tytul=${apiResponse.message}`); show popup, to not crash logout made by CheckTime.tsx
            ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
            throw new Error('fetchAndLoadInitialObjectData: not get InitialObjectScreenData ');
        }
        dispatch(setInitBudgetData(apiResponse.data));
    };
