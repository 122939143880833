import React, { FC } from 'react';

import ContentScrollTwo from 'src/components/shared/molecules/contentScrollTwo/ContentScrollTwo';
import styles from './ContentWrapper.module.css';
import { Content } from 'src/components/popupFormExtended/content/Content';
import { ContentWrapperProps } from 'src/components/popupFormExtended/types';
import PopupTemplateHead from 'src/components/shared/molecules/popupTemaplateHead/PopupTemplateHead';
import { usePopupState } from 'src/context/globalPopup/popupContext';
import { useComponentSize } from 'react-use-size';

export const ContentWrapper: FC<ContentWrapperProps> = ({ popup }) => {
    const { ref } = useComponentSize();
    const { popupStateContext, dispatchPopupStateContext } = usePopupState(popup.key);
    const poupData = popup?.popupFormExtendedData;
    const { isFixedWidth, maxContentWidth, breakpointWidth, width } = popupStateContext.dimensions;

    React.useEffect(() => {
        if (ref.current?.clientWidth && ref.current?.clientWidth !== width) {
            dispatchPopupStateContext({
                type: 'SET_WIDTH',
                payload: ref.current?.clientWidth
            });
        }
    }, [ref.current?.clientWidth]);

    if (poupData === null) {
        console.error('ContentWrapper: popupData is null');
        return null;
    }
    return (
        <div className={styles.wrapper} ref={ref}>
            <div className={styles.box}>
                <PopupTemplateHead title={poupData.title} />
                <ContentScrollTwo
                    isFixedWidth={isFixedWidth}
                    breakpointWidth={breakpointWidth}
                    maxWidth={maxContentWidth}
                    breakpointHeight={800}
                    maxHeight={500}>
                    <Content keyPopup={popup.key} />
                </ContentScrollTwo>
            </div>
        </div>
    );
};
