import React, { FC } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import { PasswordEndAdornmentIconProps } from './types';

const PasswordEndAdornmentIcon: FC<PasswordEndAdornmentIconProps> = ({
    handleClick,
    showPassword
}) => {
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <InputAdornment position="end" tabIndex={-1}>
            <IconButton
                tabIndex={-1}
                sx={{ marginRight: '0' }}
                aria-label="toggle password visibility"
                onClick={handleClick}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? (
                    <VisibilityOff fontSize="small" />
                ) : (
                    <Visibility fontSize="small" />
                )}
            </IconButton>
        </InputAdornment>
    );
};

export default PasswordEndAdornmentIcon;
