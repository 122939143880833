import { useEffect } from 'react';
import { AppDispatch, handleDays } from '../../../store/src/calendar/index';

/**
 * Hook dispatches handleDays
 */
export function useSetInitialDays(dispatch: AppDispatch): void {
    useEffect(() => {
        dispatch(handleDays());
    }, [dispatch]);
}
