import { DynamicFieldT } from 'src/data/popupFormTypes';
import { generateToken } from '../shared/generateToken';
import { prepareSubdirectoryPath } from 'src/utils/src/shared/prepareSubdirectoryPath';
import { Fields } from 'src/components/popupFormExtended/dynamicField/types';

export const generateAddBackUrl = (dynamicFieldT: DynamicFieldT, fieldSuperior: Fields) => {
    window.name = window.name ?? generateToken();

    const params = new URLSearchParams([
        ['window_name', window.name],
        ['obiekt', dynamicFieldT.tabela_opcji ?? ''],
        ['dodawanie_zwrotne', dynamicFieldT.kod]
    ]);

    if (dynamicFieldT.pole_nadrzedne_typ === 'powiazanie_typ') {
        if (!fieldSuperior) {
            console.error('generateAddBackUrl: fieldSuperior is empty');
            return '';
        }
        if (fieldSuperior.typ !== 'powiazanie_typ') {
            console.error("generateAddBackUrl: fieldSuperior types doesn't match");
            return '';
        }
        params.append('pole_nadrzedne_id', fieldSuperior.value.value ?? '');
        params.append('pole_nadrzedne_tab', fieldSuperior.value.type ?? '');
        params.append('pole_nadrzedne', dynamicFieldT.pole_nadrzedne ?? '');
        params.append('pole_zwrotne', dynamicFieldT.id);
    }

    if (dynamicFieldT.pole_nadrzedne_typ === 'lista_obca') {
        if (!fieldSuperior) {
            console.error('generateAddBackUrl: fieldSuperior is empty');
            return '';
        }
        if (fieldSuperior.typ !== 'lista_obca') {
            console.error("generateAddBackUrl: fieldSuperior types doesn't match");
            return '';
        }
        params.append('pole_nadrzedne_id', fieldSuperior.value.value ?? '');
        params.append('pole_nadrzedne_tab', dynamicFieldT.pole_nadrzedne_tabela_opcji ?? '');
        params.append('pole_nadrzedne', dynamicFieldT.pole_nadrzedne ?? '');
        params.append('pole_zwrotne', dynamicFieldT.id);
    }

    const subdirectoryPath = prepareSubdirectoryPath();
    return `${subdirectoryPath}/dodaj-obiekt?${params.toString()}`;
};
