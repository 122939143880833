import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import LoginLogoSign from '../loginLogoSign/LoginLogoSign';

const LoginMiddleSectionWrapper: FC = ({ children }) => {
    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{
                filter: 'drop-shadow(0 0 10px rgb(200, 200, 200))',
                position: 'relative',
                zIndex: 1,
                background: 'white',
                padding: 3
            }}>
            <Box
                sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <LoginLogoSign />
                {children}
            </Box>
        </Container>
    );
};

export default LoginMiddleSectionWrapper;
