import { FormStateFields } from '../../types';
import { DynamicFormObjectFileUploadingValidatorProps } from './types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';
import { FieldFileBasicTypeValue, FieldFileManyBasicTypeValue } from 'src/data/fieldsFormType';

const typeFnValidate = 'validateFileLoading';
export default class DynamicFormObjectFileUploadingValidator extends DynamicFormObjectHelperValidator {
    fields: FormStateFields;

    constructor({
        fields,
        dataRef,
        dispatchFormState
    }: DynamicFormObjectFileUploadingValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.fields = fields;
    }

    validate() {
        for (const fieldKey in this.fields) {
            const field = this.fields[fieldKey];

            // in fields could be possibly null
            if (!field) continue;

            if (field.typ === 'plik') {
                const value: FieldFileBasicTypeValue = field.value;
                if (value.loading) {
                    this.setValidationResult({
                        typeOfInfo: 'Error',
                        type: {
                            issue: 'validateFileLoading',
                            fieldCode: fieldKey,
                            message: `Plik/pliki w polu "${fieldKey}" nie zakończyły się przesyłać`,
                            title: validationData['validateFileLoading'].title,
                            id_zakladki: field.id_zakladki
                        }
                    });
                    return;
                }
            } else if (field.typ === 'pliki_wielokrotne') {
                const value: FieldFileManyBasicTypeValue = field.value;
                for (const fileState of value) {
                    if (fileState.loading) {
                        this.setValidationResult({
                            typeOfInfo: 'Error',
                            type: {
                                issue: 'validateFileLoading',
                                fieldCode: fieldKey,
                                message: `Plik/pliki w polu "${fieldKey}" nie zakończyły się przesyłać`,
                                title: validationData['validateFileLoading'].title,
                                id_zakladki: field.id_zakladki
                            }
                        });
                        return;
                    }
                }
            }
        }
    }
}
