import React, { FC } from 'react';

import { NormalScroll } from 'src/components/shared/molecules/normalScroll/NormalScroll';
import { useComponentSize, useWindowSize } from 'react-use-size';
import { ContentScrollProps } from 'src/components/shared/molecules/contentScroll/types';
import styles from './ContentScroll.module.css';

/**
 * Scroll component used mainly in pupups to be sure that popup wont be bigger than window
 */
const ContentScroll: FC<ContentScrollProps> = ({
    maxHeight,
    maxWidth,
    spaceLeftRight = 300,
    spaceUpDown = 300,
    children
}) => {
    const { height: heightWindow, width: widthWindow } = useWindowSize();
    const { ref, height, width } = useComponentSize();

    const availableWidth = widthWindow - spaceLeftRight;
    const calculatedMaxWidth = availableWidth > maxWidth ? maxWidth : availableWidth;
    const setScrollWidth = width >= calculatedMaxWidth;
    const calculatedWidth = setScrollWidth ? calculatedMaxWidth : width;

    const availableHeight = heightWindow - spaceUpDown;
    const calculatedMaxHeight = availableHeight > maxHeight ? maxHeight : availableHeight;
    const setScrollHeight = height >= availableHeight;
    const calculatedHeight = setScrollHeight ? calculatedMaxHeight : height;

    if (setScrollHeight || setScrollWidth) {
        return (
            <NormalScroll width={`${calculatedWidth}px`} height={`${calculatedHeight}px`}>
                <div className={styles.box} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.box2}>{children}</div>
                </div>
            </NormalScroll>
        );
    }

    return (
        <div
            className={styles.box}
            style={{
                maxHeight: `${maxHeight}px`,
                maxWidth: `${maxWidth}px`
            }}
            onClick={(e) => e.stopPropagation()}
            ref={ref}>
            <div className={styles.box2}>{children}</div>
        </div>
    );
};

export default ContentScroll;
