import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { parseStringPromise } from 'xml2js';
import { useAppDispatch } from 'src/store';
import { setOnLoad } from 'src/store/src/calendar';
import { post } from 'axios';

import Select from '../components/Select';
import CancelAssignBtn from '../components/CancelAssignBtn';
import { getCompatibleUrl } from 'src/utils';
import { useAddBackBroadcastChannel } from 'src/hooks/src/popupFormExtended/useAddBackBroadcastChannel';

const useStyles = makeStyles(({ spacing }) => ({
    timer_form: {
        paddingLeft: spacing(2.5),
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        paddingBottom: spacing(2),
        paddingTop: spacing(1),
        fontSize: spacing(1.5)
    },
    timer_form_typ: {
        width: spacing(9.5)
    },
    timer_form_sprawa: {
        width: spacing(19.5),
        marginLeft: spacing(0.5)
    }
}));

/**
 * Stanowy komponent funkcyjny. Wyświetla formularz pozwalający powiązać Timer ze sprawą.
 * Wyświetla komponenty: [Components/Timers/Select]{@link Select}, [Components/Timers/CancelAssignBtn]{@link CancelAssignBtn}
 * @component
 * @category Components
 * @subcategory Timers
 * @param {object} props - Props komponentu.
 * @param {funtion} props.handleCase - funckja przypisywania stopera do sprawy
 * @param {object|null} props.sprawa -  informacje o przypisanej sprawie
 * @param {number} props.timerId -  Id Timera.
 * @param {string} props.fieldId -  stan id pola potrzebny do ściągnięcia listy opcji dla pola select
 * @param {object} props.containerRef -  referencja do listy wyświetlającej stopery
 * @param {'large'|'medium'|'small'} props.viewport - rozmiar viewportu
 * @property {boolean} caseOpen -  stan komponentu czy otwarty jest select z wyborem powiązania.
 * @property {funtion} setCaseOpen - funckja ustawiania stanu selectu z wyborem powiązania.
 * @property {boolean} caseInput -  stan komponentu wartość inputu select z wyborem powiązania.
 * @property {funtion} setCaseInput - funckja ustawiania stanu wartości inputu select z wyborem powiązania.
 * @property {array} caseOptions -  stan komponentu tablica dostępnych opcji powiązań
 * @property {funtion} setCaseOptions - funckja ustawiania stanu wartości opcji powiązań.
 * @property {boolean} typeOpen -  stan komponentu czy otwarty jest select z wyborem typu powiązania.
 * @property {funtion} setTypeOpen - funckja ustawiania stanu selectu z wyborem typu powiązania.
 * @property {boolean} typeInput -  stan komponentu wartość inputu select z wyborem typu powiązania.
 * @property {funtion} settypeInput - funckja ustawiania stanu wartości inputu select z wyborem typu powiązania.
 * @property {boolean} typeOptions -  stan komponentu tablica dostępnych opcji typów powiązań.
 * @property {funtion} setTypeOptions - funckja ustawiania stanu wartości opcji typów powiązań.
 * @returns {ReactComponent}
 * @see [Components/Timers/Select]{@link Select}, [Components/Timers/CancelAssignBtn]{@link CancelAssignBtn}, [Components/Timers/Timer]{@link Timer}
 */
const AssignForm = ({ handleCase, sprawa, timerId, containerRef, fieldId, viewport }) => {
    const dodawanie_zwrotne = 'stopery';
    const startAddBackBroadcastChannel = useAddBackBroadcastChannel(dodawanie_zwrotne);
    const classes = useStyles();
    // Case related state
    const [caseOpen, setCaseOpen] = useState(false);
    const [caseInput, setCaseInput] = useState(sprawa ? sprawa.nazwa_czego : '');
    const [caseOptions, setCaseOptions] = useState([]);
    const [caseTimeout, setCaseTimeout] = useState(null);

    // Type related state
    const [typeInput, setTypeInput] = useState('');
    const [typeOpen, setTypeOpen] = useState(false);
    const [typeOptions, setTypeOptions] = useState([]);

    const dispatchApp = useAppDispatch();
    /**
     * @memberof AssignForm
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook effektu który pobiera z backendu dostępne opcje typów powiązań.
     */
    useEffect(() => {
        post('api_react/stopery.php', { action: 'getTypes' })
            .then(({ data }) => setTypeOptions(data))
            .catch((err) => {
                console.error(err.message);
            });
    }, []);

    /**
     * @memberof AssignForm
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook effektu który ustawia wartość inputu typu powiązania w zależności od pobranych opcji typu powiązania.
     */
    useEffect(() => {
        if (typeOptions.length && !typeInput) {
            if (sprawa) {
                const type = typeOptions.find((o) => o.id === sprawa.typ_czego);
                setTypeInput(type ? type.nazwa : 'nieznany');
            } else {
                setTypeInput(typeOptions[0].nazwa);
            }
        }
    }, [typeOptions, typeInput, sprawa]);

    /**
     * @memberof AssignForm
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description  Hook effektu który ustawia lub czyści wartości inputów w zależnośći od powiązania stopera.
     */
    useEffect(() => {
        if (sprawa) {
            setCaseInput(sprawa.nazwa_czego);
        }
        if (!caseOpen && !sprawa) {
            setCaseInput('');
            setCaseOptions([]);
        }
    }, [sprawa, caseOpen]);

    /**
     * @memberof AssignForm
     * @method handleTypeInput
     * @description funkcja obsługuje input typu powiązania.
     * @param {event} event.target.value - wartość inputu
     * @returns {void}
     */
    const handleTypeInput = ({ target: { value } }) => setTypeInput(value);

    /**
	* @memberof AssignForm
	* @method handleCaseInput
	@description funkcja obsługuje zapytanie do "xml_ogolnie_dotyczy.php" i ustawia dostępne opcje powiązania
	* @param {string} data - query string przesyłany jako część zapytania do backendu.
	* @returns {void}
	*/
    const handleCaseRequest = (data) => {
        post('xml_ogolnie_dotyczy.php', data)
            .then(({ data }) => parseStringPromise(data))
            .then(({ response: { result, result_id } }) =>
                result
                    ? result.map((res, i) => ({ nazwa_czego: res, id_czego: result_id[i] }))
                    : null
            )
            .then((opts) => {
                if (opts) {
                    setCaseOptions(opts);
                } else {
                    setCaseOptions([]);
                }

                return setCaseOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /**
     * @memberof AssignForm
     * @method handleCaseInput
     * @description funkcja obsługuje input powiązania, ustawia jego wartość i  zakłada timeout z zapytaniem o dostępne opcje
     * @param {event} event.target.value - wartość inputu
     * @returns {void}
     */
    const handleCaseInput = ({ target: { value } }) => {
        setCaseInput(value);
        caseTimeout && clearTimeout(caseTimeout);

        if (value.length >= 3) {
            const data = `q=${value}&typ=${
                typeOptions.find((o) => o.nazwa === typeInput).id
            }&id_pola=${fieldId}`;
            setCaseTimeout(setTimeout(() => handleCaseRequest(data), 500));
        } else {
            setCaseOptions([]);
        }
    };

    /**
     * @memberof AssignForm
     * @method handleTypeSelect
     * @description funkcja obsługuje wybór typu sprawy
     * @param {event} event - event przekazany do funkcji
     * @param {string} val - wartość na jaką zostanie nastawiony typ sprawy
     * @param {array} caseOptions - przefiltrowana przez wybrany rodzaj sprawy tablica dostępnych spraw
     * @returns {void}
     */
    const handleTypeSelect = (event, val) => {
        event.stopPropagation();
        setTypeInput(val);
    };

    /**
     * @memberof AssignForm
     * @method handleCaseSelect
     * @description funkcja obsługuje wybór sprawy. Wywołuje żądanie do backendu przypisania sprawy
     * @param {Event} event - event przekazany do funkcji
     * @param {number} id - id sprawy
     * @returns {void}
     */
    const handleCaseSelect = (event, id_czego, nazwa_czego) => {
        event.stopPropagation();
        if (sprawa && id_czego === sprawa.id_czego) {
            return;
        }
        const typ_czego = typeOptions.find((opt) => opt.nazwa === typeInput).id;
        handleCase(timerId, { typ_czego, id_czego, nazwa_czego });
    };

    /**
     * @memberof AssignForm
     * @method handleCaseCancel
     * @description funkcja obsługuje anulowanie przypisania. Wywołuje żądanie do backendu anulowania sprawy
     * @returns {void}
     */
    const handleCaseCancel = () => {
        handleCase(timerId, null);
    };

    /**
     * @memberof AssignForm
     * @method openNewCase
     * @description funkcja otwiera nowe okno w systemie, w którym można stworzyć zadanie lub inny obiekt który automatycznie zostanie przypisany do stopera
     * @param {Event} event - event przekazany do funkcji
     * @returns {void}
     */
    const openNewCase = (e) => {
        e.stopPropagation();
        const typ = typeOptions.find((o) => o.nazwa === typeInput).id;
        const handleNewWindow = () => {
            // set callback than will run after creation is finished and new window will close

            startAddBackBroadcastChannel((event) => {
                handleCase(timerId, {
                    typ_czego: typ,
                    id_czego: event.data?.id,
                    nazwa_czego: event.data?.name
                });
            });
            const url = getCompatibleUrl(
                'ekran_obiektu.php',
                new URLSearchParams(
                    `obiekt=${typ}&dodawanie_zwrotne=${dodawanie_zwrotne}&dodawanie_zwrotne_typ=powiazanie_typ&window_name=${window.name}`
                )
            );
            // open new window for creation
            window.open(window.basename + url, '_blank');
        };
        dispatchApp(setOnLoad(true));
        setTimeout(handleNewWindow, 100);
    };

    return (
        <div className={classes.timer_form}>
            <div className={classes.timer_form_typ}>
                <Select
                    handleOpen={setTypeOpen}
                    options={typeOptions}
                    containerRef={containerRef}
                    handleSelect={handleTypeSelect}
                    open={typeOpen}
                    type="typ_czego"
                    inputValue={typeInput}
                    setInputValue={handleTypeInput}
                    disabled={!!sprawa}
                    sprawa={sprawa}
                    viewport={viewport}
                />
            </div>
            <div className={classes.timer_form_sprawa}>
                <Select
                    handleOpen={setCaseOpen}
                    options={caseOptions}
                    handleSelect={handleCaseSelect}
                    containerRef={containerRef}
                    open={caseOpen}
                    type="nazwa_czego"
                    inputValue={caseInput}
                    setInputValue={handleCaseInput}
                    disabled={!!sprawa}
                    sprawa={sprawa}
                    openNewCase={openNewCase}
                    viewport={viewport}
                />
            </div>
            {sprawa && (
                <CancelAssignBtn info="anuluj przypisanie" cancelAssign={handleCaseCancel}>
                    <CloseIcon />
                </CancelAssignBtn>
            )}
        </div>
    );
};

export default AssignForm;
