import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from 'src/store';

export const useHandleSystemLock = () => {
    const systemLock = useTypedSelector((state) => state.system.systemLock);
    const navigate = useNavigate();

    useEffect(() => {
        if (systemLock) {
            navigate(
                '/message?tekst=Dostęp do systemu jest niemożliwy. W celu zalogowania się do niego prosimy o kontakt pod numerem: (22) 299-32-07'
            );
        }
    }, [systemLock, navigate]);
};
