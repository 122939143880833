import React from 'react';
import { fetchGlobalSearchInitData } from 'src/api/src/globalSearchScreem/fetchGlobalSearchInitData';
import { GlobalSearchData } from 'src/api/src/globalSearchScreem/types';

import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';

export const useGlobalSearchDefaultData = () => {
    const q = useSearchParam('query_global_search');
    const [data, setData] = React.useState<GlobalSearchData>(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            if (q) {
                const res = await fetchGlobalSearchInitData(q);
                if (res.success) {
                    setData(res.data);
                }
                setIsLoading(false);
            }
        })();
    }, []);

    return {
        isLoading,
        data
    };
};
