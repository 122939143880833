import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

import Window from './Window';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        background: palette.grey[200],
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: '"Lato", sans-serif',
        '& p': {
            marginLeft: spacing(0.5),
            color: palette.text.secondary,
            fontSize: 22,
            fontWeight: 700
        }
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Wyświetla zastępcze UI w razie błędu w aplikacji.
 * Wyświetla komponent [Components/Chat/Window]{@link Window}
 * @component
 * @category Components
 * @subcategory Chat
 * @returns {ReactComponent}
 * @see [Components/Chat/Window]{@link Window}
 */
const ErrorUI = () => {
    const classes = useStyles();
    return (
        <Window windowOrder={0} windowOpen={true} handleFocus={() => {}}>
            <div className={classes.root}>
                <ErrorIcon fontSize="large" color="error" />
                <p>Coś poszło nie tak...</p>
            </div>
        </Window>
    );
};

export default ErrorUI;
