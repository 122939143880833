import { MenuStructureData } from '../../../store/src/menu/menu/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

/**
 * Fetches information about actual system configuration
 */
export async function fetchMenuDataForNavigation(): Promise<BaseApiResponse<MenuStructureData>> {
    return simpleQueryAPIData('api_react/src/menu/getMenuDataForNavigation.php');
}
