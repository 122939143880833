import React, { useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from 'src/store/index';
import { useNavigate } from 'react-router-dom';
import { combineFullUrl } from 'src/utils';
import { setPopup } from 'src/store/src/popup';
import { setFirstAlertLoaded } from 'src/store/src/general/alerts/alertsSlice';

const AlertsLoader = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const alerts = useTypedSelector((state) => state.alertsData.alerts);
    const userLogged = useTypedSelector((state) => state.user.isLogged);

    useEffect(() => {
        if (!userLogged || !navigate || !dispatch) return;

        const alert = alerts[0];

        if (!alerts.length || !alert || alert.loaded) return;

        if (alert.link)
            navigate(combineFullUrl(alert.link.url, new URLSearchParams(alert.link.params)));

        dispatch(setFirstAlertLoaded());
        dispatch(
            setPopup({
                type: 'alert',
                isPossibleClose: false,
                message: alert.type === 'message' ? alert.data.message : undefined,
                alertData: alert
            })
        );
    }, [alerts, dispatch, navigate, userLogged]);

    return null;
};

export default React.memo(AlertsLoader);
