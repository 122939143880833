import React from 'react';
import ThemeCompatibleIcon from '../../../shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';

export type ArrowIconProps = {
    enabled: boolean;
    sx?: Object;
    arrowIcon?: string;
};

const ArrowIcon = (props: ArrowIconProps) => {
    const icon =
        props.arrowIcon !== undefined
            ? { faCode: '', svgFileName: props.arrowIcon }
            : { faCode: '', svgFileName: 'arrow_calendar.svg' };
    const disableStyle = props.enabled ? { cursor: 'pointer' } : { cursor: 'not-allowed' };
    return (
        <ThemeCompatibleIcon
            iconData={icon}
            fontSize={'large'}
            sx={{ width: '7px', ...props?.sx, ...disableStyle }}
        />
    );
};

export default ArrowIcon;
