import { MassBillState } from 'src/store/src/massBill/massBill/types';
import { convertParamsObjectToStrTwo } from 'src/utils/src/shared/convertParamsObjectToStrTwo';

export function createParamsForFetchMassBillData({ massBill }: { massBill: MassBillState }) {
    if (massBill.isMounting) {
        return window.location.search;
    } else {
        return convertParamsObjectToStrTwo(massBill.params);
    }
}
