import { budgetStableCodesForTotalCalc } from 'src/constants/budget/budgetStableCodesForTotalCalc';
import { BudgetFieldsStateUnion } from 'src/hooks/src/budget/useFormBudget';
import {
    BugdetFieldStructure,
    BugdetFormFieldStructure,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { NumberManager } from 'src/utils/src/shared/NumberManager';
import { getVat } from 'src/utils/src/shared/getVat';

export function calcTotalsInBudget({
    bugdetFormVisibleFieldStructure,
    values,
    code,
    fields
}: {
    fields: BugdetFieldStructure[];
    bugdetFormVisibleFieldStructure: BugdetFormFieldStructure;
    values: {
        [key: string]: any; // data for fields, like in popupFormExtended relations.objectsData.values
    };
    code: SettledObjectsCodes;
}): BudgetFieldsStateUnion {
    let value = 0;

    const codes = budgetStableCodesForTotalCalc[code];
    const rateToExchange: number =
        codes.kurs != null && NumberManager.isNumber(values[codes.kurs])
            ? NumberManager.getSafeNumberFromString(values[codes.kurs])
            : 1;
    if (bugdetFormVisibleFieldStructure.column === 'cena netto {waluta}') {
        const numberUnit =
            values[codes.liczba] ?? getValueFromFieldStructure(fields, codes.liczba) ?? 1;
        const price = values[codes.cena] ?? getValueFromFieldStructure(fields, codes.liczba);
        value = NumberManager.round(Number(numberUnit) * Number(price) * rateToExchange, 4);
    } else if (bugdetFormVisibleFieldStructure.column === 'cena brutto {waluta}') {
        const numberUnit =
            values[codes.liczba] ?? getValueFromFieldStructure(fields, codes.liczba) ?? 1;
        const price = values[codes.cena] ?? getValueFromFieldStructure(fields, codes.liczba);
        const vat =
            codes.vat == null
                ? 1
                : getVat(values[codes.vat] ?? getValueFromFieldStructure(fields, codes.vat));
        value = NumberManager.round(Number(numberUnit) * Number(price) * vat * rateToExchange, 4);
    }
    return {
        liczba: String(value)
    };
}

function getValueFromFieldStructure(fields: BugdetFieldStructure[], code: string) {
    const field = fields.find((field) => field.code === code);
    if (field?.value) {
        return field?.value;
    }
    return null;
}
