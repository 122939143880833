export default [
    '#666c91',
    '#d1aacf',
    '#75a298',
    '#c2b59b',
    '#918b87',
    '#90c7a9',
    '#da6460',
    '#74c3ab',
    '#dc858f'
];
