import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../../calendar';
import { makeErrorText } from 'src/utils';
import { SystemAction } from 'src/data/types';
import { TopPlusActionsState } from './types';
import { BaseApiResponse } from 'src/api/types';

export const initialState: TopPlusActionsState = {
    actions: []
};

const topPlusActionsSlice = createSlice({
    name: 'topPlusActions',
    initialState,
    reducers: {
        setActions: (state: TopPlusActionsState, action: PayloadAction<SystemAction[]>) => {
            state.actions = action.payload;
        }
    }
});

export const setTopPlusActions =
    (topPlusActionResponse: BaseApiResponse<SystemAction[]>): AppThunk =>
    async (dispatch) => {
        if (!topPlusActionResponse.success) {
            throw new Error(makeErrorText(topPlusActionResponse));
        }

        // set data
        dispatch(setActions(topPlusActionResponse.data));
    };

export const { setActions } = topPlusActionsSlice.actions;

export default topPlusActionsSlice.reducer;
