import React, { FC } from 'react';
import { WrapperSvgProps } from './types';
import styles from './WrapperIcon.module.css';
import { useTheme } from '@mui/material/styles';
const WrapperIcon: FC<WrapperSvgProps> = ({ children }) => {
    const theme = useTheme();
    return (
        <div className={styles.wrapperIcon} style={{ borderColor: theme.palette.primary.main }}>
            {children}
        </div>
    );
};

export default WrapperIcon;
