import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import MainMenu from 'src/components/menu/pages/mainMenu/MainMenu';
import { useTypedSelector } from 'src/store';
import { useLocation } from 'react-router-dom';
import { checkResetNavBackLevel, handleSettingNavStartPoint } from 'src/utils';

/**
 * Component managing menu display considering at which php page is front, because some php pages
 * loading by PhpMapper should be displaying without wrapping menu
 */
const AutoHideableMainMenu = () => {
    const isLogged = useTypedSelector((state) => state.user.isLogged);
    const dataLoaded = useTypedSelector((state) => state.system.initialDataLoaded);

    const location = useLocation();

    // in order to navigation system knows to not go back before first mecenas page, set start point only when user logged
    useEffect(() => {
        handleSettingNavStartPoint(Boolean(isLogged));
    }, [isLogged, location]);

    // to store information about back possibility
    useEffect(() => {
        checkResetNavBackLevel();
    }, [location]);

    return dataLoaded ? (
        isLogged ? (
            <MainMenu>
                <Outlet />
            </MainMenu>
        ) : (
            <Outlet />
        )
    ) : null;
};

export default AutoHideableMainMenu;
