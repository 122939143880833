import { BugdetFieldStructure } from 'src/store/src/budget/budget/types';

export const budgetCapfeeStableFields: BugdetFieldStructure[] = [
    {
        id: '9',
        type: 'liczba',
        column: 'cena (szt.)',
        code: 'capfee',
        displayCurrencyAsPostfix: true,
        name: '',
        readonly: true,
        isPriceWithoutNumber: true
    },
    {
        id: '10',
        type: 'liczba',
        column: 'cena (szt.)',
        code: 'capfee_kurs',
        name: '',
        hidden: true,
        isPriceWithoutNumber: true
    },
    {
        id: '11',
        column: 'cena (szt.)',
        code: 'capfee_waluta_tekst',
        onlyText: true,
        isPriceWithoutNumber: true
    },
    {
        id: '12',
        type: 'liczba',
        column: 'cena netto {waluta}',
        code: '0',
        name: '',
        readonly: true,
        isPriceWithoutNumber: true
    },
    {
        id: '13',
        type: 'tekst',
        column: 'vat',
        code: 'capfee_vat',
        name: '',
        hidden: true,
        isPriceWithoutNumber: true
    },
    {
        id: '14',
        type: 'tekst',
        code: 'capfee_waluta',
        name: '',
        hidden: true
    },
    {
        id: '15',
        type: 'liczba',
        column: 'cena brutto {waluta}',
        code: '0',
        name: '',
        readonly: true,
        isPriceWithoutNumber: true
    }
];
