import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';
import {
    FormValidationDataRefRHF,
    TypeFnValidate
} from 'src/components/form/molecules/formValidator/types';
import { PopupPayloadsAll } from 'src/store/src/popup/popup/types';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { FieldsStateRHF } from 'src/data/fieldsReactHookForm';
import { FormValidatorRequirementRHF } from 'src/components/form/molecules/formValidator/FormValidatorRequirementRHF';
import { FormValidationResultHanderRHF } from 'src/components/form/molecules/formValidator/FormValidationResultHanderRHF';
import { ScreenRHF } from 'src/data/commonRHF';

const functions: TypeFnValidate[] = ['validateRequirement'];

type FormValidatorRHFProps = {
    dynamicFieldT: DynamicFieldT[];
    dispatchPopupStateRedux: Dispatch<PayloadAction<PopupPayloadsAll>>;
    getValues: UseFormGetValues<FieldsStateRHF>;
    reset: UseFormReset<FieldsStateRHF>;
    screen: ScreenRHF;
    fieldsState: FieldsStateRHF;
};
export class FormValidatorRHF {
    dynamicFieldT: DynamicFieldT[];
    formValidationDataRef: FormValidationDataRefRHF;
    dispatchPopupStateRedux: Dispatch<PayloadAction<PopupPayloadsAll>>;
    getValues: UseFormGetValues<FieldsStateRHF>;
    reset: UseFormReset<FieldsStateRHF>;
    screen: ScreenRHF;
    constructor({
        dispatchPopupStateRedux,
        getValues,
        reset,
        dynamicFieldT,
        screen,
        fieldsState
    }: FormValidatorRHFProps) {
        this.formValidationDataRef = {
            fieldsToValid: fieldsState,
            fieldsNotValid: {},
            validationResult: {
                isNotValid: false,
                typeFnValidates: 'default',
                scrollKey: ''
            },
            dynamicFieldT: []
        };
        this.dispatchPopupStateRedux = dispatchPopupStateRedux;
        this.reset = reset;
        this.getValues = getValues;
        this.dynamicFieldT = dynamicFieldT;
        this.screen = screen;
    }

    async validate() {
        switch (this.screen) {
            case 'massEditObjects':
            case 'raport':
                await this.runValidations(['validateRequirement']);
                break;
            default:
                break;
        }

        return this.formValidationDataRef.validationResult;
    }

    private async runValidations(validations?: TypeFnValidate[]) {
        const fns: TypeFnValidate[] = validations ? validations : functions;

        for (const fn of fns) {
            await this[fn]();
            if (this.formValidationDataRef.validationResult.isNotValid) {
                await this.handleResultValidation();
                break;
            }
        }
    }

    private async validateRequirement() {
        await new FormValidatorRequirementRHF({
            formValidationDataRef: this.formValidationDataRef,
            screen: this.screen
        }).exec();
    }
    private async default() {}

    private async handleResultValidation() {
        await new FormValidationResultHanderRHF({
            dynamicFieldT: this.dynamicFieldT,
            dispatchPopupStateRedux: this.dispatchPopupStateRedux,
            formValidationDataRef: this.formValidationDataRef,
            reset: this.reset,
            getValues: this.getValues,
            screen: this.screen
        }).exec();
    }
}
