import { BugdetFieldTypes } from 'src/store/src/budget/budget/types';

export function getTypeOfFieldFromKeyInBudget(key: string): BugdetFieldTypes {
    switch (true) {
        case key.includes('stawkaspecjalna'):
            return 'checkbox';
        default:
            return 'liczba';
    }
}
