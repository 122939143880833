import { FieldListForeignBasicTypeValue } from 'src/data/fieldsFormType';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { FieldListForeignStateRHF } from 'src/data/fieldsReactHookForm';
import { FieldDependentFieldDefaultRHF } from 'src/components/form/molecules/fieldDependent/FieldDependentFieldDefaultRHF';
import {
    FieldDependentFunctionRHF,
    FieldDependentRHFProps,
    FieldDependentRowRHF,
    FieldDependentTempStateRHF
} from 'src/components/form/molecules/fieldDependent/types';

export type FieldDependentFieldListForeignRHFProps = FieldDependentRHFProps & {
    tempState: FieldDependentTempStateRHF;
    fieldWasTriggeredDynamicFieldT: DynamicFieldT;
    dependentFunctionDynamicType: FieldDependentFunctionRHF;
    fieldWasTriggered: FieldListForeignStateRHF;
    row?: FieldDependentRowRHF;
};
export class FieldDependentFieldListForeignRHF extends FieldDependentFieldDefaultRHF {
    fieldWasTriggeredWew: FieldListForeignStateRHF;
    constructor({
        dynamicFieldsT,
        formStateHandlers,
        tempState,
        fieldWasTriggeredDynamicFieldT,
        dependentFunctionDynamicType,
        fieldWasTriggered,
        row,
        staticData
    }: FieldDependentFieldListForeignRHFProps) {
        super({
            dynamicFieldsT,
            formStateHandlers,
            tempState,
            fieldWasTriggeredDynamicFieldT,
            dependentFunctionDynamicType,
            fieldWasTriggered,
            row,
            staticData
        });
        this.fieldWasTriggeredWew = fieldWasTriggered;
    }
    async zmienPole() {
        await this.checkAndChangeFieldChangeStateOfField();
    }

    private async checkAndChangeFieldChangeStateOfField() {
        let opcje = this.fieldWasTriggeredWew.lista_obca.opcje ?? {};

        const beforeValue = this.fieldWasTriggeredWew.lista_obca.value;
        const beforeText = this.fieldWasTriggeredWew.lista_obca.text;

        const data = await this.getValueToSetForChangeField();
        opcje = data.opcje;

        let value: FieldListForeignBasicTypeValue = { opcje, value: '0', text: '' };

        if (
            this.fieldWasTriggeredDynamicFieldT.zablokuj_po_ustawieniu_z_nadrzednego === 't' ||
            (beforeValue && opcje[beforeValue])
        ) {
            value.value = beforeValue;
            value.text = beforeText;
        }

        this.setTempState(this.fieldWasTriggeredCode, {
            lista_obca: value
        });
    }
}
