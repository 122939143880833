import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiConfigData } from 'src/api/src/configurationScreen/types';
import { configStableColumn } from 'src/constants/config/configStableData';
import { ConfigState, UpdateConfigFieldPayload } from 'src/store/src/configScreen/config/types';

export const initialState: ConfigState = {
    row: [],
    column: [],
    isLoaded: false
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setInitConfigData: (state: ConfigState, action: PayloadAction<ApiConfigData>) => {
            state.column = [...configStableColumn, ...action.payload.loginableScreensDefaultData];
            state.row = action.payload.configRows;
            state.isLoaded = true;
        },
        updateConfigField: (
            state: ConfigState,
            action: PayloadAction<UpdateConfigFieldPayload>
        ) => {
            const updatedColumn = state.column.map((item) => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        fields: {
                            ...item.fields,
                            [action.payload.keyField]: action.payload.value
                        }
                    };
                } else {
                    return item;
                }
            });
            state.column = updatedColumn;
        }
    }
});

export const { setInitConfigData, updateConfigField } = configSlice.actions;

export default configSlice.reducer;
