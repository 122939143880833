import {
    BugdetFormVisibleFieldStructureAll,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';

export const budgetStableFields: {
    [key in SettledObjectsCodes]: BugdetFormVisibleFieldStructureAll;
} = {
    zadania: {
        id: '1001',
        code: 'x',
        name: 'invoice_z_{$item.id}',
        type: 'checkbox'
    },
    koszty: {
        id: '1001',
        code: 'x',
        name: 'koszt_invoice_{$item.id}_{$miesiac.data_rozp}',
        type: 'checkbox'
    },
    dokumenty: {
        id: '1001',
        code: 'x',
        name: 'invoice_dokumenty_{$item.id}',
        type: 'checkbox'
    },
    dokumenty_kontrakt: {
        id: '1001',
        code: 'x',
        name: 'invoice_dokumenty_{$item.id}',
        type: 'checkbox'
    },
    kontakty: {
        id: '1001',
        code: 'x',
        name: 'kontakt_invoice_{$item.id}',
        type: 'checkbox'
    },
    kontakty_ryczalty: {
        id: '1001',
        code: 'x',
        name: 'kontakt_ryczalt_invoice_{$item.id}',
        type: 'checkbox'
    },
    oplatywstepne_za_sprawy: {
        id: '1001',
        code: 'x',
        name: 'invoice_oplatywstepne_sprawy_{$item.id}',
        type: 'checkbox'
    },
    rozprawy: {
        id: '1001',
        code: 'x',
        name: 'rozprawa_invoice_{$item.id}',
        type: 'checkbox'
    },
    rozprawy_ryczalty: {
        id: '1001',
        code: 'x',
        name: 'rozprawa_ryczalt_invoice_{$item.id}',
        type: 'checkbox'
    },
    ryczalt: {
        id: '1001',
        code: 'x',
        name: 'k_{$kontrakt.id}_invoice_ryczalt',
        type: 'checkbox'
    },
    ryczalt_miesiac: {
        id: '1001',
        code: 'x',
        name: 'invoice_ryczalt_{$item.id}',
        type: 'checkbox'
    },
    ryczalty_za_sprawy: {
        id: '1001',
        code: 'x',
        name: 'invoice_ryczalty_za_sprawy_{$item.id}',
        type: 'checkbox'
    },
    successfees: {
        id: '1001',
        code: 'x',
        name: 'invoice_successfee_{$item.id}',
        type: 'checkbox'
    },
    kosztyzastepstwa: {
        id: '1001',
        code: 'x',
        name: 'invoice_kosztzastepstwa_{$item.id}',
        type: 'checkbox'
    },
    limit_godzin: {
        id: '1001',
        code: 'x',
        name: 'invoice_limit_godzin_{$item.id}',
        type: 'checkbox'
    },
    fakturowaneelicencje: {
        id: '1001',
        code: 'x',
        name: 'invoice_fakturowaneelicencja_{$item.id}',
        type: 'checkbox'
    },
    etapyprojektow: {
        id: '1001',
        code: 'x',
        name: 'invoice_e_{$item.id}',
        type: 'checkbox'
    },
    koszty_projektow: {
        id: '1001',
        code: 'x',
        name: 'koszt_invoice_{$item.id}_{$miesiac.data_rozp}',
        type: 'checkbox'
    }
};
