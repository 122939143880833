import React, { FC } from 'react';
import UtilitiesBar from 'src/components/listScreen/organisms/utilitiesBar/UtilitiesBar';
import { useTypedSelector } from 'src/store';
import { UtilitiesBarRouterProps } from './types';
import { UtilityBarInternalActionGenerator } from 'src/components/listScreen/organisms/utilityBarRouter/UtilityBarInternalActionGenerator';
import { useSearchParam } from 'src/hooks';

export const UtilitiesBarRouter: FC<UtilitiesBarRouterProps> = (props) => {
    const screen = useSearchParam('obiekt');
    const modules = useTypedSelector((state) => state.menu.menuStructureData.modules);
    const activeTabData = useTypedSelector((state) => state.object.activeTabData);
    const userSlice = useTypedSelector((state) => state.user);
    const treeTableState = useTypedSelector((state) => state.listTreeTableData);
    const internalNotesExists = useTypedSelector((state) => state.budget.internalNotesExists);
    const alsoActual = useTypedSelector((state) => state.objectNotBill.data.params.rowniez_biezacy);
    const internalActions = React.useMemo(
        () =>
            new UtilityBarInternalActionGenerator({
                props,
                modules,
                treeTableState,
                activeTabData,
                internalNotesExists,
                screen,
                userSlice,
                alsoActual
            }).run(),
        [
            props,
            modules,
            treeTableState,
            screen,
            activeTabData,
            alsoActual,
            userSlice,
            internalNotesExists
        ]
    );
    return <UtilitiesBar {...props} listInternalAction={internalActions} />;
};
