import { RelationTreeCardElement } from 'src/store/src/object/object/types';

export function getCardTreeElementsFileIdsFromAttachments(
    elements: RelationTreeCardElement[],
    idStr: string
): string {
    let ids = idStr;

    elements.forEach((item) => {
        if (item.attachmentsData.length) {
            ids = item.attachmentsData.reduce((accumulator, currentValue) => {
                if (idStr === currentValue.fileId) return accumulator;
                return accumulator + ',' + currentValue.fileId;
            }, ids);
        }
    });
    return ids;
}
