import React, { FC } from 'react';

import { UIFieldDateProps } from './types';
import { DatePicker } from 'src/components/shared/atoms/datePicker/DatePicker';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

export const UiFieldDate: FC<UIFieldDateProps> = (props) => {
    const { value, onChange, uiFieldCommonProps, uiFieldSharedProps } = props;
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <DatePicker
                {...uiFieldCommonProps}
                dateInitial={value || undefined}
                dateFromOuterToUpdate={value || undefined}
                numberPanels="three"
                getDate={(data) => {
                    onChange(data);
                }}
                portalEnabled={true}
                sxContainerPanels={{ position: 'absolute' }}
                clearButton={true}
                typ="data"
                inputProps={uiFieldSharedProps.inputProps}
            />
        </UiWrapper>
    );
};
