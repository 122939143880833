import * as React from 'react';

import { FieldLogin } from 'src/components/popupFormExtended/dynamicField/fieldLogin/types';
import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFormObjectLoginValidatorProps, TypeValidateLogin } from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateLogin';
export class DynamicFormObjectLoginValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateLogin;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectLoginValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        for (const key in this.formState.fields) {
            if (this.dataRef.validationResult.isFormValid) {
                const field = this.formState.fields[key];
                if (field && field.kod === 'login' && field.typ === 'login') {
                    await this.runValidationOne(field, key);
                }
            } else {
                break;
            }
        }
    }
    private async runValidationOne(field: FieldLogin, key: string) {
        const params = {
            id_obiektu: this.fieldRelatedObjectData.id,
            ekran: this.fieldRelatedObjectData.screen,
            login: field?.value
        };

        const res = await getFieldAdditionalData({
            url: 'json_sprawdz_login.php',
            method: 'GET',
            params: params
        });
        if (res.data && !res.data.valid) {
            this.setValidationResult({
                typeOfInfo: 'Error',
                type: {
                    issue: this.typeFnValidate,
                    message: res.data.message,
                    title: validationData[this.typeFnValidate].title,
                    id_zakladki: field.id_zakladki
                }
            });
            this.setError(key, field);
        }
    }
}
