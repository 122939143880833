import React, { FC } from 'react';

import { UIFieldPasswordProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { fields } from 'src/constants/subtitles';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import styles from './UiFieldPassword.module.css';

export const UIFieldPassword: FC<UIFieldPasswordProps> = (props) => {
    const { uiFieldCommonProps, uiFieldSharedProps, changePasswordCallback } = props;
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <p onClick={changePasswordCallback}>
                <div className={styles.actionBlock}>
                    <ThemeCompatibleIcon
                        iconData={{ faCode: 'fa-edit', svgFileName: 'edytuj.svg' }}
                        fontSize={'small'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    />
                    <p className={styles.textCenter}>{fields.fieldPassword.changePassword}</p>
                </div>
            </p>
        </UiWrapper>
    );
};
