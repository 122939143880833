import React, { FC } from 'react';

import styles from './TabActive.module.css';
import { usePopupState } from 'src/context/globalPopup/popupContext';
import { TabActiceProps } from 'src/components/popupFormExtended/tabActive/types';
import { useTheme } from '@mui/material/styles';
import { TabActiveHandler } from 'src/components/popupFormExtended/tabActive/TabActiveHandler';

export const TabActive: FC<TabActiceProps> = ({ keyPopup, scroll = 0 }) => {
    const { popupStateRedux, popupStateContext } = usePopupState(keyPopup);
    const theme = useTheme();

    const activeTab = popupStateRedux.popupFormExtendedData?.tabs.find(
        (item) => item.id === popupStateContext.activeTab.id
    );
    const activeTabIndex = popupStateRedux.popupFormExtendedData?.tabs.findIndex(
        (item) => item.id === popupStateContext.activeTab.id
    );
    const position = new TabActiveHandler({
        globalPopupState: popupStateContext,
        scroll
    }).calc();

    if (popupStateContext.tabsLayout.name === 'WITHOUTSCROLL') {
        return (
            <div
                className={styles.box}
                style={{
                    left: `${position}px`
                }}>
                <div
                    className={styles.tab}
                    style={{
                        boxShadow: `-1px -1px 0px 0px ${
                            !activeTabIndex ? theme.palette.primary.main : 'transparent'
                        }`,
                        borderRight: `1px solid  ${theme.palette.primary.main}`,
                        borderBottom: '1px solid transparent',
                        borderTop: 'transparent',
                        color: `1px solid ${theme.palette.primary.main}`
                    }}>
                    <p className={styles.text}> {activeTab?.name}</p>
                </div>
            </div>
        );
    }

    return (
        <div
            className={styles.box}
            style={{
                left: `${position}px`
            }}>
            <div
                className={styles.tab}
                style={{
                    borderLeft: `1px solid  ${
                        !activeTabIndex ? theme.palette.primary.main : 'transparent'
                    }`,
                    borderRight: `1px solid  ${theme.palette.primary.main}`,
                    borderBottom: '1px solid transparent',
                    borderTop: 'transparent',
                    color: `1px solid ${theme.palette.primary.main}`
                }}>
                <p className={styles.text}> {activeTab?.name}</p>
            </div>
        </div>
    );
};
