import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const PatternGenerateView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "PatternGenerateView" */ 'src/components/patternGenerateScreen/template/patternGenerateView/PaternGenerateView'
        )
);

export const PatternGenerateMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Szablony generowane');
    return (
        <div>
            <React.Suspense fallback>
                <PatternGenerateView />
            </React.Suspense>
        </div>
    );
};
