import React, { FC } from 'react';

import styles from './UiFieldNumber.module.css';
import { UiFieldNumberWithCalcMinuteHourProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { TimeManager } from 'src/widgets/shared/utils/TimeManager';
import { fields } from 'src/constants/subtitles';

export const UiFieldNumberWithCalcMinuteHour: FC<UiFieldNumberWithCalcMinuteHourProps> = (
    props
) => {
    const {
        value,
        onChangeDecimal,
        onBlurDecimal,
        onBlurNormal,
        onChangeNormal,
        uiFieldCommonProps,
        uiFieldSharedProps
    } = props;

    return (
        <div className={styles.wrapper}>
            <UiWrapper
                style={{
                    width: '100%'
                }}>
                <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
                <div className={styles.wrapper}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={value}
                        onChange={onChangeDecimal}
                        onBlur={onBlurDecimal}
                        alignTextRight={true}
                        inputProps={uiFieldSharedProps.inputProps}
                    />
                    <p>h</p>
                </div>
            </UiWrapper>
            <p>&lt;-&gt;</p>
            <UiWrapper
                style={{
                    width: '100%'
                }}>
                <UiFieldLabel
                    {...uiFieldCommonProps}
                    {...uiFieldSharedProps}
                    name={
                        uiFieldCommonProps.isLabel
                            ? `${uiFieldCommonProps.name} ${fields.fieldNumber.oneLabel}`
                            : ''
                    }
                />
                <div className={styles.wrapper}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={TimeManager.devideHoursToAbsoluteMinutes(Number(value))}
                        onChange={onChangeNormal}
                        onBlur={onBlurNormal}
                        alignTextRight={true}
                    />
                    <p>min</p>
                </div>
            </UiWrapper>
        </div>
    );
};
