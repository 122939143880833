import { CalendarQuery } from '../../types';

export async function queryServerData<T = any>(url = '', data: CalendarQuery): Promise<T> {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    const dataPrimary = await response.text();
    const dataParsed = JSON.parse(dataPrimary);

    try {
        return dataParsed;
    } catch (e) {
        return { errorCode: 500, message: dataParsed } as any;
    }
}
