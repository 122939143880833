import React, { FC } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import plLocale from 'date-fns/locale/pl';
import format from 'date-fns/format';

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date: any) {
        return format(date, 'LLLL y', { locale: this.locale });
    }
    getCalendarHeaderText(date: Date) {
        return format(date, 'LLLL', { locale: this.locale });
    }
}

const WithPickers: FC = ({ children }) => {
    return (
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
            {children}
        </MuiPickersUtilsProvider>
    );
};

export default WithPickers;
