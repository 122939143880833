import React, { FC } from 'react';

import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { ApiActionImportPayoffProps } from './types';
import { useLoadImportPayoff } from 'src/hooks';

const ApiActionImportPayoff: FC<ApiActionImportPayoffProps> = ({ children }) => {
    const { loadImportPayoff } = useLoadImportPayoff();

    return (
        <ButtonCustom onClick={() => loadImportPayoff()} withoutStyles={true}>
            {children}
        </ButtonCustom>
    );
};

export default ApiActionImportPayoff;
