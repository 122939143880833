import { availableFunctionToCallInFieldNested } from '../../../constants/popupContentDynamicExtended/dependent';
import { FieldDependentRelationshipTypeManager } from './fieldsDependentManager/fieldDependentRelationshipTypeManager/FieldDependentRelationshipTypeManager';
import { FieldDependentDefaultManager } from './fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { FormState } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { FieldExtender } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldExtender/FieldExtender';
import {
    TempFieldsState,
    TempFieldsValues
} from 'src/components/popupFormExtended/fieldGlobalDependent/types';
import { FieldDependentListForeignManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentListForeignManager/FieldDependentListForeignManager';
import { availableFunctionToCallInField } from 'src/constants/popupContentDynamicExtended/dependent';
import { FormActionKind } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import { FieldDependentCheckboxManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentCheckboxManager/FieldDependentCheckboxManager';
import { FieldDependentListManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentListManager/FieldDependentListManager';
import { FieldDependentListDependendManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentListDependentManager/FieldDependentListDependentManager';
import {
    DataFunctionDynamic,
    DependentFunctionDynamicType,
    ValuesForSuperiorFromApi
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/types';
import { FieldDependentTekstManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentTekstManager/FieldDependentTekstManager';
import { FieldDependentListForeignManyManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentListForeignManyManager/FieldDependentListForeignManyManager';
import { FieldDependentDateManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDateManager/FieldDependentDateManager';
import { FieldDependentDateHourManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDateHourManager/FieldDependentDateHourManager';
import { FieldDependentNumberManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentNumberManager/FieldDependentNumberManager';
import { FieldDependentCostsManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentCostsManager/FieldDependentCostsManager';
import { FieldDependentPriceManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentPriceManager/FieldDependentPriceManager';
import { FieldDependentPriceNumberManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentPriceNumberManager/FieldDependentPriceNumberManager';
import { legacyApiParamToBool } from 'src/utils';
import { FieldDependentFileManager } from './fieldsDependentManager/fieldDependentFileManager/FieldDependentFileManager';
import { FieldDependentRelationshipManyTypeManager } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentRelationshipManyTypeManager/FieldDependentRelationshipManyTypeManager';

export type FieldGlobalDependentManagerProps = {
    formState: FormState;
    popupFormExtendedData: PopupFormExtendedData;
    tempFieldsState: TempFieldsState;
    tempFieldsValues?: TempFieldsValues;
    prefix?: string;
    relationId?: string;
};

type FieldGlobalDependentManagerFunctionDynamicProps = {
    dynamicFieldT: DynamicFieldT;
    dataFunctionDynamic: DataFunctionDynamic;
};

export class FieldGlobalDependentManager extends FieldExtender {
    formState: FormState;
    popupFormExtendedData: PopupFormExtendedData;
    tempFieldsState: TempFieldsState;
    tempFieldsValues: TempFieldsValues;
    usedForAddingObject: boolean;
    prefix?: string;
    relationId?: string;
    constructor(props: FieldGlobalDependentManagerProps) {
        super({
            formState: props.formState,
            popupFormExtendedData: props.popupFormExtendedData,
            tempFieldsState: props.tempFieldsState,
            prefix: props.prefix,
            relationId: props.relationId
        });
        this.formState = props.formState;
        this.popupFormExtendedData = props.popupFormExtendedData;
        this.usedForAddingObject = props.popupFormExtendedData.objectId === 'nowy';
        this.tempFieldsState = props.tempFieldsState;
        this.prefix = props.prefix;
        this.relationId = props.relationId;
        this.tempFieldsValues = props.tempFieldsValues ?? {
            sums: {
                value: ''
            }
        };
    }

    async executeOne(dynamicFieldT: DynamicFieldT) {
        const arr: DependentFunctionDynamicType[] =
            availableFunctionToCallInField[dynamicFieldT.typ as FormActionKind] ?? [];
        for (const dependentFunctionDynamicType of arr) {
            await this.executeItem({ dependentFunctionDynamicType, dynamicFieldT });
        }
    }

    async executeNested(dynamicFieldT: DynamicFieldT) {
        const arr: DependentFunctionDynamicType[] =
            availableFunctionToCallInFieldNested[dynamicFieldT.typ as FormActionKind] ?? [];
        for (const dependentFunctionDynamicType of arr) {
            // if (dynamicFieldT.kod === 'apelacja') continue;
            await this.executeItem({ dependentFunctionDynamicType, dynamicFieldT });
        }
    }

    async executeItem({
        dynamicFieldT,
        dependentFunctionDynamicType
    }: {
        dynamicFieldT: DynamicFieldT;
        dependentFunctionDynamicType: DependentFunctionDynamicType;
    }) {
        switch (dependentFunctionDynamicType) {
            case 'pokazPole':
            case 'przeliczWartosc':
            case 'zmienWartoscPola':
            case 'zmienPole':
            case 'zmienWartosciPolZNadrzednegoModTextForm':
                await this[dependentFunctionDynamicType](dynamicFieldT);
                break;
            case 'zmienWartosciPolZNadrzednego':
                break;
            default:
                break;
        }
    }

    private async przeliczWartosc(dynamicFieldT: DynamicFieldT) {
        if (dynamicFieldT && Array.isArray(dynamicFieldT?.wyliczane)) {
            for (const item of dynamicFieldT.wyliczane) {
                const dynamicFieldTToCall = this.findDynamicFieldTById(item.id);
                if (dynamicFieldTToCall) {
                    await this.functionDynamic({
                        dynamicFieldT: dynamicFieldTToCall,
                        dataFunctionDynamic: {
                            typeOfFunction: 'przeliczWartosc'
                        }
                    });
                }
            }
        }
    }

    private async pokazPole(dynamicFieldT: DynamicFieldT) {
        if (dynamicFieldT && Array.isArray(dynamicFieldT?.pola_zalezne_widocznosc)) {
            for (const item of dynamicFieldT?.pola_zalezne_widocznosc) {
                const dynamicFieldTToCall = this.findDynamicFieldTById(item.id);
                if (dynamicFieldTToCall) {
                    await this.functionDynamic({
                        dynamicFieldT: dynamicFieldTToCall,
                        dataFunctionDynamic: {
                            typeOfFunction: 'pokazPole'
                        }
                    });
                }
            }
        }
    }

    private async zmienWartoscPola(dynamicFieldT: DynamicFieldT) {
        if (dynamicFieldT && Array.isArray(dynamicFieldT?.pola_zalezne_opcja_domyslna)) {
            for (const item of dynamicFieldT?.pola_zalezne_opcja_domyslna) {
                const dynamicFieldTToCall = this.findDynamicFieldTById(item.id);
                if (dynamicFieldTToCall) {
                    await this.functionDynamic({
                        dynamicFieldT: dynamicFieldTToCall,
                        dataFunctionDynamic: {
                            typeOfFunction: 'zmienWartoscPola'
                        }
                    });
                }
            }
        }
    }

    private async zmienPole(dynamicFieldT: DynamicFieldT) {
        if (dynamicFieldT && Array.isArray(dynamicFieldT?.zalezne)) {
            for (const item of dynamicFieldT?.zalezne) {
                const dynamicFieldTToCall = this.findDynamicFieldTById(item.id);
                if (dynamicFieldTToCall) {
                    await this.functionDynamic({
                        dynamicFieldT: dynamicFieldTToCall,
                        dataFunctionDynamic: {
                            typeOfFunction: 'zmienPole',
                            valueToSet: 0,
                            isValueToSet: false
                        }
                    });
                }
            }
        }
    }

    // uwaga w przypadku funkcji 'zmienWartosciPolZNadrzednegoModTextForm' w przeciwienstwie do powyższych 4 funcjji nie wywoływane sa dzieci pola które sie zmieniły, a samo pole i to ono pobiera dane a nastepnie przesyła dane dalej do dzieci

    private async zmienWartosciPolZNadrzednegoModTextForm(dynamicFieldT: DynamicFieldT) {
        if (dynamicFieldT && Array.isArray(dynamicFieldT?.pola_domyslne_z_nadrzednego)) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'zmienWartosciPolZNadrzednegoModTextForm'
                }
            });
        }
    }

    //  osobna funkcja zmienWartosciPolZNadrzednego() która nie jest wywoływana przy wywołaniu kaskady (  executeNested() ) a jedynie jako skutek pobrania wartosci z zmienWartosciPolZNadrzednegoModTextForm, ona sama jedynie ustawia wartosc przesłana z changeValueFromSuperiorModTextForm oraz wywołuje kaskade czyli executeNested()

    async zmienWartosciPolZNadrzednego(
        dynamicFieldT: DynamicFieldT,
        valuesForSuperiorFromApi: ValuesForSuperiorFromApi
    ) {
        if (dynamicFieldT && Array.isArray(dynamicFieldT?.pola_domyslne_z_nadrzednego)) {
            for (const item of dynamicFieldT?.pola_domyslne_z_nadrzednego) {
                const dynamicFieldTToCall = this.findDynamicFieldTById(item.id);
                if (dynamicFieldTToCall) {
                    const valueForField = valuesForSuperiorFromApi[dynamicFieldTToCall.kod];
                    if (
                        valueForField &&
                        valueForField.value !== null &&
                        valueForField.value !== undefined
                    ) {
                        await this.functionDynamic({
                            dynamicFieldT: dynamicFieldTToCall,
                            dataFunctionDynamic: {
                                typeOfFunction: 'zmienWartosciPolZNadrzednego',
                                valueToSet: valueForField
                            }
                        });
                    }
                }
            }
        }
    }

    async zmienWartosciPolZNadrzednegoSums(dynamicFieldT: DynamicFieldT) {
        if (dynamicFieldT && dynamicFieldT.pole_z_ekranu_do_przeniesienia_sumy) {
            const dynamicFieldTToCall = this.findDynamicFieldTById(
                dynamicFieldT.pole_z_ekranu_do_przeniesienia_sumy
            );
            if (dynamicFieldTToCall) {
                await this.functionDynamic({
                    dynamicFieldT: dynamicFieldTToCall,
                    dataFunctionDynamic: {
                        typeOfFunction: 'zmienWartosciPolZNadrzednego',
                        valueToSet: {
                            value: this.tempFieldsValues.sums.value,
                            text: ''
                        }
                    }
                });
            }
        }
    }

    async zmienWartosciPolZNadrzednegoSumsForAllChanged() {
        for (const updatedField of Object.values(this.tempFieldsState)) {
            const dynamicFieldTToCall = this.findDynamicFieldTById(updatedField.id);
            if (!dynamicFieldTToCall) continue;

            await new FieldGlobalDependentManager({
                formState: this.formState,
                tempFieldsState: this.tempFieldsState,
                popupFormExtendedData: this.popupFormExtendedData,
                tempFieldsValues: this.tempFieldsValues
            }).zmienWartosciPolZNadrzednegoSums(dynamicFieldTToCall);
        }
    }

    async executeOnMount() {
        for (const dynamicFieldT of this.popupFormExtendedData.fields) {
            const fieldState = this.findStateOfFieldsById(dynamicFieldT.id);
            if (!fieldState || fieldState.initialHidden) continue;

            if (fieldState.initialIsModify)
                await this.runOnMountFirstStageFieldModified({ dynamicFieldT });
            else await this.runOnMountFirstStageFieldUnModified({ dynamicFieldT });
        }
        if (this.usedForAddingObject) {
            for (const dynamicFieldT of this.popupFormExtendedData.fields) {
                await this.runOnMountSecondStage({ dynamicFieldT });
            }
        }
    }

    private async runOnMountFirstStageFieldModified({
        dynamicFieldT
    }: {
        dynamicFieldT: DynamicFieldT;
    }) {
        if (
            this.usedForAddingObject &&
            dynamicFieldT.typ === 'powiazanie_typ' &&
            legacyApiParamToBool(dynamicFieldT.domyslna_wartosc_id) &&
            dynamicFieldT.pola_domyslne_z_nadrzednego
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'zmienWartosciPolZNadrzednegoModTextForm'
                }
            });
        } else if (
            this.usedForAddingObject &&
            dynamicFieldT.typ === 'lista_obca' &&
            legacyApiParamToBool(dynamicFieldT.domyslna_wartosc) &&
            dynamicFieldT.pola_domyslne_z_nadrzednego
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'zmienWartosciPolZNadrzednegoModTextForm'
                }
            });
        }

        if (
            this.usedForAddingObject &&
            legacyApiParamToBool(dynamicFieldT.pole_nadrzedne) &&
            dynamicFieldT.pole_nadrzedne_typ === 'powiazanie_typ' &&
            !legacyApiParamToBool(dynamicFieldT.domyslna_wartosc) &&
            !legacyApiParamToBool(dynamicFieldT.check_ustawiane_z_powiazan)
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'zmienPole',
                    valueToSet: 0,
                    isValueToSet: true
                }
            });
        } else if (
            this.usedForAddingObject &&
            legacyApiParamToBool(dynamicFieldT.pole_nadrzedne) &&
            (dynamicFieldT.pole_nadrzedne_typ === 'powiazanie_typ' ||
                dynamicFieldT.pole_nadrzedne_typ === 'lista_obca') &&
            legacyApiParamToBool(dynamicFieldT.domyslna_wartosc) &&
            !legacyApiParamToBool(dynamicFieldT.check_ustawiane_z_powiazan)
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'zmienPole',
                    valueToSet: 0,
                    isValueToSet: true
                }
            });
        } else if (
            this.usedForAddingObject &&
            legacyApiParamToBool(dynamicFieldT.pole_nadrzedne) &&
            dynamicFieldT.pole_nadrzedne_typ !== 'powiazanie_typ' &&
            !legacyApiParamToBool(dynamicFieldT.check_ustawiane_z_powiazan)
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'zmienPole',
                    valueToSet: 0,
                    isValueToSet: false
                }
            });
        }

        if (dynamicFieldT.typ === 'lista_zalezna') {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'zmienPole',
                    valueToSet: 0,
                    isValueToSet: false
                }
            });
        }
        if (
            legacyApiParamToBool(dynamicFieldT.pole_widocznosc) &&
            dynamicFieldT.pole_widocznosc_typ === 'lista'
        ) {
            // do not replicated {if $pole_{$item.pole_widocznosc_kod}_brak_modyfikacji == 1}
            // because it depends on modifying state of field, in this react system it is no
            // difference between modified and unmodieifed field values
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'pokazPole'
                }
            });
        }
        if (
            legacyApiParamToBool(dynamicFieldT.pole_widocznosc) &&
            dynamicFieldT.pole_widocznosc_typ === 'lista_zalezna'
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'pokazPole'
                }
            });
        }
        if (
            legacyApiParamToBool(dynamicFieldT.pole_widocznosc) &&
            dynamicFieldT.pole_widocznosc_typ === 'checkbox'
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'pokazPole'
                }
            });
        }
        if (
            legacyApiParamToBool(dynamicFieldT.pole_widocznosc) &&
            dynamicFieldT.pole_widocznosc_typ === 'powiazanie_typ'
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'pokazPole'
                }
            });
        }
        if (this.usedForAddingObject && dynamicFieldT.typ === 'powiazanie_typ') {
            await new FieldGlobalDependentManager({
                formState: this.formState,
                tempFieldsState: this.tempFieldsState,
                popupFormExtendedData: this.popupFormExtendedData
            }).executeNested(dynamicFieldT);
        }
    }

    private async runOnMountFirstStageFieldUnModified({
        dynamicFieldT
    }: {
        dynamicFieldT: DynamicFieldT;
    }) {
        if (
            legacyApiParamToBool(dynamicFieldT.pole_widocznosc) &&
            dynamicFieldT.pole_widocznosc_typ === 'lista'
        ) {
            // do not replicated {if $pole_{$item.pole_widocznosc_kod}_brak_modyfikacji == 1}
            // because it depends on modifying state of field, in this react system it is no
            // difference between modified and unmodieifed field values
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'pokazPole'
                }
            });
        }
        if (
            legacyApiParamToBool(dynamicFieldT.pole_widocznosc) &&
            dynamicFieldT.pole_widocznosc_typ === 'checkbox'
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'pokazPole'
                }
            });
        }
        if (
            legacyApiParamToBool(dynamicFieldT.pole_widocznosc) &&
            dynamicFieldT.pole_widocznosc_typ === 'powiazanie_typ'
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'pokazPole'
                }
            });
        }
    }

    private async runOnMountSecondStage({ dynamicFieldT }: { dynamicFieldT: DynamicFieldT }) {
        if (
            dynamicFieldT?.wyliczenie_dzialanie_od_razu === 't' &&
            !legacyApiParamToBool(dynamicFieldT.blokada_wyliczenia)
        ) {
            await this.functionDynamic({
                dynamicFieldT,
                dataFunctionDynamic: {
                    typeOfFunction: 'przeliczWartosc'
                }
            });
            await this.zmienWartoscPola(dynamicFieldT);
        }
    }

    private async functionDynamic({
        dynamicFieldT,
        dataFunctionDynamic
    }: FieldGlobalDependentManagerFunctionDynamicProps) {
        const fieldWasTriggered = this.findStateOfFieldsByCode(dynamicFieldT.kod);
        switch (fieldWasTriggered?.typ) {
            case 'cena':
                await new FieldDependentPriceManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'cena_liczba':
                await new FieldDependentPriceNumberManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'lista_obca':
                await new FieldDependentListForeignManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'lista_obca_wielokrotna':
            case 'lista_wielokrotna_cena':
            case 'lista_wielokrotna':
                await new FieldDependentListForeignManyManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'powiazanie_typ':
                await new FieldDependentRelationshipTypeManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'powiazanie_typ_wielokrotne':
                await new FieldDependentRelationshipManyTypeManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'checkbox':
                await new FieldDependentCheckboxManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'koszty':
                await new FieldDependentCostsManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'data':
                await new FieldDependentDateManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'data_godzina':
                await new FieldDependentDateHourManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'liczba':
                await new FieldDependentNumberManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'lista':
            case 'lista_cena':
                await new FieldDependentListManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'lista_zalezna':
                await new FieldDependentListDependendManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'tekst':
                await new FieldDependentTekstManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            case 'plik':
                await new FieldDependentFileManager({
                    formState: this.formState,
                    tempFieldsState: this.tempFieldsState,
                    popupFormExtendedData: this.popupFormExtendedData,
                    fieldWasTriggered,
                    dataFunctionDynamic,
                    prefix: this.prefix,
                    relationId: this.relationId
                })[dataFunctionDynamic.typeOfFunction]();
                break;
            default:
                if (fieldWasTriggered) {
                    await new FieldDependentDefaultManager({
                        formState: this.formState,
                        tempFieldsState: this.tempFieldsState,
                        popupFormExtendedData: this.popupFormExtendedData,
                        fieldWasTriggered,
                        dataFunctionDynamic,
                        prefix: this.prefix,
                        relationId: this.relationId
                    })[dataFunctionDynamic.typeOfFunction]();
                }
                break;
        }
    }
}
