import React, { FC } from 'react';

import { UIFieldMailProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

export const UIFieldMail: FC<UIFieldMailProps> = (props) => {
    const { value, onChange, onBlur, uiFieldCommonProps, uiFieldSharedProps } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <UiInputNormalTwo
                {...uiFieldCommonProps}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={uiFieldSharedProps.inputProps}
            />
        </UiWrapper>
    );
};
