import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { TemplateGlobalMainManager } from 'src/utils/src/shared/TemplateGlobalMainManager';
import { TemplateMainItem } from 'src/utils/src/shared/TemplateMainItem';
import { Additional, ObjectSlice } from 'src/store/src/object/object/types';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { ObjectDataManager } from 'src/store/src/object/object/ObjectDataManager';
import { getCellRawValueOrTextFromListCellRow } from 'src/utils/src/shared/getCellRawValueOrTextFromListCellRow';

type TemplateMainDolarProps = {
    temporaryAllString: string;
    templateMainItem: TemplateMainItem;
    rowData: RowData[];
    rowId?: RowDataId | null;
    objectSlice: ObjectSlice;
    additional: Additional;
    sourceOfTrigger: SourceOfTrigger;
};

export class TemplateMainDolar extends TemplateGlobalMainManager {
    temporaryAllString: string;
    templateMainItem: TemplateMainItem;
    rowData: RowData[];
    rowId?: RowDataId | null;
    objectSlice: ObjectSlice;
    additional: Additional;
    sourceOfTrigger: SourceOfTrigger;
    constructor({
        templateMainItem,
        rowData,
        rowId,
        temporaryAllString,
        objectSlice,
        sourceOfTrigger,
        additional
    }: TemplateMainDolarProps) {
        super();
        this.templateMainItem = templateMainItem;
        this.temporaryAllString = temporaryAllString;
        this.rowData = rowData;
        this.rowId = rowId;
        this.objectSlice = objectSlice;
        this.additional = additional;
        this.sourceOfTrigger = sourceOfTrigger;
    }
    getData() {
        switch (this.sourceOfTrigger.type) {
            case 'startupCardList': {
                const rawValue = getCellRawValueOrTextFromListCellRow({
                    key: this.templateMainItem.contentClear,
                    type: 'rawValue',
                    onlyFirstValue: false,
                    cellRow: this.sourceOfTrigger.cellRow
                });

                return this.replaceContent({
                    contentToReplace: Array.isArray(rawValue) ? rawValue?.join(',') : rawValue,
                    templateMainItem: this.templateMainItem,
                    temporaryAllString: this.temporaryAllString
                });
            }
            case 'object': {
                const rawValue = new ObjectDataManager({
                    objectSlice: this.objectSlice,
                    additional: this.additional
                }).getCellRawValueOrText({
                    key: this.templateMainItem.contentClear,
                    type: 'rawValue',
                    onlyFirstValue: false
                });
                return this.replaceContent({
                    contentToReplace: Array.isArray(rawValue) ? rawValue?.join(',') : rawValue,
                    templateMainItem: this.templateMainItem,
                    temporaryAllString: this.temporaryAllString
                });
            }
            case 'list': {
                const rawValue = new TableDataManager({
                    rowData: this.rowData,
                    rowId: this.rowId
                }).getRawValues2(this.templateMainItem);
                return this.replaceContent({
                    contentToReplace: rawValue.join(','),
                    templateMainItem: this.templateMainItem,
                    temporaryAllString: this.temporaryAllString
                });
            }
            default:
                return '';
        }
    }
}
