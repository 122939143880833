import { BugdetCaseLevel } from 'src/store/src/budget/budget/types';

// for example 'zadania' should be displayed even when there are no items, to enable adding new items
export const useIsCaseHidden = (caseItem: BugdetCaseLevel) => {
    let isHidden = true;

    caseItem.settledElements.forEach((item) => {
        item.objects.forEach((object) => {
            if (!object.hidden) {
                isHidden = false;
            }
        });
    });
    return {
        isHidden
    };
};
