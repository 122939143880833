import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import {
    FieldPriceNumberChangeType,
    FieldPriceNumberManager
} from 'src/components/popupFormExtended/dynamicField/fieldPriceNumber/FieldPriceNumberManager';
import { UIFieldPriceNumber } from 'src/components/popupFormExtended/dynamicField/fieldPriceNumber/UIFieldPriceNumber';
import { FieldPriceNumberObjectProps } from './types';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { checkIsSamePriceNumber } from 'src/utils/src/shared/checkIsSamePriceNumber';
import { replaceStr } from 'src/utils';

export const FieldPriceNumberObject: FC<FieldPriceNumberObjectProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const [state, setState] = React.useState(field.value);

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        changeType: FieldPriceNumberChangeType,
        onBlur: boolean
    ) => {
        let newVal = field.value;
        if (
            !checkIsSamePriceNumber({
                oldValue: field.value,
                value: e.target.value,
                changeType
            })
        ) {
            let valueTemp = e.target.value;
            if (
                changeType === 'total_brutto' ||
                changeType === 'netto' ||
                changeType === 'liczba'
            ) {
                valueTemp = replaceStr({
                    str: e.target.value,
                    replaceTypes: ['commaForDotAll', 'onlyDigitAll']
                });
            }
            newVal = new FieldPriceNumberManager({
                oldValue: field.value,
                value: valueTemp,
                changeType
            }).calc();
        }
        setState((prev) => {
            return {
                ...prev,
                ...newVal
            };
        });
        if (onBlur) {
            const newValue = {
                ...field,
                value: newVal
            };
            dispatch({
                type: 'cena_liczba',
                payload: {
                    code: keyOfFieldInState,
                    value: newValue
                }
            });
            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: newValue,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        }
    };

    React.useEffect(() => {
        if (state.liczba !== field.value.liczba || state.netto !== field.value.netto) {
            setState(field.value);
        }
    }, [field.value]);

    return (
        <UIFieldPriceNumber
            value={state}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            onChangeNetto={(e) => onChange(e, 'netto', false)}
            onChangeVat={(e) => onChange(e, 'vat', true)}
            onChangeBrutto={(e) => onChange(e, 'total_brutto', false)}
            onChangeNumber={(e) => onChange(e, 'liczba', false)}
            onChangeCurrency={(e) => onChange(e, 'waluta', false)}
            onBlurBrutto={(e) => onChange(e, 'total_brutto', true)}
            onBlurNetto={(e) => onChange(e, 'netto', true)}
            onBlurNumber={(e) => onChange(e, 'liczba', true)}
            onBlurCurrency={(e) => onChange(e, 'waluta', true)}
            onBlurVat={() => {}}
        />
    );
};
