import React, { FC } from 'react';

import { ColumnsProps } from 'src/components/popupFormExtended/columns/types';
import styles from './Columns.module.css';
import { ColumnLeftRight } from 'src/components/popupFormExtended/columnLeftRight/ColumnLeftRight';
import { ColumnDown } from 'src/components/popupFormExtended/columnDown/ColumnDown';

export const Columns: FC<ColumnsProps> = (props) => {
    const { columns, availableWidth, fieldWidth } = props;

    if (
        !columns.down?.fields?.length &&
        !columns.left?.fields?.length &&
        !columns.right?.fields?.length
    )
        return null;

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.twoColumns}
                style={{
                    gridTemplateColumns: `${
                        availableWidth && availableWidth >= 700
                            ? `${fieldWidth}px ${fieldWidth}px`
                            : 'auto'
                    }`,
                    justifyContent: 'center'
                }}>
                <ColumnLeftRight column={columns.left} {...props} />
                <ColumnLeftRight column={columns.right} {...props} />
            </div>
            <div
                className={styles.down}
                style={{
                    width: `${
                        availableWidth && availableWidth >= 700
                            ? `${(fieldWidth + 15) * 2}px`
                            : '100%'
                    }`
                }}>
                <ColumnDown column={columns.down} {...props} />
            </div>
        </div>
    );
};
