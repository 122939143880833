import React, { FC } from 'react';

import { UiConfigOrderListArrowBoxProps } from './types';
import styles from './styles.module.css';

export const UiConfigOrderListArrowBox: FC<UiConfigOrderListArrowBoxProps> = ({
    children,
    style,
    onClick
}) => {
    return (
        <div className={styles.wrapper} style={style} onClick={onClick}>
            {children}
        </div>
    );
};
