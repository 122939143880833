import { AutocompleteOption } from 'src/data/uiTypes/autocompleteTypes';

export function filterAutoCompleteOptions(
    options: AutocompleteOption[],
    values: AutocompleteOption[]
) {
    const filtred = options.filter((item) => {
        if (values.find((item2) => item2.id === item.id)) {
            return false;
        }
        return true;
    });
    return filtred;
}
