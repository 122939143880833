import { DynamicFieldT } from 'src/data/popupFormTypes';

export function checkFieldIsMassEditGlobal({ dynamicFieldT }: { dynamicFieldT: DynamicFieldT }) {
    if (dynamicFieldT?.edycja_masowa === 't') {
        switch (dynamicFieldT.typ) {
            case 'lista_obca':
                if (
                    dynamicFieldT.wyszukiwanie_rozwijalna !== 't' &&
                    (dynamicFieldT.pole_nadrzedne_typ === '' || !dynamicFieldT.pole_nadrzedne_typ)
                )
                    return true;
                break;
            case 'data':
            case 'checkbox':
            case 'lista':
            case 'tekst_bez_ograniczen':
            case 'tekst':
            case 'liczba':
                return true;
            default:
                break;
        }
    }
    return false;
}
