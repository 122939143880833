import { ObjectSlice } from 'src/store/src/object/object/types';
import { Additional } from 'src/store/src/object/object/types';
import { SystemAction } from 'src/data/types';
import { ObjectDataManager } from 'src/store/src/object/object/ObjectDataManager';

type ObjectDataManagerProps = {
    objectSlice: ObjectSlice;
    additional: Additional;
};
export class FileDownloadHelper extends ObjectDataManager {
    objectSlice: ObjectSlice;
    additional: Additional;
    constructor({ objectSlice, additional }: ObjectDataManagerProps) {
        super({ objectSlice, additional });
        this.objectSlice = objectSlice;
        this.additional = additional;
    }

    getPayloadForSectionCardForDownload({ action, id }: { action: SystemAction; id: string }) {
        const payload = [];
        let key = 'id_plikow';
        if (action?.code === 'pobierz_plik_jeden_plik') key = 'id';
        if (this.relationTreeCardElementsFlatten && this.relationTreeCardElementsFileIds) {
            let ids = this.relationTreeCardElementsFileIds;
            if (action?.code === 'pobierz_z_zalacznikami') {
                this.relationTreeCardElementsFlatten.forEach((item) => {
                    if (item.attachmentsData.length) {
                        ids = item.attachmentsData.reduce(
                            (accumulator, currentValue) => accumulator + ',' + currentValue.fileId,
                            ids
                        );
                    }
                });
            }
            payload.push({ key, value: ids });
        }
        return payload;
    }
}
