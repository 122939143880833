type CreateFormDataFunctionFromObject = {
    type: 'object';
    data: { [key: string]: any };
};

type CreateFormDataFunction = CreateFormDataFunctionFromObject;

export const createFormData = (props: CreateFormDataFunction) => {
    const formData = new FormData();
    switch (props.type) {
        case 'object':
            Object.entries(props.data).forEach(([key, value]) => {
                if (key.endsWith('[]') && value instanceof Array) {
                    for (const v of value) {
                        formData.append(key, v);
                    }
                } else {
                    formData.append(key, value);
                }
            });
            break;
        default:
            break;
    }
    return formData;
};
