import {
    BudgetScreenState,
    BugdetFormFieldStructure,
    BugdetSettlementMethodLevel
} from 'src/store/src/budget/budget/types';
import { BudgetHandlerStateGlobalFnExecuteProps } from './BudgetHandlerStateGlobal';
import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BudgetSetterOperationalState } from './BudgetSetterOperationalState';

export type BudgetFieldValueRestricterProps = {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
    calcProps: BudgetHandlerStateGlobalFnExecuteProps;
    method?: BugdetSettlementMethodLevel;
};

export class BudgetFieldValueRestricter extends BudgetSetterOperationalState {
    operationalState: BudgetOperationalState;
    calcProps: BudgetHandlerStateGlobalFnExecuteProps;
    method?: BugdetSettlementMethodLevel;

    constructor({ budget, operationalState, calcProps, method }: BudgetFieldValueRestricterProps) {
        super({ budget: budget, operationalState });
        this.method = method;
        this.operationalState = operationalState;
        this.calcProps = calcProps;
    }

    // find related to calcProps field value and restrict it value in operationalstate when needed
    restrictField() {
        if (!this.method) {
            console.error('BudgetFieldValueRestricter: method is not defined');
            return;
        }
        if (!this.calcProps.field || this.calcProps.field.onlyText) {
            console.error('BudgetFieldValueRestricter: field is not defined');
            return;
        }

        switch (this.calcProps.field?.id) {
            // 9 is for price of single hour/element 'cena (szt.)'
            case '9':
                this.restrictPriceField(this.calcProps.field);
                break;
        }
    }

    private restrictPriceField(field: BugdetFormFieldStructure) {
        switch (this.calcProps.settleObjectCode) {
            case 'dokumenty_kontrakt':
            case 'dokumenty':
                this.restrictDocumentPrice(field);
                break;
        }
    }

    private restrictDocumentPrice(field: BugdetFormFieldStructure) {
        if (
            !this.method?.rozliczaniedokumentow ||
            !this.method?.documentPriceMax ||
            !this.method?.documentPriceMin
        ) {
            return;
        }

        const fieldValue = this.getValueNumberFromOSByKeyField(this.calcProps.keyField);
        const newValue = Math.max(
            Math.min(this.method?.documentPriceMax, fieldValue),
            this.method?.documentPriceMin
        );
        this.updateOSBothTempStateField({
            newState: {
                liczba: String(newValue)
            },
            objectId: this.calcProps.objectId,
            methodId: this.calcProps.methodId,
            monthId: this.calcProps.monthId,
            name: field.name
        });
    }
}
