import { budgetStableFields } from 'src/constants/budget/budgetStableFields';
import { BudgetScreenState, BugdetSettlementMethodLevel } from 'src/store/src/budget/budget/types';
import {
    createKeyFieldBudget,
    createKeyGlobalSummary,
    createTimestampByAddThreeZero
} from 'src/utils';
import { BudgetGetterOperationalState } from 'src/utils/src/budget/BudgetGetterOperationalState';
import {
    BudgetHandlerStateGlobalFnExecuteProps,
    BudgetHandlerStateGlobalFnExecuteSharedProps
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';

// Class working on only onChange

type BudgetHandlerCalcGlobalSummaryItemProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {
    method?: BugdetSettlementMethodLevel;
};

type BudgetCalcGlobalSummaryItemExecFnProps = Omit<
    BudgetHandlerStateGlobalFnExecuteProps,
    'keyField'
>;

type BudgetHandlerCalcGlobalSummaryDataPrepareItemStructure = {
    valueHour: number;
    valuePriceTotal: number;
    valueRateExchange: number;
    specialStake: boolean;
    isInvoiced: boolean;
    date: number;
};

export class BudgetHandlerCalcGlobalSummaryDataPrepareItem extends BudgetGetterOperationalState {
    budget: BudgetScreenState;
    method?: BugdetSettlementMethodLevel;
    objectStructure: BudgetHandlerCalcGlobalSummaryDataPrepareItemStructure;

    constructor(data: BudgetHandlerCalcGlobalSummaryItemProps) {
        super(data);
        this.budget = data.budget;
        this.method = data.method;
        this.objectStructure = {
            valueHour: 0,
            valuePriceTotal: 0,
            valueRateExchange: 1,
            specialStake: false,
            isInvoiced: false,
            date: 0
        };
    }
    execute(data: BudgetCalcGlobalSummaryItemExecFnProps) {
        this.setObjectStrucutureIsInvoiced(data);
        this.setRateToExchange();
        this.setObjectStructure(data);
        this.setItem(data);
    }

    private setObjectStrucutureIsInvoiced(data: BudgetCalcGlobalSummaryItemExecFnProps) {
        const fieldCheck = budgetStableFields[data.settleObjectCode];
        if (fieldCheck) {
            this.objectStructure.isInvoiced = this.getValueBooleanFromOS({
                ...data,
                name: fieldCheck.name
            });
        }
    }

    private setRateToExchange() {
        if (this.method?.kurs_waluty) {
            this.objectStructure.valueRateExchange = this.method.kurs_waluty;
        }
    }

    private setObjectStructure(data: BudgetCalcGlobalSummaryItemExecFnProps) {
        if (this.objectStructure.isInvoiced) {
            let valueDate = 0;
            if (data.settleObjectCode === 'limit_godzin') {
                valueDate = createTimestampByAddThreeZero(data.monthId ?? '0');
            } else {
                valueDate = this.getValueNumberFromStatePrimary({
                    ...data,
                    settleObjectCode: data.settleObjectCode,
                    fieldId: '4'
                });
            }
            const valueHour = this.getValueNumberFromStatePrimary({
                ...data,
                settleObjectCode: data.settleObjectCode,
                fieldId: '7'
            });
            const specialStake = this.getValueBooleanFromStatePrimary({
                ...data,
                settleObjectCode: data.settleObjectCode,
                fieldId: '9',
                getSpecialRateOptionCode: true
            });

            const valuePriceTotal = this.getValueNumberFromStatePrimary({
                ...data,
                settleObjectCode: data.settleObjectCode,
                fieldId: '12'
            });
            this.objectStructure.date = valueDate;
            this.objectStructure.valueHour = valueHour;
            this.objectStructure.specialStake = specialStake;
            this.objectStructure.valuePriceTotal = valuePriceTotal;
        }
    }
    private setItem(data: BudgetCalcGlobalSummaryItemExecFnProps) {
        if (data.settleObjectCode === 'limit_godzin') {
            const { keyField: key } = createKeyFieldBudget({
                ...data,
                name: 'limit_godzin_{$item.id}'
            });
            if (this.objectStructure.isInvoiced) {
                Object.assign(this.operationalState.globalSummaryDataLimit, {
                    [key]: {
                        ...this.objectStructure,
                        type: data.settleObjectCode,
                        methodId: data.methodId
                    }
                });
                Object.assign(this.operationalState.globalSummaryDataLimitToCalc, {
                    [key]: {
                        ...this.objectStructure,
                        type: data.settleObjectCode,
                        methodId: data.methodId
                    }
                });
            } else {
                delete this.operationalState.globalSummaryDataLimit[key];
                delete this.operationalState.globalSummaryDataLimitToCalc[key];
            }
        } else {
            const key = createKeyGlobalSummary(data);
            if (this.objectStructure.isInvoiced) {
                Object.assign(this.operationalState.globalSummaryDataObject, {
                    [key]: {
                        ...this.objectStructure,
                        type: data.settleObjectCode,
                        methodId: data.methodId
                    }
                });
            } else {
                delete this.operationalState.globalSummaryDataObject[key];
            }
        }
    }
}
