import { BudgetHandlerStateGlobalKind } from 'src/store/src/budget/budget/types';

export const checkRunMethodSumInMonth = (
    budgetHandlerStateGlobalKind: BudgetHandlerStateGlobalKind,
    monthId: string,
    monthIdWasInteracted?: string
) => {
    switch (budgetHandlerStateGlobalKind?.kind) {
        case 'getYearsBefore':
            return true;
        default:
            if (monthIdWasInteracted === monthId) {
                return true;
            }
            return false;
    }
};
