import { FieldBankAccountNumber } from 'src/components/popupFormExtended/dynamicField/fieldBankAccountNumber/types';
import { FieldCheckbox } from 'src/components/popupFormExtended/dynamicField/fieldCheckbox/types';
import { FieldCosts } from 'src/components/popupFormExtended/dynamicField/fieldCosts/types';
import { FieldDate } from 'src/components/popupFormExtended/dynamicField/fieldDate/types';
import { FieldDateHour } from 'src/components/popupFormExtended/dynamicField/fieldDateHour/types';
import { FieldDateHours } from 'src/components/popupFormExtended/dynamicField/fieldDateHours/types';
import { FieldDateRelative } from 'src/components/popupFormExtended/dynamicField/fieldDateRelative/types';
import { FieldFile } from 'src/components/popupFormExtended/dynamicField/fieldFile/types';
import { FieldFileMany } from 'src/components/popupFormExtended/dynamicField/fieldFileMany/types';
import { FieldList } from 'src/components/popupFormExtended/dynamicField/fieldList/types';
import { FieldListDependent } from 'src/components/popupFormExtended/dynamicField/fieldListDependent/types';
import { FieldListForeign } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/types';
import { FieldListForeignMany } from 'src/components/popupFormExtended/dynamicField/fieldListForeignMany/types';
import { FieldListMany } from 'src/components/popupFormExtended/dynamicField/fieldListMany/types';
import { FieldListPrice } from 'src/components/popupFormExtended/dynamicField/fieldListPrice/types';
import { FieldListPriceMany } from 'src/components/popupFormExtended/dynamicField/fieldListPriceMany/types';
import { FieldLocation } from 'src/components/popupFormExtended/dynamicField/fieldLocation/types';
import { FieldLogin } from 'src/components/popupFormExtended/dynamicField/fieldLogin/types';
import { FieldMail } from 'src/components/popupFormExtended/dynamicField/fieldMail/types';
import { FieldMailMany } from 'src/components/popupFormExtended/dynamicField/fieldMailMany/types';
import { FieldNip } from 'src/components/popupFormExtended/dynamicField/fieldNip/types';
import { FieldNumber } from 'src/components/popupFormExtended/dynamicField/fieldNumber/types';
import { FieldNumberSettledToZero } from 'src/components/popupFormExtended/dynamicField/fieldNumberSettledToZero/types';
import { FieldObjectNumber } from 'src/components/popupFormExtended/dynamicField/fieldObjectNumber/types';
import { FieldOpenPassword } from 'src/components/popupFormExtended/dynamicField/fieldOpenPassword/types';
import { FieldPassword } from 'src/components/popupFormExtended/dynamicField/fieldPassword/types';
import { FieldPesel } from 'src/components/popupFormExtended/dynamicField/fieldPesel/types';
import { FieldPrice } from 'src/components/popupFormExtended/dynamicField/fieldPrice/types';
import { FieldPriceNumber } from 'src/components/popupFormExtended/dynamicField/fieldPriceNumber/types';
import { FieldRelationshipType } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipType/types';
import { FieldRelationshipTypeMany } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipTypeMany/types';
import { FieldSystemDate } from 'src/components/popupFormExtended/dynamicField/fieldSystemDate/types';
import { FieldText } from 'src/components/popupFormExtended/dynamicField/fieldText/types';
import { FieldTextWithPrefix } from 'src/components/popupFormExtended/dynamicField/fieldTextWithPrefix/types';
import { FieldTextWithoutRestrict } from 'src/components/popupFormExtended/dynamicField/fieldTextWithoutRestrict/types';
import { FieldShared, Fields } from 'src/components/popupFormExtended/dynamicField/types';
import { DynamicFormObjectDataConverter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectDataConverter';
import {
    FieldAdressBasicTypeValue,
    FieldMailManyElBasicType,
    FieldNumberSettledToZeroBasicTypeValue,
    FieldPriceNumberBasicTypeValue,
    FieldRelationshipTypeManyBasicTypeValue,
    FieldTextWithPrefixBasicTypeValue
} from 'src/data/fieldsFormType';
import {
    DynamicFieldT,
    FieldRelationshipTypeManyApiElements,
    RelatedObjectData
} from 'src/data/popupFormTypes';
import {
    DateManager,
    getVat,
    intToHoutMinute,
    safeGetKeyValueOptionsWithAddNewObjectOption,
    sortAutocompleteOptions
} from 'src/utils';
import { getFieldForeignValueWithSelectedSoleOption } from 'src/utils/src/popupFormExtended/getFieldForeignValueWithSelectedSoleOption';
import { DataFromApiConverter } from 'src/utils/src/shared/DataFromApiConverter';
import { NumberManager } from 'src/utils/src/shared/NumberManager';

type SetInitValueField = {
    field: DynamicFieldT;
    sharedProperties: FieldShared;
    relatedObjectData?: RelatedObjectData;
    usedForAddingObject: boolean;
};

export function setInitValueField({
    field,
    relatedObjectData,
    sharedProperties,
    usedForAddingObject
}: SetInitValueField): Fields {
    switch (field?.typ) {
        case 'checkbox': {
            let value = false;
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            value = DataFromApiConverter.getBoolean(defaultVal);

            return {
                value,
                typ: 'checkbox',
                ...sharedProperties
            } as FieldCheckbox;
        }
        case 'liczba': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            const value = DataFromApiConverter.getStringifiedNumber(defaultVal);
            return {
                value: value ? value : '0',
                typ: 'liczba',
                ...sharedProperties,
                isModify:
                    sharedProperties.isModify &&
                    !(
                        field.wyliczenie_dzialanie &&
                        // without this type mapping compiler throws error that wyliczenie_dzialanie never would have '' value
                        (field as DynamicFieldT).wyliczenie_dzialanie !== '' &&
                        field.zablokuj_po_ustawieniu_z_nadrzednego === 't'
                    )
            } as FieldNumber;
        }
        case 'lista_obca': {
            let id = field.domyslna_wartosc;
            let options = safeGetKeyValueOptionsWithAddNewObjectOption(field.opcje ?? {}, field);
            let text = field.domyslna_wartosc_nazwa
                ? field.domyslna_wartosc_nazwa
                : options && options[id];

            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod]) {
                    options = safeGetKeyValueOptionsWithAddNewObjectOption(
                        relatedObjectData?.values[field.kod + '_opcje'] ?? field.opcje ?? {},
                        field
                    );
                    id = relatedObjectData?.values[field.kod];
                    text = relatedObjectData?.values[field.kod + '_nazwa'] ?? options[id];
                }
            }
            const value = {
                value: id ?? '',
                text: text ?? '',
                opcje: options
            };
            return {
                value:
                    getFieldForeignValueWithSelectedSoleOption(
                        value,
                        DynamicFormObjectDataConverter.getArrayIdAndText(options),
                        field
                    ) ?? value,
                typ: 'lista_obca',
                ...sharedProperties,
                //special condition to match pola_typy\poletypu_lista_obca.tpl behavoiur near line 751
                isModify:
                    sharedProperties.isModify &&
                    !(
                        field.zablokuj_po_ustawieniu_z_nadrzednego === 't' &&
                        id &&
                        id !== '' &&
                        id !== '0' &&
                        !usedForAddingObject
                    )
            } as FieldListForeign;
        }
        case 'koszty': {
            const value = {
                value: DataFromApiConverter.getStringifiedNumber(field.domyslna_wartosc),
                currency: field.domyslna_wartosc_waluta ? field.domyslna_wartosc_waluta : 'PLN'
            };

            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod]) {
                    value.value = DataFromApiConverter.getStringifiedNumber(
                        relatedObjectData.values[field.kod]
                    );
                }
                if (relatedObjectData?.values[field.kod + '_waluta']) {
                    value.currency = relatedObjectData?.values[field.kod + '_waluta']
                        ? relatedObjectData?.values[field.kod + '_waluta']
                        : 'PLN';
                }
            }
            return {
                value,
                typ: 'koszty',
                ...sharedProperties
            } as FieldCosts;
        }
        case 'lista': {
            const value = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: {
                    value: value ? value : '0',
                    opcje: field.opcje
                },
                typ: 'lista',
                ...sharedProperties
            } as FieldList;
        }
        case 'lista_cena': {
            const value = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: value ? value : '0',
                typ: 'lista_cena',
                ...sharedProperties
            } as FieldListPrice;
        }
        case 'lista_zalezna': {
            let value = field.domyslna_wartosc;
            let opcje = field.opcje ?? {};

            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod]) {
                    if (relatedObjectData?.values[field.kod + '_opcje']) {
                        opcje = relatedObjectData?.values[field.kod + '_opcje'];
                    }
                    value = relatedObjectData?.values[field.kod];
                }
            }
            return {
                value: {
                    value,
                    opcje: { 0: '', ...opcje }
                },
                typ: 'lista_zalezna',
                ...sharedProperties
            } as FieldListDependent;
        }
        case 'data': {
            let date = DateManager.prepareFromVariousTypeOfDateToNewDateFromDynamicT(field);

            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod]) {
                    const data = DateManager.prepareFromVariousTypeOfDateToNewDate(
                        relatedObjectData?.values[field.kod]
                    );
                    if (data instanceof Date) {
                        date = data;
                    }
                }
            }

            return {
                // pure value, because when field has 'domyslna_dzisiejsza' === 't' defaultVal
                // has today date, on other case date should be null
                value: date,
                typ: 'data',
                ...sharedProperties
            } as FieldDate;
        }
        case 'data_godzina': {
            let date = DateManager.prepareFromVariousTypeOfDateToNewDateFromDynamicT(field);
            let hour = field?.domyslna_wartosc_godzina_godziny
                ? String(Number(field?.domyslna_wartosc_godzina_godziny))
                : '0';
            let minuteFirst = field?.domyslna_wartosc_godzina_minuty
                ? String(Number(field?.domyslna_wartosc_godzina_minuty))
                : '0';
            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod + '_int']) {
                    const timestamp = relatedObjectData?.values[field.kod + '_int'] + '000';
                    if (!isNaN(Number(timestamp))) {
                        const dateTemp = new Date(Number(timestamp));
                        if (dateTemp instanceof Date) {
                            date = dateTemp;
                            hour = String(dateTemp.getHours());
                            minuteFirst = String(dateTemp.getMinutes());
                        }
                    }
                }
            }
            const minute = intToHoutMinute(
                Number(minuteFirst),
                'minute',
                field?.przedzial_minutowy
            );
            return {
                value: {
                    date,
                    hour,
                    minute: String(Number(minute))
                },
                typ: 'data_godzina',
                ...sharedProperties
            } as FieldDateHour;
        }
        case 'data_godziny': {
            const value = {
                date: DateManager.prepareFromVariousTypeOfDateToNewDateFromDynamicT(field),
                rozp: DateManager.prepareStartOrEndDate(field, 'rozp'),
                zak: DateManager.prepareStartOrEndDate(field, 'zak')
            };

            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod]) {
                    value.date = DateManager.prepareFromVariousTypeOfDateToNewDate(
                        relatedObjectData?.values[field.kod]
                    );
                    value.rozp = relatedObjectData?.values[field.kod + '_rozp_tekst'];
                    value.zak = relatedObjectData?.values[field.kod + '_zak_tekst'];
                }
            }

            return {
                value,
                typ: 'data_godziny',
                ...sharedProperties
            } as FieldDateHours;
        }
        case 'data_wzgledna': {
            const value = {
                date: field?.domyslna_wartosc ?? '',
                zakres: field?.domyslna_wartosc_zakres ? field?.domyslna_wartosc_zakres : 'd'
            };
            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod]) {
                    value.date = relatedObjectData?.values[field.kod];
                    value.zakres = relatedObjectData?.values[field.kod + '_zakres']
                        ? relatedObjectData?.values[field.kod + '_zakres']
                        : 'm';
                }
            }
            return {
                value,
                typ: 'data_wzgledna',
                ...sharedProperties
            } as FieldDateRelative;
        }
        case 'powiazanie_typ': {
            const isEmptyValue = field.domyslna_wartosc ? false : true;
            let select = field.domyslna_wartosc
                ? field.domyslna_wartosc
                : Object.keys(field.opcje ?? {})?.[0];
            let value = field.domyslna_wartosc_id;
            let text = field.domyslna_wartosc_nazwa;
            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod]) {
                    select = relatedObjectData?.values[field.kod];
                    value = relatedObjectData?.values[field.kod + '_id'];
                    text = relatedObjectData?.values[field.kod + '_nazwa'];
                }
            }
            let type = select;
            if (field.dozwolona_opcja_pusta === 't' && field.domyslna_wartosc === '') {
                select = field.domyslna_wartosc_nazwa;
                type = '';
            }
            return {
                value: {
                    isEmptyValue,
                    select,
                    type,
                    value,
                    text
                },
                typ: 'powiazanie_typ',
                ...sharedProperties
            } as FieldRelationshipType;
        }
        case 'powiazanie_typ_wielokrotne': {
            const defaultVal = field.domyslna_wartosc
                ? field.domyslna_wartosc
                : Object.keys(field.opcje ?? {})?.[0];
            const elements: FieldRelationshipTypeManyApiElements = Array.isArray(
                relatedObjectData?.values[field.kod + '_elementy']
            )
                ? relatedObjectData?.values[field.kod + '_elementy']
                : Array.isArray(field.elementy)
                ? field?.elementy
                : [];

            const objects: FieldRelationshipTypeManyBasicTypeValue['objects'] = elements.map(
                (el) => ({
                    ...el,
                    // populate typ_nazwa property
                    typ_nazwa: field.opcje?.[el.typ] ?? ''
                })
            );
            // when adding object default objects dat ais not in field.elementy but in field.domyslna_wartosc
            if (field.domyslna_wartosc_id) {
                objects.push({
                    id: field.domyslna_wartosc_id,
                    nazwa: field.domyslna_wartosc_nazwa ?? '',
                    typ: field.domyslna_wartosc,
                    typ_nazwa: field.opcje?.[field.domyslna_wartosc] ?? ''
                });
            }

            return {
                value: {
                    select: defaultVal,
                    objects: objects,
                    elements: elements
                },
                typ: 'powiazanie_typ_wielokrotne',
                ...sharedProperties
            } as FieldRelationshipTypeMany;
        }
        case 'cena': {
            const defaultVal = {
                netto: DataFromApiConverter.getNumberString(field.domyslna_wartosc),
                brutto: DataFromApiConverter.getNumberString(field.domyslna_wartosc_brutto),
                vat: field.domyslna_wartosc_vat ? field.domyslna_wartosc_vat : '23',
                waluta: field.domyslna_wartosc_waluta ? field.domyslna_wartosc_waluta : 'PLN'
            };
            if (relatedObjectData) {
                if (relatedObjectData?.values[field.kod]) {
                    defaultVal.netto = DataFromApiConverter.getNumberString(
                        relatedObjectData?.values[field.kod]
                    );
                    defaultVal.brutto = DataFromApiConverter.getNumberString(
                        relatedObjectData?.values[field.kod + '_brutto']
                    );
                    defaultVal.vat = relatedObjectData?.values[field.kod + '_vat'];
                    defaultVal.waluta = relatedObjectData?.values[field.kod + '_waluta'];
                }
            }

            return {
                value: defaultVal,
                typ: 'cena',
                ...sharedProperties
            } as FieldPrice;
        }
        case 'cena_liczba': {
            const defaultVal: FieldPriceNumberBasicTypeValue = {
                brutto: DataFromApiConverter.getNumberString(field.domyslna_wartosc_brutto),
                netto: DataFromApiConverter.getNumberString(field.domyslna_wartosc),
                liczba: DataFromApiConverter.getNumberString(field.domyslna_wartosc_liczba),
                total_brutto: DataFromApiConverter.getNumberString(
                    field.domyslna_wartosc_total_brutto
                ),
                total_netto: DataFromApiConverter.getNumberString(
                    field.domyslna_wartosc_total_netto
                ),
                vat: field.domyslna_wartosc_vat ? field.domyslna_wartosc_vat : '23',
                waluta: field.domyslna_wartosc_waluta ? field.domyslna_wartosc_waluta : 'PLN'
            };

            if (relatedObjectData?.values[field.kod]) {
                defaultVal.netto = DataFromApiConverter.getNumberString(
                    relatedObjectData.values[field.kod]
                );
                defaultVal.liczba = DataFromApiConverter.getNumberString(
                    relatedObjectData.values[`${field.kod}_liczba`]
                );

                defaultVal.vat = relatedObjectData.values[`${field.kod}_vat`];
                defaultVal.waluta = relatedObjectData.values[`${field.kod}_waluta`];

                // kwoty brutto maja byc na sztywno to dwóch miejsc po przecinku
                if (relatedObjectData.values[`${field.kod}_total_brutto`]) {
                    defaultVal.total_brutto = DataFromApiConverter.getNumberString(
                        relatedObjectData.values[`${field.kod}_total_brutto`]
                    );
                } else {
                    const calcedValue =
                        Number(defaultVal.liczba) *
                        getVat(defaultVal.vat) *
                        Number(defaultVal.netto);
                    defaultVal.total_brutto = String(NumberManager.round(calcedValue, 2));
                }

                if (relatedObjectData.values[`${field.kod}_total_netto`]) {
                    defaultVal.total_netto = DataFromApiConverter.getNumberString(
                        relatedObjectData.values[`${field.kod}_total_netto`]
                    );
                } else {
                    defaultVal.total_netto = String(
                        Number(defaultVal.liczba) * Number(defaultVal.netto)
                    );
                }

                if (relatedObjectData.values[`${field.kod}_brutto`]) {
                    defaultVal.brutto = DataFromApiConverter.getNumberString(
                        relatedObjectData.values[`${field.kod}_brutto`]
                    );
                } else if (relatedObjectData.values[`${field.kod}_total_brutto`]) {
                    defaultVal.brutto = String(
                        Number(defaultVal.total_brutto) / Number(defaultVal.liczba)
                    );
                } else {
                    defaultVal.brutto = String(Number(defaultVal.netto) * getVat(defaultVal.vat));
                }
            }

            return {
                value: defaultVal,
                typ: 'cena_liczba',
                ...sharedProperties
            } as FieldPriceNumber;
        }
        case 'numer': {
            const defaultVal = {
                prefix: field?.domyslna_wartosc_prefix_id ?? null,
                interfix: field?.domyslna_wartosc_interfix_id ?? null,
                suffix: field?.domyslna_wartosc_suffix_id ?? null
            };
            return {
                value: defaultVal,
                typ: 'numer',
                ...sharedProperties
            } as FieldObjectNumber;
        }
        case 'numer_rachunku_bankowego': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'numer_rachunku_bankowego',
                ...sharedProperties
            } as FieldBankAccountNumber;
        }
        case 'haslo_otwarte': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'haslo_otwarte',
                ...sharedProperties
            } as FieldOpenPassword;
        }
        case 'nip': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'nip',
                ...sharedProperties
            } as FieldNip;
        }
        case 'adres': {
            const defaultVal: FieldAdressBasicTypeValue = {
                dzielnica: field.domyslna_wartosc?.dzielnica
                    ? field.domyslna_wartosc.dzielnica
                    : '',
                gmina: field.domyslna_wartosc?.gmina ? field.domyslna_wartosc.gmina : '',
                id: field.domyslna_wartosc?.id ? field.domyslna_wartosc.id : '',
                kod: field.domyslna_wartosc?.kod ? field.domyslna_wartosc.kod : '',
                kraj: field.domyslna_wartosc?.kraj ? field.domyslna_wartosc.kraj : 'POL',
                miasto: field.domyslna_wartosc?.miasto ? field.domyslna_wartosc.miasto : '',
                nrdomu: field.domyslna_wartosc?.nrdomu ? field.domyslna_wartosc.nrdomu : '',
                nrmieszkania: field.domyslna_wartosc?.nrmieszkania
                    ? field.domyslna_wartosc.nrmieszkania
                    : '',
                numer_dzialki: field.domyslna_wartosc?.numer_dzialki
                    ? field.domyslna_wartosc.numer_dzialki
                    : '',
                numer_obrebu: field.domyslna_wartosc?.numer_obrebu
                    ? field.domyslna_wartosc.numer_obrebu
                    : '',
                poczta: field.domyslna_wartosc?.poczta ? field.domyslna_wartosc.poczta : '',
                powiat: field.domyslna_wartosc?.powiat ? field.domyslna_wartosc.powiat : '',
                typulicy: field.domyslna_wartosc?.typulicy ? field.domyslna_wartosc.typulicy : '',
                ulica: field.domyslna_wartosc?.ulica ? field.domyslna_wartosc.ulica : '',
                wojewodztwo: field.domyslna_wartosc?.wojewodztwo
                    ? field.domyslna_wartosc.wojewodztwo
                    : '',
                addressId: field.domyslna_wartosc_id
            };
            return {
                value: defaultVal,
                typ: 'adres',
                ...sharedProperties
            };
        }
        case 'emaile_wielokrotne': {
            const defaultVal = {
                elements: field?.elementy
                    ? (field.elementy.map((item) => {
                          return {
                              ...item,
                              korespondencja: item?.korespondencja === 't' ? true : false,
                              wysylka_reklamowa: item?.wysylka_reklamowa === 't' ? true : false,
                              wysylka_raportow: item?.wysylka_raportow === 't' ? true : false
                          };
                      }) as FieldMailManyElBasicType[])
                    : [],
                newElements: [
                    {
                        adres: '',
                        adresat_id: '',
                        adresat_typ: '',
                        opis: '',
                        id: '1',
                        korespondencja: true,
                        wysylka_raportow: true,
                        wysylka_reklamowa: true,
                        isAdded: true
                    }
                ]
            };
            return {
                value: defaultVal,
                typ: 'emaile_wielokrotne',
                ...sharedProperties
            } as FieldMailMany;
        }
        case 'tekst': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'tekst',
                ...sharedProperties
            } as FieldText;
        }
        case 'pesel': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'pesel',
                ...sharedProperties
            } as FieldPesel;
        }
        case 'email': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'email',
                ...sharedProperties
            } as FieldMail;
        }
        case 'login': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'login',
                ...sharedProperties
            } as FieldLogin;
        }
        case 'plik': {
            const data = {
                id: '',
                name: '',
                originalFileName: ''
            };
            if (field.dane_pliku) {
                data.id = field.domyslna_wartosc ? field.domyslna_wartosc : '';
                data.name = field.dane_pliku.nazwa ? field.dane_pliku.nazwa : '';
                data.originalFileName =
                    field.dane_pliku.nazwa && field.dane_pliku.nazwa
                        ? `${field.dane_pliku.nazwa}.${field.dane_pliku.ext}`
                        : '';
            }
            return {
                value: {
                    id: data.id,
                    name: data.name,
                    originalFileName: data.originalFileName,
                    loading: false
                },
                typ: 'plik',
                ...sharedProperties
            } as FieldFile;
        }
        case 'pliki_wielokrotne': {
            return {
                value: field.pliki
                    ? Object.values(field.pliki).map(({ dane_pliku }) => ({
                          id: dane_pliku.id,
                          loading: false,
                          name: `${dane_pliku.nazwa}.${dane_pliku.ext}`
                      }))
                    : [],
                typ: 'pliki_wielokrotne',
                ...sharedProperties
            } as FieldFileMany;
        }
        case 'tekst_bez_ograniczen': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'tekst_bez_ograniczen',
                ...sharedProperties
            } as FieldTextWithoutRestrict;
        }
        case 'lista_obca_wielokrotna': {
            let arr = DynamicFormObjectDataConverter.getArrayIdAndText(
                field.opcje ? field.opcje : {}
            );

            let values: { id: string; text: string }[] = [];

            if (Array.isArray(field.domyslna_wartosc)) {
                values = field.domyslna_wartosc.map((id: string) => ({
                    id: id,
                    text: field.opcje?.[id] ?? ''
                }));
            } else if (typeof field.domyslna_wartosc === 'string') {
                arr.forEach((item) => {
                    if (item.id === field.domyslna_wartosc) {
                        values.push({
                            id: item.id,
                            text: item.text
                        });
                    }
                });
            }
            if (relatedObjectData?.values[field.kod]) {
                if (relatedObjectData?.values[field.kod + '_opcje']) {
                    arr = DynamicFormObjectDataConverter.getArrayIdAndText(
                        relatedObjectData?.values[field.kod + '_opcje']
                    );
                }
                if (Array.isArray(relatedObjectData?.values[field.kod])) {
                    values = arr.filter((item) => {
                        const found = relatedObjectData?.values[field.kod]?.find(
                            (item2: string) => item2 === item.id
                        );
                        if (found) return true;
                        else return false;
                    });
                }
            }
            const opcje = sortAutocompleteOptions({ op: arr, type: field.porzadek_elementow });
            return {
                value: {
                    values,
                    opcje: [{ id: '0', text: 'WSZYSTKIE' }, ...opcje]
                },
                typ: 'lista_obca_wielokrotna',
                ...sharedProperties
            } as FieldListForeignMany;
        }
        case 'lista_wielokrotna': {
            let arr = DynamicFormObjectDataConverter.getArrayIdAndText(
                field.opcje ? field.opcje : {}
            );

            let values: { id: string; text: string }[] = [];

            if (Array.isArray(field.domyslna_wartosc)) {
                values = field.domyslna_wartosc.map((id: string) => ({
                    id: id,
                    text: field.opcje?.[id] ?? ''
                }));
            } else if (typeof field.domyslna_wartosc === 'string') {
                arr.forEach((item) => {
                    if (item.id === field.domyslna_wartosc) {
                        values.push({
                            id: item.id,
                            text: item.text
                        });
                    }
                });
            }
            if (
                relatedObjectData?.values[field.kod] &&
                Array.isArray(relatedObjectData?.values[field.kod])
            ) {
                values =
                    relatedObjectData?.values[field.kod].map((id: string) => ({
                        id: id,
                        text: field.opcje?.[id] ?? ''
                    })) ?? [];

                if (relatedObjectData?.values[field.kod + '_opcje']) {
                    arr = relatedObjectData?.values[field.kod + '_opcje'];
                }
            }
            const opcje = sortAutocompleteOptions({ op: arr, type: field.porzadek_elementow });
            return {
                value: {
                    values,
                    opcje: [{ id: 0, text: 'WSZYSTKIE' }, ...opcje]
                },
                typ: 'lista_wielokrotna',
                ...sharedProperties
            } as FieldListMany;
        }
        case 'lista_wielokrotna_cena': {
            let arr = DynamicFormObjectDataConverter.getArrayIdAndText(
                field.opcje ? { 0: 'WSZYSTKIE', ...field.opcje } : { 0: 'WSZYSTKIE' }
            );
            let values: { id: string; text: string }[] = [];

            if (Array.isArray(field.domyslna_wartosc)) {
                values = field.domyslna_wartosc.map((id: string) => ({
                    id: id,
                    text: field.opcje?.[id] ?? ''
                }));
            } else if (typeof field.domyslna_wartosc === 'string') {
                arr.forEach((item) => {
                    if (item.id === field.domyslna_wartosc) {
                        values.push({
                            id: item.id,
                            text: item.text
                        });
                    }
                });
            }
            if (
                relatedObjectData?.values[field.kod] &&
                Array.isArray(relatedObjectData?.values[field.kod])
            ) {
                values =
                    relatedObjectData?.values[field.kod].map((id: string) => ({
                        id: id,
                        text: field.opcje?.[id] ?? ''
                    })) ?? [];

                if (relatedObjectData?.values[field.kod + '_opcje']) {
                    arr = relatedObjectData?.values[field.kod + '_opcje'];
                }
            }
            return {
                value: {
                    values,
                    opcje: arr
                },
                typ: 'lista_wielokrotna_cena',
                ...sharedProperties
            } as FieldListPriceMany;
        }
        case 'haslo': {
            // field type password have null value
            return {
                value: null,
                typ: 'haslo',
                ...sharedProperties
            } as FieldPassword;
        }
        case 'system_data': {
            const defaultVal = relatedObjectData?.values[field.kod]
                ? relatedObjectData.values[field.kod]
                : field.domyslna_wartosc;
            return {
                value: defaultVal ? defaultVal : '',
                typ: 'system_data',
                ...sharedProperties
            } as FieldSystemDate;
        }
        case 'polozenie': {
            return {
                value: {
                    latitude: field.domyslna_wartosc_szerokosc ?? '',
                    longitude: field.domyslna_wartosc_dlugosc ?? ''
                },
                typ: 'polozenie',
                ...sharedProperties
            } as FieldLocation;
        }
        case 'liczba_rozliczana_do_zera': {
            const value: FieldNumberSettledToZeroBasicTypeValue = {
                baseValue: DataFromApiConverter.getNumberString(field.domyslna_wartosc),
                settled: DataFromApiConverter.getNumberString(field.domyslna_wartosc_rozliczono),
                restToSettle: DataFromApiConverter.getNumberString(field.domyslna_wartosc_pozostalo)
            };
            if (relatedObjectData?.values[field.kod]) {
                let baseValueTemp = DataFromApiConverter.getNumber(
                    relatedObjectData?.values[field.kod]
                );
                let settledTemp = DataFromApiConverter.getNumber(
                    relatedObjectData?.values[field.kod + '_rozliczono']
                );
                value.baseValue = String(baseValueTemp);
                value.settled = String(settledTemp);
                value.restToSettle = String(baseValueTemp - settledTemp);
            }
            return {
                value,
                typ: 'liczba_rozliczana_do_zera',
                ...sharedProperties
            } as FieldNumberSettledToZero;
        }
        case 'tekst_z_przedrostkiem': {
            const defaultVal: FieldTextWithPrefixBasicTypeValue = {
                text: field.domyslna_wartosc,
                prefix: field.domyslna_wartosc_prefix ?? ''
            };
            if (relatedObjectData?.values[field.kod]) {
                defaultVal.text = relatedObjectData.values[field.kod];
                defaultVal.prefix = relatedObjectData.values[`${field.kod}_prefix`];
            }

            return {
                value: defaultVal,
                typ: 'tekst_z_przedrostkiem',
                ...sharedProperties
            } as FieldTextWithPrefix;
        }
        case 'dodanie_powiazania': {
            return {
                typ: 'dodanie_powiazania',
                value: '',
                ...sharedProperties
            };
        }
        default: {
            return null;
        }
    }
}
