import { BudgetSumKeyPartsAll } from 'src/store/src/budget/budget/types';

export type CreateKeySumSharedProps = Omit<
    CreateKeySumProps,
    'code' | 'fieldId' | 'methodId' | 'monthId'
>;

export type CreateKeySumProps = BudgetSumKeyPartsAll;

export function createKeySumBudget(data: CreateKeySumProps): {
    keySum: string;
    keySumPrefixMethod: `${string}.${string}`;
} {
    const { keySum } = createKeySumBudgetItem(data);
    return {
        keySum,
        keySumPrefixMethod: `${data.methodId}.${keySum}`
    };
}

function createKeySumBudgetItem({
    code,
    fieldId,
    methodId,
    monthId,
    monthLevelTypeId,
    monthLevelType,
    methodLevelType
}: CreateKeySumProps) {
    let key = '';
    let keyForeign = '';

    if (code) {
        key = `${code}#${methodLevelType}!${fieldId}!${methodId}`;
    } else key = `#${methodLevelType}!${fieldId}!${methodId}`;

    if (monthId) {
        key = key + `!${monthId}`;
    }
    if (monthLevelType) {
        key = key + `!${monthLevelType}`;
    }
    if (monthLevelTypeId) {
        key = key + `!${monthLevelTypeId}`;
    }

    return {
        keySum: key,
        keySumForeign: keyForeign
    };
}
