import { FieldListDependent } from './../../../dynamicField/fieldListDependent/types.d';

import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';

type FieldDependentListDependentManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldListDependent;
};

export class FieldDependentListDependendManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldListDependent;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentListDependentManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async zmienPole() {
        if (
            this.fieldWasTriggered?.updatedNumber === undefined ||
            (this.fieldWasTriggered?.updatedNumber !== undefined &&
                this.fieldWasTriggered.updatedNumber < 5)
        ) {
            if (!this.fieldWasTriggered.isUpdatedDepedentent) {
                await this.checkAndChangeFieldChangeStateOfField();
                await this.runNested(this.fieldWasTriggeredDynamicFieldT);
            }
        }
    }

    private async checkAndChangeFieldChangeStateOfField() {
        let opcje = this.fieldWasTriggered.value.opcje;
        const data = await this.getValueToSetForChangeField();

        opcje = { 0: '', ...data.opcje };
        const value = Object.keys(opcje).includes(this.fieldWasTriggered.value.value)
            ? this.fieldWasTriggered.value.value
            : '';
        const newValue = {
            ...this.fieldWasTriggered,
            value: {
                ...this.fieldWasTriggered.value,
                opcje,
                value
            }
        };
        this.isRunNested = true;
        this.setTempFieldsState(newValue, 'withoutFlag');
    }

    async zmienWartoscPola() {
        if (
            this.fieldWasTriggered?.updatedNumber === undefined ||
            (this.fieldWasTriggered?.updatedNumber !== undefined &&
                this.fieldWasTriggered.updatedNumber < 5)
        ) {
            if (!this.fieldWasTriggered.isUpdatedDepedententDefault) {
                await this.checkAndChangeFieldChangeValueStateOfField();
                await this.runNested(this.fieldWasTriggeredDynamicFieldT);
            }
        }
    }

    private async checkAndChangeFieldChangeValueStateOfField() {
        let val = this.fieldWasTriggered.value.value;

        const value = await this.getValueToSetForChangeValueField();
        if (value) val = value;

        const newValue = {
            ...this.fieldWasTriggered,
            value: {
                ...this.fieldWasTriggered.value,
                value: val
            }
        };
        if (this.fieldWasTriggered.value.value !== val) {
            this.setTempFieldsState(newValue, 'isUpdatedDepedententDefault');
            this.isRunNested = true;
        }
    }
}
