import {
    BudgetElementCommon,
    SettledObjectsCodes,
    BudgetScreenState,
    BugdetSettlementMethodLevel,
    SettledElement,
    SettledSingleElements
} from 'src/store/src/budget/budget/types';
import { BudgetFormStateRHF } from 'src/hooks/src/budget/useFormBudget';

export class BudgetApiFormDataObjectIdsAppender {
    budgetState: BudgetScreenState;
    dataState: BudgetFormStateRHF;

    constructor(budgetState: BudgetScreenState, dataState: BudgetFormStateRHF) {
        this.budgetState = budgetState;
        this.dataState = dataState;
    }

    appendObjectIdentifiersToData(
        data: { [key: string]: any },
        budgetStateMethodsLevel: BugdetSettlementMethodLevel[]
    ) {
        for (const method of budgetStateMethodsLevel) {
            for (const year of method.years) {
                if (year.months)
                    for (const month of year.months) {
                        this.appendObjectPropertiesToDataFromSettledElement(
                            data,
                            month.settledElements,
                            method.id,
                            month.data_rozp
                        );
                        this.appendObjectPropertiesToDataFromSettledSingleElement(
                            data,
                            month.settledSingleElements,
                            method.id,
                            month.data_rozp
                        );
                        if (month.hoursLimit) {
                            this.appendObjectPropertiesToDataFromSettleObject(
                                data,
                                method.id,
                                'limit_godzin',
                                month.hoursLimit,
                                month.data_rozp
                            );
                        }
                        for (const budgetCase of month.cases) {
                            this.appendObjectPropertiesToDataFromSettledElement(
                                data,
                                budgetCase.settledElements,
                                method.id,
                                month.data_rozp
                            );
                        }
                        for (const project of month.projects) {
                            this.appendObjectPropertiesToDataFromSettledElement(
                                data,
                                project.settledElements,
                                method.id,
                                month.data_rozp
                            );
                        }
                    }
            }
            this.appendObjectPropertiesToDataFromSettledElement(
                data,
                method.settledElements,
                method.id
            );
            this.appendObjectPropertiesToDataFromSettledSingleElement(
                data,
                method.settledSingleElements,
                method.id
            );
        }
    }

    private appendObjectPropertiesToDataFromSettledElement(
        data: { [key: string]: any },
        settledElements: SettledElement[],
        methodId: string,
        month_data_rozp?: string
    ) {
        for (const settledElement of settledElements) {
            const dataKey = this.getObjectIdentifierDataKeyFromSettleObjectCode(
                settledElement.code
            );
            if (!(dataKey in data)) {
                data[dataKey] = [];
            }
            for (const object of settledElement.objects) {
                data[dataKey].push(
                    this.getObjectIdentifierValueFromSettleObjectCode(
                        settledElement.code,
                        object,
                        month_data_rozp
                    )
                );
                this.appendRestOfNeededObjectPropertiesToData(
                    data,
                    object,
                    settledElement.code,
                    methodId,
                    month_data_rozp
                );
            }
        }
        return data;
    }

    private appendObjectPropertiesToDataFromSettledSingleElement(
        data: { [key: string]: any },
        settledSingleElement: SettledSingleElements,
        methodId: string,
        month_data_rozp?: string
    ) {
        let key: keyof SettledSingleElements;
        for (key in settledSingleElement) {
            this.appendObjectPropertiesToDataFromSettleObject(
                data,
                methodId,
                key,
                settledSingleElement[key],
                month_data_rozp
            );
        }
        return data;
    }

    private appendObjectPropertiesToDataFromSettleObject(
        data: { [key: string]: any },
        methodId: string,
        settleObjectKey: SettledObjectsCodes,
        settleObject: BudgetElementCommon,
        month_data_rozp?: string
    ) {
        const dataKey = this.getObjectIdentifierDataKeyFromSettleObjectCode(settleObjectKey);
        if (!(dataKey in data)) {
            data[dataKey] = [];
        }

        data[dataKey].push(
            this.getObjectIdentifierValueFromSettleObjectCode(
                settleObjectKey,
                settleObject,
                month_data_rozp
            )
        );
        this.appendRestOfNeededObjectPropertiesToData(
            data,
            settleObject,
            settleObjectKey,
            methodId,
            month_data_rozp
        );
    }

    /**
     * @returns formdata key for which we will append object ids(like zadania[]: 1542) for certain @param objectType
     */
    private getObjectIdentifierDataKeyFromSettleObjectCode(objectType: SettledObjectsCodes) {
        switch (objectType) {
            case 'ryczalt_miesiac':
                return 'ryczalty[]';
            case 'ryczalt':
                return 'kontrakty_ryczalty_id[]';
            case 'dokumenty_kontrakt':
                return 'dokumenty[]';
            case 'oplatywstepne_za_sprawy':
                return 'oplatywstepne_sprawy[]';
            case 'kosztyzastepstwa':
                return 'kosztzastepstwa[]';
            case 'limit_godzin':
                return 'limity_godzin[]';
            case 'successfees':
                return 'successfee[]';
            case 'koszty_projektow':
                return 'koszty[]';

            default:
                return `${objectType}[]`;
        }
    }

    /**
     * analog to getObjectIdentifierDataKeyFromSettleObjectCode but return id for formdata object identification (like zadania[]: 1542)
     */
    private getObjectIdentifierValueFromSettleObjectCode(
        objectType: SettledObjectsCodes,
        settledSingleElement: BudgetElementCommon,
        month_data_rozp?: string
    ) {
        switch (objectType) {
            case 'koszty':
                return `${settledSingleElement.id}_${month_data_rozp}`;

            default:
                return settledSingleElement.id;
        }
    }

    private appendRestOfNeededObjectPropertiesToData(
        data: { [key: string]: any },
        settledSingleElement: BudgetElementCommon,
        objectType: SettledObjectsCodes,
        methodId: string,
        month_data_rozp?: string
    ) {
        this.appendObjectIdIdentifierToData(
            data,
            settledSingleElement,
            objectType,
            month_data_rozp
        );
        this.appendSettlementMethodToObjectIdentifierToData(
            data,
            settledSingleElement,
            methodId,
            objectType,
            month_data_rozp
        );

        this.appendSettlementMethodsRatesToData(data);
    }

    private appendSettlementMethodToObjectIdentifierToData(
        data: { [key: string]: any },
        settledSingleElement: BudgetElementCommon,
        methodId: string,
        objectType: SettledObjectsCodes,
        month_data_rozp?: string
    ) {
        // to mimic z_kontrakt_137602:525 and so on
        switch (objectType) {
            case 'ryczalt':
                break;
            case 'ryczalt_miesiac':
                data[`ryczalt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="koszt_kontrakt_{$item.id}_{$miesiac.data_rozp}" value="{$kontrakt.id}" />
            case 'koszty':
                data[`koszt_kontrakt_${settledSingleElement.id}_${month_data_rozp}`] = methodId;
                break;
            // <input type="hidden" name="rozprawa_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'rozprawy':
                data[`rozprawa_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="kontakt_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'kontakty':
                data[`kontakt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="rozprawa_ryczalt_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'rozprawy_ryczalty':
                data[`rozprawa_ryczalt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="kontakt_ryczalt_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'kontakty_ryczalty':
                data[`kontakt_ryczalt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="dokumenty_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'dokumenty':
                data[`dokumenty_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="limit_godzin_kontrakt_{$miesiac.limit_godzin_id}" value="{$kontrakt.id}" />
            case 'limit_godzin':
                data[`limit_godzin_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="fakturowaneelicencja_kontrakt_{$i.id}" value="{$kontrakt.id}" />
            case 'fakturowaneelicencje':
                data[`fakturowaneelicencja_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="successfee_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'successfees':
                data[`successfee_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="kosztzastepstwa_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'kosztyzastepstwa':
                data[`kosztzastepstwa_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="dokumenty_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'dokumenty_kontrakt':
                data[`dokumenty_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'oplatywstepne_za_sprawy':
                data[`oplatywstepne_sprawy_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'ryczalty_za_sprawy':
                data[`ryczalty_za_sprawy_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'koszty_projektow':
                data[`koszt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'etapyprojektow':
                data[`e_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'zadania':
            default:
                data[`z_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
        }
    }

    private appendObjectIdIdentifierToData(
        data: { [key: string]: any },
        settledSingleElement: BudgetElementCommon,
        objectType: SettledObjectsCodes,
        month_data_rozp?: string
    ) {
        switch (objectType) {
            case 'koszty':
                data[`koszt_id_${settledSingleElement.id}_${month_data_rozp}`] =
                    settledSingleElement.id;
                break;
            default:
                break;
        }
    }

    private appendSettlementMethodsRatesToData(data: { [key: string]: any }) {
        for (const method of this.budgetState.settlementMethods) {
            data[`kurs_kontraktu_${method.id}`] = method.kurs_waluty;
        }
    }
}
