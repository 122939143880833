import isSameDay from 'date-fns/isSameDay';
import { EventsData } from '../../api/types';
import { CalendarDays } from '../../store/src/calendar/calendar/types';
import { EventStub } from '../types';

/**
 * Function divides all calendar events on those displayed on user and those displayed outside calendar user. Additionally
 * @returns [gridEvents, noGridEvents]
 */
export function divideEvents(
    events: EventsData['events'],
    days: CalendarDays
): [EventStub[], EventStub[]] {
    // store index of last day
    const lastDayIndex = days.length - 1;

    // prepare arrays for different types of events. Fill it later
    const gridEvents: EventStub[] = [];
    const noGridEvents: EventStub[] = [];

    for (const ev of events) {
        // Create event stub object for each event
        const event: EventStub = {
            id: ev.id,
            type: ev.type,
            name: ev.name,
            startHour: ev.startHour,
            endHour: ev.endHour,
            endTime: ev.endTime,
            startTime: ev.startTime,
            endDayTimestamp: null!,
            startDayTimestamp: null!
        };

        // iterate over each day checking if events start or ends in that day
        days.forEach((day) => {
            // compare events start and end time with display days
            if (isSameDay(ev.startTime, day.startTimestamp))
                event.startDayTimestamp = day.startTimestamp;
            if (isSameDay(ev.endTime, day.startTimestamp))
                event.endDayTimestamp = day.startTimestamp;
        });

        // noGridevent only when has noGrid flag start to true, and spans over one display day
        if (ev.noGrid && !isSameDay(event.startDayTimestamp, event.endDayTimestamp)) {
            noGridEvents.push(event);
        } else {
            gridEvents.push(event);
        }

        // event started before display days, start its startDay as first display day
        if (!event.startDayTimestamp) event.startDayTimestamp = days[0].startTimestamp;

        // event finished after display days, start its endDay as last display day
        if (!event.endDayTimestamp) event.endDayTimestamp = days[lastDayIndex].startTimestamp;
    }

    return [gridEvents, noGridEvents];
}
