import React, { FC } from 'react';

import { FieldValueToAccpetWrapperProps } from './types';
import styles from './FieldValueToAcceptWrapper.module.css';
import FieldValueToAcceptSection from '../fieldValueToAcceptSection/fieldValueToAcceptSection';

const FieldValueToAcceptWrapper: FC<FieldValueToAccpetWrapperProps> = ({
    valueToAccept,
    textToAccept,
    onAcceptValue,
    value,
    children
}) => {
    return valueToAccept === undefined || valueToAccept === value ? (
        <>{children}</>
    ) : (
        <div className={styles.wrapper}>
            {children}
            <FieldValueToAcceptSection
                valueToAccept={valueToAccept}
                textToAccept={textToAccept}
                onAcceptValue={onAcceptValue}
            />
        </div>
    );
};

export default React.memo(FieldValueToAcceptWrapper);
