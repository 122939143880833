import { Box } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useComponentSize } from 'react-use-size';
import ShortcutItem from 'src/components/menu/molecules/shortcutItem/ShortcutItem';
import { useTypedSelector } from 'src/store';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import { ScreenMenuData } from 'src/store/src/menu/menu/types';
import { DimArray } from './types';
import styles from './ShortcutsMenu.module.css';

const ShortcutsMenu: FC = () => {
    const modules = useTypedSelector((state) => state.menu.menuStructureData.modules);
    const [pinnedScreens, setPinnedScreens] = useState<ScreenMenuData[]>([]);
    const [titleIsVisible, SetTitleIsVisible] = React.useState<boolean>(true);
    const { ref } = useComponentSize();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [cut, setCut] = React.useState<number>(-1);
    const icons = React.useRef<Record<string, DimArray>>({});
    const screenChangeEmit = React.useRef<any>();

    useEffect(() => {
        const { pinnedScreens: pinned } = MenuStateManager.getPinnedScreens(modules);
        setIsVisible(true);
        SetTitleIsVisible(true);
        setPinnedScreens([...pinned]);
        setCut(-1);
        const codemap = pinned.map((el) => {
            return el.code;
        });
        Object.keys(icons.current).forEach((el) => {
            const is = codemap.includes(el);
            if (!is) {
                delete icons.current[el];
            }
        });
    }, [modules]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            screenChangeEmit.current = [];
        });
        icons.current = {};
        return () => {
            icons.current = {};
            setIsVisible(false);
            window.removeEventListener('resize', () => {
                screenChangeEmit.current = [];
            });
        };
    }, []);

    useEffect(() => {
        if (isVisible == true) {
            reclculate();
        }
    }, [screenChangeEmit.current]);

    useEffect(() => {
        SetTitleIsVisible(true);
        setIsVisible(false);
        reclculate();
    }, [[...pinnedScreens]]);

    const setDim = (icon: number, text: number, index: string) => {
        icons.current[index] = [icon, text];
        if (Object.keys(icons.current).length == pinnedScreens.length && pinnedScreens.length > 0) {
            reclculate();
        }
    };

    const reclculate = () => {
        if (ref.current.offsetWidth > 0) {
            let toCut = -1;
            const suma = Object.values(icons.current).map((el) => {
                return el[0] + el[1];
            });

            const sumaIcon = Object.values(icons.current).map((el) => {
                return el[0];
            });
            const sumall = suma.reduce((prev, cur) => {
                return prev + cur;
            }, 0);

            const sumaicon = sumaIcon.reduce((prev, cur) => {
                return prev + cur;
            }, 0);

            if (sumall > ref.current.offsetWidth - 10) {
                SetTitleIsVisible(false);

                if (sumaicon > ref.current.offsetWidth - 10) {
                    let sumax = 0;
                    sumaIcon.forEach((el) => {
                        sumax += el;
                        if (sumax <= ref.current.offsetWidth) {
                            toCut++;
                        }
                    });

                    SetTitleIsVisible(false);
                }
            } else {
                SetTitleIsVisible(true);
            }

            setCut(toCut);
            setIsVisible(true);
        }
    };

    const setDivider = (index: number) => {
        if (cut == -1 && index === pinnedScreens.length - 1) {
            return true;
        }
        if (cut !== -1 && index == cut - 1) {
            return true;
        }
        return false;
    };

    const setCutMe = (index: number) => {
        return cut != -1 ? (index < cut ? true : false) : true;
    };

    return (
        <div className={styles.wrapperMain} ref={ref}>
            <Box
                sx={{
                    display: 'flex',
                    height: '35px',
                    opacity: isVisible ? '1' : '0',
                    transition: 'all 0.3s linear'
                }}>
                {pinnedScreens.map((item, index) => {
                    return (
                        <ShortcutItem
                            titleIsVisible={titleIsVisible}
                            toCut={setCutMe(index)}
                            content={item}
                            key={item.code}
                            getDims={(text, icon) => {
                                if (icons.current[item.code] === undefined) {
                                    setDim(icon, text, item.code);
                                }
                            }}
                            showDivider={setDivider(index)}
                        />
                    );
                })}
            </Box>
        </div>
    );
};

export default ShortcutsMenu;
