import { EventBus } from 'src/utils/src/shared/EventBus';
import { generateToken } from 'src/utils/src/shared/generateToken';

export class EventHandler {
    static setFieldFocusON({
        dataKey,
        scrollKey,
        res
    }: {
        dataKey: string;
        scrollKey: string;
        res: (value: boolean) => void;
    }) {
        let eventScrollId = '';
        if (scrollKey && dataKey) {
            const token = generateToken();
            eventScrollId = `${scrollKey}-${token}`;
            EventBus.$on(eventScrollId, () => {
                const el2: HTMLElement | null = document.querySelector(`[${dataKey}=${scrollKey}]`);
                if (el2) {
                    el2?.focus();
                    el2?.click();
                }
                EventBus.$off(eventScrollId);
                res(false);
            });
        }

        return {
            eventScrollId
        };
    }
}
