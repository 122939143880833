import { useEffect } from 'react';
import { useTypedSelector } from 'src/store';

type Func = () => void;

export const usePressKeyWithKeyCodeTwo = (func: Func, keyCode: string, popupKey: string) => {
    const popups = useTypedSelector((state) => state.popup.popupItemState);
    const keys = Object.keys(popups);
    const lastKey = keys[keys.length - 1];
    const isPopupOnTheTop = lastKey === popupKey;

    useEffect(() => {
        function run(e: KeyboardEvent): void {
            if (isPopupOnTheTop) {
                if (e.code === keyCode) {
                    func();
                }
            }
        }
        window.addEventListener('keydown', run);
        return function cleanupListener() {
            window.removeEventListener('keydown', run);
        };
    }, [isPopupOnTheTop, func, keyCode]);
};
