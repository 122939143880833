import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { StartupScreenConfig } from './types';

export async function updateStartupScreenConfiguraton(
    data: StartupScreenConfig
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        'api_react/src/startupScreen/updateStartupScreenConfiguraton.php',
        'POST',
        data
    );
}
