/**
 * wrapper for function for legacy compatible purpouses to not lock object while they are editted
 * @param navFunction function actually performing some navigation
 */
export const makeEditSafeNavigation = (navFunction: () => void) => {
    if (window.location.href.includes('edycja=1')) {
        if (
            window.confirm(
                'Uwaga opuszczenie okna w trybie edycji spowoduje zablokowanie danych ! Czy chcesz kontynuować ?'
            )
        ) {
            navFunction();
        }
    } else {
        navFunction();
    }
};
