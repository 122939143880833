import { Fields } from 'src/components/popupFormExtended/dynamicField/types';
import { DynamicFormObjectDataConverter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectDataConverter';
import { DateManager } from 'src/utils/src/shared/DateManager';
import { getVat } from 'src/utils/src/shared/getVat';

type DataCalcFromField = {
    kod: string;
    typ: string;
    valueList: string | null;
    valueText: string | null;
    value: string | null;
    id_zakladki: string | null;
    id: string | null;
    timestampAll: number;
    timestampAllEndHour: number;
    timestampWithoutHourMinute: number;
    timestampMinute: number;
    timestampHour: number;
    hour: number;
    minute: number;
    hourTwo: number;
    minuteTwo: number;
    checkbox: boolean;
    number: number;
    numbersCheckbox: number;
    opcje: { [key: string]: string };
    optionsAutoComplete: {
        id: string;
        text: string;
    }[];
    currency: string;
    netto: number;
    brutto: number;
    vat: string;
    vatPlusUnitOneInNumber: number;
    prefix: string | null;
    interfix: string | null;
    suffix: string | null;
};

export const getValuesFromFields = (field: Fields) => {
    const data: DataCalcFromField = {
        typ: field?.typ ? field.typ : 'default',
        id_zakladki: field?.id_zakladki ?? null,
        id: field?.id ?? null,
        kod: '',
        value: '',
        valueList: '',
        valueText: '',
        timestampAll: 1,
        timestampWithoutHourMinute: 1,
        timestampMinute: 1,
        timestampHour: 1,
        timestampAllEndHour: 1,
        hour: 0,
        minute: 0,
        hourTwo: 0,
        minuteTwo: 0,
        number: 0,
        checkbox: false,
        numbersCheckbox: 0,
        brutto: 0,
        netto: 0,
        opcje: {},
        optionsAutoComplete: [],
        currency: '',
        vat: '',
        vatPlusUnitOneInNumber: 1,
        prefix: '',
        interfix: '',
        suffix: ''
    };

    if (field) data.kod = field.kod;

    switch (field?.typ) {
        case 'pesel':
        case 'tekst':
            data.value = field.value;
            break;
        case 'liczba':
            data.value = field.value;
            data.number = Number(field.value);
            break;
        case 'numer':
            data.suffix = field.value.suffix;
            data.interfix = field.value.interfix;
            data.prefix = field.value.prefix;
            break;
        case 'lista':
        case 'lista_cena':
            if (field.value.value) {
                data.valueList = field.value.value;
                data.vat = field.value.value;
            }
            data.opcje = field.value.opcje;
            break;
        case 'koszty':
            data.number = Number(field.value.value);
            data.value = field.value.value;
            data.currency = field.value.currency;
            break;
        case 'powiazanie_typ':
            data.valueList = field.value.value;
            data.valueText = field.value.text;
            break;
        case 'lista_obca':
            data.valueList = field.value.value;
            data.valueText = field.value.text;
            data.opcje = field.value.opcje;
            data.optionsAutoComplete = DynamicFormObjectDataConverter.getArrayIdAndText(
                field.value.opcje
            );
            break;
        case 'lista_zalezna':
            data.valueList = field.value.value;
            data.opcje = field.value.opcje;
            break;
        case 'checkbox':
            data.checkbox = field.value;
            break;
        case 'cena_liczba':
            data.number = Number(field.value.liczba);
            data.netto = Number(field.value.netto);
            data.vat = field.value.vat;
            data.vatPlusUnitOneInNumber = getVat(field.value.vat);
            data.currency = field.value.waluta;
            break;
        case 'cena':
            data.currency = field.value.waluta;
            data.netto = Number(field.value.netto);
            data.brutto = Number(field.value.brutto);
            data.vatPlusUnitOneInNumber = getVat(field.value.vat);
            data.vat = field.value.vat;
            break;
        case 'lista_obca_wielokrotna':
        case 'lista_wielokrotna':
        case 'lista_wielokrotna_cena':
            data.numbersCheckbox = Number(field.value.values.length);
            data.optionsAutoComplete = field.value.opcje;
            data.opcje = DynamicFormObjectDataConverter.setObjectKeyValue(field.value.opcje);
            break;
        case 'data':
            if (field.value) {
                data.timestampAll = new Date(field.value).getTime();
                data.timestampWithoutHourMinute = new Date(field.value).setHours(0, 0, 0);
            }
            break;
        case 'data_godzina':
            if (field.value.date && field.value.hour && field.value.minute) {
                data.timestampAll = DateManager.setTimestampFromDateMinuteHour2({
                    date: field.value.date,
                    hour: field.value.hour,
                    minute: field.value.minute
                });
                data.timestampWithoutHourMinute = new Date(field.value.date as Date).setHours(
                    0,
                    0,
                    0
                );
                data.hour = Number(field.value.hour);
                data.minute = Number(field.value.minute);
                data.timestampMinute = DateManager.setTimestamFromMinutes(field.value.minute ?? 0);
                data.timestampHour = DateManager.setTimestamFromHours(field.value.hour ?? 0);
            }
            break;
        case 'data_godziny':
            if (field.value.rozp && field.value.zak) {
                const dataRoz = DateManager.getMinutesAndHours(field.value.rozp);
                const dataZak = DateManager.getMinutesAndHours(field.value.zak);
                data.hour = dataRoz[0];
                data.minute = dataRoz[1];
                data.hourTwo = dataZak[0];
                data.minuteTwo = dataZak[1];
            }
            if (field.value.date) {
                data.timestampWithoutHourMinute = new Date(field.value.date as Date).setHours(
                    0,
                    0,
                    0
                );
                data.timestampAll = new Date(field.value.date as Date).setHours(
                    data.hour,
                    data.minute,
                    0
                );
                data.timestampAllEndHour = new Date(field.value.date as Date).setHours(
                    data.hourTwo,
                    data.minuteTwo,
                    0
                );
            }
            break;
        default:
            break;
    }
    return data;
};
