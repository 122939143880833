import { maskURL } from 'src/api/src/shared/maskUrl';
import { makeErrorText } from '../shared/makeErrorText';

/**
 * @param pathname not from window.location.pathname because this not consider basename
 * @returns code from api
 */
export const getLinkCode = async (
    locationState: any,
    pathname: string,
    params?: string
): Promise<string | null> => {
    const apiResponse = await maskURL(pathname + (params ?? window.location.search), locationState);

    if (!apiResponse.success) {
        console.error(makeErrorText(apiResponse));
        return null;
    }

    return apiResponse.data;
};
