import React from 'react';
import { useTypedSelector } from 'src/store';
import { Box } from '@mui/material';
import ScrollPan from 'src/components/shared/organisms/scrollPan/ScrollPan';
import {
    MENU_HEADER_HEIGHT,
    MENU_LEFT_NAVIGATION_WIDTH_FOLDED,
    MENU_LEFT_NAVIGATION_WIDTH_UNFOLDED
} from 'src/constants';
import { NavigationMenu } from './NavigationMenu';

export const NavigationMenuScrollable = () => {
    const isUnfolded = useTypedSelector((state) => state.menu.isUnfolded);

    React.useEffect(() => {
        const unfoldedMenuWidth = parseInt(MENU_LEFT_NAVIGATION_WIDTH_UNFOLDED.replace('px', ''));
        const foldedMenuWidth = parseInt(MENU_LEFT_NAVIGATION_WIDTH_FOLDED.replace('px', ''));

        const sizeChange = isUnfolded
            ? unfoldedMenuWidth - foldedMenuWidth
            : foldedMenuWidth - unfoldedMenuWidth;

        if (
            typeof window.PrzesunObiekty !== 'undefined' &&
            typeof window.PrzesunObiekty === 'function'
        ) {
            window.PrzesunObiekty(sizeChange);
        }
        if (
            typeof window.PrzesunObiektyWszystkie !== 'undefined' &&
            typeof window.PrzesunObiektyWszystkie === 'function'
        ) {
            window.PrzesunObiektyWszystkie(!isUnfolded ? sizeChange : 0, false);
        }
    }, [isUnfolded]);

    return (
        <ScrollPan
            showVertical={true}
            showHorizonal={false}
            size={12}
            maxHeight={`100vh - ${MENU_HEADER_HEIGHT}`}
            minHeight={`100vh - ${MENU_HEADER_HEIGHT}`}
            background="#BEB8B8"
            // that width have old menu, to be compatible with old content use fixed 194px width
            maxWidth={`${
                isUnfolded ? MENU_LEFT_NAVIGATION_WIDTH_UNFOLDED : MENU_LEFT_NAVIGATION_WIDTH_FOLDED
            }`}
            minWidth={`${
                isUnfolded ? MENU_LEFT_NAVIGATION_WIDTH_UNFOLDED : MENU_LEFT_NAVIGATION_WIDTH_FOLDED
            }`}
            boxShadow={false}
            thumbColor="#fff">
            <Box
                component="nav"
                sx={{
                    minHeight: `calc(100vh - ${MENU_HEADER_HEIGHT})`,
                    height: '100%',

                    background: (theme) => theme.palette.background.paper
                }}>
                <NavigationMenu />
            </Box>
        </ScrollPan>
    );
};

export default NavigationMenu;
