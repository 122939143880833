import { useCallback, useEffect, useRef } from 'react';
import { BroadcastAddBackPayload } from 'src/components/popupFormExtended/types';
import { ADD_BACK_BROADCAST_CHANNEL } from 'src/constants';

/**
 *
 * @param targetFieldCode code of field that wait for ADD_BACK_BROADCAST_CHANNEL event
 * @returns
 */
export const useAddBackBroadcastChannel = (targetFieldCode: string) => {
    const bcRef = useRef<BroadcastChannel>();

    useEffect(() => {
        return () => {
            bcRef.current?.close();
        };
    }, []);

    /**
     * @param callback function called when is called proper ADD_BACK_BROADCAST_CHANNEL event related to @targetFieldCode
     */
    const connectBroadcastChannel = useCallback(
        (callback: (event: MessageEvent<BroadcastAddBackPayload>) => void) => {
            // in case if bc was opened before
            bcRef.current?.close();

            bcRef.current = new BroadcastChannel(ADD_BACK_BROADCAST_CHANNEL);
            bcRef.current.addEventListener(
                'message',
                (event: MessageEvent<BroadcastAddBackPayload>) => {
                    if (
                        event.data.windowName === window.name &&
                        event.data.targetFieldCode === targetFieldCode
                    ) {
                        callback(event);
                        bcRef.current?.close();
                    }
                }
            );
        },
        [targetFieldCode]
    );

    return connectBroadcastChannel;
};
