import startOfWeek from 'date-fns/startOfWeek';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import endOfMonth from 'date-fns/endOfMonth';
import isSameDay from 'date-fns/isSameDay';
import startOfMonth from 'date-fns/startOfMonth';
import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import { AppDate } from '../../../components/calendar/templates/calendarView/types';

/**
 * returns first monday and last sunday to display in month view (which usually contains 35 days, but sometimes 28 or 42)
 */
export function getCalendarMonthBoundries(date: AppDate): [AppDate, AppDate] {
    // First day of month
    const firstDay = startOfMonth(date);

    // Last day of month
    const lastDay = endOfMonth(date);

    // Monday of first day of month week, out start day
    const firstDayOfWeek = startOfWeek(firstDay, { weekStartsOn: 1 });

    // Difference in days between start day of the month and start day of view
    const daysToFirst = differenceInDays(firstDay, firstDayOfWeek);

    // How many days given month has
    const monthLength = getDaysInMonth(date);

    // Some months need only 28 days to display in view
    const shortView = monthLength === 28 && isSameDay(firstDay, firstDayOfWeek);

    // Some months need 42 days to display in view
    const longView = monthLength + daysToFirst > 35;

    const viewDays = longView ? 42 : 35;

    // How many days need to be added to last day of the month for the view to have last day on Sunday
    const daysToAdd = viewDays - (daysToFirst + monthLength);

    // Non-leap year February starting on Monday
    if (shortView) return [firstDay, lastDay];

    // Return monday as first day, and sunday as last
    return [firstDayOfWeek, addDays(lastDay, daysToAdd)];
}
