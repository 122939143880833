import { useCallback } from 'react';
import { getUserSetting } from 'src/api/src/shared/fetchUserSetting';
import { useAppDispatch } from 'src/store';
import { handleUserApiDataResponse } from 'src/store/src/general/user/userSlice';

export const useUpdateUserInfo = () => {
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        const response = await getUserSetting();

        // set user settings to store
        dispatch(handleUserApiDataResponse(response));
    }, [dispatch]);
};
