import * as actionTypes from './actionTypes';
const initialState = {
    notes: null
};
export default (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.SET_NOTES: {
            const notes = payload.data;
            return {
                ...state,
                notes: notes
            };
        }

        default:
            return state;
    }
};
