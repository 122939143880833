import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage, useUnMountBudget } from 'src/hooks';

const BudgetView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "VideoHelpView" */ 'src/components/budgetScreen/templates/budgetView/BudgetView'
        )
);

export const BudgetMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage();
    useUnMountBudget();
    return (
        <div>
            <React.Suspense fallback>
                <BudgetView />
            </React.Suspense>
        </div>
    );
};
