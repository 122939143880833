import React from 'react';
import { format } from 'date-fns';

import { Action, DatePickerState } from 'src/components/shared/atoms/datePicker/reducer';
import { GetDateFn } from 'src/components/shared/atoms/datePicker/DatePicker';
import { DateManager } from 'src/utils';

type TimeInputManagerProps = {
    dispatch: React.Dispatch<Action>;
    state: DatePickerState;
    value: string;
    getDate: GetDateFn;
};

export class TimeInputManager {
    dispatch: React.Dispatch<Action>;
    state: DatePickerState;
    value: string;
    getDate: GetDateFn;
    datePrevious: Date;
    constructor({ value, dispatch, state, getDate }: TimeInputManagerProps) {
        this.dispatch = dispatch;
        this.state = state;
        this.value = value;
        this.getDate = getDate;
        this.datePrevious = state.dateSelected ?? new Date();
    }

    handleInput() {
        if (this.value.length === 5) {
            if (this.validate()) {
                this.datePrevious = new Date(
                    new Date(this.datePrevious).setHours(
                        Number(this.value[0] + this.value[1]),
                        Number(this.value[3] + this.value[4])
                    )
                );
                this.dispatch({
                    type: 'SET_TIME',
                    payload: {
                        hoursSelected: this.value[0] + this.value[1],
                        minutesSelected: this.value[3] + this.value[4],
                        timeVisible: this.value,
                        datePanel: this.datePrevious,
                        dateSelected: this.datePrevious,
                        dateInputVisible: format(new Date(this.datePrevious), 'ddMMyyyy'),
                        isToday: DateManager.isToday(this.datePrevious)
                    }
                });
                this.getDate({
                    dateSelectedNormal: this.datePrevious,
                    dateSelectedFormatted: format(
                        new Date(this.datePrevious),
                        `dd${this.state.signDivider}MM${this.state.signDivider}yyyy`
                    ),
                    isToday: this.state.isToday
                });
                return;
            } else {
                this.dispatch({
                    type: 'OPEN_ERROR_TIME'
                });
            }
        }
        this.dispatch({
            type: 'SET_VISIBLE_TIME',
            payload: {
                ...this.state,
                timeVisible: this.value
            }
        });
    }

    validate() {
        let isValid = true;
        if (!(this.value[0] === '0' || this.value[0] === '1' || this.value[0] === '2')) {
            isValid = false;
        }
        if (
            !(
                this.value[3] === '0' ||
                this.value[3] === '1' ||
                this.value[3] === '2' ||
                this.value[3] === '3' ||
                this.value[3] === '4' ||
                this.value[3] === '5'
            )
        ) {
            isValid = false;
        }
        return isValid;
    }
}
