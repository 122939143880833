import React, { FC } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import styles from './UIFieldRelationshipType.module.css';
import { UIFieldRelationshipTypeProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { useFieldClasses } from 'src/hooks';
import { UiAutocompleteInputThree } from 'src/components/shared/atoms/uiInputs/uiAutocompleteInputThree/UiAutocompleteInputThree';

export const UIFieldRelationshipType: FC<UIFieldRelationshipTypeProps> = (props) => {
    const {
        onInputChangeAutocomplete,
        onBlur,
        inputValueAutocomplete,
        onChangeAutocomplete,
        onClearAutocomplete,
        optionsAutocomplete,
        opcjeSelect,
        value,
        onChangeSelect,
        uiFieldCommonProps,
        autocompleteFilterOptions,
        uiFieldSharedProps
    } = props;
    const { disabled } = uiFieldCommonProps;

    const classes = useFieldClasses({ muiType: 'autocompleteOne' });

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputSelectTwo
                    value={value.select}
                    opcje={opcjeSelect}
                    onChange={onChangeSelect}
                />
                <Autocomplete
                    sx={{
                        paddingLeft: '10px'
                    }}
                    freeSolo
                    onInputChange={onInputChangeAutocomplete}
                    onBlur={(e) => onBlur(e)}
                    options={optionsAutocomplete || null}
                    value={{
                        id: value.value,
                        text: inputValueAutocomplete
                    }}
                    inputValue={inputValueAutocomplete}
                    getOptionLabel={(option: any) => {
                        return option?.text;
                    }}
                    // to set proper key in case of duplicated texts
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.text}
                            </li>
                        );
                    }}
                    filterOptions={autocompleteFilterOptions}
                    disablePortal={false}
                    onChange={onChangeAutocomplete}
                    clearIcon={
                        value.text ? (
                            <ButtonCross
                                onClick={onClearAutocomplete}
                                style={{
                                    top: '2px'
                                }}
                            />
                        ) : (
                            <></>
                        )
                    }
                    disabled={disabled}
                    classes={{
                        option: classes.option,
                        listbox: classes.listbox,
                        paper: classes.paper,
                        popper: classes.popper
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => {
                        return (
                            <UiAutocompleteInputThree
                                {...uiFieldCommonProps}
                                params={params}
                                inputProps={{
                                    ...params.inputProps,
                                    ...uiFieldSharedProps.inputProps
                                }}
                            />
                        );
                    }}
                />
            </div>
        </UiWrapper>
    );
};
