import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateSecond from 'src/components/shared/organisms/popupTemplateSecond/PopupTemplateSecond';
import { useAppDispatch } from 'src/store';
import { closePopup, setPopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Typography } from '@mui/material';
import { ApiActionBehavior } from 'src/data/types';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';

export const PopupContentInitialConfirmApi: FC<BasePopupContentProps> = ({ popup }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const behaviour = popup.action?.behaviour as ApiActionBehavior;
    const setNextConfirm = () => {
        dispatch(
            setPopup({
                type: 'confirmationApi',
                objectId: popup.objectId,
                action: popup.action,
                sourceOfTrigger: popup.sourceOfTrigger,
                objectAdditional: popup.objectAdditional
            })
        );
        dispatch(closePopup(popup.key));
    };
    return (
        <PopupTemplateSecond
            title={globalPopup.contentConfirmation.title}
            buttonLeftTxt={globalPopup.contentConfirmation.button.yes}
            buttonRightTxt={globalPopup.contentConfirmation.button.no}
            buttonRightOnClick={() => dispatch(closePopup(popup.key))}
            buttonLeftOnClick={setNextConfirm}>
            <Typography
                sx={{
                    color: theme.palette.primary.main
                }}>
                {behaviour?.data?.confirmText}
            </Typography>
        </PopupTemplateSecond>
    );
};
