import {
    PerrmisionScreenData,
    PermissionScreen,
    PermissionTab,
    PermissionSection
} from 'src/api/src/permissionsScreen/types';
import { PermissionFormState, PermissionItem, PermissionLevel, PermissionType } from './types';

export class InitialPermissionsFormStateCreator {
    static buildFormStateKey(
        functionId: string,
        item: PermissionItem,
        type: PermissionType,
        level: PermissionLevel
    ) {
        switch (level) {
            case 'screen':
                switch (type) {
                    case 'visible':
                        return `${functionId}_${item.id}_widok`;
                    case 'read':
                        return `${functionId}_${item.id}_odczyt`;
                    case 'adding':
                        return `${functionId}_${item.id}_dodawanie`;
                    case 'editing':
                        return `${functionId}_${item.id}_modyfikacja`;
                    case 'deleting':
                        return `${functionId}_${item.id}_usuwanie`;
                    case 'readOwn':
                        return `${functionId}_${item.id}_tylko_swoje`;
                    case 'readOwnDirect':
                        return `${functionId}_${item.id}_tylko_swoje_tylko_bezposrednie`;
                    case 'editingOwn':
                        return `${functionId}_${item.id}_modyfikacja_tylko_swoje`;
                    case 'deletingOwn':
                        return `${functionId}_${item.id}_usuwanie_tylko_swoje`;
                }
                break;
            case 'tab':
                switch (type) {
                    case 'visible':
                        return `zakladka_${functionId}_${item.id}`;
                }
                break;
            case 'section':
                switch (type) {
                    case 'visible':
                        return `sekcja_${functionId}_${item.id}`;
                }
                break;
            case 'field':
                switch (type) {
                    case 'visible':
                        return `${functionId}_${item.id}`;
                    case 'editing':
                        return `${functionId}_${item.id}_modyfikacja`;
                }
                break;
            case 'relation':
                switch (type) {
                    case 'visible':
                        return `${functionId}_powiazania_${item.id}`;
                    case 'adding':
                        return `${functionId}_powiazania_${item.id}_dodawanie`;
                    case 'readOwn':
                        return `${functionId}_powiazania_${item.id}_tylko_swoje`;
                }
                break;
            case 'option':
                switch (type) {
                    case 'visible':
                        return `${functionId}_opcja_${item.id}`;
                }
                break;
            default:
                break;
        }
        console.error('buildFormStateKey: Unknown level or type', level, type);
        return '';
    }

    static createInitialStates(data: PerrmisionScreenData): PermissionFormState {
        const formState: PermissionFormState = {};

        for (const func of data.functions) {
            for (const module of func.modules) {
                // Handle screens
                for (const screen of module.screens) {
                    this.addScreenPermissions(formState, func.id, screen);
                }
            }
        }

        return formState;
    }

    private static addScreenPermissions(
        formState: PermissionFormState,
        functionId: string,
        screen: PermissionScreen
    ) {
        const screenId = screen.id;

        formState[`${functionId}_${screenId}_widok`] = screen.visible;

        // For PermissionScreenBasic, add all the extra fields and handle tabs
        if (screen.isBasicScreen) {
            let type: PermissionType;
            for (type of [
                'read',
                'adding',
                'editing',
                'deleting',
                'readOwn',
                'readOwnDirect',
                'editingOwn',
                'deletingOwn'
            ] as PermissionType[]) {
                formState[
                    InitialPermissionsFormStateCreator.buildFormStateKey(
                        functionId,
                        screen,
                        type,
                        'screen'
                    )
                ] = screen[type];
            }

            for (const tab of screen.tabs) {
                this.addTabPermissions(formState, functionId, tab);
            }
        }

        // Handle options in the screen
        for (const option of screen.options) {
            formState[
                InitialPermissionsFormStateCreator.buildFormStateKey(
                    functionId,
                    option,
                    'visible',
                    'option'
                )
            ] = option.visible;
        }
    }

    private static addTabPermissions(
        formState: { [key: string]: boolean },
        functionId: string,
        tab: PermissionTab
    ) {
        formState[
            InitialPermissionsFormStateCreator.buildFormStateKey(functionId, tab, 'visible', 'tab')
        ] = tab.visible;

        // Handle sections in the tab
        for (const section of tab.sections) {
            this.addSectionPermissions(formState, functionId, section);
        }
    }

    private static addSectionPermissions(
        formState: { [key: string]: boolean },
        functionId: string,
        section: PermissionSection
    ) {
        formState[
            InitialPermissionsFormStateCreator.buildFormStateKey(
                functionId,
                section,
                'visible',
                'section'
            )
        ] = section.visible;

        // Handle fields in the section
        for (const field of section.fields) {
            formState[
                InitialPermissionsFormStateCreator.buildFormStateKey(
                    functionId,
                    field,
                    'visible',
                    'field'
                )
            ] = field.visible;
            formState[
                InitialPermissionsFormStateCreator.buildFormStateKey(
                    functionId,
                    field,
                    'editing',
                    'field'
                )
            ] = field.editing;
        }

        // Handle relations in the section
        for (const relation of section.relations) {
            formState[
                InitialPermissionsFormStateCreator.buildFormStateKey(
                    functionId,
                    relation,
                    'visible',
                    'relation'
                )
            ] = relation.visible;
            formState[
                InitialPermissionsFormStateCreator.buildFormStateKey(
                    functionId,
                    relation,
                    'adding',
                    'relation'
                )
            ] = relation.adding;
            formState[
                InitialPermissionsFormStateCreator.buildFormStateKey(
                    functionId,
                    relation,
                    'readOwn',
                    'relation'
                )
            ] = relation.readOwn;
        }
    }
}
