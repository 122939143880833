import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import styles from './UiWrapperField.module.css';

type Props = {
    sx?: SxProps<Theme>;
    width?: number;
};

// definiuje % szerokość inputów (całego bloku np. przypadku datepickera) biorąc parametr z width z api - uwaga jesli sie doda width w sx to zostanie nadpisany
// define % width inputs (whole block example datepicker) gets parametr from api - attention if pass in sx property width you overwrite api prop

export const UiWrapperField: FC<Props> = ({ children, sx, width = 1 }) => {
    return (
        <Box
            sx={{
                width: `${width * 100}%`,
                ...sx
            }}
            className={styles.wrapper}>
            {children}
        </Box>
    );
};
