import React, { FC } from 'react';

import { UIFieldTextWithPrefixProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import styles from './UiFieldTextWithPrefix.module.css';

export const UIFieldTextWithPrefix: FC<UIFieldTextWithPrefixProps> = (props) => {
    const { value, onChange, onBlur, uiFieldCommonProps, uiFieldSharedProps } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.inputsBox}>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.prefix}
                    readonly="readonly"
                    onChange={() => {}}
                    onBlur={() => {}}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                <div className={styles.textInputBox}>
                    <UiInputNormalTwo
                        {...uiFieldCommonProps}
                        value={value.text}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                </div>
            </div>
        </UiWrapper>
    );
};
