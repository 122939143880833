import React, { useEffect, useState } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';
import PermissionsView from '../../templates/permissionsView/PermissionsView';
import { useNavigate } from 'react-router-dom';
import { getPermissionsScreenData } from 'src/api/src/permissionsScreen/getPermissionsScreenData';
import { PerrmisionScreenData } from 'src/api/src/permissionsScreen/types';
import { LoaderWewTwo } from 'src/components/shared/organisms/loader/LoaderWewTwo';

const PermissionsScreen = () => {
    useRunAllHooksOnEachRenderReactPage('Uprawnienia');

    const [permissionScreenState, setPermissionScreenState] = useState<PerrmisionScreenData | null>(
        null
    );
    const navigate = useNavigate();

    // load data for screen
    useEffect(() => {
        const loadData = async () => {
            const res = await getPermissionsScreenData();
            if (!res.success) {
                navigate(`/message?tytul=${res.message}`, { replace: true });
                return;
            }
            setPermissionScreenState(res.data);
        };
        loadData();
    }, []);

    return !permissionScreenState ? (
        <LoaderWewTwo />
    ) : (
        <PermissionsView permissionScreenState={permissionScreenState} />
    );
};

export default PermissionsScreen;
