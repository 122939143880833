import React, { FC, useMemo } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldObjectNumber } from './UIFieldObjectNumber';
import { FieldObjectNumberObjectProps } from './types';

export const FieldObjectNumberObject: FC<FieldObjectNumberObjectProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const {
        prefix,
        interfix,
        suffix,
        rok,
        miesiac,
        rok_przed_miesiacem,
        rok_przed_numerem,
        miesiac_przed_numerem,
        interfix_po_miesiacu,
        interfix_po_roku,
        rok_dwie_cyfry,
        domyslna_wartosc_rok,
        domyslna_wartosc_miesiac,
        domyslna_wartosc
    } = dynamicFieldT;

    const [state, setState] = React.useState(field.value);

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        key: 'prefix' | 'suffix' | 'interfix',
        onBlur: boolean
    ) => {
        const stateCopy = { ...state };
        stateCopy[key] = e.target.value;
        setState(stateCopy);

        if (onBlur) {
            const fieldCopy = { ...field };
            fieldCopy.value[key] = e.target.value;

            dispatch({
                type: 'numer',
                payload: {
                    code: keyOfFieldInState,
                    value: fieldCopy
                }
            });
        }
    };

    const safeConcatString = (root: string, newPart: string | number | undefined) => {
        return newPart !== undefined ? root + newPart : root;
    };

    const textBeforeInterfix = useMemo(() => {
        let text = prefix === 't' ? '/' : '';
        if (
            rok === 't' &&
            rok_przed_miesiacem !== 't' &&
            rok_przed_numerem === 't' &&
            miesiac_przed_numerem !== 't'
        ) {
            text =
                safeConcatString(
                    text,
                    rok_dwie_cyfry === 't'
                        ? parseInt(domyslna_wartosc_rok ?? '') % 100
                        : domyslna_wartosc_rok
                ) + '/';
        }
        if (miesiac_przed_numerem !== 't') {
            text = safeConcatString(text, domyslna_wartosc);
        }
        if (rok === 't' && rok_przed_miesiacem === 't' && rok_przed_numerem === 't') {
            if (miesiac_przed_numerem !== 't') {
                text += '/';
            }
            text = safeConcatString(
                text,
                rok_dwie_cyfry === 't'
                    ? parseInt(domyslna_wartosc_rok ?? '') % 100
                    : domyslna_wartosc_rok
            );
        }
        if (interfix_po_miesiacu === 't') {
            if (miesiac === 't') {
                text += '/';
                text = safeConcatString(text, domyslna_wartosc_miesiac);
            }
            if (interfix === 't') {
                text += '/';
            }
            return text;
        }
        if (interfix_po_roku !== 't') {
            if (interfix === 't') {
                text += '/';
            }
            return text;
        }
        if (miesiac === 't') {
            text += '/';
            text = safeConcatString(text, domyslna_wartosc_miesiac);
        }
        if (
            rok === 't' &&
            rok_przed_miesiacem !== 't' &&
            rok_przed_numerem === 't' &&
            miesiac_przed_numerem === 't'
        ) {
            text += '/';
            text = safeConcatString(
                text,
                rok_dwie_cyfry === 't'
                    ? parseInt(domyslna_wartosc_rok ?? '') % 100
                    : domyslna_wartosc_rok
            );
        }
        if (miesiac_przed_numerem === 't') {
            text += '/';
            text = safeConcatString(text, domyslna_wartosc);
        }
        if (rok === 't' && rok_przed_miesiacem !== 't' && rok_przed_numerem !== 't') {
            text += '/';
            text = safeConcatString(
                text,
                rok_dwie_cyfry === 't'
                    ? parseInt(domyslna_wartosc_rok ?? '') % 100
                    : domyslna_wartosc_rok
            );
        }
        // at old code was previous if, but when interfix_po_roku !== 'f' code return value at upper if
        // if (interfix_po_roku === 't')
        if (interfix === 't') text += '/';

        return text;
    }, [dynamicFieldT]);

    const textAfterInterfix = useMemo(() => {
        let text = '';
        if (interfix_po_roku === 't') {
            if (suffix === 't') text += '/';
            return text;
        }
        if (interfix_po_miesiacu !== 't') {
            if (miesiac === 't') {
                text += '/';
                text = safeConcatString(text, domyslna_wartosc_miesiac);
            }
        }

        if (
            rok === 't' &&
            rok_przed_miesiacem !== 't' &&
            rok_przed_numerem === 't' &&
            miesiac_przed_numerem === 't'
        ) {
            text += '/';
            text = safeConcatString(
                text,
                rok_dwie_cyfry === 't'
                    ? parseInt(domyslna_wartosc_rok ?? '') % 100
                    : domyslna_wartosc_rok
            );
        }
        if (miesiac_przed_numerem === 't') {
            text += '/';
            text = safeConcatString(text, domyslna_wartosc);
        }
        if (rok === 't' && rok_przed_miesiacem !== 't' && rok_przed_numerem !== 't') {
            text += '/';
            text = safeConcatString(
                text,
                rok_dwie_cyfry === 't'
                    ? parseInt(domyslna_wartosc_rok ?? '') % 100
                    : domyslna_wartosc_rok
            );
        }

        if (suffix === 't') text += '/';

        return text;
    }, [dynamicFieldT]);

    return (
        <UIFieldObjectNumber
            value={state}
            onChangePrefix={(e) => onChange(e, 'prefix', false)}
            onBlurPrefix={(e) => onChange(e, 'prefix', true)}
            onChangeInterfix={(e) => onChange(e, 'interfix', false)}
            onBlurInterfix={(e) => onChange(e, 'interfix', true)}
            onChangeSuffix={(e) => onChange(e, 'suffix', false)}
            onBlurSuffix={(e) => onChange(e, 'suffix', true)}
            showPrefix={dynamicFieldT.prefix === 't'}
            showInterfix={dynamicFieldT.interfix === 't'}
            showSuffix={dynamicFieldT.suffix === 't'}
            prefixOptions={dynamicFieldT.opcje_prefix ?? {}}
            interfixOptions={dynamicFieldT.opcje_interfix ?? {}}
            suffixOptions={dynamicFieldT.opcje_suffix ?? {}}
            textAfterInterFix={textAfterInterfix}
            textBeforeInterFix={textBeforeInterfix}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT
            }}
        />
    );
};
