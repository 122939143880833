import React, { FC } from 'react';

import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const SendingDocScreenView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "SendingDocScreenView" */ 'src/components/sendingDocScreen/templates/sendingDocView/SendingDocView'
        )
);

export const SendingDocMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Wysyłka dokumentów');
    return (
        <div>
            <React.Suspense fallback>
                <SendingDocScreenView />
            </React.Suspense>
        </div>
    );
};
