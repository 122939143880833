import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ListScreenLocationState } from 'src/components/listScreen/pages/objectList/types';
import { getState, useAppDispatch, useTypedSelector } from 'src/store';
import { updateObjectsData } from 'src/store/src/listScreen';
import { updateStatusTableData, updateTreeData } from 'src/store/src/listScreen/sharedActions';
import {
    ListPagingData,
    ListSearchFilters,
    ListSortProperty
} from 'src/store/src/listScreen/transformations/types';
import { makeListScreenLocationData, savePageLocationState } from 'src/utils';
import { useSafeNavigate } from '../shared/useSafeNavigate';
import { useSearchParam } from '../shared/useSearchParam';
import { setSpinner } from 'src/store/src/general/system/systemSlice';

/**
 * update store state with given parameters: paging, filters etc. and fetch and set to store new
 * data from sever about objects for table, because every params change should change objects data.
 * Also save change in navigation history
 */

type UseUpdateObjectsDataPros = {
    saveLocationState?: boolean;
    paging?: ListPagingData;
    filters?: ListSearchFilters;
    sortingData?: ListSortProperty[];
    view?: 'standard' | 'statusTable' | 'tree' | 'all';
    checkViewInHook?: boolean;
    forceRefreshData?: boolean;
    resetPage?: boolean;
};

export const useUpdateObjectsData = () => {
    // collect hooks
    const dispatch = useAppDispatch();
    const screen = useSearchParam('obiekt');
    const navigate = useSafeNavigate();
    const storeState = getState();
    const location = useLocation();
    const listPagingData = useTypedSelector((state) => state.listTransformations.paging);
    // make callback updating objects data
    return useCallback(
        async ({
            saveLocationState = true,
            paging,
            filters,
            sortingData,
            view,
            checkViewInHook,
            forceRefreshData = false,
            resetPage = false
        }: UseUpdateObjectsDataPros) => {
            if (!screen) {
                throw new Error(
                    'updateObjectsData: Try to update objects without screen("obiekt") search param'
                );
            }
            dispatch(setSpinner(true));
            if (resetPage === true) {
                paging = { ...listPagingData, actualPage: 1 };
            }
            let viewUpdated = view;
            if (!viewUpdated || checkViewInHook) {
                if (location.pathname.includes('/lista-drzewo')) {
                    viewUpdated = 'tree';
                } else if (location.pathname.includes('/lista-statusy')) {
                    viewUpdated = 'statusTable';
                } else if (location.pathname.includes('/lista')) {
                    viewUpdated = 'standard';
                }
            }

            // update data in store for all views if this is not specified, or for one of them
            if (viewUpdated === 'all' || viewUpdated === 'standard') {
                dispatch(updateObjectsData(screen, paging, filters, sortingData, forceRefreshData));
            }
            if (viewUpdated === 'all' || viewUpdated === 'statusTable') {
                dispatch(updateStatusTableData(screen, paging, filters, sortingData));
            }

            if (viewUpdated === 'all' || viewUpdated === 'tree') {
                dispatch(updateTreeData(screen, paging, filters, sortingData));
            }

            // save specified state values in navigation
            if (!saveLocationState) return;

            const locationData: ListScreenLocationState = makeListScreenLocationData(
                paging,
                filters,
                sortingData,
                storeState.listTransformations
            );

            savePageLocationState(locationData, navigate, location.pathname);
        },
        [navigate, screen, dispatch, storeState, location, listPagingData]
    );
};
