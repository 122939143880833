import React from 'react';
import { dispatch } from 'src/store';
import { fetchAndLoadInitialMessageScreenData } from 'src/store/src/messageScreen/sharedAcions';

export const useLoadInitialMessageScreenData = () => {
    React.useEffect(() => {
        const loadData = async () => {
            await dispatch(fetchAndLoadInitialMessageScreenData());
        };
        loadData();
    }, []);
};
