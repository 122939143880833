import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiTagData } from './types';

export async function fetchTagInitData(obiekt: string): Promise<BaseApiResponse<ApiTagData>> {
    const params = new URLSearchParams([['obiekt', obiekt]]);
    return simpleQueryAPIData(
        `api_react/src/tagObjectScreen/getInitialDataTagObject.php?${params.toString()}`,
        'GET'
    );
}
