import React, { FC } from 'react';
import { UtilityBarOptionUiProps } from './types';
import GrowWrapper from 'src/components/shared/atoms/growWrapper/GrowWrapper';
import styles from './styles.module.css';

export const UtilityBarOptionUi: FC<UtilityBarOptionUiProps> = ({ name, children }) => {
    return (
        <div className={styles.wrapper}>
            <GrowWrapper>
                <div className={styles.wrapperInner}>{children}</div>
                <p className={` ${styles.text}`}>{name}</p>
            </GrowWrapper>
        </div>
    );
};
