import React, { FC, useMemo } from 'react';

import { FieldAddExtendProps } from './types';
import { Field } from 'src/components/popupFormExtended/field/Field';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { usePopupState } from 'src/context/globalPopup/popupContext';
import { UiWrapper } from '../shared/uiWrapper/UiWrapper';
import { UiFieldLabel } from '../shared/uiFieldLabel/UiFieldLabel';
import style from './FieldAddExtend.module.css';
import { Button } from '@mui/material';
import { RelatedObjectDataManager } from 'src/components/popupFormExtended/relationCreator/RelatedObjectDataManager';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { UiFrameDash } from 'src/components/popupFormExtended/dynamicField/shared/uiFrameDash/UiFrameDash';
import { DynamicFormObjectGlobalValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/DynamicFormObjectGlobalValidator';
import { UiFieldCommonProps } from '../types';
import { FormContext } from '../../dynamicFormObject/DynamicFormContextProvider';

export const FieldAddExtend: FC<FieldAddExtendProps> = ({
    usedForAddingObject,
    uiFieldCommonProps,
    dispatch,
    keyPopup,
    dynamicFieldT,
    keyOfFieldInState
}) => {
    const {
        popupStateContext,
        popupStateRedux,
        dispatchPopupStateContext,
        dispatchPopupStateRedux
    } = usePopupState(keyPopup);
    const { formState } = React.useContext(FormContext);
    const maxWidth = uiFieldCommonProps?.maxWidth ? uiFieldCommonProps?.maxWidth : 0;

    const childrenUiFieldCommonProps: UiFieldCommonProps = useMemo(
        () => ({
            isLabel: true,
            usedForAddingObject: usedForAddingObject,
            maxWidth: maxWidth
        }),
        [usedForAddingObject, maxWidth]
    );

    const popupFormExtendedData = popupStateRedux.popupFormExtendedData;

    const onClick = async () => {
        if (popupFormExtendedData === null) {
            console.log('DynamicFormObject: submit, popupFormExtendedData is null');
            return;
        }
        const validationResult = await new DynamicFormObjectGlobalValidator({
            formState,
            dispatchFormState: dispatch,
            dispatchPopupStateRedux,
            dispatchPopupStateContext,
            popupFormExtendedData,
            popupStateContext,
            popupStateRedux,
            submitMode: 0,
            placementRun: 'fieldAddExtend',
            keyOfFieldInState
        }).execute();

        if (!validationResult.isFormValid) return;

        const { relatedObjectData, relation } = new RelatedObjectDataManager({
            formState,
            activeTabId: popupStateContext.activeTab.id,
            targetScreenCode: dynamicFieldT.powiazanie?.targetScreenCode
                ? dynamicFieldT.powiazanie?.targetScreenCode
                : ''
        }).getData();
        const relationNew = {
            ...relation,
            newObjectsData: [...relation.newObjectsData, relatedObjectData]
        };
        const updatedState = new DynamicFormObjectStateHandler({
            relations: [relationNew],
            state: formState,
            kod: dynamicFieldT.kod,
            prefixFieldKey: relatedObjectData.prefixKey,
            targetScreenCode: dynamicFieldT.powiazanie?.targetScreenCode
                ? dynamicFieldT.powiazanie?.targetScreenCode
                : ''
        }).handleState('add_new_relation_item_with_values');

        dispatch({
            type: 'set_state',
            payload: updatedState
        });
    };

    if (dynamicFieldT?.powiazanie) {
        return (
            <UiWrapper>
                <UiFieldLabel
                    isLabel={true}
                    name={dynamicFieldT.nazwa}
                    dynamicFieldT={dynamicFieldT}
                />
                <UiFrameDash>
                    {dynamicFieldT?.powiazanie.fields?.map((field: DynamicFieldT) => (
                        <Field
                            key={field.id}
                            keyPopup={keyPopup}
                            dynamicFieldT={field}
                            keyOfFieldInState={dynamicFieldT.kod + '-' + field.kod}
                            usedForAddingObject={usedForAddingObject}
                            uiFieldCommonProps={childrenUiFieldCommonProps}
                        />
                    ))}
                    <div className={style.buttonContainer}>
                        <Button
                            variant="contained"
                            sx={{
                                color: (theme) => theme.palette.background.default,
                                padding: '6px 20px',
                                cursor: 'pointer'
                            }}
                            onClick={onClick}>
                            <p className={`${style.button}`}>Dodaj</p>
                        </Button>
                    </div>
                </UiFrameDash>
            </UiWrapper>
        );
    }
    return null;
};
