import {
    FieldDateBasicType,
    FieldDateHourBasicType,
    FieldFileBasicType,
    FieldListBasicType,
    FieldListForeignBasicType,
    FieldRelationshipTypeBasicType,
    FieldRelationshipTypeManyBasicType,
    FieldTextBasicType
} from 'src/data/fieldsFormType';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MassEditObjectsFieldNumber, MassEditObjectsRow, MassEditObjectsState } from './types';
import {
    ApiMassEditObjectsData,
    MassEditObjectItem
} from 'src/api/src/massEditObjectsScreen/types';
import { massEditObjectsColumnFieldInit } from 'src/constants/massEditObjectsScreen/massEditObjectsColumnField';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { DateManager } from 'src/utils';

export const initialState: MassEditObjectsState = {
    columnField: [...massEditObjectsColumnFieldInit],
    row: [],
    isLoading: true,
    params: { obiekt: '', obiekt_id: '' }
};

const massEditObjectsSlice = createSlice({
    name: 'massEditObjects',
    initialState,
    reducers: {
        setInitState: (
            state: MassEditObjectsState,
            action: PayloadAction<ApiMassEditObjectsData>
        ) => {
            state.columnField = [
                ...massEditObjectsColumnFieldInit,
                ...createCode(action.payload.columnField)
            ];
            state.row = createInit(action.payload);
            state.isLoading = false;
            state.params = action.payload.params;
        },
        clearState: (state: MassEditObjectsState) => {
            state.columnField = [...massEditObjectsColumnFieldInit];
            state.row = [];
            state.isLoading = true;
            state.params = { obiekt: '', obiekt_id: '' };
        }
    }
});

export const { setInitState, clearState } = massEditObjectsSlice.actions;

export default massEditObjectsSlice.reducer;

function createCode(dynamicFieldTs: DynamicFieldT[]): DynamicFieldT[] {
    return dynamicFieldTs.map((dynamicFieldT) => {
        if (dynamicFieldT.obce === '1') {
            return {
                ...dynamicFieldT,
                code: `${dynamicFieldT.tabela}_${dynamicFieldT.kod}`
            };
        } else {
            return {
                ...dynamicFieldT,
                code: `${dynamicFieldT.kod}`
            };
        }
    });
}

function createInit(data: ApiMassEditObjectsData) {
    const rows: MassEditObjectsRow[] = [];

    data?.object?.forEach((objectItem, i) => {
        const row: MassEditObjectsRow = {
            id: objectItem.id,
            i: i,
            locked: false,
            mod_checked: false,
            field: {},
            fieldApiValues: {
                ...objectItem
            }
        };
        row['locked'] = Boolean(objectItem.locked);
        row['mod_checked'] = Boolean(objectItem.mod_check);

        data.columnField.forEach((columnFieldItem) => {
            const field = setInitValue({ columnFieldItem, massEditObjectItem: objectItem });
            if (field) {
                row.field[columnFieldItem.kod] = field;
            }
        });
        rows.push(row);
    });
    return rows;
}

function setInitValue({
    columnFieldItem,
    massEditObjectItem
}: {
    columnFieldItem: DynamicFieldT;
    massEditObjectItem: MassEditObjectItem;
}) {
    switch (columnFieldItem.typ) {
        case 'lista': {
            const value = massEditObjectItem[columnFieldItem.code + '_id'];
            return {
                typ: 'lista',
                value: value,
                opcje: columnFieldItem.opcje
            } as FieldListBasicType;
        }
        case 'numer': {
            const value = massEditObjectItem[columnFieldItem.code];
            return {
                typ: 'numer',
                value: value
            } as MassEditObjectsFieldNumber;
        }
        case 'tekst': {
            const value = massEditObjectItem[columnFieldItem.code];
            return {
                typ: 'tekst',
                value: value
            } as FieldTextBasicType;
        }
        case 'data_godzina': {
            const date = DateManager.prepareFromVariousTypeOfDateToNewDate(
                massEditObjectItem[columnFieldItem.code + '_data']
            );
            const value = massEditObjectItem[columnFieldItem.code + '_godzina']?.split(':');
            return {
                typ: 'data_godzina',
                value: {
                    date: date,
                    hour: value?.[0],
                    minute: value?.[1]
                }
            } as FieldDateHourBasicType;
        }
        case 'data': {
            const date = DateManager.prepareFromVariousTypeOfDateToNewDate(
                massEditObjectItem[columnFieldItem.code]
            );

            return {
                typ: 'data',
                value: date
            } as FieldDateBasicType;
        }

        case 'lista_obca': {
            const value = massEditObjectItem[columnFieldItem.code + '_id'];
            const text = massEditObjectItem[columnFieldItem.code];
            return {
                typ: 'lista_obca',
                value: {
                    value: value,
                    text: text,
                    opcje: columnFieldItem.opcje
                }
            } as FieldListForeignBasicType;
        }

        case 'powiazanie_typ': {
            const value = massEditObjectItem[columnFieldItem.code + '_id'];
            const text = massEditObjectItem[columnFieldItem.code + '_nazwa'];
            const select = massEditObjectItem[columnFieldItem.code + '_tab'];
            return {
                typ: 'powiazanie_typ',
                value: {
                    value: value,
                    text: text,
                    select: select,
                    type: select
                }
            } as FieldRelationshipTypeBasicType;
        }

        case 'powiazanie_typ_wielokrotne': {
            const value = massEditObjectItem[columnFieldItem.code];
            return {
                typ: 'powiazanie_typ_wielokrotne',
                value: {
                    select: '',
                    objects: value?.elementy?.map((item: any) => {
                        return {
                            id: item?.id,
                            typ: item?.typ,
                            nazwa: item?.nazwa,
                            typ_nazwa: item?.nazwa_tabeli
                        };
                    }),
                    elements: value?.elementy
                }
            } as FieldRelationshipTypeManyBasicType;
        }
        case 'plik': {
            const id = massEditObjectItem[columnFieldItem.code];
            const name = massEditObjectItem[columnFieldItem.code + '_nazwa'];
            return {
                typ: 'plik',
                value: {
                    id: id,
                    name: name,
                    loading: false,
                    originalFileName: name
                }
            } as FieldFileBasicType;
        }
        default:
            break;
    }
}
