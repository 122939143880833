import React, { FC } from 'react';
import { SearchOptionDeleteIndicatorProps } from './types';
import styles from './SearchOptionDeleteIndicator.module.css';
const SearchOptionDeleteIndicator: FC<SearchOptionDeleteIndicatorProps> = ({
    deleteSearchOptionCallback,
    action
}) => {
    return (
        <div
            onClick={(e) => deleteSearchOptionCallback(e, action.behaviour.data.id)}
            className={styles.close}></div>
    );
};

export default SearchOptionDeleteIndicator;
