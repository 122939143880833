import React, { FC } from 'react';
import { PermissionRelationsLevelProps } from './types';
import PermissionsField from '../../molecules/permissionsField/PermissionsField';
import PermissionsLevelHeader from '../../molecules/permissionsLevelHeader/PermissionsLevelHeader';
import PermissionsItemHeader from '../../molecules/permissionsItemHeader/PermissionsItemHeader';
import { PERIMISSION_LEVEL_INDENT } from 'src/constants/permissionsScreen';

const PermissionRelationsLevel: FC<PermissionRelationsLevelProps> = ({
    relations,
    sectionId,
    unfoldedState,
    toggleFolding,
    ...props
}) => {
    const foldedKey = `relations_${sectionId}`;
    return (
        <div style={{ marginLeft: PERIMISSION_LEVEL_INDENT }}>
            <PermissionsLevelHeader
                folded={unfoldedState[foldedKey]}
                onClick={() => toggleFolding(foldedKey)}
                title="Powiązania"
            />
            {unfoldedState[foldedKey] &&
                relations.map((relation) => (
                    <ul key={relation.id}>
                        <PermissionsItemHeader text={relation.name} />
                        {relation.canChangeVisible && (
                            <PermissionsField
                                {...props}
                                item={relation}
                                type="visible"
                                level="relation"
                            />
                        )}
                        {relation.canChangeAdding && (
                            <PermissionsField
                                {...props}
                                item={relation}
                                type="adding"
                                level="relation"
                            />
                        )}
                        <PermissionsField
                            {...props}
                            item={relation}
                            type="readOwn"
                            level="relation"
                        />
                    </ul>
                ))}
        </div>
    );
};

export default PermissionRelationsLevel;
