import { sendMessageData } from 'src/api/src/messageScreen/sendMessage';
import { SystemAction } from 'src/data/types';
import { dispatch, useTypedSelector } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { MessageDataApiCreator } from 'src/store/src/messageScreen/message/MessageDataApiCreator';
import { setPopup, setPopupInfoTwo } from 'src/store/src/popup';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { generateToken } from 'src/utils';
import { EventBus } from 'src/utils/src/shared/EventBus';

export const usePrepareDataForApiMessageScreen = (action: SystemAction) => {
    const messageRowHidden = useTypedSelector((state) => state.messageSlice.rowHidden);
    const messageRow = useTypedSelector((state) => state.messageSlice.row);

    const prepareDataForApiMessageScreen = async () => {
        const data = new MessageDataApiCreator({
            messageRowHidden,
            messageRow
        }).createDataForMainRequest();

        if (data.validationResult.isValid) {
            dispatch(setSpinner(true));
            const resp = await sendMessageData(data.messageDataRequest.body);

            dispatch(setSpinner(false));
            if (resp.success) {
                const closeCallbackEventId = `messege-sending-${generateToken()}`;
                EventBus.$on(closeCallbackEventId, () => {
                    EventBus.$off(closeCallbackEventId);
                    window.history.back();
                });
                dispatch(
                    setPopup({
                        type: 'info',
                        message: resp.message,
                        title: 'Informacja',
                        messageInjectHtml: true,
                        closeCallbackEventId: closeCallbackEventId
                    })
                );
            } else {
                new PopupManager({ dispatch: dispatch, action: action }).setPopupInfo(
                    resp.message ? resp.message : 'Nie wykonano'
                );
            }
        } else {
            dispatch(
                setPopupInfoTwo({
                    message: data.validationResult.message,
                    title: 'Błąd'
                })
            );
        }
    };
    return { prepareDataForApiMessageScreen };
};
