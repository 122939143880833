import { ApiSaveConfigData } from 'src/api/src/configurationScreen/types';
import { ConfigState } from 'src/store/src/configScreen/config/types';

export function createDateApiForActionSaveConfigScreen({
    configState
}: {
    configState: ConfigState;
}): ApiSaveConfigData {
    const data: ApiSaveConfigData = {};

    configState.column.forEach((item) => {
        if (item.id !== 'x') {
            data[item.id] = {};
            configState.row.forEach((row) => {
                const field = item.fields[row.code];
                if (field) {
                    data[item.id][row.code] = field.value;
                }
            });
        }
    });
    return data;
}
