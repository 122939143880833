import React, { FC } from 'react';

import styles from './ButtonCross.module.css';
import { ButtonCrossProps } from './types';

const ButtonCross: FC<ButtonCrossProps> = ({ style, onClick }) => {
    return (
        <div
            className={`${styles.wrapper}`}
            style={{
                ...style
            }}
            onClick={onClick}>
            <div className={`${styles.close}`}></div>
        </div>
    );
};

export default ButtonCross;
