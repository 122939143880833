import { FormActionKind } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import { DependentFunctionDynamicType } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/types';

// 'powiazanie_typ' like in 'onchange_typ'
export const availableFunctionToCallInFieldNested: {
    [key in FormActionKind]?: DependentFunctionDynamicType[];
} = {
    cena_liczba: ['przeliczWartosc'],
    cena: ['przeliczWartosc'],
    checkbox: ['przeliczWartosc', 'pokazPole', 'zmienWartoscPola'],
    data_godzina: ['przeliczWartosc'],
    data_godziny: ['przeliczWartosc'],
    data: ['przeliczWartosc'],
    data_wzgledna: ['przeliczWartosc'],
    koszty: ['przeliczWartosc'],
    liczba: ['przeliczWartosc'],
    lista_obca: ['zmienPole', 'pokazPole', 'zmienWartosciPolZNadrzednegoModTextForm'],
    lista: [
        'zmienPole',
        'pokazPole',
        'zmienWartoscPola',
        'przeliczWartosc',
        'zmienWartosciPolZNadrzednegoModTextForm'
    ],
    lista_obca_wielokrotna: ['przeliczWartosc'],
    lista_zalezna: [
        'zmienPole',
        'pokazPole',
        'zmienWartoscPola',
        'przeliczWartosc',
        'zmienWartosciPolZNadrzednegoModTextForm'
    ],
    powiazanie_typ_wielokrotne: ['zmienPole'],
    powiazanie_typ: ['zmienPole', 'pokazPole', 'zmienWartoscPola']
};

// 'powiazanie_typ' like in function 'go{/literal}{$prefix|cat:$item.kod}{literal}'
export const availableFunctionToCallInField: {
    [key in FormActionKind]?: DependentFunctionDynamicType[];
} = {
    cena_liczba: ['przeliczWartosc'],
    cena: ['przeliczWartosc'],
    checkbox: ['przeliczWartosc', 'pokazPole', 'zmienWartoscPola'],
    data_godzina: ['przeliczWartosc'],
    data_godziny: ['przeliczWartosc'],
    data: ['przeliczWartosc'],
    data_wzgledna: ['przeliczWartosc'],
    koszty: ['przeliczWartosc'],
    liczba: ['przeliczWartosc'],
    lista_obca: ['zmienPole', 'pokazPole', 'zmienWartosciPolZNadrzednegoModTextForm'],
    lista: ['zmienPole', 'pokazPole', 'zmienWartoscPola', 'przeliczWartosc'],
    lista_obca_wielokrotna: ['przeliczWartosc'],
    lista_zalezna: ['zmienPole', 'pokazPole', 'zmienWartoscPola', 'przeliczWartosc'],
    powiazanie_typ_wielokrotne: ['zmienPole', 'zmienWartosciPolZNadrzednegoModTextForm'],
    powiazanie_typ: ['zmienPole', 'zmienWartosciPolZNadrzednegoModTextForm'],
    // in order to simulate 'WpiszNazwePliku' system
    plik: ['zmienWartosciPolZNadrzednegoModTextForm']
};
