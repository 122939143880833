import React, { FC } from 'react';

import { CreatingMainProps } from 'src/components/creatingScreen/pages/creatingMain/types';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const CreatingScreenView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "CreatingScreenView" */ 'src/components/creatingScreen/templates/creatingView/CreatingView'
        )
);

export const CreatingMain: FC<CreatingMainProps> = (props) => {
    useRunAllHooksOnEachRenderReactPage('Tworzenie paczki/zbioru');

    return (
        <div>
            <React.Suspense fallback>
                <CreatingScreenView {...props} />
            </React.Suspense>
        </div>
    );
};
