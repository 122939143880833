import React, { FC } from 'react';

import styles from './Relation.module.css';
import { RelationProps } from 'src/components/popupFormExtended/relation/types';
import Belt from 'src/components/popupFormExtended/belt/Belt';
import { FieldsRelation } from 'src/components/popupFormExtended/fieldsRelation/FieldsRelation';
import { HeadRelation } from 'src/components/popupFormExtended/headRelation/HeadRelation';
import { RelationCreator } from 'src/components/popupFormExtended/relationCreator/RelationCreator';
import { SumsRelation } from 'src/components/popupFormExtended/sumsRelation/SumsRelation';

export const Relation: FC<RelationProps> = (props) => {
    const { relation } = props;

    if (!Array.isArray(relation.fields)) return <></>;
    return (
        <Belt index={1} name={relation.name}>
            <div className={styles.box}>
                <table
                    style={{
                        width:
                            relation.objectsData.length || relation.newObjectsData.length
                                ? 'auto'
                                : '100%'
                    }}>
                    <HeadRelation relation={relation} />
                    <SumsRelation relation={relation} />
                    {relation?.objectsData?.map((item, i) => {
                        return (
                            <FieldsRelation
                                prefixFieldKey={item.prefixKey ? item.prefixKey : ''}
                                key={i}
                                {...props}
                                sectionIndex={props.index}
                                usedForAddingObject={false}
                                relatedObjectData={item}
                            />
                        );
                    })}

                    {relation?.addOption && (
                        <tr>
                            <td colSpan={100}>
                                <RelationCreator {...props} />
                            </td>
                        </tr>
                    )}
                    {relation?.newObjectsData?.map((item, i) => {
                        return (
                            <FieldsRelation
                                prefixFieldKey={item.prefixKey ?? ''}
                                key={i}
                                {...props}
                                usedForAddingObject={true}
                                relatedObjectData={item}
                                sectionIndex={props.index}
                                index={i}
                            />
                        );
                    })}
                    <SumsRelation relation={relation} />
                </table>
            </div>
        </Belt>
    );
};
