import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@mui/icons-material/Refresh';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

import Tooltip from '@material-ui/core/Tooltip';

import AssignForm from '../containers/AssignForm';
import { formatTime, countTime } from '../../shared/utils';
import PauseBtn from './PauseBtn';
import PlayBtn from './PlayBtn';

const useStyles = makeStyles(({ palette, spacing }) => ({
    timer_timer_root: {
        padding: 0,
        width: '95%',
        margin: `${spacing(3)}px ${spacing(0.5)}px ${spacing(2)}px`,
        display: 'flex',
        flexWrap: 'wrap'
    },
    timer_timer_btn_container: {
        height: spacing(5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    timer_timer_btn_group: {
        display: 'flex'
    },
    timer_timer_btn: {
        width: spacing(5),
        height: spacing(5),
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    timer_timer_btn_icon: {
        width: spacing(4.5),
        height: spacing(4.5),
        color: palette.primary.main
    },
    timer_timer_btn_sm: {
        width: spacing(3),
        height: spacing(3),
        padding: 0,
        display: 'flex',
        color: palette.text.hint,
        justifyContent: 'center',
        alignItems: 'center'
    },
    timer_timer_time: {
        margin: `0 ${spacing(1)}px`,
        fontWeight: 'lighter',
        padding: `0 ${spacing(0.8)}px`,
        color: palette.text.primary,
        fontFamily: '"Lato", sans-serif',
        fontSize: spacing(4),
        borderRight: `2px solid ${palette.divider}`,
        '&.timer_time_active': {
            color: palette.primary.light
        }
    }
}));

/**
 * Bezstanowy komponent funkcyjny Timer. Wyświetla pojedyńczy stoper.
 * Wyświetla komponent [Components/Timers/AssignForm]{@link AssignForm}
 * @component
 * @category Components
 * @subcategory Timers
 * @param {object} props - Propsy komponentu
 * @param {array} props.data - lista właściwości Timera pobierana z backendu
 * @param {number} props.data.id -  Id Timera.
 * @param {number} props.data.stan_stopera -  informacja o dotychczasowym stanie stopera w sekundach
 * @param {number|null} props.data.start_date - null lub timestamp od kiedy Timer jest aktualnie wystartowany
 * @param {object|null} props.data.sprawa -  informacje o przypisanej sprawie
 * @param {funtion} props.handleCase - funckja przypisywania stopera do sprawy
 * @param {funtion} props.start - Funckja statrująca stoper.
 * @param {funtion} props.pause - Funckja pauzująca stoper.
 * @param {funtion} props.clear - Funckja restartująca stoper.
 * @param {funtion} props.cancel - Funckja anulująca stoper.
 * @param {funtion} props.finish - Funckja kończąca stoper.
 * @param {object} props.containerRef -  referencja do listy wyświetlającej stopery
 * @param {string} props.fieldId -  stan id pola potrzebny do ściągnięcia listy opcji dla pola select
 * @param {'large'|'medium'|'small'} props.viewport - rozmiar viewportu
 * @returns {ReactComponent}
 * @see [Components/Timers/AssignForm]{@link module:AssignForm}, [Components/Timers/Timers]{@link module:Timers}
 */
const Timer = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const {
        data: { id, stan_stopera, start_date, sprawa },
        handleCase,
        start,
        pause,
        clear,
        cancel,
        finish,
        activeId,
        timeOffset,
        containerRef,
        fieldId,
        viewport
    } = props;

    return (
        <Fragment>
            <ListItem className={classes.timer_timer_root}>
                <div className={classes.timer_timer_btn_container}>
                    {activeId === id ? (
                        <PauseBtn pause={pause} id={id} />
                    ) : (
                        <PlayBtn start={start} id={id} />
                    )}
                </div>

                <div
                    className={`${classes.timer_timer_time} ${
                        activeId === id ? 'timer_time_active' : ''
                    }`}
                    style={{
                        color: theme.palette.primary.main
                    }}>
                    {activeId === id
                        ? formatTime(countTime(start_date, stan_stopera, timeOffset))
                        : formatTime(stan_stopera)}
                </div>

                <div className={classes.timer_timer_btn_group}>
                    <div className={classes.timer_timer_btn_container}>
                        <Tooltip arrow className={classes.timer_btn_tooltip} title="reset">
                            <IconButton
                                className={classes.timer_timer_btn_sm}
                                onClick={() => clear(id)}>
                                <StopIcon
                                    sx={{
                                        fontSize: 25,
                                        marginTop: '1px'
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.timer_timer_btn_container}>
                        <Tooltip
                            arrow
                            className={classes.timer_btn_tooltip}
                            title={sprawa ? 'zakończ' : 'dodaj przypisanie aby zakończyć'}>
                            <span>
                                <IconButton
                                    disabled={!sprawa}
                                    className={classes.timer_timer_btn_sm}
                                    onClick={() => finish(id)}>
                                    <DoneIcon
                                        sx={{
                                            fontSize: 25,
                                            marginTop: '1px'
                                        }}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                    <div className={classes.timer_timer_btn_container}>
                        <Tooltip arrow className={classes.timer_btn_tooltip} title="anuluj">
                            <IconButton
                                className={classes.timer_timer_btn_sm}
                                onClick={() => cancel(id)}>
                                <DeleteIcon
                                    sx={{
                                        fontSize: 25,
                                        marginTop: '1px'
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

                <AssignForm
                    fieldId={fieldId}
                    sprawa={sprawa}
                    handleCase={handleCase}
                    containerRef={containerRef}
                    timerId={id}
                    viewport={viewport}
                />
            </ListItem>
        </Fragment>
    );
};

export default Timer;
