import { phpScreenToComponentUrl } from 'src/constants/phpScreenToComponentUrl';
import { getAternativeViewUrlData } from '../navigationSystem/getAternativeViewUrlData';

/**
 * modify link data to automatically target right react components even when url target to old
 * php page, also make proper link and query params when targeting php pages
 */
export const getLegacyCompatibleUrlData = (
    url: string,
    queryParams: URLSearchParams | undefined = undefined
) => {
    // work only for php links, we suppose that all links to components should be valid and
    // don't care about them
    if (!url.endsWith('.php')) return { queryParams, url };

    const php_url = url[0] === '/' ? url.replace('/', '') : url;

    // make proper url if php url have prepared page
    if (php_url in phpScreenToComponentUrl) {
        return getAternativeViewUrlData(phpScreenToComponentUrl[php_url], queryParams);
    }

    let validQueryParams = new URLSearchParams(queryParams);

    // if php_url do not have ready component format query params in order to PHPMapper can
    // work with them
    validQueryParams.append('php_url', php_url);

    // usage of url without slash, because router in react app will be only one dimmentional
    return { queryParams: validQueryParams, url: '/index.html' };
};
