import React, { FC, useCallback, useMemo } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldList } from 'src/components/popupFormExtended/dynamicField/fieldList/UiFieldList';
import { FieldListObjectProps } from 'src/components/popupFormExtended/dynamicField/fieldList/types';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { createSelectOptions, getNewFieldValueFromValueToAccept } from 'src/utils';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';

export const FieldListObject: FC<FieldListObjectProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = {
                ...field,
                ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
                value: {
                    ...field.value,
                    value: e.target.value
                }
            };
            dispatch({
                type: 'lista',
                payload: {
                    code: keyOfFieldInState,
                    value: newValue
                }
            });
            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: newValue,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        },
        [
            dispatch,
            keyOfFieldInState,
            field,
            dynamicFieldT,
            popupFormExtendedData,
            prefix,
            relationId
        ]
    );

    const onAcceptValue = useCallback(async () => {
        await dispatch({ type: 'accept_value', payload: keyOfFieldInState });

        const newFieldState = { ...field, value: getNewFieldValueFromValueToAccept(field) };

        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newFieldState,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    }, [
        dispatch,
        keyOfFieldInState,
        field,
        dynamicFieldT,
        popupFormExtendedData,
        prefix,
        relationId
    ]);

    const selectOptions = useMemo(
        () =>
            createSelectOptions({
                obj: field.value.opcje,
                type: dynamicFieldT.porzadek_elementow
            }),
        [field.value.opcje, dynamicFieldT.porzadek_elementow]
    );

    const uiFieldCommonPropsToUIComp = useMemo(
        () => ({
            ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
            ...uiFieldCommonProps
        }),
        [field, uiFieldCommonProps]
    );

    const uiFieldSharedProps = useMemo(
        () => ({
            dynamicFieldT,
            inputProps: {
                [dataSetInputKey]: keyOfFieldInState
            }
        }),
        [dynamicFieldT, keyOfFieldInState]
    );

    return (
        <UIFieldList
            value={field.value}
            options={selectOptions}
            onChange={onChange}
            onAcceptValue={onAcceptValue}
            uiFieldCommonProps={uiFieldCommonPropsToUIComp}
            uiFieldSharedProps={uiFieldSharedProps}
        />
    );
};
