import React from 'react';
import { LoginLayoutWrapper } from 'src/components/loginScreen/molecules/loginLayoutWrapper/LoginLayoutWrapper';
import LoginFooter from 'src/components/loginScreen/organisms/loginFooter/LoginFooter';
import LoginHeader from 'src/components/loginScreen/organisms/loginHeader/LoginHeader';
import SecondFAMiddleSection from '../../organisms/secondFAMiddleSection/SecondFAMiddleSection';

const SecondFAView = () => {
    return (
        <LoginLayoutWrapper>
            <LoginHeader />
            <SecondFAMiddleSection />
            <LoginFooter />
        </LoginLayoutWrapper>
    );
};

export default SecondFAView;
