import React, { FC } from 'react';
import { PermissionSectionsLevelProps } from './types';
import PermissionsLevelHeader from '../../molecules/permissionsLevelHeader/PermissionsLevelHeader';
import PermissionsField from '../../molecules/permissionsField/PermissionsField';
import PermissionsItemHeader from '../../molecules/permissionsItemHeader/PermissionsItemHeader';
import PermissionFieldsLevel from '../permissionFieldsLevel/PermissionFieldsLevel';
import PermissionRelationsLevel from '../permissionRelationsLevel/PermissionRelationsLevel';
import { PERIMISSION_LEVEL_INDENT } from 'src/constants/permissionsScreen';

const PermissionSectionsLevel: FC<PermissionSectionsLevelProps> = ({
    sections,
    tabId,
    unfoldedState,
    toggleFolding,
    ...props
}) => {
    const sectionsKey = `sections_${tabId}`;

    return (
        <div style={{ marginLeft: PERIMISSION_LEVEL_INDENT }}>
            <PermissionsLevelHeader
                folded={unfoldedState[sectionsKey]}
                onClick={() => toggleFolding(sectionsKey)}
                title="Sekcje"
            />
            {unfoldedState[sectionsKey] &&
                sections.map((section) => (
                    <ul key={section.id}>
                        <PermissionsItemHeader text={section.name} />
                        <PermissionsField
                            {...props}
                            item={section}
                            type="visible"
                            level="section"
                        />
                        <PermissionFieldsLevel
                            {...props}
                            fields={section.fields}
                            sectionId={section.id}
                            unfoldedState={unfoldedState}
                            toggleFolding={toggleFolding}
                        />
                        <PermissionRelationsLevel
                            {...props}
                            relations={section.relations}
                            sectionId={section.id}
                            unfoldedState={unfoldedState}
                            toggleFolding={toggleFolding}
                        />
                    </ul>
                ))}
        </div>
    );
};

export default PermissionSectionsLevel;
