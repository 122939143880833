import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useTypedSelector } from 'src/store';
import { ShortcutsPinSectionModuleListProps } from 'src/components/menu/organisms/shortcutsPinSection/types';
import styles from './AddObjectShortcutsSectionList.module.css';
import { ActionType } from 'src/components/shared/molecules/actionType/ActionType';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';

const AddObjectShortcutsSectionList: FC<ShortcutsPinSectionModuleListProps> = ({ onClose }) => {
    const actions = useTypedSelector((state) => state.topPlusSlice.actions);

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            border={1}
            padding={2}
            sx={{
                borderColor: (theme) => theme.palette.primary.main,
                borderRadius: '4px',
                color: (theme) => theme.palette.primary.main,
                background: (theme) => theme.palette.background.default
            }}>
            {actions.map((action) => {
                return (
                    <ActionType
                        action={action}
                        rowId={null}
                        key={action.code}
                        clickCallback={onClose}
                        sourceOfTrigger={{
                            type: 'addObjectShortcuts'
                        }}>
                        <div className={styles.screenWrapper}>
                            <ThemeCompatibleIcon
                                iconData={action.icon}
                                rawColor={action.color ?? 'primary.main'}
                                sx={{
                                    fontSize: '.8rem'
                                }}
                            />
                            <p>{action.name}</p>
                        </div>
                    </ActionType>
                );
            })}
        </Box>
    );
};

export default AddObjectShortcutsSectionList;
