import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { safeGetArrayOptionsWithAddNewObjectOption } from 'src/utils';

import { DynamicFormObjectDataConverter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectDataConverter';

import { DynamicFieldT } from 'src/data/popupFormTypes';
import {
    FieldListForeignStateRHF,
    FieldRelationTypeStateRHF,
    FieldsStateUnionRHF
} from 'src/data/fieldsReactHookForm';
import { HelperRHF } from 'src/utils/src/shared/HelperRHF';
import { FieldDependentHelperRHF } from 'src/components/form/molecules/fieldDependent/FieldDependentHelperRHF';
import {
    FieldDependentFunctionRHF,
    FieldDependentRHFProps,
    FieldDependentRowRHF,
    FieldDependentStaticDataRHF,
    FieldDependentTempStateRHF,
    FieldDepenedentFormStateHandlersRHF
} from 'src/components/form/molecules/fieldDependent/types';

export type FieldDependentFieldDefaultRHFProps = FieldDependentRHFProps & {
    tempState: FieldDependentTempStateRHF;
    fieldWasTriggeredDynamicFieldT: DynamicFieldT;
    dependentFunctionDynamicType: FieldDependentFunctionRHF;
    fieldWasTriggered: FieldsStateUnionRHF;
    row?: FieldDependentRowRHF;
};
export class FieldDependentFieldDefaultRHF extends FieldDependentHelperRHF {
    dynamicFieldsT: DynamicFieldT[];
    formStateHandlers: FieldDepenedentFormStateHandlersRHF;
    tempState: FieldDependentTempStateRHF;
    fieldWasTriggeredDynamicFieldT: DynamicFieldT;
    dependentFunctionDynamicType: FieldDependentFunctionRHF;
    fieldSuperiorOneDynamicFieldT: DynamicFieldT | null;
    fieldSuperiorOne: FieldsStateUnionRHF;
    fieldWasTriggered: FieldsStateUnionRHF;
    fieldWasTriggeredCode: string;
    fieldSuperiorOneCode: string;
    row?: FieldDependentRowRHF;
    staticData: FieldDependentStaticDataRHF;
    constructor({
        dynamicFieldsT,
        formStateHandlers,
        tempState,
        fieldWasTriggeredDynamicFieldT,
        dependentFunctionDynamicType,
        fieldWasTriggered,
        row,
        staticData
    }: FieldDependentFieldDefaultRHFProps) {
        super({
            formStateHandlers,
            dynamicFieldsT,
            tempState,
            staticData
        });
        this.row = row;
        this.formStateHandlers = formStateHandlers;
        this.dynamicFieldsT = dynamicFieldsT;
        this.tempState = tempState;
        this.fieldWasTriggeredDynamicFieldT = fieldWasTriggeredDynamicFieldT;
        this.dependentFunctionDynamicType = dependentFunctionDynamicType;
        this.fieldSuperiorOneDynamicFieldT = null;
        this.fieldSuperiorOne = {};
        this.fieldWasTriggered = fieldWasTriggered;
        this.fieldWasTriggeredCode = '';
        this.fieldSuperiorOneCode = '';
        this.staticData = staticData;
        this.setCodes();
        this.prepareStateFieldsSuperior();
    }

    private setCodes() {
        switch (this.staticData.type) {
            case 'massEditObjects':
                this.fieldWasTriggeredCode = HelperRHF.createKey({
                    dynamicFieldT: this.fieldWasTriggeredDynamicFieldT,
                    id: this.row?.id,
                    screen: 'massEditObjects'
                });
                this.fieldSuperiorOneCode = `${this.row?.id}_${this.fieldWasTriggeredDynamicFieldT?.pole_nadrzedne_kod}`;
                break;
            case 'raport':
                this.fieldWasTriggeredCode = HelperRHF.createKey({
                    dynamicFieldT: this.fieldWasTriggeredDynamicFieldT,
                    screen: 'raport'
                });
                this.fieldSuperiorOneCode = `${this.fieldWasTriggeredDynamicFieldT?.pole_nadrzedne_kod}`;
                break;
            default:
                break;
        }
    }

    async prepareStateFieldsSuperior() {
        switch (this.dependentFunctionDynamicType) {
            case 'zmienPole':
                {
                    const fieldSuperiorO = this.findStateOfFieldsByCode(this.fieldSuperiorOneCode);
                    this.fieldSuperiorOne = fieldSuperiorO ?? null;

                    this.fieldSuperiorOneDynamicFieldT =
                        this.findDynamicFieldTById(
                            String(this.fieldWasTriggeredDynamicFieldT?.pole_nadrzedne)
                        ) ?? null;
                }
                break;
            default:
                break;
        }
    }

    // START  - COMMON CODE FOR zmienPole()

    async zmienPole() {}

    protected async getValueToSetForChangeField() {
        const data: {
            opcje: { [key: string]: string };
            optionAutoComplete: { id: string; text: string }[];
        } = {
            opcje: {},
            optionAutoComplete: []
        };

        if (this.fieldSuperiorOne) {
            if (!this.fieldWasTriggeredDynamicFieldT) {
                console.error(
                    'FieldDependentDefaultManager: fieldWasTriggeredDynamicFieldT is null'
                );
                return { opcje: {}, optionAutoComplete: [] };
            }
            switch (true) {
                case 'powiazanie_typ' in this.fieldSuperiorOne:
                    {
                        const oneObj: Required<FieldRelationTypeStateRHF> = this
                            .fieldSuperiorOne as Required<FieldRelationTypeStateRHF>;
                        const params = {
                            q: oneObj.powiazanie_typ.value,
                            typ_nadrzedne: oneObj.powiazanie_typ?.select,
                            typ_podrzedne: this.fieldWasTriggeredDynamicFieldT?.tabela_opcji,
                            id_pola: this.fieldWasTriggeredDynamicFieldT?.id
                        };
                        const res = await getFieldAdditionalData({
                            url: 'json_ogolnie_podrzedne.php',
                            params
                        });
                        if (res.success) {
                            const options = safeGetArrayOptionsWithAddNewObjectOption(
                                res.data,
                                this.fieldWasTriggeredDynamicFieldT
                            );
                            data.optionAutoComplete = options;
                            data.opcje = DynamicFormObjectDataConverter.setObjectKeyValue(options);
                        }
                    }
                    break;
                case 'lista_obca' in this.fieldSuperiorOne:
                    {
                        const oneObj: Required<FieldListForeignStateRHF> = this
                            .fieldSuperiorOne as Required<FieldListForeignStateRHF>;
                        const params = {
                            q: oneObj.lista_obca.value,
                            typ_nadrzedne:
                                this.fieldWasTriggeredDynamicFieldT?.pole_nadrzedne_tabela_opcji,
                            typ_podrzedne: this.fieldWasTriggeredDynamicFieldT?.tabela_opcji,
                            id_pola: this.fieldWasTriggeredDynamicFieldT.id
                        };
                        const res = await getFieldAdditionalData({
                            url: 'json_ogolnie_podrzedne.php',
                            params
                        });
                        if (res.success) {
                            const options = safeGetArrayOptionsWithAddNewObjectOption(
                                res.data,
                                this.fieldWasTriggeredDynamicFieldT
                            );
                            data.optionAutoComplete = options;
                            data.opcje = DynamicFormObjectDataConverter.setObjectKeyValue(options);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        return data;
    }
}
