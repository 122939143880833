import { SettledObjectsCodes } from 'src/store/src/budget/budget/types';
import { BudgetFormStateSharedProps } from 'src/components/budgetScreen/organisms/budgetForm/types';
import { createMemoKey } from 'src/utils';

export type UseGetObjectMemoKeyProps = BudgetFormStateSharedProps & {
    methodId: string;
    code: SettledObjectsCodes;
    monthId: string;
    objectId: string;
};

export function useGetObjectMemoKey(props: UseGetObjectMemoKeyProps) {
    const value = props
        .getValuesBudgetRHF({
            keyField: createMemoKey([props.monthId, props.code, props.objectId]),
            methodId: props.methodId
        })
        .getString({ fieldType: 'liczba' });
    return value;
}
