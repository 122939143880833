import React, { FC } from 'react';
import { Button } from '@mui/material';

import { DynamicFormButtonProps } from './types';
import styles from './DynamicFormButton.module.css';

export const DynamicFormButton: FC<DynamicFormButtonProps> = ({
    children,
    styleWrapper,
    styleText,
    styleButton,
    onClick,
    submit = true
}) => {
    const type = submit ? 'submit' : 'button';
    return (
        <div className={styles.box} style={styleWrapper} onClick={onClick}>
            <Button
                variant="contained"
                type={type}
                sx={{
                    color: (theme) => theme.palette.background.default,
                    padding: '6px 50px',
                    cursor: 'pointer',
                    ...styleButton
                }}>
                <p className={`${styles.button}`} style={styleText}>
                    {children}
                </p>
            </Button>
        </div>
    );
};
