import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetScreenState,
    BugdetSettlementMethodLevel,
    SettleObject,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { BudgetHandlerStateSum } from 'src/utils/src/budget/BudgetHandlerStateSum';
import { BudgetSetterStateLimit } from 'src/utils/src/budget/BudgetSetterStateLimit';
import { BudgetHandlerTemplateMonthLimit } from 'src/utils/src/budget/BudgetHandlerTemplateMonthLimit';
import { BudgetHandlerObjectsToCalcLimitStake } from 'src/utils/src/budget/BudgetHandlerObjectsToCalcLimitStake';
import { BudgetSetterStateField } from 'src/utils/src/budget/BudgetSetterStateField';
import { BudgetHandlerUtils } from './BudgetHandlerUtils';
import { BudgetHandlerStateGlobalFnExecuteProps } from './BudgetHandlerStateGlobal';
import { checkRunMethodSumInMonth } from './checkRunMethodSumInMonth';
import { checkRunMethodSumInMonthForCaseLimit } from './checkRunMethodSumInMonthForCaseLimit';
import { getKeyMonthIdToUpdateMonthsForCaseLimit } from './getKeyMonthIdToUpdateMonthsForCaseLimit';
import { checkRunMethodSumInCase } from './checkRunMethodSumInCase';

type BudgetHandlerStateMethodProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};

export class BudgetHandlerStateMethod extends BudgetHandlerUtils {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
    budgetHandlerStateSum: BudgetHandlerStateSum;
    budgetSetterStateLimit: BudgetSetterStateLimit;
    budgetSetterStateField: BudgetSetterStateField;
    budgetHandlerObjectsToCalcLimitStake: BudgetHandlerObjectsToCalcLimitStake;
    budgetHandlerTemplateMonthLimit: BudgetHandlerTemplateMonthLimit;
    constructor({ budget, operationalState }: BudgetHandlerStateMethodProps) {
        super();
        this.budget = budget;
        this.operationalState = operationalState;
        this.budgetHandlerStateSum = new BudgetHandlerStateSum({ budget, operationalState });
        this.budgetSetterStateLimit = new BudgetSetterStateLimit({ budget, operationalState });
        this.budgetHandlerObjectsToCalcLimitStake = new BudgetHandlerObjectsToCalcLimitStake({
            budget,
            operationalState
        });
        this.budgetSetterStateField = new BudgetSetterStateField({ budget, operationalState });
        this.budgetHandlerTemplateMonthLimit = new BudgetHandlerTemplateMonthLimit({
            budget,
            operationalState
        });
    }

    runMethod(method: BugdetSettlementMethodLevel, data?: BudgetHandlerStateGlobalFnExecuteProps) {
        this.operationalState.method = method;

        switch (this.budget.budgetHandlerStateGlobalKind?.kind) {
            case 'getYearsBefore':
                this.runFieldsForYearBefore(method);
                break;
            default:
                break;
        }
        if (method.rozliczanielimitgodzin) {
            this.runObjectsToCalcLimit(method);
            this.runLimit(method);
            this.runSum(method, data);
        } else {
            this.runAll(method, data);
        }
    }

    private runFieldsForYearBefore(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            if (year.name?.includes('lata poprzednie') && year.months) {
                year?.months?.forEach((month) => {
                    this.runForMonthSublevelForEveryObject(
                        (x) => this.budgetSetterStateField.runSettleObject(x),
                        method,
                        month,
                        month.cases,
                        'sprawy'
                    );
                    this.runForMonthSublevelForEveryObject(
                        (x) => this.budgetSetterStateField.runSettleObject(x),
                        method,
                        month,
                        month.projects,
                        'projekty'
                    );
                    const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] =
                        Object.entries(month?.settledSingleElements ?? {}) as [
                            SettledObjectsCodes,
                            SettleObject
                        ][];
                    settledSingleElementsArr.forEach(([code, object]) => {
                        this.budgetSetterStateField.runSettleObject({
                            code,
                            object,
                            method,
                            month,
                            methodLevelType: 'miesiac'
                        });
                    });
                });
            }
        });
    }

    private runAll(
        method: BugdetSettlementMethodLevel,
        data?: BudgetHandlerStateGlobalFnExecuteProps
    ) {
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                if (
                    checkRunMethodSumInMonth(
                        this.budget.budgetHandlerStateGlobalKind,
                        month.id,
                        data?.monthId
                    )
                ) {
                    this.runForMonthSublevelForCaseItem(
                        (x) => this.budgetHandlerStateSum.calcSumFromField(x),
                        method,
                        month,
                        month.cases,
                        'sprawy',
                        'sprawa',
                        (x) => this.budgetHandlerStateSum.calcSumFromSum(x),
                        (caseItemId) =>
                            checkRunMethodSumInCase(
                                this.budget.budgetHandlerStateGlobalKind,
                                caseItemId,
                                data?.monthSubLevelItemId
                            )
                    );
                    this.runForMonthSublevelForCaseItem(
                        (x) => this.budgetHandlerStateSum.calcSumFromField(x),
                        method,
                        month,
                        month.projects,
                        'projekty',
                        'projekt',

                        (x) => this.budgetHandlerStateSum.calcSumFromSum(x),
                        (caseItemId) =>
                            checkRunMethodSumInCase(
                                this.budget.budgetHandlerStateGlobalKind,
                                caseItemId,
                                data?.monthSubLevelItemId
                            )
                    );
                    this.budgetHandlerStateSum.calcSumFromField({
                        type: 'miesiac',
                        month,
                        method,
                        methodLevelType: 'miesiac',
                        typeOfMatchedKeySum: 'miesiac'
                    });
                }
            });
        });
        if (method.rozliczaniecapfee) {
            this.budgetHandlerStateSum.calcBeforeCapfee({
                type: 'przedcapfee',
                method,
                methodLevelType: 'przedcapfee'
            });
            this.budgetHandlerStateSum.calcCapFeeFromSum({
                type: 'capfee',
                method,
                methodLevelType: 'capfee'
            });
        }
        this.budgetHandlerStateSum.calcSummary({
            type: 'podsumowanie',
            method,
            methodLevelType: 'podsumowanie'
        });
    }

    private runObjectsToCalcLimit(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                this.runForMonthSublevelForEveryObject(
                    (x) => this.budgetHandlerObjectsToCalcLimitStake.execute(x),
                    method,
                    month,
                    month.cases,
                    'sprawy'
                );
                this.runForMonthSublevelForEveryObject(
                    (x) => this.budgetHandlerObjectsToCalcLimitStake.execute(x),
                    method,
                    month,
                    month.projects,
                    'projekty'
                );
            });
        });
        this.budgetHandlerObjectsToCalcLimitStake.sortObjectsToCalcLimit();
    }

    private runLimit(method: BugdetSettlementMethodLevel) {
        if (this.budget.budgetHandlerStateGlobalKind?.kind === 'getYearsBefore') {
            method.years.forEach((year) => {
                year?.months?.forEach((month) => {
                    if (year.name?.includes('lata poprzednie')) {
                        this.budgetSetterStateLimit.runHoursLimitBeforeYears({
                            code: 'limit_godzin',
                            method,
                            month,
                            methodLevelType: 'miesiac'
                        });
                    } else {
                        this.budgetSetterStateLimit.runHoursLimit({
                            code: 'limit_godzin',
                            method,
                            month,
                            methodLevelType: 'miesiac'
                        });
                    }
                });
            });
        } else {
            method.years.forEach((year) => {
                year?.months?.forEach((month) => {
                    this.budgetSetterStateLimit.runHoursLimit({
                        code: 'limit_godzin',
                        method,
                        month,
                        methodLevelType: 'miesiac'
                    });
                });
            });
        }
    }

    private runSum(
        method: BugdetSettlementMethodLevel,
        data?: BudgetHandlerStateGlobalFnExecuteProps
    ) {
        const monthIdLimitArr = getKeyMonthIdToUpdateMonthsForCaseLimit(
            this.operationalState.templateKeyMonthIdToUpdateMonthsForCaseLimit,
            method.id,
            data?.monthId ?? ''
        );
        method.years.forEach((year) => {
            year?.months?.forEach((month) => {
                if (
                    checkRunMethodSumInMonthForCaseLimit(
                        monthIdLimitArr,
                        this.budget.budgetHandlerStateGlobalKind,
                        month.id
                    )
                ) {
                    this.runForMonthSublevelForCaseItem(
                        (x) => this.budgetHandlerStateSum.calcSumFromField(x),
                        method,
                        month,
                        month.cases,
                        'sprawy',
                        'sprawa',
                        (x) => this.budgetHandlerStateSum.calcSumFromSum(x),
                        (caseItemId) =>
                            checkRunMethodSumInCase(
                                this.budget.budgetHandlerStateGlobalKind,
                                caseItemId,
                                data?.monthSubLevelItemId
                            )
                    );
                    this.runForMonthSublevelForCaseItem(
                        (x) => this.budgetHandlerStateSum.calcSumFromField(x),
                        method,
                        month,
                        month.projects,
                        'projekty',
                        'projekt',
                        (x) => this.budgetHandlerStateSum.calcSumFromSum(x),
                        (caseItemId) =>
                            checkRunMethodSumInCase(
                                this.budget.budgetHandlerStateGlobalKind,
                                caseItemId,
                                data?.monthSubLevelItemId
                            )
                    );
                    this.budgetHandlerStateSum.calcSumMonthFromField({
                        type: 'miesiac',
                        month,
                        method,
                        methodLevelType: 'miesiac',
                        typeOfMatchedKeySum: 'miesiac'
                    });
                }
            });
        });
        if (method.rozliczaniecapfee) {
            this.budgetHandlerStateSum.calcBeforeCapfee({
                type: 'przedcapfee',
                method,
                methodLevelType: 'przedcapfee'
            });
            this.budgetHandlerStateSum.calcCapFeeFromSum({
                type: 'capfee',
                method,
                methodLevelType: 'capfee'
            });
        }
        this.budgetHandlerStateSum.calcSummary({
            type: 'podsumowanie',
            method,
            methodLevelType: 'podsumowanie'
        });
    }
}
