import { dispatch, useTypedSelector } from 'src/store';
import { fetchObjectNotBillInitData } from 'src/api/src/objectNotBillScreen/fetchObjectNotBillnitData';
import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';
import { setObjectNotBillData, setIsLoading } from 'src/store/src/objectNotBillScreen';
import { prepareParamsToApi } from 'src/utils';

export function useObjectNotBillDataWithActualMonth() {
    const objectNotBillStateData = useTypedSelector((state) => state.objectNotBill.data);
    const obiekt = useSearchParam('obiekt');
    const onClick = async () => {
        if (obiekt) {
            const prevParams = {
                obiekt: obiekt,
                ...prepareParamsToApi(objectNotBillStateData.params),
                rowniez_biezacy: objectNotBillStateData.params.rowniez_biezacy ? '0' : '1'
            };
            dispatch(setIsLoading(true));
            const params = new URLSearchParams([...Object.entries(prevParams)]);
            const data = await fetchObjectNotBillInitData({ params });

            if (data.success) {
                dispatch(setObjectNotBillData(data.data));
            }
        } else {
            console.error('Brak parametru object');
        }
    };

    return { onClick };
}
