const divider = 0.6;
export class TimeManager {
    static setMinutesAbsoluteToHour(minutes) {
        if (minutes) {
            const hours = Math.floor(minutes / 60);
            const rest = minutes % 60;
            const newMinutes = rest / divider;
            const totalH = hours + newMinutes / 100;
            return Math.round(totalH * 1000) / 1000;
        } else return 0;
    }
    static devideHoursToAbsoluteMinutes(time) {
        if (time) {
            const hoursInMinutes = Math.floor(time) * 60;
            const rest = Number(time.toString().split('.')[1])
                ? Number(time.toFixed(2).split('.')[1])
                : 0;
            const restMinutes = rest * divider;
            const allMinutes = hoursInMinutes + restMinutes;
            return Math.round(allMinutes);
        } else return 0;
    }
}
