import React from 'react';
import WidgetContainer from '../../shared/components/WidgetContainer';
import Timers from './Timers';
import { CloseArrow } from 'src/widgets/shared/components/CloseArrow';

const TimerTop = ({ viewport, timersOpen, handleOpen }) => {
    return (
        <>
            <WidgetContainer open={timersOpen} order={1} type="timers">
                <CloseArrow handleOpen={handleOpen} open={timersOpen} />
                <Timers handleOpen={handleOpen} open={timersOpen} viewport={viewport} />
            </WidgetContainer>
        </>
    );
};
export default TimerTop;
