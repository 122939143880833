import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './LoaderWewTwo.module.css';
export const LoaderWewTwo = () => {
    return (
        <div className={styles.box}>
            <CircularProgress color="primary" size={60} />
        </div>
    );
};
