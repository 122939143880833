import { DimensionsPopup, GlobalPopupState } from 'src/context/globalPopup/popupContext';
import { PopupState } from 'src/store/src/popup/popup/types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { dimensionsPopupFormExtended } from 'src/constants/popupContentDynamicExtended/dimensions';

type DimensionsSetterProps = {
    popupState: PopupState;
    popupContext: GlobalPopupState;
};

export class DimensionsSetter {
    popupState: PopupState;
    popupContext: GlobalPopupState;
    dimansions: DimensionsPopup;
    constructor({ popupContext, popupState }: DimensionsSetterProps) {
        this.popupState = popupState;
        this.popupContext = popupContext;
        this.dimansions = {
            ...popupContext.dimensions
        };
        this.execute();
    }

    private execute() {
        if (this.popupState.popupFormExtendedData) {
            this.calcForFormExtended(this.popupState.popupFormExtendedData);
        }
    }

    private calcForFormExtended(popupFormExtendedData: PopupFormExtendedData) {
        if (!popupFormExtendedData.relations.length) this.dimansions.isFixedWidth = false;

        popupFormExtendedData.relations.forEach((item) => {
            if (!item.objectsData.length && !item.addOption) this.dimansions.isFixedWidth = false;
        });
        switch (String(popupFormExtendedData.width)) {
            case '1.00':
                this.dimansions.maxContentWidth =
                    dimensionsPopupFormExtended.biggest.maxContentWidth;
                this.dimansions.breakpointWidth =
                    dimensionsPopupFormExtended.biggest.breakpointWidth;
                this.dimansions.fieldWidth = dimensionsPopupFormExtended.biggest.fieldWidth;
                break;
            case '0.66':
            case '0.75':
                this.dimansions.maxContentWidth =
                    dimensionsPopupFormExtended.bigger.maxContentWidth;
                this.dimansions.breakpointWidth =
                    dimensionsPopupFormExtended.bigger.breakpointWidth;
                this.dimansions.fieldWidth = dimensionsPopupFormExtended.bigger.fieldWidth;
                break;
            default:
                break;
        }
    }
    get() {
        return this.dimansions;
    }
}
