import React, { Dispatch, FC, SetStateAction } from 'react';
import { Divider } from '@mui/material';
import { useTypedSelector } from 'src/store';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { TypesOfContentPopOver } from 'src/components/menu/organisms/userSection/UserSection';
import { useLogout } from 'src/hooks';
import { topMenu } from 'src/constants/subtitles';
import { useTheme } from '@mui/material/styles';
import styles from './UserList.module.css';
import ThemeColorMiniMenu from '../themeColorMiniMenu/ThemeColorMiniMenu';

type Props = {
    setContentPopOver: Dispatch<SetStateAction<TypesOfContentPopOver>>;
    handleClose: () => void;
};

const UserList: FC<Props> = ({ setContentPopOver, handleClose }) => {
    const user = useTypedSelector((state) => state.user);
    const menuStructureData = useTypedSelector((state) => state.menu.menuStructureData);
    const params = new URLSearchParams(`obiekt=${user.userType}&id=${user.userID}`);
    const logout = useLogout();
    const theme = useTheme();

    return (
        <div
            className={styles.wrapperMain}
            style={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}>
            {/* link to user object */}
            <div className={styles.wrapperInner}>
                <LegacyCompatibleLink
                    url={'ekran_obiektu.php'}
                    queryParams={params}
                    outerFn={handleClose}>
                    <p className={styles.p}>{user.userNameToDisplay}</p>
                </LegacyCompatibleLink>
            </div>
            {/* options 'Mój raport' */}
            {menuStructureData.userOptions.findIndex((e) => e.type === 'Mój raport') !== -1 && (
                <div className={styles.wrapperInner} onClick={() => setContentPopOver('MY_REPORT')}>
                    <p className={styles.p}>{topMenu.options.userSection.two}</p>
                </div>
            )}
            {/* changing theme color */}
            <div className={styles.wrapperInner}>
                <ThemeColorMiniMenu />
            </div>
            <div className={styles.wrapperDivider}>
                <Divider />
            </div>
            {/* logout */}
            <div className={styles.wrapperInner}>
                <p className={styles.p} onClick={() => logout()}>
                    {topMenu.options.userSection.last}
                </p>
            </div>
        </div>
    );
};

export default UserList;
