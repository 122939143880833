import React, { FC } from 'react';

import Plus from 'src/components/object/atoms/plus/Plus';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { FormContext } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormContextProvider';
import styles from './RelationCreator.module.css';
import { RelationCreatorProps } from 'src/components/popupFormExtended/relationCreator/types';
import { RelatedObjectDataManager } from 'src/components/popupFormExtended/relationCreator/RelatedObjectDataManager';
import { globalPopup } from 'src/constants/subtitles';
import { useOnUpdating } from 'src/hooks/src/shared/useOnUpdating';
import { createUpdatingRelationEventId } from 'src/utils';

export const RelationCreator: FC<RelationCreatorProps> = (props) => {
    const { relation } = props;
    const { formState, dispatchFormState } = React.useContext(FormContext);
    const updating = useOnUpdating(createUpdatingRelationEventId(relation.id));

    const onClick = () => {
        const { relatedObjectData } = new RelatedObjectDataManager({
            relation: props.relation
        }).getData();

        const relationNew = {
            ...relation,
            newObjectsData: [...relation.newObjectsData, relatedObjectData]
        };

        const updatedState = new DynamicFormObjectStateHandler({
            relations: [relationNew],
            relatedObjectData,
            targetScreenCode: relationNew.targetScreenCode,
            state: formState
        }).handleState('add_new_relation_item_empty');

        dispatchFormState({
            type: 'update_state',
            payload: {
                tabs: updatedState.tabs,
                fields: updatedState.fields
            }
        });
    };

    if (props.relation.wylaczenie_dodawania) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.box} onClick={updating ? () => {} : onClick}>
                <Plus
                    style={{
                        margin: '2px',
                        width: '10px',
                        cursor: `${updating ? 'not-allowed' : 'pointer'}`
                    }}
                    disabled={updating}
                />
                <p
                    className={styles.p}
                    style={{
                        color: `${updating ? 'gray' : 'black'}`,
                        cursor: `${updating ? 'not-allowed' : 'pointer'}`
                    }}>
                    {globalPopup.contentFormExtended.relationCreator.add}
                </p>
            </div>
        </div>
    );
};
