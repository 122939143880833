import { PerrmisionScreenData } from 'src/api/src/permissionsScreen/types';
import { InitialPermissionsFormStateCreator } from './InitialPermissionsFormStateCreator';
import { PermissionLevel, PermissionType } from './types';
import { UnfoldedState } from 'src/components/permissionScreen/organisms/permissionsForm/types';

export class PermissionsScreenManager {
    static createInitialFormState(data: PerrmisionScreenData) {
        return InitialPermissionsFormStateCreator.createInitialStates(data);
    }

    static createInitialFoldingState(data: PerrmisionScreenData) {
        const state: UnfoldedState = {};
        if (data.functions.length <= 0) {
            return state;
        }

        for (const module of data.functions[0].modules) {
            const moduleKey = `module_${module.id}`;
            state[moduleKey] = true;
        }

        return state;
    }

    static getPermissionTypeText(type: PermissionType, level: PermissionLevel) {
        switch (type) {
            case 'visible':
                return 'Widoczność';
            case 'read':
                return 'Odczyt (wszystkie)';
            case 'adding':
                switch (level) {
                    case 'relation':
                        return 'Dodawanie';
                    default:
                        return 'Dodawanie (wszystkie)';
                }
            case 'editing':
                switch (level) {
                    case 'field':
                        return 'Modyfikacja';
                    default:
                        return 'Edycja (wszystkie)';
                }
            case 'deleting':
                return 'Usuwanie (wszystkie)';
            case 'readOwn':
                switch (level) {
                    case 'relation':
                        return 'Tylko swoje';
                    default:
                        return 'Odczyt (tylko osobiste)';
                }
            case 'readOwnDirect':
                return 'Tylko bezpośrednie';
            case 'editingOwn':
                return 'Edycja (tylko osobiste)';
            case 'deletingOwn':
                return 'Usuwanie (tylko osobiste)';
        }
    }
}
