import React, { FC } from 'react';

import { UiInputSelect } from 'src/components/shared/atoms/uiInputs/uiInputSelect/UiInputSelect';
import { DynamicListProps } from 'src/components/shared/atoms/dynamicList/types';

export const DynamicList: FC<DynamicListProps> = (props) => {
    return (
        <UiInputSelect
            value={props.fieldValue.value}
            label={props.dynamicFieldT.nazwa}
            opcje={props.dynamicFieldT.opcje}
            options={props.dynamicFieldT.options}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.field.onChange({
                    ...props.fieldValue,
                    value: e.target.value
                });
            }}
        />
    );
};
