import React, { FC } from 'react';

import { FieldDefaultUnmodifiedProps } from './types';
import { FieldWrapperUnmodified } from 'src/components/popupFormExtended/dynamicField/shared/fieldWrapperUnmodified/FieldWrapperUnmodified';
import { fieldRelativeOp } from 'src/constants/optionsForSelects';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { useSearchParam } from 'src/hooks';

export const FieldDefaultUnmodified: FC<FieldDefaultUnmodifiedProps> = ({
    dynamicFieldT,
    uiFieldCommonProps,
    field
}) => {
    const screen = useSearchParam('obiekt');

    const calculateFieldText: () => string = () => {
        switch (field?.typ) {
            case 'data':
                if (!field.value) return '';
                return `${field.value.toLocaleDateString('pl').replaceAll('.', '-')}`;
            case 'data_godzina':
                if (!field.value.date) return '';
                return `${field.value.date
                    .toLocaleDateString('pl')
                    .replaceAll('.', '-')} godzina ${field.value.date
                    .toLocaleTimeString()
                    .slice(0, -3)}`;
            case 'data_godziny':
                if (!field.value.date) return '';
                return `${field.value.date.toLocaleDateString('pl').replaceAll('.', '-')} Od ${
                    field.value.rozp
                } Do ${field.value.zak}`;
            case 'data_wzgledna':
                return `${field.value.date} ${fieldRelativeOp[field.value.zakres]}`;
            case 'powiazanie_typ':
                return `${field.value.select}: ${field.value.text}`;
            case 'cena_liczba':
                return `${field.value.liczba} x ${field.value.netto} netto ${
                    dynamicFieldT.ukryta_waluta ? '' : field.value.waluta
                } + VAT ${field.value.vat}% = ${field.value.total_brutto} brutto`;
            case 'cena':
                return `${field.value.netto} netto ${
                    dynamicFieldT.ukryta_waluta ? '' : field.value.waluta
                } + VAT ${field.value.vat}% = ${field.value.brutto} brutto`;
            case 'koszty':
                return `${field.value.value} ${field.value.currency}`;
            case 'lista_zalezna':
                return field.value.value;
            case 'lista':
                return field.value.opcje[field.value.value ?? ''];
            case 'numer':
                return dynamicFieldT.domyslna_wartosc_tekst ?? '';
            default:
                return dynamicFieldT.domyslna_wartosc.toString();
        }
    };
    const text = typeof field?.value == 'string' ? field?.value : calculateFieldText();

    return (
        <FieldWrapperUnmodified
            dynamicFieldT={dynamicFieldT}
            field={field}
            isLabel={Boolean(uiFieldCommonProps?.isLabel)}>
            {dynamicFieldT.link === 't' ? (
                <LegacyCompatibleLink
                    url="ekran_obiektu.php"
                    queryParams={
                        new URLSearchParams([
                            ['id', dynamicFieldT.link_id ?? ''],
                            ['obiekt', screen ?? '']
                        ])
                    }>
                    <p>
                        {text}
                        {dynamicFieldT.procent === 't' && '%'}
                    </p>
                </LegacyCompatibleLink>
            ) : (
                <p>
                    {text}
                    {dynamicFieldT.procent === 't' && '%'}
                </p>
            )}
        </FieldWrapperUnmodified>
    );
};
