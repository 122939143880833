import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(({ palette }) => ({
    badge: {
        position: 'absolute',
        top: (props) => (props.size === 'small' ? -1 : -3),
        left: (props) => (props.size === 'small' ? -1 : -3),
        width: (props) => (props.size === 'small' ? 13 : 17),
        height: (props) => (props.size === 'small' ? 13 : 17),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: (props) => (props.size === 'small' ? 8 : 10),
        borderRadius: '50%',
        color: palette.text.white,
        lineHeight: 1,
        background: palette.info.dark
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie odznaki z liczbą.
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {number} props.count - liczba do wyświetlenia, jeśli zero komponent nie wyświetli się
 * @param {'small'|'large'} props.size - wielkość odznaki, przekazywana do css
 * @returns {ReactComponent}
 */
const Badge = ({ count, size }) => {
    const classes = useStyles({ size });

    return (
        <Zoom in={!!count} unmountOnExit>
            <div className={classes.badge}>{count > 20 ? '20+' : count}</div>
        </Zoom>
    );
};

export default Badge;
