import React, { FC } from 'react';

import PopupTemplateFirst from 'src/components/shared/organisms/popupTemplateFirst/PopupTemplateFirst';
import styles from './styles.module.css';
import { usePopupContentConfig } from 'src/components/listScreen/organisms/popupContentConfig/usePopupContentConfig';
import { PopupContentConfigColumnSection } from 'src/components/listScreen/organisms/popupContentConfig/popupContentConfigColumnSection/PopupContentConfigColumnSection';
import { PopupContentConfigLine } from './popupContentConfigLine/PopupContentConfigLine';
import { PopupContentConfigColumnOther } from 'src/components/listScreen/organisms/popupContentConfig/popupContentConfigOther/PopupContentConfigOther';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/types';

const PopupContentConfig: FC<BasePopupContentProps> = ({ popup }) => {
    const {
        setAll,
        ubdatePinnedItem,
        ubdateVisibleItem,
        updateIsCondensedView,
        popupStateContext
    } = usePopupContentConfig(popup);

    return (
        <PopupTemplateFirst onClick={setAll} buttonTxt={'Zapisz'} title={'Konfiguracja'}>
            <div className={styles.wrapper}>
                <PopupContentConfigColumnSection
                    ubdatePinnedItem={ubdatePinnedItem}
                    ubdateVisibleItem={ubdateVisibleItem}
                    popupStateContext={popupStateContext}
                />
                <PopupContentConfigLine />
                <PopupContentConfigColumnOther
                    updateIsCondensedView={updateIsCondensedView}
                    popupStateContext={popupStateContext}
                />
            </div>
        </PopupTemplateFirst>
    );
};

export default PopupContentConfig;
