import React from 'react';
import { get } from 'axios';

import COLORS from '../../chat/constants/colors';
import STORAGE_ACTIONS from '../../chat/constants/storageActions';
import { openWindowsKey } from '../../chat/constants/storageKeys';

export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null;
}

function calculateShadeVal(val, shade = 'light') {
    if (!Number.isInteger(val) || val < 0)
        throw new TypeError('Val parameter must be a positive integer');
    if (shade !== 'light' && shade !== 'dark')
        throw new TypeError("Available values for shade parameter: 'light' and 'dark'.");

    const step = shade === 'light' ? (255 - val) / 10 : -(val / 10);
    let result = val + 2 * step;
    return Math.floor(result);
}

/**
 * @category Utils
 * @function genrateColorShades
 * @description funkcja zwraca wy
 * @param {string} hex kolor w notacji HEX.
 * @returns {{light: string, main: string, dark: string}} - obiekt z ocieniami w notacji RGB
 */
export function genrateColorShades(hex) {
    const rgb = hexToRgb(hex);
    return {
        light: `rgb(${calculateShadeVal(rgb.r, 'light')},${calculateShadeVal(
            rgb.g,
            'light'
        )},${calculateShadeVal(rgb.b, 'light')})`,
        main: `rgb(${rgb.r},${rgb.g},${rgb.b})`,
        dark: `rgb(${calculateShadeVal(rgb.r, 'dark')},${calculateShadeVal(
            rgb.g,
            'dark'
        )},${calculateShadeVal(rgb.b, 'dark')})`
    };
}

/**
 * @category Utils
 * @function formatTime
 * @description Funkcja która formatuje czas w sekundach na string: 'HH:MM:SS'.
 * @param {number} totalSeconds - czas w sekundach
 * @returns {string} - sformatowany czas
 */
export const formatTime = (totalSeconds) => {
    if (totalSeconds <= 0) return '00:00:00';
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    hours = hours < 10 ? '0' + hours : hours + '';
    minutes = minutes < 10 ? '0' + minutes : minutes + '';
    seconds = seconds < 10 ? '0' + seconds : seconds + '';

    return `${hours}:${minutes}:${seconds}`;
};

/**
 * @category Utils
 * @function countTime
 * @description Funkcja która wylicza aktualny czas stopera.
 * @param {number} start_date - data startu w timestamp
 * @param {number} timer_state - zapisany stan poprzednich sesji stopera w sekundach
 * @returns {number} - sformatowany czas w sekundach
 */
export const countTime = (start_date, timer_state, timeOffset) => {
    return Math.floor(new Date().getTime() / 1000) + timeOffset - start_date + timer_state;
};

/* regex for detecting url links in text */
const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/\\/=]*)/gi;

/**
 * @category Utils
 * @function isGroupIdTemporary
 * @description Sprawdz czy grupa posiada tymczasowe id (istnieje tylko lokalnie)
 * @param {string} stringID - id grupy
 * @returns {boolean}
 */
export const isGroupIdTemporary = (stringID) => /^temp_group_/.test(stringID);

/**
 * @category Utils
 * @function usersToArray
 * @description Przyjmuje dictionary użytkowników i zwraca tablice
 * @param {{userId: {}}} users - dictionary użytkowników
 * @returns {Array.<Object>}
 */
export const usersToArray = (users) => [].concat.apply([], Object.values(users));

/**
 * @category Utils
 * @function sortByUnread
 * @description Sortuje dwa obiekty po wyższej ilości nieodczytanych wiadomości
 * @param {{roomID: string}} obj1 - użytkownik lub grupa do porównania
 * @param {{roomID: string}} obj2 - użytkownik lub grupa do porównania
 * @param {{}} [unreadChats] - obiekt z nieodczytanymi czatami
 * @returns {number}
 */
const sortByUnread = (obj1, obj2, unreadChats) =>
    (unreadChats[obj2.roomID] || 0) - (unreadChats[obj1.roomID] || 0);

export const sortUsersAndGroups = (prev, next, unreadChats = null) => {
    let sortedByUnread = 0;
    if (unreadChats) {
        sortedByUnread = sortByUnread(prev, next, unreadChats);
    }

    if (sortedByUnread === 0) {
        const lastMessageSort = (next.lastMessage || 0) - (prev.lastMessage || 0);

        if (lastMessageSort) {
            return lastMessageSort;
        }
        return prev.name.toUpperCase().localeCompare(next.name.toUpperCase());
    }
    return sortedByUnread;
};
/**
 * @category Utils
 * @function usersArrayToObject
 * @description Bierze tablice użytkowników i zwraca obiekt o typach użytkowników jako kluczach i tablicach posortowanych użytkowników jako wartościach
 * @param {Array.<Object>} usersArr - tablica użytkowników
 * @param {string[]} types - typy użytkowników
 * @param {{}} [unreadChats] - obiekt z nieodczytanymi czatami
 */
export const usersArrayToObject = (usersArr, types, unreadChats = null) => {
    if (!usersArr.length || !types.length) return {};

    const result = types.reduce((acc, type) => {
        acc[type] = [];
        return acc;
    }, {});
    usersArr.forEach((user) => result[user.userType].push(user));
    /* Get rid of user type array with no users inside */
    Object.keys(result).forEach((key) => (!result[key].length ? delete result[key] : null));
    /* Sort users */
    Object.values(result).forEach((array) =>
        array.sort((a, b) => sortUsersAndGroups(a, b, unreadChats))
    );
    return result;
};

/**
 * @category Utils
 * @function identifyUser
 * @description Tylko w developmencie: zwróć dane użytkownika z api systemu
 * @async
 * @returns {{userID: string, userType: string, sessionID: string, system_url: string, chat: boolean, timers:boolean, node_url: string}|string} - Obiekt z danymi użytkownika lub string jeśli nie ma autoryzacji
 */
export const identifyUser = async () => {
    try {
        const { data } = await get('api_react/userSynch.php');
        return data;
    } catch (error) {
        console.log(error);
    }
};

/**
 * @category Utils
 * @function addAvatarToUser
 * @description Dodaje avatar do obiektu użytkownika
 * @param {{name: string}} user użytkownik
 * @param {number} i - index
 * @returns {{name: string, avatar: {initials: string, color: string}}}
 */
export const addAvatarToUser = (user, i) => {
    const { name } = user;
    let initials;

    if (user?.avatar?.initials) {
        initials = user.avatar.initials;
    } else {
        initials = name
            .split(' ')
            .map((word) => word[0])
            .join('');
    }
    const color = i === -1 ? '#43a047' : COLORS[i % COLORS.length];
    return {
        ...user,
        avatar: {
            initials,
            color
        }
    };
};

/**
 * @category Utils
 * @function getUnreadMessagesCount
 * @description Zwraca ilość nieodczytanych wiadomości dla danego id
 * @param {Object.<string, number>} unreadChats obiekt z nieodczytanymi czatami
 * @param {string} id
 * @returns {number}
 */
export const getUnreadMessagesCount = (unreadChats, id) => unreadChats[id] || 0;

/**
 * @category Utils
 * @function formatMessageDate
 * @description Formatuje timestamp do 'dd.mm.yyyy, HH:MM'
 * @param {number} timestamp
 * @returns {string}
 */
export const formatMessageDate = (timestamp) => {
    const date = new Date(timestamp),
        now = new Date(),
        sameYear = date.getFullYear() === now.getFullYear(),
        sameMonth = date.getMonth() === now.getMonth(),
        sameDay = date.getDate() === now.getDate(),
        year = date.getFullYear(),
        month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
        day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        time = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${
            date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        }`;

    let formatted;

    if (sameYear) {
        if (sameMonth && sameDay) {
            formatted = time;
        } else {
            if (now.getDate() - date.getDate() === 1) {
                formatted = 'Wczoraj ' + time;
            } else if (now.getDate() - date.getDate() === 2) {
                formatted = 'Przedwczoraj ' + time;
            } else {
                formatted = day + '.' + month + ' ' + time;
            }
        }
    } else {
        formatted = year + '.' + month + '.' + day + ' ' + time;
    }

    return formatted;
};

/**
 * @category Utils
 * @function buildAnchors
 * @description Tworzy specjalny reaktowy obiekt __html potrzebny do zbudowania linku wokół wykrytego w wiadomości stringu url.
 * @param {string} message  wiadomość
 * @returns {{__html: string}} - Format potrzebny do funkcji Reacta dangerouslySetInnerHTML
 */
const buildAnchors = (message) => ({
    __html: message.replace(
        urlRegex,
        (match) => `<a target="_blank" rel="noopener noreferrer" href=${match}>${match}</a>`
    )
});

/**
 * @category Utils
 * @function parseMessage
 * @description Tworzy tag <p> ze stringa
 * @param {string} message
 * @returns {HTMLParagraphElement}
 */
export const parseMessage = (message) => <p dangerouslySetInnerHTML={buildAnchors(message)} />;

/**
 * @category Utils
 * @function playSound
 * @description Odtwarza dźwięk
 * @param {HTMLAudioElement} sound
 */
export const playSound = (sound) => {
    if (!sound.paused) {
        sound.pause();
        sound.currentTime = 0;
    }
    sound.play().catch((e) => console.log(e.message));
};

/**
 * @category Utils
 * @function createChatObject
 * @description Inicjuje obiekt czatu, z częściowego obiektu, ustawia defaultowe wartości
 * @param {{}} chatInfo - informacje z serwera
 * @param {string} windowID - odniesienie do id okna czatu
 * @returns {{}}
 */
export const createChatObject = (chatInfo, windowID) => ({
    ...chatInfo,
    windowID,
    messages: [],
    index: null,
    scrolledUp: false,
    bottomOffset: null,
    newMessage: false,
    loading: false,
    minimized: false,
    lastReadMessages: null
});

/**
 * @category Utils
 * @function createLastReadDict
 * @description Tworzy słownik z tablicy ostatnich wiadomośći
 * @param {undefined | any[]} lastRead - informacje z serwera
 * @returns {{}}
 */
export const createLastReadDict = (lastRead) => {
    if (!lastRead) return null;

    return lastRead.reduce((acc, lr) => {
        if (acc[lr.messageID]) acc[lr.messageID].push(lr.roomID);
        else acc[lr.messageID] = [lr.roomID];
        return acc;
    }, {});
};

/**
 * @async
 * @category Utils
 * @function resizePicturePromise
 * @description Resize zdjęcia do max 500px na dłuższym boku.
 * @param {File} file
 * @returns {Promise}
 */
export const resizePicturePromise = (file) =>
    new Promise((resolve, reject) => {
        const img = new Image();
        const type = file.type;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        img.onload = () => {
            const w = img.width,
                h = img.height,
                wider = w > h,
                dw = (canvas.width = wider ? 500 : 500 * (w / h)),
                dh = (canvas.height = wider ? 500 * (h / w) : 500);

            if (w <= 500 && h <= 500) {
                return resolve(file);
            }

            ctx.drawImage(img, 0, 0, w, h, 0, 0, dw, dh);

            canvas.toBlob((b) => {
                URL.revokeObjectURL(img.src);
                return resolve(b);
            }, type);
        };

        img.onerror = (e) => {
            return reject(e.message);
        };

        img.src = URL.createObjectURL(file);
    });

/**
 * @category Utils
 * @description Znajduje użytkownika lub grupę po roomID
 * @function findChatInfoByRoomID
 * @param {{}} users - hash table użytkowników
 * @param {{}} groups - hash table grup
 * @param {string} roomID
 * @returns {{}|undefined} użytkownik lub grupa
 */
export const findChatInfoByRoomID = (users, groups, roomID) => {
    return (
        Object.values(users).find((u) => u.roomID === roomID) ||
        Object.values(groups).find((g) => g.roomID === roomID)
    );
};

/**
 * @category Utils
 * @function getChatByWindowID
 * @description Znajduje obiekt czatu po id otwartego okna czatu
 * @param {{}} state - stan Redux store
 * @param {string} windowID
 * @returns {{}| null}
 */
export const getChatByWindowID = (state, windowID) => {
    const chats = state.chats;
    const users = state.chatUser.users;
    const groups = state.chatUser.groups;
    const target = users[windowID] || groups[windowID];

    return target ? chats[target.roomID] : null;
};

/**
 * @category Utils
 * @function bindWindowCallback
 * @description ustawia callback jako własność 'app_chat_callback' obiektu window
 * @param {Function} cb - callback do przypisania
 * @returns {void}
 */
export const bindWindowCallback = (cb) => {
    window['app_chat_callback'] = cb;
};

/**
 * @category Utils
 * @function executeWindowCallback
 * @description wywołuje  i następnie kasuje callback przypisany do własność 'app_chat_callback' obiektu window.
 * @returns {void}
 */
export const executeWindowCallback = () => {
    if ('app_chat_callback' in window && typeof window['app_chat_callback'] === 'function') {
        window['app_chat_callback']();
        delete window['app_chat_callback'];
    }
};

/**
 * @category Utils
 * @function cancelWindowCallback
 * @description kasuje callback przypisany do własność 'app_chat_callback' obiektu window.
 * @returns {void}
 */
export const cancelWindowCallback = () => {
    if ('app_chat_callback' in window && typeof window['app_chat_callback'] === 'function') {
        delete window['app_chat_callback'];
    }
};

/**
 * @category Utils
 * @function getAvailableGroupUsers
 * @description Zwraca dictionary z użytkownikami przefiltrowanymi przez wybranych już użytkowników
 * @param {Array.<Object>}selectedUsers -wybrani użytkownicy
 * @param {Object.<string, User>} users - obiekt z użytkownikami
 * @returns {{}}
 */
export const getAvailableGroupUsers = (selectedUsers, users) => {
    const types = Object.keys(users);
    return usersArrayToObject(
        usersToArray(users).filter((u) => selectedUsers.findIndex((su) => su.id === u.id) === -1),
        types
    );
};

/**
 * @category Utils
 * @function areTwoArraysDifferent
 * @description Porównuje dwie tablice ze stringami i zwraca informacje czy są różne
 * @param {string[]} groupUsers -  id użytkowników grupy
 * @param {string[]} selectedUsers - id wybranych użytkowników
 * @returns {Boolean}
 */
export const areTwoArraysDifferent = (groupUsers, selectedUsers) => {
    let different = false;

    groupUsers.forEach((id) => {
        if (selectedUsers.indexOf(id) === -1) {
            different = true;
        }
    });

    selectedUsers.forEach((id) => {
        if (groupUsers.indexOf(id) === -1) {
            different = true;
        }
    });

    return different;
};

/**
 * @category Utils
 * @function computeMaxWindows
 * @description Funkcja mapuje string z wielkością viewportu na liczbę.
 * @param {'mobile'|'medium'|'large'|'xlarge'}  viewport -  rozmiar wiewprotu
 * @returns {number}
 */
export const computeMaxWindows = (viewport) => {
    switch (viewport) {
        case 'small':
            return 1;
        case 'medium':
            return 2;
        case 'large':
            return 2;
        default:
            return 3;
    }
};

/**
 * @category Utils
 * @function computeMaxWindows
 * @description Funkcja tworzy url z podanych komponentów.
 * @param {string}  baseUrl - protokół, domena port
 * @param {string}  path - sćieżka
 * @param {string[]}  [queryArr] - tablica z query
 * @returns {string}
 * @example
 * createUrl('https://www.google.com/', '/download/', 'url=www.abcde.com', 'file=skas:kdjf:jclsa.jpg'); // https://www.google.com/download?url=www.abcde.com&file=skas%3Akdjf%3Ajclsa.jpg
 */
export const createUrl = (baseUrl, path, ...queryArr) => {
    const queryStr = queryArr
        .map((q) =>
            q
                .trim()
                .split('=')
                .map((qp) => encodeURIComponent(qp))
                .join('=')
        )
        .join('&');
    baseUrl = baseUrl.trim().replace(/\/$/, '');
    path = path.trim().replace(/^\/|\/$/g, '');
    return `${baseUrl}${path ? '/' + path : ''}${queryArr.length ? '?' + queryStr : '/'}`;
};

/**
 * @category Utils
 * @function transformStringKeysToBooleans
 * @description Zamienia wartości podanych kluczy z 'f' na false i z 't' na true.
 * @param {any} item - struktura danych do transformacji
 * @param {string[]} keysArr - tablica z kluczami których wartości mają zostać zmienione
 * @returns {any}
 */
export function transformStringKeysToBooleans(item, keysArr) {
    if (typeof item === 'object') {
        for (const key in item) {
            if (Object.hasOwnProperty.call(item, key)) {
                if (typeof item[key] === 'object') {
                    item[key] = transformStringKeysToBooleans(item[key], keysArr);
                } else if (typeof item[key] === 'string') {
                    if (keysArr.includes(key)) {
                        if (item[key] === 'f') item[key] = false;
                        else if (item[key] === 't') item[key] = true;
                    }
                }
            }
        }
    }
    return item;
}

/**
 * @category Utils
 * @function createClassNamesString
 * @description Tworzy string z przekazanymi nazwami klas.
 * @param {...string} names - nazwy klas
 * @returns {string}
 */
export const createClassNamesString = (...names) => names.join(' ');

/**
 * @category Utils
 * @function storeOpenWindows
 * @description Zarządza zapisywaniem stanu czatu w sessionStorage.
 * @param {string} type - typ akcji do zapisania
 * @param {string[]} [openWindows] - lista otwartych okien
 * @returns {void}
 */
export const storeOpenWindows = (type, openWindows) => {
    switch (type) {
        case STORAGE_ACTIONS.CLOSE_APP:
            sessionStorage.removeItem(openWindowsKey);
            // sessionStorage.removeItem(minimizedWindowsKey);
            // console.log(sessionStorage.getItem(minimizedWindowsKey));
            break;
        case STORAGE_ACTIONS.OPEN_APP:
            sessionStorage.setItem(openWindowsKey, JSON.stringify([]));
            // sessionStorage.setItem(minimizedWindowsKey, JSON.stringify([]));
            break;
        default:
            sessionStorage.setItem(openWindowsKey, JSON.stringify(openWindows));
    }
};

export const flashHaveUnreadMessage = (() => {
    /* This part is called only */

    let orginalTitle = document.title;
    /* interval id */
    let flashId;
    /* cancel document title flashing */
    function cancelFlash() {
        document.title = orginalTitle;
        clearInterval(flashId);
        flashId = null;
    }
    /* when card is focused it cancel flashing and sends message(via localStorage) to other tabs to also cancel flashing */
    function handleFocus() {
        cancelFlash();
        /* all tabs listen to ''storage' event so setting item will act as messsage */
        window.localStorage.setItem('cancelFlashing', true);
        window.localStorage.removeItem('cancelFlashing');
    }

    /* Events fires if another tab called handleFocus function */
    window.addEventListener('storage', (event) => {
        // is event right cheks
        if (event.storageArea != localStorage) return;
        if (event.key === 'cancelFlashing' && event.newValue === true) {
            /* cancel flash */
            cancelFlash();
            /* remove event listener to not call handleFocus if tab is focused later */
            window.removeEventListener('focus', handleFocus);
        }
    });

    /* This part is called each time in onMessage event */
    return () => {
        if (!flashId && !document.hasFocus()) {
            flashId = setInterval(() => {
                document.title === orginalTitle
                    ? (document.title = 'Masz wiadomość')
                    : (document.title = orginalTitle);
            }, 1500);

            window.addEventListener('focus', handleFocus, { once: true });
        }
    };
})();
