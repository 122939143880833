import React, { FC } from 'react';
import { Button, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import TrashIcon from 'src/assets/kosz.svg';
import { getColorForInputWithAllFlag } from 'src/styles/getColor';
import styles from './UiFieldFIleMany.module.css';
import { UIFieldFileManyProps } from 'src/components/popupFormExtended/dynamicField/fieldFileMany/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { fields } from 'src/constants/subtitles';
import { UiLabel } from 'src/components/shared/atoms/uiInputs/uiLabel/UiLabel';

export const UIFieldFileMany: FC<UIFieldFileManyProps> = (props) => {
    const {
        value,
        onChange,
        onDeleteFile,
        uiFieldCommonProps,
        uiFieldSharedProps,
        errorMessage,
        progress
    } = props;
    const inputRef = React.useRef<null | HTMLInputElement>(null);
    const theme = useTheme();

    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            onChange(e.dataTransfer.files);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            onChange(e.target.files);
        }
    };

    const onButtonClick = () => {
        inputRef?.current?.click();
    };
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div onDragEnter={handleDrag}>
                <input
                    type="file"
                    id="input-file-upload"
                    multiple
                    className={styles.inputfileupload}
                    ref={inputRef}
                    onChange={handleChange}
                    accept={uiFieldSharedProps.dynamicFieldT.plik_akceptowany_typ}
                />
                <label
                    className={styles.box}
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}>
                    <div
                        className={styles.dropzone}
                        style={{
                            border: `solid 1px ${getColorForInputWithAllFlag({
                                theme,
                                flag: { ...uiFieldCommonProps }
                            })}`
                        }}>
                        <UiLabel label={fields.fieldFileMany.field} />
                    </div>
                    <Button
                        variant="contained"
                        onClick={onButtonClick}
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 16px',
                            cursor: 'pointer'
                        }}>
                        <p className={`${styles.button}`}>{fields.fieldFileMany.button}</p>
                    </Button>
                </label>
                {errorMessage && <p>{errorMessage}</p>}
                <div>
                    {value.map((item) => {
                        return (
                            <div key={item?.name} className={styles.box2}>
                                <p className={styles.fileName}>{item?.originalFileName}</p>
                                {item.loading ? (
                                    <div className={styles.progressWrapper}>
                                        <LinearProgress
                                            variant={progress ? 'determinate' : undefined}
                                            value={progress}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        onClick={(e) => onDeleteFile(e, item.id)}
                                        className={styles.trashWarpper}>
                                        {' '}
                                        <ThemeCompatibleIcon
                                            SvgComponent={TrashIcon}
                                            fontSize={'medium'}
                                            sx={{
                                                width: '11px'
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </UiWrapper>
    );
};
