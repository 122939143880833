import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const TagView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "TagView" */ 'src/components/tagScreen/templates/TagView/TagView'
        )
);

export const TagMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Tagi');
    return (
        <div>
            <React.Suspense fallback>
                <TagView />
            </React.Suspense>
        </div>
    );
};
