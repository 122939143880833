import { RefObject, useEffect, useState } from 'react';
import { useObserveElementSize } from './useObserveElementSize';

type CellHeight = number;
type RemSize = number;
/**
 * Hook calculates approximate rem size and height of given element when screen height changes
 * @param offset - additional space occupied expressed in rems
 */
export function useCalculateElementHeight(
    ref: RefObject<HTMLElement>,
    rows = 1,
    offset = 0
): [CellHeight, RemSize] {
    const [elemSize, setElemSize] = useState<number>(1);
    const [remSize] = useState<number>(
        parseFloat(window.getComputedStyle(document.documentElement).fontSize)
    );
    const { height } = useObserveElementSize(ref);

    useEffect(() => {
        // calculate height offset based on current rem size
        // start values
        if (height) {
            const pxOffset = remSize * offset;
            setElemSize(Math.floor((height - pxOffset) / rows));
        }
    }, [height, remSize, offset, rows]);

    return [elemSize, remSize];
}
