import { DynamicFieldT } from 'src/data/popupFormTypes';
import { legacyApiParamToBool } from 'src/utils/src/shared/legacyApiParamToBool';

export function setIsModifyOfField(
    field: DynamicFieldT,
    usedForAddingObject: boolean,
    relatedObjectData?: { edit: boolean }
) {
    // here not used only legacyApiParamToBool because when value is undefiend it means true
    const dodawanie = field?.dodawanie === undefined || legacyApiParamToBool(field?.dodawanie);
    const modyfikacja =
        field?.modyfikacja === undefined || legacyApiParamToBool(field?.modyfikacja);

    const widoczne_tylko_w_dodawaniu =
        field?.widoczne_tylko_w_dodawaniu === undefined ||
        legacyApiParamToBool(field?.widoczne_tylko_w_dodawaniu);
    const modyfikacja_przy_dodawaniu =
        field?.modyfikacja_przy_dodawaniu === undefined ||
        legacyApiParamToBool(field?.modyfikacja_przy_dodawaniu);
    let isModify = false;

    // added here "&& modyfikacja_przy_dodawaniu" because some fields when
    //!modyfikacja_przy_dodawaniu and form is used for adding object lock field modification
    if (dodawanie && modyfikacja && !widoczne_tylko_w_dodawaniu && modyfikacja_przy_dodawaniu) {
        isModify = true;
    }

    if (usedForAddingObject) {
        if (dodawanie && modyfikacja && widoczne_tylko_w_dodawaniu) {
            isModify = true;
        } else if (dodawanie && modyfikacja_przy_dodawaniu) {
            isModify = true;
        }
    } else {
        if (modyfikacja) {
            isModify = true;
        }
        if (relatedObjectData && !(relatedObjectData.edit && modyfikacja)) isModify = false;
    }

    return isModify;
}
