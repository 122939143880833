import React, { FC } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, createFilterOptions } from '@mui/material';

import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import style from './style.module.css';
import { useFieldClasses } from 'src/hooks';
import { createFormData, getTypeUrl } from 'src/utils';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { DynamicRelationshipTypeProps } from 'src/components/shared/atoms/dynamicRelationshipType/types';
import { DynamicLabel } from 'src/components/shared/atoms/dynamicLabel/DynamicLabel';
import { FieldListForeignAutocompleteOption } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/types';
import { BroadcastAddBackPayload } from 'src/components/popupFormExtended/types';
import { useAddBackBroadcastChannel } from 'src/hooks/src/popupFormExtended/useAddBackBroadcastChannel';
import { generateAddBackUrlTwo } from 'src/utils/src/popupFormExtended/generateAddBackUrlTwo';
import { UiAutocompleteInputTwo } from 'src/components/shared/atoms/uiInputs/uiAutocompleteInputTwo/UiAutocompleteInputTwo';

const filter = createFilterOptions<FieldListForeignAutocompleteOption>();

export const DynamicRelationshipType: FC<DynamicRelationshipTypeProps> = ({
    dynamicFieldT,
    field,
    fieldValue
}) => {
    const value = fieldValue.value;
    const classes = useFieldClasses({ muiType: 'autocompleteOne' });
    const [inputValue, setInputValue] = React.useState(value?.text ?? '');
    const [optionToChoose, setOptionToChoose] = React.useState([]);
    const url = getTypeUrl({ field: dynamicFieldT });

    const startAddBackBroadcastChannel = useAddBackBroadcastChannel(dynamicFieldT.kod);

    const getOptionsFromApi = (valueTyped: string) => {
        if (valueTyped != null && valueTyped.length > 0) {
            const params = {
                q: valueTyped,
                typ: value?.select,
                id_pola_z_warunkiem: dynamicFieldT.id
            };
            const formData = createFormData({ type: 'object', data: params });
            getFieldAdditionalData({
                url,
                params: params,
                body: formData
            })
                .then((res) => {
                    if (res.data) {
                        setOptionToChoose(res.data);
                    } else {
                        setOptionToChoose([]);
                    }
                })
                .catch((err) => {
                    setOptionToChoose([]);
                });
        } else {
            setOptionToChoose([]);
        }
    };

    const handleAddNewObject = () => {
        window.open(generateAddBackUrlTwo(dynamicFieldT.kod, fieldValue.value.select));

        startAddBackBroadcastChannel((event: MessageEvent<BroadcastAddBackPayload>) => {
            field.onChange({
                ...field?.value,
                value: {
                    ...value,
                    value: event.data.id,
                    text: event.data.name
                }
            });
            setInputValue(event.data.name);
            getOptionsFromApi(event.data.name);
        });
    };

    return (
        <div className={style.wrapper}>
            <DynamicLabel label={dynamicFieldT.nazwa} />
            <div className={style.box}>
                <UiInputSelectTwo
                    value={value.select}
                    opcje={dynamicFieldT.opcje ? dynamicFieldT.opcje : {}}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value !== value.select) {
                            field.onChange({
                                ...field.value,
                                value: {
                                    isEmptyValue: true,
                                    select: e.target.value,
                                    type: e.target.value,
                                    value: null,
                                    text: ''
                                }
                            });
                            setInputValue('');
                            setOptionToChoose([]);
                        }
                    }}
                />
                <Autocomplete
                    sx={{
                        paddingLeft: '10px'
                    }}
                    freeSolo
                    onInputChange={(_, value: string) => {
                        if (value !== 'Dodaj...') {
                            setInputValue(value);
                            getOptionsFromApi(value);
                        }
                    }}
                    options={optionToChoose || null}
                    value={{
                        id: value.value,
                        text: inputValue
                    }}
                    inputValue={inputValue}
                    getOptionLabel={(option: any) => {
                        return option?.text;
                    }}
                    renderOption={(props, option: any) => {
                        return (
                            <li {...props} key={option?.id}>
                                {option?.text}
                            </li>
                        );
                    }}
                    disablePortal={true}
                    onChange={(
                        _: React.SyntheticEvent<Element, Event>,
                        newValue: { id: string; text: string } | null | string
                    ) => {
                        if (newValue && typeof newValue !== 'string') {
                            if (newValue.id === 'nowy') {
                                handleAddNewObject();
                                return;
                            }
                            field.onChange({
                                ...field?.value,
                                value: {
                                    ...value,
                                    value: newValue.id,
                                    text: newValue.text
                                }
                            });
                        } else {
                            field.onChange({
                                ...field?.value,
                                value: {
                                    ...value,
                                    value: null,
                                    text: ''
                                }
                            });
                        }
                    }}
                    clearIcon={
                        inputValue ? (
                            <ButtonCross
                                onClick={() => {
                                    field.onChange({
                                        ...field?.value,
                                        value: {
                                            ...value,
                                            value: null,
                                            text: ''
                                        }
                                    });
                                }}
                                style={{
                                    top: '-4px'
                                }}
                            />
                        ) : (
                            <></>
                        )
                    }
                    classes={{
                        option: classes.option,
                        listbox: classes.listbox,
                        paper: classes.paper,
                        popper: classes.popper
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (dynamicFieldT.dodanie_nowego === 't')
                            filtered.push({ id: 'nowy', text: 'Dodaj...' });

                        return filtered;
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => {
                        return <UiAutocompleteInputTwo params={params} />;
                    }}
                />
            </div>
        </div>
    );
};
