import React from 'react';
import { LoginLayoutWrapper } from '../../molecules/loginLayoutWrapper/LoginLayoutWrapper';
import LoginHeader from '../../organisms/loginHeader/LoginHeader';
import LoginMiddleSection from '../../organisms/loginMiddleSection/LoginMiddleSection';
import LoginFooter from '../../organisms/loginFooter/LoginFooter';

const LoginView = () => {
    return (
        <LoginLayoutWrapper>
            <LoginHeader />
            <LoginMiddleSection />
            <LoginFooter />
        </LoginLayoutWrapper>
    );
};

export default LoginView;
