import { AlertsResponse } from 'src/store/src/general/alerts/types';
import { BaseApiResponse } from 'src/api/types';

/**
 * Fetches information about actual system configuration
 */

export async function getAlerts(): Promise<BaseApiResponse<AlertsResponse>> {
    const response = await fetch('api_react/src/shared/getAlerts.php', {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const dataPrimary = await response.text();

    if (dataPrimary === 'Unauthorized access!') {
        return {
            data: null,
            errorCode: 1,
            success: false,
            message: 'Unauthorized access!'
        };
    }

    const dataParsed = JSON.parse(dataPrimary);
    try {
        return dataParsed;
    } catch (e) {
        return { data: null, errorCode: 500, success: false, message: dataParsed };
    }
}
