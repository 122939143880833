import { unmaskUrl } from 'src/api/src/shared/unmaskUrl';
import { SafeNavigateFunction } from 'src/hooks/src/shared/types';

export const navigateByCode = async (
    code: string,
    navigate: SafeNavigateFunction,
    replace: boolean = false
) => {
    const apiResponse = await unmaskUrl(code);

    if (!apiResponse.success) {
        return apiResponse;
    }

    navigate(apiResponse.data.url, apiResponse.data.locationState, undefined, undefined, replace);
    return apiResponse;
};
