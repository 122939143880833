import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BugdetSettlementMethodLevel, BugdetYearLevel } from 'src/store/src/budget/budget/types';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { createKeySumBudget } from 'src/utils/src/budget/createKeySumBudget';
import { getStableFieldsSums } from 'src/utils/src/budget/getStableFieldsSums';

type BudgetSetterStateSumBeforeYearProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};
export class BudgetSetterStateSumBeforeYear extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetSetterStateSumBeforeYearProps) {
        super({ budget, operationalState });
    }

    execute(method: BugdetSettlementMethodLevel) {
        method.years.forEach((year) => {
            if (year.name === 'lata poprzednie' || year.tempSums) {
                if (!year.months) {
                    this.setSumBeforeYear({
                        medhodId: method.id,
                        bugdetYearLevel: year
                    });
                }
            }
        });
    }
    private setSumBeforeYear({
        medhodId,
        bugdetYearLevel
    }: {
        medhodId: string;
        bugdetYearLevel: BugdetYearLevel;
    }) {
        if (bugdetYearLevel.tempSums) {
            const dataStable = getStableFieldsSums({ type: 'rok' });

            if (dataStable.isSum) {
                dataStable?.fields?.forEach((field) => {
                    switch (field.id) {
                        case '12':
                            {
                                const { keySum } = createKeySumBudget({
                                    methodId: medhodId,
                                    fieldId: field.id,
                                    methodLevelType: 'rok'
                                });

                                this.operationalState.sumTemp[keySum] = {
                                    liczba: String(bugdetYearLevel.tempSums?.netto ?? 0)
                                };
                            }
                            break;
                        case '15':
                            {
                                const { keySum } = createKeySumBudget({
                                    methodId: medhodId,
                                    fieldId: field.id,
                                    methodLevelType: 'rok'
                                });

                                this.operationalState.sumTemp[keySum] = {
                                    liczba: String(bugdetYearLevel.tempSums?.brutto ?? 0)
                                };
                            }
                            break;
                    }
                });
            }
        }
    }
}
