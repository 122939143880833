import { NavigateFunction, NavigateOptions } from 'react-router-dom';

export const navigateWithTranslate = (
    navigate: NavigateFunction,
    url: string,
    options?: NavigateOptions
) => {
    const prams = new URLSearchParams(window.location.search); // get search params
    navigate(
        prams.has('translate') ? createUrlWhenTranslate(url, prams.get('translate')) : url,
        options
    );
};

const createUrlWhenTranslate = (url: string, translateValue: string | null) => {
    if (url === '/start') {
        return `/link?code=${translateValue}`;
    }

    const splittedUrl = url.split('?'); // split path and params

    return `${splittedUrl[0]}?translate=${translateValue}&${splittedUrl.slice(1).join('?')}`;
};
