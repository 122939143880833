import { batch } from 'react-redux';

import * as actionTypes from '../../constants/actions';
import { socket, emitSocketEvent } from '../../sockets';
import { getChatByWindowID } from '../../../shared/utils';
import { setChatOpen, addGroup, setNewMessage, closeChatWindow, setDisconnected } from './index';
import rustle from '../../assets/rustle.mp3';
import { playSound } from '../../../shared/utils';
import { openWindowsKey } from '../../constants/storageKeys';

const rustleSound = new Audio(rustle);

let tempRoomID = 1;

/**
 * @function ActionCreators.resetAppState
 * @description Akcja resetuje stan czatu i zamyka wszystkie czaty.
 * @returns {ReduxAction}
 */
export const resetAppState = () => ({ type: actionTypes.RESET_APP_STATE });

/**
 * @function ActionCreators.createGroup
 * @description Akcja sprawdza czy grupa o podanej nazwie istnieje w grupach użytkownika. Ustawia błąd aplikacji jeśli istnieje, w przeciwnym wypadku
 * uruchamia szereg akcji związanych z utworzeniem grupy
 * @param {string} name - nazwa nowej grupy
 * @param {string[]} users - identyfikatory użytkowników dodanych do grupy
 * @returns {ThunkAction}
 */
export const createGroup = (name, users) => (dispatch, getState) => {
    const id = 'temp_group_' + tempRoomID++;
    const {
        chatUser: { groups }
    } = getState();
    const groupNames = Object.keys(groups).map((key) => groups[key].name.toLowerCase());
    const nameError = groupNames.includes(name.toLowerCase());

    if (nameError) {
        const error = {
            type: 'Niewłaściwa nazwa',
            message: 'Grupa o tej nazwie już istnieje'
        };

        dispatch(setError(error));
    } else {
        const group = {
            type: 'group',
            name,
            id,
            users: users.map((m) => m.id)
        };

        batch(() => {
            dispatch(addGroup(group));
            dispatch(openChatWindow(group.id));
            dispatch(setActiveChatWindow(group.id));
        });
    }
};

/**
 * @function ActionCreators.createsetErrorGroup
 * @description Akcja ustawia stan błędu aplikacji. Resetuje, zamyka aplikacje i rozłącza połączenie z serwerem jeśli flaga critical jest ustawiona na true.
 * @param {object} error - obiekt błędu
 * @param {string} error.type - wiadomość o typie błędu
 * @param {string} error.message - tekst błędu
 * @param {boolean} error.critical - flaga wyznaczająca czy trzeba zresetować aplikację
 * @returns {ThunkAction}
 */
export const setError = (error) => (dispatch) => {
    batch(() => {
        if (error.critical) {
            /* disconnect form socket.io server */
            socket.disconnect();
            dispatch(resetAppState());
            dispatch(setChatOpen(false));
            dispatch(setDisconnected(true));
        }
        dispatch({ type: actionTypes.SET_ERROR, payload: { error } });
    });
};

/**
 * @function ActionCreators.setActiveChatWindow
 * @description Akcja wywoływana po kliknięciu w okno. Ustawia identyfikator okna jako aktywne okno. Dodatkowo jeśli stan danego czatu jest ustawiony
 * na newMessage, i czat nie jest przekrolowany do góry, usuwa ten stan
 * @param {string|null} id - id klikniętego okna
 * @returns {ThunkAction}
 */
export const setActiveChatWindow = (id) => (dispatch, getState) => {
    const state = getState();

    if (id === null) {
        if (!state.app.activeWindow) {
            return;
        }

        return dispatch({ type: actionTypes.SET_ACTIVE_CHAT_WINDOW, payload: { id } });
    }
    const chat = getChatByWindowID(state, id);

    if (chat && chat.newMessage) {
        emitSocketEvent('update_last_read', { roomID: chat.id });
    }
    batch(() => {
        if (chat && !chat.scrolledUp && chat.newMessage) {
            dispatch(setNewMessage(chat.id, false));
        }

        dispatch({ type: actionTypes.SET_ACTIVE_CHAT_WINDOW, payload: { id } });
    });
};

/**
 * @function ActionCreators.prependChatWindow
 * @description Akcja wywoływana po kliknięciu w czat w panelu ukrytych czatów. Przenosi okno czatu na początek otwartych okien. Ustawai stan okna na aktywny.
 * Dodatkowo jeśli stan danego czatu jest ustawiony na newMessage, i czat nie jest przekrolowany do góry, usuwa ten stan
 * @param {string} id - id klikniętego okna
 * @returns {ThunkAction}
 */
export const prependChatWindow = (id) => (dispatch, getState) => {
    const chat = getChatByWindowID(getState(), id);

    batch(() => {
        if (chat && !chat.scrolledUp && chat.newMessage) {
            dispatch(setNewMessage(chat.id, false));
        }

        dispatch({
            type: actionTypes.PREPEND_CHAT_WINDOW,
            payload: { id }
        });
    });
};

/**
 * @function ActionCreators.openChatWindow
 * @description Otwiera okno z czatem, i ustawia je jako aktywne
 * @param {string} id - id klikniętego elementu listy z czatami.
 * @returns {ThunkAction}
 */
export const openChatWindow = (id) => (dispatch, getState) => {
    const {
        app: { sounds }
    } = getState();

    if (sounds) {
        playSound(rustleSound);
    }

    batch(() => {
        dispatch({
            type: actionTypes.OPEN_CHAT_WINDOW,
            payload: { id }
        });
        dispatch(setActiveChatWindow(id));
    });
};

/**
 * @function ActionCreators.deleteGroup
 * @description Zamyka okno z czatem grupy i usuwa grupę z grup użytkownika
 * @param {string} id - id usuniętej grupy.
 * @returns {ThunkAction}
 */
export const deleteGroup = (id) => (dispatch) => {
    batch(() => {
        dispatch(closeChatWindow(id));
        dispatch({
            type: actionTypes.DELETE_GROUP,
            payload: { id }
        });
    });
};

/**
 * @function ActionCreators.setConfig
 * @description Ustawia informacje na temat użytkownika i aplikacji pobrane z systemu
 * @param {object} config - obiekt z informacjami.
 * @param {string} config.userID - id użytkownika
 * @param {string} config.userType - typ użytkownika
 * @param {string} config.system_url - url systemu
 * @param {string} config.node_url - url serwera nodejs
 * @param {string} config.sessionID - id sesji
 * @returns {ReduxAction}
 */
export const setConfig = (config) => ({ type: actionTypes.SET_CONFIG, payload: { config } });

/**
 * @function ActionCreators.deleteGroup
 * @description Przywraca stan czatu z przed reloadu strony
 * @returns {ThunkAction}
 */
export const recreateChat = () => (dispatch, getState) => {
    const { users, groups } = getState().chatUser;
    const windowsToOpen = JSON.parse(sessionStorage.getItem(openWindowsKey));

    // closing chat sets windowsToOpen to null, so if not null open chat
    if (windowsToOpen) {
        dispatch(setChatOpen(true));
        emitSocketEvent('open_app');

        windowsToOpen.reverse().forEach((windowID) => {
            const chatInfo = users[windowID] || groups[windowID];

            // it filters temporary groups, which cant be recreated
            if (chatInfo) {
                const { id, roomID } = chatInfo;
                // roomID means chat exists in database
                if (roomID) {
                    emitSocketEvent('open_chat', { roomID: roomID, windowID: id });
                }

                dispatch({
                    type: actionTypes.OPEN_CHAT_WINDOW,
                    payload: { id }
                });
            }
        });
    }
};
