export const adjustStr = (str: string) => {
    const newStr = [];
    const words = str.split(' ');
    let firstStr = '';
    let secondStr = '';

    if (words.length < 3) {
        return words;
    }
    const lengthAllStr = words.reduce(
        (accumulator, currentValue) => accumulator + currentValue
    ).length;
    words.forEach((item) => {
        if (firstStr.length > lengthAllStr / 2) {
            secondStr = secondStr + ' ' + item;
        } else {
            firstStr = firstStr + ' ' + item;
        }
    });
    if (secondStr) {
        newStr.push(firstStr, secondStr);
    } else {
        newStr.push(firstStr);
    }
    return newStr;
};
