export function sortArrayOfObject<T, K extends keyof T>(values: T[], key: K, type: 'ASC' | 'DESC') {
    if (type === 'ASC') {
        return values.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        });
    } else {
        return values.sort((a, b) => {
            if (a[key] < b[key]) {
                return 1;
            }
            if (a[key] > b[key]) {
                return -1;
            }
            return 0;
        });
    }
}
