import {
    BudgetScreenState,
    HoursLimitData,
    SettledSingleElements,
    SettleObject
} from 'src/store/src/budget/budget/types';
import { updateMemoMonthKeyInBudget } from './updateMemoMonthKeyInBudget';

export function toggleLastThreeMonthHiddenSettledObject(state: BudgetScreenState) {
    return state.settlementMethods.map((method) => {
        return {
            ...method,
            years: method.years.map((year) => {
                const monthLenght = year?.months?.length ?? 0;
                return {
                    ...year,
                    months: year?.months?.map((month, monthKey) => {
                        return {
                            ...month,
                            memoKey: updateMemoMonthKeyInBudget(month.memoKey),
                            settledElements: month?.settledElements?.map((settledElement) => {
                                return {
                                    ...settledElement,
                                    objects: settledElement?.objects?.map((object) =>
                                        handleObjectVisibilityOnChangeLastThreeMonth(
                                            object,
                                            monthKey,
                                            monthLenght
                                        )
                                    )
                                };
                            }),
                            hoursLimit: handleHoursLimitVisibilityOnChangeLastThreeMonth(
                                month?.hoursLimit,
                                monthKey,
                                monthLenght
                            ),
                            settledSingleElements:
                                handleSingleElementsVisibilityOnChangeLastThreeMonth(
                                    month.settledSingleElements,
                                    monthKey,
                                    monthLenght
                                ),
                            cases: month?.cases?.map((caseItem) => {
                                return {
                                    ...caseItem,
                                    settledElements: caseItem?.settledElements?.map(
                                        (settledElement) => {
                                            return {
                                                ...settledElement,
                                                objects: settledElement?.objects?.map((object) =>
                                                    handleObjectVisibilityOnChangeLastThreeMonth(
                                                        object,
                                                        monthKey,
                                                        monthLenght
                                                    )
                                                )
                                            };
                                        }
                                    )
                                };
                            }),
                            projects: month?.projects?.map((project) => {
                                return {
                                    ...project,
                                    settledElements: project?.settledElements?.map(
                                        (settledElement) => {
                                            return {
                                                ...settledElement,
                                                objects: settledElement?.objects?.map((object) =>
                                                    handleObjectVisibilityOnChangeLastThreeMonth(
                                                        object,
                                                        monthKey,
                                                        monthLenght
                                                    )
                                                )
                                            };
                                        }
                                    )
                                };
                            })
                        };
                    })
                };
            })
        };
    });
}

function handleObjectVisibilityOnChangeLastThreeMonth(
    object: SettleObject,
    monthKey: number,
    monthLenght: number
) {
    if (object.isCanChangeVisibility && monthKey >= monthLenght - 5) {
        return {
            ...object,
            hidden: !object.hidden
        };
    }
    return {
        ...object
    };
}

function handleSingleElementsVisibilityOnChangeLastThreeMonth(
    el: SettledSingleElements,
    monthKey: number,
    monthLenght: number
): SettledSingleElements {
    const data = {
        ...el
    };

    for (const key in el) {
        const settleElement = el?.[key as keyof SettledSingleElements];
        if (settleElement.isCanChangeVisibility && monthKey >= monthLenght - 5) {
            data[key as keyof SettledSingleElements] = {
                ...settleElement,
                hidden: !settleElement.hidden
            };
        }
    }
    return data;
}

function handleHoursLimitVisibilityOnChangeLastThreeMonth(
    hoursLimit: HoursLimitData | undefined,
    monthKey: number,
    monthLenght: number
) {
    if (hoursLimit) {
        if (hoursLimit.isCanChangeVisibility && monthKey >= monthLenght - 5) {
            return {
                ...hoursLimit,
                hidden: !hoursLimit.hidden
            };
        }
        return hoursLimit;
    }
}
