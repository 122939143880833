import {
    BugdetFieldStructure,
    BugdetSettlementMethodLevel,
    RateCategory
} from 'src/store/src/budget/budget/types';
import {
    BudgetHandlerStateGlobalFnExecuteProps,
    BudgetHandlerStateGlobalFnExecuteSharedProps
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { getVat } from 'src/utils/src/shared/getVat';

// Class working on only onChange

type BudgetHandlerCalcNewStakeWorkerProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {
    method?: BugdetSettlementMethodLevel;
};

export class BudgetHandlerCalcNewStakeWorker extends BudgetSetterOperationalState {
    stakeId: string;
    rateCategory?: RateCategory;
    method?: BugdetSettlementMethodLevel;
    constructor(props: BudgetHandlerCalcNewStakeWorkerProps) {
        super(props);
        this.method = props.method;
        this.stakeId = '';
    }

    execute(data: BudgetHandlerStateGlobalFnExecuteProps) {
        this.setFromWorkerStakeId(data);
        this.setStakeData();
        this.setToOSStructure('zadania');
        this.setToOSFieldsStructure({
            settled: false,
            code: 'zadania'
        });
        this.updateData(data);
    }

    private setFromWorkerStakeId(data: BudgetHandlerStateGlobalFnExecuteProps) {
        const idWorker = this.getValueNumberFromOSByKeyField(data.keyField);
        this.stakeId = this.budget.employeesRateCategories[String(idWorker)];
    }

    private setStakeData() {
        if (this.method) {
            this.rateCategory = this.method.rateCategories?.[this.stakeId];
        }
    }

    private updateData(data: BudgetHandlerStateGlobalFnExecuteProps) {
        if (!this.rateCategory) {
            return;
        }

        let hours = 0;
        let vat = 1;

        let field: BugdetFieldStructure;
        for (field of this.operationalState.fieldsStructure) {
            if (field.onlyText) {
                continue;
            }
            switch (field.id) {
                case '7':
                    hours = this.getValueNumberFromStatePrimary({
                        ...data,
                        fieldId: field.id
                    });
                    break;
                case '9':
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            liczba: String(this.rateCategory.stawka)
                        },
                        updateReset: true
                    });
                    break;
                case '10':
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            liczba: String(this.rateCategory.kurs)
                        },
                        updateReset: true
                    });
                    break;
                case '11':
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            tekst: `${this.rateCategory.stawka_waluta}/${this.method?.waluta} ${this.rateCategory.kurs}`
                        },
                        updateReset: true
                    });
                    break;
                case '12': {
                    const value = this.rateCategory.stawka * hours * this.rateCategory.kurs;
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            liczba: String(value)
                        },
                        updateReset: true
                    });
                    break;
                }
                case '13':
                    vat = getVat(
                        this.getValueNumberFromStatePrimary({
                            ...data,
                            fieldId: field.id
                        })
                    );
                    break;
                case '14':
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            tekst: this.rateCategory.stawka_waluta
                        },
                        updateReset: true
                    });
                    break;
                case '15': {
                    const value = this.rateCategory.stawka * hours * this.rateCategory.kurs * vat;
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            liczba: String(value)
                        },
                        updateReset: true
                    });
                    break;
                }
                default:
                    break;
            }
        }
    }
}
