import React, { FC } from 'react';
import { Button, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getColorForInputWithAllFlag } from 'src/styles/getColor';
import styles from './UiFieldFIle.module.css';
import { UIFieldFileProps } from 'src/components/popupFormExtended/dynamicField/fieldFile/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { fields } from 'src/constants/subtitles';
import { UiFrameDash } from 'src/components/popupFormExtended/dynamicField/shared/uiFrameDash/UiFrameDash';
import { UiLabel } from 'src/components/shared/atoms/uiInputs/uiLabel/UiLabel';

export const UIFieldFile: FC<UIFieldFileProps> = (props) => {
    const { value, onChange, onChangeNameFile, uiFieldCommonProps, uiFieldSharedProps, progress } =
        props;

    const inputRef = React.useRef<null | HTMLInputElement>(null);
    const theme = useTheme();

    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            onChange(e.dataTransfer.files[0]);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            onChange(e.target.files[0]);
        }
    };

    const onButtonClick = () => {
        inputRef?.current?.click();
    };
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div onDragEnter={handleDrag}>
                <UiFrameDash>
                    <input
                        type="file"
                        id="input-file-upload"
                        className={styles.inputfileupload}
                        ref={inputRef}
                        onChange={handleChange}
                        accept={uiFieldSharedProps.dynamicFieldT.plik_akceptowany_typ}
                    />
                    <label
                        id="drag-file-element"
                        className={styles.box}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}>
                        <div
                            className={styles.dropzone}
                            style={{
                                border: `solid 1px ${getColorForInputWithAllFlag({
                                    theme,
                                    flag: { ...uiFieldCommonProps }
                                })}`
                            }}>
                            <UiLabel label={fields.fieldFile.field} />
                        </div>
                        <Button
                            variant="contained"
                            onClick={onButtonClick}
                            sx={{
                                color: (theme) => theme.palette.background.default,
                                padding: '6px 16px',
                                cursor: 'pointer'
                            }}>
                            <p className={`${styles.button}`}>{fields.fieldFile.button}</p>
                        </Button>
                    </label>
                    <div className={styles.box2}>
                        <p className={styles.fileName}>{value?.originalFileName}</p>
                        {value.loading && (
                            <div className={styles.progressWrapper}>
                                <LinearProgress
                                    variant={progress ? 'determinate' : undefined}
                                    value={progress}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <UiFieldLabel
                            {...uiFieldCommonProps}
                            {...uiFieldSharedProps}
                            name={fields.fieldFile.fileNameInput}
                            isMandatory={false}
                        />
                        <UiInputNormalTwo
                            value={value?.name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onChangeNameFile(e, false)
                            }
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onChangeNameFile(e, true)
                            }
                        />
                    </div>
                </UiFrameDash>
            </div>
        </UiWrapper>
    );
};
