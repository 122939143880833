import { CalendarEvent } from '../../api/types';
import { CalendarEventProps } from '../../components/calendar/molecules/calendarEvent/types';
import { CalendarDays } from '../../store/src/calendar/calendar/types';
import { Categories } from '../../store/src/calendar/categories/types';
import { Department, Resource } from '../../store/src/calendar/resources/types';
import { SYSTEM_COLORS } from '../../styles/systemColors';

export const categories: Categories = [
    { id: 1, name: 'spotkania', color: SYSTEM_COLORS.CyanLight, selected: false },
    { id: 2, name: 'rozprawy', color: SYSTEM_COLORS.Info, selected: false },
    { id: 3, name: 'zadania', color: SYSTEM_COLORS.Teal, selected: false },
    { id: 4, name: 'urlopy', color: SYSTEM_COLORS.Purple, selected: false },
    { id: 5, name: 'działania', color: SYSTEM_COLORS.BlueGray, selected: false },
    { id: 6, name: 'ryczałty', color: SYSTEM_COLORS.DeepOrange, selected: false },
    { id: 7, name: 'upadki', color: SYSTEM_COLORS.Error, selected: false },
    { id: 8, name: 'porady', color: SYSTEM_COLORS.LightGreen, selected: false }
];

export const employees: Resource[] = [
    { id: 1, initials: 'KS', departments: [1, 2], selected: true, name: 'Kuba Sułkowski' },
    { id: 2, initials: 'KO', departments: [1], selected: false, name: 'Krzysztof Ogorzałek' },
    { id: 3, initials: 'MO', departments: [2], selected: false, name: 'Marcin Ogorzałek' },
    { id: 4, initials: 'KCh', departments: [2], selected: false, name: 'Kamil Chojnacki' },
    { id: 5, initials: 'ŁG', departments: [2], selected: false, name: 'Łukasz Gmur' },
    { id: 6, initials: 'AF', departments: [1, 2], selected: false, name: 'Ada Fijałkowska' }
];

export const departments: Department[] = [
    { id: 1, selected: false, name: 'Produkcja' },
    { id: 2, selected: false, name: 'Sprzedaż' }
];

export const calendarEventProps: CalendarEventProps = {
    offsetLeft: 0,
    width: 50,
    height: 100,
    participants: [1, 2],
    eventColor: SYSTEM_COLORS.DeepOrange,
    id: 234,
    offsetTop: 0,
    canceled: false,
    type: 'zadania',
    title: 'Sprawdzenie stanu kosztów',
    description: `Nazwa zadania: Sprawdzenie stanu kosztów
Czego dotyczy: Sprawy: Odszkodowanie Ryś vs UMiG Szczebrzeszyn
Wykonujący: Kuba Kuba
Status: w toku
Termin wykonania: 07-04-2021 16:30
Data realizacji: 07-04-2021 16:30
Poświęcony czas: 3,5
Zlecający:
Planowana liczba godzin: 0,
Data rozpoczęcia: 07-04-2021 13:00`
};

export const calendarEventsData: CalendarEvent[] = [
    {
        canceled: false,
        noGrid: false,
        startTime: new Date(2020, 0, 2, 13, 15).getTime(),
        endTime: new Date(2020, 0, 2, 13, 45).getTime(),
        startHour: '13:15',
        endHour: '13:45',
        color: SYSTEM_COLORS.Brown,
        status: 'aktywny',
        statusColor: '000',
        timeSpent: 600,
        workEndHour: 13,
        workStartHour: 13,
        workEndMinute: 45,
        workStartMinute: 15,
        participants: [1, 2],
        type: 'zadania',
        id: 234,
        name: 'test1',
        description: 'test1',
        rozliczane: true
    },
    {
        canceled: false,
        noGrid: true,
        startTime: new Date(2020, 0, 2, 13, 15).getTime(),
        endTime: new Date(2020, 0, 3, 13, 45).getTime(),
        startHour: '13:15',
        endHour: '13:45',
        color: SYSTEM_COLORS.Brown,
        status: 'aktywny',
        statusColor: '000',
        timeSpent: 600,
        workEndHour: 13,
        workStartHour: 13,
        workEndMinute: 45,
        workStartMinute: 15,
        participants: [1, 2],
        type: 'zadania',
        id: 235,
        name: 'test2',
        description: 'test2',
        rozliczane: true
    }
];

export const days: CalendarDays = [
    {
        number: 1,
        selected: false,
        startTimestamp: new Date(2020, 0, 1).getTime(),
        endTimestamp: new Date(2020, 0, 2).getTime() - 1,
        weekDay: 'Śr',
        when: 'past',
        holiday: false
    },
    {
        number: 2,
        selected: false,
        startTimestamp: new Date(2020, 0, 2).getTime(),
        endTimestamp: new Date(2020, 0, 3).getTime() - 1,
        weekDay: 'Czw',
        when: 'past',
        holiday: false
    },
    {
        number: 3,
        selected: false,
        startTimestamp: new Date(2020, 0, 3).getTime(),
        endTimestamp: new Date(2020, 0, 4).getTime() - 1,
        weekDay: 'Pt',
        when: 'past',
        holiday: false
    },
    {
        number: 4,
        selected: false,
        startTimestamp: new Date(2020, 0, 4).getTime(),
        endTimestamp: new Date(2020, 0, 5).getTime() - 1,
        weekDay: 'Sb',
        when: 'past',
        holiday: false
    },
    {
        number: 5,
        selected: false,
        startTimestamp: new Date(2020, 0, 5).getTime(),
        endTimestamp: new Date(2020, 0, 6).getTime() - 1,
        weekDay: 'Nd',
        when: 'past',
        holiday: true
    }
];
