import React, { FC } from 'react';

import { PopupContentTypeWew2fa } from 'src/components/shared/atoms/popupContentTypeWew2fa/PopupContentTypeWew2fa';
import { PopupContentTypeWewInfoChangePassword } from 'src/components/shared/molecules/popupContentTypeWewInfoChangePassword/PopupContentTypeWewInfoChangePassword';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import { dispatch } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { PopupContentInfo } from '../popupContentInfo/PopupContentInfo';
import { PopupContentInfoAlertUpdateMessage } from 'src/components/shared/molecules/popupContentInfoAlertUpdateMessage/PopupContentInfoAlertUpdateMessage';

/**
 * Point of rendering all of popups that can be fired as popupAlerts, note that every of that popups should on his close fire "dispatch(clearFirst());"
 */
export const PopupContentAlert: FC<BasePopupContentProps> = (props) => {
    if (!props.popup.alertData) return null;
    switch (props.popup.alertData.type) {
        case 'changePasswordAlert':
            return (
                <PopupContentTypeWewInfoChangePassword
                    {...props}
                    alertData={props.popup.alertData}
                />
            );
        case '2fa':
            return <PopupContentTypeWew2fa {...props} alertData={props.popup.alertData} />;
        case 'message':
            return <PopupContentInfo {...props} />;
        case 'updatesMessage':
            return (
                <PopupContentInfoAlertUpdateMessage {...props} alertData={props.popup.alertData} />
            );
        default:
            dispatch(closePopup(props.popup.key));
            return <></>;
    }
};
