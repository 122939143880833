import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(({ palette, spacing }) => ({
    timer_open_icon: {
        fontSize: spacing(2.8),
        color: palette.primary.main
    }
}));
/**
 * Bezstanowy komponent funkcyjny. Przycisk do otwierania widżetu stoperów.
 */

type Props = {
    handleOpen: () => {};
    open: boolean;
};

export const CloseArrow: FC<Props> = ({ handleOpen, open }) => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <Box
            onClick={handleOpen}
            sx={{
                position: 'absolute',
                transform: 'translateX(-100%)',
                background: '#f7f7f7',
                cursor: 'pointer',
                paddingTop: '3px',
                border: '1px solid #ccc',
                borderRight: 'none',
                boxShadow: '-1px 3px 5px -2px rgb(0 0 0 / 50%)'
            }}>
            {open && (
                <ArrowIcon
                    className={classes.timer_open_icon}
                    style={{
                        color: theme.palette.primary.main
                    }}
                />
            )}
        </Box>
    );
};
