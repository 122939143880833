import React, { FC } from 'react';

import { NormalScroll } from 'src/components/shared/molecules/normalScroll/NormalScroll';
import { useWindowSize } from 'react-use-size';
import { ContentScrollTwoProps } from 'src/components/shared/molecules/contentScrollTwo/types';

const ContentScrollTwo: FC<ContentScrollTwoProps> = ({
    children,
    maxHeight,
    maxWidth,
    breakpointHeight,
    breakpointWidth,
    isFixedWidth,
    availableWidthFromParent,
    availableHeightFromParent,
    styleInnerWrapper,
    styleOuterWrapper
}) => {
    const { height: heightWindow, width: widthWindow } = useWindowSize();

    const marginWidth = breakpointWidth - maxWidth;
    const marginHeight = breakpointHeight - maxHeight;
    const availableWidth = widthWindow > breakpointWidth ? maxWidth : widthWindow - marginWidth;
    const availableHeight =
        heightWindow > breakpointHeight ? maxHeight : heightWindow - marginHeight;

    return (
        <NormalScroll
            width={`${availableWidthFromParent ? availableWidthFromParent : availableWidth}px`}
            height={`${availableHeightFromParent ? availableHeightFromParent : availableHeight}px`}
            styleOuterWrapper={{ display: 'flex', justifyContent: 'center', ...styleOuterWrapper }}
            styleInnerWrapper={styleInnerWrapper}>
            <div
                style={{
                    minWidth: `${isFixedWidth ? maxWidth - 10 + 'px' : 'auto'}`,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                {React.cloneElement(children, {
                    availableWidth,
                    availableHeight,
                    ...children.props
                })}
            </div>
        </NormalScroll>
    );
};
export default ContentScrollTwo;
