import { useEffect } from 'react';
import { loadInitialData } from 'src/store/src/general/index';
import { AppDispatch } from 'src/store/src/general/index';

/**
 * Fetched and dispatches all data for the calendar.
 * @param dispatch - takes dispatch funtion as a param to facilitate tests.
 */

export function useLoadInitialData(dispatch: AppDispatch) {
    useEffect(() => {
        dispatch(loadInitialData());
    }, [dispatch]);
}
