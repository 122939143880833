import React, { ReactNode } from 'react';
import styles from './GrowWrapper.module.css';

type GrowWrapperProps = {
    children: ReactNode;
};
const GrowWrapper = (props: GrowWrapperProps) => {
    return <div className={styles.box}>{props.children}</div>;
};

export default GrowWrapper;
