import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from 'src/store';

/**
 * Hook restricting access to component calling this hook, when no user is logged to application
 */
export const useRestrictforNotLogged = () => {
    const navigate = useNavigate();
    const isLogged = useTypedSelector((state) => state.user.isLogged);

    useEffect(() => {
        if (isLogged === false) {
            navigate('/login');
        }
    }, [isLogged, navigate]);
};
