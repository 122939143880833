import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { UIFieldNip } from 'src/components/popupFormExtended/dynamicField/fieldNip/UIFieldNip';
import { FieldNipObjectProps } from 'src/components/popupFormExtended/dynamicField/fieldNip/types';
import { InputValidator } from 'src/components/popupFormExtended/dynamicField/shared/InputValidator';
import { DynamicFormObjectGusDataLoader } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectGusDataLoader';
import { dataSetInputKey } from 'src/constants/dataset';
import { FormContext } from '../../dynamicFormObject/DynamicFormContextProvider';

export const FieldNipObject: FC<FieldNipObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    popupFormExtendedData,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);
    const [infoText, setInfoText] = React.useState<null | string>(null);
    const { formState } = React.useContext(FormContext);

    const loadDataFromNipEvent = async () => {
        if (state) {
            setInfoText('Prosimy czekać, trwa komunikacja z GUS...');
            const res = await new DynamicFormObjectGusDataLoader(
                dispatch,
                dynamicFieldT.id,
                formState,
                popupFormExtendedData
            ).execute(state, uiFieldCommonProps?.usedForAddingObject ? 'add' : 'edit');
            if (res.success) {
                if (res.isData) {
                    setInfoText(null);
                } else {
                    setInfoText('Brak obiektu w bazie');
                }
            } else {
                setInfoText(res.message);
            }
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        const checkedValue = new InputValidator({
            dynamicFieldT,
            oldValue: state,
            staticActions: ['runSize'],
            newValue: e.target.value
        }).execute();

        setState(checkedValue);
        if (onBlur) {
            dispatch({
                type: 'nip',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: checkedValue
                    }
                }
            });
        }
    };

    React.useEffect(() => {
        if (field.value !== state) {
            setState(field.value);
        }
    }, [field.value]);

    return (
        <UIFieldNip
            value={state}
            infoText={infoText}
            onButtonClick={loadDataFromNipEvent}
            onChange={(e) => onChange(e, false)}
            onBlur={(e) => onChange(e, true)}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
