import React, { FC } from 'react';

import { FieldListForeignManyUnmodifiedProps } from './types';
import { FieldWrapperUnmodified } from 'src/components/popupFormExtended/dynamicField/shared/fieldWrapperUnmodified/FieldWrapperUnmodified';

export const FieldListForeignManyUnmodified: FC<FieldListForeignManyUnmodifiedProps> = ({
    dynamicFieldT,
    uiFieldCommonProps,
    field
}) => {
    return (
        <FieldWrapperUnmodified
            dynamicFieldT={dynamicFieldT}
            field={field}
            isLabel={Boolean(uiFieldCommonProps?.isLabel)}>
            <div>
                {field.value.values.map((el, i) => (
                    <p key={i}>{field.value.opcje.find((option) => option.id === el.id)?.text}</p>
                ))}
            </div>
        </FieldWrapperUnmodified>
    );
};
