import { NumberManager } from 'src/utils/src/shared/NumberManager';

import { getVat } from 'src/utils';
import { FieldPriceNumberBasicTypeValue } from 'src/data/fieldsFormType';

export type FieldPriceNumberChangeType = 'vat' | 'netto' | 'total_brutto' | 'liczba' | 'waluta';
export class FieldPriceNumberManager {
    newValue: FieldPriceNumberBasicTypeValue;
    changeType: FieldPriceNumberChangeType;
    value: string;
    vatOptional?: string;
    tempNetto: number;
    tempTotalBruttoStr: string;
    tempNettoStr: string;
    constructor({
        oldValue,
        changeType,
        value,
        vatOptional
    }: {
        oldValue: FieldPriceNumberBasicTypeValue;
        changeType: FieldPriceNumberChangeType;
        value: string;
        vatOptional?: string;
    }) {
        this.newValue = oldValue;
        this.changeType = changeType;
        this.value = value;
        this.vatOptional = vatOptional;
        this.tempNetto = 1;
        this.tempTotalBruttoStr = '0';
        this.tempNettoStr = '0';
        this.checkIfOptionalVat();
        this.setTempNetto();
    }

    calc() {
        switch (this.changeType) {
            case 'netto':
                this.checkIfEmptyStringInNumber();
                this.newValue.netto = this.value;
                this.setTempNettoStr();
                this.calcTotalBruttoFromNetto();
                this.setTempTotalBruttoStr();
                this.calcTotalNettoFromTotalBrutto();
                this.calcBruttoFromNetto();
                break;
            case 'liczba':
                this.newValue.liczba = this.value;
                this.calcTotalNettoFromTempNetto();
                this.calcTotalBruttoFromTempNetto();
                break;
            case 'vat':
                this.newValue.vat = this.value;
                this.calcBruttoFromTempNetto();
                this.calcTotalBruttoFromTempNetto();
                break;
            case 'total_brutto':
                this.checkIfEmptyStringInNumber();
                this.newValue.total_brutto = this.value;
                this.setTempTotalBruttoStr();
                this.calcBruttoFromTotalBrutto();
                this.calcTotalNettoFromTotalBrutto();
                this.calcNettoFromTotalNetto();
                break;
            case 'waluta':
                this.newValue.waluta = this.value;
                break;
            default:
                break;
        }
        return this.newValue;
    }

    private setTempTotalBruttoStr() {
        this.tempTotalBruttoStr = this.newValue.total_brutto;
        if (this.tempTotalBruttoStr === '-') {
            this.tempTotalBruttoStr = '-0';
        }
    }
    private setTempNettoStr() {
        this.tempNettoStr = this.newValue.netto;
        if (this.tempNettoStr === '-') {
            this.tempNettoStr = '-0';
        }
    }

    private setTempNetto() {
        const vat = getVat(this.newValue.vat);
        const newValue = Number(this.newValue.brutto) / vat;
        this.tempNetto = newValue;
    }

    private checkIfOptionalVat() {
        if (this.vatOptional) {
            this.newValue.vat = this.vatOptional;
        }
    }

    private checkIfEmptyStringInNumber() {
        if (this.newValue.liczba === '' || this.newValue.liczba === '0') {
            this.newValue.liczba = '1';
        }
    }

    private calcTotalBruttoFromNetto() {
        const newValue = NumberManager.round(
            Number(this.tempNettoStr) * Number(this.newValue.liczba) * getVat(this.newValue.vat),
            2
        );
        this.newValue.total_brutto = String(newValue);
    }

    private calcNettoFromTotalNetto() {
        const newValue = NumberManager.truncate(
            Number(this.newValue.total_netto) / Number(this.newValue.liczba),
            4
        );
        this.newValue.netto = String(newValue);
    }

    private calcBruttoFromTempNetto() {
        const newValue = Number(this.tempNetto) * getVat(this.newValue.vat);

        this.newValue.brutto = String(newValue);
    }

    private calcTotalBruttoFromTempNetto() {
        const newValue = NumberManager.round(
            Number(this.tempNetto) * getVat(this.newValue.vat) * Number(this.newValue.liczba),
            2
        );
        this.newValue.total_brutto = String(newValue);
    }

    private calcTotalNettoFromTempNetto() {
        const newValue = NumberManager.truncate(
            Number(this.tempNetto * Number(this.newValue.liczba)),
            4
        );
        this.newValue.total_netto = String(newValue);
    }

    private calcTotalNettoFromTotalBrutto() {
        const newValue = NumberManager.truncate(
            Number(this.tempTotalBruttoStr) / getVat(this.newValue.vat),
            4
        );
        this.newValue.total_netto = String(newValue);
    }

    private calcBruttoFromNetto() {
        const newValue = Number(this.tempNettoStr) * getVat(this.newValue.vat);
        this.newValue.brutto = String(newValue);
    }

    private calcBruttoFromTotalBrutto() {
        const newValue = Number(this.tempTotalBruttoStr) / Number(this.newValue.liczba);
        this.newValue.brutto = String(newValue);
    }
}
