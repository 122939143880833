import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { GetDate } from 'src/components/shared/atoms/datePicker/DatePicker';
import { FieldDateHourObjectProps } from './types';
import { UiFieldDateHour } from 'src/components/popupFormExtended/dynamicField/fieldDateHour/UiFieldDateHour';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { useGetMinutesOptionsForField } from 'src/hooks/src/shared/useGetMinutesOptionsForField';

export const FieldDateHourObject: FC<FieldDateHourObjectProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const optionsMinutes = useGetMinutesOptionsForField(dynamicFieldT);

    const onChangeDate = (data: GetDate) => {
        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: {
                ...field.value,
                date: data.dateSelectedNormal
            }
        };
        dispatch({
            type: 'data_godzina',
            payload: {
                code: keyOfFieldInState,
                value: newValue
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newValue,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, type: 'hour' | 'minute') => {
        const newValue = {
            ...field,
            value: {
                ...field.value,
                [type]: e.target.value
            }
        };
        dispatch({
            type: 'data_godzina',
            payload: {
                code: keyOfFieldInState,
                value: {
                    ...field,
                    value: {
                        ...field.value,
                        [type]: e.target.value
                    }
                }
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newValue,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };

    return (
        <UiFieldDateHour
            value={field.value}
            onChangeDate={onChangeDate}
            onChangeHour={(e) => onChangeInput(e, 'hour')}
            onChangeMinute={(e) => onChangeInput(e, 'minute')}
            optionsMinutes={optionsMinutes}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
