import startOfDay from 'date-fns/startOfDay';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';

const timeMaxValInMs = 1000 * 60 * 60 * 24;
/**
 * function takes two dates given eiter as Date objects or number timestamp.
 * Second argument can be also expressed as time only number of ms.
 * Takes date from first, time from second and combines them into timestamp,
 * @param date - either Date object, date timestamp
 * @param time - either Date object, date timestamp, or time of the day only as miliseconds
 * @param rounding - integer in range 0 to 30, where: 0 means no rounding, 1 round to closest minute, 30 to half hours
 */
export function joinDateAndTime(date: Date | number, time: Date | number, rounding = 0): number {
    let timeInMsFlag = false;

    if (!Number.isInteger(rounding) || rounding < 0 || rounding > 30)
        throw new TypeError('rounding param must be integer between 0 and 60');

    // start the flag
    if (typeof time === 'number' && time < timeMaxValInMs) {
        timeInMsFlag = true;
    }

    /* Normalization */
    if (typeof date === 'number') date = new Date(date);
    if (typeof time === 'number' && !timeInMsFlag) time = new Date(time);

    // extract day from date;
    const dayStart = startOfDay(date);

    // Handle time as number of ms branch
    if (timeInMsFlag) {
        return dayStart.getTime() + (time as number);
    }

    // extrat day from time date
    const timeDayStart = startOfDay(time);

    // calculate time in ms
    const timeInMs = (time as Date).getTime() - timeDayStart.getTime();

    // handle rounding option
    if (rounding) {
        return roundToNearestMinutes(dayStart.getTime() + timeInMs, {
            nearestTo: rounding
        }).getTime();
    }

    // without rounding
    return dayStart.getTime() + timeInMs;
}
