import { DynamicFieldT } from 'src/data/popupFormTypes';
import { RowDataId, RowData } from 'src/store/src/listScreen/tableData/types';
import { TemplateMainItem } from 'src/utils/src/shared/TemplateMainItem';
import { PopupState } from 'src/store/src/popup/popup/types';
import { DynamicFormHelper } from 'src/components/shared/molecules/dynamicForm/DynamicFormHelper';
import { ObjectSlice } from 'src/store/src/object/object/types';
import {
    DateManager,
    consoleConditionally,
    generateValueFieldFromTemplate,
    getCellRawValueOrTextFromListCellRow,
    normaliseStringNumber,
    safeGetKeyValueOptionsWithAddNewObjectOption,
    sortAutocompleteOptions
} from 'src/utils';
import { DynamicFormObjectDataConverter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectDataConverter';
import {
    DynamicDateHourValue,
    DynamicDateHoursValue,
    DynamicStateFormFields
} from 'src/components/shared/molecules/dynamicForm/types';

const fields: { [key: string]: any } = {
    poswiecanyczas: '0.000',
    datarealizacji: new Date(),
    attachment: '',
    status: false
};

type DynamicStateFormHandlerProps = {
    rowData: RowData[];
    object: ObjectSlice;
    popupState: PopupState;
    fields?: DynamicFieldT[];
    state?: DynamicStateForm;
};

export type DynamicStateForm = {
    [key: string]: DynamicStateFormFields;
};
export class DynamicStateFormHandler {
    fields: DynamicFieldT[];
    rowData: RowData[];
    object: ObjectSlice;
    state: DynamicStateForm;
    popupState: PopupState;
    constructor({ state, fields, rowData, object, popupState }: DynamicStateFormHandlerProps) {
        this.fields = fields ? fields.filter((item) => item.modyfikacja === 1) : [];
        this.state = state ? state : {};
        this.rowData = rowData;
        this.object = object;
        this.popupState = popupState;
    }
    createInitState() {
        this.popupState.objectId.forEach((id) => {
            this.createFields(id);
        });
        return this.state;
    }
    private createFields(id: RowDataId) {
        const state: DynamicStateForm = {};
        this.fields.forEach((field: DynamicFieldT) => {
            state[id + '-' + field.kod + '-' + field.id] = this.setInitValue(field, id);
        });
        this.state = {
            ...this.state,
            ...state
        };
        consoleConditionally(this.fields, 'fields');
        consoleConditionally(state, 'state');
    }

    private setInitValue(field: DynamicFieldT, id: string): DynamicStateFormFields {
        const str = String(field.domyslna_wartosc);
        const templateItem = new TemplateMainItem({ itemStr: str }).getData();
        let value: any;
        if (templateItem.sign === '$') {
            switch (this.popupState.sourceOfTrigger.type) {
                case 'startupCardList':
                    value = getCellRawValueOrTextFromListCellRow({
                        key: templateItem.contentClear,
                        type: 'rawValue',
                        onlyFirstValue: false,
                        cellRow: this.popupState.sourceOfTrigger.cellRow
                    });
                    break;
                default:
                    value = new DynamicFormHelper({
                        objectSlice: this.object,
                        rowData: this.rowData,
                        popupState: this.popupState,
                        rowId: id
                    }).getRawValueOrText({
                        str,
                        sign: '$',
                        type: 'rawValue'
                    });
                    break;
            }
        }
        switch (field.typ) {
            case 'checkbox':
                if (value && (value?.[0] === 1 || value?.[0] === '1')) {
                    return {
                        type: 'checkbox',
                        value: true
                    };
                } else if (field.domyslna_wartosc === 1 || field.domyslna_wartosc === '1') {
                    return {
                        type: 'checkbox',
                        value: true
                    };
                }
                return {
                    type: 'checkbox',
                    value: false
                };
            case 'liczba':
                if (value && value?.[0]?.[templateItem?.contentClear]) {
                    return {
                        type: 'liczba',
                        value: normaliseStringNumber(value?.[0]?.[templateItem?.contentClear])
                    };
                } else if (value && (value[0] || value[0] === 0)) {
                    return {
                        type: 'liczba',
                        value: normaliseStringNumber(value?.[0])
                    };
                }
                return {
                    type: 'liczba',
                    value: '0.500'
                };
            case 'tekst_bez_ograniczen':
                return {
                    type: 'tekst_bez_ograniczen',
                    value: field?.domyslna_wartosc ?? ''
                };
            case 'tekst':
                return {
                    type: 'tekst',
                    value: field?.domyslna_wartosc ?? ''
                };
            case 'lista':
                return {
                    type: 'lista',
                    value: field?.domyslna_wartosc
                };
            case 'data': {
                if (field.domyslna_dzisiejsza === 't') {
                    return {
                        type: 'data',
                        value: new Date()
                    };
                } else {
                    return {
                        type: 'data',
                        value: null
                    };
                }
            }
            case 'data_godzina': {
                let data: DynamicDateHourValue = {
                    type: 'data_godzina',
                    value: {
                        date: null,
                        hour: '00:00'
                    }
                };
                if (field.domyslna_dzisiejsza === 't') {
                    data.value.date = new Date();
                    data.value.hour = new Date().toLocaleTimeString('pl', {
                        hour: '2-digit',
                        minute: '2-digit'
                    });
                }
                if (templateItem.sign === '@') {
                    return generateValueFieldFromTemplate({
                        templateMainItemReturn: templateItem,
                        data
                    });
                }

                return data;
            }
            case 'lista_obca': {
                let id = field.domyslna_wartosc;
                let options = safeGetKeyValueOptionsWithAddNewObjectOption(
                    field.opcje ?? {},
                    field
                );
                let text = field.domyslna_wartosc_nazwa
                    ? field.domyslna_wartosc_nazwa
                    : options && options[id];
                return {
                    type: 'lista_obca',
                    value: {
                        value: id ?? '',
                        text: text ?? '',
                        opcje: options
                    }
                };
            }
            case 'powiazanie_typ': {
                const isEmptyValue = field.dozwolona_opcja_pusta === 't';
                let select = field.domyslna_wartosc
                    ? field.domyslna_wartosc
                    : Object.keys(field.opcje ?? {})?.[0];
                let value = field.domyslna_wartosc_id;
                let text = field.domyslna_wartosc_nazwa;
                let type = select;
                if (isEmptyValue && field.domyslna_wartosc === '') {
                    select = field.domyslna_wartosc_nazwa;
                    type = '';
                }
                return {
                    type: 'powiazanie_typ',
                    value: {
                        isEmptyValue,
                        select,
                        type,
                        value: value ?? '',
                        text: text ?? ''
                    }
                };
            }
            case 'lista_obca_wielokrotna': {
                let arr = DynamicFormObjectDataConverter.getArrayIdAndText(
                    field.opcje ? field.opcje : {}
                );
                let values = Array.isArray(field.domyslna_wartosc)
                    ? field.domyslna_wartosc.map((id: string) => ({
                          id: id,
                          text: field.opcje?.[id] ?? ''
                      }))
                    : [];
                const opcje = sortAutocompleteOptions({ op: arr, type: field.porzadek_elementow });

                return {
                    type: 'lista_obca_wielokrotna',
                    value: { values, opcje: [{ id: '0', text: 'WSZYSTKIE' }, ...opcje] }
                };
            }
            case 'powiazanie_typ_wielokrotne': {
                const defaultVal = field.domyslna_wartosc
                    ? field.domyslna_wartosc
                    : Object.keys(field.opcje ?? {})?.[0];

                const elements = Array.isArray(field.elementy) ? field?.elementy : [];
                const objects = elements.map((el) => ({
                    ...el,
                    typ_nazwa: field.opcje?.[el.typ] ?? ''
                }));
                if (field.domyslna_wartosc_id) {
                    objects.push({
                        id: field.domyslna_wartosc_id,
                        nazwa: field.domyslna_wartosc_nazwa ?? '',
                        typ: field.domyslna_wartosc,
                        typ_nazwa: field.opcje?.[field.domyslna_wartosc] ?? ''
                    });
                }
                return {
                    type: 'powiazanie_typ_wielokrotne',
                    value: {
                        select: defaultVal,
                        objects: objects,
                        elements: elements
                    }
                };
            }

            case 'data_godziny': {
                let data: DynamicDateHoursValue = {
                    type: 'data_godziny',
                    value: {
                        data: null,
                        rozp: '00:00',
                        zak: '00:00'
                    }
                };
                if (field.domyslna_dzisiejsza === 't') {
                    data.value.data = new Date();
                    data.value.rozp = new Date().toLocaleTimeString('pl', {
                        hour: '2-digit',
                        minute: '2-digit'
                    });
                    data.value.zak = new Date().toLocaleTimeString('pl', {
                        hour: '2-digit',
                        minute: '2-digit'
                    });
                }
                if (templateItem.sign === '$' && Array.isArray(value)) {
                    return {
                        type: 'data_godziny',
                        value: {
                            data: DateManager.setDateFromPhpTimestamp(value[0]?.data_rozp),
                            rozp: DateManager.setTimeString(
                                DateManager.setDateFromPhpTimestamp(value[0]?.data_rozp)
                            ),
                            zak: DateManager.setTimeString(
                                DateManager.setDateFromPhpTimestamp(value[0]?.data_zak)
                            )
                        }
                    };
                } else if (templateItem.sign === '@') {
                    return generateValueFieldFromTemplate({
                        templateMainItemReturn: templateItem,
                        data
                    });
                }
                return data;
            }
            default: {
                let value: any = null;
                if (!fields[field.kod]) {
                    value = fields[field.kod];
                }
                return field.domyslna_wartosc ? field.domyslna_wartosc : value;
            }
        }
    }
}
