import { BasicTwoColumn } from 'src/data/table';

export const raportColumn: BasicTwoColumn[] = [
    {
        text: 'L.p.',
        columnType: 'lp',
        code: 'lp'
    },
    {
        text: 'Nazwa raportu',
        columnType: 'text',
        code: 'raport'
    },
    {
        text: 'Warunki',
        columnType: 'fields',
        code: 'conditions'
    },
    {
        text: 'Generuj',
        columnType: 'button',
        code: 'generate'
    }
];
