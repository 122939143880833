import { NAV_DO_NOT_CLEAR_BACK_LEVEL, NAV_BACK_LEVEL } from 'src/constants';

export const checkResetNavBackLevel = () => {
    const doNotClearBackLevelLevel = parseInt(
        sessionStorage.getItem(NAV_DO_NOT_CLEAR_BACK_LEVEL) ?? '0'
    );

    if (doNotClearBackLevelLevel <= 0) {
        sessionStorage.setItem(NAV_BACK_LEVEL, '0');
    }
    sessionStorage.setItem(
        NAV_DO_NOT_CLEAR_BACK_LEVEL,
        Math.max(doNotClearBackLevelLevel - 1, 0).toString()
    );
};
