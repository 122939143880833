import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';
import { FieldCheckboxObjectProps } from './types';
import { UiFieldCheckbox } from 'src/components/popupFormExtended/dynamicField/fieldCheckbox/UiFieldCheckbox';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';

export const FieldCheckboxObject: FC<FieldCheckboxObjectProps> = ({
    field,
    dynamicFieldT,
    keyOfFieldInState,
    dispatch,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const onClick = () => {
        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: !field.value
        };
        dispatch({
            type: 'checkbox',
            payload: {
                code: keyOfFieldInState,
                value: newValue
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newValue,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };

    return (
        <UiFieldCheckbox
            value={field.value}
            onClick={onClick}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT
            }}
        />
    );
};
