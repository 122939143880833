import isSameDay from 'date-fns/isSameDay';
import { EventsData } from '../../api/types';
import { CalendarDays } from '../../store/src/calendar/calendar/types';
import { roundToTwoDec } from '../../utils';
import { EnteredHours } from '../../store/src/calendar/calendar/types';

/**
 * Calculates entered hours for each day and returns dictionary with daytimestamps keys and rounded to two decimals string values
 */
export function calculateEnteredHours(
    events: EventsData['events'],
    days: CalendarDays,
    employees: number[]
): EnteredHours {
    // target data structure, days timestamps as keys, numbers as values
    const enteredHours = days.reduce((acc, day) => {
        acc[day.startTimestamp] = 0;
        return acc;
    }, {} as EnteredHours);

    for (const day of days) {
        // all events fit in given day, not canceled
        const dayEvents = events.filter(
            (ev) =>
                !ev.canceled && ev.startTime < day.endTimestamp && ev.endTime > day.startTimestamp
        );

        // Store current day as a Date object, for later date manipulations
        const dayDate = new Date(day.startTimestamp);

        // Temp variable, stores enteredHours for that day
        let hoursCount = 0;

        // iterate day events
        for (const event of dayEvents) {
            /* Timestamps of current day start and end of work */
            let startTime = new Date(
                dayDate.getFullYear(),
                dayDate.getMonth(),
                dayDate.getDate(),
                event.workStartHour,
                event.workStartMinute
            ).getTime();
            let endTime = new Date(
                dayDate.getFullYear(),
                dayDate.getMonth(),
                dayDate.getDate(),
                event.workEndHour,
                event.workEndMinute
            ).getTime();

            // Adjust those values, don't know exactly why it works
            endTime = Math.min(endTime, event.endTime);
            startTime = Math.max(startTime, event.startTime);

            // No user events treated differently
            if (event.noGrid) {
                // increment hoursCount only when it is a day event ends
                if (isSameDay(event.endTime, day.startTimestamp)) {
                    // if event is of 'zadania' type, increment hoursCount by timespent property
                    if (event.type === 'zadania' && event.timeSpent) {
                        hoursCount += event.timeSpent;

                        // else calculate hoursCount from startTime and endTime
                    } else if (
                        !Number.isNaN(startTime) &&
                        !Number.isNaN(endTime) &&
                        startTime < endTime
                    ) {
                        // count only for selected employees that are part of event
                        const len =
                            event.participants.filter((id) => employees.includes(id)).length || 1;
                        hoursCount += (len * (endTime - startTime)) / (3600 * 1000);
                    }
                }

                // all user events here
            } else {
                // check if got valid numbers and start time before end time
                if (!Number.isNaN(startTime) && !Number.isNaN(endTime) && startTime < endTime) {
                    // count only for selected employees that are part of event
                    const len =
                        event.participants.filter((id) => employees.includes(id)).length || 1;
                    hoursCount += (len * (endTime - startTime)) / (3600 * 1000);
                }
            }
        }

        // store value outside the loop
        enteredHours[day.startTimestamp] = hoursCount;
    }

    // iterate over result format it as max two digits after decimal point
    for (const key in enteredHours) {
        enteredHours[key] = roundToTwoDec(enteredHours[key]);
    }

    return enteredHours;
}
