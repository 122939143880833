import { BudgetFormStateSharedProps } from 'src/components/budgetScreen/organisms/budgetForm/types';
import {
    BudgetIdsShared,
    BugdetMonthLevel,
    BugdetYearLevel
} from 'src/store/src/budget/budget/types';
import { createMemoKey } from 'src/utils';

export type UseGetMonthMemoKeyProps = BudgetIdsShared &
    BudgetFormStateSharedProps & {
        month: BugdetMonthLevel;
        year: BugdetYearLevel;
    };

export function useGetMonthMemoKey(props: UseGetMonthMemoKeyProps) {
    const value = props
        .getValuesBudgetRHF({ keyField: createMemoKey([props.month.id]), methodId: props.methodId })
        .getString({ fieldType: 'liczba' });
    return value;
}
