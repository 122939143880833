import React, { FC } from 'react';

import styles from './UiFieldNumber.module.css';
import { UiFieldNumberNormalProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

export const UiFieldNumberNormal: FC<UiFieldNumberNormalProps> = (props) => {
    const { value, onBlur, onChange, uiFieldCommonProps, percent, uiFieldSharedProps } = props;

    return (
        <UiWrapper
            style={{
                width: '100%'
            }}>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.wrapperWhenPercent}>
                <UiInputNormalTwo
                    value={value}
                    {...uiFieldCommonProps}
                    onChange={onChange}
                    onBlur={onBlur}
                    alignTextRight={true}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                {percent && <p className={styles.percentText}>%</p>}
            </div>
        </UiWrapper>
    );
};
