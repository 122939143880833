import React, { FC } from 'react';

import { ActionTypeProps } from './types';
import { PopupBehavior } from 'src/data/types';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { useAppDispatch, AppDispatch } from 'src/store';
import { FileDownload } from 'src/components/object/atoms/actionType/fileDownload/FiledDownload';
import { ApiAction } from 'src/components/object/atoms/actionType/apiAction/ApiAction';
import { PopupForm } from 'src/components/object/atoms/actionType/popupForm/PopupForm';
import { LinkAction } from 'src/components/object/atoms/actionType/linkAction/LinkAction';

export const ActionType: FC<ActionTypeProps> = (props) => {
    const { children, action, id, additional } = props;
    const dispatch: AppDispatch = useAppDispatch();

    const handleSetPopupTemplate = (behaviour: PopupBehavior) => {
        new PopupManager({ dispatch, action }).setPopupTemplate({
            behaviour,
            rowId: id,
            additional,
            sourceOfTrigger: {
                type: 'object',
                typeWew: additional.typeSection
            }
        });
    };

    switch (action.behaviour.type) {
        case 'apiAction':
            return <ApiAction {...props}>{children}</ApiAction>;
        case 'fileDownload':
            return <FileDownload {...props}>{children}</FileDownload>;
        case 'link':
            return <LinkAction {...props}>{children}</LinkAction>;
        case 'popupForm':
            return <PopupForm {...props}>{children}</PopupForm>;
        case 'popup':
            return (
                <div onClick={() => handleSetPopupTemplate(action.behaviour as PopupBehavior)}>
                    {children}
                </div>
            );

        default:
            return <div>{children}</div>;
    }
};
