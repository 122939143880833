export function getVatStake(type: string) {
    switch (type) {
        case '23':
            return 1.23;
        case '8':
            return 1.08;
        case '5':
            return 1.05;
        case '0':
            return 1;
        default:
            return 1;
    }
}
