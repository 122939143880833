import { isNonNullChain } from 'typescript';
import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

/**
 * Fetches information about messeges screen id, to render messages section in menu
 */
export async function fetchMessagesScreenStatus(): Promise<
    BaseApiResponse<{ isMessageScreenVisible: boolean; id: number }>
> {
    return simpleQueryAPIData('api_react/src/menu/getMessagesScreenStatus.php');
}
