import { makeThemeObj } from 'src/widgets/shared/theme';

/**
 * Generate widgets theme to given viewport
 * @param {*} viewport
 * @returns theme from src/widgets/theme
 */
export const createWidgetsTheme = (viewport: string, color: string | undefined = undefined) => {
    switch (viewport) {
        case 'small':
            return makeThemeObj(color).themeSmall;
        case 'medium':
            return makeThemeObj(color).themeMedium;
        default:
            return makeThemeObj(color).themeLarge;
    }
};
