import { UpdateObjectDataForApi } from 'src/api/types';
import { DynamicStateForm } from 'src/components/shared/molecules/dynamicForm/DynamicFormStateHandler';
import {
    DynamicDateHoursValue,
    DynamicStateFormFields
} from 'src/components/shared/molecules/dynamicForm/types';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { DateManager } from 'src/utils';

type DynamicFormDataApiCreatorProps = {
    state: DynamicStateForm;
    fields: DynamicFieldT[];
};

type HandleField = {
    // type: DynamicFieldType | 'default';
    code: string;
    fieldValue: DynamicStateFormFields;
};

type GetIdCodeType = {
    id: string;
    code: string;
};

export class DynamicFormDataApiCreator {
    state: DynamicStateForm;
    fields: DynamicFieldT[];
    dataApi: UpdateObjectDataForApi;
    constructor({ state, fields }: DynamicFormDataApiCreatorProps) {
        this.state = state ? state : {};
        this.fields = fields;
        this.dataApi = {};
    }

    private getIdCodeAndType(property: string): GetIdCodeType {
        const data = property.split('-');
        return {
            id: data[0],
            code: data[1]
        };
    }

    private handleField({ code, fieldValue }: HandleField) {
        switch (fieldValue.type) {
            case 'data_godzina': {
                const hourArr = fieldValue.value.hour?.split(':')
                    ? fieldValue.value.hour?.split(':')
                    : ['00', '00'];
                return {
                    [code]: DateManager.getDateDayDashMonthDashYear(fieldValue.value.date),
                    [`${code}Minute`]: hourArr[1],
                    [`${code}Hour`]: hourArr[0]
                };
            }
            case 'data_godziny':
                return OutputData.timeHours({ code, value: fieldValue });
            case 'checkbox':
                return {
                    [code]: fieldValue.value ? 1 : 0
                };
            case 'lista_obca':
                return {
                    [`${code}`]: fieldValue.value.value,
                    [`${code}_tekst`]: fieldValue.value.text
                };
            case 'lista_obca_wielokrotna':
                return {
                    [`${code}`]: fieldValue.value.values
                        .filter((el) => el.text !== 'WSZYSTKIE')
                        .map((el) => el.id),
                    [`${code}_wszystkie`]: +(
                        fieldValue.value.values.findIndex((el) => el.text === 'WSZYSTKIE') !== -1
                    )
                };
            case 'powiazanie_typ':
                return {
                    [`${code}`]: fieldValue.value.text,
                    [`${code}_typ`]: fieldValue.value.type,
                    [`${code}_id`]: fieldValue.value.value
                };
            case 'powiazanie_typ_wielokrotne': {
                const data = {
                    [`${code}_nowe`]: fieldValue.value.objects
                        .filter((item) => {
                            if (
                                fieldValue.value.elements.find(
                                    (item2) => item2.id === item.id && item2.typ === item.typ
                                )
                            ) {
                                return false;
                            }
                            return true;
                        })
                        .map((item) => {
                            return `${item.typ}_${item.id}`;
                        }),
                    [`${code}_typ`]: fieldValue.value.select,
                    [`${code}_usun_pola`]: fieldValue.value.elements
                        .filter((item: any) => {
                            if (item.isToDelete) {
                                if (
                                    fieldValue.value.objects.find(
                                        (item2) => item2.id === item.id && item2.typ === item.typ
                                    )
                                ) {
                                    return false;
                                }
                                return true;
                            }
                            return false;
                        })
                        .map((item) => {
                            return `${item.typ}_${item.id}`;
                        })
                };

                if (!data[`${code}_nowe`].length) delete data[`${code}_nowe`];
                if (!data[`${code}_usun_pola`].length) delete data[`${code}_usun_pola`];
                return data;
            }
            default:
                return {
                    [code]: fieldValue.value
                };
        }
    }

    private prepareData() {
        for (const property in this.state) {
            const { id, code } = this.getIdCodeAndType(property);
            this.dataApi[id] = {
                ...this.dataApi[id],
                ...this.handleField({ code, fieldValue: this.state[property] })
            };
        }
    }

    getData() {
        this.prepareData();
        return this.dataApi;
    }
}

class OutputData {
    static timeHours({ code, value }: { value: DynamicDateHoursValue; code: string }) {
        const value2 = value.value;
        const date = DateManager.getDateDayDashMonthDashYear(value2.data);
        const rozp = value2?.rozp?.split(':') ? value2?.rozp?.split(':') : [];
        const zak = value2?.zak?.split(':') ? value2?.zak?.split(':') : [];
        return {
            [code]: date,
            [`${code}_rozpMinute`]: rozp[1],
            [`${code}_rozpHour`]: rozp[0],
            [`${code}_zakMinute`]: zak[1],
            [`${code}_zakHour`]: zak[0]
        };
    }
}
