import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetGlobalSummaryDataItem,
    BudgetGlobalSummaryDataLimit,
    BudgetScreenState,
    BugdetSettlementMethodLevel,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetHandlerStateGlobalFnExecuteSharedProps } from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { checkTimestampIsInMonth } from 'src/utils/src/shared/checkTimestampIsInMonth';
import { consoleConditionally } from 'src/utils/src/shared/consoleConditionally';
import { NumberManager } from 'src/utils/src/shared/NumberManager';

type BudgetHandlerCalcGlobalSummaryMainProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {};

const typesOfCodesInvolvedToLimit: SettledObjectsCodes[] = ['kontakty', 'rozprawy', 'zadania'];

export class BudgetHandlerCalcGlobalSummaryMain {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
    isCapfee: boolean;
    isLimit: boolean;
    limit: BudgetGlobalSummaryDataLimit;
    limitSummed: {
        [methodId: string]: number;
    };
    capfeeRetrievedOnMethod: {
        [methodId: string]: number;
    };
    isBaseOnCalcStakeHour: boolean;
    capfeeRetrieved: number;
    capfeeRateExchange: number;
    constructor(data: BudgetHandlerCalcGlobalSummaryMainProps) {
        this.budget = data.budget;
        this.operationalState = data.operationalState;
        this.isCapfee = false;
        this.isLimit = false;
        this.limit = {};
        this.limitSummed = {};
        this.capfeeRetrievedOnMethod = {};
        this.isBaseOnCalcStakeHour = false;
        this.capfeeRetrieved = 0;
        this.capfeeRateExchange = 1;
    }

    execute() {
        this.prepareLimitSummed();
        this.sum();
        this.protectMinusAmount();
    }

    private prepareLimitSummed() {
        for (const methodId in this.operationalState.globalSummaryData) {
            if (
                this.operationalState.globalSummaryData[methodId].isLimit &&
                !this.operationalState.globalSummaryData[methodId].isBaseOnCalcStakeHour
            ) {
                this.limitSummed[methodId] = 0;
                for (const objectKey in this.operationalState.globalSummaryData[methodId].limit) {
                    const rateExchange =
                        this.operationalState.globalSummaryData[methodId].limit[objectKey]
                            .valueRateExchange ?? 1;
                    this.limitSummed[methodId] =
                        this.limitSummed[methodId] +
                        this.operationalState.globalSummaryData[methodId].limit[objectKey]
                            .valuePriceTotal *
                            rateExchange;
                }
            }
        }
    }

    private protectMinusAmount() {
        if (this.operationalState.globalSummary.amount < 0) {
            this.operationalState.globalSummary.amount = 0;
        }
    }

    private sum() {
        for (const methodId in this.operationalState.globalSummaryData) {
            this.isCapfee = this.operationalState.globalSummaryData[methodId].isCapfee;
            this.isLimit = this.operationalState.globalSummaryData[methodId].isLimit;
            this.limit = {
                ...this.operationalState.globalSummaryData[methodId].limit
            };
            this.capfeeRetrieved = this.operationalState.globalSummaryData[methodId].capfee;
            this.capfeeRateExchange =
                this.operationalState.globalSummaryData[methodId].capfeeRateExchange;
            this.isBaseOnCalcStakeHour =
                this.operationalState.globalSummaryData[methodId].isBaseOnCalcStakeHour;
            for (const objectKey in this.operationalState.globalSummaryData[methodId].object) {
                this.calc({
                    ...this.operationalState.globalSummaryData[methodId].object[objectKey]
                });
            }
            this.capfeeRetrievedOnMethod[methodId] = this.capfeeRetrieved;
        }
        this.budget.settlementMethods.forEach((method) => {
            this.capfeeRetrieved = this.capfeeRetrievedOnMethod[method.id];
            this.addPotentialBeforeYearsHoursAndAmount(method);
        });
    }

    private calc(data: BudgetGlobalSummaryDataItem) {
        switch (data.type) {
            case 'kontakty':
            case 'rozprawy':
            case 'zadania': {
                this.calcAll({
                    ...data
                });
                break;
            }
            case 'kosztyzastepstwa':
            case 'ryczalty_za_sprawy':
            case 'oplatywstepne_za_sprawy':
            case 'dokumenty':
            case 'dokumenty_kontrakt':
            case 'ryczalt':
            case 'ryczalt_miesiac':
            case 'successfees':
            case 'koszty':
            case 'rozprawy_ryczalty':
            case 'fakturowaneelicencje':
            case 'etapyprojektow':
            case 'koszty_projektow':
            case 'kontakty_ryczalty': {
                this.calcOnlyAmount({
                    ...data
                });
                break;
            }
            default:
                break;
        }
    }

    private calcOnlyAmount(data: BudgetGlobalSummaryDataItem) {
        if (data.isInvoiced) {
            this.addAmount(data);
        }
    }

    private calcAll(data: BudgetGlobalSummaryDataItem) {
        if (data.isInvoiced) {
            if (data.specialStake) {
                this.setHoursSpecial(data.valueHour);
            } else {
                this.setHours(data.valueHour);
            }
            this.addAmount(data);
        }
    }

    private addAmount(data: BudgetGlobalSummaryDataItem) {
        let amount = data.valuePriceTotal * data.valueRateExchange;
        if (this.isLimit && !data.specialStake && typesOfCodesInvolvedToLimit.includes(data.type)) {
            if (this.isBaseOnCalcStakeHour) {
                for (const methodId in this.operationalState.templateMonthLimit) {
                    if (methodId === data.methodId) {
                        for (const key in this.operationalState.templateMonthLimit[methodId]) {
                            const timestampStartMonth = Number(key);
                            if (checkTimestampIsInMonth(timestampStartMonth, data.date)) {
                                const keyLimit =
                                    this.operationalState.templateMonthLimit?.[methodId]?.[
                                        key
                                    ]?.[0];
                                if (this.limit?.[keyLimit]) {
                                    const rateExchange =
                                        this.limit?.[keyLimit]?.valueRateExchange ?? 1;
                                    const limit =
                                        this.limit?.[keyLimit]?.valuePriceTotal * rateExchange;
                                    const tempAmount = amount + limit;
                                    if (tempAmount >= 0) {
                                        amount = tempAmount;
                                        this.limit[keyLimit] = {
                                            ...this.limit[keyLimit],
                                            valuePriceTotal: 0
                                        };
                                    } else {
                                        amount = 0;
                                        this.limit[keyLimit] = {
                                            ...this.limit[keyLimit],
                                            valuePriceTotal: tempAmount / rateExchange
                                        };
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                const tempAmount = amount + this.limitSummed[data.methodId];
                if (tempAmount >= 0) {
                    amount = tempAmount;
                    this.limitSummed[data.methodId] = 0;
                } else {
                    amount = 0;
                    this.limitSummed[data.methodId] = tempAmount;
                }
            }
        }
        this.updateAmount(amount, data.valueRateExchange);
    }

    private recalcBaseOnRateExchange(amountRateExchange: number, amount: number) {
        const rate = Number(this.budget.invoiceRate);
        let newValue = amount;

        if (Number(this.budget.invoiceRate) >= amountRateExchange) {
            newValue = newValue / rate;
        } else if (Number(this.budget.invoiceRate) < amountRateExchange) {
            newValue = newValue * rate;
        }
        return newValue;
    }

    private addPotentialBeforeYearsHoursAndAmount(method: BugdetSettlementMethodLevel) {
        const beforeYears = method.years.find((year) => year.name?.includes('lata poprzednie'));
        if (beforeYears) {
            if (!beforeYears.months && beforeYears.tempSums) {
                // let exchangeRate = 1;
                // if (Number(this.budget.invoiceRate) > method.kurs_waluty) {
                //     exchangeRate = method.kurs_waluty / Number(this.budget.invoiceRate);
                // } else if (Number(this.budget.invoiceRate) < method.kurs_waluty) {
                //     exchangeRate = Number(this.budget.invoiceRate) * method.kurs_waluty;
                // }
                // this.setAmount(beforeYears.tempSums.toInvoice * exchangeRate);
                this.updateAmount(beforeYears.tempSums.toInvoice, method.kurs_waluty);
                if (beforeYears.tempSums.toInvoiceHours) {
                    this.setHours(beforeYears.tempSums.toInvoiceHours);
                }
                if (beforeYears.tempSums.toInvoiceHoursSpecial) {
                    this.setHoursSpecial(beforeYears.tempSums.toInvoiceHoursSpecial);
                }
            }
        }
    }

    private updateAmount(amount: number, amountRateExchange: number) {
        if (this.isCapfee) {
            const amountToSubtract = amount > 0 ? amount / amountRateExchange : 0;
            consoleConditionally(this.capfeeRetrieved, 'this.capfeeRetrieved');
            const difference = this.capfeeRetrieved - amountToSubtract;
            if (difference > 0) {
                this.setAmount(this.recalcBaseOnRateExchange(amountRateExchange, amount));
                this.capfeeRetrieved = difference;
            } else {
                if (this.capfeeRateExchange < amountRateExchange) {
                    this.setAmount(
                        this.recalcBaseOnRateExchange(
                            amountRateExchange,
                            this.capfeeRetrieved * amountRateExchange
                        )
                    );
                } else {
                    this.setAmount(
                        this.recalcBaseOnRateExchange(amountRateExchange, this.capfeeRetrieved)
                    );
                }

                this.capfeeRetrieved = 0;
            }
        } else {
            this.setAmount(this.recalcBaseOnRateExchange(amountRateExchange, amount));
        }
    }

    private setAmount(value: number) {
        this.operationalState.globalSummary.amount =
            this.operationalState.globalSummary.amount + value;
    }

    private setHours(value: number) {
        this.operationalState.globalSummary.hours = NumberManager.round(
            this.operationalState.globalSummary.hours + value,
            2
        );
    }

    private setHoursSpecial(value: number) {
        this.operationalState.globalSummary.hoursSpecial = NumberManager.round(
            this.operationalState.globalSummary.hoursSpecial + value,
            2
        );
    }
}
