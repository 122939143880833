import React, { RefObject } from 'react';

export function useCalculateMinHeight<T extends HTMLElement>(
    standardHeight: number
): {
    ref: RefObject<T>;
    minHeight: number;
} {
    const ref = React.useRef<T>(null);
    const [minHeight, setMinHeight] = React.useState<number>(standardHeight);
    React.useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            const height = ref.current?.clientHeight ? ref.current?.clientHeight : 0;
            if (height) {
                const addedMultiplier = height % standardHeight === 0 ? 0 : 1;
                const multiplier = Number.parseInt(String(height / standardHeight));
                if (height > standardHeight) {
                    setMinHeight((multiplier + addedMultiplier) * standardHeight);
                } else {
                    setMinHeight(standardHeight);
                }
            } else if (height === 0) {
                setMinHeight(0);
            }
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect();
    }, []);

    return {
        ref,
        minHeight
    };
}
