import { DynamicFieldT } from 'src/data/popupFormTypes';

type KeyValueOptions = {
    [key: string]: string;
};

export function safeGetKeyValueOptionsWithAddNewObjectOption(
    options: KeyValueOptions,
    field: DynamicFieldT
): KeyValueOptions {
    if (field.dodanie_nowego === 't' && !('nowy' in options))
        return { ...options, nowy: 'Dodaj...' };
    return options;
}
