import { ListSortProperty, TransformationsState, ListPagingData } from './types';
import { ColumnData } from '../tableData/types';
import { getActualURLParamsAsObject } from 'src/utils/src/navigationSystem/getActualURLParamsAsObject';

// TransformationManager jest podstawowym obiektem tworzącym, przetwarzającym, pobierającym dane ze slice listScreenTransformations w redux
// TransformationManager is basic object create, convert, load data from  slice listScreenTransformations in redux

interface TransformationManagerProp {
    transformationState: TransformationsState;
}

export class TransformationManager {
    transformationState: TransformationsState;
    sortDataArrayItem: undefined | null | ListSortProperty;
    sortDataArray: ListSortProperty[];
    constructor({ transformationState }: TransformationManagerProp) {
        this.transformationState = transformationState;
        this.sortDataArrayItem = null;
        this.sortDataArray = [];
    }

    getItemRowSort(columnData: ColumnData) {
        this.sortDataArrayItem = this.transformationState.sortingData.find(
            (item) => item.code === columnData.code
        );
    }

    setItemNewRowSort(columnData: ColumnData) {
        if (this.sortDataArrayItem) {
            if (this.sortDataArrayItem.value === 'ASC') {
                this.setArrayRowSortMap(
                    {
                        code: columnData.code,
                        value: 'DESC',
                        type: columnData.type,
                        fieldType: columnData.fieldType
                    },
                    columnData,
                    false
                );
            } else {
                this.setArrayRowSortFilter(columnData);
            }
        } else {
            this.setArrayRowSortMap(
                {
                    code: columnData.code,
                    value: 'ASC',
                    type: columnData.type,
                    fieldType: columnData.fieldType
                },
                columnData,
                true
            );
        }
    }

    setArrayRowSortFilter(columnData: ColumnData) {
        this.sortDataArray = this.transformationState.sortingData.filter(
            (item) => item.code !== columnData.code
        );
    }

    setArrayRowSortMap(sortItem: ListSortProperty, columnData: ColumnData, add: boolean) {
        this.sortDataArray = this.transformationState.sortingData.map((item) => {
            if (item.code === columnData.code) {
                return sortItem;
            }
            return item;
        });
        if (add) {
            this.sortDataArray.push(sortItem);
        }
    }

    getPagingAsParams({ keys = [], merge = false }: { keys?: string[]; merge?: boolean }) {
        let paging = this.transformationState.paging;
        if (merge) {
            paging = {
                ...getActualURLParamsAsObject(),
                ...this.transformationState.paging
            };
        }
        let str = '';
        if (!keys.length) {
            for (const property in paging) {
                str = str + `${property}=${paging[property as keyof ListPagingData]}&`;
            }
        } else {
            keys.forEach((key) => {
                str = str + `${key}=${paging[key as keyof ListPagingData]}&`;
            });
        }

        return str.slice(0, -1);
    }

    getDataLocationState(locationStateFlag: boolean) {
        let locationState = null;
        if (locationStateFlag) {
            locationState = {
                filters: this.transformationState.filters,
                sortingData: this.transformationState.sortingData
            };
        }
        return locationState;
    }

    //handle sortingData
    static changeRowSort(transformationState: TransformationsState, columnData: ColumnData) {
        const sort = new this({ transformationState });
        sort.getItemRowSort(columnData);
        sort.setItemNewRowSort(columnData);
        return sort.sortDataArray;
    }
    // handle columnFilters
    static changeFilltersColumn({
        transformationState,
        columnData,
        filter
    }: {
        transformationState: TransformationsState;
        columnData: ColumnData;
        filter: string;
    }) {
        const filterForColumn = transformationState.filters[columnData.code];
        let filterItem = {};
        let deleteFilter = false;
        if (!filterForColumn) {
            filterItem = {
                [columnData.code]: {
                    columnValueFilter: filter
                }
            };
        } else {
            if (!filter) {
                const data: { [key: string]: any } = {};
                const arrFromObj = Object.entries(filterForColumn);
                if (arrFromObj.length === 1 && arrFromObj[0][0] === 'columnValueFilter') {
                    deleteFilter = true;
                } else {
                    for (const [key, value] of arrFromObj) {
                        if (key !== 'columnValueFilter') {
                            data[key] = value;
                        }
                    }
                    filterItem = {
                        [columnData.code]: {
                            ...data
                        }
                    };
                }
            } else {
                filterItem = {
                    [columnData.code]: {
                        ...filterForColumn,
                        columnValueFilter: filter
                    }
                };
            }
        }
        if (deleteFilter) {
            return {
                ...Object.fromEntries(
                    Object.entries(transformationState.filters).filter(
                        ([key]) => !key.includes(columnData.code)
                    )
                )
            };
        }

        return {
            ...transformationState.filters,
            ...filterItem
        };
    }

    static getSetSortColumn({
        transformationState,
        columnData
    }: {
        transformationState: TransformationsState;
        columnData: ColumnData;
    }) {
        const found = transformationState.sortingData.find((item) => item.code === columnData.code);
        if (found) return found?.value;
        else return 'EMPTY';
    }
}
