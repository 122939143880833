import { fieldDataMinutes } from 'src/constants/inputDateHourData';

export function transformListMinute(number?: string) {
    const divider = Number(number);
    const data: { [k: string]: string } = {};
    for (const key in fieldDataMinutes) {
        const option = Number(fieldDataMinutes[key]);
        if (!(option % divider)) {
            data[key] = fieldDataMinutes[key];
        }
    }
    return data;
}
