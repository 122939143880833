import {
    FieldDependentFieldTypeRHF,
    FieldDependentFunctionRHF
} from 'src/components/form/molecules/fieldDependent/types';

export const raportAvailableFunctionToCallInField: {
    [key in FieldDependentFieldTypeRHF]?: FieldDependentFunctionRHF[];
} = {
    lista_obca: ['zmienPole'],
    lista: [],
    powiazanie_typ: ['zmienPole'],
    basic_list_many: ['zmienPole']
};
