import React, { FC } from 'react';

import { usePopupState } from 'src/context/globalPopup/popupContext';
import styles from './Tabs.module.css';
import { Tab } from 'src/components/popupFormExtended/tab/Tab';
import { TabsProps } from 'src/components/popupFormExtended/tabs/types';
import { TabActive } from 'src/components/popupFormExtended/tabActive/TabActive';

export const Tabs: FC<TabsProps> = ({ popup, scroll = 0 }) => {
    const { popupStateRedux } = usePopupState(popup.key);

    return (
        <div className={styles.box}>
            {popupStateRedux.popupFormExtendedData?.tabs?.map((item, i) => {
                return <Tab key={i} index={i} {...item} keyPopup={popup.key} />;
            })}
            <TabActive keyPopup={popup.key} scroll={scroll} />
        </div>
    );
};
