import { GlobalPopupState } from 'src/context/globalPopup/popupContext';

type TabActiveHandlerProps = {
    scroll: number;
    globalPopupState: GlobalPopupState;
};

export class TabActiveHandler {
    scroll: number;
    position: number;
    globalPopupState: GlobalPopupState;
    constructor({ scroll, globalPopupState }: TabActiveHandlerProps) {
        this.globalPopupState = globalPopupState;
        this.scroll = scroll;
        this.position = 0;
    }
    calc() {
        this.setPosition();
        this.checkLeft();
        this.checkRight();
        return this.position;
    }

    private setPosition() {
        this.position = this.globalPopupState.activeTab.offsetLeft - this.scroll;
    }
    private checkLeft() {
        if (this.position <= this.globalPopupState.tabsLayout.left)
            this.position = this.globalPopupState.tabsLayout.left;
    }
    private checkRight() {
        const maxPosition =
            this.globalPopupState.dimensions.width -
            this.globalPopupState.activeTab.width -
            this.globalPopupState.tabsLayout.left +
            1;
        if (this.position > maxPosition) this.position = maxPosition;
    }
}
