export function convertNumberPriceToFormatDisplay({
    price,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    clearCurrency = true
}: {
    price: number | string | undefined | null;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    clearCurrency?: boolean;
}): string {
    if (price === undefined || price === null) {
        return '';
    }
    const number = Number(price);
    if (isNaN(number)) {
        return '';
    }

    const stringifiedNumber = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'PLN',
        minimumFractionDigits,
        maximumFractionDigits
    })
        .format(number)
        .replaceAll('.', ' ');
    return clearCurrency ? stringifiedNumber.replace('PLN', '') : stringifiedNumber;
}
