import React, { FC, useMemo } from 'react';
import { PermissionScreenLevelProps } from './types';
import PermissionsLevelHeader from '../../molecules/permissionsLevelHeader/PermissionsLevelHeader';
import PermissionsField from '../../molecules/permissionsField/PermissionsField';
import { PermissionType } from 'src/utils/src/permissionsScreen/types';
import PermissionTabsLevel from '../permissionTabsLevel/PermissionTabsLevel';
import { PERIMISSION_LEVEL_INDENT } from 'src/constants/permissionsScreen';
import PermissionOptionsLevel from '../permissionOptionsLevel/PermissionOptionsLevel';

const PermissionScreenLevel: FC<PermissionScreenLevelProps> = ({
    screen,
    unfoldedState,
    toggleFolding,
    ...props
}) => {
    const screenKey = `screen_${screen.id}`;

    const screenPermissionTypes = useMemo(
        () =>
            (!screen.isBasicScreen
                ? ['visible']
                : [
                      'visible',
                      'read',
                      'adding',
                      'editing',
                      'deleting',
                      'readOwn',
                      'readOwnDirect',
                      'editingOwn',
                      'deletingOwn'
                  ]) as PermissionType[],
        [screen.isBasicScreen]
    );

    return (
        <div style={{ marginLeft: PERIMISSION_LEVEL_INDENT }}>
            <PermissionsLevelHeader
                folded={unfoldedState[screenKey]}
                onClick={() => toggleFolding(screenKey)}
                title={screen.name}
            />
            {unfoldedState[screenKey] && (
                <div>
                    {screenPermissionTypes.map((type) =>
                        type in screen ? (
                            <PermissionsField
                                {...props}
                                key={`${screen.id}_${type}`}
                                item={screen}
                                type={type}
                                level="screen"
                            />
                        ) : null
                    )}
                    {screen.isBasicScreen && (
                        <PermissionTabsLevel
                            {...props}
                            screenId={screen.id}
                            tabs={screen.tabs}
                            unfoldedState={unfoldedState}
                            toggleFolding={toggleFolding}
                        />
                    )}
                    <PermissionOptionsLevel
                        {...props}
                        screenId={screen.id}
                        options={screen.options}
                        unfoldedState={unfoldedState}
                        toggleFolding={toggleFolding}
                    />
                </div>
            )}
        </div>
    );
};

export default PermissionScreenLevel;
