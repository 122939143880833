import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MassBillData } from 'src/api/src/massBill/types';

import { MassBillState } from 'src/store/src/massBill/massBill/types';

const initData: MassBillState = {
    isMounting: true,
    isLoading: true,
    params: {},
    position: [],
    worker: {},
    client: [],
    clientSearch: {},
    dateSale: {
        kod: '',
        domyslna_wartosc: '',
        modyfikacja_przy_dodawaniu: '',
        onchange: ''
    },
    dateSet: {
        onchange: '',
        kod: '',
        domyslna_wartosc: '',
        modyfikacja_przy_dodawaniu: ''
    },
    contractScreenName: ''
};

export const initialState: MassBillState = {
    ...initData
};

const massBillSlice = createSlice({
    name: 'massBill',
    initialState,
    reducers: {
        setInitBudgetData: (state: MassBillState, action: PayloadAction<MassBillData>) => {
            state.isMounting = false;
            state.isLoading = false;
            state.params = action.payload.params;
            state.position = action.payload.position;
            state.worker = action.payload.worker;
            state.client = action.payload.client;
            state.clientSearch = action.payload.clientSearch;
            state.dateSale = action.payload.dateSale;
            state.dateSet = action.payload.dateSet;
            state.contractScreenName = action.payload.contractScreenName;
        },
        setIsMounting: (state: MassBillState) => {
            state.isMounting = true;
            state.isLoading = true;
        },
        setUpdateClient: (state: MassBillState, action: PayloadAction<string>) => {
            state.params.id_klienta = action.payload;
            state.isLoading = true;
        },
        setUpdateWorker: (state: MassBillState, action: PayloadAction<string>) => {
            state.params.id_pracownika = action.payload;
            state.isLoading = true;
        },
        setUpdateParams: (
            state: MassBillState,
            action: PayloadAction<{ [key: string]: string }>
        ) => {
            state.params = {
                ...state.params,
                ...action.payload
            };
        },
        clearState: (state: MassBillState) => {
            state.isMounting = true;
            state.isLoading = true;
        }
    }
});

export const {
    setInitBudgetData,
    setIsMounting,
    clearState,
    setUpdateClient,
    setUpdateWorker,
    setUpdateParams
} = massBillSlice.actions;

export default massBillSlice.reducer;
