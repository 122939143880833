import { IcalData } from '../../types';
import { queryServerData } from './queryServerData';

/**
 * Function fetches and avialable and selected categories categories of displayable calendar events
 */
export async function fetchIcalUrl(): Promise<IcalData> {
    return queryServerData<IcalData>('api_react/calendar.php', {
        view: 'personal',
        type: 'icalendar',
        req_type: 'get'
    });
}
