import {
    MenuState,
    ScreenMenuData,
    ModuleMenuData,
    ScreenSelected,
    MenuStructureData,
    ModuleExpanded
} from 'src/store/src/menu/menu/types';
import { Dispatch } from 'redux';

type UbdateMenuModuleData = {
    menuStructureData: MenuStructureData;
    screenSelected: string | number | null;
    moduleSelected: string | number | null;
    dispatch: Dispatch;
    ubdateModule: any;
};

type SetSelectedModule = {
    modules: ModuleMenuData[];
    screenSelected: string | number | null;
    moduleSelected: string | number | null;
};

export class MenuStateManager {
    pinnedScreens: ScreenMenuData[];
    menuState: MenuState;
    constructor({ menuState }: { menuState: MenuState }) {
        this.pinnedScreens = [];
        this.menuState = menuState;
    }

    static filterPinnedScreens(modules: ModuleMenuData[]) {
        const pinnedScreens: ScreenMenuData[] = [];
        modules.forEach((module) => {
            module.screens.forEach((screen) => {
                if (screen.isSelectedToShortcut) {
                    pinnedScreens.push(screen);
                }
            });
        });
        return pinnedScreens;
    }

    static getPinnedScreens(modules: ModuleMenuData[]) {
        const pinnedScreens = MenuStateManager.filterPinnedScreens(modules);
        return {
            pinnedScreens
        };
    }
    static getPinnedScreensIds(modules: ModuleMenuData[]) {
        const pinnedScreens = MenuStateManager.filterPinnedScreens(modules);

        return {
            pinnedIds: pinnedScreens.map((s) => ({ id: s.id.toString() }))
        };
    }

    static filterVisibleOnlyWhenEditing(modules: ModuleMenuData[]) {
        return modules.map((item) => {
            const excludeIsStaticShortcut = item.screens.filter((item) => !item.isStaticShortcut);
            return {
                ...item,
                screens: excludeIsStaticShortcut.filter((item) => !item.visibleOnlyWhenEditing)
            };
        });
    }

    static getSelectedObject() {
        let screenSelected = null;
        let moduleSelected = null;
        const obiekt = new URLSearchParams(window.location.search).get('obiekt');
        const ekran = new URLSearchParams(window.location.search).get('ekran');
        const modul = new URLSearchParams(window.location.search).get('modul');
        if (obiekt) {
            screenSelected = obiekt;
        } else if (ekran) {
            screenSelected = ekran;
        } else if (modul) {
            moduleSelected = modul;
        }
        return {
            screenSelected,
            moduleSelected
        };
    }

    static setInitMenuStructureData(modules: ModuleMenuData[]) {
        const { screenSelected, moduleSelected } = MenuStateManager.getSelectedObject();
        return MenuStateManager.setSelectedScreen({
            modules,
            moduleSelected,
            screenSelected
        });
    }

    static ubdateMenuModuleData({
        menuStructureData,
        screenSelected,
        moduleSelected,
        dispatch,
        ubdateModule
    }: UbdateMenuModuleData) {
        if (
            screenSelected != menuStructureData.screenSelected[0] &&
            screenSelected != menuStructureData.screenSelected[1]
        ) {
            dispatch(
                ubdateModule({
                    ...MenuStateManager.setSelectedScreen({
                        modules: menuStructureData.modules,
                        moduleSelected,
                        screenSelected
                    })
                })
            );
        }
    }

    static setSelectedModule(modules: ModuleMenuData[], moduleSelected: string | number | null) {
        return {
            modules: modules.map((module) => {
                let isExpanded = false;

                if (moduleSelected == module.id || moduleSelected == module.code) {
                    isExpanded = true;
                }
                return {
                    ...module,
                    screens: module.screens.map((screen) => {
                        return {
                            ...screen,
                            isSelected: false
                        };
                    }),
                    isExpanded
                };
            }),
            screenSelected: []
        };
    }

    static getIsCodeOrId(screen: string) {
        return Number.isInteger(parseInt(screen)) ? 'id' : 'code';
    }

    static getActualScreen({
        menuData,
        screen
    }: {
        menuData: ModuleMenuData[];
        screen: string | null;
    }) {
        if (!screen) {
            return null;
        }
        const type = MenuStateManager.getIsCodeOrId(screen);
        for (const el of menuData) {
            const screenSelected = el.screens.find((screenObj) => {
                return screenObj[type] == screen;
            });
            if (screenSelected) return screenSelected;
        }

        return null;
    }

    static setSelectedScreen({ modules, screenSelected, moduleSelected }: SetSelectedModule): {
        moduleExpanded: ModuleExpanded;
        screenSelected: ScreenSelected;
    } {
        let screenSelectedToSave: ScreenSelected = [];
        let moduleExpandedToSave: ModuleExpanded = '';
        const searchBy = Number(screenSelected) ? 'id' : 'code';
        modules.forEach((module) => {
            module.screens.forEach((screen) => {
                if (screen[searchBy] == screenSelected) {
                    screenSelectedToSave = [screen.id, screen.code];
                    moduleExpandedToSave = module.id;
                }
            });
        });
        return {
            moduleExpanded: moduleExpandedToSave,
            screenSelected: screenSelectedToSave
        };
    }
}
