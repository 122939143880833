import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
    ObjectNotBillState,
    ObjectNotBillStateData,
    ObjectNotBillTypeOfGroupingByWorker
} from './types';
import { DataObjectNotBill } from 'src/api/src/objectNotBillScreen/types';

export const initialState: ObjectNotBillState = {
    data: {
        params: {
            grupowanie_po_pracownikach: false,
            grupowanie_po_pracownikach_2: false,
            rowniez_biezacy: false,
            pracownik: '0',
            pracownik_2: '0'
        },
        contracts: [],
        kontrakty: {},
        pracownicy: {},
        nazwa_kontraktu: ''
    },
    isLoading: true
};

const objectNotBillSlice = createSlice({
    name: 'objectsNotBill',
    initialState,
    reducers: {
        setObjectNotBillData: (
            state: ObjectNotBillState,
            action: PayloadAction<DataObjectNotBill>
        ) => {
            state.data = prepareObjectNotBillDataFromApi(action.payload);
            state.isLoading = false;
        },
        setIsLoading: (state: ObjectNotBillState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setWorkers: (
            state: ObjectNotBillState,
            action: PayloadAction<{
                typeWorker: ObjectNotBillTypeOfGroupingByWorker;
                worker: string;
            }>
        ) => {
            state.data.params = {
                ...state.data.params,
                [action.payload.typeWorker]: action.payload.worker
            };
        },
        clearStateObjectNotBillData: (state: ObjectNotBillState) => {
            state.isLoading = true;
            state.data = {
                params: {
                    grupowanie_po_pracownikach: false,
                    grupowanie_po_pracownikach_2: false,
                    rowniez_biezacy: false,
                    pracownik: '0',
                    pracownik_2: '0'
                },
                contracts: [],
                kontrakty: {},
                pracownicy: {},
                nazwa_kontraktu: ''
            };
        }
    }
});

export const { setObjectNotBillData, setIsLoading, setWorkers, clearStateObjectNotBillData } =
    objectNotBillSlice.actions;

export default objectNotBillSlice.reducer;

function prepareObjectNotBillDataFromApi(dataApi: DataObjectNotBill): ObjectNotBillStateData {
    const contracts = Object.entries(dataApi.kontrakty);
    return {
        contracts,
        ...dataApi
    };
}
