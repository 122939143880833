import { ListScreenLocationState } from 'src/components/listScreen/pages/objectList/types';
import {
    ListPagingData,
    ListSearchFilters,
    ListSortProperty,
    TransformationsState
} from 'src/store/src/listScreen/transformations/types';

export const makeListScreenLocationData = (
    paging: ListPagingData | undefined,
    filters: ListSearchFilters | undefined,
    sortingData: ListSortProperty[] | undefined,
    listTransformations: TransformationsState
): ListScreenLocationState => ({
    params: {
        actualPage: paging?.actualPage ?? listTransformations.paging.actualPage,
        objectsPerPage: paging?.objectsPerPage ?? listTransformations.paging.objectsPerPage
    },
    legacyParams: {},
    store: {
        filters: filters ?? listTransformations.filters,
        sortingData: sortingData ?? listTransformations.sortingData
    }
});
