import { FieldPriceNumberManager } from 'src/components/popupFormExtended/dynamicField/fieldPriceNumber/FieldPriceNumberManager';
import { FieldPriceNumber } from 'src/components/popupFormExtended/dynamicField/fieldPriceNumber/types';

import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { NumberManager } from 'src/utils/src/shared/NumberManager';

type FieldDependentPriceNumberManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldPriceNumber;
};

export class FieldDependentPriceNumberManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldPriceNumber;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentPriceNumberManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async przeliczWartosc() {
        if (!this.fieldWasTriggered.isUpdatedCalc) {
            await this.calc();
            await this.runNested(this.fieldWasTriggeredDynamicFieldT);
        }
    }

    private async calc() {
        const data = this.getValuesToSet();

        let result = 0;
        let value1 = 0;
        let value2 = 0;
        let update = false;
        let vat;

        switch (data.one.typ) {
            case 'cena':
                value1 = data.one.netto;
                break;

            case 'cena_liczba':
                value1 = data.one.netto * data.one.number;
                break;
            case 'liczba':
                if (!isNaN(data.one.number)) {
                    value1 = data.one.number;
                }
                break;
            case 'koszty':
                value2 = data.one.number;
                break;
            default:
                break;
        }

        switch (data.two.typ) {
            case 'cena':
                value2 = data.two.netto;
                vat = data.two.vat;
                break;
            case 'cena_liczba':
                value2 = data.two.netto * data.two.number;
                vat = data.two.vat;
                break;
            case 'liczba':
                if (!isNaN(data.two.number)) {
                    value2 = data.two.number;
                }
                break;
            case 'koszty':
                value2 = data.two.number;
                break;
            case 'lista':
                vat = data.two.vat;
                break;
            default:
                break;
        }

        if (this.fieldSuperiorOneDynamicFieldT?.pole_do_wyliczen_1_procent === 't') {
            value1 = value1 / 100;
        }
        if (this.fieldSuperiorTwoDynamicFieldT?.pole_do_wyliczen_2_procent === 't') {
            value2 = value2 / 100;
        }
        switch (this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie) {
            case 'cnt()':
            case '=':
            case '*VAT':
            case '=godziny':
                result = value1;
                update = true;
                break;
            case '+':
            case '-':
            case '*':
            case '/':
                {
                    const datanew = NumberManager.calcArithmeticValue(
                        value1,
                        value2,
                        this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie
                    );
                    result = Math.round(datanew * data.round) / data.round;
                    update = true;
                }
                break;
            default:
                break;
        }
        const newVal = new FieldPriceNumberManager({
            oldValue: this.fieldWasTriggered.value,
            value: String(result),
            changeType: 'netto',
            vatOptional: vat
        }).calc();

        const value = {
            ...this.fieldWasTriggered,
            value: newVal
        };
        if (update) {
            this.setTempFieldsState(value, 'isUpdatedCalc');
            this.isRunNested = true;
        }
    }
}
