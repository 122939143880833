import { Department, Resource } from '../../../store/src/calendar/resources/types';

/**
 * Function check if every employee is assigned at least one department. If not, creates special department for those unassigned
 * @throws when department is empty array
 */
export function createDepartmentForUnassignedEmployees(
    deps: Department[],
    emps: Resource[]
): [Department[], Resource[]] {
    if (!deps.length) throw new TypeError('Empty departments array');

    // Check if at least one employee without department
    const empsWithoutDepExist = emps.some((e) => !e.departments || !e.departments.length);

    // return untouched data if none exist
    if (!empsWithoutDepExist) return [deps, emps];
    const everyDepSelected = deps.every((d) => d.selected);
    const unassignedDep: Department = { id: -1, name: 'Nieprzypisani', selected: everyDepSelected };
    const updatedDeps = [...deps, unassignedDep];
    const updatedEmps = emps.map((e) => ({
        ...e,
        departments: e.departments && e.departments.length ? e.departments : [-1]
    }));

    return [updatedDeps, updatedEmps];
}
