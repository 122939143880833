import { BaseApiResponse } from '../../types';

export async function getFieldAdditionalData({
    url,
    method = 'POST',
    params,
    body
}: {
    url: string;
    method?: 'POST' | 'GET';
    params?: {
        [key: string]: any;
    };
    body?: any;
}): Promise<BaseApiResponse<any>> {
    let urlAll = url;
    if (params) {
        const paramsToApi = new URLSearchParams(Object.entries(params));
        urlAll = `${url}?${paramsToApi.toString()}`;
    }

    const response = await fetch(`api_react/src/popupFormExtended/${urlAll}`, {
        method,
        credentials: 'include', // include, *same-origin, omit
        body: body
    });
    const dataPrimary = await response.text();

    let dataParsed = null;
    try {
        dataParsed = JSON.parse(dataPrimary);
    } catch (e) {
        return { success: false, errorCode: 500, data: null, message: e } as any;
    }

    return dataParsed;
}
