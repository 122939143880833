import React, { FC } from 'react';

import { useTypedSelector, useAppDispatch } from 'src/store';
import { ApiActionBehavior, FileDownloadBehavior } from 'src/data/types';
import { useSearchParam } from 'src/hooks';
import { FileManager } from 'src/utils';
import { setProgress } from 'src/store/src/general';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { FileDownloadProps } from 'src/components/shared/molecules/actionType/fileDownload/types';
import { TemplateMainManager } from 'src/utils/src/shared/TemplateMainManager';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';

const FileDownload: FC<FileDownloadProps> = ({ children, action, rowId, clickCallback }) => {
    const screen = useSearchParam('obiekt');
    const behaviour = action.behaviour as FileDownloadBehavior;
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const totalIds = useTypedSelector((state) => state.listScreenTableData.totalIds);
    const objectSlice = useTypedSelector((state) => state.object);
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const dispatch = useAppDispatch();
    const [isDownloaded, setIsDownloaded] = React.useState<boolean>(false);
    const [isClicked, setIsCliced] = React.useState<boolean>(false);
    const additional = { sectionId: '', sectionIdx: 0, typeSection: null };
    const sourceOfTrigger: SourceOfTrigger = { type: 'list' };

    const totalIdsIsActual = useTypedSelector(
        (state) => state.listScreenTableData.totalIdsIsActual
    );

    const download = async () => {
        setIsDownloaded(true);
        const { payload, params, body } = new TemplateMainManager({
            rowData,
            rowId,
            transformationState,
            actionBehavior: action.behaviour as ApiActionBehavior,
            action,
            objectSlice,
            sourceOfTrigger,
            additional,
            screen,
            totalIds
        }).getData();

        await FileManager.handleDownloadFile({
            url: behaviour?.data?.url,
            params,
            body,
            payload,
            dispatch,
            setProgress,
            setSpinner
        });
    };
    const handleFileDownload = async () => {
        if (isDownloaded) return;
        dispatch(setSpinner(true));
        if (action.isAllIdsRequired && !totalIdsIsActual) {
            setIsCliced(true);
            dispatch(setSpinner(true));
            setTimeout(() => {
                dispatch(setSpinner(false));
            }, 600000);
            return;
        }
        await download();

        clickCallback && clickCallback();
    };

    React.useEffect(() => {
        if (isClicked && totalIdsIsActual) {
            (async () => {
                await download();
                setIsCliced(false);
            })();
        }
    }, [totalIdsIsActual]);

    React.useEffect(() => {
        if (isDownloaded) {
            setTimeout(() => {
                setIsDownloaded(false);
            }, 1000);
        }
    }, [isDownloaded]);

    return <div onClick={() => handleFileDownload()}>{children}</div>;
};

export default FileDownload;
