export const getUnusualSearchParams = () => {
    const parameters = new URLSearchParams(window.location.search);

    parameters.delete('php_url');
    parameters.delete('id_okna');
    parameters.delete('obiekt');
    parameters.delete('id');
    parameters.delete('screen');
    parameters.delete('objectId');

    return parameters;
};
