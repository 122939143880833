import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import {
    DynamicFormObjectDateCorrectnessValidatorProps,
    TypeValidateDateCorrectness
} from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { getValuesFromFields } from 'src/utils';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

// można testowac na ekranie zadania poprzez ustawienie godziny konca (termin  wykonania) wczesniej niz rozpoczecia (data rozpoczecia)

const typeFnValidate = 'validateDateCorrectness';
export class DynamicFormObjectDateCorrectnessValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateDateCorrectness;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectDateCorrectnessValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        const codeStart =
            this.popupFormExtendedData.environmentVariables.kalendarz_pole_data_rozp?.kod;
        const codeEnd =
            this.popupFormExtendedData.environmentVariables.kalendarz_pole_data_zak?.kod;

        if (codeStart && codeEnd) {
            const dataStart = getValuesFromFields(this.formState.fields[codeStart]);
            const dataEnd = getValuesFromFields(this.formState.fields[codeEnd]);
            if (dataStart.id === dataEnd.id && dataStart.typ === dataEnd.typ) {
                if (dataStart.timestampAllEndHour < dataStart.timestampAll) {
                    this.setValidationResult({
                        typeOfInfo: 'Error',
                        type: {
                            issue: this.typeFnValidate,
                            title: validationData[this.typeFnValidate].title,
                            id_zakladki: dataStart.id_zakladki,
                            message: validationData[this.typeFnValidate].message
                        }
                    });
                }
            } else {
                if (dataStart.timestampAll > dataEnd.timestampAll) {
                    this.setValidationResult({
                        typeOfInfo: 'Error',
                        type: {
                            issue: this.typeFnValidate,
                            title: validationData[this.typeFnValidate].title,
                            id_zakladki: dataStart.id_zakladki,
                            message: validationData[this.typeFnValidate].message
                        }
                    });
                }
            }
        }
    }
}
