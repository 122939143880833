import React from 'react';

import { ContentPreviewItem } from 'src/components/listScreen/organisms/popupContentPreview/PopupContentPreview';
import { useTypedSelector } from 'src/store';
import { usePopupState } from 'src/context/globalPopup/popupContext';
import { ObjectDataManager } from 'src/store/src/object/object/ObjectDataManager';
import { PopupState } from 'src/store/src/popup/popup/types';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { SystemAction } from 'src/data/types';
import { ObjectSlice, RelationTreeCardElement } from 'src/store/src/object/object/types';

export const usePopupContentPreviewData = (keyPopup: string) => {
    const { popupStateRedux } = usePopupState(keyPopup);
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const objectSlice = useTypedSelector((state) => state.object);
    const [allDataPreview, setAllDataPreview] = React.useState<ContentPreviewItem[]>([]);
    React.useEffect(() => {
        if (popupStateRedux.sourceOfTrigger.type === 'list') {
            const dataAllPreview = getDataPreviewForList({
                rowData,
                rowId: popupStateRedux.objectId[0],
                action: popupStateRedux.action
            });
            setAllDataPreview(dataAllPreview);
        } else if (popupStateRedux.sourceOfTrigger.type === 'object') {
            const dataAllPreview = getDataPreviewForObject({
                objectSlice,
                popupState: popupStateRedux
            });
            setAllDataPreview(dataAllPreview);
        } else if (popupStateRedux.sourceOfTrigger.type === 'popupFormExtended') {
            setAllDataPreview([
                {
                    rawValue: popupStateRedux.sourceOfTrigger.fileId,
                    text: popupStateRedux.sourceOfTrigger.fileName,
                    isSelected: true
                }
            ]);
        } else if (popupStateRedux.sourceOfTrigger.type === 'instruction') {
            setAllDataPreview([
                {
                    rawValue: popupStateRedux.sourceOfTrigger.file.id,
                    text: popupStateRedux.sourceOfTrigger.file.name,
                    isSelected: true,
                    mozliwe_pobieranie: popupStateRedux.sourceOfTrigger.file.mozliwe_pobieranie
                }
            ]);
        } else if (popupStateRedux.sourceOfTrigger.type === 'startupCardTree') {
            setAllDataPreview(getDataStartupCardTree(popupStateRedux.sourceOfTrigger.elements));
        }
    }, []);
    return {
        allDataPreview,
        setAllDataPreview
    };
};

const getDataPreviewForObject = ({
    objectSlice,
    popupState
}: {
    objectSlice: ObjectSlice;
    popupState: PopupState;
}) => {
    const contentPreviewItem: ContentPreviewItem[] = [];
    const data = new ObjectDataManager({
        objectSlice,
        additional: popupState.objectAdditional
    }).getData();

    if (popupState.objectAdditional.relationCardType === 'tree') {
        if (data.relationTreeCardElementsFlatten) {
            if (popupState.action?.code === 'podglad_z_zal') {
                data.relationTreeCardElementsFlatten.forEach((item) => {
                    if (item.fileId) {
                        contentPreviewItem.push({
                            rawValue: String(item.fileId),
                            text: item.name,
                            isSelected:
                                String(item.fileId) ===
                                popupState.objectAdditional.relationCardTreeElementFileId
                        });
                        if (item.attachmentsData.length) {
                            item.attachmentsData.forEach((item2) => {
                                contentPreviewItem.push({
                                    rawValue: String(item2.fileId),
                                    text: item2.name,
                                    isSelected: false
                                });
                            });
                        }
                    }
                });
            } else {
                data.relationTreeCardElementsFlatten.forEach((item) => {
                    if (item.fileId) {
                        contentPreviewItem.push({
                            rawValue: String(item.fileId),
                            text: item.name,
                            isSelected:
                                String(item.fileId) ===
                                popupState.objectAdditional.relationCardTreeElementFileId
                        });
                    }
                });
            }
        }
        const found = contentPreviewItem.find((item) => item.isSelected);
        if (!found) {
            return contentPreviewItem.map((item, i) => {
                if (i === 0) {
                    return {
                        ...item,
                        isSelected: true
                    };
                }
                return item;
            });
        }
    } else if (popupState.objectAdditional.relationCardType === 'list') {
        const codeColumn = popupState.action?.behaviour?.data?.data?.fileFieldCode;
        if (codeColumn) {
            const rawValue = new ObjectDataManager({
                objectSlice,
                additional: popupState.objectAdditional
            }).getCellRawValueOrText({
                key: codeColumn,
                type: 'rawValue',
                onlyFirstValue: true
            });

            const text = new ObjectDataManager({
                objectSlice,
                additional: popupState.objectAdditional
            }).getCellRawValueOrText({
                key: codeColumn,
                type: 'text',
                onlyFirstValue: true
            });

            contentPreviewItem.push({
                rawValue,
                text,
                isSelected: true
            });
        }
    } else {
        if (data.cellValue) {
            contentPreviewItem.push({
                rawValue: String(data.cellValue.rawValue),
                text: String(data.cellValue.text),
                isSelected: true
            });
        }
    }

    return contentPreviewItem;
};

const getDataPreviewForList = ({
    rowData,
    action,
    rowId
}: {
    rowData: RowData[];
    action: SystemAction | null;
    rowId: RowDataId;
}) => {
    const data: ContentPreviewItem[] = [];
    const codeColumn = action?.behaviour?.data?.data?.fileFieldCode;
    if (codeColumn) {
        rowData.forEach((item) => {
            const rawValue = item.cellValues?.[codeColumn][0]?.rawValue;
            const text = item.cellValues?.[codeColumn][0]?.text;
            if (Array.isArray(rawValue) && Array.isArray(text)) {
                rawValue.forEach((item2, i) => {
                    let isSelected = false;
                    if (item.id === rowId && i === 0) {
                        isSelected = true;
                    }
                    data.push({
                        rawValue: item2,
                        text: text[i],
                        isSelected
                    });
                });
            } else {
                let isSelected = false;
                if (item.id === rowId) {
                    isSelected = true;
                }
                data.push({
                    rawValue,
                    text: typeof text === 'string' ? text : '',
                    isSelected
                });
            }
        });
    }
    return data;
};

function getDataStartupCardTree(elements: RelationTreeCardElement[]) {
    const contentPreviewItem: ContentPreviewItem[] = [];
    if (!elements) {
        return [];
    }
    elements?.forEach((item, i) => {
        const isSelected = i === 0 ? true : false;
        contentPreviewItem.push({
            rawValue: item.fileId,
            text: item.name,
            isSelected
        });
        item.attachmentsData.forEach((attachment) => {
            contentPreviewItem.push({
                rawValue: attachment.fileId,
                text: attachment.name,
                isSelected
            });
        });
    });
    return contentPreviewItem;
}
