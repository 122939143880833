import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { useAppDispatch } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Button } from '@mui/material';
import ContentScroll from 'src/components/shared/molecules/contentScroll/ContentScroll';
import styles from './styles.module.css';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { usePressKeyWithKeyCode } from 'src/hooks';
import { PopupContentProps } from 'src/components/shared/organisms/globalPopup/types';
import { PopupStateConfirmation } from 'src/store/src/popup/popup/types';

export const PopupContentConfirmationTwo: FC<PopupContentProps<PopupStateConfirmation>> = ({
    popup
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleOnClick = () => {
        if (popup.submitCallbackEventId) EventBus.$emit(popup.submitCallbackEventId);
        dispatch(closePopup(popup.key));
    };

    const onClose = () => {
        dispatch(closePopup(popup.key));
    };

    usePressKeyWithKeyCode(handleOnClick, 'Enter');
    return (
        <PopupTemplateThird title={popup.title}>
            <div className={styles.wrapper}>
                <ContentScroll maxWidth={800} maxHeight={300}>
                    <p
                        style={{
                            color: theme.palette.primary.main,
                            fontSize: '16px',
                            padding: '5px 0'
                        }}
                        dangerouslySetInnerHTML={
                            popup.messageInjectHtml ? { __html: popup.message } : undefined
                        }>
                        {popup.messageInjectHtml ? null : popup.message}
                    </p>
                </ContentScroll>
                <div className={styles.box}>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={handleOnClick}>
                        <p>{globalPopup.contentConfirmation.button.yes}</p>
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={() => onClose()}>
                        <p>{globalPopup.contentConfirmation.button.no}</p>
                    </Button>
                </div>
            </div>
        </PopupTemplateThird>
    );
};
