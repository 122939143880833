import React, { FC } from 'react';
import PopupTemplateHead from 'src/components/shared/molecules/popupTemaplateHead/PopupTemplateHead';
import styles from './PopupTemplateFirst.module.css';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { usePressKeyWithKeyCode } from 'src/hooks';
/**
 * Describes main menu, handle all mechanics related to menu data
 */

type Props = {
    title: string;
    buttonTxt: string;
    onClick: () => void;
};

const PopupTemplateFirst: FC<Props> = ({ children, title, buttonTxt, onClick }) => {
    usePressKeyWithKeyCode(onClick, 'Enter');
    return (
        <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
            <PopupTemplateHead title={title} />
            <div className={styles.box}>{children}</div>
            <div className={styles.box2}>
                <ButtonCustom onClick={onClick} preventMultipleClick={true}>
                    <p className={`${styles.button}`}>{buttonTxt}</p>
                </ButtonCustom>
            </div>
        </div>
    );
};

export default PopupTemplateFirst;
