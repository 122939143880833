import { fetchConfigData } from 'src/api/src/configurationScreen/fetchConfig';
import { AppThunk } from 'src/store';
import { setInitConfigData } from 'src/store/src/configScreen/config/configSlice';
import { ApiError } from 'src/utils';

export const fetchAndLoadInitialConfigScreenData = (): AppThunk => async (dispatch) => {
    const apiResponse = await fetchConfigData();

    if (!apiResponse.success) {
        // instead of window.location.replace(`/message?tytul=${apiResponse.message}`); show popup, to not crash logout made by CheckTime.tsx
        ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
        throw new Error('fetchAndLoadInitialObjectData: not get InitialObjectScreenData ');
    }
    dispatch(setInitConfigData(apiResponse.data));
};
