import { AutocompleteOption } from 'src/data/uiTypes/autocompleteTypes';
import { sortArrayOfObject } from 'src/utils/src/shared/sortArrayOfObject';

type SortAutocompleteOptionsProps = {
    op: AutocompleteOption[];
    type?: '-1' | '0';
};

export function sortAutocompleteOptions({ op, type }: SortAutocompleteOptionsProps) {
    if (type === '0') {
        return sortArrayOfObject(op, 'text', 'ASC');
    } else {
        return op;
    }
}
