import React, { FC, useEffect } from 'react';
import { getPopupFormExtendedData } from 'src/api/src/popupFormExtended/getPopupFormExtendedData';
import { PopupFormExtendedBehavior } from 'src/data/popupFormTypes';
import { SystemAction } from 'src/data/types';
import {
    useLoadScreenParamPageTitle,
    useRunAllHooksOnEachRenderReactPage,
    useSearchParam
} from 'src/hooks';
import { AppDispatch, useAppDispatch } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { setPopupInfoTwo } from 'src/store/src/popup';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { generateToken, getUnusualSearchParams } from 'src/utils';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { getPhpHiddenFormDataEntries } from 'src/utils/src/shared/makeFormData';

const AddObjectPage: FC = () => {
    useRunAllHooksOnEachRenderReactPage();
    useLoadScreenParamPageTitle();

    const dispatch: AppDispatch = useAppDispatch();

    const screen = useSearchParam('obiekt');

    const getHiddenData = (): { [key: string]: string } | undefined => {
        const dataString = sessionStorage.getItem('phpHiddenFormData');
        if (dataString) {
            const returnData: { [key: string]: string } = {};
            const hiddenData = getPhpHiddenFormDataEntries(dataString);
            sessionStorage.removeItem('phpHiddenFormData'); // remove item so next nav won't use it
            for (const [key, value] of hiddenData) {
                if (Array.isArray(value)) {
                    for (const subValue of value) {
                        if (typeof subValue !== 'string') {
                            console.error('AddObjectPage: get phpHiddenFormData value object');
                            continue;
                        }
                        returnData[key] = subValue;
                    }
                } else {
                    if (typeof value !== 'string') {
                        console.error('AddObjectPage: get phpHiddenFormData value object');
                        continue;
                    }
                    returnData[key] = value;
                }
            }
            return returnData;
        }
    };

    const makeAddObjectAction = (
        screen: string
    ): SystemAction & { behaviour: PopupFormExtendedBehavior } => ({
        name: 'Dodaj API',
        code: 'add-api',
        icon: {
            faCode: 'fa-plus',
            svgFileName: 'dodaj.svg'
        },
        colorConditions: null,
        displayConditions: null,
        behaviour: {
            type: 'popupFormExtended',
            data: {
                objectId: 'nowy',
                screen: screen,
                additionalParameters: getUnusualSearchParams().toString(),
                phpHiddenData: getHiddenData()
            }
        }
    });

    const showPopup = async () => {
        if (!screen) {
            console.error('AddObjectPage: screen param is null');
            return;
        }
        dispatch(setSpinner(true));

        const action = makeAddObjectAction(screen);

        const responseData = await getPopupFormExtendedData(
            action.behaviour.data.screen,
            action.behaviour.data.objectId,
            action.behaviour.data.additionalParameters,
            action.behaviour.data.phpHiddenData
        );

        if (!responseData.success) {
            dispatch(setSpinner(false));

            const closeCallbackEventId = `AddObjectPage-api-error-close-window-${generateToken()}`;
            EventBus.$on(closeCallbackEventId, () => {
                EventBus.$off(closeCallbackEventId);
                window.close();
            });

            dispatch(
                setPopupInfoTwo({
                    message: responseData.message,
                    title: 'Błąd',
                    messageInjectHtml: true,
                    closeCallbackEventId: closeCallbackEventId
                })
            );
            return;
        }

        new PopupManager({ dispatch, action }).setPopupDynamicExtended({
            popupFormExtendedData: {
                ...responseData.data,
                objectId: action.behaviour.data.objectId,
                screen: action.behaviour.data.screen
            }
        });
        dispatch(setSpinner(false));
    };

    useEffect(() => {
        showPopup();
    }, []);

    return <div></div>;
};

export default AddObjectPage;
