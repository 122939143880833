import React from 'react';
import { Typography } from '@mui/material';
import { SecondFAForm } from '../../molecules/secondFAForm/SecondFAForm';
import LoginMiddleSectionWrapper from 'src/components/loginScreen/molecules/loginMiddleSectionWrapper/LoginMiddleSectionWrapper';

const SecondFAMiddleSection = () => {
    return (
        <LoginMiddleSectionWrapper>
            <Typography component="h1" fontSize="large">
                Przeprowadź drugi etap weryfikacji
            </Typography>

            <SecondFAForm />
        </LoginMiddleSectionWrapper>
    );
};

export default SecondFAMiddleSection;
