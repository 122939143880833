import { useSearchParam } from 'src/hooks';

export function useSelectedModuleAndScreen() {
    let screenSelected = null;
    let moduleSelected = null;
    const obiekt = useSearchParam('obiekt');
    const modul = useSearchParam('modul');
    const ekran = useSearchParam('ekran');
    if (obiekt) {
        screenSelected = obiekt;
    } else if (ekran) {
        screenSelected = ekran;
    } else if (modul) {
        moduleSelected = modul;
    }
    return {
        screenSelected,
        moduleSelected
    };
}
