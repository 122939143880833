import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiMassEditObjectsData } from './types';

export async function getMassEditObjectsInitData(
    param: string
): Promise<BaseApiResponse<ApiMassEditObjectsData>> {
    return simpleQueryAPIData(
        `api_react/src/massEditObjectsScreen/getMassEditObjectsInitData.php?${param}`,
        'GET'
    );
}
