import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldDateRelativeObjectProps } from './types';
import { UiFieldDateRelative } from 'src/components/popupFormExtended/dynamicField/fieldDateRelative/UiFieldDateRelative';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { fieldRelativeOp } from 'src/constants/optionsForSelects';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { FieldRangeDateRelativeBasicType } from 'src/data/fieldsFormType';

export const FieldDateRelativeObject: FC<FieldDateRelativeObjectProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const [state, setState] = React.useState(field.value);

    const onChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: {
                ...field.value,
                zakres: e.target.value as FieldRangeDateRelativeBasicType
            }
        };
        setState((prev) => {
            return {
                ...prev,
                zakres: e.target.value as FieldRangeDateRelativeBasicType
            };
        });
        dispatch({
            type: 'data_wzgledna',
            payload: {
                code: keyOfFieldInState,
                value: newValue
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newValue,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        const newValue = {
            ...field,
            value: {
                ...field.value,
                ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
                date: e.target.value
            }
        };
        setState((prev) => {
            return {
                ...prev,
                date: e.target.value
            };
        });
        if (onBlur) {
            dispatch({
                type: 'data_wzgledna',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: {
                            ...field.value,
                            date: e.target.value
                        }
                    }
                }
            });
            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: newValue,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        }
    };

    return (
        <UiFieldDateRelative
            value={state}
            opcje={fieldRelativeOp}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            onChangeSelect={onChangeSelect}
            onChangeInput={(e) => onChangeInput(e, false)}
            onBlurInput={(e) => onChangeInput(e, true)}
        />
    );
};
