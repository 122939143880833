import React from 'react';
import { useTypedSelector } from 'src/store';

export const useIsCondensedView = () => {
    const isCondensedView = useTypedSelector((state) => state.listScreenTableData.isCondensedView);

    const data = React.useMemo(() => {
        if (isCondensedView) {
            return {
                heightCellScroll: 18,
                heightCell: 20,
                isCondensedView
            };
        } else {
            return {
                heightCellScroll: 58,
                heightCell: 60,
                isCondensedView
            };
        }
    }, [isCondensedView]);

    return data;
};
