import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { DataApiMassEditObjects } from 'src/hooks/src/massEditObjectsScreen/useSubmitMassEditObjects';

export async function saveMassEditObjects(
    data: DataApiMassEditObjects
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        `api_react/src/massEditObjectsScreen/saveMassEditObjects.php?${data.params.toString()}`,
        'POST',
        data.body,
        true
    );
}
