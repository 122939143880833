import { combineFullUrl } from 'src/utils';

export const updateIdWindow = () => {
    const element = document.querySelector('[name=id_okna]');
    const idOkna = (element as HTMLInputElement)?.value;
    if (idOkna) {
        let params = new URLSearchParams(window.location.search);
        params.set('id_okna', String(idOkna));
        const url = window.location.origin + window.location.pathname;
        const href = combineFullUrl(url, params);
        window.history.replaceState({ ...window.history.state }, '', href);
    }
};
