import { DynamicFormObjectInvoiceBillValidatorProps } from './types.d';
import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { TypeValidateInvoiceBill } from './types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { DateManager, getValuesFromFields } from 'src/utils';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';

const typeFnValidate = 'validateInvoiceBill';
export class DynamicFormObjectInvoiceBillValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateInvoiceBill;
    usedForAddingObject: boolean;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef,
        usedForAddingObject
    }: DynamicFormObjectInvoiceBillValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
        this.usedForAddingObject = usedForAddingObject;
    }
    async validate() {
        const ev = this.popupFormExtendedData.environmentVariables;
        if (ev.ksiegowy === 't' && this.usedForAddingObject) {
            const params = this.getParams();

            const res = await getFieldAdditionalData({
                url: 'json_sprawdz_date_wystawienia.php',
                method: 'GET',
                params: params
            });

            if (res.data && !res.data.valid) {
                this.setValidationResult({
                    typeOfInfo: 'Error',
                    type: {
                        issue: 'validateInvoiceBill',
                        message: res.data.message,
                        title: validationData[this.typeFnValidate].title,
                        id_zakladki: params.id_zakladki
                    }
                });
            }
        }
    }

    private getParams() {
        let params: { [key: string]: any } = {
            id_obiektu: this.fieldRelatedObjectData.id,
            ekran: this.fieldRelatedObjectData.screen
        };
        const ev = this.popupFormExtendedData.environmentVariables;

        if (ev.ksiegowy_pole_data_wystawienia?.kod) {
            const data = getValuesFromFields(
                this.formState.fields[ev.ksiegowy_pole_data_wystawienia.kod]
            );
            params.id_zakladki = data.id_zakladki;
            const date = DateManager.prepareDayMonthYearFromDate(new Date(data.timestampAll));
            params.data_wystawienia = date;
        }
        if (ev.ksiegowy_pole_wystawcy) {
            const data = getValuesFromFields(this.formState.fields[ev.ksiegowy_pole_wystawcy.kod]);
            params.wystawca = data.valueList;
        }

        if (ev.ksiegowy_pole_numeru) {
            params.id_pola_numeru = ev.ksiegowy_pole_numeru.id;
        }

        if (ev.ksiegowy_pole_wystawcy) {
            params.kod_pola_wystawca = ev.ksiegowy_pole_wystawcy.kod;
        }

        if (ev.ksiegowy_pole_data_wystawienia) {
            params.kod_pola_data_wystawienia = ev.ksiegowy_pole_data_wystawienia.kod;
        }

        return params;
    }
}
