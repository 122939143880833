import { TransformationSlice, SetTransformationsAction } from './types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: TransformationSlice = {
    2: {
        204: {
            123: {
                side: {
                    filter: 'lina'
                }
            }
        }
    }
};

const transformationSlice = createSlice({
    name: 'object',
    initialState,
    reducers: {
        setTransformations: (
            state: TransformationSlice,
            action: PayloadAction<SetTransformationsAction>
        ) => {
            const { sectionId, relationId, activeTabId, data } = action.payload;
            state[activeTabId] = state[activeTabId] ?? {};
            state[activeTabId][sectionId] = state[activeTabId][sectionId] ?? {};
            state[activeTabId][sectionId][relationId] = {
                ...state[activeTabId][sectionId][relationId],
                ...data
            };
        }
    }
});

export const { setTransformations } = transformationSlice.actions;

export default transformationSlice.reducer;
