import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

/**
 * decode given code to navigation system data
 */
export async function unmaskUrl(
    code: string
): Promise<BaseApiResponse<{ url: string; locationState: any }>> {
    return simpleQueryAPIData('api_react/src/shared/unmaskUrl.php', 'POST', { code });
}
