import { DynamicFieldT } from 'src/data/popupFormTypes';
import { legacyApiParamToBool } from 'src/utils/src/shared/legacyApiParamToBool';

export function calcIsHiddenOfField(field: DynamicFieldT, usedForAddingObject: boolean) {
    const ukryte = legacyApiParamToBool(field.ukryte);
    return (
        ukryte ||
        (field.dodawanie !== 't' && usedForAddingObject) ||
        (field.widoczne_tylko_w_dodawaniu === 't' && !usedForAddingObject)
    );
}
