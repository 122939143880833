import * as actionTypes from '../../constants/actions';

export const initialState = {
    groups: {},
    users: {},
    types: []
};

export default function userReducer(state = initialState, action) {
    const { type, payload } = action;
    const { groups } = state;

    switch (type) {
        case actionTypes.SET_CHAT_USER: {
            return {
                ...state,
                ...payload.user,
                groups: state.groups,
                users: state.users
            };
        }

        case actionTypes.SET_GROUPS: {
            return {
                ...state,
                groups: payload.groups
            };
        }

        case actionTypes.SET_USERS: {
            return {
                ...state,
                users: payload.users
            };
        }

        case actionTypes.ADD_GROUP: {
            return {
                ...state,
                groups: {
                    ...groups,
                    [payload.group.id]: payload.group
                }
            };
        }

        case actionTypes.DELETE_GROUP: {
            const groups = { ...state.groups };
            delete groups[payload.id];
            return {
                ...state,
                groups: groups
            };
        }

        case actionTypes.SET_USER_ACTIVE_STATUS: {
            if (!state.users[payload.id]) {
                return state;
            }

            const user = { ...state.users[payload.id], activeStatus: payload.boolean };

            return {
                ...state,
                users: {
                    ...state.users,
                    [payload.id]: user
                }
            };
        }

        case actionTypes.REPLACE_TEMPORARY_ID: {
            const { tempID, roomID } = payload;

            if (!state.groups[tempID]) {
                return state;
            }

            let { [tempID]: group, ...groups } = state.groups;

            group = { ...group, id: roomID, roomID };
            groups[roomID] = group;

            return {
                ...state,
                groups
            };
        }

        case actionTypes.UPDATE_LAST_MESSAGE: {
            const { timestamp, room } = payload;
            const isGroup = room.type === 'group';

            /* update group or user based on room type */
            if (isGroup) {
                let { [room.id]: group, ...groups } = state.groups;
                group = { ...group, lastMessage: timestamp };
                groups[room.id] = group;
                return {
                    ...state,
                    groups
                };
            } else {
                let { [room.id]: user, ...users } = state.users;
                user = { ...user, lastMessage: timestamp };
                users[room.id] = user;
                return {
                    ...state,
                    users
                };
            }
        }

        default: {
            return state;
        }
    }
}

/**
 * @description - Wycinek stanu aplikacji dotyczący głównego użytkownika, pozostałych użytkowników i grup
 * @typedef {Object} Store.UserSlice
 * @property {string} name - imię głównego użytkownika
 * @property {string} roomID - id głównego użytkownika
 * @property {string[]} types - Typy dostępnych użytkowników
 * @property {Object.<string, user>} users - Słownik dostępnych użytkowników gdzie kluczami są ich id, a wartościami obiekty użytkowników
 * @property {object} [user] - element users. pojedyńczy użytkownik (może ich nie być w ogóle)
 * @property {string} user.name - imię użytkownika
 * @property {boolean} user.activeStatus - status aktywności użytkownika
 * @property {string} user.userType - typ użytkownika, część id, jeden z "types"
 * @property {number} user.userID -  część id, rzadko używane"
 * @property {string} user.id - id składa się z user.type + user.userID np: "pracownicy14"
 * @property {string|null} user.roomID - string przypisywany tylko wtedy kiedy główny użytkownik ma wspólną konwersację z użytkownikiem składa się z typu i id konwersacji np: "private3"
 * @property {objet} avatar - obiekt avataru użytkownika
 * @property {string} avatar.initials - dwie pierwsze litery imienia i nazwiska użytkownika
 * @property {string} avatar.color - string z kolorem w formacie HEX
 * @property {Object.<string, group>} groups - Słownik dostępnych grup gdzie kluczami są ich id, a wartościami obiekty grup
 * @property {object} [group] - element groups. pojedyńcza grupa (może ich nie być w ogóle)
 * @property {string} group.name - nazwa grupy
 * @property {string} group.roomID - roomID grupy w przeciwieństwie to obiektu user roomID === id
 * @property {string} group.id - id grupy składa się z typu czatu i id czatu np 'groups4
 * @property {'group'} group.type - typ czatu
 * @property {string} group.creator - id użytkownika który stworzył grupę np "pracownicy14"
 * @property {string[]} group.users - tablica id użytkowników grupy
 */
