import { simpleQueryAnyText } from 'src/api/src/simpleQueryAnyText';
import { DynamicFieldT } from 'src/data/popupFormTypes';

export type PasswordRequirementsCheckResult = {
    hasla_cyfry: boolean;
    hasla_dlugosc: boolean;
    hasla_dlugosc_maksymalna: boolean;
    hasla_duze_litery: boolean;
    hasla_male_litery: boolean;
    hasla_znaki_specjalne: boolean;
    hasla_blokuj_popularne: boolean;
};

export default class PasswordRequirementsChecker {
    password: string;
    field: DynamicFieldT;

    constructor(password: string, field: DynamicFieldT) {
        this.password = password;
        this.field = field;
    }

    async checkRequirements(
        fieldRequirementsEnabled: PasswordRequirementsCheckResult
    ): Promise<PasswordRequirementsCheckResult> {
        const propertyToFunction: {
            [key in keyof PasswordRequirementsCheckResult]: () => Promise<boolean>;
        } = {
            hasla_blokuj_popularne: () => this.chceckPopular(),
            hasla_cyfry: () => this.checkDigit(),
            hasla_dlugosc: () => this.checkLength(),
            hasla_dlugosc_maksymalna: () => this.checkMaxLength(),
            hasla_duze_litery: () => this.checkLargeLetters(),
            hasla_male_litery: () => this.checkSmallLetters(),
            hasla_znaki_specjalne: () => this.checkSpecialLetters()
        };

        const checkResult: PasswordRequirementsCheckResult = {
            hasla_blokuj_popularne: false,
            hasla_cyfry: false,
            hasla_dlugosc: false,
            hasla_dlugosc_maksymalna: false,
            hasla_duze_litery: false,
            hasla_male_litery: false,
            hasla_znaki_specjalne: false
        };

        let key: keyof PasswordRequirementsCheckResult;
        for (key in propertyToFunction) {
            if (fieldRequirementsEnabled[key]) checkResult[key] = await propertyToFunction[key]();
        }

        return checkResult;
    }

    async checkDigit(): Promise<boolean> {
        return this.password.match(/[0-9]/gi) !== null;
    }

    async checkLength(): Promise<boolean> {
        return this.password.length >= parseInt(this.field.hasla_dlugosc ?? '');
    }

    async checkMaxLength(): Promise<boolean> {
        return this.password.length <= parseInt(this.field.hasla_dlugosc_maksymalna ?? '');
    }

    async checkLargeLetters(): Promise<boolean> {
        return this.password.match(/[A-ZĄĆĘŁŃÓŚŹŻ]/g) !== null;
    }

    async checkSmallLetters(): Promise<boolean> {
        return this.password.match(/[a-ząćęłńóśźż]/g) !== null;
    }

    async checkSpecialLetters(): Promise<boolean> {
        return this.password.match(/[^A-ZĄĆĘŁŃÓŚŹŻa-ząćęłńóśźż0-9]+/g) !== null;
    }

    async chceckPopular(): Promise<boolean> {
        let formData = new FormData();
        formData.append('haslo', this.password);

        const text = await simpleQueryAnyText({
            url: 'sprawdz_popularne_hasla.php',
            method: 'POST',
            bodyContent: formData,
            parseBody: false
        });

        return text === 'OK';
    }
}
