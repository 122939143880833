import React, { FC } from 'react';

import styles from './UiFieldPriceNumber.module.css';
import { UIFieldPriceNumberProps } from './types';

import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { fieldCostsOp, optionsVat } from 'src/constants/optionsForSelects';
import { fields } from 'src/constants/subtitles';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { StaticHider } from 'src/components/shared/atoms/staticHider/StaticHider';

export const UIFieldPriceNumber: FC<UIFieldPriceNumberProps> = ({
    value,
    onChangeVat,
    onChangeBrutto,
    onChangeNetto,
    onChangeNumber,
    onBlurBrutto,
    onBlurNetto,
    onBlurNumber,
    onBlurVat,
    onChangeCurrency,
    onBlurCurrency,
    uiFieldCommonProps,
    uiFieldSharedProps
}) => {
    const { isLabel, isMandatory, name } = uiFieldCommonProps;
    const label2 = isMandatory ? name + '*' : name;
    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <div className={styles.box}>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.liczba}
                    onChange={onChangeNumber}
                    onBlur={onBlurNumber}
                    label={isLabel ? label2 : ''}
                    alignTextRight={true}
                    inputProps={uiFieldSharedProps.inputProps}
                />
                <p className={styles.p}>{fields.fieldPriceNumber.one}</p>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.netto}
                    onChange={onChangeNetto}
                    onBlur={onBlurNetto}
                    label={''}
                    alignTextRight={true}
                />
                <p className={styles.p} style={{ whiteSpace: 'nowrap' }}>
                    {fields.fieldPriceNumber.two}
                </p>
                <StaticHider dynamicFieldT={uiFieldSharedProps.dynamicFieldT} type="waluta">
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.waluta}
                        opcje={fieldCostsOp}
                        onChange={onChangeCurrency}
                        onBlur={onBlurCurrency}
                        label={''}
                    />
                </StaticHider>
                <StaticHider dynamicFieldT={uiFieldSharedProps.dynamicFieldT} type="vat">
                    <p className={styles.p} style={{ whiteSpace: 'nowrap' }}>
                        {fields.fieldPriceNumber.three}
                    </p>
                    <UiInputSelectTwo
                        {...uiFieldCommonProps}
                        value={value.vat}
                        options={optionsVat}
                        onChange={onChangeVat}
                        onBlur={onBlurVat}
                        label={''}
                        alignTextRight={true}
                    />
                </StaticHider>
                <p className={styles.p}>{fields.fieldPriceNumber.four}</p>
                <UiInputNormalTwo
                    {...uiFieldCommonProps}
                    value={value.total_brutto}
                    onChange={onChangeBrutto}
                    onBlur={onBlurBrutto}
                    label={''}
                    alignTextRight={true}
                />
            </div>
        </UiWrapper>
    );
};
