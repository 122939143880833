import React from 'react';
import { EventBus } from 'src/utils/src/shared/EventBus';

export function useOnUpdating(eventId: string) {
    const [updating, setUpdating] = React.useState(false);
    React.useEffect(() => {
        new Promise<string | null>((res, _) => {
            EventBus.$on(eventId, (newUpdating: boolean) => {
                setUpdating(newUpdating);
            });
        });
    }, []);
    return updating;
}
