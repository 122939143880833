import { FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import { BudgetEmployees, BugdetFormFieldStructure } from 'src/store/src/budget/budget/types';
import { DataFromApiConverter } from 'src/utils/src/shared/DataFromApiConverter';
import { DateManager } from 'src/utils/src/shared/DateManager';

export function setInitValueInBudget({
    bugdetFormVisibleFieldStructure,
    values,
    employees
}: {
    bugdetFormVisibleFieldStructure: BugdetFormFieldStructure;
    values: { [key: string]: any };
    employees: BudgetEmployees;
}): FieldsStateUnionRHF {
    const isReadOnly = values['settled'] && !values['settledInThisSettlingObject'];
    switch (bugdetFormVisibleFieldStructure.type) {
        case 'checkbox': {
            if (
                values['settled'] &&
                values['settledInThisSettlingObject'] &&
                bugdetFormVisibleFieldStructure.code === 'x'
            ) {
                return {
                    checkbox: true,
                    isReadOnly
                };
            }
            const defValue = values[bugdetFormVisibleFieldStructure.code];
            const value = DataFromApiConverter.getBoolean(defValue);
            return {
                checkbox: value,
                isReadOnly
            };
        }
        case 'data': {
            const date = DateManager.prepareFromVariousTypeOfDateToNewDate(
                values[bugdetFormVisibleFieldStructure.code]
            );
            return {
                data: date,
                isReadOnly
            };
        }
        case 'lista': {
            const value =
                values[bugdetFormVisibleFieldStructure.code] ??
                bugdetFormVisibleFieldStructure.value;
            if (
                isPriceWithoutNumberField(bugdetFormVisibleFieldStructure.column) ||
                isPriceWithoutNumberField(bugdetFormVisibleFieldStructure.name)
            ) {
                if (
                    !bugdetFormVisibleFieldStructure.onlyText &&
                    !bugdetFormVisibleFieldStructure.hidden
                ) {
                    return {
                        lista: {
                            value: value,
                            opcje: bugdetFormVisibleFieldStructure.options
                                ? bugdetFormVisibleFieldStructure.options
                                : {}
                        },
                        isReadOnly
                    };
                }
                return {
                    lista: {
                        value: value,
                        opcje: {}
                    },
                    isReadOnly
                };
            }
            return {
                lista: {
                    value: value,
                    opcje: employees
                },
                isReadOnly
            };
        }
        case 'tekst': {
            const value = values[bugdetFormVisibleFieldStructure.code];
            return {
                tekst: String(value),
                isReadOnly
            };
        }
        case 'liczba': {
            let value = values[bugdetFormVisibleFieldStructure.code];
            if (typeof value !== 'undefined') {
                value = String(value);
            }
            if (bugdetFormVisibleFieldStructure.value) {
                value = bugdetFormVisibleFieldStructure.value;
            }
            return {
                liczba: value,
                isReadOnly
            };
        }
        case 'tekst_bez_ograniczen': {
            const value = values[bugdetFormVisibleFieldStructure.code];
            return {
                tekst_bez_ograniczen: String(value),
                isReadOnly
            };
        }
        default: {
            const value = values[bugdetFormVisibleFieldStructure.code];
            return {
                basic: String(value),
                isReadOnly
            };
        }
    }
}

const priceWithoutNumberFieldStr = [
    'cena (szt.)',
    'koszt_price_vat_',
    'cena netto {waluta}',
    'vat',
    'cena brutto {waluta}',
    'cena (szt.)'
];

function isPriceWithoutNumberField(str?: string) {
    if (str) {
        if (priceWithoutNumberFieldStr.includes(str)) return true;
    } else return false;
}
