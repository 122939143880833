import { MessageRow } from 'src/store/src/messageScreen/message/types';

export const messageScreenRowWithoutIdList: MessageRow[] = [
    {
        code: 'id_szablonu',
        isVisibilityDependent: false,
        type: 'template',
        value: '0',
        opcje: {},
        validation: [
            {
                type: 'requirement',
                info: 'Brak wybranego szablonu',
                omitValidionType: 'main'
            }
        ],
        apiType: ['main']
    },
    {
        code: 'id_konta',
        isVisibilityDependent: false,
        type: 'list',
        value: null,
        opcje: {},
        apiType: ['main']
    },
    {
        code: 'email_osoby',
        isVisibilityDependent: false,
        type: 'list_wielokrotna',
        value: [],
        opcje: [],
        apiType: ['main']
    },
    {
        code: 'do',
        isVisibilityDependent: false,
        type: 'email',
        value: null,
        validation: [
            {
                type: 'requirement',
                info: 'Brak maila do wysyłki',
                alternativeFieldCode: ['dw', 'udw'],
                conditionField: {
                    code: 'email_osoby',
                    typeField: 'list_wielokrotna',
                    value: 'proszę podać mail w polu poniżej'
                }
            },
            {
                type: 'correctEmail',
                info: 'Wpisany email niepoprawny:'
            }
        ],
        apiType: ['main']
    },
    {
        code: 'dw',
        isVisibilityDependent: false,
        type: 'email',
        value: null,
        validation: [
            {
                type: 'requirement',
                info: 'Brak maila do wysyłki',
                alternativeFieldCode: ['do', 'udw'],
                conditionField: {
                    code: 'email_osoby',
                    typeField: 'list_wielokrotna',
                    value: 'proszę podać mail w polu poniżej'
                }
            },
            {
                type: 'correctEmail',
                info: 'Wpisany email niepoprawny:'
            }
        ],
        apiType: ['main']
    },
    {
        code: 'udw',
        isVisibilityDependent: false,
        type: 'email',
        value: null,
        validation: [
            {
                type: 'requirement',
                info: 'Brak maila do wysyłki',
                alternativeFieldCode: ['dw', 'do'],
                conditionField: {
                    code: 'email_osoby',
                    typeField: 'list_wielokrotna',
                    value: 'proszę podać mail w polu poniżej'
                }
            },
            {
                type: 'correctEmail',
                info: 'Wpisany email niepoprawny:'
            }
        ],
        apiType: ['main']
    },
    {
        code: 'odpowiedzdo',
        isVisibilityDependent: false,
        type: 'email',
        validation: [
            {
                type: 'correctEmail',
                info: 'Wpisany email niepoprawny:'
            }
        ],
        value: null,
        apiType: ['main']
    },
    {
        code: 'html',
        isVisibilityDependent: false,
        type: 'checkbox',
        value: false,
        apiType: ['main']
    },
    {
        code: 'potwierdz_otrzymanie',
        isVisibilityDependent: false,
        type: 'checkbox',
        value: false,
        apiType: ['main']
    },
    {
        code: 'tytul',
        isVisibilityDependent: false,
        type: 'text',
        value: null,
        validation: [
            {
                type: 'requirement',
                info: 'Proszę podać tytuł wiadomości!'
            }
        ],
        // isToValid: true,
        apiType: ['main']
    },
    {
        code: 'naglowek',
        isVisibilityDependent: true,
        type: 'text_not_edit',
        value: '',
        apiType: []
    },
    {
        code: 'tresc',
        isVisibilityDependent: false,
        type: 'text_without_restrict',
        value: '',
        apiType: ['main']
    },
    {
        code: 'stopka',
        isVisibilityDependent: true,
        type: 'text_not_edit',
        value: '',
        apiType: []
    },
    {
        code: 'zalaczniki',
        isVisibilityDependent: false,
        type: 'file',
        value: [],
        apiType: ['main']
    },
    {
        code: 'dokumenty',
        isVisibilityDependent: false,
        type: 'attachment',
        value: [],
        apiType: ['main']
    }
];
