import React, { useState, useEffect } from 'react';
import { useTypedSelector, useAppDispatch } from 'src/store';
import NavMenuItem from 'src/components/menu/molecules/navMenuItem/NavMenuItem';
import NavMenuList from 'src/components/menu/molecules/navMenuList/NavMenuList';
import Collapse from '@mui/material/Collapse';
import { NavMenuUnfold } from 'src/components/menu/molecules/navMenuUnfold/NavMenuUnfold';
import { NavMenuAdd } from 'src/components/menu/molecules/navMenuAdd/NavMenuAdd';
import { useSearchParam, useSelectedModuleAndScreen } from 'src/hooks';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import { ubdateModule } from 'src/store/src/menu';

export const NavigationMenu = () => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const menuStructureData = useTypedSelector((state) => state.menu.menuStructureData);
    const dispatchRedux = useAppDispatch();
    const isAdmin = useTypedSelector((state) => state.user.adminFeatures);
    const { screenSelected, moduleSelected } = useSelectedModuleAndScreen();
    const phpUrl = useSearchParam('php_url');

    useEffect(() => {
        if (phpUrl === 'ekran_edycji_ekranu.php' || phpUrl === 'ekran_edycji_modulu.php') {
            setEditMode(true);
        } else {
            if (editMode) {
                setEditMode(false);
            }
        }
    }, [phpUrl]);

    useEffect(() => {
        MenuStateManager.ubdateMenuModuleData({
            menuStructureData,
            dispatch: dispatchRedux,
            ubdateModule,
            screenSelected,
            moduleSelected
        });
    }, [screenSelected, moduleSelected]);

    return (
        <div>
            {menuStructureData.modules.map((item) => {
                return (
                    <NavMenuList
                        item={item}
                        key={item.id}
                        isAdmin={isAdmin}
                        isExpanded={item.id === menuStructureData.moduleExpanded}
                        isEdit={editMode}>
                        <div>
                            {item.screens.map((screen) => {
                                return (
                                    <Collapse
                                        key={screen.id}
                                        in={item.id === menuStructureData.moduleExpanded}>
                                        {(!screen.visibleOnlyWhenEditing || editMode) && (
                                            <NavMenuItem
                                                screen={screen}
                                                isAdmin={isAdmin}
                                                isSelected={
                                                    screen.id ===
                                                        menuStructureData.screenSelected[0] ||
                                                    screen.code ===
                                                        menuStructureData.screenSelected[1]
                                                }></NavMenuItem>
                                        )}
                                    </Collapse>
                                );
                            })}
                            {isAdmin && (
                                <Collapse in={item.id === menuStructureData.moduleExpanded}>
                                    <NavMenuAdd
                                        id={item.id}
                                        admin={true}
                                        url={'/ekran_edycji_ekranu.php'}
                                        params={new URLSearchParams(`modul=${item.code}`)}
                                        fs={'13px'}
                                        marginLeft={'18px'}
                                        text={'Nowy Ekran'}
                                        height={'15px'}
                                    />
                                </Collapse>
                            )}
                        </div>
                    </NavMenuList>
                );
            })}
            {isAdmin && (
                <NavMenuAdd
                    id={null}
                    admin={true}
                    url={'/ekran_edycji_modulu.php'}
                    params={new URLSearchParams()}
                    fs={'14px'}
                    marginLeft={'11px'}
                    text={'Nowy Moduł'}
                    height={'35px'}
                />
            )}
            <NavMenuUnfold />
        </div>
    );
};
