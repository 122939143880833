import React, { FC } from 'react';

import styles from './UiWrapperView.module.css';
import { UiWrapperViewProps } from 'src/components/shared/atoms/uiLayout/uiWrapperView/types';

const UiWrapperView: FC<UiWrapperViewProps> = ({ children, style }) => {
    return (
        <div
            className={styles.wrapper}
            style={{
                ...style
            }}>
            {children}
        </div>
    );
};

export default UiWrapperView;
