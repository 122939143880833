import React, { FC } from 'react';
import { useAppDispatch, AppDispatch, useTypedSelector } from 'src/store';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { ApiActionBehavior } from 'src/data/types';
import { useSearchParam } from 'src/hooks';
import { useUpdateObjectsData } from 'src/hooks';
import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { fetchAndUpdateMessagesCount } from 'src/store/src/menu/menu/menuSlice';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { TemplateMainManager } from 'src/utils/src/shared/TemplateMainManager';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { ApiActionProps } from 'src/components/shared/molecules/actionType/apiAction/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { generateToken } from 'src/utils';

const ApiAction: FC<ApiActionProps> = ({ children, action, rowId, clickCallback }) => {
    const refPreventMultipleClick = React.useRef(true);
    const [resetClick, setResetClick] = React.useState(1);
    const screen = useSearchParam('obiekt');
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const behaviour = action.behaviour as ApiActionBehavior;
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const objectSlice = useTypedSelector((state) => state.object);
    const additional = { sectionId: '', sectionIdx: 0, typeSection: null };
    const sourceOfTrigger: SourceOfTrigger = { type: 'list' };
    const updateObjects = useUpdateObjectsData();
    const [isClicked, setIsCliced] = React.useState<boolean>(false);
    const totalIdsIsActual = useTypedSelector(
        (state) => state.listScreenTableData.totalIdsIsActual
    );
    const totalIds = useTypedSelector((state) => state.listScreenTableData.totalIds);
    const handlePrevApiAction = () => {
        if (action.isAllIdsRequired && !totalIdsIsActual) {
            setIsCliced(true);
            dispatch(setSpinner(true));
            setTimeout(() => {
                dispatch(setSpinner(false));
            }, 600000);
            return;
        }
        handleApiAction();
        clickCallback && clickCallback();
    };

    const handleApiAction = async () => {
        if (behaviour?.data?.confirmText) {
            return new Promise<string | null>((res, _) => {
                const closeCallbackEventId = `close-popup-unlock-click-${generateToken()}`;
                new PopupManager({ dispatch, action }).setPopupConfirmApi({
                    rowId: rowId ? [rowId] : [],
                    behaviour,
                    additional,
                    closeCallbackEventId,
                    sourceOfTrigger
                });
                EventBus.$on(closeCallbackEventId, () => {
                    EventBus.$off(closeCallbackEventId);
                    setResetClick(resetClick + 1);
                });
                res(null);
            });
        }

        const { params, body, url } = new TemplateMainManager({
            rowData,
            transformationState,
            rowId,
            actionBehavior: action.behaviour as ApiActionBehavior,
            screen,
            action,
            objectSlice,
            sourceOfTrigger,
            additional,
            totalIds
        }).getData();

        const urlAll = url + '?' + params;

        dispatch(setSpinner(true));
        const resp = await simpleQueryAPIData(urlAll, 'POST', body, false);
        if (resp.success) {
            if (behaviour.data.refresh) {
                if (location.pathname === '/lista') {
                    updateObjects({ saveLocationState: false, forceRefreshData: true });
                } else {
                    // because object screen need in some cases reload utilities bar which is unusual
                    window.location.reload();
                }
                // special reload for marking messages as readed
                if (action.code === 'oznacz_jako_przeczytane_wszystkie') {
                    dispatch(fetchAndUpdateMessagesCount());
                }
            }
            if (behaviour.data.showResult) {
                new PopupManager({ dispatch: dispatch, action: action }).setPopupInfo(
                    resp.message ? resp.message : 'Wykonano'
                );
            }
            if (behaviour.data.navigateBack) {
                navigate(-1);
            }
        } else {
            new PopupManager({ dispatch: dispatch, action: action }).setPopupInfo(
                resp.message ? resp.message : 'Nie wykonano'
            );
        }
        dispatch(setSpinner(false));
    };

    React.useEffect(() => {
        if (isClicked && totalIdsIsActual) {
            handleApiAction();
            setIsCliced(false);
        }
    }, [totalIdsIsActual]);

    return (
        <ButtonCustom
            key={resetClick}
            onClick={() => handlePrevApiAction()}
            withoutStyles={true}
            preventMultipleClick={refPreventMultipleClick.current}>
            {children}
        </ButtonCustom>
    );
};

export default ApiAction;
