import React, { FC } from 'react';
import { RequirementsBlockProps } from './types';
import { Typography, useTheme } from '@mui/material';
import styles from '../PopupContentChangePassword.module.css';
import { PasswordRequirementsCheckResult } from '../PasswordRequirementsChecker';

const RequirementsBlock: FC<RequirementsBlockProps> = React.memo(
    ({ requirementsEnabled, requirementsState, field }) => {
        const theme = useTheme();

        if (!requirementsEnabled.anyPresent) return null;
        return (
            <div className={styles.requirementsWrapper}>
                <p>Wymogi haseł: </p>
                {requirementsEnabled.fields.hasla_dlugosc && (
                    <Typography
                        color={
                            requirementsState.hasla_dlugosc
                                ? theme.palette.success.main
                                : theme.palette.error.main
                        }>
                        - musi się składać z min {field.hasla_dlugosc} znaków
                    </Typography>
                )}
                {requirementsEnabled.fields.hasla_dlugosc_maksymalna && (
                    <Typography
                        color={
                            requirementsState.hasla_dlugosc_maksymalna
                                ? theme.palette.success.main
                                : theme.palette.error.main
                        }>
                        - musi się składać z max {field.hasla_dlugosc_maksymalna} znaków
                    </Typography>
                )}
                {requirementsEnabled.fields.hasla_male_litery && (
                    <Typography
                        color={
                            requirementsState.hasla_male_litery
                                ? theme.palette.success.main
                                : theme.palette.error.main
                        }>
                        - min 1 mała litera
                    </Typography>
                )}
                {requirementsEnabled.fields.hasla_duze_litery && (
                    <Typography
                        color={
                            requirementsState.hasla_duze_litery
                                ? theme.palette.success.main
                                : theme.palette.error.main
                        }>
                        - min 1 wielka litera
                    </Typography>
                )}
                {requirementsEnabled.fields.hasla_cyfry && (
                    <Typography
                        color={
                            requirementsState.hasla_cyfry
                                ? theme.palette.success.main
                                : theme.palette.error.main
                        }>
                        - min 1 cyfra
                    </Typography>
                )}
                {requirementsEnabled.fields.hasla_znaki_specjalne && (
                    <Typography
                        color={
                            requirementsState.hasla_znaki_specjalne
                                ? theme.palette.success.main
                                : theme.palette.error.main
                        }>
                        - min 1 znak specjalny, np: !,@,#..
                    </Typography>
                )}
                {requirementsEnabled.fields.hasla_blokuj_popularne && (
                    <Typography
                        color={
                            requirementsState.hasla_blokuj_popularne
                                ? theme.palette.success.main
                                : theme.palette.error.main
                        }>
                        - popularne hasło np: password
                    </Typography>
                )}
            </div>
        );
    },
    (prevProps, nextProps) => {
        if (prevProps.field !== nextProps.field) return false;
        if (prevProps.requirementsEnabled !== nextProps.requirementsEnabled) return false;
        let key: keyof PasswordRequirementsCheckResult;
        for (key in prevProps.requirementsState) {
            if (prevProps.requirementsState[key] !== nextProps.requirementsState[key]) return false;
        }
        return true;
    }
);

RequirementsBlock.displayName = 'RequirementsBlock';

export default RequirementsBlock;
