import React from 'react';
import { Box } from '@mui/material';

const Icon = () => {
    return (
        <Box width={'28px'} height={'28px'} borderRadius={'50%'} bgcolor={'gray'} marginLeft={1} />
    );
};
export const iconStyleCommon = {
    fontSize: '18px',
    minWidth: '26px',
    minHeight: '26px',
    border: '1px solid transparent',
    display: 'flex',
    padding: '4px'
};
export default Icon;
