import { BaseApiResponse } from '../../types';
import { ObjectSublistDataFromApi, RelationId } from 'src/store/src/object/object/types';
import { simpleQueryAPIData } from 'src/api/src/simpleQueryAPIData';

export async function getObjectScreenSublistData({
    objectId,
    sublistId,
    screen
}: {
    objectId: string;
    screen: string;
    sublistId: RelationId;
}): Promise<BaseApiResponse<ObjectSublistDataFromApi>> {
    const params = new URLSearchParams([
        ['screen', screen],
        ['objectId', objectId],
        ['sublistId', sublistId]
    ]);

    return simpleQueryAPIData(
        `api_react/src/objectScreen/getObjectScreenSublistData.php?${params.toString()}`
    );
}
