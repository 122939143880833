import { FieldDependentFieldBasicListManyRHF } from 'src/components/form/molecules/fieldDependent/FieldDependentFieldBasicListManyRHF';
import { FieldDependentFieldDefaultRHF } from 'src/components/form/molecules/fieldDependent/FieldDependentFieldDefaultRHF';
import { FieldDependentFieldListForeignRHF } from 'src/components/form/molecules/fieldDependent/FieldDependentFieldListForeignRHF';
import { FieldDependentHelperRHF } from 'src/components/form/molecules/fieldDependent/FieldDependentHelperRHF';
import {
    FieldDependentFieldTypeRHF,
    FieldDependentFunctionRHF,
    FieldDependentRHFProps,
    FieldDependentRowRHF,
    FieldDependentStaticDataRHF,
    FieldDependentTempStateRHF,
    FieldDepenedentFormStateHandlersRHF
} from 'src/components/form/molecules/fieldDependent/types';

import { massEditObjectsAvailableFunctionToCallInField } from 'src/constants/massEditObjectsScreen/massEditObjectsAvailableFunctionToCallInField';
import { raportAvailableFunctionToCallInField } from 'src/constants/raportScreen/raportAvailableFunctionToCallInField';
import {
    FieldBasicListManyStateRHF,
    FieldListForeignStateRHF,
    FieldsStateUnionRHF
} from 'src/data/fieldsReactHookForm';
import { DynamicFieldT } from 'src/data/popupFormTypes';
import { HelperRHF } from 'src/utils/src/shared/HelperRHF';

type FieldDependentGlobalManagerRHFProps = FieldDependentRHFProps & {
    tempState: FieldDependentTempStateRHF;
};

type FieldDependentGlobalManagerChangeItemProps = {
    dynamicFieldT: DynamicFieldT;
    row?: FieldDependentRowRHF;
};

export class FieldDependentGlobalRHFManager extends FieldDependentHelperRHF {
    dynamicFieldsT: DynamicFieldT[];
    formStateHandlers: FieldDepenedentFormStateHandlersRHF;
    tempState: FieldDependentTempStateRHF;
    staticData: FieldDependentStaticDataRHF;
    constructor({
        dynamicFieldsT,
        formStateHandlers,
        tempState,
        staticData
    }: FieldDependentGlobalManagerRHFProps) {
        super({ dynamicFieldsT, formStateHandlers, tempState, staticData });
        this.dynamicFieldsT = dynamicFieldsT;
        this.formStateHandlers = formStateHandlers;
        this.tempState = tempState;
        this.staticData = staticData;
    }
    async onMount() {
        await this.runAll();
    }

    async onChangeItem(data: FieldDependentGlobalManagerChangeItemProps) {
        await this.executeOne(data);
    }

    private async runAll() {
        switch (this.staticData.type) {
            case 'massEditObjects':
                for await (const row of this.staticData.rows) {
                    for await (const dynamicFieldT of this.dynamicFieldsT) {
                        await this.executeOne({ dynamicFieldT, row });
                    }
                }
                break;
            default:
                break;
        }
    }

    private async executeOne({
        dynamicFieldT,
        row
    }: {
        dynamicFieldT: DynamicFieldT;
        row?: FieldDependentRowRHF;
    }) {
        let arr: FieldDependentFunctionRHF[] = [];
        switch (this.staticData.type) {
            case 'massEditObjects':
                arr =
                    massEditObjectsAvailableFunctionToCallInField[
                        dynamicFieldT.typ as FieldDependentFieldTypeRHF
                    ] ?? [];
                break;
            case 'raport':
                arr =
                    raportAvailableFunctionToCallInField[
                        dynamicFieldT.typ as FieldDependentFieldTypeRHF
                    ] ?? [];
                break;
            default:
                break;
        }

        for (const dependentFunctionDynamicType of arr) {
            await this.executeItem({ dependentFunctionDynamicType, dynamicFieldT, row });
        }
    }

    private async executeItem({
        dynamicFieldT,
        dependentFunctionDynamicType,
        row
    }: {
        dynamicFieldT: DynamicFieldT;
        dependentFunctionDynamicType: FieldDependentFunctionRHF;
        row?: FieldDependentRowRHF;
    }) {
        switch (dependentFunctionDynamicType) {
            case 'zmienPole':
                await this[dependentFunctionDynamicType]({ dynamicFieldT, row });
                break;

            default:
                break;
        }
    }

    private async zmienPole({
        dynamicFieldT,
        row
    }: {
        dynamicFieldT: DynamicFieldT;
        row?: FieldDependentRowRHF;
    }) {
        if (dynamicFieldT && Array.isArray(dynamicFieldT?.zalezne)) {
            for (const item of dynamicFieldT?.zalezne) {
                const dynamicFieldTToCall = this.findDynamicFieldTById(item.id);
                if (dynamicFieldTToCall) {
                    await this.functionDynamic({
                        dynamicFieldT: dynamicFieldTToCall,
                        dependentFunctionDynamicType: 'zmienPole',
                        row
                    });
                }
            }
        }
    }

    private async functionDynamic({
        dynamicFieldT,
        dependentFunctionDynamicType,
        row
    }: {
        dynamicFieldT: DynamicFieldT;
        dependentFunctionDynamicType: FieldDependentFunctionRHF;
        row?: FieldDependentRowRHF;
    }) {
        let keyField = '';
        switch (this.staticData.type) {
            case 'massEditObjects':
                keyField = HelperRHF.createKey({
                    dynamicFieldT,
                    id: row?.id,
                    screen: 'massEditObjects'
                });
                break;
            default:
                keyField = HelperRHF.createKey({
                    dynamicFieldT,
                    screen: 'raport'
                });
                break;
        }

        const fieldWasTriggered: FieldsStateUnionRHF = this.findStateOfFieldsByCode(keyField);
        if (fieldWasTriggered) {
            switch (true) {
                case 'lista_obca' in fieldWasTriggered: {
                    const oneObj: Required<FieldListForeignStateRHF> =
                        fieldWasTriggered as Required<FieldListForeignStateRHF>;
                    await new FieldDependentFieldListForeignRHF({
                        formStateHandlers: this.formStateHandlers,
                        dynamicFieldsT: this.dynamicFieldsT,
                        tempState: this.tempState,
                        fieldWasTriggeredDynamicFieldT: dynamicFieldT,
                        dependentFunctionDynamicType,
                        fieldWasTriggered: oneObj,
                        row,
                        staticData: this.staticData
                    })[dependentFunctionDynamicType]();
                    break;
                }
                case 'basic_list_many' in fieldWasTriggered: {
                    const oneObj: Required<FieldBasicListManyStateRHF> =
                        fieldWasTriggered as Required<FieldBasicListManyStateRHF>;
                    await new FieldDependentFieldBasicListManyRHF({
                        formStateHandlers: this.formStateHandlers,
                        dynamicFieldsT: this.dynamicFieldsT,
                        tempState: this.tempState,
                        fieldWasTriggeredDynamicFieldT: dynamicFieldT,
                        dependentFunctionDynamicType,
                        fieldWasTriggered: oneObj,
                        row,
                        staticData: this.staticData
                    })[dependentFunctionDynamicType]();
                    break;
                }
                default:
                    if (fieldWasTriggered) {
                        await new FieldDependentFieldDefaultRHF({
                            formStateHandlers: this.formStateHandlers,
                            dynamicFieldsT: this.dynamicFieldsT,
                            tempState: this.tempState,
                            fieldWasTriggeredDynamicFieldT: dynamicFieldT,
                            dependentFunctionDynamicType,
                            fieldWasTriggered,
                            row,
                            staticData: this.staticData
                        })[dependentFunctionDynamicType]();
                    }
                    break;
            }
        }
    }
}
