import React, { useEffect, useState } from 'react';
import NoteIcon from 'src/assets/ikona_terminarz.svg';
import OpenTimersBtn from 'src/widgets/shared/components/openButton/OpenButton';
import { fetchInitNotes } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { topMenu } from 'src/constants/subtitles';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
const NotesTop = React.lazy(() => import(/* webpackChunkName: "Notes" */ './NotesTop'));

const NotesApp = ({ viewport, systemUrl }) => {
    const dispatch = useDispatch();
    const notes = useSelector(({ notes }) => notes.notes);
    const [notesApp, setNotesApp] = useState(false);
    const [notesOpen, setNotesOpen] = useState(false);
    const handleNotesOpen = () => {
        setNotesOpen((prev) => !prev);
    };

    function loadData() {
        dispatch(fetchInitNotes(systemUrl));
    }

    const handleLoad = () => {
        if (!notesApp) setNotesApp(true);
    };

    useEffect(() => {
        loadData();
    }, [systemUrl, dispatch]);

    return (
        <>
            <OpenTimersBtn
                tooltipText={topMenu.tooltipOnHover.notes}
                handleOpen={handleNotesOpen}
                handleLoad={handleLoad}
                iconComponent={
                    <ThemeCompatibleIcon
                        SvgComponent={NoteIcon}
                        fontSize={'small'}
                        sx={{
                            position: 'absolute',
                            fontSize: '1rem',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)'
                        }}
                    />
                }
            />
            <React.Suspense fallback>
                {notesApp && (
                    <NotesTop
                        viewport={viewport}
                        handleOpen={handleNotesOpen}
                        notesOpen={notesOpen}
                        loadData={loadData}
                        systemUrl={systemUrl}
                        notes={notes}
                    />
                )}
            </React.Suspense>
        </>
    );
};
export default NotesApp;
