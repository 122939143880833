import { FieldDateHoursBasicTypeValue } from 'src/data/fieldsFormType';

type Type = 'rozp' | 'zak' | 'default';
type Type2 = 'minutes' | 'hours' | 'default';
export class FieldDateHoursManager {
    fieldStateValue: FieldDateHoursBasicTypeValue;
    type: Type;
    type2: Type2;
    value: string;
    newValue: string;
    rozp?: string[];
    rozpM: string;
    rozpH: string;
    zakM: string;
    zakH: string;
    zak?: string[];
    constructor({ fieldStateValue }: { fieldStateValue: FieldDateHoursBasicTypeValue }) {
        this.fieldStateValue = fieldStateValue;
        this.type = 'default';
        this.type2 = 'default';
        this.value = '';
        this.newValue = '';
        this.rozp = fieldStateValue?.rozp?.split(':');
        this.zak = fieldStateValue?.zak?.split(':');
        this.rozpH = '0';
        this.rozpM = '0';
        this.zakH = '0';
        this.zakM = '0';
        this.prepareHoursAndMinutes();
    }

    private prepareHoursAndMinutes() {
        if (Array.isArray(this.rozp)) {
            this.rozpH = this.rozp?.[0] ? String(Number(this.rozp?.[0])) : '0';
            this.rozpM = this.rozp?.[1] ? String(Number(this.rozp?.[1])) : '0';
        }
        if (Array.isArray(this.zak)) {
            this.zakH = this.zak?.[0] ? String(Number(this.zak?.[0])) : '0';
            this.zakM = this.zak?.[1] ? String(Number(this.zak?.[1])) : '0';
        }
    }

    generateDataInOnChangeInput({
        type,
        type2,
        value
    }: {
        type: Type;
        type2: Type2;
        value: string;
    }) {
        this.type = type;
        this.type2 = type2;
        this.value = value;
        this.calc();
        return this.newValue;
    }

    private calc() {
        switch (this.type) {
            case 'rozp': {
                switch (this.type2) {
                    case 'hours':
                        this.newValue = `${this.value}:${this.rozpM}`;
                        break;
                    case 'minutes':
                        this.newValue = `${this.rozpH}:${this.value}`;
                        break;
                    default:
                        break;
                }
                break;
            }
            case 'zak': {
                switch (this.type2) {
                    case 'hours':
                        this.newValue = `${this.value}:${this.zakM}`;
                        break;
                    case 'minutes':
                        this.newValue = `${this.zakH}:${this.value}`;
                        break;
                    default:
                        break;
                }
                break;
            }
            default:
                break;
        }
    }

    prepareDataForInputs() {
        return {
            rozpH: this.rozpH,
            rozpM: this.rozpM,
            zakH: this.zakH,
            zakM: this.zakM
        };
    }
}
