import { Box } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import ArrowIcon from './ArrowIcon';
import { ModeEnum } from 'src/components/shared/atoms/datePicker/DatePickerPanel';
import { DatePickerPanelExtendedProps } from 'src/components/shared/atoms/datePicker/DatePickerPanel';
import { monthLong } from 'src/constants/dataPicker';
import styles from './DatePickerHeader.module.css';

export const DatePickerHeader = ({
    setMode,
    panel,
    dispatch,
    state
}: DatePickerPanelExtendedProps) => {
    const theme = useTheme();

    const setNext = () => {
        if (panel.month + panel.changer === 11) {
            dispatch({
                type: 'SET_PANEL_DATE',
                payload: {
                    ...state,
                    datePanel: new Date(panel.year + 1, 0, 1)
                }
            });
        } else {
            dispatch({
                type: 'SET_PANEL_DATE',
                payload: {
                    ...state,
                    datePanel: new Date(panel.year, panel.month + panel.changer + 1, 1)
                }
            });
        }
    };
    const setPrev = () => {
        if (panel.month + panel.changer === 0) {
            dispatch({
                type: 'SET_PANEL_DATE',
                payload: {
                    ...state,
                    datePanel: new Date(panel.year - 1, 11, 1)
                }
            });
        } else {
            dispatch({
                type: 'SET_PANEL_DATE',
                payload: {
                    ...state,
                    datePanel: new Date(panel.year, panel.month + panel.changer - 1, 1)
                }
            });
        }
    };
    return (
        <div className={styles.wrapper}>
            <div
                style={{
                    color: theme.palette.primary.main,
                    width: '175px',
                    borderBottom: `2px solid ${theme.palette.primary.main}`
                }}>
                <div className={styles.box}>
                    <div className={styles.box2} onClick={setPrev}>
                        <ArrowIcon enabled={true} />
                    </div>
                    <div className={styles.box3}>
                        <Box
                            component="span"
                            onClick={() => setMode(ModeEnum.monthMode)}
                            sx={{
                                cursor: 'pointer',
                                marginRight: '5px'
                            }}>
                            {monthLong[panel.month]}
                        </Box>
                        <div onClick={() => setMode(ModeEnum.yearMode)} className={styles.box4}>
                            {panel.year}
                        </div>
                    </div>
                    <div className={styles.box5} onClick={setNext}>
                        <ArrowIcon enabled={true} sx={{ transform: 'rotate(-180deg)' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};
