import { SystemAction } from 'src/data/types';

export const unFoldNodes: SystemAction = {
    name: 'Rozwiń Węzły',
    code: 'toggleunfoldNodes',
    icon: {
        faCode: 'fa-chevron-down',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'toggleUnfoldNodes'
        }
    }
};

export const foldNodes: SystemAction = {
    name: 'Zwiń Węzły',
    code: 'toggleunfoldNodes',
    icon: {
        faCode: 'fa-chevron-up',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'toggleUnfoldNodes'
        }
    }
};

export const setDefaultView: SystemAction = {
    name: 'Zmień widok',
    code: 'internal',
    icon: {
        faCode: 'fa-repeat',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'changeView',
            data: ''
        }
    }
};

export const setTreeView: SystemAction = {
    name: 'Zmień widok',
    code: 'internal',
    icon: {
        faCode: 'fa-repeat',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'changeView',
            data: 'drzewo'
        }
    }
};

export const setStatusView: SystemAction = {
    name: 'Zmień widok',
    code: 'internal',
    icon: {
        faCode: 'fa-repeat',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'changeView',
            data: 'statusy'
        }
    }
};

export const refresh: SystemAction = {
    name: 'Odśwież',
    code: 'refresh',
    icon: {
        faCode: '',
        svgFileName: 'odswierz.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'navigate',
        data: {
            target: 'refresh'
        }
    }
};

export const getLink: SystemAction = {
    name: 'Link',
    code: 'getLink',
    icon: {
        faCode: '',
        svgFileName: 'kopiuj_link.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'getLink',
        data: null
    }
};

export const help: SystemAction = {
    name: 'Pomoc',
    code: 'help',
    icon: {
        faCode: '',
        svgFileName: 'pomoc.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'help',
        data: null
    }
};

export const prev: SystemAction = {
    name: 'Wróć',
    code: 'back',
    icon: {
        faCode: '',
        svgFileName: 'wroc.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'navigate',
        data: {
            target: 'back'
        }
    }
};

export const next: SystemAction = {
    name: 'Naprzód',
    code: 'forward',
    icon: {
        faCode: '',
        svgFileName: 'naprzod.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'navigate',
        data: {
            target: 'forward'
        }
    }
};

export const config: SystemAction = {
    name: 'Konfiguruj',
    code: 'config',
    icon: {
        faCode: '',
        svgFileName: 'konfiguruj_ikona.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'popup',
        data: {
            template: 'config',
            data: null
        }
    }
};

export const startupBlockConfigVisbility: SystemAction = {
    name: 'Konfiguruj bloki',
    code: 'startupBlockConfigVisbility',
    icon: {
        faCode: '',
        svgFileName: 'konfiguruj_ikona.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'popup',
        data: {
            template: 'startupBlockConfig',
            data: null
        }
    }
};

export const search: SystemAction = {
    name: 'Szukaj',
    code: 'search',
    icon: {
        faCode: '',
        svgFileName: 'szukaj.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'popup',
        data: {
            template: 'search',
            data: null
        }
    }
};

export const configOrder: SystemAction = {
    name: 'Konfiguruj kolejność',
    code: 'configOrder',
    icon: {
        faCode: '',
        svgFileName: 'konfiguruj_ikona.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'popup',
        data: {
            template: 'configOrder',
            data: null
        }
    }
};

export const configSave: SystemAction = {
    name: 'Zapisz zmiany',
    code: 'configSave',
    icon: {
        faCode: 'fa-check',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'custom'
        }
    }
};

export const massEditObjectsSave: SystemAction = {
    name: 'Zapisz zmiany',
    code: 'massEditObjectsSave',
    icon: {
        faCode: 'fa-check',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'massEditObjectsSave'
        }
    }
};

export const massEditObjectsRejectSave: SystemAction = {
    name: 'Odrzuć zmiany',
    code: 'massEditObjectsRejectSave',
    icon: {
        faCode: '',
        svgFileName: 'cross_close.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'massEditObjectsRejectSave'
        }
    }
};

export const sendMessage: SystemAction = {
    name: 'Wyślij',
    code: 'sendMessage',
    icon: {
        faCode: 'fa-paper-plane',
        svgFileName: 'wyslij.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'custom'
        }
    }
};

export const withActualMouth: SystemAction = {
    name: 'Pokaż łącznie z bieżącym miesiącem',
    code: 'withActualMonth',
    icon: {
        faCode: 'fa-star',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'custom'
        }
    }
};

export const onlyBeforeMouth: SystemAction = {
    name: 'Pokaż tylko z poprzednich miesięcy',
    code: 'onlyBeforeMonth',
    icon: {
        faCode: 'fa-star',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'custom'
        }
    }
};

export const importPayoff: SystemAction = {
    name: 'Dodaj',
    code: 'importpayoff',
    icon: {
        faCode: 'fa-plus',
        svgFileName: 'dodaj.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'custom'
        }
    }
};

export const savePermissionsChanges: SystemAction = {
    code: 'savePermissionsChanges',
    name: 'Zapisz zmiany',
    icon: {
        faCode: 'fa-check',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'submitPermissionsForm'
        }
    }
};

export const budgetSave: SystemAction = {
    name: 'Zapisz zmiany',
    code: 'budget-save',
    icon: {
        faCode: 'fa-check',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'zapiszzmiany'
        }
    }
};

export const budgetToggleAllHidden: SystemAction = {
    name: 'Pokaż/ukryj wszystkie zafakturowane',
    code: 'toggleAllHidden',
    icon: {
        faCode: '',
        svgFileName: 'podglad.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'budgetWewAction',
            action: 'toggleAllHidden'
        }
    }
};

export const budgetToggleLastThreeMonthHidden: SystemAction = {
    name: 'Pokaż/ukryj wszystkie zafakt. z ost. 3 m',
    code: 'toggleLastThreeMonthHidden',
    icon: {
        faCode: '',
        svgFileName: 'podglad.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'budgetWewAction',
            action: 'toggleLastThreeMonthHidden'
        }
    }
};

export const budgetRaport: SystemAction = {
    name: 'Raport',
    code: 'budget-raport',
    icon: {
        faCode: 'fa-check',
        svgFileName: 'raporty.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'raport'
        }
    }
};

export const budgetPrepareBill: SystemAction = {
    name: 'Przygotuj fakture',
    code: 'budget-f',
    icon: {
        faCode: 'fa-check',
        svgFileName: 'finanse.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'przygotujfaktury'
        }
    }
};

export const budgetInvoiceBill: SystemAction = {
    name: 'Wystaw fakture',
    code: 'budget-fw',
    icon: {
        faCode: 'fa-check',
        svgFileName: 'faktury.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'faktury'
        }
    }
};
export const budgetInvoiceProform: SystemAction = {
    name: 'Wystaw proforme',
    code: 'budget-pro',
    icon: {
        faCode: 'fa-check',
        svgFileName: 'plik_tekstowy.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'proformy'
        }
    }
};

export const budgetInvoiceReceipt: SystemAction = {
    name: 'Wystaw paragon',
    code: 'budget-p',
    icon: {
        faCode: 'fa-check',
        svgFileName: 'import_wplat.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'paragony'
        }
    }
};

export const budgetNote: SystemAction = {
    name: 'Wystaw note księgową',
    code: 'budget-n',
    icon: {
        faCode: 'fa-check',
        svgFileName: 'noty_ksiegowe.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'notyksiegowe'
        }
    }
};

export const budgetNoteWew: SystemAction = {
    name: 'Wystaw note wewnętrzną',
    code: 'budget-w',
    icon: {
        faCode: 'fa-check',
        svgFileName: 'noty_ksiegowe.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'notawewnetrzna'
        }
    }
};

export const budgetNotInvoice: SystemAction = {
    name: 'Nie rozliczaj',
    code: 'budget-nierozliczaj',
    icon: {
        faCode: 'fa-check',
        svgFileName: 'obiekty_niezafakturowane.svg'
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'budgetAction',
            data: 'nierozliczaj'
        }
    }
};

export const signMassBill: SystemAction = {
    name: 'Wystaw wszystkie wybrane',
    code: 'mass-bill-save',
    icon: {
        faCode: 'fa-check',
        svgFileName: null
    },
    colorConditions: null,
    displayConditions: null,
    defaultColor: null,
    behaviour: {
        type: 'internal',
        data: {
            type: 'callEventBus',
            eventId: 'massBillAction',
            data: {
                kind: 'all'
            }
        }
    }
};

export const listActionAll = [prev, refresh, next, getLink, help, search];
export const listActionObject = [prev, refresh, next, getLink, help];
export const listActionBookCorrespondence = [prev, refresh, next, help];
export const listActionHelp = [prev, refresh, next];
export const listActionMassBill = [prev, refresh, next, signMassBill];
export const listActionConfig = [prev, refresh, next, configSave, help];
export const listActionMassEditObjects = [
    prev,
    refresh,
    next,
    massEditObjectsSave,
    massEditObjectsRejectSave,
    help
];
export const listActionBudget = [
    prev,
    refresh,
    help,
    budgetSave,
    budgetRaport,
    budgetPrepareBill,
    budgetInvoiceBill,
    budgetInvoiceProform,
    budgetInvoiceReceipt,
    budgetNote,
    budgetNotInvoice,
    budgetToggleAllHidden,
    budgetToggleLastThreeMonthHidden
];

export const listActionBudgetMin = [
    prev,
    refresh,
    help,
    budgetSave,
    budgetToggleAllHidden,
    budgetToggleLastThreeMonthHidden
];
export const listActionBudgetAll = [...listActionBudget, budgetNoteWew];
export const listActionSendMessage = [prev, refresh, next, help, sendMessage];
export const listActionObjectsNotBill = [prev, refresh, next, withActualMouth, help];
export const listActionObjectsNotBillOnlyBeforeMonth = [prev, refresh, next, onlyBeforeMouth, help];
export const listActionImportPayoff = [prev, refresh, next, help, importPayoff];
export const listStartup = [prev, refresh, next, help];
export const listPermissions = [prev, refresh, next, savePermissionsChanges, help];
