export function mapObjectValueAnyToObjectValueString(obj: { [key: string]: any }): {
    [key: string]: string;
} {
    const newObj: { [key: string]: string } = {};
    for (const key in obj) {
        if (typeof obj[key] === 'string') {
            newObj[key] = obj[key];
        } else if (typeof obj[key] === 'number') {
            newObj[key] = String(obj[key]);
        }
    }
    return newObj;
}
