import React from 'react';
import { BudgetBehaviorDataKind } from 'src/components/budgetScreen/molecules/budgetFormSubmit/types';
import { SystemAction, KeyValueObject } from 'src/data/types';
import { convertParamsObjectToStr } from 'src/utils/src/shared/convertParamsObjectToStr';

type CreateBudgetActionProps = {
    actionKind: React.MutableRefObject<BudgetBehaviorDataKind | null>;
    params: { [key: string]: any };
};

export function createBudgetAction({ params, actionKind }: CreateBudgetActionProps): SystemAction {
    const {
        dane_pol_pozycje,
        dane_pol_pozycjeproform,
        dane_pol_pozycjeparagonow,
        dane_pol_pozycjenotwewnetrznych,
        dane_pol_pozycjenotksiegowych,
        elementy_fakturowane,
        ...additionalParameters
    } = params;
    const additionalParametersStr = convertParamsObjectToStr(additionalParameters);

    return {
        name: 'PopupFormExtended',
        code: 'popupFormExtended',
        icon: {
            faCode: '',
            svgFileName: ''
        },
        colorConditions: null,
        displayConditions: null,
        behaviour: {
            type: 'popupFormExtended',
            data: {
                objectId: 'nowy',
                screen:
                    actionKind.current === 'przygotujfaktury'
                        ? 'faktury'
                        : actionKind.current ?? 'faktury',
                phpHiddenData: {
                    dane_pol_pozycje,
                    dane_pol_pozycjeproform,
                    dane_pol_pozycjeparagonow,
                    dane_pol_pozycjenotwewnetrznych,
                    dane_pol_pozycjenotksiegowych,
                    elementy_fakturowane
                } as KeyValueObject,
                additionalParameters: additionalParametersStr
            }
        }
    };
}
