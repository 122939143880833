import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import { loadInitialDataForLoggedUser } from 'src/store/src/general';
import { navigateWithTranslate } from 'src/utils';
import { useUpdateUserInfo } from './useUpdateUserInfo';

export const useOnUserSuccessullyLoggedIn = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const updateUserInfo = useUpdateUserInfo();

    return useCallback(async () => {
        await updateUserInfo();
        navigateWithTranslate(navigate, '/start');
        dispatch(loadInitialDataForLoggedUser());
    }, [dispatch, updateUserInfo, navigate]);
};
