import * as actionTypes from '../../constants/actions';
import mainWindowID from '../../constants/mainWindowID';
import { storeOpenWindows } from '../../../shared/utils';
import STORAGE_ACTIONS from '../../constants/storageActions';

export const initialState = {
    chatOpen: false,
    tabActive: false,
    disconnected: true,
    cb: null,
    unreadChats: {},
    openWindows: [],
    activeChats: [],
    hiddenWindows: [],
    notification: null,
    notificationsList: [],
    activeWindow: null,
    sounds: true,
    activeStatus: false,
    busy: false,
    loading: false,
    error: null,
    searchUsers: false,
    searchGroups: false,
    searchUsersQuery: '',
    searchGroupsQuery: ''
};

/**
 * returns new app state slice
 * @param {Object} state - slice of state
 * @param {{type: string, ?payload:{}}} action - action
 */
export default function appReducer(state = initialState, action) {
    const { payload, type } = action || {};
    const { openWindows } = state;

    switch (type) {
        case actionTypes.SET_DISCONNECTED: {
            return {
                ...state,
                disconnected: payload.boolean
            };
        }

        case actionTypes.SET_CHAT_OPEN: {
            // store chat state in sessionStorage
            payload.boolean
                ? storeOpenWindows(STORAGE_ACTIONS.OPEN_APP)
                : storeOpenWindows(STORAGE_ACTIONS.CLOSE_APP);

            return {
                ...state,
                chatOpen: payload.boolean
            };
        }

        case actionTypes.SET_TAB_ACTIVE: {
            return {
                ...state,
                tabActive: payload.boolean
            };
        }

        case actionTypes.RESET_APP_STATE: {
            return {
                ...state,
                openWindows: [],
                hiddenWindows: [],
                activeChats: [],
                activeWindow: null,
                notification: null,
                loading: false,
                error: null,
                searchUsers: false,
                searchGroups: false,
                searchUsersQuery: '',
                searchGroupsQuery: '',
                tabActive: false
            };
        }

        case actionTypes.SET_LOADING_STATE: {
            return {
                ...state,
                loading: payload.boolean
            };
        }

        case actionTypes.OPEN_CHAT_WINDOW: {
            if (openWindows.includes(payload.id)) {
                return state;
            }
            const newOpenWindows = [payload.id, ...openWindows];

            // save open windows in sessionStorage
            storeOpenWindows(STORAGE_ACTIONS.OPEN_WINDOW, newOpenWindows);

            return {
                ...state,
                openWindows: newOpenWindows
            };
        }

        case actionTypes.CLOSE_CHAT_WINDOW: {
            const filterdWindows = openWindows.filter((id) => id !== payload.id);

            // save open windows in sessionStorage
            storeOpenWindows(STORAGE_ACTIONS.CLOSE_WINDOW, filterdWindows);

            return {
                ...state,
                openWindows: filterdWindows,
                activeWindow: mainWindowID
            };
        }

        case actionTypes.PREPEND_CHAT_WINDOW: {
            const filterdWindows = openWindows.filter((id) => id !== payload.id);
            const newOpenWindows = [payload.id, ...filterdWindows];

            // save open windows in sessionStorage
            storeOpenWindows(STORAGE_ACTIONS.PREPEND_WINDOW, newOpenWindows);

            return {
                ...state,
                openWindows: newOpenWindows,
                activeWindow: payload.id
            };
        }

        case actionTypes.SET_ACTIVE_CHAT_WINDOW: {
            return {
                ...state,
                activeWindow: payload.id
            };
        }

        case actionTypes.REPLACE_TEMPORARY_ID: {
            const { roomID, tempID } = payload;
            const newOpenWindows = [...openWindows];

            newOpenWindows[openWindows.indexOf(tempID)] = roomID;

            // save open windows in sessionStorage
            storeOpenWindows(STORAGE_ACTIONS.REPLACE_ID, newOpenWindows);

            return {
                ...state,
                openWindows: newOpenWindows
            };
        }

        case actionTypes.SET_ERROR: {
            return {
                ...state,
                error: payload.error,
                loading: false
            };
        }

        case actionTypes.CANCEL_ERROR: {
            return {
                ...state,
                error: null
            };
        }

        case actionTypes.SET_ACTIVE_STATUS: {
            return {
                ...state,
                activeStatus: payload.boolean
            };
        }

        case actionTypes.SET_SOUNDS: {
            return {
                ...state,
                sounds: payload.boolean
            };
        }

        case actionTypes.SET_BUSY: {
            return {
                ...state,
                busy: payload.boolean
            };
        }

        case actionTypes.SET_UNREAD_CHATS: {
            const { list, initial } = payload;
            let unread = list;

            // Jeśli lista przychodzi od razu po synchronizacji powinna resetować stan unread, w przeciwnym razie inkrementuje
            if (!initial) {
                unread = { ...state.unreadChats };
                Object.keys(list).forEach((key) =>
                    typeof unread[key] === 'number'
                        ? (unread[key] += +list[key])
                        : (unread[key] = +list[key])
                );
            }

            return {
                ...state,
                unreadChats: unread
            };
        }

        case actionTypes.REMOVE_UNREAD_CHAT: {
            const { [payload.id]: _, ...rest } = state.unreadChats;
            return {
                ...state,
                unreadChats: rest
            };
        }

        case actionTypes.TOGGLE_SEARCH: {
            const { type } = payload;
            const search = `search${type[0].toUpperCase() + type.slice(1)}`;
            const searchQuery = `search${type[0].toUpperCase() + type.slice(1) + 'Query'}`;

            return {
                ...state,
                [search]: !state[search],
                [searchQuery]: ''
            };
        }

        case actionTypes.SET_SEARCH_QUERY: {
            const { type, value } = payload;
            const searchQuery = `search${type[0].toUpperCase() + type.slice(1) + 'Query'}`;

            return {
                ...state,
                [searchQuery]: value
            };
        }

        case actionTypes.ADD_ACTIVE_CHAT: {
            const { roomID } = payload;
            return {
                ...state,
                activeChats: [...state.activeChats, roomID]
            };
        }

        case actionTypes.SET_ACTIVE_CHATS: {
            const { activeChats } = payload;
            return {
                ...state,
                activeChats: activeChats
            };
        }

        case actionTypes.REMOVE_ACTIVE_CHAT: {
            const { roomID } = payload;

            return {
                ...state,
                activeChats: state.activeChats.filter((id) => id !== roomID)
            };
        }

        case actionTypes.SET_CALLBACK: {
            const { cb } = payload;

            return {
                ...state,
                cb: cb
            };
        }

        case actionTypes.EXECUTE_CALLBACK: {
            /* Execute and clear */
            if (typeof state.cb === 'function') {
                state.cb();
            } else {
                console.error('callback is not a function');
            }

            return {
                ...state,
                cb: null
            };
        }

        case actionTypes.SET_HIDDEN_WINDOWS: {
            const { windows } = payload;

            return {
                ...state,
                hiddenWindows: windows
            };
        }

        case actionTypes.SET_NOTIFICATIONS: {
            const { list } = payload;

            return {
                ...state,
                notificationsList: [...state.notificationsList, ...list]
            };
        }

        case actionTypes.SET_NOTIFICATION: {
            const { notification } = payload;

            return {
                ...state,
                notification
            };
        }

        case actionTypes.REMOVE_NOTIFICATION: {
            const { notification } = payload;

            return {
                ...state,
                notificationsList: state.notificationsList.filter((n) => n !== notification),
                notification: null
            };
        }

        default:
            return state;
    }
}

/**
 * @description - Wycinek stanu aplikacji dotyczący funkcjonowania aplikacji
 * @typedef {Object} Store.AppSlice
 * @property {boolean} chatOpen - Czy aplikacja jest otwarta
 * @property {boolean} tabActive - Czy ta karta przeglądarki jest aktualnie aktywna (tylko jedna karta może być w danym czasie aktywna i komunikować się z serwerem)
 * @property {boolean} disconneected - Czy aplikacja nie może się połączyć z serwerem
 * @property {null|function} cb - callback do wykonania
 * @property {string} sessionID - id sesji
 * @property {string} node_url - id użytkownika
 * @property {string} system_url - id użytkownika
 * @property {Object.<string, number>} unreadChats - Obiekt typu słownik gdzie kluczami są id czatów a wartościami ilość nieodczytanych wiadomości dla danego czatu.
 * @property {string[]} activeChats - Lista id czatów które są aktualnie aktywne, co oznacza że są otwarte w którejś z kart lub przeglądarek użytkownika
 * @property {string[]} openWindows - Lista id okien czatów które są otwarte
 * @property {string[]} hiddenWindows - Lista id okien czatów które są otwarte ale schowane ponieważ nie mieszczą się na ekranie
 * @property {string| null} activeWindow - id okna ostatnio klikniętego czatu.
 * @property {boolean} sounds - ustawienie czy należy odtwarzać dźwięki
 * @property {boolean} activeStatus - informacja czy użytkownik ma się wyświetlać jako aktywny innym uzytkownikom czatu
 * @property {boolean} busy - ustawienie zajętości, jeśli true, nowe okna czatu nie będą się automatycznie otwierać gdy pojawiają się nowe wiadomośći
 * @property {boolean} loading - Stan ładowania głównego okna czatu
 * @property {Object} error - stan błędu aplikacji
 * @property {string} error.type - wiadomość o typie błędu
 * @property {string} error.message - tekst błędu
 * @property {boolean} error.critical - flaga wyznaczająca czy trzeba zresetować aplikację
 * @property {boolean} searchUsers - Czy uruchomiony jest tryb filtrowania użytkowników
 * @property {boolean} searchGroups - Czy uruchomiony jest tryb filtrowania grup
 * @property {string} searchUsersQuery - Fraza filrująca  użytkowników
 * @property {string} searchGroupsQuery - Fraza filrująca  użytkowników
 * @property {string[]} notigicationsList - Lista powiadomień do wyświetlenia
 * @property {string} notification - aktualne wyświetlane powiadomienie
 */
