import React, { FC } from 'react';

import { UiFrameDashProps } from './types';
import styles from './UiFrameDash.module.css';

export const UiFrameDash: FC<UiFrameDashProps> = ({ children, style }) => {
    return (
        <div
            className={styles.wrapper}
            style={{
                ...style
            }}>
            {children}
        </div>
    );
};
