import { SettleObjectIdForLink } from 'src/store/src/budget/budget/types';

type CreateQueryParamsForLinkInBudgetProps = {
    codeForLink?: string;
    id: string;
    obiekt: string;
    idForLink?: SettleObjectIdForLink;
};

export function createQueryParamsForLinkInBudget({
    codeForLink,
    obiekt,
    id,
    idForLink
}: CreateQueryParamsForLinkInBudgetProps) {
    return new URLSearchParams({
        id: idForLink ? idForLink : id,
        obiekt: codeForLink ? codeForLink : obiekt
    });
}
