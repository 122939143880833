import React, { FC } from 'react';

import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { ApiActionWithActualMonthProps } from './types';
import { useObjectNotBillDataWithActualMonth } from 'src/hooks/src/objectNotBillScreen/useObjectNotBillDataWithActualMonth';

const ApiActionWithActualMonth: FC<ApiActionWithActualMonthProps> = ({ children }) => {
    const { onClick } = useObjectNotBillDataWithActualMonth();
    return (
        <ButtonCustom onClick={onClick} withoutStyles={true}>
            {children}
        </ButtonCustom>
    );
};

export default ApiActionWithActualMonth;
