import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { fetchStartupScreenDataForBlock } from 'src/api/src/startupScreen/fetchStartupScreenDataForBlock';
import { StartupBlock, StartupBlockTypes } from 'src/store/src/startupScreen/startupScreen/types';

export type UseGetDataForBlock = {
    block: StartupBlock;
    loading: boolean;
    updateScope: (id: string) => void;
    updateMonth: (value: string, type: StartupBlockTypes) => void;
    updateData: () => void;
};

type UseGetDataForBlockProps = {
    block: StartupBlock;
};

export const useGetDataForBlock = ({ block }: UseGetDataForBlockProps): UseGetDataForBlock => {
    const [dataBlock, setDataBlock] = useState<StartupBlock>(block);
    const [loading, setLoading] = useState<boolean>(true);
    const updateScope = (id: string) => updateScopeWew({ id, dataBlock, setDataBlock, setLoading });
    const updateMonth = (value: string, type: StartupBlockTypes) =>
        updateMonthWew({ value, dataBlock, setDataBlock, setLoading, type });
    const updateData = () => updateDataWew({ setLoading });
    useEffect(() => {
        if (loading) {
            const params = prepareParams(dataBlock).toString();
            const fetchData = async () => {
                const response = await fetchStartupScreenDataForBlock(params);
                if (response.success) {
                    setDataBlock((prev) => {
                        return {
                            ...prev,
                            data: response.data
                        } as StartupBlock;
                    });
                }
                setLoading(false);
            };

            fetchData();
        }
    }, [loading]);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setLoading(true);
        }, 300000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return { block: dataBlock, loading, updateScope, updateMonth, updateData };
};

function updateScopeWew({
    id,
    dataBlock,
    setDataBlock,
    setLoading
}: {
    id: string;
    dataBlock: StartupBlock;
    setDataBlock: Dispatch<SetStateAction<StartupBlock>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
}) {
    if (dataBlock.type === 'hoursStatistics' && dataBlock.data) {
        setDataBlock({
            ...dataBlock,
            data: {
                ...dataBlock.data,
                selectedScopeId: id
            }
        });
        setLoading(true);
    }
}

function prepareDate(date: string, value: string) {
    const dateArr = date.split('-');
    let year = parseInt(dateArr[0]);
    let month = parseInt(dateArr[1]);
    if (month === 1 && value === 'before') {
        year = year - 1;
        month = 12;
        if (month < 10) {
            return `${year}-0${month}`;
        }
        return `${year}-${month}`;
    } else if (month === 12 && value === 'after') {
        year = year + 1;
        month = 1;
        if (month < 10) {
            return `${year}-0${month}`;
        }
        return `${year}-${month}`;
    } else {
        if (value === 'before') {
            month = month - 1;
        } else {
            month = month + 1;
        }
        if (month < 10) {
            return `${year}-0${month}`;
        }
        return `${year}-${month}`;
    }
}

function updateMonthWew({
    value,
    dataBlock,
    setDataBlock,
    setLoading,
    type
}: {
    value: string;
    dataBlock: StartupBlock;
    setDataBlock: Dispatch<SetStateAction<StartupBlock>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    type: StartupBlockTypes;
}) {
    switch (type) {
        case 'hoursStatistics':
            if (dataBlock.type === 'hoursStatistics' && dataBlock.data) {
                const newDate = prepareDate(dataBlock.data.selectedMonth, value);
                setDataBlock({
                    ...dataBlock,
                    data: {
                        ...dataBlock.data,
                        selectedMonth: newDate
                    }
                });
                setLoading(true);
            }
            break;
        case 'finances':
            if (dataBlock.type === 'finances' && dataBlock.data) {
                const newDate = prepareDate(dataBlock.data.selectedMonth, value);
                setDataBlock({
                    ...dataBlock,
                    data: {
                        ...dataBlock.data,
                        selectedMonth: newDate
                    }
                });
                setLoading(true);
            }
            break;
    }
}

function prepareParams(block: StartupBlock) {
    const params = new URLSearchParams();
    params.append('forBlock', block.code);
    if (block.type === 'hoursStatistics') {
        if (block.data) {
            params.append('scope', block.data.selectedScopeId);
            params.append('month', block.data.selectedMonth);
        }
    } else if (block.type === 'finances') {
        if (block.data) {
            params.append('month', block.data.selectedMonth);
        }
    }
    return params;
}

function updateDataWew({ setLoading }: { setLoading: Dispatch<SetStateAction<boolean>> }) {
    setLoading(true);
}
