export function copyTextToClipboard({
    text,
    callbackNegative,
    callbackPositive
}: {
    text: string;
    callbackPositive?: () => void;
    callbackNegative?: () => void;
}) {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            if (callbackPositive) callbackPositive();
        })
        .catch((err) => {
            if (callbackNegative) callbackNegative();
        });
}
