import React, { FC } from 'react';

import { FieldMinWidthProps } from 'src/components/shared/atoms/fieldMinWidth/types';
import { getMinWidthForField } from 'src/utils/src/shared/getMinWidthForField';

// uwaga wykorzystywany:
// - relacje w popupach
// - ekran masowej edycji obiektow

export const FieldMinWidth: FC<FieldMinWidthProps> = ({
    children,
    dynamicFieldT,
    fieldPlacement
}) => {
    return (
        <div
            style={{
                minWidth: getMinWidthForField(dynamicFieldT, fieldPlacement)
            }}>
            {children}
        </div>
    );
};
