import React, { FC, useState } from 'react';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import styles from './PopupContentChangePassword.module.css';
import { BasePopupContentProps } from '../../organisms/globalPopup/GlobalPopup';
import { usePopupState } from 'src/context/globalPopup/popupContext';
import { InputsState, PopupChangePasswordData } from './types';
import PasswordRequirementsChecker, {
    PasswordRequirementsCheckResult
} from './PasswordRequirementsChecker';
import { zxcvbn } from '@zxcvbn-ts/core';
import { Button } from '@mui/material';
import { globalPopup } from 'src/constants/subtitles';
import { useAppDispatch, useTypedSelector } from 'src/store';
import PasswordValidator from './PasswordValidator';
import { changePassword } from 'src/api/src/shared/changePassword';
import { closePopup } from 'src/store/src/popup';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import RequirementsBlock from './requirementsBlock/RequirementsBlock';
import { usePressKeyWithKeyCodeTwo, useRequirementsEnabled } from 'src/hooks';
import { clearFirst } from 'src/store/src/general';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { PasswordInput } from '../../../popupFormExtended/dynamicField/shared/passwordInput/PasswordInput';

const PopupContentChangePassword: FC<BasePopupContentProps> = ({ popup }) => {
    const { popupStateRedux } = usePopupState(popup.key);
    const userSliceData = useTypedSelector((state) => state.user);
    const [inputsState, setInputsState] = useState<InputsState>({
        actualPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
    });
    const [passwordPowerColor, setPasswordPowerColor] = useState<string>('secondary');
    const [requirementsState, setRequirementsState] = useState<PasswordRequirementsCheckResult>({
        hasla_blokuj_popularne: false,
        hasla_cyfry: false,
        hasla_dlugosc: false,
        hasla_dlugosc_maksymalna: false,
        hasla_duze_litery: false,
        hasla_male_litery: false,
        hasla_znaki_specjalne: false
    });
    const behaviourData = popupStateRedux.action?.behaviour?.data?.data as PopupChangePasswordData;
    const field = behaviourData.passwordfield;
    const dispatch = useAppDispatch();

    const getPasswordStrengthColor = (power: number) => {
        switch (power) {
            case 0:
                return '#ff0000';
            case 1:
                return '#ff6200';
            case 2:
                return '#ffd800';
            case 3:
                return '#8df516';
            case 4:
                return '#2cb117';
            default:
                return 'secondary';
        }
    };

    const updateNewPassword = async (newPassword: string) => {
        setInputsState((state) => ({
            ...state,
            newPassword: newPassword
        }));
        setPasswordPowerColor(
            getPasswordStrengthColor(newPassword.length > 0 ? zxcvbn(newPassword).score : 0)
        );

        const newValidatedState = await new PasswordRequirementsChecker(
            newPassword,
            field
        ).checkRequirements(requirementsEnabled.fields);
        setRequirementsState(newValidatedState);
    };

    const requirementsEnabled = useRequirementsEnabled(field);

    const handleYesButton = async () => {
        const validationResult = await new PasswordValidator(
            inputsState,
            behaviourData.userPasswordValidation,
            userSliceData.userType ?? '',
            behaviourData.objectId
        ).checkPasswordCorrectnessOnSubmit(requirementsEnabled.fields, requirementsState);

        if (!validationResult.valid) {
            new PopupManager({ dispatch: dispatch, action: null }).setPopupInfo(
                validationResult.message
            );
            return;
        }
        dispatch(setSpinner(true));
        const response = await changePassword(
            behaviourData.objectId,
            behaviourData.screen,
            behaviourData.userPasswordValidation,
            inputsState.newPassword,
            inputsState.actualPassword
        );
        if (!response.success) {
            new PopupManager({ dispatch: dispatch, action: null }).setPopupInfo(response.message);
            return;
        }

        dispatch(clearFirst());
        dispatch(closePopup(popup.key));
        dispatch(setSpinner(false));
    };

    usePressKeyWithKeyCodeTwo(() => handleYesButton(), 'Enter', popup.key);

    React.useEffect(() => {
        const key = behaviourData.userPasswordValidation ? 'passwordOne' : 'passwordTwo';
        const el2: HTMLElement | null = document.querySelector(`[data-fieldkey-input=${key}]`);
        el2?.focus();
        el2?.click();
    }, []);

    return (
        <PopupTemplateThird title={popupStateRedux.action?.name ?? ''}>
            <div className={styles.wrapper}>
                <p className={styles.topText}>
                    {behaviourData.infoText ??
                        'Zmiana hasła (usuwa również zapamiętany kod QR jeżeli zmieniane jest hasło innego użytkownika)'}
                </p>
                <div className={styles.inputsWrapper}>
                    {behaviourData.userPasswordValidation && (
                        <>
                            <PasswordInput
                                uiFieldCommonProps={{
                                    name: 'Podaj swoje obecne hasło:',
                                    isLabel: true
                                }}
                                uiFieldSharedProps={{
                                    dynamicFieldT: field
                                }}
                                datakey="passwordOne"
                                value={inputsState.actualPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setInputsState((state) => ({
                                        ...state,
                                        actualPassword: e.target.value
                                    }))
                                }
                            />
                        </>
                    )}

                    <PasswordInput
                        uiFieldCommonProps={{
                            name: 'Wpisz nowe hasło:',
                            isLabel: true
                        }}
                        datakey="passwordTwo"
                        uiFieldSharedProps={{ dynamicFieldT: field }}
                        value={inputsState.newPassword}
                        sxinput={{ borderColor: passwordPowerColor }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateNewPassword(e.target.value)
                        }
                    />

                    <PasswordInput
                        uiFieldCommonProps={{
                            name: 'Powtórz nowe hasło:',
                            isLabel: true
                        }}
                        uiFieldSharedProps={{ dynamicFieldT: field }}
                        value={inputsState.newPasswordRepeat}
                        sxinput={{ borderColor: passwordPowerColor }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setInputsState((state) => ({
                                ...state,
                                newPasswordRepeat: e.target.value
                            }))
                        }
                    />
                </div>
                <RequirementsBlock
                    field={field}
                    requirementsEnabled={requirementsEnabled}
                    requirementsState={requirementsState}
                />
                <div className={styles.bottomButtonWrapper}>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={handleYesButton}>
                        <p>{globalPopup.contentInfo.button.yes}</p>
                    </Button>
                </div>
            </div>
        </PopupTemplateThird>
    );
};

export default PopupContentChangePassword;
