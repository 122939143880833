import { BaseApiResponse } from 'src/api/types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { QrCodeScreenData } from './types';

export async function getQrCodeData(
    userID: string,
    userType: string
): Promise<BaseApiResponse<QrCodeScreenData>> {
    return simpleQueryAPIData('api_react/src/qrCodeScreen/getQrCodeData.php', 'POST', {
        userID,
        userType
    });
}
