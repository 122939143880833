import React, { FC } from 'react';

import styles from './styles.module.css';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { fieldDataHours } from 'src/constants/inputDateHourData';
import { StartEndHoursMinutesProps } from './types';
import { useGetMinutesOptionsForField } from 'src/hooks';

export const StartEndHoursMinutes: FC<StartEndHoursMinutesProps> = (props) => {
    const { onChangeH, onChangeM, hours, minutes, dynamicFieldT } = props;
    const optionsMinutes = useGetMinutesOptionsForField(dynamicFieldT);

    return (
        <div className={styles.wrapper}>
            <UiInputSelectTwo
                value={hours}
                opcje={fieldDataHours}
                onChange={onChangeH}
                sxinput={{
                    width: '65px'
                }}
            />
            <UiInputSelectTwo
                value={minutes}
                opcje={optionsMinutes}
                onChange={onChangeM}
                sxinput={{
                    width: '65px'
                }}
            />
        </div>
    );
};
