import React, { FC, useCallback } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';
import { FieldTextWithoutRestrictObjectProps } from './types';
import { UIFieldTextWithoutRestrict } from 'src/components/popupFormExtended/dynamicField/fieldTextWithoutRestrict/UIFieldTextWithoutRestrict';
import { dataSetInputKey } from 'src/constants/dataset';

export const FieldTextWithoutRestrictObject: FC<FieldTextWithoutRestrictObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>, onBlur: boolean) => {
        setState(e.target.value);
        if (onBlur) {
            dispatch({
                type: 'tekst_bez_ograniczen',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: e.target.value
                    }
                }
            });
        }
    };

    const onChangeW = (content: string, onBlur: boolean) => {
        setState(content);
        if (onBlur) {
            dispatch({
                type: 'tekst_bez_ograniczen',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: content
                    }
                }
            });
        }
    };

    const onAcceptValue = useCallback(() => {
        setState(field.valueToAccept);
        dispatch({ type: 'accept_value', payload: keyOfFieldInState });
    }, [dispatch, keyOfFieldInState, field.valueToAccept]);

    React.useEffect(() => {
        if (JSON.stringify(field.value) !== JSON.stringify(state)) {
            setState(field.value);
        }
    }, [field.value]);

    return (
        <UIFieldTextWithoutRestrict
            value={state}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            onAcceptValue={onAcceptValue}
            onChange={(e) => onChange(e, false)}
            onChangeW={onChangeW}
            onBlur={(e) => onChange(e, true)}
        />
    );
};
