import React, {
    FC,
    useRef,
    useState,
    useMemo,
    useEffect,
    Reducer,
    PropsWithChildren,
    useImperativeHandle
} from 'react';
import { Box } from '@mui/material';
import {
    IScrollPan,
    ScrollActionReducerType,
    ScrollToEvent,
    HTMLElementCustomEventsElement
} from './types';

import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { useObserveElementSize } from 'src/hooks/src/shared/useObserveElementSize';

const ScrollPan = React.forwardRef<HTMLElementCustomEventsElement, PropsWithChildren<IScrollPan>>(
    (
        {
            showVertical,
            showHorizonal,
            background,
            thumbColor,
            children,
            icon,
            size,
            maxWidth,
            maxHeight,
            minHeight,
            minWidth,
            childDirectParentHeight,
            paddingBottom = 0,
            paddingLeft = 0,
            paddingTop = 0,
            paddingRight = 0,
            marginTopScrollY = '0em',
            paddingBottomScroll = '0.5em',
            headerFixedHeight = 0,
            boxShadow = true,
            offInitialScroll = false
        },
        ref
    ) => {
        const outerWraper = useRef<HTMLElement>(null);
        const innerWraper = useRef<HTMLElementCustomEventsElement>(null);
        const innerChild = useRef<HTMLElement>(null);
        const thumbY = useRef<HTMLDivElement>(null);
        const thumbYWraper = useRef<HTMLDivElement>(null);
        const thumbX = useRef<HTMLDivElement>(null);
        const thumbXWraper = useRef<HTMLDivElement>(null);

        useImperativeHandle(ref, () => innerWraper.current!);

        const [thumbXLeft, SetThumbXLeft] = useState<string>('0%');
        const [thumbYTop, SetThumbYTop] = useState<string>('0.3em');

        let innerScrollPanDims = useObserveElementSize(innerChild); // to inspect

        const [thumbXWidth, setThumbXWidth] = useState<number>(0);
        const [thumbYHeight, setThumbYHeight] = useState<number>(0);

        type ScrollStateType = {
            thumbXLeft: string;
            thumbYTop: string;
            clickHold: boolean;
            holdPosY: number;
            holdPosX: number;
            scrollTop: number;
            scrollLeft: number;
            screenSize: Array<number>;
        };

        const initialScrollState: ScrollStateType = {
            thumbXLeft: '0%',
            thumbYTop: '0.3em',
            clickHold: false,
            holdPosY: 0,
            holdPosX: 0,
            scrollTop: 0,
            scrollLeft: 0,
            screenSize: [0, 0]
        };

        const scrollReducer = (state: ScrollStateType, action: ScrollActionReducerType) => {
            const { type, payload } = { ...action };
            switch (type) {
                case 'HOLD_CLICK_X':
                    return {
                        ...state,
                        clickHold: true,
                        holdPosX: payload.holdX,
                        scrollLeft: payload.left
                    };
                case 'HOLD_CLICK_Y':
                    return {
                        ...state,
                        clickHold: true,
                        holdPosY: payload.holdY,
                        scrollTop: payload.top
                    };
                case 'SCROLL_TO_Y':
                    return {
                        ...state,
                        holdPosY: payload.top_position,
                        scrollTop: payload.top_position
                    };
                case 'RELEASE_HOLD':
                    if (state.clickHold == true) {
                        return { ...state, clickHold: false };
                    } else {
                        return state;
                    }
                case 'SET_SCREEN_SIZE':
                    return { ...state, screenSize: payload };
                default:
                    return state;
            }
        };

        const [scrollState, dispatchScroll] = React.useReducer<
            Reducer<ScrollStateType, ScrollActionReducerType>
        >(scrollReducer, initialScrollState);

        useEffect(() => {
            calculateThumb();

            const scrollTo = (event: ScrollToEvent) => {
                if (innerWraper?.current) innerWraper.current.scrollTo(0, event.detail);
                dispatchScroll({
                    type: 'SCROLL_TO_Y',
                    payload: { top_position: event.detail }
                });
            };

            innerWraper?.current?.addEventListener('scrollTo', scrollTo);

            thumbYWraper?.current?.addEventListener('mousedown', (event) => {
                event.preventDefault();
                dispatchScroll({
                    type: 'HOLD_CLICK_Y',
                    payload: { holdY: event.clientY, top: innerWraper?.current?.scrollTop || 0 }
                });
            });
            thumbYWraper?.current?.addEventListener('touchstart', (event) => {
                event.preventDefault();
                dispatchScroll({
                    type: 'HOLD_CLICK_Y',
                    payload: {
                        holdY: event.touches[0].clientY,
                        top: innerWraper?.current?.scrollTop || 0
                    }
                });
            });

            thumbXWraper?.current?.addEventListener('mousedown', (event) => {
                event.preventDefault();
                dispatchScroll({
                    type: 'HOLD_CLICK_X',
                    payload: { holdX: event.clientX, left: innerWraper?.current?.scrollLeft || 0 }
                });
            });

            thumbXWraper?.current?.addEventListener('touchstart', (event) => {
                event.preventDefault();
                dispatchScroll({
                    type: 'HOLD_CLICK_X',
                    payload: {
                        holdX: event.touches[0].clientX,
                        left: innerWraper?.current?.scrollLeft || 0
                    }
                });
            });

            window.addEventListener('resize', () => {
                dispatchScroll({
                    type: 'SET_SCREEN_SIZE',
                    payload: [window.innerWidth, window.innerHeight]
                });
            });
            window.addEventListener('mouseup', () => {
                dispatchScroll({ type: 'RELEASE_HOLD' });
            });

            window.addEventListener('touchend', () => {
                dispatchScroll({ type: 'RELEASE_HOLD' });
            });

            return () => {
                innerWraper?.current?.removeEventListener('scrollTo', scrollTo);

                thumbYWraper?.current?.removeEventListener('mousedown', (event) => {
                    event.preventDefault();
                    if (innerWraper?.current) {
                        dispatchScroll({
                            type: 'HOLD_CLICK_Y',
                            payload: { holdY: event.clientY, top: innerWraper?.current.scrollTop }
                        });
                    }
                });

                thumbXWraper?.current?.removeEventListener('mousedown', (event) => {
                    event.preventDefault();
                    dispatchScroll({
                        type: 'HOLD_CLICK_X',
                        payload: {
                            holdX: event.clientX,
                            left: innerWraper?.current?.scrollLeft || 0
                        }
                    });
                });

                thumbXWraper?.current?.removeEventListener('touchstart', (event) => {
                    event.preventDefault();
                    dispatchScroll({
                        type: 'HOLD_CLICK_X',
                        payload: {
                            holdX: event.touches[0].clientX,
                            left: innerWraper?.current?.scrollLeft || 0
                        }
                    });
                });

                thumbYWraper?.current?.removeEventListener('touchstart', (event) => {
                    event.preventDefault();
                    dispatchScroll({
                        type: 'HOLD_CLICK_Y',
                        payload: {
                            holdY: event.touches[0].clientY,
                            top: innerWraper?.current?.scrollTop || 0
                        }
                    });
                });

                window.removeEventListener('resize', () => {
                    dispatchScroll({
                        type: 'SET_SCREEN_SIZE',
                        payload: [window.innerWidth, window.innerHeight]
                    });
                });
                window.removeEventListener('mouseup', () => {
                    dispatchScroll({ type: 'RELEASE_HOLD' });
                });

                window.removeEventListener('touchend', () => {
                    dispatchScroll({ type: 'RELEASE_HOLD' });
                });
            };
        }, [showVertical, showHorizonal]);

        const calculateThumb = () => {
            if (innerWraper?.current && outerWraper?.current) {
                let calculatedWidth =
                    outerWraper.current.scrollWidth *
                    (outerWraper.current.scrollWidth / innerWraper.current.scrollWidth);

                if (
                    innerWraper.current.scrollWidth != innerWraper.current.clientWidth &&
                    Math.abs(calculatedWidth - innerWraper.current.clientWidth) <= size * 2
                ) {
                    calculatedWidth -= size * 2;
                }

                const calculatedHeight =
                    outerWraper.current.scrollHeight *
                    (outerWraper.current.scrollHeight /
                        (innerWraper.current.scrollHeight + headerFixedHeight));

                setThumbXWidth(calculatedWidth);
                setThumbYHeight(calculatedHeight);
            }
        };

        React.useEffect(() => {
            calculateThumb();
        }, [innerScrollPanDims]);

        useEffect(() => {
            if (offInitialScroll != true) {
                setTimeout(() => {
                    if (innerWraper?.current) {
                        innerWraper.current.scrollTop = 0;
                        innerWraper.current.scrollLeft = 0;
                    }
                }, 300);
            }
        }, [scrollState.screenSize]);

        useEffect(() => {
            moveBars();
        }, [thumbYHeight, thumbXWidth]);

        const onThumbMoveY = (event: React.MouseEvent | React.TouchEvent): void => {
            event.preventDefault();

            if (scrollState.clickHold == true) {
                if (innerWraper?.current && thumbY?.current && thumbYWraper?.current) {
                    const scale =
                        innerWraper.current.scrollHeight /
                        (thumbYWraper.current.clientHeight - size * 2);
                    let movement = 0;
                    if (Object.keys(event).indexOf('clientY') > -1) {
                        movement = -scrollState.holdPosY + (event as React.MouseEvent).clientY;
                    }
                    if (Object.keys(event).indexOf('changedTouches') > -1) {
                        movement =
                            -scrollState.holdPosY +
                            (event as React.TouchEvent).changedTouches[0].clientY;
                    }

                    innerWraper.current.scrollTop = scrollState.scrollTop + movement * scale; // scroll the inner by %
                }
            }
        };

        const onThumbMoveX = (event: React.MouseEvent | React.TouchEvent): void => {
            event.preventDefault();
            if (scrollState.clickHold == true) {
                if (innerWraper?.current && thumbX?.current && thumbXWraper?.current) {
                    const scale =
                        innerWraper.current.scrollWidth /
                        (thumbXWraper.current.clientWidth - size * 2);

                    let movement = 0;
                    if (Object.keys(event).indexOf('clientX') > -1) {
                        movement = -scrollState.holdPosX + (event as React.MouseEvent).clientX;
                    }
                    if (Object.keys(event).indexOf('changedTouches') > -1) {
                        movement =
                            -scrollState.holdPosX +
                            (event as React.TouchEvent).changedTouches[0].clientX;
                    }

                    innerWraper.current.scrollLeft = scrollState.scrollLeft + movement * scale; // scroll the inner by % %
                }
            }
        };

        const iconDefault = useMemo(() => {
            return {
                faCode: '',
                svgFileName: icon !== undefined ? icon : 'arrow_white.svg'
            };
        }, [icon]);

        const marginForXThumb = useMemo(() => {
            return showHorizonal ? `${size + 4}px` : '0px';
        }, [showHorizonal]);

        const marginForYThumb = useMemo(() => {
            return showVertical ? `${size}px` : '0px';
        }, [showVertical]);

        const iconOutlineStyles = useMemo(() => {
            return {
                position: 'relative',
                cursor: 'pointer',
                display: 'flex',
                width: `${size}px`,
                height: `${size}px`,
                alignItems: 'center'
            };
        }, [size]);

        const thumbStyle = useMemo(() => {
            return {
                borderRadius: '2px',
                position: 'absolute',
                transtion: 'all 0.2s ease-in-out',
                background: `${thumbColor ? thumbColor : '#fefefe'}`
            };
        }, [thumbColor]);

        //scroll button actions

        const move = (direction: 'DOWN' | 'UP' | 'LEFT' | 'RIGHT') => {
            if (innerWraper?.current) {
                switch (direction) {
                    case 'DOWN':
                        innerWraper.current.scrollTop += thumbYHeight;
                        break;
                    case 'UP':
                        innerWraper.current.scrollTop -= thumbYHeight;
                        break;
                    case 'RIGHT':
                        innerWraper.current.scrollLeft += thumbXWidth;
                        break;
                    case 'LEFT':
                        innerWraper.current.scrollLeft -= thumbXWidth;
                        break;
                    default:
                        console.warn('ScrollPan move(): no such direction choise');
                }
            }
        };

        // some const style
        const iconStyle = {
            fontSize: '14px',
            width: `${size - 1}px`,
            height: `${size - 1}px`,
            maxWidth: '100%',
            maxHeight: '100%',
            display: 'flex'
        };
        const thumbOuterStyle = {
            borderRadius: '3px',
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            boxSizing: 'content-box'
        };

        function moveBars() {
            if (innerWraper?.current && outerWraper?.current) {
                if (thumbYWraper?.current) {
                    const { height } = thumbYWraper?.current?.getBoundingClientRect();

                    const thumbYCalculation =
                        ((innerWraper.current.scrollTop + outerWraper.current.scrollHeight) /
                            innerWraper.current.scrollHeight) *
                        100;

                    let thumbYTopPosition = `clamp(0.3em,calc(${Math.min(
                        thumbYCalculation,
                        100
                    )}% - ${thumbYHeight}px),${height}px)`;

                    SetThumbYTop(thumbYTopPosition);
                }

                if (thumbXWraper?.current) {
                    const { width } = thumbXWraper?.current?.getBoundingClientRect();

                    const thumbXCalculation =
                        ((innerWraper.current.scrollLeft + outerWraper.current.scrollWidth) /
                            innerWraper.current.scrollWidth) *
                        100;

                    let thumbXLeftPosition = `clamp(0px,calc(${Math.min(
                        thumbXCalculation,
                        100
                    )}% - ${thumbXWidth}px),${width}px)`;
                    SetThumbXLeft(thumbXLeftPosition);
                }
            }
        }

        const bs = '0 0 12px -5px black';

        return (
            <Box
                className="outerWraper"
                ref={outerWraper}
                sx={{
                    position: 'relative',
                    boxSizing: 'border-box',
                    width: '100%',
                    height: '100%',
                    minHeight: `calc(${minHeight})`,
                    maxHeight: `calc(${maxHeight})`,
                    minWidth: `calc(${minWidth})`,
                    maxWidth: `calc(${maxWidth})`,
                    scrollBehavior: 'smooth!important'
                    // transition: 'all 0.3s linear'
                }}>
                <Box
                    className="inner"
                    ref={innerWraper}
                    onScroll={() => {
                        moveBars();
                    }}
                    sx={{
                        width: `calc(100% - ${marginForYThumb} - ${paddingRight + paddingLeft}px)`,
                        height: `calc(100% - ${marginForXThumb} - ${paddingTop + paddingBottom}px)`,
                        position: 'absolute',
                        top: `${paddingTop}px`,
                        left: `${paddingLeft}px`,
                        boxShadow: boxShadow ? bs : 'none',
                        zIndex: '1',
                        float: 'left',
                        overflow: 'scroll',
                        scrollbarWidth: 'none',
                        maxHeight: `calc(${maxHeight} - ${marginForXThumb})`,
                        '::-webkit-scrollbar': {
                            display: 'none',
                            height: 0
                        }
                    }}>
                    <Box
                        sx={{ height: childDirectParentHeight ?? 'auto', position: 'relative' }}
                        className="innerC"
                        ref={innerChild}>
                        {children}
                    </Box>
                </Box>
                {showHorizonal == true && (
                    <Box
                        sx={{
                            height: `${size}px`,
                            bottom: '0px',
                            position: 'absolute',
                            maxWidth: '100%',
                            width: `calc(100% - ${marginForYThumb})`,
                            background: background ? background : '#eee',
                            boxSizing: 'content-box',

                            display: 'grid',
                            gridTemplateColumns: 'auto 1fr auto',
                            zIndex: '2',
                            alignItems: 'center',
                            alignContent: 'center'
                        }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                backgroundColor: 'transparent',
                                width: '100%',
                                height: `${scrollState.clickHold ? size + 100 + 'px' : '0px'}`,
                                zIndex: `${scrollState.clickHold ? '1000' : '-1'}`
                            }}
                            onTouchMove={(e: React.TouchEvent) => {
                                onThumbMoveX(e);
                            }}
                            onMouseMove={(e: React.MouseEvent) => {
                                onThumbMoveX(e);
                            }}></Box>
                        <Box onClick={() => move('LEFT')} sx={{ ...iconOutlineStyles }}>
                            <ThemeCompatibleIcon
                                iconData={iconDefault}
                                sx={{
                                    transform: 'rotate(-90deg)',
                                    ...iconStyle
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                ...thumbOuterStyle,
                                height: `${size - 2}px`,
                                width: '100%'
                            }}>
                            <Box
                                ref={thumbXWraper}
                                sx={{
                                    position: 'relative',
                                    width: '100%'
                                }}>
                                <Box
                                    ref={thumbX}
                                    sx={{
                                        width: `${thumbXWidth}px`,
                                        height: `${size - 2}px`,
                                        ...thumbStyle,
                                        left: `${thumbXLeft}`
                                    }}></Box>
                            </Box>
                        </Box>
                        <Box onClick={() => move('RIGHT')} sx={{ ...iconOutlineStyles }}>
                            <ThemeCompatibleIcon
                                iconData={iconDefault}
                                sx={{
                                    ...iconStyle,
                                    transform: 'rotate(90deg)'
                                }}
                            />
                        </Box>
                    </Box>
                )}
                {showVertical == true && (
                    <Box
                        sx={{
                            width: `${size}px`,
                            top: '0',
                            paddingTop: '0.5em',
                            paddingBottom: paddingBottomScroll,
                            position: 'absolute',
                            right: '0',
                            maxHeight: `calc(100% - ${marginForXThumb} + ${marginTopScrollY.replace(
                                '-',
                                ''
                            )})`,
                            height: `calc(100% + ${marginTopScrollY.replace('-', '')})`,
                            background: background ? background : '#eee',
                            display: 'grid',
                            gridTemplateRows: 'auto 1fr auto',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            marginTop: marginTopScrollY
                        }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                backgroundColor: 'transparent',
                                height: '100%',
                                width: `${scrollState.clickHold ? size + 100 + 'px' : '0px'}`,

                                zIndex: `${scrollState.clickHold ? '1000' : '-1'}`
                            }}
                            onTouchMove={(e: React.TouchEvent) => {
                                onThumbMoveY(e);
                            }}
                            onMouseMove={(e: React.MouseEvent) => {
                                onThumbMoveY(e);
                            }}></Box>
                        <Box
                            onClick={() => move('UP')}
                            sx={{
                                ...iconOutlineStyles,
                                top: '0'
                            }}>
                            <ThemeCompatibleIcon
                                iconData={iconDefault}
                                sx={{
                                    ...iconStyle
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: `${size - 2}px`,
                                ...thumbOuterStyle,
                                height: '100%'
                            }}>
                            <Box
                                ref={thumbYWraper}
                                sx={{
                                    position: 'relative',
                                    height: '100%'
                                }}>
                                <Box
                                    ref={thumbY}
                                    sx={{
                                        height: `${thumbYHeight}px`,
                                        width: `${size - 2}px`,
                                        ...thumbStyle,
                                        transform: 'translateX(-50%)',
                                        left: '50%',
                                        top: `${thumbYTop}`
                                    }}></Box>
                            </Box>
                        </Box>
                        <Box
                            onClick={() => move('DOWN')}
                            sx={{
                                ...iconOutlineStyles,
                                marginTop: '0.3em'
                            }}>
                            <ThemeCompatibleIcon
                                iconData={iconDefault}
                                sx={{
                                    ...iconStyle,
                                    transform: 'rotate(180deg)'
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }
);

ScrollPan.displayName = 'ScrollPan';

export default ScrollPan;
