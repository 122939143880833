import { clearSlashCharacter } from 'src/utils/src/shared/clearSlashCharacter';

export function clearTextFromApiForOptionAutocomplete(data: { id: string; text: string }[]) {
    if (Array.isArray(data)) {
        return data.map((item) => {
            if (typeof item.text === 'string') {
                return {
                    ...item,
                    text: clearSlashCharacter(item.text)
                };
            }
            return item;
        });
    }
    return data;
}
