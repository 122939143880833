import React, { FC } from 'react';

import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { useStructureObjectAttribute } from 'src/hooks';
import { HelpProps } from 'src/components/shared/molecules/actionType/help/types';

const Help: FC<HelpProps> = ({ children }) => {
    const screen = useStructureObjectAttribute('code', 'screen');
    const params = new URLSearchParams(`obiekt=${screen}`);

    return (
        <>
            <LegacyCompatibleLink url={'ekran_pomocy_obiektu.php'} queryParams={params}>
                {children}
            </LegacyCompatibleLink>
        </>
    );
};

export default Help;
