import { Component } from 'react';

/**
 * Klasowy komponent ErrorBoundry. Renderuje swoje dzieci,
 * @component
 * @category Components
 * @subcategory Shared
 * @param {object} props - Propsy komponentu
 * @param {Function} props.render - zwraca zastępcze ui
 * @param {ReactComponent[]} props.children - Komponenty dzieci
 * @property {object} state - stan komponentu
 * @property {object|undefinded} state.error - error
 * @returns {ReactComponent}
 */
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_) {
        // Zaktualizuj stan, aby następny render pokazał zastępcze UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Możesz także zalogować błąd do zewnętrznego serwisu raportowania błędów
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Możesz wyrenderować dowolny interfejs zastępczy.
            return this.props.render();
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
