import React from 'react';
import { dispatch } from 'src/store';
import { fetchAndLoadInitialConfigScreenData } from 'src/store/src/configScreen/sharedAcions';
import { setSpinner } from 'src/store/src/general/system/systemSlice';

export const useLoadInitialConfigScreenData = () => {
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setSpinner(true));

            await dispatch(fetchAndLoadInitialConfigScreenData());
            dispatch(setSpinner(false));
        };

        loadData();
    }, []);
};
