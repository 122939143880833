import React, { FC, useCallback } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldPeselObjectProps } from './types';
import { InputValidator } from 'src/components/popupFormExtended/dynamicField/shared/InputValidator';
import { UIFieldPesel } from './UIFieldPesel';
import { dataSetInputKey } from 'src/constants/dataset';

export const FieldPeselObject: FC<FieldPeselObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, onBlur: boolean) => {
        const checkedValue = new InputValidator({
            dynamicFieldT,
            oldValue: state,
            staticActions: ['runOnlyNumbers'],
            newValue: e.target.value
        }).execute();

        setState(checkedValue);
        if (onBlur) {
            dispatch({
                type: 'pesel',
                payload: {
                    code: keyOfFieldInState,
                    value: {
                        ...field,
                        value: checkedValue
                    }
                }
            });
        }
    };

    const onAcceptValue = useCallback(() => {
        setState(field.valueToAccept);
        dispatch({ type: 'accept_value', payload: keyOfFieldInState });
    }, [dispatch, keyOfFieldInState, field.valueToAccept]);

    React.useEffect(() => {
        if (field.value !== state) {
            setState(field.value);
        }
    }, [field.value]);

    return (
        <UIFieldPesel
            value={state}
            onChange={(e) => onChange(e, false)}
            onBlur={(e) => onChange(e, true)}
            onAcceptValue={onAcceptValue}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
