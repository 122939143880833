import { Box, Link, Typography } from '@mui/material';
import React from 'react';

const LoginFooter = () => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto 1fr',
                flexGrow: 1,
                mb: 0.5
            }}>
            <div></div>
            <Box
                sx={{
                    flexGrow: 1,
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    display: 'flex',
                    mt: 1,
                    pr: 1,
                    pl: 1
                }}>
                <Typography variant="caption" color="initial" fontSize="small">
                    Masz pytania? Napisz do nas{' '}
                    <Link href="mailto:sprzedaz@mecenas.it"> sprzedaz@mecenas.it</Link>
                </Typography>
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    justifyContent: 'end',
                    alignItems: 'end',
                    display: 'flex'
                }}>
                <Typography variant="caption" color="initial">
                    Stworzone przez : JMK Computerate Sp. z o.o.
                </Typography>
            </Box>
        </Box>
    );
};

export default LoginFooter;
