export function createAnimationStat(ratio: number) {
    const animation = document.createElementNS('http://www.w3.org/2000/svg', 'animate');
    animation.setAttribute('attributeType', 'XML');
    animation.setAttribute('attributeName', 'height');
    animation.setAttribute('dur', '3s');
    animation.setAttribute('to', String(ratio));
    animation.setAttribute('from', '3');
    animation.setAttribute('fill', 'freeze');
    return animation;
}
