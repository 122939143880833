import { PlaceEvent } from '../../../data/types';
import { GridStructure } from 'src/utils/src/calendar/GridStructureCreator';

export class GridCalcEvent {
    gridStructure: GridStructure;
    event: PlaceEvent;
    timestampday: number;
    constructor(gridStructure: GridStructure, event: PlaceEvent) {
        this.gridStructure = gridStructure;
        this.event = event;
        this.timestampday = 86400000;
    }
    calcStartRowAndOffsetTop() {
        const num =
            (this.event.startTime - this.event.startDayTimestamp) /
            (this.timestampday / this.gridStructure.numberOfRows);
        const startRow = Math.floor(num);
        const offsetTop = Number(num.toFixed(4).slice(-4)) / 100;
        return { startRow, offsetTop };
    }
    calcHeightEvent() {
        const ratePixelOnMillisecond = this.timestampday / (this.gridStructure.numberOfRows * 50);
        return (this.event.endTime - this.event.startTime) / ratePixelOnMillisecond;
    }
    getData() {
        const { startRow, offsetTop } = this.calcStartRowAndOffsetTop();
        return {
            height: this.calcHeightEvent(),
            startRow,
            offsetTop
        };
    }
}
