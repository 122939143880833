import React, { FC } from 'react';
import { useAppDispatch, AppDispatch, useTypedSelector } from 'src/store';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { PopupFormStaticProps } from './types';
import { PopupFormStaticBehavior } from 'src/data/popupFormTypes';

const PopupFormStatic: FC<PopupFormStaticProps> = ({
    children,
    action,
    rowId,
    clickCallback,
    sourceOfTrigger
}) => {
    const dispatch: AppDispatch = useAppDispatch();
    const behaviour = action.behaviour as PopupFormStaticBehavior;

    const handleSetPopupFormStatic = async () => {
        new PopupManager({ dispatch, action }).setPopupDynamic({
            rowsId: ['nowy'],
            title: behaviour.data.title,
            sourceOfTrigger,
            popupFormData: {
                ...behaviour.data
            }
        });

        dispatch(setSpinner(false));
        clickCallback && clickCallback();
    };

    return <div onClick={() => handleSetPopupFormStatic()}>{children}</div>;
};

export default PopupFormStatic;
