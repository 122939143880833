export const optionsVat = [
    { name: '23%', value: '23' },
    { name: '8%', value: '8' },
    { name: '5%', value: '5' },
    { name: '3%', value: '3' },
    { name: '0%', value: '0' },
    { name: 'zw', value: '-1' },
    { name: 'np', value: '-2' },
    { name: 'oo', value: '-3' }
];

export const streetTypes = [
    { name: '', value: 'empty' },
    { name: 'ul.', value: 'ul.' },
    { name: 'Al.', value: 'Al.' },
    { name: 'Pl.', value: 'Pl.' },
    { name: 'Os.', value: 'Os.' },
    { name: 'Rynek', value: 'Rynek' },
    { name: 'Rondo', value: 'Rondo' },
    { name: 'Skwer', value: 'Skwer' }
];

export const fieldRelativeOp = {
    d: '(dni)',
    t: '(tygodnie)',
    m: '(miesiące)',
    r: '(lata)'
};

export const fieldCostsOp = {
    PLN: 'PLN',
    EUR: 'EUR',
    USD: 'USD',
    CHF: 'CHF',
    GBP: 'GBP'
};
