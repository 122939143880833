import React, { FC, useContext, useEffect } from 'react';

import { useTypedSelector, useAppDispatch } from 'src/store';
import { TableStructureManager } from 'src/store/src/listScreen/tableData/TableStructureManager';
import { GlobalPopup, ConfigPropertyArrayItem } from 'src/context/globalPopup/store';
import { useSearchParam } from 'src/hooks';
import { changeColumnConfiguration } from 'src/store/src/listScreen';
import { closePopup } from 'src/store/src/popup';
import { PopupStateNormal } from 'src/store/src/popup/popup/types';
import { usePopupState } from 'src/context/globalPopup/popupContext';

export const usePopupContentConfig = (popup: PopupStateNormal) => {
    const screen = useSearchParam('obiekt');
    const objectDataState = useTypedSelector((state) => state.listScreenTableData);
    // const dispatch = useAppDispatch();
    // const { dispatchGlobalPopupContext, globalPopupState } = useContext(GlobalPopup);

    const { popupStateContext, dispatchPopupStateContext, dispatchPopupStateRedux } = usePopupState(
        popup.key
    );

    const setAll = () => {
        if (!screen) {
            console.error('Brak parametru "obiekt"');
            return;
        }
        const apiConfig = TableStructureManager.generateDataConfigForApi(
            popupStateContext.config.propertyCol
        );
        dispatchPopupStateRedux(
            changeColumnConfiguration(screen, {
                apiConfig: apiConfig,
                storeConfig: popupStateContext.config.propertyColObj,
                isCondensedView: popupStateContext.config.isCondensedView
            })
        );

        dispatchPopupStateRedux(closePopup(popup.key));
    };

    const ubdatePinnedItem = (item: ConfigPropertyArrayItem) => {
        const configPinnedCol = TableStructureManager.ubdatePinnedColItem(
            popupStateContext.config,
            item
        );
        dispatchPopupStateContext({
            type: 'SET_CONFIG',
            payload: {
                config: {
                    ...popupStateContext.config,
                    ...configPinnedCol
                }
            }
        });
    };

    const ubdateVisibleItem = (item: ConfigPropertyArrayItem) => {
        const configPinnedCol = TableStructureManager.ubdateVisilbleColItem(
            popupStateContext.config,
            item
        );
        dispatchPopupStateContext({
            type: 'SET_CONFIG',
            payload: {
                config: {
                    ...popupStateContext.config,
                    ...configPinnedCol
                }
            }
        });
    };

    const updateIsCondensedView = () => {
        dispatchPopupStateContext({
            type: 'TOGGLE_CONFIG_IS_CONDENSED_VIEW',
            payload: null
        });
    };

    useEffect(() => {
        const configPropertyCol = new TableStructureManager({
            columnDataEntries: objectDataState.columnData
        }).setStatePopupPropertyCol();

        dispatchPopupStateContext({
            type: 'SET_CONFIG',
            payload: {
                config: {
                    ...configPropertyCol,
                    isCondensedView: objectDataState.isCondensedView
                }
            }
        });
    }, [dispatchPopupStateContext, objectDataState]);

    return {
        ubdateVisibleItem,
        ubdatePinnedItem,
        updateIsCondensedView,
        setAll,
        popupStateContext
    };
};
