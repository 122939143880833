import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export {
    setInitData,
    setTabData,
    setSublistData,
    resetData,
    setActiveTabDimensions,
    setActiveTabScroll,
    changeRelationsOrder,
    clearObjectData
} from './object/objectSlice';
export { setTransformations } from './tranformation/transformationSlice';
