export function getSafeArrWithIndex<T>(arr: T[]): (T & { index: string })[] {
    const newArr: (T & { index: string })[] = [];
    for (const property in arr) {
        if (typeof arr[property] === 'object') {
            newArr.push({
                ...arr[property],
                index: property
            });
        }
    }
    return newArr;
}
