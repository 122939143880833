import { RelationTreeCardElement } from 'src/store/src/object/object/types';

export function getCardTreeElementsFlatten(
    el: RelationTreeCardElement[]
): RelationTreeCardElement[] {
    var a: RelationTreeCardElement[] = [];
    for (var i = 0; i < el.length; i++) {
        if (el[i].elements) {
            a = a.concat(getCardTreeElementsFlatten(el[i].elements));
        }
        a.push(el[i]);
    }
    return a.map((item) => {
        return {
            ...item,
            elements: []
        };
    });
}
