import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { ApiActionBehavior, SystemAction } from 'src/data/types';
import { useTypedSelector } from 'src/store';
import { Additional } from 'src/store/src/object/object/types';
import {
    TemplateMainManager,
    TemplateManagerActionBehavior
} from 'src/utils/src/shared/TemplateMainManager';

export type UseGetUrlBodyParamsProps = {
    action: SystemAction;
    behaviour: TemplateManagerActionBehavior;
    sourceOfTrigger: SourceOfTrigger;
    additional?: Additional;
};

export function useGetUrlBodyParamsForApiAction({
    action,
    behaviour,
    sourceOfTrigger,
    additional = { sectionId: '', sectionIdx: 0, typeSection: null }
}: UseGetUrlBodyParamsProps) {
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const objectSlice = useTypedSelector((state) => state.object);

    const getUrlBodyParams = () => {
        const { params, body, url } = new TemplateMainManager({
            rowData,
            transformationState,
            actionBehavior: behaviour as ApiActionBehavior,
            action,
            objectSlice,
            sourceOfTrigger,
            additional
        }).getData();
        return { params, body, url };
    };

    return { getUrlBodyParams };
}
