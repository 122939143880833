import React from 'react';
import {
    budgetStableFieldSum,
    BudgetStableFieldSumType
} from 'src/constants/budget/budgetStableFieldSum';
import { useGetBugdetShowTimeApproved } from 'src/hooks/src/budget/useGetBugdetShowTimeApproved';

export const usePrepareSum = ({ type }: { type: BudgetStableFieldSumType }) => {
    const { showTimeApproved } = useGetBugdetShowTimeApproved();
    const data = React.useMemo(() => {
        const sumFields = budgetStableFieldSum[type];

        return showTimeApproved
            ? {
                  isSum: true,
                  sumFields: sumFields.map((field) => {
                      if (field.id === '2') {
                          return {
                              ...field,
                              colspan: 2
                          };
                      }
                      return field;
                  })
              }
            : {
                  isSum: true,
                  sumFields
              };
    }, []);
    return data;
};
