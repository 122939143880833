import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { FieldRelationshipTypeMany } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipTypeMany/types';
import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';

type FieldDependentRelationshipTypeManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldRelationshipTypeMany;
};

export class FieldDependentRelationshipManyTypeManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldRelationshipTypeMany;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentRelationshipTypeManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async zmienWartosciPolZNadrzednegoModTextForm() {
        await this.getDataForSuperior();
        await this.checkIfIsReturnValuesForChild();
    }

    private async getDataForSuperior() {
        const screen = this.getScreen();
        const last =
            this.fieldWasTriggered.value.objects[this.fieldWasTriggered.value.objects.length - 1];
        if (last) {
            const params = {
                q: last.id,
                ekran: screen,
                tabela_opcji: this.fieldWasTriggered.value.select
            };
            const res = await getFieldAdditionalData({
                url: 'json_pobierz_wartosci_pol_z_nadrzenego.php',
                params
            });
            if (res.data) {
                this.valuesFromSuperior = res.data;
                this.isCaptureValuesFromSuperior = true;
            }
        }
    }
}
