import { DynamicFieldT } from 'src/data/popupFormTypes';

export const fieldTypesNotSendedWhenNotModified: DynamicFieldT['typ'][] = [
    'tekst',
    'email',
    'adres',
    'checkbox',
    'data_godzina',
    'data_godziny',
    'data_wzgledna',
    'data',
    'emaile_wielokrotne',
    'haslo_otwarte',
    'haslo',
    'koszty',
    'liczba_rozliczana_do_zera',
    'lista_wielokrotna',
    'lista_wielokrotna_cena',
    'lista_obca_wielokrotna',
    'lista',
    'lista_zalezna',
    'nip',
    'numer_rachunku_bankowego',
    'pesel',
    'plik',
    'pliki_wielokrotne',
    'powiazanie_typ_wielokrotne',
    'powiazanie_typ',
    'tekst_bez_ograniczen',
    'tekst_z_przedrostkiem'
];
