import { SearchField } from 'src/store/src/listScreen/searchFields/types';
import { ListSearchFilters } from 'src/store/src/listScreen/transformations/types';

export type Options = {
    fieldId: number;
    isRelationForeignField: boolean;
    additionalFieldCode?: string | null;
    additionalFieldType?: string | null;
    showArchives?: number;
    additionalFieldValue?: string;
};

export class DynamicAutoCompleteManager {
    static prepareOptions(searchField: SearchField, listSearchFilters: ListSearchFilters) {
        const options: Options = {
            fieldId: searchField.fieldId,
            isRelationForeignField: searchField.isRelationForeignField,
            additionalFieldCode: searchField.additionalFieldForSearchCode ?? null,
            additionalFieldType: searchField.additionalFieldForSearchType ?? null,
            showArchives: listSearchFilters?.['pokaz_archiwizacje']?.fieldFilter?.value ?? null,
            additionalFieldValue:
                (searchField.additionalFieldForSearchCode &&
                    listSearchFilters?.[searchField.additionalFieldForSearchCode]?.fieldFilter
                        ?.value) ??
                null
        };
        if (options.showArchives === null) delete options.showArchives;
        if (options.additionalFieldType === null) delete options.additionalFieldType;
        if (options.additionalFieldCode === null) {
            delete options.additionalFieldCode;
            delete options.additionalFieldValue;
        }
        return options;
    }
}
