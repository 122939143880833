import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const ObjectNotBillView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ObjectView" */ 'src/components/objectNotBillScreen/templates/objectNotBillView/ObjectNotBillView'
        )
);

export const ObjectNotBillMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Obiekty niezafakturowane');
    return (
        <div>
            <React.Suspense fallback>
                <ObjectNotBillView />
            </React.Suspense>
        </div>
    );
};
