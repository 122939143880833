import React, { FC } from 'react';

import { useLoadScreenParamPageTitle, useRunAllHooksOnEachRenderReactPage } from 'src/hooks';
import { useLoadInitialMassEditObjectsScreenData } from 'src/hooks/src/massEditObjectsScreen/useLoadInitialMassEditObjectsScreenData';

const MassEditObjectsView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "MassEditObjectsView" */ 'src/components/massEditObjectsScreen/templates/massObjectsEditView/MassEditObjectsView'
        )
);

export const MassEditObjectsMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage();
    useLoadScreenParamPageTitle();
    useLoadInitialMassEditObjectsScreenData();
    return (
        <div>
            <React.Suspense fallback>
                <MassEditObjectsView />
            </React.Suspense>
        </div>
    );
};
