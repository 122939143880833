import {
    FieldBasicStateRHF,
    FieldCheckboxStateRHF,
    FieldDateStateRHF,
    FieldListStateRHF,
    FieldNumberStateRHF,
    FieldTekstStateRHF,
    FieldTekstWithoutRestrictStateRHF
} from 'src/data/fieldsReactHookForm';
import { BudgetFieldsStateUnionRHF, BudgetGetValuesFn } from 'src/hooks/src/budget/useFormBudget';
import {
    BugdetFieldTypes,
    BugdetFormVisibleFieldStructureAll,
    SettleObjectValues
} from 'src/store/src/budget/budget/types';

// Class working on only onChange

type GetValuesBudgetPropsRHF = {
    getValues: BudgetGetValuesFn;
};

export type GetValuesFnBudgetPropsRHF = {
    keyField: string;
    methodId: string;
};

type GetValuesFnSpecBudgetPropsRHF = {
    fieldType: BugdetFieldTypes;
    returnValueNotText?: boolean;
};

type GetListOptionsBudgetPropsRHF = {
    field: BugdetFormVisibleFieldStructureAll;
    initialValue: string | null;
    values: SettleObjectValues;
};

export class BudgetGetValuesHandlerRHF {
    getValues: BudgetGetValuesFn;
    field: BudgetFieldsStateUnionRHF;
    constructor(data: GetValuesBudgetPropsRHF) {
        this.getValues = data.getValues;
        this.field = {};
    }
    run({ keyField, methodId }: GetValuesFnBudgetPropsRHF) {
        this.field = this.getValues(`main.${methodId}.${keyField}`);
        return this;
    }

    getFieldState() {
        return this.field;
    }

    getBoolean({ fieldType }: GetValuesFnSpecBudgetPropsRHF) {
        let value = false;
        switch (fieldType) {
            case 'checkbox': {
                const oneObj: Required<FieldCheckboxStateRHF> = this
                    .field as Required<FieldCheckboxStateRHF>;
                value = oneObj?.checkbox;
                break;
            }
        }
        return value;
    }

    getString({ fieldType }: GetValuesFnSpecBudgetPropsRHF) {
        let value: string | undefined | null = '';

        switch (fieldType) {
            case 'tekst_bez_ograniczen': {
                const oneObj: Required<FieldTekstWithoutRestrictStateRHF> = this
                    .field as Required<FieldTekstWithoutRestrictStateRHF>;
                value = oneObj?.tekst_bez_ograniczen;
                break;
            }
            case 'tekst': {
                const oneObj: Required<FieldTekstStateRHF> = this
                    .field as Required<FieldTekstStateRHF>;
                value = oneObj?.tekst;
                break;
            }
            case 'liczba': {
                const oneObj: Required<FieldNumberStateRHF> = this
                    .field as Required<FieldNumberStateRHF>;
                value = oneObj?.liczba;
                break;
            }
            case 'basic': {
                const oneObj: Required<FieldBasicStateRHF> = this
                    .field as Required<FieldBasicStateRHF>;
                value = oneObj?.basic;
                break;
            }
            case 'lista': {
                const oneObj: Required<FieldListStateRHF> = this
                    .field as Required<FieldListStateRHF>;
                value = oneObj?.lista?.value;
                break;
            }
            case 'data': {
                const oneObj: Required<FieldDateStateRHF> = this
                    .field as Required<FieldDateStateRHF>;
                if (oneObj?.data) {
                    value = new Date(oneObj?.data).toLocaleDateString().replaceAll('.', '-');
                }
                break;
            }
        }
        return value;
    }

    getListValue({ fieldType, returnValueNotText }: GetValuesFnSpecBudgetPropsRHF) {
        let value: string | null = '';

        switch (fieldType) {
            case 'lista': {
                const oneObj: Required<FieldListStateRHF> = this
                    .field as Required<FieldListStateRHF>;
                value = returnValueNotText
                    ? oneObj?.lista.value
                    : oneObj?.lista.opcje[oneObj?.lista.value ?? ''] ?? null;
                break;
            }
            default:
                console.error('getListValue - wrong field type');
                break;
        }
        return value;
    }

    getListOptions({ field, initialValue, values }: GetListOptionsBudgetPropsRHF) {
        let options: {
            [key: string]: string;
        } = {};

        switch (field.type) {
            case 'lista': {
                const oneObj: Required<FieldListStateRHF> = this
                    .field as Required<FieldListStateRHF>;
                options = oneObj?.lista.opcje;
                if (initialValue != null && !(initialValue in options)) {
                    const optionNameValueKey = `${field.code}_nazwa`;
                    if (optionNameValueKey in values) {
                        options[initialValue] = values[optionNameValueKey];
                    }
                }
                break;
            }
            default:
                console.error(
                    "BudgetGetValuesHandlerRHF.getListOptions(): Try to get options from field that isn't list"
                );
                break;
        }
        return options;
    }

    getDate({ fieldType }: GetValuesFnSpecBudgetPropsRHF) {
        let value: Date | undefined | null;

        switch (fieldType) {
            case 'data': {
                const oneObj: Required<FieldDateStateRHF> = this
                    .field as Required<FieldDateStateRHF>;
                value = oneObj?.data;
                break;
            }
        }
        return value;
    }

    getKeyReset() {
        return this.field?.keyReset;
    }
}
