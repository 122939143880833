import React from 'react';
import { HoursStatisticsBlockData } from 'src/store/src/startupScreen/startupScreen/types';
import { createAnimationStat } from 'src/utils';
import { TimeManager } from 'src/utils/src/shared/TimeManager';

export type UseCalcAndDrawStatInLogoStartupScreen = {
    ratioActualTargetToMaxDescription: number;
    ratioActualToMaxDescription: number;
    minutesMax: number;
    minutesActual: number;
    minutesActualTarget: number;
    hourMax: number;
    hourActual: number;
    hourActualTarget: number;
};

export function useCalcAndDrawStatInLogoStartupScreen(
    data: HoursStatisticsBlockData
): UseCalcAndDrawStatInLogoStartupScreen {
    const {
        ratioActualTargetToMax,
        ratioActualToMax,
        ratioActualTargetToMaxDescription,
        ratioActualToMaxDescription
    } = calcDraw(data);

    const { minutesActual, minutesActualTarget, minutesMax } = getMinutes(data);
    const { hourMax, hourActual, hourActualTarget } = getHours(data);
    React.useEffect(() => {
        const rectOne = document.getElementById('rectone');
        const animationOne = createAnimationStat(ratioActualTargetToMax);
        const rectTwo = document.getElementById('recttwo');
        const animationTwo = createAnimationStat(ratioActualToMax);
        if (rectOne && rectTwo) {
            rectOne.setAttribute('height', String(ratioActualTargetToMax));
            rectOne.appendChild(animationOne);
            rectTwo.setAttribute('height', String(ratioActualToMax));
            rectTwo.appendChild(animationTwo);
        }
    }, []);
    return {
        ratioActualTargetToMaxDescription,
        ratioActualToMaxDescription,
        minutesActual,
        minutesActualTarget,
        minutesMax,
        hourMax,
        hourActual,
        hourActualTarget
    };
}

function calcDraw(data: HoursStatisticsBlockData) {
    const hoursMaxN = Number(data.hoursMax);
    const hoursActualN = Number(data.hoursActual);
    const hoursActualTargetN = Number(data.hoursActualTarget);

    const safeHoursMax = hoursMaxN ? hoursMaxN : 1;

    const ratioActualToMaxDescription = Math.floor((hoursActualN / safeHoursMax) * 1000) / 10;
    const ratioActualTargetToMaxDescription =
        Math.floor((hoursActualTargetN / safeHoursMax) * 1000) / 10;

    const diffActualToMaxByHoursMax =
        (hoursActualN / safeHoursMax) % 1 ? (hoursActualN / safeHoursMax) % 1 : 1;
    const diffActualTargetToMaxByHoursMax =
        (hoursActualTargetN / safeHoursMax) % 1 ? (hoursActualTargetN / safeHoursMax) % 1 : 1;

    let ratioActualToMax = Math.floor(diffActualToMaxByHoursMax * 1000) / 10;
    let ratioActualTargetToMax = Math.floor(diffActualTargetToMaxByHoursMax * 1000) / 10;

    if (hoursActualN === 0) {
        ratioActualToMax = 0;
    }
    if (hoursActualTargetN === 0) {
        ratioActualTargetToMax = 0;
    }
    if (ratioActualToMaxDescription > 100) {
        ratioActualToMax = 100;
    }
    if (ratioActualTargetToMaxDescription > 100) {
        ratioActualTargetToMax = 100;
    }

    return {
        ratioActualTargetToMaxDescription: ratioActualTargetToMaxDescription
            ? ratioActualTargetToMaxDescription
            : 0,
        ratioActualToMaxDescription: ratioActualToMaxDescription ? ratioActualToMaxDescription : 0,
        ratioActualToMax: ratioActualToMax ? ratioActualToMax : 0,
        ratioActualTargetToMax: ratioActualTargetToMax ? ratioActualTargetToMax : 0
    };
}

function getMinutes(data: HoursStatisticsBlockData): {
    minutesMax: number;
    minutesActual: number;
    minutesActualTarget: number;
} {
    const minutesMax = TimeManager.getDecimalNumberAsMinutes(data.hoursMax);
    const minutesActual = TimeManager.getDecimalNumberAsMinutes(data.hoursActual);
    const minutesActualTarget = TimeManager.getDecimalNumberAsMinutes(data.hoursActualTarget);

    return {
        minutesMax,
        minutesActual,
        minutesActualTarget
    };
}

function getHours(data: HoursStatisticsBlockData): {
    hourMax: number;
    hourActual: number;
    hourActualTarget: number;
} {
    const hourMax = TimeManager.getNumberAsHourWithoutMinutes(data.hoursMax);
    const hourActual = TimeManager.getNumberAsHourWithoutMinutes(data.hoursActual);
    const hourActualTarget = TimeManager.getNumberAsHourWithoutMinutes(data.hoursActualTarget);

    return {
        hourMax,
        hourActual,
        hourActualTarget
    };
}
