import React, { FC, useMemo } from 'react';

import { FieldBelowProps } from 'src/components/popupFormExtended/fieldsBelow/types';
import Arrow from 'src/components/object/atoms/arrow/Arrow';
import styles from './FieldsBelow.module.css';
import { Field } from 'src/components/popupFormExtended/field/Field';
import { UiFieldCommonProps } from '../dynamicField/types';

export const FieldsBelow: FC<FieldBelowProps> = (props) => {
    const { dynamicFieldT, keyPopup } = props;
    const [show, setShow] = React.useState(false);

    const uiFieldCommonProps: UiFieldCommonProps = useMemo(
        () => ({
            usedForAddingObject: props.usedForAddingObject
        }),
        [props.usedForAddingObject]
    );

    if (dynamicFieldT.fieldsBelow) {
        return (
            <div className={styles.box}>
                <div className={styles.box2} onClick={() => setShow((prev) => !prev)}>
                    <p className={styles.p}>
                        {show
                            ? dynamicFieldT.tekst_ukryj_chowane
                            : dynamicFieldT.tekst_pokaz_chowane}
                    </p>
                    <div className={styles.boxArrow}>
                        <Arrow
                            isUnFolded={show}
                            style={{
                                left: '0px',
                                top: '0px',
                                padding: '4px'
                            }}
                        />
                    </div>
                </div>
                {show &&
                    dynamicFieldT.fieldsBelow.map((item) => (
                        <Field
                            key={item.id}
                            keyPopup={keyPopup}
                            dynamicFieldT={item}
                            keyOfFieldInState={item.kod}
                            usedForAddingObject={props.usedForAddingObject}
                            uiFieldCommonProps={uiFieldCommonProps}
                        />
                    ))}
            </div>
        );
    }

    return null;
};
