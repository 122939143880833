import React, { FC } from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';

import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import { UiAutocompleteInputTwo } from 'src/components/shared/atoms/uiInputs/uiAutocompleteInputTwo/UiAutocompleteInputTwo';
import { useFieldClasses } from 'src/hooks';

export const DynamicListManySearch2: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const ids = listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        ? listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        : [];
    const defVal: string[] = searchField.searchOptions
        .filter((item) => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
        .map((item) => item.name);
    const classes = useFieldClasses({ muiType: 'autocompleteOne' });

    return (
        <div style={{ position: 'relative' }}>
            <Autocomplete
                multiple
                popupIcon={''}
                defaultValue={defVal}
                options={searchField.searchOptions.map((op) => op.name)}
                onChange={(_, data: string[]) => {
                    const ids: number[] = [];
                    data.forEach((item) => {
                        const found = searchField.searchOptions.find(
                            (option) => option.name === item
                        );
                        if (found) ids.push(found.id);
                    });
                    dispatchFilter({
                        type: 'SET_VALUE_TWO',
                        payload: {
                            code: searchField.searchCode,
                            value: ids
                        }
                    });
                }}
                disableCloseOnSelect={true}
                disablePortal={true}
                clearIcon={
                    <ButtonCross
                        onClick={() => {}}
                        style={{
                            top: '2px'
                        }}
                    />
                }
                classes={{
                    option: classes.option,
                    listbox: classes.listbox,
                    paper: classes.paper,
                    popper: classes.popper
                }}
                renderInput={(params: AutocompleteRenderInputParams) => {
                    return <UiAutocompleteInputTwo params={params} />;
                }}
            />
        </div>
    );
};
