import React, { useCallback, useMemo } from 'react';
import {
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue,
    useForm
} from 'react-hook-form';

import { FieldsStateRHF, FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { doFormBudgetCalc } from 'src/store/src/budget/budget/budgetSlice';

import {
    BudgetFieldKeyParts,
    BudgetGlobalSummary,
    BudgetGlobalSummaryData,
    BudgetGlobalSummaryDataLimit,
    BudgetGlobalSummaryDataObject,
    BugdetCaseLevel,
    BugdetFieldStructure,
    BugdetFormFieldStructure,
    BugdetMonthLevel,
    BugdetObjectStructure,
    BugdetSettlementMethodLevel,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import {
    BudgetGetValuesHandlerRHF,
    GetValuesFnBudgetPropsRHF
} from 'src/utils/src/budget/BudgetGetValuesHandlerRHF';
import {
    BudgetHandlerStateGlobalFnExecuteCallPlacement,
    BudgetHandlerStateGlobal
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import {
    BudgetSetValueHandlerRHF,
    SetValueFnBudgetPropsRHF
} from 'src/utils/src/budget/BudgetSetValueHandlerRHF';
import { BudgetSetterStateGlobal } from 'src/utils/src/budget/BudgetSetterStateGlobal';

/** 
 
Desctiption running:

1. Classes in Budget - in second word in name of class discribe when is runned
Name 'Setter' means that is runned when is mounted
Name 'Handler' means that is runned when is onChange or mounted but mainly when is onChange 


2. Sums
a) Sums is calced from field or other sum
b) Fn isMatchedKeySum  - TypeOfMatchedKeySum is enum with allow choose which field or sum was matched to calc for some sum


3. @param {BudgetOperationalState} - is state for calc which is pass through by reference beetwen classes

**/

export type CalcFnProps = BudgetFieldKeyParts & {
    monthLevelTypeId?: string;
    monthLevelType?: string;
    monthId?: string;
    keyField: string;
    settleObjectCode: SettledObjectsCodes;
    recalcOnChange?: boolean;
    callPlacement?: BudgetHandlerStateGlobalFnExecuteCallPlacement;
    tickAll?: BudgetTickAll;
    field?: BugdetFormFieldStructure;
};

export type CalcFn = ({
    keyField,
    settleObjectCode,
    objectId,
    recalcOnChange
}: CalcFnProps) => void;

export type SelectAllFnProps = {
    cases: BugdetCaseLevel[];
    flag: boolean;
    methodId: string;
};

export type BudgetFormFieldsState = FieldsStateRHF;

export type BudgetFieldsStateUnion = FieldsStateUnionRHF;

export type BudgetTickAll = 'tick' | 'untick';

export type ObjectToCalcLimitItem = {
    objectId: string;
    objectCode: SettledObjectsCodes;
    timestamp: number;
    hours: number;
    stake: number;
};

export type ObjectToCalcLimit = {
    [key: number]: ObjectToCalcLimitItem[];
};

export type TemplateMonthLimit = {
    [methodId: string]: {
        [monthData: number]: string[]; // array with 2 elements, first is hours limit overall key, second is brutto field key
    };
};

export type TemplateMonthLimitStartTimestampWhereIsLimit = {
    [methodId: string]: {
        [monthData: number]: string;
    };
};

export type TemplateKeyMonthIdToUpdateMonthsForCaseLimit = {
    [methodId: string]: string[][];
};

export type BudgetOperationalStateFormFieldsStateKeys = Exclude<
    keyof BudgetOperationalState,
    | 'templateMonthLimit'
    | 'objectsToCalcLimit'
    | 'structure'
    | 'fieldsStructure'
    | 'monthWhereWasChanged'
    | 'method'
    | 'globalSummary'
    | 'globalSummaryData'
    | 'globalSummaryDataLimit'
    | 'globalSummaryDataLimitToCalc'
    | 'globalSummaryDataObject'
    | 'callPlacement'
    | 'tickAll'
    | 'templateKeyMonthIdToUpdateMonthsForCaseLimit'
    | 'dataToCalcAdditionalField'
>;

export type BudgetCallPlacement = 'budgetSetterStateGlobal' | 'budgetHandlerStateGlobal';

export type BudgetOperationalState = {
    callPlacement: BudgetCallPlacement;
    // Props global for all method

    dataToCalcAdditionalField: {
        invoiceAllInCase: {
            [monthId: string]: {
                [caseId: string]: boolean[];
            };
        };
    };

    templateMonthLimit: TemplateMonthLimit; // template for calc limit when limit is not in all month
    templateMonthLimitStartTimestampWhereIsLimit: TemplateMonthLimitStartTimestampWhereIsLimit;
    templateKeyMonthIdToUpdateMonthsForCaseLimit: TemplateKeyMonthIdToUpdateMonthsForCaseLimit;
    globalSummaryData: BudgetGlobalSummaryData; // data for culc globalSummary, cumulated and recalculated on change and save in redux
    globalSummary: BudgetGlobalSummary; // result of calc globalSummaryData display for user

    // Props for one method, reset before run odther method

    fieldsTempFromRHF: BudgetFormFieldsState;
    // fieldsTempFromRHF - is state from RHF which is used only onChange (onMount is always empty)
    fieldsTemp: BudgetFormFieldsState;
    // fieldsTemp - is main operational state with all fields
    sumTemp: BudgetFormFieldsState;
    // sumTemp - is state with all sums
    limitTemp: BudgetFormFieldsState;
    limitTempToCalc: BudgetFormFieldsState;
    objectsToCalcLimit: ObjectToCalcLimit;
    structure?: BugdetObjectStructure;
    fieldsStructure: BugdetFieldStructure[];
    monthWhereWasChanged?: BugdetMonthLevel;
    method?: BugdetSettlementMethodLevel;
    globalSummaryDataLimit: BudgetGlobalSummaryDataLimit; // limit item to save globalSummaryData for redux
    globalSummaryDataLimitToCalc: BudgetGlobalSummaryDataLimit; // limit item to calc globalSummary for one method
    globalSummaryDataObject: BudgetGlobalSummaryDataObject; // object item to calc globalSummary for one method
};

export type BudgetFormState = {
    main: { [medhodId: string]: FieldsStateRHF };
    globalSummaryData: BudgetGlobalSummaryData;
    globalSummary: BudgetGlobalSummary;
};

export type BudgetSetValueFn = UseFormSetValue<BudgetFormState>;

export type BudgetGetValuesFn = UseFormGetValues<BudgetFormState>;

export type BudgetResetFn = UseFormReset<BudgetFormState>;

export type BudgetRegisterFn = UseFormRegister<BudgetFormState>;

export type BudgetFormStateRHF = BudgetFormState;

export type BudgetFieldsStateUnionRHF = FieldsStateUnionRHF;

export type BudgetStateSummaryRHF = {
    globalSummary: BudgetGlobalSummary;
};

export type UseFormBudgetReturnProps = {
    register: BudgetRegisterFn;
    handleSubmit: UseFormHandleSubmit<BudgetFormState>;
    calc: CalcFn;
    getValuesBudgetRHF: (data: GetValuesFnBudgetPropsRHF) => BudgetGetValuesHandlerRHF;
    setValueBudgetRHF: (data: SetValueFnBudgetPropsRHF) => BudgetSetValueHandlerRHF;
    getStateBudgetRHF: (data: GetValuesFnBudgetPropsRHF) => BudgetFieldsStateUnionRHF;
    getStateSummaryRHF: () => BudgetStateSummaryRHF;
};

export const useFormBudget = (): UseFormBudgetReturnProps => {
    const budget = useTypedSelector((state) => state.budget);
    const dispatch = useAppDispatch();

    // calc defaultValues for rhf form only one, because are  they cached anyway, and when budget
    // state changed there is any reason to recalculate this default values, form won't use them
    // anyway
    const formDefaultValues = useMemo(
        () => new BudgetSetterStateGlobal({ budget, dispatch }).execute(),
        []
    );

    const { register, setValue, handleSubmit, getValues, reset } = useForm<BudgetFormState>({
        defaultValues: formDefaultValues
    });

    // changed to call proper thunk, not to call directly with budget state, because this cause
    // all components needing to rerender calc be rerendered when any change in budget was made
    const calc = useCallback(
        (data: CalcFnProps) => dispatch(doFormBudgetCalc({ data, getValues, reset, setValue })),
        [getValues, reset, setValue, dispatch]
    );

    const getValuesBudgetRHF = useCallback(
        (data: GetValuesFnBudgetPropsRHF) => new BudgetGetValuesHandlerRHF({ getValues }).run(data),
        [getValues]
    );

    const setValueBudgetRHF = useCallback(
        (data: SetValueFnBudgetPropsRHF) =>
            new BudgetSetValueHandlerRHF({ getValues, setValue }).run(data),
        [getValues, setValue]
    );

    const getStateBudgetRHF = useCallback(
        (data: GetValuesFnBudgetPropsRHF) => getValues(`main.${data.methodId}.${data.keyField}`),
        [getValues]
    );

    const getStateSummaryRHF = useCallback(
        () => ({
            globalSummary: getValues('globalSummary')
        }),
        [getValues]
    );

    React.useEffect(() => {
        new BudgetHandlerStateGlobal({
            getValues,
            reset,
            budget,
            setValue,
            dispatch
        }).executeFromChangedBudget();
    }, [budget.budgetHandlerStateGlobalKind]);

    return {
        getStateBudgetRHF,
        setValueBudgetRHF,
        getValuesBudgetRHF,
        getStateSummaryRHF,
        handleSubmit,
        calc,
        register
    };
};
