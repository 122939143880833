import React, { FC, useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
    sxInputV1Default,
    sxSelectV1Default
} from 'src/components/shared/atoms/uiInputs/sxDefault';
import { UiInputTwoSelectProps } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/types';
import { isObject } from 'src/utils';
import { useFieldClasses } from 'src/hooks';
import { SelectIcon } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/SelectIcon';
import { useStyleInlineOne } from 'src/hooks/src/shared/useStyleInlineOne';

const UiInputSelectTwoComp: FC<UiInputTwoSelectProps> = (props) => {
    const ref = React.useRef(false);
    const { sx } = props;
    const [open, setOpen] = React.useState(false);
    const inlineStyle = useStyleInlineOne({ ...props });

    const options = useMemo(() => {
        const options = props.options ? props.options : [];
        if (!options.length && props.opcje) {
            const ops = props.opcje ? props.opcje : {};
            if (isObject(ops)) {
                for (const property in ops) {
                    options.push({ name: ops[property], value: property });
                }
            }
        }
        return options;
    }, [props.opcje, props.options]);

    const classes = useFieldClasses({ muiType: 'selectOne' });

    return (
        <Select
            {...props}
            onChange={(e) => props.onChange(e, { isEmptyValue: ref.current })}
            onClick={() => {
                setOpen(!open);
            }}
            open={open}
            sx={{
                ...sxInputV1Default,
                ...inlineStyle,
                ...props.sxinput,
                ...sxSelectV1Default,
                ...sx
            }}
            inputProps={props.inputProps}
            MenuProps={{ classes: { paper: classes.paper } }}
            IconComponent={
                props.icon
                    ? props.icon
                    : (propsWew) => (
                          <SelectIcon
                              {...propsWew}
                              disabled={props.disabled}
                              archived={props.archived}
                          />
                      )
            }>
            {options?.map((option: { name: string; value: string | number }) => {
                if (option?.value === '') {
                    return (
                        <MenuItem
                            key={option?.value?.toString()}
                            value={option?.name}
                            onClick={() => (ref.current = true)}>
                            {option?.name}
                        </MenuItem>
                    );
                }
                return (
                    <MenuItem
                        key={option?.value?.toString()}
                        value={option?.value}
                        onClick={() => {
                            setOpen(!open);
                            return (ref.current = false);
                        }}>
                        {option?.name}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export const UiInputSelectTwo = React.memo(UiInputSelectTwoComp);
