import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { UiLabelProps } from './types';

export const UiLabel: FC<UiLabelProps> = (props) => {
    const theme = useTheme();

    return (
        <p
            style={{
                color: `${theme.palette.text.label}`,
                ...props.styles
            }}>
            {props.label}
        </p>
    );
};
