import { getState } from 'src/store';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';

// checks if path should be to alternative view or default
export const getAternativeViewUrlData = (componentPath: string, queryParams?: URLSearchParams) => {
    const menuData = getState().menu.menuStructureData.modules;
    const screenObj = MenuStateManager.getActualScreen({
        menuData,
        screen: queryParams?.get('obiekt') ?? null
    });

    if (
        componentPath === '/lista' &&
        screenObj &&
        'alternativeView' in screenObj &&
        screenObj.alternativeView
    ) {
        return { queryParams, url: `/lista-${screenObj.alternativeView}` };
    }

    if (componentPath === '/obiekt' && !queryParams?.get('id')) {
        return { queryParams, url: '/dodaj-obiekt' };
    }

    return { queryParams, url: componentPath };
};
