import React, { FC } from 'react';
import { useInterval } from 'src/hooks';
import { dispatch } from 'src/store';
import { fetchAndUpdateMenuData } from 'src/store/src/menu/menu/menuSlice';
import { fetchAndUpdateMessagesCount } from 'src/store/src/menu/menu/menuSlice';
import MainMenuView from 'src/components/menu/templates/mainMenuView/MainMenuView';

/**
 * Describes main menu, handle all mechanics related to menu data
 */
const MainMenu: FC = ({ children }) => {
    // start here timeout fetching and updating menu data in store
    useInterval(
        () => {
            dispatch(fetchAndUpdateMenuData());
        },
        60 * 10,
        true
    );
    useInterval(
        () => {
            dispatch(fetchAndUpdateMessagesCount());
        },
        60 * 3,
        true
    );

    return <MainMenuView>{children}</MainMenuView>;
};

export default MainMenu;
