import React, { FC } from 'react';
import { Popover } from '@mui/material';

import { UtilitiesBarOptionsInThreeDotsProps } from './types';
import styles from './UtilitiesBarOptionsInThreeDots.module.css';
import { ThreeDots } from 'src/components/shared/atoms/threeDots/ThreeDots';
import UtilitiesBarOption from 'src/components/listScreen/molecules/utilityBarOption/UtilityBarOption';

export const UtilitiesBarOptionsInThreeDots: FC<UtilitiesBarOptionsInThreeDotsProps> = ({
    actions,
    pageType,
    deleteSearchOptionCallback
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (_e: React.BaseSyntheticEvent) => {
        setAnchorEl(_e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={styles.wrapper}>
            <div aria-describedby={id} onClick={handleClick}>
                <ThreeDots />
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}>
                <div className={styles.container}>
                    {actions.map((item) => {
                        return (
                            <UtilitiesBarOption
                                key={item.code != '' ? item.code : item.name}
                                action={item}
                                deleteSearchOptionCallback={deleteSearchOptionCallback}
                                pageType={pageType}
                                typeDisplay="horizontal"
                            />
                        );
                    })}
                </div>
            </Popover>
        </div>
    );
};
