import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProgressBarState } from './types';

export const initialState: ProgressBarState = {
    progress: '0%',
    isShowed: false
};

const progressBarSlice = createSlice({
    name: 'progressBar',
    initialState,
    reducers: {
        setProgress: (state, action: PayloadAction<ProgressBarState>) => {
            state.progress = action.payload.progress;
            state.isShowed = action.payload.isShowed;
        }
    }
});

export const { setProgress } = progressBarSlice.actions;

export default progressBarSlice.reducer;
