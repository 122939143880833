import React from 'react';
import { useTypedSelector } from 'src/store/index';
import GlobalPopup from 'src/components/shared/organisms/globalPopup/GlobalPopup';

const WraperGlobalPopup = () => {
    const popup = useTypedSelector((state) => state.popup);
    const popupItems = Object.entries(popup.popupItemState);
    return (
        <>
            {popupItems.map(([key, state]) => (
                <GlobalPopup popup={state} key={key} />
            ))}
        </>
    );
};

export default WraperGlobalPopup;
