export const numberRowsPerPage = [10, 15, 30, 50, 100];
export const CELL_BODY_ACTION_ICON_SIZE = '1.25rem';
export const SORTING_PARAM_NAME = 'porzadek';

export const DYNAMIC_DATE_SEARCH_DATE_PRESETS_OPTIONS = [
    { id: 0, text: 'Konkretna data' },
    { id: 1, text: 'Ostatnie 7 dni' },
    { id: 2, text: 'Bieżący tydzień' },
    { id: 3, text: 'Ostatni tydzień' },
    { id: 4, text: 'Bieżący miesiąc' },
    { id: 5, text: 'Ostatni miesiąc' },
    { id: 6, text: 'Bieżący kwartał' },
    { id: 7, text: 'Ostatni kwartał' },
    { id: 8, text: 'Bieżący rok' },
    { id: 9, text: 'Ostatni rok' }
];
