import { NavigateFunction } from 'react-router-dom';
import { NAV_DO_NOT_CLEAR_BACK_LEVEL, NAV_BACK_LEVEL } from 'src/constants';
import { makeEditSafeNavigation } from 'src/utils';

export const legacyCompatibleNavigateForward = (navigate: NavigateFunction) => {
    // lock going back before first mecenas entry in history
    const backLevel = parseInt(sessionStorage.getItem(NAV_BACK_LEVEL) ?? '');
    if (!backLevel || backLevel <= 0) {
        console.info('Cannot go forward, no further browser history entry');
        return;
    }

    makeEditSafeNavigation(() => {
        sessionStorage.setItem(NAV_DO_NOT_CLEAR_BACK_LEVEL, '1');
        // leave information in sessionStorage to display correctly forward button
        sessionStorage.setItem(NAV_BACK_LEVEL, (backLevel - 1).toString());

        navigate(1);
    });
};
