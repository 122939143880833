import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiDataImportPayoff } from 'src/api/src/importPayoff/types';
import { ImportPayoffState } from 'src/store/src/importPayoff/importPayoff/types';

export const initialState: ImportPayoffState = {
    banks: [],
    bank: '14',
    issuers: [],
    issuer: '',
    fileId: null
};

const importPayoffSlice = createSlice({
    name: 'importPayoff',
    initialState,
    reducers: {
        setInitData: (state: ImportPayoffState, action: PayloadAction<ApiDataImportPayoff>) => {
            state.banks = action.payload.banks;
            state.issuers = action.payload.issuers;
        },
        setBank: (state: ImportPayoffState, action: PayloadAction<string>) => {
            state.bank = action.payload;
        },
        setFileId: (state: ImportPayoffState, action: PayloadAction<string | null>) => {
            state.fileId = action.payload;
        },
        setIssuer: (state: ImportPayoffState, action: PayloadAction<string>) => {
            state.issuer = action.payload;
        },
        clearState: (state: ImportPayoffState, action: PayloadAction<null>) => {
            state.banks = [];
            state.issuers = [];
            state.fileId = null;
            state.bank = '14';
        }
    }
});

export const { setBank, setFileId, clearState, setInitData, setIssuer } = importPayoffSlice.actions;

export default importPayoffSlice.reducer;
