import React from 'react';
import { getUserTimeLogout } from 'src/api/src/shared/getUserTimeLogout';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSearchParam } from 'src/hooks';
import { ubdateTimeSession } from 'src/api/src/shared/ubdateTimeSession';
import { Typography } from '@material-ui/core';
import { useLogout } from 'src/hooks';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { globalPopup } from 'src/constants/subtitles';
import { ApiError, consoleConditionally } from 'src/utils';

export const CheckTimeTimer = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const obiekt = useSearchParam('obiekt');
    const { timeToLogout } = useTypedSelector((state) => state.user);
    const refTimer = React.useRef<HTMLDivElement>(null);
    const refPopup = React.useRef<HTMLSpanElement>(null);
    const refCheckLogoutTimeTimeout = React.useRef<NodeJS.Timeout | null>(null);
    const refUpdateTimerInterval = React.useRef<NodeJS.Timer | null>(null);
    const logout = useLogout();

    const doLogoutWithAlert = React.useCallback(async () => {
        if (refUpdateTimerInterval.current) clearInterval(refUpdateTimerInterval.current);
        if (refCheckLogoutTimeTimeout.current) clearTimeout(refCheckLogoutTimeTimeout.current);
        refPopup.current!.style.visibility = 'hidden';

        await logout();
        ApiError.handle({
            dispatch,
            err: { message: 'Wylogowano z powodu zbyt długiej nieaktywności' }
        });
    }, [logout]);

    const startUpdatingTimer = React.useCallback(
        (time: number) => {
            refTimer.current!.innerText = String(time);

            if (refUpdateTimerInterval.current) clearInterval(refUpdateTimerInterval.current);
            refUpdateTimerInterval.current = setInterval(async () => {
                const actualTimer = Number(refTimer.current!.innerText);
                if (actualTimer === 1) {
                    refPopup.current!.style.visibility = 'hidden';
                    doLogoutWithAlert();
                    return;
                }
                if (actualTimer) {
                    refTimer.current!.innerText = String(actualTimer - 1);
                } else {
                    if (refUpdateTimerInterval.current)
                        clearInterval(refUpdateTimerInterval.current);
                }
            }, 1000);
        },
        [doLogoutWithAlert]
    );

    const checkLogoutTime = React.useCallback(
        async (initTime?: number) => {
            let time = initTime;
            if (!initTime) {
                const res = await getUserTimeLogout();
                if (res.data === null || res.data === 'null') {
                    time = 0;
                }
                time = res.data;
            }

            consoleConditionally(time, 'CheckTimeTimer: checkLogoutTime');

            if (!time || Number(time) < 0) {
                doLogoutWithAlert();
                return;
            }

            let timeSettimeout = 0;

            if (time < 61) {
                refPopup.current!.style.visibility = 'visible';
                startUpdatingTimer(time);

                // start timeout slighty before logout time (or after 10 seconds) to check if timer realy should logout
                // (without this user can refresh session 5 sec before logout time and despite this other tab timer can call logout)
                timeSettimeout = (time - 1) * 1000;
                // timeSettimeout = Math.min((time - 3) * 1000, 10000);
            } else {
                refPopup.current!.style.visibility = 'hidden';
                if (refUpdateTimerInterval.current) clearInterval(refUpdateTimerInterval.current);
                // start timeout checking if and maybe showing alert with logout time
                timeSettimeout = (time - 60) * 1000;
            }
            if (timeSettimeout > 0) {
                if (refCheckLogoutTimeTimeout.current)
                    clearTimeout(refCheckLogoutTimeTimeout.current);
                refCheckLogoutTimeTimeout.current = setTimeout(function () {
                    checkLogoutTime();
                }, timeSettimeout);
            }
        },
        [doLogoutWithAlert, startUpdatingTimer]
    );

    React.useEffect(() => {
        if (refCheckLogoutTimeTimeout.current) clearTimeout(refCheckLogoutTimeTimeout.current);
        if (timeToLogout) {
            const logoutTimeInSeconds = Number(timeToLogout) * 60;
            const checkLogoutTimeTimout = logoutTimeInSeconds - 60 * 1000;
            refCheckLogoutTimeTimeout.current = setTimeout(function () {
                checkLogoutTime(logoutTimeInSeconds);
            }, checkLogoutTimeTimout);
        }
    }, [timeToLogout, obiekt, checkLogoutTime]);

    const handleUpdateTimeSession = async () => {
        const res = await ubdateTimeSession();
        refPopup.current!.style.visibility = 'hidden';
        checkLogoutTime(res.data);
    };

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    width: '100%',
                    height: '100vh',
                    top: 0,
                    left: 0,
                    background: 'rgba(255,255,255, 0.5)',
                    zIndex: 100000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    visibility: 'hidden'
                }}
                ref={refPopup}>
                <Box
                    sx={{
                        border: `1px solid ${theme.palette.primary.main}`,
                        background: theme.palette.background.default,
                        borderRadius: ' 2px',
                        padding: '10px',
                        display: 'grid',
                        gridTemplateRows: 'auto auto auto',
                        gap: '15px',
                        justifyItems: 'center'
                    }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography>{globalPopup.checkTime.content}</Typography>
                        <Typography ref={refTimer}>60</Typography>
                    </Box>

                    <Typography> {globalPopup.checkTime.content2}</Typography>
                    <Button
                        sx={{
                            border: '1px solid black'
                        }}
                        onClick={handleUpdateTimeSession}>
                        {globalPopup.checkTime.button}
                    </Button>
                </Box>
            </Box>
        </>
    );
};
