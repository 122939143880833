import { GusDataFromNip } from 'src/components/popupFormExtended/dynamicField/fieldNip/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

/**
 * Get data from GUS by given NIP code
 */
export async function getGusDataByNip(
    nip: string,
    fieldId: string,
    gusSession?: string
): Promise<BaseApiResponse<GusDataFromNip>> {
    const params = new URLSearchParams([
        ['nip', nip],
        ['fieldId', fieldId]
    ]);
    if (gusSession) params.append('gusSession', gusSession);
    return simpleQueryAPIData(
        `api_react/src/popupFormExtended/getGusDataByNip.php?${params.toString()}`,
        'GET'
    );
}
