import React, { FC } from 'react';

import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const ImportPayoffView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ImportPayoff" */ 'src/components/importPayoff/templates/importPayoffView/ImportPayoffView'
        )
);

export const ImportPayoffMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Import wpłat');

    return (
        <div>
            <React.Suspense fallback>
                <ImportPayoffView />
            </React.Suspense>
        </div>
    );
};
