import React from 'react';
import { Link, Grid, Typography, Tooltip } from '@mui/material';
import { LoginForm } from '../../molecules/loginForm/LoginForm';
import LoginMiddleSectionWrapper from '../../molecules/loginMiddleSectionWrapper/LoginMiddleSectionWrapper';

const LoginMiddleSection = () => {
    return (
        <LoginMiddleSectionWrapper>
            <Typography component="h1" fontSize="large">
                Zaloguj się
            </Typography>

            <LoginForm />

            <Grid container>
                <Grid item xs>
                    <Tooltip
                        title="Skontaktuj się z administratorem systemu z prośbą o zresetowanie hasła"
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Link href="#" variant="body2" underline="none">
                            Zapomniałeś hasła?
                        </Link>
                    </Tooltip>
                </Grid>
            </Grid>
        </LoginMiddleSectionWrapper>
    );
};

export default LoginMiddleSection;
