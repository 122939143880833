import React, { FC, useMemo } from 'react';
import InputB from '@mui/material/InputBase';

import { sxInputV1Default } from 'src/components/shared/atoms/uiInputs/sxDefault';
import { UiInputNormalTwoProps } from 'src/components/shared/atoms/uiInputs/types';
import { useStyleInlineOne } from 'src/hooks/src/shared/useStyleInlineOne';

export const UiInputNormalTwo: FC<UiInputNormalTwoProps> = (props) => {
    const {
        type = 'text',
        disabled,
        disabledWew,
        value,
        maxlength,
        alignTextRight,
        inputProps
    } = props;
    const inlineStyle = useStyleInlineOne({ ...props });

    const inputPropsForInputB = useMemo(
        () => ({
            ...inputProps,
            maxlength,
            style: (alignTextRight
                ? {
                      textAlign: 'right',
                      padding: '4px 4px 5px'
                  }
                : {
                      textAlign: 'left',
                      padding: '4px 0 5px'
                  }) as React.CSSProperties
        }),
        [inputProps, alignTextRight, maxlength]
    );

    return (
        <InputB
            sx={{
                ...inlineStyle,
                ...sxInputV1Default,
                ...props.sxinput
            }}
            inputProps={inputPropsForInputB}
            value={value}
            disabled={disabled ?? disabledWew}
            type={type}
            onChange={props.onChange}
            onBlur={props.onBlur}
            endAdornment={props.endAdornment}
            name={props.name}
            autoComplete="on"
        />
    );
};
