import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ApiScreenColumnsConfiguration,
    ApiScreenColumnsOrder,
    ApiSums
} from 'src/api/src/listScreen/types';
import { StoreNewOrder } from 'src/store/src/listScreen/tableData/TableStructureManager';
import { ColumnDataEntries, ObjectsDataState, RowData } from './types';
import { AppThunk } from 'src/store';
import { updateListScreenConfiguration } from 'src/api/src/listScreen/updateListScreenConfiguration';
import { makeErrorText } from 'src/utils';
import { updateScreenColumnsOrder } from 'src/api/src/listScreen/updateScreenColumnsOrder';
import { ConfigPropertyObj } from 'src/context/globalPopup/popupContext';

export const initialState: ObjectsDataState = {
    totalIds: [],
    totalIdsIsActual: true,
    isFooterActionsExist: true,
    isCondensedView: false,
    targetScreenCode: null,
    columnData: {},
    rowData: []
};

const tableDataState = createSlice({
    name: 'listScreenTableData',
    initialState,
    reducers: {
        setRowsData: (state: ObjectsDataState, action: PayloadAction<RowData[]>) => {
            state.rowData = action.payload;
        },
        setColumnsData: (state: ObjectsDataState, action: PayloadAction<ColumnDataEntries>) => {
            state.columnData = action.payload;
        },
        setFooterActionsExist: (state: ObjectsDataState, action: PayloadAction<boolean>) => {
            state.isFooterActionsExist = action.payload;
        },
        setTargetScreenCode: (state: ObjectsDataState, action: PayloadAction<string | null>) => {
            state.targetScreenCode = action.payload;
        },
        setTotalIdsIsActual: (state: ObjectsDataState, action: PayloadAction<boolean>) => {
            state.totalIdsIsActual = action.payload;
        },
        setTotalIds: (state: ObjectsDataState, action: PayloadAction<string[]>) => {
            state.totalIds = action.payload;
        },
        setColumnsDatasums: (state: ObjectsDataState, action: PayloadAction<ApiSums>) => {
            for (const [columnCode, sums] of Object.entries(action.payload)) {
                if (columnCode in state.columnData) {
                    state.columnData[columnCode].sums = sums;
                } else {
                    console.error(
                        `setColumnsDatasums: sum code ${columnCode} does not exists in state.columnData`
                    );
                }
            }
        },
        setColumnsConfiguration: (
            state: ObjectsDataState,
            action: PayloadAction<{
                [key: string]: {
                    isPinned: boolean;
                    isVisible: boolean;
                };
            }>
        ) => {
            for (const columnCode in action.payload) {
                if (state.columnData[columnCode]) {
                    state.columnData[columnCode].isVisible = action.payload[columnCode].isVisible;
                    state.columnData[columnCode].isPinned = action.payload[columnCode].isPinned;
                }
            }
        },

        setColumnOrder: (state: ObjectsDataState, action: PayloadAction<StoreNewOrder>) => {
            for (const columnCode in action.payload) {
                if (state.columnData[columnCode]) {
                    state.columnData[columnCode].order = action.payload[columnCode];
                }
            }
        },
        clearListData: (state: ObjectsDataState) => {
            state.totalIds = [];
            state.totalIdsIsActual = true;
            state.isFooterActionsExist = true;
            state.targetScreenCode = null;
            state.columnData = {};
            state.rowData = [];
        },
        setIsCondensedView: (state: ObjectsDataState, action: PayloadAction<boolean>) => {
            state.isCondensedView = action.payload;
        }
    }
});

/**
 * Api compatible way to save column visibility and pins changes
 * @param newConfig
 */
export const changeColumnConfiguration =
    (
        screen: string,
        newConfig: {
            apiConfig: ApiScreenColumnsConfiguration;
            storeConfig: ConfigPropertyObj;
            isCondensedView: boolean;
        }
    ): AppThunk =>
    async (dispatch, getState) => {
        // get start column data, for later restore
        const startColumnData = { ...getState().listScreenTableData.columnData };

        // save in store
        dispatch(setColumnsConfiguration(newConfig.storeConfig));
        dispatch(setIsCondensedView(newConfig.isCondensedView));
        // save in api
        const apiResponse = await updateListScreenConfiguration(
            screen,
            newConfig.apiConfig,
            newConfig.isCondensedView
        );
        if (!apiResponse.success) {
            // restore previous state
            dispatch(setColumnsData(startColumnData));
            dispatch(setIsCondensedView(false));
            throw new Error(makeErrorText(apiResponse));
        }
    };

/**
 * Api compatible way to change column order
 * @param newOrder
 */

export const changeColumnOrder =
    (
        screen: string,
        newOrder: {
            apiNewOrder: ApiScreenColumnsOrder;
            storeNewOrder: StoreNewOrder;
        }
    ): AppThunk =>
    async (dispatch, getState) => {
        // get start column data, for later restore
        const startColumnData = { ...getState().listScreenTableData.columnData };

        // save in store
        dispatch(setColumnOrder(newOrder.storeNewOrder));

        // save in api
        const apiResponse = await updateScreenColumnsOrder(screen, newOrder.apiNewOrder);
        if (!apiResponse.success) {
            // restore previous state
            dispatch(setColumnsData(startColumnData));

            throw new Error(makeErrorText(apiResponse));
        }
    };

export const {
    setRowsData,
    setColumnsData,
    setColumnsDatasums,
    setFooterActionsExist,
    setTargetScreenCode,
    setColumnsConfiguration,
    setColumnOrder,
    setTotalIds,
    setTotalIdsIsActual,
    clearListData,
    setIsCondensedView
} = tableDataState.actions;

export default tableDataState.reducer;
