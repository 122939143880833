import { FieldDateStateRHF, FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';

export function getValueDateRHF(fieldState: FieldsStateUnionRHF) {
    let value: Date | null = null;
    if (!fieldState) return value;
    switch (true) {
        case 'data' in fieldState: {
            const oneObj: Required<FieldDateStateRHF> = fieldState as Required<FieldDateStateRHF>;
            return oneObj?.data;
        }
    }
    return value;
}
