import React, { FC } from 'react';

import { UiConfigOrderListArrowWrapperProps } from './types';
import styles from './styles.module.css';

export const UiConfigOrderListArrowWrapper: FC<UiConfigOrderListArrowWrapperProps> = ({
    children,
    style
}) => {
    return (
        <div className={styles.wrapper} style={style}>
            {children}
        </div>
    );
};
