import React from 'react';
import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';
import { dispatch, useTypedSelector } from 'src/store';
import { setIsLoading } from 'src/store/src/budget';
import { fetchAndLoadInitialBudgetScreenData } from 'src/store/src/budget/sharedAcions';

export const useLoadInitBudgetData = () => {
    const isMounting = useTypedSelector((state) => state.budget.isMounting);
    const isLoadedBeforeYears = useTypedSelector((state) => state.budget.isLoadedBeforeYears);
    const obiekt = useSearchParam('obiekt') ?? 'kontrakty';
    const id = useSearchParam('id');
    React.useEffect(() => {
        if (isMounting) {
            if (obiekt && id) {
                const loadData = async () => {
                    await dispatch(
                        fetchAndLoadInitialBudgetScreenData(obiekt, id, isLoadedBeforeYears)
                    );
                    dispatch(setIsLoading(false));
                };

                loadData();
            } else {
                console.error("Couldn't load initial data for budget screen, lack of id param");
            }
        }
    }, [isMounting]);
};
