import React, { FC } from 'react';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { globalPopup } from 'src/constants/subtitles';
import styles from './PopupContentDefault.module.css';

const PopupContentDefault: FC = () => {
    return (
        <PopupTemplateThird title="Komunikat">
            <div className={styles.wrapper}>
                <p>{globalPopup.contentDefault.title}</p>
            </div>
        </PopupTemplateThird>
    );
};

export default PopupContentDefault;
