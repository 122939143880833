export class NumberManager {
    static countDecimals = function (value: number | string) {
        let safeNumber = Number(value);
        if (typeof value === 'string') {
            safeNumber = Number(value.replaceAll(',', '.'));
        }
        if (isNaN(Number(safeNumber))) return 0;
        if (Math.floor(safeNumber) !== safeNumber)
            return safeNumber.toString().split('.')[1].length || 0;
        return 0;
    };

    static countDecimalsFromString = function (valueString: string) {
        const value = Number(valueString.replaceAll(',', '.'));
        if (Math.floor(value) !== value) return value.toString().split('.')[1]?.length || 0;
        return 0;
    };

    static calcArithmeticValue(
        val1: number | string,
        val2: number | string,
        sign?: string
    ): number {
        const num1 = Number(val1);
        const num2 = Number(val2);

        if (isNaN(num1) || isNaN(num2)) return 0;

        switch (sign) {
            case '+':
                return num1 + num2;
            case '-':
                return num1 - num2;
            case '*':
                return num1 * num2;
            case '/':
                if (num2) {
                    return num1 / num2;
                }
                return num1;
            default:
                return num1;
        }
    }
    static truncate(number: number, digitAfterDot: number) {
        let multiplierStr = '1';
        for (let index = 0; index < digitAfterDot; index++) {
            multiplierStr = multiplierStr + '0';
        }
        const multiplier = Number(multiplierStr);
        return Math.floor(number * multiplier) / multiplier;
    }
    static round(number: number, digitAfterDot: number) {
        let multiplierStr = '1';
        for (let index = 0; index < digitAfterDot; index++) {
            multiplierStr = multiplierStr + '0';
        }
        const multiplier = Number(multiplierStr);
        return Math.round(number * multiplier) / multiplier;
    }
    static getSafeNumberFromString(number: string | number) {
        let numberToReturn = typeof number === 'string' ? number : String(number);
        numberToReturn = numberToReturn.replaceAll(',', '.');
        numberToReturn = numberToReturn.replaceAll(' ', '');
        return Number(numberToReturn);
    }
    static getSafeNumberAsStringFromString(number: string | number) {
        return String(NumberManager.getSafeNumberFromString(number));
    }

    static isNumber(number: unknown) {
        let numberProcess = number;

        if (
            numberProcess === null ||
            numberProcess === undefined ||
            typeof numberProcess === 'object'
        ) {
            return false;
        }
        if (typeof numberProcess === 'string') {
            if (numberProcess === '') return false;
            numberProcess = numberProcess.replaceAll(',', '.');
        }
        const numberToCheck = Number(numberProcess);
        if (isNaN(numberToCheck)) {
            return false;
        }

        return true;
    }
}
