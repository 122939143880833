import React, { FC } from 'react';

import styles from './Relations.module.css';
import { Relation } from 'src/components/popupFormExtended/relation/Relation';
import { RelationsProps } from 'src/components/popupFormExtended/relations/types';

export const Relations: FC<RelationsProps> = (props) => {
    const { section } = props;

    return (
        <div className={styles.wrapper}>
            {section.relations?.map((relation, i) => {
                return <Relation key={i} relation={relation} {...props} />;
            })}
        </div>
    );
};
