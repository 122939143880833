/**
 * Returns a funtion that will cast given properties as integers
 * Returned functions mutates original object
 */
export function makeCastPropertiesAsInt(...properties: string[]) {
    return function castPropertiesAsInt(el: Record<string, any>) {
        for (const prop of properties) {
            el[prop] = parseInt(el[prop]);
        }
        return el;
    };
}
