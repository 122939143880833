import { BrowserListScreenLocationState } from 'src/components/listScreen/pages/objectList/types';
import { SORTING_PARAM_NAME } from 'src/constants/list';
import { ListSortProperties } from 'src/store/src/listScreen/transformations/types';

export const prepareSorting = (
    locationData: BrowserListScreenLocationState
): { sortingData: ListSortProperties; sortingLegacyParamsKey: string | null } => {
    const sortingParamBValue = locationData?.params?.[SORTING_PARAM_NAME];

    if (!sortingParamBValue)
        return { sortingData: locationData.store?.sortingData ?? [], sortingLegacyParamsKey: null };

    const sortingObject = JSON.parse(sortingParamBValue);

    // basic type check
    if (
        !Array.isArray(sortingObject) ||
        (sortingObject.length !== 0 && typeof sortingObject[0] !== 'object')
    ) {
        console.error(
            `prepareSorting: param ${SORTING_PARAM_NAME} does not have valid sortingObject`
        );
        return { sortingData: locationData.store?.sortingData ?? [], sortingLegacyParamsKey: null };
    }

    return {
        sortingData: [
            ...((sortingObject as ListSortProperties) ?? []),
            ...(locationData.store?.sortingData ?? [])
        ],
        sortingLegacyParamsKey: SORTING_PARAM_NAME
    };
};
