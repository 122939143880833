import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldFileObjectProps } from 'src/components/popupFormExtended/dynamicField/fieldFile/types';
import { UIFieldFile } from 'src/components/popupFormExtended/dynamicField/fieldFile/UIFieldFile';
import { FileManager } from 'src/utils';
import { useAppDispatch } from 'src/store';
import { setPopupInfoTwo } from 'src/store/src/popup';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { FieldFileBasicTypeValue } from 'src/data/fieldsFormType';

export const FieldFileObject: FC<FieldFileObjectProps> = ({
    dynamicFieldT,
    field,
    dispatch,
    popupFormExtendedData,
    prefix,
    relationId,
    keyOfFieldInState,
    uiFieldCommonProps
}) => {
    const [state, setState] = React.useState(field.value);
    const [progress, setProgress] = React.useState<number | undefined>(undefined);

    const appDispatch = useAppDispatch();

    const onChange = (file: File) => {
        loadFile(file);
    };

    const loadFile = async (file: File) => {
        const idWew = String(file.lastModified) + String(file.size) + 0;
        setState((_) => {
            const newState = {
                id: '',
                originalFileName: file.name,
                name: file.name.split('.').slice(0, -1).join('.'),
                idWew,
                loading: true
            };

            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: { ...field, value: newState },
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );

            dispatch({
                type: 'plik',
                payload: {
                    code: keyOfFieldInState,
                    value: { ...field, value: newState }
                }
            });

            return newState;
        });

        setProgress(0);
        const res = await FileManager.uploadFiles(
            'api_react/src/popupFormExtended/uploadFiles.php',
            [file],
            (progress: number) => setProgress(progress * 100)
        );
        setProgress(undefined);

        const entries = res?.data ? Object.entries(res?.data) : [];
        const entry = entries[0];

        if (!res.success || !(idWew === entry[0])) {
            console.error('FieldFileObject: cannot upload a file', file.name);
            appDispatch(
                setPopupInfoTwo({
                    message: res.message,
                    title: 'Błąd przesyłania pliku',
                    messageInjectHtml: true
                })
            );

            const newState = {
                id: '',
                name: '',
                originalFileName: 'Błąd przesyłania!',
                loading: false
            };
            setState(newState);
            dispatch({
                type: 'plik',
                payload: {
                    code: keyOfFieldInState,
                    value: { ...field, value: newState }
                }
            });
            return;
        }

        setState((prev) => {
            const newState: FieldFileBasicTypeValue = {
                ...prev,
                id: entry[1].id,
                name: entry[1].name,
                loading: false
            };

            dispatch({
                type: 'plik',
                payload: {
                    code: keyOfFieldInState,
                    value: { ...field, value: newState }
                }
            });

            return newState;
        });
    };

    const onChangeNameFile = (e: React.ChangeEvent<HTMLInputElement>, blur: boolean) => {
        e.preventDefault();
        const newState = { ...state, name: e.target.value };
        setState(newState);

        if (blur) {
            dispatch({
                type: 'plik',
                payload: {
                    code: keyOfFieldInState,
                    value: { ...field, value: newState }
                }
            });
        }
    };

    return (
        <UIFieldFile
            value={state}
            onChange={onChange}
            onChangeNameFile={onChangeNameFile}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            progress={progress}
            uiFieldSharedProps={{
                dynamicFieldT
            }}
        />
    );
};
