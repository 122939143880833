import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(({ palette, spacing }) => ({
    timer_select_options_root: {
        position: 'absolute',
        zIndex: 50002,
        boxSizing: 'border-box',
        // top: props => props.position.top,
        // right: props => props.position.right,
        top: (props) => props.position.height + 8,
        left: 0,
        maxWidth: (props) => (props.type === 'nazwa_czego' ? spacing(60) : spacing(8)),
        minWidth: (props) => (props.type === 'nazwa_czego' ? spacing(18) : spacing(8)),
        textAlign: (props) => (props.type === 'nazwa_czego' ? 'right' : 'left'),
        background: 'white',
        border: '1px solid ' + palette.divider,
        borderRadius: '0 0 4px 4px'
    },
    timer_select_options_wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        maxHeight: spacing(40),
        overflowY: 'auto'
    },
    timer_select_options_item: {
        padding: spacing(0.8),
        fontFamily: '"Lato", sans-serif',
        fontSize: spacing(1.4),
        color: palette.text.secondary,
        cursor: 'pointer',
        '&:hover': {
            background: palette.background.paper
        }
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie opcji do inputu  select. Renderowany przez [Components/Timers/Select]{@link Select}
 * @component
 * @category Components
 * @subcategory Timers
 * @param {object} props - Propsy komponentu
 * @param {boolean} props.open - Wyznacza czy komponent jest wyświetlany
 * @param {"nazwa_czego"|"typ_czego"} props.type - Typ selectu.
 * @param {array} props.options -  Przefiltrowane opcje wyboru.
 * @param {Function} props.handleClick -  Funckja wywoływana na kliknięcie w którąś z opcji.
 * @param {Function} props.openNewCase -funkcja otwiera nowe okno w systemie, w którym można stworzyć zadanie lub inny obiekt który automatycznie zostanie przypisany do stopera
 * @param {'large'|'medium'|'small'} props.viewport - rozmiar viewportu
 * @returns {ReactComponent}
 * @see [Components/Timers/Select]{@link Select}
 */
const SelectOptions = (props) => {
    const classes = useStyles(props);
    const { open, options, type, handleClick, openNewCase } = props;

    const list =
        type === 'nazwa_czego'
            ? options.map((o) => (
                  <div
                      className={classes.timer_select_options_item}
                      onMouseDown={(e) => handleClick(e, o.id_czego, o.nazwa_czego)}
                      onClick={(e) => e.stopPropagation()}
                      key={o.id_czego}>
                      {o.nazwa_czego}
                  </div>
              ))
            : options.map((o) => (
                  <div
                      className={classes.timer_select_options_item}
                      onMouseDown={(e) => handleClick(e, o.nazwa)}
                      onClick={(e) => e.stopPropagation()}
                      key={o.id}>
                      {o.nazwa}
                  </div>
              ));

    return (
        <Zoom in={open} unmountOnExit>
            <div className={classes.timer_select_options_root} onClick={(e) => e.stopPropagation()}>
                <div
                    className={classes.timer_select_options_wrapper}
                    onClick={(e) => e.stopPropagation()}>
                    {list}
                    {type === 'nazwa_czego' ? (
                        <div
                            className={classes.timer_select_options_item}
                            onMouseDown={openNewCase}
                            onClick={(e) => e.stopPropagation()}>
                            Dodaj...
                        </div>
                    ) : null}
                </div>
            </div>
        </Zoom>
    );
};

export default SelectOptions;
