import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { FieldRelationshipType } from 'src/components/popupFormExtended/dynamicField/fieldRelationshipType/types';
import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';

type FieldDependentRelationshipTypeManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldRelationshipType;
};

export class FieldDependentRelationshipTypeManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldRelationshipType;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentRelationshipTypeManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async zmienWartosciPolZNadrzednegoModTextForm() {
        await this.getDataForSuperior();
        await this.checkIfIsReturnValuesForChild();
    }

    private async getDataForSuperior() {
        const screen = this.getScreen();
        const params = {
            q: this.fieldWasTriggered.value.value,
            ekran: screen,
            tabela_opcji: this.fieldWasTriggered.value.select
        };
        const res = await getFieldAdditionalData({
            url: 'json_pobierz_wartosci_pol_z_nadrzenego.php',
            params
        });
        if (res.data) {
            this.valuesFromSuperior = res.data;
            this.isCaptureValuesFromSuperior = true;
        }
    }
}
