import React, { FC } from 'react';
import { PermissionFieldsLevelProps } from './types';
import PermissionsField from '../../molecules/permissionsField/PermissionsField';
import PermissionsLevelHeader from '../../molecules/permissionsLevelHeader/PermissionsLevelHeader';
import PermissionsItemHeader from '../../molecules/permissionsItemHeader/PermissionsItemHeader';
import { PERIMISSION_LEVEL_INDENT } from 'src/constants/permissionsScreen';

const PermissionFieldsLevel: FC<PermissionFieldsLevelProps> = ({
    fields,
    sectionId,
    unfoldedState,
    toggleFolding,
    ...props
}) => {
    const fieldsKey = `fields_${sectionId}`;

    return (
        <div style={{ marginLeft: PERIMISSION_LEVEL_INDENT }}>
            <PermissionsLevelHeader
                folded={unfoldedState[fieldsKey]}
                onClick={() => toggleFolding(fieldsKey)}
                title="Pola"
            />
            {unfoldedState[fieldsKey] &&
                fields.map((field) => (
                    <ul key={field.id}>
                        <PermissionsItemHeader text={field.name} />
                        {field.canChangeVisible && (
                            <PermissionsField
                                item={field}
                                type="visible"
                                level="field"
                                {...props}
                            />
                        )}
                        {field.canChangeEditing && (
                            <PermissionsField
                                item={field}
                                type="editing"
                                level="field"
                                {...props}
                            />
                        )}
                    </ul>
                ))}
        </div>
    );
};

export default PermissionFieldsLevel;
