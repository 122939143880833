import { FieldPriceNumberChangeType } from 'src/components/popupFormExtended/dynamicField/fieldPriceNumber/FieldPriceNumberManager';
// import { ChangeType } from 'src/components/popupFormExtended/dynamicField/fieldPriceNumber/types';
import { FieldPriceNumberBasicTypeValue } from 'src/data/fieldsFormType';

export function checkIsSamePriceNumber({
    oldValue,
    changeType,
    value
}: {
    oldValue: FieldPriceNumberBasicTypeValue;
    changeType: FieldPriceNumberChangeType;
    // changeType: ChangeType;
    value: string;
}) {
    switch (changeType) {
        case 'netto':
            if (oldValue.netto === value) return true;
            break;
        case 'liczba':
            if (oldValue.liczba === value) return true;
            break;
        case 'vat':
            if (oldValue.vat === value) return true;
            break;
        case 'total_brutto':
            if (oldValue.total_brutto === value) return true;
            break;
        case 'waluta':
            if (oldValue.waluta === value) return true;
            break;
        default:
            return false;
    }
}
