import React, { FC } from 'react';
import { PermissionsLevelHeaderProps } from './types';
import Icon from 'src/assets/arrow.svg';
import ThemeCompatibleSvgIcon from 'src/components/shared/atoms/themeCompatibleSvgIcon/ThemeCompatibleSvgIcon';
import { Typography } from '@material-ui/core';
import { decodeDbEncoding } from 'src/utils';

const PermissionsLevelHeader: FC<PermissionsLevelHeaderProps> = ({ folded, onClick, title }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <ThemeCompatibleSvgIcon
                color="primary"
                SvgComponent={Icon}
                sx={{
                    fontSize: '1rem',
                    transform: `${folded ? 'rotate(0.5turn)' : 'rotate(0.75turn)'}`
                }}
            />
            <Typography>{decodeDbEncoding(title)}</Typography>
        </div>
    );
};

// title and onclick should not change during app time
export default React.memo(
    PermissionsLevelHeader,
    (prevProps, nextProps) => prevProps.folded === nextProps.folded
);
