import React, { FC } from 'react';

import { UiInputNormal } from 'src/components/shared/atoms/uiInputs/uiInputNormal/UiInputNormal';
import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';

export const DynamicTextSearch: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter,
    confirm
}) => {
    const value = listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        ? listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        : '';
    return (
        <UiInputNormal
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatchFilter({
                    type: 'SET_VALUE_SIX',
                    payload: {
                        code: searchField.searchCode,
                        value: e.target.value
                    }
                });
            }}
            typeIcon="component"
            isicon={value ? 'true' : 'false'}
            iconComponent={
                <ButtonCross
                    onClick={() => {
                        dispatchFilter({
                            type: 'SET_VALUE_SIX',
                            payload: {
                                code: searchField.searchCode,
                                value: null
                            }
                        });
                    }}
                    style={{ top: '5px' }}
                />
            }
            onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                    confirm && confirm();
                }
            }}
        />
    );
};

export default React.memo(DynamicTextSearch);
