export const defaultSetting = {
    zeroLevel: {
        groupX: 0,
        groupY: 10
    },
    firstLevel: {
        allHeight: 220,
        rectHeight: 120,
        rectHeightWithoutTitle: 90,
        groupX: 10,
        groupXadm: 160,
        groupY: 0,
        distanceToCentralPointY: 100,
        values: {
            y: 10,
            y2: 30
        },
        value: {
            y: 35
        }
    },

    secondLevel: {
        allHeight: 120,
        rectHeight: 90,
        groupY: 30,
        values: {
            y: 10,
            x: 0
        },
        value: {
            y: 35
        }
    },
    threeLevel: {
        lineFromLeft: 20,
        marginTop: 20,
        rectHeight: 35,
        widthLineToRight: 42,
        values: {
            y: 12,
            x: 0
        }
    },
    threeLevelAndUpper: {
        marginLeft: 30,
        boxHeight: 60,
        widthLineToRight: 15,
        value: {
            y: 25
        }
    },
    otherLevel: {
        widthLineToRight: 8,
        marginLeft: 30,
        values: {
            y: 10,
            x: 0
        }
    },
    bettweenFirstSecondHeight: 30,
    boxWidthOneColumn: 150,
    boxWidthManyColumn: 100,
    minWidthForColumnInModeBlock: 270,
    fontSize: {
        normal: 12,
        small: 11,
        superSmall: 10
    },
    numersOfCharacterLengthText: {
        degreeFirst: 30,
        degreeSecond: 35
    },
    minLeftRightMargin: 50,
    minOneItemWidth: 150,
    minManyItemWidth: 100
};
