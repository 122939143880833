import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ShortSearchValuesResult } from '../listScreen/types';
import { ValueOne } from 'src/components/listScreen/organisms/popupContentSearch/reducer';

/**
 * Find objects matching query
 */

const actors = [
    'Salvatore Baccaro',
    'Don Backy',
    'Giancarlo Badessi',
    'Carlo Bagno',
    'Silvio Bagolini',
    'Ennio Balbo',
    'Raf Baldassarre',
    'Renato Baldini',
    'Franco Balducci',
    'Gigi Ballista',
    'Ignazio Balsamo',
    'Lino Banfi',
    'Luca Barbareschi',
    'Urbano Barberini',
    'Ciccio Barbi',
    'Guglielmo Barnabò',
    'Gianfranco Barra',
    'Giovanni Barrella',
    'Cesco Baseggio',
    'Rick Battaglia',
    'Gino Bechi',
    'Memo Benassi',
    'Carmelo Bene',
    'Roberto Benigni',
    'Francesco Benigno',
    'Galeazzo Benti',
    'Fabrizio Bentivoglio',
    'Alessandro Benvenuti',
    'Nerio Bernardi',
    'Toni Bertorelli',
    'Calisto Bertramo',
    'Ughetto Bertucci',
    'Enrico Beruschi',
    'Nino Besozzi',
    'Cesare Bettarini',
    'Giorgio Bianchi',
    'Roberto Bianchi',
    'Tino Bianchi',
    'Claudio Bigagli',
    'Oreste Bilancia',
    'Enzo Biliotti',
    'Riccardo Billi',
    'Roberto Bisacco',
    'Peppo Biscarini',
    'Claudio Bisio',
    'Gildo Bocci',
    'Federico Boido',
    'Massimo Boldi',
    'Ugo Bologna',
    'Bombolo',
    'Paolo Bonacelli',
    'Gianni Bonagura',
    'Massimo Bonetti',
    'Mike Bongiorno',
    'Alessio Boni',
    'Mario Bonnard',
    'Luigi Bonos',
    'Alberto Bonucci',
    'Sal Borgese',
    'Gianfabio Bosco',
    'Giulio Bosetti',
    'Andrea Bosic',
    'Raoul Bova',
    'Franco Bracardi',
    'Giorgio Bracardi',
    'Arturo Bragaglia',
    'Andrea Brambilla',
    'Gino Bramieri',
    'Armando Brancia',
    'Rossano Brazzi',
    'Mario Brega',
    'Fabrizio Brienza',
    'Enrico Brignano',
    'Paolo Briguglia',
    'Giulio Brogi',
    'Nando Bruno',
    'Tino Buazzelli',
    'Flavio Bucci',
    'Carlo Buccirosso',
    'Aldo Bufi Landi',
    'Luigi Maria Burruano',
    'Fred Buscaglione',
    'Gino Buzzanca',
    'Lando Buzzanca'
];

export async function searchForFieldValues(
    query: string,
    mode: string | null,
    optional: {
        [key: string]: any;
    } = {}
): Promise<BaseApiResponse<ShortSearchValuesResult>> {
    const params = new URLSearchParams(`query=${query}&mode=${mode}`);
    for (const [key, value] of Object.entries(optional)) {
        params.append(key, value?.toString());
    }
    console.log(params.toString());
    return simpleQueryAPIData(`api_react/src/shared/searchForFieldValues.php?${params.toString()}`);

    // const { fieldId } = optional;
    // const paramsArray = [
    //     ['query', query],
    //     ['mode', mode],
    //     ['fieldId', fieldId]
    // ];
    // if (fieldId) paramsArray.push(['fieldId', fieldId.toString()]);
    // if (mode) paramsArray.push(['mode', mode]);

    // let data_emulate = [];

    // if (query.length == 0) {
    //     data_emulate = mockdata.slice(0, 70);
    // } else {
    //     data_emulate = mockdata.filter((el) => {
    //         if (el.name.toUpperCase().includes(query.toUpperCase())) {
    //             return { name: el.name, id: el.id };
    //         }
    //     });
    // }

    // return {
    //     message: 'Zdobyto dane',
    //     success: true,
    //     errorCode: null,
    //     data: data_emulate
    // };
}
