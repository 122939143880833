import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export {
    setObjectNotBillData,
    setIsLoading,
    setWorkers,
    clearStateObjectNotBillData
} from './objectNotBill/objectNotBillSlice';
