import React, { FC } from 'react';
import { StaticHiderProps } from './types';

export const StaticHider: FC<StaticHiderProps> = ({ dynamicFieldT, type, children }) => {
    switch (type) {
        case 'waluta':
            if (dynamicFieldT?.ukryta_waluta === 't') return null;
            return <>{children}</>;
        case 'vat':
            if (dynamicFieldT?.ukryty_vat === 't') return null;
            return <>{children}</>;
        default:
            return <>{children}</>;
    }
};
