import React from 'react';
import { Popover } from '@mui/material';
import Add from 'src/assets/dodaj.svg';
import TooltipOnHover from 'src/components/shared/molecules/tooltipOnHover/TooltipOnHover';
import { topMenu } from 'src/constants/subtitles';
import styles from '../PopoverSectionStyles.module.css';
import WrapperIcon from 'src/components/shared/atoms/wrapperIcon/WrapperIcon';
import ThemeCompatibleSvgIcon from 'src/components/shared/atoms/themeCompatibleSvgIcon/ThemeCompatibleSvgIcon';
import AddObjectShortcutsSectionList from '../addObjectShortcutsSectionList/AddObjectShortcutsSectionList';
import { useTypedSelector } from 'src/store';

const AddObjectShortcutsSection = () => {
    const actions = useTypedSelector((state) => state.topPlusSlice.actions);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (_e: React.BaseSyntheticEvent) => {
        setAnchorEl(_e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (actions.length === 0) return null;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={styles.wrapperMain}>
            <TooltipOnHover
                title={topMenu.tooltipOnHover.plus}
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -10]
                            }
                        }
                    ]
                }}>
                <div className={styles.box} aria-describedby={id} onClick={handleClick}>
                    <WrapperIcon>
                        <ThemeCompatibleSvgIcon
                            SvgComponent={Add}
                            color="primary"
                            sx={{
                                position: 'absolute',
                                fontSize: '1rem',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)'
                            }}
                        />
                    </WrapperIcon>
                </div>
            </TooltipOnHover>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}>
                <AddObjectShortcutsSectionList onClose={handleClose} />
            </Popover>
        </div>
    );
};

export default React.memo(AddObjectShortcutsSection);
