import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

export async function do2faAuth(
    code: string,
    authorizeDevice: boolean
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData('api_react/src/2faScreen/do2faAuth.php', 'POST', {
        code,
        authorizeDevice
    });
}
