import { UserApiData } from 'src/store/src/general/user/types';
import { BaseApiResponse, UserSettingResp } from '../../types';

type Data = {
    grid: string;
};

export async function getUserSetting(): Promise<BaseApiResponse<UserApiData>> {
    // Default options are marked with *
    const response = await fetch('api_react/userSettings.php', {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const dataPrimary = await response.text();

    if (dataPrimary === 'Unauthorized access!') {
        return {
            data: null,
            errorCode: 1,
            success: false,
            message: 'Unauthorized access!'
        };
    }

    const dataParsed = JSON.parse(dataPrimary);
    try {
        return dataParsed;
    } catch (e) {
        return { data: null, errorCode: 500, success: false, message: dataParsed };
    }
}

export async function setUserSetting(data: Data): Promise<UserSettingResp> {
    // Default options are marked with *
    const response = await fetch('api_react/userSettings.php', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    const dataPrimary = await response.text();
    const dataParsed = JSON.parse(dataPrimary);

    try {
        return dataParsed;
    } catch (e) {
        return { errorCode: 500, message: dataParsed, grid: null } as any;
    }
}
