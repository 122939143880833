import React, { FC } from 'react';

import styles from './FieldWrapperUnmodified.module.css';
import FieldHistoryWrapperUnmodified from '../fieldHistoryWrapperUnmodified/FieldHistoryWrapperUnmodified';
import { FieldWrapperUnmodifiedCommonProps } from './types';

export const FieldWrapperUnmodified: FC<FieldWrapperUnmodifiedCommonProps> = ({
    children,
    field,
    isLabel,
    dynamicFieldT
}) => {
    return (
        <FieldHistoryWrapperUnmodified dynamicFieldT={dynamicFieldT} field={field}>
            <div className={styles.box}>
                {isLabel && <p>{dynamicFieldT.nazwa}: </p>}
                <div className={styles.childrenBox}>{children}</div>
            </div>
        </FieldHistoryWrapperUnmodified>
    );
};
