import * as React from 'react';

import { FieldsPure } from 'src/components/popupFormExtended/dynamicField/types';
import { FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFormObjectHelperValidatorProps } from './types';
import { DataRef, TypeFnValidate, TypeOfInfo, ValidationResultTypes } from '../types';

export class DynamicFormObjectHelperValidator {
    dispatchFormState: React.Dispatch<FormActions>;
    typeFnValidate: TypeFnValidate;
    dataRef: DataRef;
    constructor({
        dispatchFormState,
        typeFnValidate,
        dataRef
    }: DynamicFormObjectHelperValidatorProps) {
        this.dispatchFormState = dispatchFormState;
        this.typeFnValidate = typeFnValidate;
        this.dataRef = dataRef;
    }
    protected async setError(key: string, field: FieldsPure) {
        this.dispatchFormState({
            type: 'update_fields',
            payload: {
                [key]: {
                    ...field,
                    isError: true
                }
            }
        });
    }
    protected async setValidationResult({
        typeOfInfo,
        type,
        scrollKey
    }: {
        typeOfInfo: TypeOfInfo;
        type: ValidationResultTypes;
        scrollKey?: string;
    }) {
        this.dataRef.validationResult = {
            isFormValid: false,
            breakValidationLoop: false,
            type: {
                ...type
            },
            typeOfInfo,
            scrollKey: scrollKey ? scrollKey : ''
        };
    }
}
