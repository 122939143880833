import { TransformationsState } from 'src/store/src/listScreen/transformations/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { ApiObjectsData } from './types';

export async function fetchAllIds(
    screen: string,
    transformationsState: TransformationsState
): Promise<BaseApiResponse<string[]>> {
    const params = new URLSearchParams([
        ['screen', screen],
        ['page', transformationsState.paging.actualPage.toString()],
        ['objectsPerPage', transformationsState.paging.objectsPerPage.toString()]
    ]);
    const body = {
        filters: transformationsState.filters,
        sortingData: transformationsState.sortingData
    };
    return simpleQueryAPIData(
        `api_react/src/listScreen/getObjectsIds.php?${params.toString()}`,
        'POST',
        body
    );
}
