import React from 'react';

import { MessageRowFile, UpdateMessagePayload } from 'src/store/src/messageScreen/message/types';
import { FileManager } from 'src/utils';

export const useUploadFileMessageScreen = ({
    row,
    updateField
}: {
    row: MessageRowFile;
    updateField: (data: UpdateMessagePayload) => void;
}) => {
    const [state, setState] = React.useState(row.value);
    const [progress, setProgress] = React.useState<number | undefined>(undefined);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    const loadFiles = async (files: File[]) => {
        if (
            files.findIndex(
                (file) => state.findIndex((stateFile) => file.name === stateFile.name) !== -1
            ) !== -1
        ) {
            setErrorMessage('Nie można przesłać ponownie tego samego pliku!');
            return;
        }

        const newFiles = files.map((file, i) => ({
            id: '',
            name: file.name,
            originalFileName: file.name,
            loading: true,
            idWew: String(file.lastModified) + String(file.size) + i,
            ext: '',
            size: 0
        }));

        setState((prev) => {
            const newState = [...prev, ...newFiles];
            updateField({
                code: row.code,
                type: 'files',
                value: newState
            });

            return newState;
        });
        setProgress(0);
        const res = await FileManager.uploadFiles(
            'api_react/src/popupFormExtended/uploadFiles.php?forSimpleScreen=1',
            files,
            (progress: number) => setProgress(progress * 100)
        );
        setProgress(undefined);
        if (!res.success) {
            setErrorMessage('Nie udało się przesłać plików!');
            setState((prev) => {
                const newState = prev.filter((f) => !f.loading);
                updateField({
                    code: row.code,
                    type: 'files',
                    value: newState
                });
                return newState;
            });
            return;
        }
        const entries = res?.data ? Object.entries(res?.data) : [];
        setState((prev) => {
            const newValue = [
                ...prev.map((file) => {
                    const found = entries.find((item) => item[0] === file.idWew);
                    if (file.loading && found) {
                        return {
                            id: found[1].id,
                            name: found[1].name,
                            originalFileName: found[1].name + '.' + found[1].ext,
                            loading: false,
                            idWew: found[0],
                            ext: found[1].ext,
                            size: found[1].size
                        };
                    }
                    return file;
                })
            ];
            updateField({
                code: row.code,
                type: 'files',
                value: newValue
            });

            return newValue;
        });
    };

    const onDeleteFile = (_: React.MouseEvent<HTMLDivElement>, id: string) => {
        const newValue = state.filter((item) => item.id !== id);
        setState(newValue);
        updateField({
            code: row.code,
            type: 'files',
            value: newValue
        });
    };

    return { loadFiles, progress, errorMessage, setErrorMessage, onDeleteFile };
};
