import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

export const PopupContentConfigLine: FC = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                paddingBottom: '5px',
                paddingTop: '5px'
            }}></div>
    );
};
