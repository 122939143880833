import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

import { genrateColorShades } from '../utils/index';

//window.jmk_theme_color = '#1274BB';

export const makeThemeObj = (color = window.jmk_theme_color || '#750b22') => {
    const THEME_COLORS = genrateColorShades(color);

    const themeObj = {
        palette: {
            type: 'light',
            background: {
                default: '#fafafa',
                paper: '#f5f5f5'
            },
            primary: {
                light: THEME_COLORS.light,
                main: THEME_COLORS.main,
                dark: THEME_COLORS.dark
            },
            info: {
                main: '#66bb6a',
                dark: '#43a047',
                light: '#84c887'
            },
            secondary: {
                main: '#9396b5',
                dark: '#666c91',
                light: '#bfbfd6'
            },
            error: {
                light: red['A100'],
                main: red[500],
                dark: red['A700']
            },
            text: {
                primary: '#403c3e',
                secondary: '#646062',
                disabled: '#959394',
                hint: '#5B5E60',
                white: '#f2f2f2'
            }
        },
        typography: {
            fontFamily: '"Lato", sans-serif'
        },
        spacing: 10,
        shape: {
            borderRadius: 4
        },
        overrides: {
            MuiTooltip: {
                tooltipArrow: {
                    color: '#403c3e',
                    background: '#eee'
                },
                arrow: {
                    color: '#eee'
                },
                popper: {
                    zIndex: 50005
                }
            },
            MuiDialog: {
                root: {
                    zIndex: '50006 !important'
                }
            },
            MuiPopover: {
                root: {
                    zIndex: '50006 !important'
                }
            }
        }
    };

    /**
     * theme
     * @category Globals
     * @type {object}
     * @description style używane w całej aplikacji, dla duzego ekranu
     */
    return {
        themeLarge: createTheme({ ...themeObj }),
        themeMedium: createTheme({
            ...themeObj,
            spacing: 9,
            typography: {
                ...themeObj.typography,
                fontSize: 14
            }
        }),
        themeSmall: createTheme({
            ...themeObj,
            spacing: 9,
            typography: {
                ...themeObj.typography,
                fontSize: 14
            }
        })
    };
};
