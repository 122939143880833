type EventBusCallback = Function;

class _EventBus {
    bus: { [id: string]: EventBusCallback };

    constructor() {
        this.bus = {};
    }

    $off(id: string) {
        delete this.bus[id];
    }

    $on(id: string, callback: EventBusCallback) {
        this.bus[id] = callback;
    }

    $emit(id: string, ...args: any[]) {
        if (this.bus[id]) this.bus[id](...args);
    }
}

export const EventBus = new _EventBus();
