import React, { FC } from 'react';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { AnimableMenuIconProps } from './types';

/**
 * Component enabling to animate properly menu icon
 * param 'lockWhite' set constatnt white color despite of hover state changes
 */
const AnimableMenuIcon: FC<AnimableMenuIconProps> = ({ sx, iconData, hoverState, lockWhite }) => (
    <ThemeCompatibleIcon
        iconData={iconData}
        fontSize={'large'}
        color={lockWhite || hoverState === 'in' ? 'white' : 'primary'}
        sx={sx}
    />
);

AnimableMenuIcon.displayName = 'AnimableMenuIcon';

export default AnimableMenuIcon;
