import { CalendarView } from 'src/store/src/calendar/calendar/types';

export const getView = (): CalendarView => {
    const data = localStorage.getItem('calendarView');
    switch (data) {
        case 'day':
        case 'week':
        case 'workweek':
        case 'month':
            return data;
        default:
            return 'workweek';
    }
};
