import React, { FC } from 'react';
import { FieldCheckboxUnmodified } from 'src/components/popupFormExtended/dynamicField/fieldCheckbox/FieldCheckboxUnmodified';
import { FieldDefaultUnmodified } from 'src/components/popupFormExtended/dynamicField/fieldDefault/FieldDefaultUnmodified';
import { FieldFileUnmodified } from 'src/components/popupFormExtended/dynamicField/fieldFile/FieldFileUnmodified';
import { FieldListForeignUnmodified } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/FieldListForeignUnmodified';
import { FieldUnmodifiedSwitcherProps } from 'src/components/popupFormExtended/fieldUnmodifiedSwitcher/types';
import { UiWrapperFieldTwo } from 'src/components/shared/atoms/uiLayout/uiWrapperFieldTwo/UiWrapperFieldTwo';

import { FieldRelationshipTypeManyUnmodified } from '../dynamicField/fieldRelationshipTypeMany/FieldRelationshipTypeManyUnmodified';
import { FieldListForeignManyUnmodified } from '../dynamicField/fieldListForeignMany/FieldListForeignManyUnmodified';
import { FieldFileManyUnmodified } from '../dynamicField/fieldFileMany/FieldFileManyUnmodified';

export const FieldUnmodifiedSwitcher: FC<FieldUnmodifiedSwitcherProps> = (props) => {
    const { field } = props;

    if (field == null) return null;

    if (!field || typeof field === 'string' || field.hidden) {
        return null;
    }

    switch (field.typ) {
        case 'lista_obca':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldListForeignUnmodified {...props} field={field} />
                </UiWrapperFieldTwo>
            );
        case 'powiazanie_typ_wielokrotne':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldRelationshipTypeManyUnmodified {...props} field={field} />
                </UiWrapperFieldTwo>
            );
        case 'lista_obca_wielokrotna':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldListForeignManyUnmodified {...props} field={field} />
                </UiWrapperFieldTwo>
            );
        case 'checkbox':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldCheckboxUnmodified {...props} field={field} />
                </UiWrapperFieldTwo>
            );
        case 'plik':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldFileUnmodified {...props} field={field} />
                </UiWrapperFieldTwo>
            );
        case 'pliki_wielokrotne':
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldFileManyUnmodified {...props} field={field} />
                </UiWrapperFieldTwo>
            );
        default:
            return (
                <UiWrapperFieldTwo {...props}>
                    <FieldDefaultUnmodified {...props} field={field} />
                </UiWrapperFieldTwo>
            );
    }
};
