import * as React from 'react';

import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { FieldsNotValid } from '../types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { FieldRelatedObjectData } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';
import {
    DynamicFormObjectMailValidatorProps,
    TypeValidateMail
} from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectMailValidator/types';
import { FieldMail } from 'src/components/popupFormExtended/dynamicField/fieldMail/types';
import { regexsGlobal } from 'src/constants/regex';

const typeFnValidate = 'validateMail';
export class DynamicFormObjectMailValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    dispatchFormState: React.Dispatch<FormActions>;
    popupFormExtendedData: PopupFormExtendedData;
    fieldRelatedObjectData: FieldRelatedObjectData;
    typeFnValidate: TypeValidateMail;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        fieldRelatedObjectData,
        dataRef
    }: DynamicFormObjectMailValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.formState = formState;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldRelatedObjectData = fieldRelatedObjectData;
        this.typeFnValidate = typeFnValidate;
    }
    async validate() {
        await this.runValidations();
    }

    private async runValidations() {
        for (const key in this.formState.fields) {
            if (this.dataRef.validationResult.isFormValid) {
                const dynamicFieldT = this.popupFormExtendedData.fields.find(
                    (item) => item.kod === this.formState.fields[key]?.kod
                );
                const field = this.formState.fields[key];
                if (dynamicFieldT && field && field?.typ === 'email') {
                    await this.runValidationOne(field, key);
                }
            } else {
                break;
            }
        }
    }
    private async runValidationOne(field: FieldMail, key: string) {
        const value = typeof field.value === 'string' ? field.value : '';
        const isValid = await this.checkIsValid(value);
        if (!isValid) {
            this.setValidationResult({
                typeOfInfo: 'Warning',
                type: {
                    issue: this.typeFnValidate,
                    message: `W polu ${field.name} znajduje się nieprawidłowy Email. Czy chcesz kontynuować?`,
                    title: validationData[this.typeFnValidate].title,
                    id_zakladki: field.id_zakladki,
                    fieldCode: field.kod
                }
            });
        }
    }
    private async checkIsValid(value: string) {
        if (value?.length === 0) {
            return true;
        }

        const dividedMail = value.match(regexsGlobal.divedeEmail);
        if (!dividedMail) return false;
        if (dividedMail[1]) {
            const matchedFirstPart = dividedMail[1].match(regexsGlobal.validateFirstPartEmail);
            if (!matchedFirstPart) return false;
        }
        if (dividedMail[2]) {
            const matchedSecondPart = dividedMail[2].match(regexsGlobal.validateSecondPartEmail);
            if (!matchedSecondPart) {
                const matchedSecondPartTwo = dividedMail[2].match(
                    regexsGlobal.validateSecondPartEmailTwo
                );
                if (!matchedSecondPartTwo) return false;
            }
            return true;
        }
        return false;
    }
}
