import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { useAppDispatch } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Button } from '@mui/material';
import ContentScroll from 'src/components/shared/molecules/contentScroll/ContentScroll';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import styles from './PopupContentInfoTwo.module.css';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { usePressKeyWithKeyCode } from 'src/hooks';

export const PopupContentInfoTwo: FC<BasePopupContentProps> = ({ popup }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleOnClick = () => {
        dispatch(closePopup(popup.key));
        if (popup.closeCallbackEventId) {
            EventBus.$emit(popup.closeCallbackEventId);
        }
    };

    usePressKeyWithKeyCode(handleOnClick, 'Enter');
    return (
        <PopupTemplateThird title={popup.title}>
            <div className={styles.wrapper}>
                <ContentScroll maxWidth={800} maxHeight={300}>
                    <p
                        style={{
                            color: theme.palette.primary.main,
                            fontSize: '16px',
                            padding: '5px 0'
                        }}
                        dangerouslySetInnerHTML={
                            popup.messageInjectHtml ? { __html: popup.message } : undefined
                        }>
                        {popup.messageInjectHtml ? null : popup.message}
                    </p>
                    <div className={styles.box2}>
                        {popup.infoList.map((item, key) => (
                            <p
                                key={key}
                                style={{
                                    color: theme.palette.primary.main,
                                    fontSize: '14px',
                                    gap: '3px'
                                }}>
                                {key + 1}.{item}
                            </p>
                        ))}
                    </div>
                </ContentScroll>
                <div className={styles.box}>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={handleOnClick}>
                        <p>{globalPopup.contentInfo.button.yes}</p>
                    </Button>
                </div>
            </div>
        </PopupTemplateThird>
    );
};
