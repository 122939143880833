import React, { FC } from 'react';

import styles from './UiFieldSystemDate.module.css';
import { UIFieldSystemDateProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

export const UIFieldSystemDate: FC<UIFieldSystemDateProps> = (props) => {
    const { value, uiFieldCommonProps, uiFieldSharedProps } = props;

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <p className={styles.p}>{value}</p>
        </UiWrapper>
    );
};
