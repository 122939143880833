import React from 'react';
import { SUBLIST_FOR_STARTUP_BLOCK_KEY } from 'src/constants/startupScreen';
import { DataCardListBlockData } from 'src/store/src/startupScreen/startupScreen/types';

export type UseSublistInBlockDataCardList = {
    sublist: string;
    updateSublist: (sublist: string) => void;
};

export function useSublistInBlockDataCardList({
    data,
    blockCode
}: {
    data: DataCardListBlockData;
    blockCode: string;
}): UseSublistInBlockDataCardList {
    const [sublist, setSublist] = React.useState<string>(setSublistOnMount(blockCode, data));

    const updateSublist = (sublist: string) => {
        setSublist(sublist);
        localStorage.setItem(`${SUBLIST_FOR_STARTUP_BLOCK_KEY}-${blockCode}`, sublist);
    };

    return {
        sublist,
        updateSublist
    };
}

function setSublistOnMount(blockCode: string, data: DataCardListBlockData) {
    return (
        localStorage.getItem(`${SUBLIST_FOR_STARTUP_BLOCK_KEY}-${blockCode}`) ??
        data.sublists[0].title
    );
}
