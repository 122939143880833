import React, { FC } from 'react';

import { UiInputNormal } from 'src/components/shared/atoms/uiInputs/uiInputNormal/UiInputNormal';
import { DynamicTekstProps } from 'src/components/shared/atoms/dynamicText/type';

export const DynamicText: FC<DynamicTekstProps> = ({
    field,
    dynamicFieldT,
    isDisabledLabel,
    placeholder,
    fieldValue
}) => {
    return (
        <UiInputNormal
            value={fieldValue.value}
            label={isDisabledLabel ? '' : dynamicFieldT.nazwa}
            placeholder={placeholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange({
                    ...fieldValue,
                    value: e.target.value
                });
            }}
        />
    );
};
