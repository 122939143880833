import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAV_BACK_LEVEL } from 'src/constants';
import { makeEditSafeNavigation } from 'src/utils';
import { SafeNavigateFunction } from './types';

/**
 * function used to navigate to given address making all inside needed operations to fullly suport
 * custom legacy compatible navigation system
 */
export const useSafeNavigate: () => SafeNavigateFunction = () => {
    const navigate = useNavigate();

    return useCallback(
        (
            fullUrl: string,
            locationState: any = null,
            e: React.SyntheticEvent | null = null,
            stopPropagation = false,
            replace = false
        ) => {
            makeEditSafeNavigation(() => {
                // set back level so Forward action can react properly to NAV_BACK_LEVEL change
                sessionStorage.setItem(NAV_BACK_LEVEL, '0');
                navigate(fullUrl, { state: locationState, replace: replace });
            });

            if (stopPropagation === true) {
                e?.stopPropagation();
            }
        },
        [navigate]
    );
};
