import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const VideoHelpView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "VideoHelpView" */ 'src/components/videoHelpScreen/templates/videoHelpView/VideoHelpView'
        )
);

export const VideoHelpMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Video pomoc');

    return (
        <div>
            <React.Suspense fallback>
                <VideoHelpView />
            </React.Suspense>
        </div>
    );
};
