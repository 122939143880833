/**
 * Check if given value is T[] type by checking if is array and if all values match T type
 * @param value value to check if it is T[]
 * @returns boolean - a result of checking
 */
export const checkIfValueIsTypedArray = <T>(value: any): value is T[] => {
    if (!Array.isArray(value)) {
        return false;
    }

    var somethingIsNotString = false;
    value.forEach(function (item) {
        if (typeof item !== 'string') {
            somethingIsNotString = true;
        }
    });

    return !somethingIsNotString && value.length > 0;
};
